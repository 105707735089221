<template>
  <div class="upload-row">
    <div class="d-flex align-items-center">
      <td width="15%">
        <img :src="uploadByField.field.boundary_image" style="width: 100px" />
      </td>
      <div class="divider" />

      <td width="15%">
        <div
          class="default-name"
          @click="() => $router.push(`/field/${uploadByField.field.id}`)"
        >
          {{ uploadByField.field.name }}
        </div>
      </td>
      <div class="divider" />

      <td width="44%">
        {{ uploadByField.gpkg.upload.name }}
      </td>
      <div class="divider" />

      <td width="13%">
        {{ uploadByField.gpkg.upload.dataset_type_display }}
      </td>
      <div class="divider" />

      <td width="13%">
        <div class="actions">
          <div @click="handleViewDetails">
            View Details
            <i class="fa fa-arrow-circle-right" />
          </div>
        </div>
      </td>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex"
import { Uploads } from "@/store/modules"

export default {
  name: "UploadRowByField",
  props: {
    uploadByField: { type: Object },
    currentPage: { type: Number },
  },
  components: {},

  data() {
    return {
      showConfirmDeleteModal: false,
    }
  },

  computed: {},

  methods: {
    ...mapActions({
      fetchUploads: Uploads.Actions.fetch,
    }),

    ...mapMutations({
      setDetailsModalUploadFieldID: Uploads.Mutations.setDetailsModalUploadFieldID,
    }),

    handleViewDetails() {
      this.$emit("selected-upload-by-field-id", this.uploadByField.id)
      this.setDetailsModalUploadFieldID(this.uploadByField.gpkg.upload.id)
    },
  },
}
</script>

<style scoped>
.upload-row {
  position: relative;
  padding: 18px;
  border-top: 1pt solid #d5d5d5;
}

.upload-row:hover {
  background: #fafafa;
}

.icon-info {
  min-width: 270px;
}

.icon-info > i {
  font-size: 25px;
}

.icon-text {
  font-size: 15px;
  margin-left: 10px;
  font-weight: 500;
}

.action-required {
  color: #fedb80;
}

.failed {
  color: #cc0101;
}

.complete {
  color: #00a200;
}

.spinner {
  font-size: 15px;
  font-weight: 500;
}

.divider {
  height: 40px;
  width: 1px;
  border-left: 1pt solid #e0e0e0;
  display: block;
  margin: auto 18px;
}

.upload-name {
  color: #333;
  font-size: 15px;
  font-weight: 500;
}

.upload-user {
  font-size: 13px;
}

.actions {
  text-align: right;
  color: #0095ff;
  font-weight: 500;
  cursor: pointer;
  position: absolute center;
  right: 10px;
}

.actions > div {
  display: block;
}

.actions > div:hover {
  color: #888;
}

.action-text {
  font-weight: 500;
  margin-right: 5px;
  font-size: 13px;
}
</style>
