<template>
  <ArvaModal
    name="enrollmentHistoryModal"
    title="Enrollment History"
    :wide="true"
    :width="1000"
    @close-modal="handleClose"
  >
    <div class="px-3 py-2">
      <div class="row">
        <div class="col-3 pr-0">
          <img :src="field.boundary_image" style="width: 100px" />
        </div>
        <div class="col-9 pl-0">
          <v-simple-table>
            <tr>
              <td class="ghg text-left text-h4 text--primary">
                <h5>Field:</h5>
              </td>
              <h5>
                <td class="ghg text-left text-h4 text--primary">
                  {{ field.name }}
                </td>
              </h5>
            </tr>
            <tr v-if="ownership">
              <td class="ghg text-left">Ownership:</td>
              <td class="ghg text-left text-capitalize font-weight-bold">
                {{ownership}}
              </td>
            </tr>
            <tr v-if="ownership === 'leased'">
              <td class="ghg text-left">Land Owner Name:</td>
              <td class="ghg text-left text-capitalize font-weight-bold">
                {{landOwnerName}}
              </td>
            </tr>
            <tr v-if="scenarios.length > 0">
              <td class="ghg text-left regen_practice_text">Regenerative Practice:</td>
              <td class="ghg text-left font-weight-bold">
                <div v-for="scenario in scenarios"
                    :key="scenario.regen_practice.name">
                    <li v-if="scenario.is_selected">{{scenario.regen_practice.name}}</li>
                </div>
              </td>
            </tr>
          </v-simple-table>
        </div>
      </div>

      <div class="row">
        <div class="col">
            <div class="mb-2 mt-4">
                <SearchBar :searchString="searchString" @searching="handleSearch" />
            </div>
        </div>
        <div class="col">
            <v-btn class="ma-1 float-right mb-2 mt-3" @click="handleFieldClicked">
                <span>Data Spreadsheet</span>
            </v-btn>
        </div>
      </div>

      <v-simple-table
        class="selector-table"
        fixed-header
        height="380px"
        overflow=auto
      >
        <thead>
          <tr>
            <th width="200">
              Log Entry
            </th>

            <th width="500">
              Message
            </th>

            <th width="110">
              Timestamp
            </th>
            <th width="190">
              Modified By
            </th>
          </tr>
        </thead>
        <tbody>
          <EnrollmentHistoryRow
            v-for="historyRecord in searchFilteredItems"
            :key="historyRecord.id"
            :historyRecord="historyRecord"
          />
        </tbody>
      </v-simple-table>
    </div>
  </ArvaModal>
</template>

<script>
import ArvaModal from "@/components/modals/ArvaModal"
import SearchBar from "@/components/misc/SearchBar"
import EnrollmentHistoryRow from "@/components/enrollmentHistory/EnrollmentHistoryRow"
import CarbonReadyAPI from "@/api/CarbonReadyAPI"

export default {
  name: "EnrollmentHistoryModal",
  props: ["field", "enrollmentField"],
  components: {
    ArvaModal,
    SearchBar,
    EnrollmentHistoryRow,
  },
  data() {
    return {
        searchString: "",
        enrollmentHistory: [],
    }
  },

  computed: {
    searchFilteredItems() {
      if (!this.searchString) return this.enrollmentHistory
      const searchString = this.searchString.toLocaleLowerCase()
      return this.enrollmentHistory.filter(function (historyRecord) {
        return (
          historyRecord.log_entry.toLowerCase().includes(searchString) 
          || historyRecord.message.toLowerCase().includes(searchString)
        )
      })
    },
    ownership() {
      var fieldOwnership = ""
      if (this.enrollmentField.length > 0) fieldOwnership =  this.enrollmentField[0].ownership
      return fieldOwnership
    },
    landOwnerName() {
      var landOwnerName = ""
      if (this.enrollmentField.length > 0) landOwnerName =  this.enrollmentField[0].land_owner_name
      return landOwnerName
    },
    scenarios() {
      if (this.field.daycent[0]) return this.field.daycent[0].scenario
      return ""
    },
  },

  mounted() {
    this.fetchEnrollmentHistory()
  },

  methods: {
    handleSearch(searchString) {
      this.searchString = searchString
    },
    handleClose() {
      this.$emit("close-modal")
    },
    handleFieldClicked() {
      this.$router.push(`/data-spreadsheet/${this.field.id}`)
    },
    fetchEnrollmentHistory() {
      CarbonReadyAPI.getEnrollmentHistoryByField({
        field_id: this.field.id,
      }).then(resp => {
        this.enrollmentHistory = resp.data
      })
    },
  },
}
</script>

<style scoped>
.regen_practice_text {
  vertical-align:top
}
</style>
