<template>
    <div>
        <v-select @change="onChange" v-bind="$attrs" />

        <ArvaModal @close-modal="handleClose"
            name="requestPracticeChoiceModal" :title="`Request new choice for ${readableChoiceName}`" v-if="showModal">
            <div class="confirm-text mx-8 my-3">Does selection of "Other" for <b>{{ readableChoiceName }}</b> indicate a missing
                option? Enter a new choice below to propose an addition to the list.</div>
            <div class="confirm-text mx-8 my-3" v-if="props.dependentChoiceName && props.dependentChoiceValue">
                This new choice only applies when <b>{{ readableDependentChoiceName }}</b> is set to <b>{{ props.dependentChoiceValue }}</b>.
            </div>
            <br />
            <div class="row mx-14">
                <v-text-field required dense label="New choice" v-model="newChoice" />
            </div>
            <div class="row mx-14">
                <v-text-field dense label="Comments" v-model="newChoiceComments" />
            </div>
            <div class="row">
                <ArvaModalSaveFooter :disabled="!newChoice || newChoice.length < 2" confirmText="Request" cancelText="Skip"
                    @on-cancel="handleClose" @on-confirm="handleChoiceRequest" />
            </div>

        </ArvaModal>
    </div>
</template>
  
<script setup>
import { ref, computed } from 'vue'
import ArvaModal from "@/components/modals/ArvaModal"
import ArvaModalSaveFooter from '@/components/modals/ArvaModalSaveFooter.vue'
import DefaultsAPI from "@/api/DefaultsAPI"


const props = defineProps({
    modelValue: {
        type: [String, Number, Object],
        default: ''
    },
    choiceName: {
        type: String,
    },
    dependentChoiceName: {
        type: String,
        default: null
    },
    dependentChoiceValue: {
        type: String,
        default: null
    },
})

// NOTE: this is `update:modelValue` in Vue 3.
const emit = defineEmits(['input']);

const showModal = ref(false)
const newChoice = ref('')
const newChoiceComments = ref('')

const readableChoiceName = computed(() => props.choiceName.replace(/_/g, ' '));
const readableDependentChoiceName = computed(() => props.dependentChoiceName.replace(/_/g, ' '));

const onChange = (newValue) => {
    emit('input', newValue);
    showModal.value = newValue === 'other'
}

const handleChoiceRequest = () => {
    const choiceRequestPayload = {
        practice_type: props.choiceName,
        label: newChoice.value,
        value: newChoice.value,
        comments: newChoiceComments.value,
        dependent_practice_choice: props.dependentChoiceValue ? props.dependentChoiceName : '',
        dependent_value: props.dependentChoiceValue
    }
    DefaultsAPI.requestPracticeChoice(choiceRequestPayload);
    showModal.value = false
    newChoice.value = ''
    newChoiceComments.value = ''
}

const handleClose = () => {
    showModal.value = false
    newChoice.value = ''
    newChoiceComments.value = ''
}

</script>