import _ from "lodash"
import { fetchAgtPerformance, fetchAgtSummary } from "@/api/AgtPerformanceAPI"
import {
  updateCropDesignerSettingCrop,
  updateCropDesignerSettingTargetYield,
} from "@/api/DashboardAPI"
import AgtAPI from "@/api/AgtAPI"
import SeedSelectorAPI from "@/api/SeedSelectorAPI"
import {
  CROPS,
  CROP_KEY,
  CROP_PRICES_CENTS,
  CROP_SEEDS_PER_BAG,
  CROP_SEEDING_RATES,
} from "@/constants"
import { Dashboard, Filter, Organization } from "@/store/modules"
import { fertScaleFactor, yieldScaleFactor } from "@/utility"

const state = {
  nextCropSelections: {},
  targetYields: {},
  agtPerformance: [],
  agtSummary: {},
  seedCompanies: [],
  agts: null,
  fieldAgtAcreage: [],
  fieldAgtYield: [],
  agtSelectedCrop: null,
  seedingRates: {
    corn: CROP_SEEDING_RATES[CROPS.Corn],
    soybeans: CROP_SEEDING_RATES[CROPS.Soybeans],
    wheat: CROP_SEEDING_RATES[CROPS.Wheat],
    cotton: CROP_SEEDING_RATES[CROPS.Cotton],
    rice: CROP_SEEDING_RATES[CROPS.Rice],
  },
  cropDesignerHybrids: {},
  carbonCreditDollarValue: 20,
  ciScore: 0,
}

const getters = {
  [Dashboard.Getters.getRoiSummary]: (_, getters) => {
    const year = getters[Organization.Getters.getYear]
    const selectedFields = getters[Filter.Getters.getSelectedFields].filter(
      field => {
        const historicalROI = field.historical_roi || []
        return historicalROI.length > 0
      }
    )

    const historicalROISummary = {}
    for (const field of selectedFields) {
      for (const roi of field.historical_roi) {
        const { crop, year } = roi
        if (!historicalROISummary[year]) historicalROISummary[year] = {}
        if (!historicalROISummary[year][crop]) {
          historicalROISummary[year][crop] = {
            acreage: 0,
            acreageCalculated: 0,
            yield: 0,
            revenue: 0,
            seedCost: 0,
            fertCost: 0,
            opCost: 0,
            profit: 0,
            profitPerAcre: 0,
            hybridPlacementRevenueImprovement: 0,
          }
        }

        historicalROISummary[year][crop].acreage += field.acreage
        historicalROISummary[year][crop].acreageCalculated +=
          roi.acreage_calculated
        historicalROISummary[year][crop].yield += roi.field_yield
        historicalROISummary[year][crop].revenue += roi.revenue
        historicalROISummary[year][crop].seedCost += roi.seed_cost
        historicalROISummary[year][crop].fertCost += roi.fert_cost
        historicalROISummary[year][crop].opCost += roi.opcost
        historicalROISummary[year][crop].profit += roi.profit

        historicalROISummary[year][crop].profitPerAcre =
          historicalROISummary[year][crop].profit /
          historicalROISummary[year][crop].acreage
        // TODO: Unclear why this acreageCalculated was included here
        // if (historicalROISummary[year][crop].acreageCalculated > 0) {
        //   historicalROISummary[year][crop].profitPerAcre =
        //     historicalROISummary[year][crop].profit /
        //     historicalROISummary[year][crop].acreageCalculated
        // }

        if (roi.hybrid_placement_revenue_improvement) {
          historicalROISummary[year][crop].hybridPlacementRevenueImprovement +=
            roi.hybrid_placement_revenue_improvement
        }
      }
    }
    const thisYearSummary = historicalROISummary[year]

    if (!thisYearSummary) return []
    return Object.keys(thisYearSummary).map(crop => ({
      ...thisYearSummary[crop],
      crop,
    }))
  },

  [Dashboard.Getters.getCropDesignerSummary]: (state, getters) => {
    const {
      agtPerformance,
      nextCropSelections,
      cropDesignerHybrids,
      seedingRates,
      targetYields,
    } = state

    const selectedFields = getters[Filter.Getters.getSelectedFields]
    const cropDesignerSummary = {}

    for (const field of selectedFields) {
      const fieldId = field.id
      let selectedCrop = nextCropSelections[fieldId]
      let selectedHybrid = cropDesignerHybrids[fieldId]
      const fieldTargetYields = targetYields[fieldId]

      if (!selectedCrop) continue

      selectedCrop = parseInt(selectedCrop)

      if (!(selectedCrop in cropDesignerSummary)) {
        cropDesignerSummary[selectedCrop] = {
          acreage: 0,
          predictedYield: 0,
          targetYield: 0,
          nUnits: 0,
          pUnits: 0,
          kUnits: 0,
          revenue: 0,
          seedCost: 0,
          fertCost: 0,
          opCost: 0,
          grossProfit: 0,
          totalCarbonOpportunity: 0,
          cropDesignerHybrids: {},
        }
      }

      const cropDesigner = field.crop_designer
      if (!cropDesigner) continue

      const cropDesignerCrop = cropDesigner.crops[selectedCrop]
      if (!cropDesignerCrop) continue

      const predictedYield = cropDesignerCrop.predicted_yield
      const predictedYieldPerAcre = predictedYield / field.acreage
      let targetYield = fieldTargetYields[selectedCrop]
      if (typeof targetYield === "undefined")
        targetYield = predictedYieldPerAcre

      const fieldYieldScaleFactor = yieldScaleFactor(
        predictedYieldPerAcre,
        targetYield
      )
      const fertRecScaleFactor = fertScaleFactor(
        selectedCrop,
        predictedYieldPerAcre,
        targetYield
      )
      const revenue = cropDesignerCrop.revenue * fieldYieldScaleFactor
      const seedCost = cropDesignerCrop.seed_cost
      const fertCost = cropDesignerCrop.fert_cost * fertRecScaleFactor
      const opCost = cropDesignerCrop.est_op_cost
      const grossProfit = revenue - seedCost - fertCost - opCost
      cropDesignerSummary[selectedCrop].acreage += field.acreage
      cropDesignerSummary[selectedCrop].predictedYield += predictedYield
      cropDesignerSummary[selectedCrop].targetYield +=
        targetYield * field.acreage
      cropDesignerSummary[selectedCrop].nUnits +=
        cropDesignerCrop.n_rec * fertRecScaleFactor
      cropDesignerSummary[selectedCrop].pUnits +=
        cropDesignerCrop.p_rec * fertRecScaleFactor
      cropDesignerSummary[selectedCrop].kUnits +=
        cropDesignerCrop.k_rec * fertRecScaleFactor
      cropDesignerSummary[selectedCrop].revenue += revenue
      cropDesignerSummary[selectedCrop].seedCost += seedCost
      cropDesignerSummary[selectedCrop].fertCost += fertCost
      cropDesignerSummary[selectedCrop].opCost += opCost
      cropDesignerSummary[selectedCrop].grossProfit += grossProfit
      cropDesignerSummary[selectedCrop].totalCarbonOpportunity +=
        cropDesignerCrop.potential_carbon

      if (selectedHybrid) {
        if (
          !(
            selectedHybrid.variety in
            cropDesignerSummary[selectedCrop].cropDesignerHybrids
          )
        ) {
          cropDesignerSummary[selectedCrop].cropDesignerHybrids[
            selectedHybrid.variety
          ] = {
            acreage: 0,
            bags: 0,
          }
        }
        cropDesignerSummary[selectedCrop].cropDesignerHybrids[
          selectedHybrid.variety
        ].acreage += selectedHybrid.acreage
        cropDesignerSummary[selectedCrop].cropDesignerHybrids[
          selectedHybrid.variety
        ].bags +=
          (seedingRates[CROP_KEY[selectedCrop]] * selectedHybrid.acreage) /
          CROP_SEEDS_PER_BAG[selectedCrop]
      }
    }

    const summaryOutput = []
    for (const crop in cropDesignerSummary) {
      const {
        acreage,
        predictedYield,
        targetYield,
        nUnits,
        pUnits,
        kUnits,
        revenue,
        seedCost,
        fertCost,
        opCost,
        grossProfit,
        totalCarbonOpportunity,
        cropDesignerHybrids,
      } = cropDesignerSummary[crop]

      const hybrids = agtPerformance
        .filter(row => row.crop_id === parseInt(crop) && row.variety)
        .map(({ agt, variety, yieldPerAcre }) => ({
          agt,
          variety,
          yieldPerAcre,
        }))

      hybrids.sort((a, b) => b.yieldPerAcre - a.yieldPerAcre)
      const topHybrids = _.uniqBy(_.uniqBy(hybrids, "agt"), "variety")

      const nTons = nUnits / 2000
      const pTons = pUnits / 2000
      const kTons = kUnits / 2000

      const nPerAcre = nUnits / acreage
      const pPerAcre = pUnits / acreage
      const kPerAcre = kUnits / acreage

      const grossProfitPerAcre = acreage ? grossProfit / acreage : null

      const cropDesignerHybridsSummary = _.orderBy(
        Object.keys(cropDesignerHybrids).map(key =>
          Object.assign(cropDesignerHybrids[key], { name: key })
        ),
        ["bags"],
        ["desc"]
      )

      summaryOutput.push({
        crop,
        acreage,
        predictedYield,
        targetYield,
        hybrids: topHybrids.slice(0, 3),
        nTons,
        pTons,
        kTons,
        nPerAcre,
        pPerAcre,
        kPerAcre,
        revenue,
        seedCost,
        fertCost,
        opCost,
        grossProfit,
        grossProfitPerAcre,
        totalCarbonOpportunity,
        cropDesignerHybridsSummary,
      })
    }

    summaryOutput.sort((a, b) => {
      const cropA = a.crop.toLowerCase()
      const cropB = b.crop.toLowerCase()
      if (cropA < cropB) return -1
      if (cropA > cropB) return 1
      return 0
    })
    return summaryOutput
  },

  [Dashboard.Getters.getCropAgtVarietySummary]: (state, getters) => {
    const selectedFields = getters[Filter.Getters.getSelectedFields]
    const selectedFieldIds = selectedFields.map(field => field.id)
    const year = getters[Organization.Getters.getYear]

    const { agtPerformance = [] } = state
    const agtPerformanceInFocus = agtPerformance.filter(
      row =>
        selectedFieldIds.includes(row.fieldId) &&
        row.variety &&
        row.year == year
    )

    const cropAgtVariety = {}
    agtPerformanceInFocus.forEach(
      ({ acres, agt, crop_id: cropId, totalYield, variety }) => {
        if (!cropAgtVariety[cropId]) cropAgtVariety[cropId] = {}
        if (!cropAgtVariety[cropId][agt]) cropAgtVariety[cropId][agt] = {}
        if (!cropAgtVariety[cropId][agt][variety]) {
          cropAgtVariety[cropId][agt][variety] = {
            acreage: 0,
            totalYield: 0,
            yieldPerAcre: 0,
          }
        }

        cropAgtVariety[cropId][agt][variety].acreage += acres
        cropAgtVariety[cropId][agt][variety].totalYield += totalYield
        cropAgtVariety[cropId][agt][variety].yieldPerAcre =
          cropAgtVariety[cropId][agt][variety].totalYield /
          cropAgtVariety[cropId][agt][variety].acreage
      }
    )
    return cropAgtVariety
  },

  [Dashboard.Getters.getTopPerformingVarieties]: (_, getters) => {
    const cropAgtVariety = getters[Dashboard.Getters.getCropAgtVarietySummary]
    const agtSummary = getters[Dashboard.Getters.getAgtSummary]
    const agtPerformanceSummary =
      getters[Dashboard.Getters.getAgtPerformanceSummary]

    const flattenedByCrop = {}
    Object.keys(cropAgtVariety).forEach(cropId => {
      const cropResults = []
      Object.keys(cropAgtVariety[cropId]).forEach(agt => {
        Object.keys(cropAgtVariety[cropId][agt]).forEach(variety => {
          const { acreage, totalYield, yieldPerAcre } = cropAgtVariety[cropId][
            agt
          ][variety]
          const { acreage: agtTotalAcreage } = agtSummary[cropId][agt]

          // Calculate dollar difference to plant this variety across the entire AGT
          const { avgYieldPerAcre: agtAvgYieldPerAcre } = agtPerformanceSummary[
            cropId
          ][agt]
          const additionalYield =
            (yieldPerAcre - agtAvgYieldPerAcre) * agtTotalAcreage
          const dollarDifference =
            (CROP_PRICES_CENTS[cropId] / 100) * additionalYield

          cropResults.push({
            acreage,
            agt,
            agtTotalAcreage,
            cropId,
            dollarDifference,
            totalYield,
            variety,
            yieldPerAcre,
          })
        })
      })

      let filteredResults = cropResults.filter(row => row.acreage >= 5)
      // If all results got filtered out, then don't filter at all, so we see some results
      if (filteredResults.length === 0) filteredResults = cropResults

      flattenedByCrop[cropId] = filteredResults
    })
    return flattenedByCrop
  },

  [Dashboard.Getters.getAgtSummary]: (state, getters) => {
    const { agtSummary } = state
    const selectedFields = getters[Filter.Getters.getSelectedFields]
    const selectedFieldIds = selectedFields.map(field => field.id)
    const agtSummaryInFocus = agtSummary.filter(row =>
      selectedFieldIds.includes(row.fieldId)
    )

    const summary = {}
    agtSummaryInFocus.forEach(({ agt, acreage, cropId }) => {
      if (!summary[cropId]) summary[cropId] = {}
      if (!summary[cropId][agt]) summary[cropId][agt] = { acreage: 0 }

      const { acreage: existingAcreage } = summary[cropId][agt]
      summary[cropId][agt] = { acreage: existingAcreage + acreage }
    })
    for (let crop_id in summary) {
      summary[crop_id] = summary["27"]
    }

    return summary
  },

  [Dashboard.Getters.getAgtPerformanceSummary]: (state, getters) => {
    const { agtPerformance } = state
    const year = getters[Organization.Getters.getYear]
    const agtSummary = getters[Dashboard.Getters.getAgtSummary]
    const selectedFields = getters[Filter.Getters.getSelectedFields]
    const selectedFieldIds = selectedFields.map(field => field.id)

    const agtPerformanceSummary = {}
    Object.keys(agtSummary).forEach(cropId => {
      agtPerformanceSummary[cropId] = {}
      Object.keys(agtSummary[cropId]).forEach(agt => {
        agtPerformanceSummary[cropId][agt] = {
          acreage: agtSummary[cropId][agt].acreage,
          totalYield: 0,
          totalYieldAcreage: 0,
        }
      })
    })

    agtPerformance
      .filter(
        row =>
          row.variety &&
          row.year === year &&
          selectedFieldIds.includes(row.fieldId)
      )
      .forEach(row => {
        agtPerformanceSummary[row.crop_id][row.agt].totalYield += row.totalYield
        agtPerformanceSummary[row.crop_id][row.agt].totalYieldAcreage +=
          row.acres
      })

    Object.keys(agtPerformanceSummary).forEach(cropId => {
      Object.keys(agtPerformanceSummary[cropId]).forEach(agt => {
        agtPerformanceSummary[cropId][agt]["avgYieldPerAcre"] =
          agtPerformanceSummary[cropId][agt].totalYield /
          agtPerformanceSummary[cropId][agt].totalYieldAcreage
      })
    })

    return agtPerformanceSummary
  },
}

const mutations = {
  [Dashboard.Mutations.initializeCropDesignerSettings](state, fields) {
    const nextCropSelections = {}
    const targetYields = {}
    fields
      .filter(field => field.crop_designer)
      .forEach(field => {
        nextCropSelections[field.id] = field.crop_designer_setting.next_crop
        targetYields[field.id] = field.crop_designer_setting.target_yields
      })
    state.nextCropSelections = nextCropSelections
    state.targetYields = targetYields
  },

  [Dashboard.Mutations.setAgtPerformance](state, agtPerformanceData) {
    state.agtPerformance = agtPerformanceData
  },

  [Dashboard.Mutations.setAgtSummary](state, agtSummaryData) {
    state.agtSummary = agtSummaryData
  },

  [Dashboard.Mutations.setNextCrop](state, { nextCrop, fieldId }) {
    const nextCropSelections = { ...state.nextCropSelections }
    nextCropSelections[fieldId] = nextCrop
    state.nextCropSelections = nextCropSelections
  },

  [Dashboard.Mutations.setTargetYield](state, { fieldId, targetYields }) {
    const allTargetYields = { ...state.targetYields }
    allTargetYields[fieldId] = targetYields
    state.targetYields = allTargetYields
  },

  [Dashboard.Mutations.setCropDesignerHybrid](
    state,
    { variety, fieldId, acreage, nextCrop }
  ) {
    const cropDesignerHybrids = { ...state.cropDesignerHybrids }
    cropDesignerHybrids[fieldId] = {
      variety: variety,
      acreage: acreage,
      nextCrop: nextCrop,
    }
    state.cropDesignerHybrids = cropDesignerHybrids
  },

  [Dashboard.Mutations.setSeedCompanies](state, companies) {
    state.seedCompanies = companies
  },

  [Dashboard.Mutations.setAgtSelectedCrop](state, cropId) {
    state.agtSelectedCrop = cropId
  },

  [Dashboard.Mutations.setAgts](
    state,
    { agts, field_agt_acreage, field_agt_yield }
  ) {
    state.agts = agts
    state.fieldAgtAcreage = field_agt_acreage
    state.fieldAgtYield = field_agt_yield
    const agtCrops = [...new Set(agts.map(agt => agt.crop_id))].sort(
      (a, b) => a - b
    )
    if (agtCrops.length > 0) state.agtSelectedCrop = agtCrops[0]
  },

  [Dashboard.Mutations.setSeedingRate](state, { crop, seedingRate }) {
    const seedingRates = { ...state.seedingRates }
    seedingRates[crop] = seedingRate
    state.seedingRates = seedingRates
  },

  [Dashboard.Mutations.setCarbonCreditDollarValue](state, dollarPerTonne) {
    state.carbonCreditDollarValue = dollarPerTonne
  },

  [Dashboard.Mutations.setCIScore](state, score) {
    state.ciScore = score
  },
}

const actions = {
  [Dashboard.Actions.setSelectedCrop]({ commit }, payload) {
    commit(Dashboard.Mutations.setNextCrop, payload)

    if (payload.settingsId) {
      updateCropDesignerSettingCrop(payload.settingsId, payload.nextCrop)
    }
  },

  [Dashboard.Actions.setTargetYield]({ commit }, payload) {
    commit(Dashboard.Mutations.setTargetYield, payload)

    if (payload.settingsId) {
      updateCropDesignerSettingTargetYield(
        payload.settingsId,
        payload.targetYields
      )
    }
  },

  [Dashboard.Actions.setSelectedHybrid]({ commit }, payload) {
    commit(Dashboard.Mutations.setCropDesignerHybrid, payload)
  },

  [Dashboard.Actions.fetchAgtPerformance]({ commit }) {
    return new Promise(resolve => {
      fetchAgtPerformance().then(response => {
        commit(Dashboard.Mutations.setAgtPerformance, response.data)
        resolve()
      })
    })
  },

  [Dashboard.Actions.fetchAgtSummary]({ commit }) {
    return new Promise(resolve => {
      fetchAgtSummary().then(response => {
        commit(Dashboard.Mutations.setAgtSummary, response.data)
        resolve()
      })
    })
  },

  [Dashboard.Actions.fetchSeedCompanies]({ commit }) {
    return new Promise(resolve => {
      SeedSelectorAPI.fetchSeedCompanies().then(response => {
        commit(Dashboard.Mutations.setSeedCompanies, response.data)
        resolve()
      })
    })
  },

  [Dashboard.Actions.fetchAgts]({ commit }) {
    return new Promise(resolve => {
      AgtAPI.fetchAgts().then(response => {
        const { agts, field_agt_acreage, field_agt_yield } = response.data
        commit(Dashboard.Mutations.setAgts, {
          agts,
          field_agt_acreage,
          field_agt_yield,
        })
        resolve()
      })
    })
  },

  [Dashboard.Actions.updateSeedingRate]({ commit }, payload) {
    commit(Dashboard.Mutations.setSeedingRate, payload)
  },

  [Dashboard.Actions.updateCarbonCreditDollarValue](
    { commit },
    dollarPerTonne
  ) {
    commit(Dashboard.Mutations.setCarbonCreditDollarValue, dollarPerTonne)
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
