import AsyncComputed from "vue-async-computed"
import moment from "moment"
import VueApexCharts from "vue-apexcharts"
import Toasted from "vue-toasted"
import VCalendar from "v-calendar"
import Vue from "vue"
import vmodal from "vue-js-modal"
import vSelect from "vue-select"

import VueUploadComponent from "vue-upload-component"
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue"

import { CROP_DISPLAY_NAME, CROP_KEY, CROPS, DEPLOYMENT_ENVIRONMENT } from "@/constants"
import Datepicker from "@/components/misc/Datepicker"
import vuetify from "@/plugins/vuetify" // path to vuetify export
import { library } from "@fortawesome/fontawesome-svg-core" // font awesome
import { faCaretDown } from "@fortawesome/free-solid-svg-icons" // font awesome
import { faCaretUp } from "@fortawesome/free-solid-svg-icons" // font awesome
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome" // font awesome
import * as Sentry from '@sentry/vue'
import * as amplitude from '@amplitude/analytics-browser';
import App from "./App.vue"
import router from "./router"
import store from "./store"

// vuetify
// import '@mdi/font/css/materialdesignicons.css'
// import "vuetify/dist/vuetify.min.css"

// Import Bootstrap an BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css"
import "bootstrap-vue/dist/bootstrap-vue.css"

import "vue-select/dist/vue-select.css"
import "./assets/css/app.css"

const channel = new BroadcastChannel('sw-messages');
channel.addEventListener('message', event => {
  console.log('Received background message from service worker', event);
});

Vue.config.productionTip = false

Vue.component("v-select-old", vSelect)

Vue.component("file-upload", VueUploadComponent)
Vue.component("datepicker", Datepicker)
Vue.component("apexchart", VueApexCharts)

// Font Awesome
library.add(faCaretDown)
library.add(faCaretUp)
Vue.component("font-awesome-icon", FontAwesomeIcon)

Vue.filter("capitalize", value => {
  if (!value) return ""
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter("cleanSnake", value => {
  if (!value) return ""
  value = value.toString()
  value = value.replaceAll("_", " ")
  value = value.split(" ")
  value = value.map(word => word.charAt(0).toUpperCase() + word.slice(1))
  return value.join(" ")
})

Vue.filter("crop", cropId => {
  if (!cropId || !CROP_DISPLAY_NAME[cropId]) return ""
  return CROP_DISPLAY_NAME[cropId]
})

Vue.filter("integer", value => {
  const intValue = parseInt(value)
  if (isNaN(intValue)) return ""
  return intValue
})

Vue.filter("prettyInteger", value => {
  const intValue = Math.round(parseFloat(value))
  if (isNaN(intValue)) return ""
  return intValue.toLocaleString()
})

Vue.filter("floatTenth", value => {
  const floatValue = parseFloat(value)
  if (isNaN(floatValue)) return ""
  return floatValue.toFixed(1)
})

Vue.filter("floatTenthZeroed", value => {
  const floatValue = parseFloat(value)
  if (value < 0) return "0.0"
  if (isNaN(floatValue)) return ""
  return floatValue.toFixed(1)
})

Vue.filter("floatHundredth", value => {
  const floatValue = parseFloat(value)
  if (isNaN(floatValue)) return ""
  return floatValue.toFixed(2)
})

Vue.filter("floatHundredthZeroed", value => {
  const floatValue = parseFloat(value)
  if (value < 0) return "0.00"
  if (isNaN(floatValue)) return ""
  return floatValue.toFixed(2)
})

Vue.filter("floatThousandth", value => {
  const floatValue = parseFloat(value)
  if (isNaN(floatValue)) return ""
  return floatValue.toFixed(3)
})

Vue.filter("floatThousandthZeroed", value => {
  const floatValue = parseFloat(value)
  if (value < 0) return "0.000"
  if (isNaN(floatValue)) return ""
  return floatValue.toFixed(3)
})

Vue.filter("date", value => {
  if (!value) return ""
  return moment(value).format("MM/DD/YYYY")
})

Vue.filter("dateSettings", value => {
  if (!value) return "--"
  let momentDate = moment(new Date(value), "MM/DD/YYYY")
  if (momentDate.isValid()) return momentDate.format("MM/DD/YYYY")
  return "--"
})

Vue.filter("shortDatetime", value => moment(value).format("L LT"))

Vue.filter("shortISODate", value => moment.utc(value).format("YYYY-MM-DD"))

Vue.filter("noDecimals", value => Intl.NumberFormat().format(value.toFixed(0)))

Vue.filter("currency", value => {
  const floatValue = parseFloat(value)
  if (isNaN(floatValue)) return ""
  return Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(floatValue)
})

Vue.filter("intCurrency", value => {
  const floatValue = parseFloat(value)
  if (isNaN(floatValue)) return ""
  const formattedValue = `$${Intl.NumberFormat().format(
    Math.abs(floatValue).toFixed(0)
  )}`
  return floatValue >= 0 ? formattedValue : `(${formattedValue})`
})

Vue.filter("percent", value => {
  if (!value) value = 0
  if (typeof value === "undefined") value = 0
  return `${Math.round(value * 100)}%`
})

Vue.filter("percentFloat", value => {
  if (typeof value === "undefined") value = 0
  value = value * 100
  return `${value.toFixed(1)}%`
})

Vue.filter("thousands", value => (value / 1000).toFixed(0) + "k")

Vue.filter("yieldUnits", cropId =>
  parseInt(cropId) === CROPS.Cotton ? "lb/ac" : "bu/ac"
)

Vue.filter("cropKeyYieldUnits", cropKey =>
  cropKey === CROP_KEY[CROPS.Cotton] ? "lb/ac" : "bu/ac"
)

Vue.filter("cropKeySeedUnits", cropKey => {
  if (
    (cropKey === CROP_KEY[CROPS.Wheat]) |
    (cropKey === CROP_KEY[CROPS.Rice])
  ) {
    return "lb/ac"
  }
  return "seeds/ac"
})

Vue.filter("shortString", value => {
  if (!value) return ""
  if (value.length <= 30) return value
  return value.slice(0, 30)
})

Vue.use(AsyncComputed)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(Toasted, {
  router,
  singleton: true,
  iconPack: "fontawesome",
})
Vue.use(VCalendar, { componentPrefix: "vc" })
Vue.use(vmodal)

if (import.meta.env.VITE_NODE_ENV !== "local") {
  Sentry.init({
    Vue,
    dsn: "https://e31d037d8f06483fa6d662c75856d70c@o418422.ingest.sentry.io/5352257",
    environment: import.meta.env.VITE_NODE_ENV,
  });
}

if (DEPLOYMENT_ENVIRONMENT === "staging") {
  // staging API key
  amplitude.init('4dbde3491a1c53b4788a8d5b9de42036', {"autocapture":true});
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount("#app")
