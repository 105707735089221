<template>
  <tr>
    <td><img :src="field.boundary_image" style="width:50px" /></td>
    <td class="nowrap">{{ field.name }}</td>
    <td class="text-right">{{ field.acreage | prettyInteger }}</td>
    <td>
      <v-select-old
        v-if="cropOptions.length > 1"
        :style="{ width: '140px' }"
        :options="cropOptions"
        :value="nextCrop"
        @input="handleCropSelection"
      />
      <div v-else-if="cropOptions.length === 1">{{ cropOptions[0].label }}</div>
    </td>
    <td class="text-right">
      {{ predictedYieldPerAcre | prettyInteger }}
    </td>
    <td class="text-right">
      {{ predictedYield | prettyInteger }}
    </td>
    <td class="prediction-confidence" :class="yieldPredictionConfidence">
      {{ yieldPredictionConfidence | capitalize }}
    </td>
    <td class="text-right">{{ targetYield | prettyInteger }}</td>
    <td class="text-right">{{ revenue | intCurrency }}</td>
    <td class="text-right">{{ seedCost | intCurrency }}</td>
    <td class="text-right">{{ fertCost | intCurrency }}</td>
    <td class="text-right">{{ opCost | intCurrency }}</td>
    <td class="text-right">{{ grossProfit | intCurrency }}</td>
    <td class="text-right gross-profit">
      {{ grossProfitPerAcre | intCurrency }}
    </td>
  </tr>
</template>

<script>
import { mapActions } from "vuex"
import { CROP_DISPLAY_NAME } from "@/constants"
import { Dashboard } from "@/store/modules"
import { fertScaleFactor, yieldScaleFactor } from "@/utility"

export default {
  name: "ProfitAndLossRow",
  props: ["field", "nextCropSelection", "targetYields"],

  computed: {
    cropOptions() {
      return Object.keys(this.field.crop_designer.crops).map(cropId => ({
        label: CROP_DISPLAY_NAME[cropId],
        value: cropId,
      }))
    },

    nextCrop() {
      const nextCrop = this.nextCropSelection
      return {
        label: CROP_DISPLAY_NAME[nextCrop],
        value: nextCrop,
      }
    },

    cropInfo() {
      const { field, nextCropSelection } = this
      return field.crop_designer.crops[nextCropSelection]
    },

    predictedYield() {
      return this.cropInfo && this.cropInfo.predicted_yield
    },

    predictedYieldPerAcre() {
      return this.predictedYield / this.field.acreage
    },

    targetYield() {
      const targetYield = this.targetYields[this.nextCrop.value]
      return typeof targetYield !== "undefined"
        ? targetYield
        : this.predictedYieldPerAcre
    },

    yieldPredictionConfidence() {
      return this.cropInfo && this.cropInfo.yield_prediction_confidence
    },

    yieldScaleFactor() {
      return yieldScaleFactor(this.predictedYieldPerAcre, this.targetYield)
    },

    fertScaleFactor() {
      const nextCropId = parseInt(this.nextCrop.value)
      return fertScaleFactor(
        nextCropId,
        this.predictedYieldPerAcre,
        this.targetYield
      )
    },

    revenue() {
      return this.cropInfo && this.cropInfo.revenue * this.yieldScaleFactor
    },

    seedCost() {
      return this.cropInfo && this.cropInfo.seed_cost
    },

    fertCost() {
      return this.cropInfo && this.cropInfo.fert_cost * this.fertScaleFactor
    },

    opCost() {
      return this.cropInfo && this.cropInfo.est_op_cost
    },

    grossProfit() {
      return (
        this.cropInfo &&
        this.revenue - this.seedCost - this.fertCost - this.opCost
      )
    },

    grossProfitPerAcre() {
      return this.grossProfit && this.grossProfit / this.field.acreage
    },
  },

  methods: {
    ...mapActions({ setNextCrop: Dashboard.Actions.setSelectedCrop }),

    handleCropSelection(nextCrop) {
      this.setNextCrop({
        nextCrop: nextCrop && nextCrop.value,
        fieldId: this.field.id,
        settingsId: this.field.crop_designer_setting.id,
      })
    },
  },
}
</script>

<style scoped>
.gross-profit {
  background: #d1ecfe !important;
}

.nowrap {
  white-space: nowrap;
}

.prediction-confidence {
  font-weight: bold;
  font-size: 12px !important;
}

.low {
  color: red;
}

.medium {
  color: orange;
}

.high {
  color: #28a745;
}
</style>
