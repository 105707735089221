import API from "./API"

const endpoint = "ciscore"

export const createNewCIScore = (formData) => 
  API.post(`/${endpoint}/`, formData);

export const getAllCIScores = () =>
  API.get(`/${endpoint}/`);

export const getCIScore = (id) =>
  API.get(`/${endpoint}/${id}`);

export const getCIScoreEstimate = (formData) => 
  API.post(`/${endpoint}/estimation/`, formData);

export const getBulkCIEstimate = (formData) =>
  API.post(`/${endpoint}/bulk_field_estimation/`, formData);

export const getBulkCIEstimateCSV = (formData) =>
  API.post(`/${endpoint}/bulk_field_estimation_csv/`, formData);
