<template>
  <div>
    <div v-if="selectedFields.length > MAX_DEFAULT_FIELDS">
      <v-card elevation="0">
        <v-card-text class="text-center">
          <span class="text-h4"
            >Please select fewer than {{ MAX_DEFAULT_FIELDS }} fields using the
            top navigation tools.</span
          >
        </v-card-text>
      </v-card>
    </div>
    <div v-else-if="selectedFields.length > 0">
      <div id="input-card-container">
        <v-card
          class="my-auto mx-auto"
          v-if="showPracticesView || showSaveView || showSummaryView"
        >
          <v-card-text class="min-step-height" v-if="showPracticesView">
            <v-row class="mx-1 mt-3">
              <div class="text-h5">Add Planting for {{ year }}</div>
              <v-btn class="ml-auto" @click="handleCancelClick()">
                <v-icon>mdi-close</v-icon> Cancel
              </v-btn>
              <v-btn
                class="ml-4"
                color="green lighten-4"
                :disabled="allPlantingsComplete().length > 0"
                @click="handlePreSave()"
                >Save and Continue</v-btn
              >
            </v-row>

            <v-row class="mx-1 mt-7">
              <v-btn-toggle
                v-model="hasPracticeData"
                readonly
                divided
                dense
                mandatory
                active-class="light-blue lighten-4"
              >
                <v-btn
                  :value="true"
                  class="px-3"
                  @click="handleYesPlantingClick"
                >
                  Crop Planted <v-icon>mdi-check-bold</v-icon>
                </v-btn>
                <v-btn
                  :value="false"
                  class="px-3"
                  @click="handleNoPlantingClick"
                >
                  No Crop Planted <v-icon>mdi-close-thick</v-icon>
                </v-btn>
              </v-btn-toggle>

              <v-btn-toggle
                class="ml-5"
                v-model="stackPractices"
                divided
                dense
                active-class="light-blue lighten-4"
                mandatory
              >
                <v-btn
                  :value="true"
                  :disabled="!hasPracticeData || plantingData.length > 1"
                  class="px-3"
                  >Add Double Crop</v-btn
                >
                <v-btn :value="false" class="px-3"
                  >Replace Existing Planting Crop</v-btn
                >
              </v-btn-toggle>
            </v-row>

            <v-row v-if="plantingData.length > 0">
              <v-col md="12">
                <v-card
                  v-for="planting in plantingData"
                  :key="planting.idx"
                  class="mt-3"
                  elevation="6"
                >
                  <v-card-title
                    class="cursor blue-grey lighten-5"
                    @click="planting.showAllInfo = !planting.showAllInfo"
                  >
                    Planting #{{ plantingData.indexOf(planting) + 1 }}
                    <v-icon
                      v-if="!planting.showAllInfo"
                      @click="planting.showAllInfo = true"
                      >mdi-chevron-right</v-icon
                    >
                    <v-icon v-else @click="planting.showAllInfo = false"
                      >mdi-chevron-down</v-icon
                    >
                    <v-tooltip top
                      ><template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          class="ml-auto"
                          :color="
                            isPlantingComplete(planting)
                              ? 'green accent-4'
                              : 'red darken-1'
                          "
                        >
                          {{
                            isPlantingComplete(planting)
                              ? "mdi-check-circle-outline"
                              : "mdi-alert-circle-outline"
                          }}
                        </v-icon> </template
                      ><span>{{
                        isPlantingComplete(planting)
                          ? "Planting is good to go!"
                          : "Planting is missing information"
                      }}</span></v-tooltip
                    >
                    <v-btn
                      class="ml-2"
                      @click.stop="
                        handleRemovePlanting(plantingData.indexOf(planting))
                      "
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text v-if="planting.showAllInfo">
                    <v-row align="end">
                      <v-col md="2">
                        <div class="mt-3 mb-2">
                          <b
                            >Which crop is planted?<span class="red--text"
                              >*</span
                            ></b
                          >
                        </div>
                        <div class="input-holder">
                          <v-select
                            dense
                            outlined
                            hide-details
                            label="Crop"
                            :items="cropChoices"
                            v-model="planting.crop_id"
                            clearable
                          >
                          </v-select>
                        </div>
                      </v-col>
                      <v-col md="2">
                        <div class="mt-3 mb-2">
                          <b
                            >Planting date:
                            {{ isPlantingInFuture(planting) ? " (Est.)" : "" }}
                          </b>
                        </div>
                        <div class="input-holder">
                          <v-menu
                            v-model="planting.menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="planting.date"
                                label="Date"
                                dense
                                outlined
                                readonly
                                hide-details
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="planting.date"
                              :picker-date.sync="planting.pickerDate"
                              :min="yearPriorOne + '-06-01'"
                              :max="yearExtendedOne + '-12-31'"
                              @input="planting.menu = false"
                              scrollable
                            ></v-date-picker>
                          </v-menu>
                        </div>
                      </v-col>
                      <v-col md="2">
                        <div class="mb-2">
                          <b class="text-h7"> Seeding rate: </b>
                        </div>
                        <div class="input-holder">
                          <v-text-field
                            dense
                            outlined
                            hide-details
                            clearable
                            label="Rate"
                            v-model="planting.rate"
                            :rules="[numberValidator]"
                            type="number"
                          ></v-text-field>
                        </div>
                      </v-col>
                      <v-col md="2">
                        <div class="mb-2">
                          <b class="text-h7">Seeding rate units</b>
                        </div>
                        <div class="input-holder">
                          <v-select
                            dense
                            outlined
                            hide-details
                            label="Units"
                            :items="seedRateUnitChoices"
                            v-model="planting.unit"
                            clearable
                          >
                          </v-select>
                        </div>
                      </v-col>
                      <v-col md="2">
                        <div class="mb-2">
                          <b class="text-h7"> Manufacturer: </b>
                        </div>
                        <div class="input-holder">
                          <v-text-field
                            dense
                            outlined
                            hide-details
                            clearable
                            label="Seed manufacturer"
                            v-model="planting.manufacturer"
                          ></v-text-field>
                        </div>
                      </v-col>
                      <v-col md="2">
                        <div class="mb-2">
                          <b class="text-h7"> Seed variety: </b>
                        </div>
                        <div class="input-holder">
                          <v-text-field
                            dense
                            outlined
                            hide-details
                            clearable
                            label="Variety"
                            v-model="planting.variety"
                          ></v-text-field>
                        </div>
                      </v-col>
                      <v-col md="2">
                        <div class="mb-2">
                          <b class="text-h7"> Biological: </b>
                        </div>
                        <div class="input-holder">
                          <v-text-field
                            dense
                            outlined
                            hide-details
                            clearable
                            label="Biological"
                            v-model="planting.biological"
                          ></v-text-field>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-btn
                  v-if="canAddPlanting"
                  class="mt-4"
                  block
                  @click="handleAddEventClick"
                  >Add Planting<v-icon>mdi-plus</v-icon></v-btn
                >
              </v-col>
            </v-row>
            <v-row v-if="plantingData.length == 0">
              <v-card class="mx-auto mt-4" elevation="0">
                <v-card-text>
                  <div class="text-center">
                    <b class="text-h5 text-bold"
                      >No Planting (Fallow) for {{ this.year }}</b
                    >
                  </div>
                </v-card-text>
              </v-card>
            </v-row>
          </v-card-text>
          <!-- Save Spinner View -->
          <v-card-text class="min-step-height text-center" v-if="showSaveView">
            <v-progress-circular
              class="mt-4"
              :size="100"
              :width="8"
              color="green"
              indeterminate
            >
            </v-progress-circular>
            <div>
              <h3>Saving your planting information</h3>
            </div>
          </v-card-text>
          <!-- Summary View -->
          <v-card-text class="min-step-height" v-if="showSummaryView">
            <div class="d-flex">
              <h3>Fields Updated</h3>
              <v-btn class="ml-auto" @click="handleAddPlanting">
                Add More Planting
              </v-btn>
            </div>
            <div class="d-flex">
              <v-chip
                class="mx-2"
                v-for="fieldName in summaryFields"
                :key="fieldName.idx"
                >{{ fieldName }}</v-chip
              >
            </div>
            <br />
            <h3 v-if="failedFields.length > 0">Some Fields Failed to Save</h3>
            <div v-if="failedFields.length > 0" class="d-flex">
              <v-chip
                color="red lighten-3"
                class="mx-2"
                v-for="fieldName in failedFields"
                :key="fieldName.idx"
                >{{ fieldName }}</v-chip
              >
            </div>
            <br />
            <h3>Planting Data Submitted</h3>
            <div>
              <v-simple-table class="mx-4 mb-4">
                <thead>
                  <tr>
                    <th v-for="key in summaryKeys" :key="key.idx">
                      {{ key == "crop_id" ? "Crop" : key | cleanSnake }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="data in summaryData" :key="data.idx">
                    <td v-for="key in summaryKeys" :key="key.idx">
                      <span v-if="key == 'crop_id'">{{
                        cropDisplay[data[key].value]
                      }}</span>
                      <span v-else>
                        {{
                          data[key].value
                            ? data[key].value
                            : "None" | cleanSnake
                        }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>
          </v-card-text>
        </v-card>
      </div>

      <!-- Fields, Data Comp, and Add Practices -->
      <v-row
        class="mt-4 mb-2 row-margin-correct"
        v-if="!showPracticesView && !showSaveView"
      >
        <div>
          <span class="text-h5 ml-1"
            >{{ opsSettingsByField.length }} fields</span
          >
          <span class="text-h5 ml-2"
            >({{ dataCompletionForYear }}% Complete)</span
          >
        </div>
        <div class="ml-auto d-flex">
          <div>
            <v-btn
              v-for="y in yearListModified"
              :key="y.idx"
              :color="
                parseInt(y) === parseInt(year) ? 'blue-grey lighten-4' : 'white'
              "
              elevation="0"
              tile
              dense
              class="mx-1 py-1"
              @click="handleSeasonSelection(y)"
            >
              {{ y }}
            </v-btn>
          </div>
          <div class="text-container ml-2">
            <v-text-field
              outlined
              dense
              hide-details
              v-model="tableFilterText"
              label="Filter Fields"
            ></v-text-field>
          </div>
          <div class="ml-2">
            <v-btn
              v-if="
                showPracticesView === false &&
                showSaveView === false &&
                showSummaryView === false
              "
              class="white--text"
              color="green"
              x-large
              :disabled="fieldsInSelection.length === 0"
              @click="handleAddPracticesClick"
              >Add Practices</v-btn
            >
          </div>
        </div>
      </v-row>
      <v-row class="mt-4 mb-2 row-margin-correct" v-else>
        <div>
          <span class="text-button ml-1">Receiving fields</span>
        </div>
      </v-row>

      <!-- Quick Filtering Buttons -->
      <v-row class="mt-4 row-margin-correct">
        <v-btn
          dense
          tile
          :color="cropSelect == null ? 'blue-grey lighten-4' : 'white'"
          @click="handleSelectAll(true, true)"
          class="px-2 mx-2 py-1 text-none letter-spacing-0"
          elevation="0"
        >
          All ({{ opsSettingsByField.length }})
        </v-btn>
        <v-btn
          v-for="sharedCropPlan in historicalPlantings"
          :key="sharedCropPlan.idx"
          dense
          tile
          :color="
            JSON.stringify(cropSelect) ==
            JSON.stringify(sharedCropPlan.crops.split(','))
              ? 'blue-grey lighten-4'
              : 'white'
          "
          @click="handleCropFilterButton(sharedCropPlan)"
          class="px-2 mx-2 py-1 text-none"
          elevation="0"
        >
          {{ mapCropNames(sharedCropPlan.crops) }} ({{
            sharedCropPlan.fieldIds.length
          }})
        </v-btn>
      </v-row>

      <!-- Field Filtering Table -->
      <v-row class="pt-3">
        <v-col>
          <v-card>
            <v-card-text v-if="showSelectedFields">
              <v-simple-table>
                <thead>
                  <tr>
                    <th class="three">
                      <div class="d-flex">
                        <v-simple-checkbox
                          color="blue"
                          v-model="selectAllBox"
                          @input="handleSelectAll($event)"
                        ></v-simple-checkbox>
                      </div>
                    </th>
                    <th class="ten">Name</th>
                    <th class="three">Farm</th>
                    <th class="three">Locked</th>
                    <th class="three">Crops</th>
                    <th>Planting</th>
                    <th class="three">Copy</th>
                    <th v-if="showPracticesView">Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="field in opsSettingsByFieldFiltered"
                    :key="field.idx"
                  >
                    <td>
                      <v-simple-checkbox
                        color="blue"
                        :value="
                          fieldsInSelection.includes(parseInt(field.fieldId))
                        "
                        :disabled="disableCheckbox(field) || field.fieldLocked"
                        @input="fieldChecked(field, $event)"
                      ></v-simple-checkbox>
                    </td>
                    <td>
                      <img :src="field.fieldImg" style="width: 50px" />&nbsp;
                      {{ field.fieldName }}
                    </td>
                    <td class="overflow-hidden">{{ field.farmName }}</td>
                    <td>
                      <v-icon
                        size="20"
                        v-if="field.fieldLocked"
                        :color="field.fieldLocked ? 'red' : 'green'"
                      >
                        {{ field.fieldLocked ? "mdi-lock" : "mdi-lock-open" }}
                      </v-icon>
                    </td>
                    <td v-if="'cropNames' in field">
                      <div v-for="crop in field.cropNames" :key="crop">
                        {{ crop }}
                      </div>
                    </td>
                    <td v-else>
                      <div>No Crop</div>
                    </td>
                    <td class="py-2" v-if="!field.noYearData">
                      <v-row
                        class="mx-0"
                        :class="
                          field.plantings.indexOf(plantingInfo) !=
                          field.plantings.length - 1
                            ? 'row-bottom'
                            : ''
                        "
                        dense
                        v-for="plantingInfo in field.plantings"
                        :key="plantingInfo.idx"
                      >
                        <v-col>
                          <div
                            v-if="isMachine(plantingInfo)"
                            @click="handleRedirect(field)"
                            style="cursor: pointer"
                          >
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <img
                                  v-bind="attrs"
                                  v-on="on"
                                  class="mr-1 my-auto machine-icon"
                                  :src="machineIcon"
                                />
                              </template>
                              <span>
                                Crop imported from a machine source (such as
                                John Deere) or shapefile import.
                                <br />Machine crops can't be overwritten in
                                planting wizard. <br />Click to open the
                                worksheet for this field and edit manually.
                              </span>
                            </v-tooltip>
                          </div>
                          <v-tooltip v-else-if="isEstimate(plantingInfo)" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on" class="pre-gen"
                                >mdi-calculator-variant-outline</v-icon
                              >
                            </template>
                            <span
                              >Crop estimated from USDA Cropscape or prior year
                              plantings.</span
                            >
                          </v-tooltip>
                          <v-tooltip v-else-if="isUserInput(plantingInfo)" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on" class="complete"
                                >mdi-check-circle-outline</v-icon
                              >
                            </template>
                            <span>Crop was set in Cropforce.</span>
                          </v-tooltip>
                        </v-col>
                        <v-col md="2">
                          <span
                            :class="
                              plantingInfo.date.value
                                ? 'black--text'
                                : 'red--text font-weight-bold'
                            "
                          >
                            {{
                              plantingInfo.date.value
                                ? plantingInfo.date.value
                                : "No Date"
                            }}
                          </span>
                        </v-col>
                        <v-col md="2">
                          <span
                            :class="
                              plantingInfo.manufacturer.value
                                ? 'black--text'
                                : 'red--text font-weight-bold'
                            "
                          >
                            {{
                              plantingInfo.manufacturer.value
                                ? plantingInfo.manufacturer.value
                                : "No Manufacturer" | cleanSnake
                            }}
                          </span>
                        </v-col>
                        <v-col md="2">
                          <span
                            :class="
                              plantingInfo.variety.value !== null
                                ? 'black--text'
                                : 'red--text font-weight-bold'
                            "
                          >
                            {{
                              plantingInfo.variety.value !== null
                                ? plantingInfo.variety.value
                                : "No Variety"
                            }}
                          </span>
                        </v-col>
                        <v-col md="1">
                          <span class="black--text">
                            {{
                              plantingInfo.rate.value !== null
                                ? parseFloat(plantingInfo.rate.value).toFixed(2)
                                : "No Seed Rate"
                            }}
                          </span>
                        </v-col>
                        <v-col md="1">
                          <span class="black--text">
                            {{
                              plantingInfo.unit.value !== null
                                ? plantingInfo.unit.value
                                : "No Units"
                            }}
                          </span>
                        </v-col>
                        <v-col md="1">
                          <span class="black--text">
                            {{
                              plantingInfo.biological.value !== null
                                ? plantingInfo.biological.value
                                : ""
                            }}
                          </span>
                        </v-col>
                        <v-col md="2">
                          <!-- Data Complete -->
                          <div
                            class="d-flex"
                            v-if="isPlantingComplete(plantingInfo)"
                          >
                            <v-lazy>
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    color="success"
                                    >mdi-check-circle</v-icon
                                  >
                                </template>
                                <span>
                                  You've filled out enough information for Arva
                                  data models to run. Thank you!
                                </span>
                              </v-tooltip>
                            </v-lazy>
                            <span class="ml-2">Data Complete</span>
                          </div>
                          <!-- Missing Data -->
                          <div
                            class="d-flex"
                            v-else-if="isPlantingNone(plantingInfo)"
                          >
                            <v-lazy>
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon v-bind="attrs" v-on="on" color="red">
                                    mdi-alert-circle
                                  </v-icon>
                                </template>
                                <span>
                                  This field is missing data. Please fill in its
                                  data using the wizard!
                                </span>
                              </v-tooltip>
                            </v-lazy>
                            <span class="ml-2">Missing Data</span>
                          </div>
                        </v-col>
                      </v-row>
                    </td>
                    <td class="py-2" v-else>
                      <div>
                        <span class="ml-2 cursor red--text text-bold"
                          >No Planting Crop Set</span
                        >
                      </div>
                    </td>
                    <td class="cursor" @click="handleCopy(field)">
                      <span class="blue--text lighten-1 text-bold">Copy</span>
                    </td>
                    <td v-if="showPracticesView">
                      <span
                        class="py-2"
                        v-if="
                          !field.noYearData &&
                          allPlantingsComplete().length === 0
                        "
                      >
                        <v-row
                          class="mx-0"
                          :class="
                            field.plantings.indexOf(plantingInfo) !=
                            field.plantings.length - 1
                              ? 'row-bottom'
                              : ''
                          "
                          dense
                          v-for="plantingInfo in field.plantings.sort(
                            (a, b) => a.crop_id.value - b.crop_id.value
                          )"
                          :key="plantingInfo.idx"
                        >
                          <v-tooltip
                            v-if="
                              stackPractices && isAnyDuplicate(plantingInfo)
                            "
                            top
                            max-width="300"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on" color="orange"
                                >mdi-alert</v-icon
                              >
                            </template>
                            <span
                              >A duplicate practice was detected, which will not
                              be added to this field.</span
                            >
                          </v-tooltip>
                        </v-row>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- No Fields Selected Alert -->
      <AlertErrorModal
        v-if="alertErrorModalOpen"
        titleText="No Fields Selected"
        :errorMessage="[
          'You currently have no fields selected to save management practices for. Please select a field to continue.',
        ]"
        @close-modal="alertErrorModalOpen = false"
      />

      <ConfirmModal
        v-if="showSaveConfirmModal"
        titleText="Confirm Practice Replacement"
        :confirmText="saveConfirmText"
        @confirm="handleSave"
        @close-modal="showSaveConfirmModal = false"
      />
    </div>
    <div v-else>
      <v-card elevation="0">
        <v-card-text class="text-center">
          <span class="text-h4"
            >No Fields Selected. Please select a field to apply management
            practices.</span
          >
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import _ from "lodash"
import { mapActions, mapGetters, mapMutations, mapState } from "vuex"
import { Defaults, Organization } from "@/store/modules"
import {
  CROPS,
  CROP_KEY,
  CROP_DISPLAY_NAME,
  CROP_KEY_DISPLAY_NAME,
} from "@/constants"
import { CROP_DROPDOWN_OPTIONS } from "@/constants"
import { SEEDING_RATE_UNIT_CHOICES } from "@/constants/defaults"
import { MAX_DEFAULT_FIELDS } from "@/constants/defaults"
import { numberValidator } from "@/utility"
import AlertErrorModal from "@/components/modals/AlertErrorModal"
import ConfirmModal from "@/components/modals/ConfirmModal"
import WizardRedirectModal from "@/components/modals/WizardRedirectModal"
import PracticeChoiceDropdown from "@/components/dropdowns/PracticeChoiceDropdown"
import { mapPayloadKeysToUserInput } from "./utils"
import machineIcon from "@/assets/images/star-04.svg"

export default {
  name: "NewPlantingWizard",
  props: {
    selectedFields: { type: Array },
    year: { type: String },
    yearList: { type: Array },
    dataCompletionMaster: { type: Object },
  },
  components: {
    AlertErrorModal,
    WizardRedirectModal,
    PracticeChoiceDropdown,
    ConfirmModal,
  },
  data() {
    return {
      // constants
      cropDisplay: CROP_DISPLAY_NAME,
      MAX_DEFAULT_FIELDS: MAX_DEFAULT_FIELDS,
      cropChoices: Object.values(CROP_KEY).sort(),
      seedRateUnitChoices: SEEDING_RATE_UNIT_CHOICES.map(c => c.value),
      // data values
      plantingData: [],
      stackPractices: false,
      // table related
      showSelectedFields: true,
      fieldsInSelection: [],
      filterCrops: [],
      filterCropsReflection: [],
      tableFilterText: "",
      selectAllBox: true,
      numberValidator,
      // full validation for completeness
      showIncompleteModal: false,
      alertErrorModalOpen: false,
      showSaveConfirmModal: false,
      loadState: null,
      // redirection
      showRedirectModal: false,
      redirectField: null,
      // select crop type UX
      cropSelect: null,
      //   new UI
      showPracticesView: false,
      showSaveView: false,
      showSummaryView: false,
      summaryFields: null,
      failedFields: [],
      summaryKeys: null,
      summaryData: null,
      machineIcon,
    }
  },
  computed: {
    ...mapState({
      opsSettings: state => state.Defaults.newFieldSettings,
    }),
    ...mapGetters({
      recentWizardData: Defaults.Getters.getRecentWizardData,
      practiceChoices: Defaults.Getters.getPracticeChoices,
    }),
    hasPracticeData() {
      return this.plantingData.length > 0 ? true : false
    },
    dataCompletionForYear() {
      if (!this.year || this.selectedFields.length == 0) {
        return 0
      }
      // planting data exists for field, field is complete
      // incomplete if field in opsSettingsByFieldFiltered does not have 'plantings' key
      const fieldDataComps = this.opsSettingsByFieldFiltered.map(f => {
        if (f.plantings && f.plantings.length > 0) {
          return 100
        } else {
          return 0
        }
      })
      const average = array => array.reduce((a, b) => a + b) / array.length
      if (fieldDataComps.length > 0) {
        return Math.ceil(average(fieldDataComps))
      } else {
        return 0
      }
    },
    canAddPlanting() {
      if (this.stackPractices == false) {
        return this.plantingData.length < 2
      } else {
        return false
      }
    },
    fieldLocks() {
      let fieldLocks = {}
      for (const field of this.opsSettingsByField) {
        fieldLocks[field.fieldId] = field.fieldLocked
      }
      return fieldLocks
    },
    fieldDisables() {
      let fieldDisables = {}
      for (const field of this.opsSettingsByField) {
        fieldDisables[field.fieldId] = this.disableCheckbox(field)
      }
      return fieldDisables
    },
    saveConfirmText() {
      let detailText
      if (this.plantingData.length) {
        detailText = this.plantingData
          .map(d => `<em>${d.crop_id}</em>`)
          .join(" and ")
      } else {
        detailText = `<em>Fallow</em>`
      }
      return `
        Are you sure you want to replace plantings on the ${this.fieldsInSelection.length} selected fields for ${this.year}?
        <br /><br />
        Existing plantings will be removed and replaced with ${detailText}.`
    },
    opsSettingsByField() {
      let opsDict = {}
      let yearInConsideration = this.year
      Object.entries(this.opsSettings).forEach(opsDefault => {
        const fieldKey = opsDefault[0]

        const selField = this.selectedFields.find(field => field.id == fieldKey)
        if (!selField) {
          return
        }
        opsDict[fieldKey] = {}
        const fieldSettingObj = opsDefault[1]
        const idSettings = {
          fieldId: fieldKey,
          fieldName: selField.name,
          farmName: selField.farm.name,
          fieldImg: selField.boundary_image,
          opsId: fieldSettingObj.operation_id,
        }

        const hasYearData = fieldSettingObj.year_data.some(
          y => y.year == yearInConsideration
        )

        fieldSettingObj.year_data.forEach(yearData => {
          if (!hasYearData) {
            opsDict[fieldKey] = idSettings
            opsDict[fieldKey]["crops"] = ["No Data"]
            opsDict[fieldKey]["cropNames"] = ["No Data"]
            opsDict[fieldKey]["noYearData"] = true
          } else if (yearData.year == yearInConsideration) {
            opsDict[fieldKey] = idSettings
            opsDict[fieldKey]["crops"] = []
            opsDict[fieldKey]["cropNames"] = []
            opsDict[fieldKey]["cropSearch"] = ""
            opsDict[fieldKey]["plantings"] = []
            opsDict[fieldKey]["opsInfo"] = []

            opsDict[fieldKey]["isSelected"] = this.fieldsInSelection.includes(
              parseInt(fieldKey)
            )
              ? true
              : false

            const cultivations = yearData.cultivations.sort(
              (a, b) => a.arva_crop_id - b.arva_crop_id
            )
            cultivations.forEach(cultivation => {
              opsDict[fieldKey]["crops"].push(cultivation.arva_crop_id)
              opsDict[fieldKey]["cropNames"].push(
                CROP_DISPLAY_NAME[cultivation.arva_crop_id]
              )
              opsDict[fieldKey]["cropSearch"] =
                opsDict[fieldKey]["cropSearch"] +
                CROP_DISPLAY_NAME[cultivation.arva_crop_id]
              opsDict[fieldKey]["plantings"] = opsDict[fieldKey][
                "plantings"
              ].concat(cultivation.plantings)
              opsDict[fieldKey]["opsInfo"].push(cultivation.operations)

              opsDict[fieldKey]["crops"].sort()
              opsDict[fieldKey]["cropNames"].sort()
              opsDict[fieldKey]["fieldLocked"] = cultivation.year_data_locked
              opsDict[fieldKey]["noYearData"] = false
            })
          }
        })
      })
      return Object.values(opsDict)
    },
    opsSettingsByFieldFiltered() {
      // returns sorted list for table, only reduces list when filtering by name
      let arrayToReturn = _.cloneDeep(this.opsSettingsByField)

      if (this.tableFilterText) {
        const searchString = this.tableFilterText.toLocaleLowerCase()
        arrayToReturn = arrayToReturn.filter(field =>
          field.fieldName.toLowerCase().includes(searchString)
        )
      }

      if (this.cropSelect) {
        const cropSelectedArrayToReturn = arrayToReturn.filter(field => {
          const found = this.cropSelect.every(
            c => field.crops.indexOf(parseInt(c)) >= 0
          )
          return found
        })
        if (cropSelectedArrayToReturn.length > 0) {
          arrayToReturn = cropSelectedArrayToReturn
        } else {
          this.cropSelect = null
        }
      }

      const sortColumns = ["fieldName"]
      const sortDirections = ["desc", "asc"]
      return _.orderBy(arrayToReturn, sortColumns, sortDirections)
    },
    historicalPlantings() {
      const something = _(this.opsSettingsByField)
        .groupBy("crops")
        .map((items, crops) => ({
          crops: crops,
          fieldIds: _.map(items, "fieldId"),
          fieldNames: _.map(items, "fieldName"),
        }))
        .value()
      return something
    },
    yearListModified() {
      return this.yearList.filter(o => !isNaN(o))
    },
    yearPriorOne() {
      return String(Number(this.year) - 1)
    },
    yearExtendedOne() {
      return String(Number(this.year) + 1)
    },
  },
  methods: {
    ...mapActions({
      updateYear: Organization.Actions.updateYear,
      updatePlanting: Defaults.Mutations.updatePlanting,
      fetchCarbonSettingsByField: Defaults.Actions.fetchCarbonSettingsByField,
    }),
    ...mapMutations({
      setRecentWizardData: Defaults.Mutations.setRecentWizardData,
    }),
    disableCheckbox(field) {
      // if any plantings are machine, disable field from selection
      if (
        field.plantings &&
        field.plantings.some(p => p.crop_id.source == "shapefile")
      ) {
        return true
      }
    },
    isMachine(planting) {
      return planting.crop_id.source == "shapefile"
    },
    isEstimate(planting) {
      return (
        planting.crop_id.source == "cropscape" ||
        planting.crop_id.source == "ARMS_state_estimate" ||
        planting.crop_id.source == "cropscape_unavailable"
      )
    },
    isUserInput(planting) {
      return planting.crop_id.source == "User Input"
    },
    fieldChecked(field, e) {
      if (
        this.fieldsInSelection.includes(parseInt(field.fieldId)) &&
        e == false
      ) {
        this.fieldsInSelection = this.fieldsInSelection.filter(
          f => f != parseInt(field.fieldId)
        )
      }
      if (e == true) {
        this.fieldsInSelection.push(parseInt(field.fieldId))
      }
    },
    isPlantingComplete(planting) {
      return planting.crop_id
    },
    isPlantingInFuture(planting) {
      return new Date(planting.date) > new Date()
    },
    allPlantingsComplete() {
      if (!this.plantingData) return [0]
      const appCompleteness = this.plantingData.map(planting =>
        this.isPlantingComplete(planting)
      )
      const appsMissing = appCompleteness.map((value, index) =>
        value == null || value == false ? index : ""
      )
      const indexOfMissing = appsMissing.filter(String)
      return indexOfMissing
    },
    handleRemovePlanting(appIndex) {
      this.plantingData.splice(appIndex, 1)
      if (this.plantingData.length == 0) {
        this.stackPractices = false
      }
    },
    handleAddPracticesClick() {
      if (this.showPracticesView == false) {
        this.showSaveView = false
        this.showSummaryView = false
        this.showPracticesView = true

        const newPlantingData = this.blankPlantingData()
        if (this.cropSelect && this.cropSelect.length == 1) {
          newPlantingData.crop_id = CROP_KEY[parseInt(this.cropSelect[0])]
        }
        this.plantingData = [newPlantingData]
      }
    },
    handleCancelClick() {
      this.showSaveView = false
      this.showSummaryView = false
      this.showPracticesView = false
      this.plantingData = []
    },
    blankPlantingData(cropValue = null) {
      return {
        crop_id: null,
        date: null,
        rate: null,
        unit: null,
        manufacturer: null,
        variety: null,
        biological: null,
        // fields not set by UI
        seeds_per_bag: null,
        monitor_type: null,
        cost_per_bag: null,
        // for UI control
        showAllInfo: true,
        menu: false, // boolean for v-menu
        pickerDate: this.year + "-01",
      }
    },
    handleAddEventClick() {
      if (this.plantingData.length <= 2) {
        this.plantingData.push(this.blankPlantingData())
      }
    },
    handleCropFilterButton(sharedCropPlan) {
      if (
        JSON.stringify(this.cropSelect) ==
        JSON.stringify(sharedCropPlan.crops.split(","))
      ) {
        this.cropSelect = null
        this.filterCrops = []
      } else {
        this.cropSelect = sharedCropPlan.crops.split(",")
        this.filterCrops = [this.mapCropNames(sharedCropPlan.crops)]
        if (this.plantingData.length > 0) {
          const cropValue =
            this.cropSelect != null && this.cropSelect.length == 1
              ? CROP_KEY[parseInt(this.cropSelect[0])]
              : null
          this.plantingData.forEach(o => {
            o.crop_id = cropValue
          })
        }
      }
    },
    isAnyDuplicate(existingPlanting) {
      for (const newPlanting of this.plantingData) {
        if (
          this.isPlantingDuplicate(
            existingPlanting,
            mapPayloadKeysToUserInput(newPlanting)
          )
        ) {
          return true
        }
      }
      return false
    },
    isPlantingDuplicate(planting1, planting2) {
      return planting1.crop_id.value == planting2.crop_id.value
    },
    isPlantingNone(planting) {
      return planting.crop_id == "fallow"
    },
    mergeFieldPayload(field, plantingPayload) {
      if (this.plantingData.length == 0 || !this.stackPractices) {
        return plantingPayload
      }
      let fieldplantingData = this.extractPlantingData(field)
      fieldplantingData = fieldplantingData.filter(
        cp => !this.isPlantingNone(cp)
      )
      let mappedData = fieldplantingData.map(cp => {
        return mapPayloadKeysToUserInput(cp)
      })

      mappedData = mappedData.filter(
        existingPractice =>
          !plantingPayload.some(newPractice =>
            this.isPlantingDuplicate(existingPractice, newPractice)
          )
      )

      const mergedPayload = plantingPayload.concat(mappedData)
      return mergedPayload
    },
    handleCopy(field) {
      this.cropSelect = null
      this.plantingData = this.extractPlantingData(field)

      this.showSummaryView = false
      this.showPracticesView = true

      const myElement = document.getElementById("input-card-container")
      myElement.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      })
    },
    extractPlantingData(field) {
      let fieldPlanting = field.plantings

      if (!fieldPlanting) {
        return []
      }

      let copiedPlantingData = []
      fieldPlanting.forEach(planting => {
        copiedPlantingData.push({
          crop_id: CROP_KEY[planting.crop_id.value],
          date: planting.date.value,
          rate: planting.rate.value,
          unit: planting.unit.value,
          manufacturer: planting.manufacturer.value,
          variety: planting.variety.value,
          biological: planting.biological.value,
          seeds_per_bag: planting.seeds_per_bag.value,
          monitor_type: planting.monitor_type.value,
          cost_per_bag: planting.cost_per_bag.value,
          showAllInfo: true,
          menu: false,
          pickerDate: this.year + "-01",
        })
      })
      return copiedPlantingData
    },
    handlePreSave() {
      if (this.stackPractices) {
        this.handleSave()
      } else {
        this.showSaveConfirmModal = true
      }
    },
    async handleSave() {
      this.showSaveConfirmModal = false
      if (this.fieldsInSelection.length == 0) {
        this.alertErrorModalOpen = true
        return
      }

      const completeness = this.plantingData.map(planting =>
        this.isPlantingComplete(planting)
      )
      const missing = completeness.map((value, index) =>
        value == null ? index : ""
      )
      const indexOfMissing = missing.filter(String)

      // find which plantings are missing info
      if (indexOfMissing.length > 0) {
        this.incompleteApplications = indexOfMissing
        this.showIncompleteModal = true
        return
      } else {
        // save to db
        let dataClone = _.cloneDeep(this.plantingData)
        let plantingPayload = null
        if (this.plantingData.length > 0) {
          plantingPayload = dataClone.map(planting => {
            // remove showAllInfo and menu to prevent issues with replace_existing in the backend
            delete planting["showAllInfo"]
            delete planting["menu"]
            delete planting["pickerDate"]

            planting = mapPayloadKeysToUserInput(planting)

            return planting
          })
        } else {
          plantingPayload = [
            {
              crop_id: { value: CROPS.Fallow, source: "User Input" },
              date: { value: this.year + "-01-01", source: "User Input" },
              rate: { value: null, source: "User Input" },
              unit: { value: null, source: "User Input" },
              manufacturer: { value: null, source: "User Input" },
              variety: { value: null, source: "User Input" },
              biological: { value: null, source: "User Input" },
              // fields not set by UI
              seeds_per_bag: { value: null, source: "User Input" },
              monitor_type: { value: null, source: "User Input" },
              cost_per_bag: { value: null, source: "User Input" },
            },
          ]
        }

        this.showPracticesView = false
        this.showSaveView = true

        const updatedFieldIds = []
        let updates = []
        this.opsSettingsByFieldFiltered.forEach(field => {
          if (this.fieldsInSelection.includes(parseInt(field.fieldId))) {
            if (!field.fieldLocked) {
              updatedFieldIds.push(parseInt(field.fieldId))
              updates.push({
                fieldId: field.fieldId,
                promise: this.updatePlanting({
                  fieldId: field.fieldId,
                  year: this.year,
                  plantingPayload: this.mergeFieldPayload(
                    field,
                    plantingPayload
                  ),
                }),
              })
            }
          }
        })
        const results = await Promise.allSettled(updates.map(u => u.promise))

        // unlike the other wizards, which update the client side field data in their update handlers
        //  (without actually checking the results from the server)
        // setting the crop + planting data in the "new settings" would be very complicated,
        // and it's better to confirm that everything was saved correctly
        await this.fetchCarbonSettingsByField({
          fieldIds: updatedFieldIds,
          checkExistingState: false,
        })

        const failedFields = updates
          .filter((item, index) => results[index].status === "rejected")
          .map(item => item.fieldId)
        const successFieldNames = this.opsSettingsByFieldFiltered
          .filter(f => this.fieldsInSelection.includes(parseInt(f.fieldId)))
          .filter(f => !failedFields.includes(f.fieldId))
          .map(f => f.fieldName)
        const failedFieldNames = this.opsSettingsByFieldFiltered
          .filter(f => this.fieldsInSelection.includes(parseInt(f.fieldId)))
          .filter(f => failedFields.includes(f.fieldId))
          .map(f => f.fieldName)

        const wantedKeys = [
          "crop_id",
          "date",
          "rate",
          "unit",
          "manufacturer",
          "variety",
          "biological",
        ]
        this.setRecentWizardData({
          wizard: "planting",
          data: this.plantingData,
        })

        this.summaryKeys = wantedKeys
        this.summaryData = plantingPayload
        this.summaryFields = successFieldNames
        this.failedFields = failedFieldNames
        this.showSaveView = false
        this.showSummaryView = true
        const cropValue =
          this.cropSelect != null && this.cropSelect.length == 1
            ? CROP_KEY[parseInt(this.cropSelect[0])]
            : null
        this.plantingData = [this.blankPlantingData(cropValue)]
        this.stackPractices = false

        this.$emit("update-data-complete")
      }
    },
    handleSeasonSelection(newYear) {
      this.updateYear(newYear)
    },
    handleSelectAll(e, resetCropSelect = false) {
      if (e == false) this.fieldsInSelection = []
      if (e == true) {
        this.fieldsInSelection = this.selectedFields
          .map(f => f.id)
          .filter(f => !this.fieldLocks[f])
          .filter(f => !this.fieldDisables[f])
      }
      if (resetCropSelect) {
        this.cropSelect = null
      }
    },
    handleRedirect(field) {
      this.$router.push(`/data-spreadsheet/${field.fieldId}`)
    },
    handleNoPlantingClick() {
      this.plantingData = []
      this.stackPractices = false
    },
    handleYesPlantingClick() {
      this.stackPractices = false
      this.plantingData = [this.blankPlantingData()]
    },
    mapCropNames(cropIdString) {
      if (cropIdString == "No Data") {
        return cropIdString
      } else if (cropIdString.includes(",")) {
        const cropIdVals = cropIdString.split(",")
        return cropIdVals.map(v => this.cropDisplay[v]).join(", ")
      } else {
        return this.cropDisplay[cropIdString]
      }
    },
    handleAddPlanting() {
      this.showSummaryView = false
      this.showPracticesView = true
    },
  },
  mounted() {
    this.fieldsInSelection = this.selectedFields
      .map(f => f.id)
      .filter(f => !this.fieldLocks[f])
      .filter(f => !this.fieldDisables[f])
  },
  watch: {
    filterCrops: {
      deep: true,
      handler(myArray) {
        this.filterCropsReflection = _.cloneDeep(myArray)

        let arrayToFilter = _.cloneDeep(this.opsSettingsByField)

        if (this.filterCropsReflection.length > 0) {
          let cropsToFilter = _.flatten(
            this.filterCropsReflection.map(i => {
              return i.split(", ")
            })
          )
          arrayToFilter = arrayToFilter.filter(field =>
            cropsToFilter.every(c => field.cropNames.indexOf(c) >= 0)
          )
        }

        this.fieldsInSelection = arrayToFilter
          .map(f => parseInt(f.fieldId))
          .filter(f => !this.fieldLocks[f])
          .filter(f => !this.fieldDisables[f])
      },
    },
  },
}
</script>
<style scoped>
.min-step-height {
  min-height: 400px;
}

.row-margin-correct {
  margin-left: 0px;
  margin-right: 0px;
}

.text-container {
  width: 200px;
}

.three {
  width: 3%;
}

.ten {
  width: 10%;
}

.cursor {
  cursor: pointer;
}

.pre-gen {
  color: #ffc83f;
}
.machine {
  color: #38c3e2;
}

.machine-icon {
  width: 22px;
  height: 22px;
}

.complete {
  color: #7de266;
}
</style>
