import API from "./API"

const endpoint = "practice-confirmations"

export const getEvidencingByFieldIds = (params) =>
  API.post(`/${endpoint}/get_evidencing_by_field_ids/`, params);

export const modifyEvidencingNotes = (params) =>
  API.post(`/${endpoint}/modify_notes/`, params);

export const fetchFieldCrops = (params) => 
  API.post(`/${endpoint}/fetch_field_crops/`, params);

export const createClientBasedGroups = (params) => 
  API.post(`/${endpoint}/create_client_based_evidencing_groups/`, params);

export const fetchClientBasedGroups = (params) => 
  API.post(`/${endpoint}/fetch_org_node_evidencing_groups_overview/`, params);

export const fetchGroupData = (params) => 
  API.post(`/${endpoint}/fetch_group_data/`, params);

export const fetchGroupUploads = (params) => 
  API.post(`/${endpoint}/fetch_group_uploads/`, params);

export const deleteEvidencingGroup = (params) => 
  API.post(`/${endpoint}/delete_evidencing_group/`, params);

export const updateGroup = (params) =>
  API.post(`/${endpoint}/update_group/`, params);

export const getEvidencingByOrg = (params) =>
  API.post(`/${endpoint}/get_evidencing_by_org/`, params);

export const createEvidencingUploadReview = (params) =>
  API.post(`/${endpoint}/create_evidencing_upload_review/`, params);

export const createEvidencingMessage = (params) => 
  API.post(`${endpoint}/create_evidencing_message/`, params);

export const modifyUploadEvidencingRelationships = (params) =>
  API.post(`${endpoint}/modify_upload_evidencing_relationships/`, params);