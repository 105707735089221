<template>
  <div class="upload-row">
    <div class="d-flex align-items-center">
      <input
        ref="orgSelectedCheckbox"
        type="checkbox"
        name="selected"
        @click="checkboxClicked"
      />
      <div class="divider" />
      <div class="content">
        <div>{{ account.name }}</div>
        <div v-if="account.climate_access_enabled" class="icon enabled">
          <i class="fa fa-check-circle" />
        </div>
        <div v-if="!account.climate_access_enabled" class="icon disabled">
          <i class="fa fa-minus-circle" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ClimateIntegrationRow",
  props: {
    account: { type: Object },
    orgs: { type: Array },
  },
  data() {
    return {
      isChecked: false,
    };
  },
  computed: {},
  methods: {
    sendCheckEvents(checkbox) {
      this.isChecked = checkbox.checked;
      if (this.isChecked) this.$emit("checked", this.account);
      else this.$emit("unchecked", this.account);
    },

    checkboxClicked() {
      const checkbox = this.$refs.orgSelectedCheckbox;
      this.sendCheckEvents(checkbox);
    },
  },
};
</script>
<style scoped>
.upload-row {
  position: relative;
  padding: 18px;
  border-top: 1pt solid #d5d5d5;
}

.upload-row:hover {
  background: #fafafa;
}

.divider {
  height: 40px;
  width: 1px;
  border-left: 1pt solid #e0e0e0;
  display: block;
  margin: auto 18px;
}

.content {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon {
  margin-left: 10px;
  font-weight: 500;
  font-size: 16px;
}

.enabled {
  color: #00a200;
}

.disabled {
  color: #a20000;
}
</style>
