<template>
  <span v-show="count > 0" class="badge badge-pill badge-success">{{
    count
  }}</span>
</template>

<script>
export default {
  name: "CountBadge",
  props: {
    count: {
      type: Number,
    },
  },
}
</script>

<style scoped>
.badge {
  position: absolute;
  top: 10px;
}
</style>
