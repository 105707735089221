<template>
  <tr>
    <td>
      <v-checkbox
        v-model="field.is_shared"
        :disabled="!isEditable"
        @click="sendCheckEvents"
        name="selected"
        ref="fieldSelectedCheckbox"
      />
    </td>
    <td>{{ field.field_name }}</td>
    <td>{{ field.farm_name }}</td>
    <td>{{ field.client_name }}</td>
    <td>{{ field.org_node_name }}</td>
  </tr>
</template>

<script>
export default {
  name: "SharingTransactionRow",

  props: ["field", "isEditable"],

  data() {
    return {
      fieldSelectedCheckbox: false,
    }
  },

  computed: {},

  methods: {
    sendCheckEvents() {
      this.$emit("checked", this.field.field_id)
    },

    setCheckbox(checked) {
      if (this.field.is_shared !== checked) this.sendCheckEvents()
      this.field.is_shared = checked
    },
  },
}
</script>

<style scoped></style>
