<template>
  <div>
    <div v-if="selectedFields.length > MAX_DEFAULT_FIELDS">
      <v-card elevation="0">
        <v-card-text class="text-center">
          <span class="text-h4"
            >Please select fewer than {{ MAX_DEFAULT_FIELDS }} fields using the
            top navigation tools.</span
          >
        </v-card-text>
      </v-card>
    </div>
    <div v-else-if="selectedFields.length > 0">
      <div id="input-card-container">
        <v-card
          class="my-auto mx-auto"
          v-if="showPracticesView || showSaveView || showSummaryView"
        >
          <v-card-text class="min-step-height" v-if="showPracticesView">
            <v-row class="mx-1 mt-3">
              <div class="text-h5">Add Liming for {{ year }}</div>
              <v-btn class="ml-auto" @click="handleCancelClick()">
                <v-icon>mdi-close</v-icon> Cancel
              </v-btn>
              <v-btn
                class="ml-4"
                color="green lighten-4"
                :disabled="allLimingsComplete().length > 0"
                @click="handlePreSave()"
                >Save and Continue</v-btn
              >
            </v-row>

            <v-row class="mx-1 mt-7">
              <v-btn-toggle
                v-model="hasPracticeData"
                readonly
                divided
                dense
                mandatory
                active-class="light-blue lighten-4"
              >
                <v-btn :value="true" class="px-3" @click="handleYesLimingClick">
                  Applied Lime <v-icon>mdi-check-bold</v-icon>
                </v-btn>
                <v-btn :value="false" class="px-3" @click="handleNoLimingClick">
                  No Liming <v-icon>mdi-close-thick</v-icon>
                </v-btn>
              </v-btn-toggle>

              <v-btn-toggle
                class="ml-5"
                v-model="stackPractices"
                divided
                dense
                active-class="light-blue lighten-4"
                mandatory
              >
                <v-btn :value="true" :disabled="!hasPracticeData" class="px-3"
                  >Stack Practices</v-btn
                >
                <v-btn :value="false" class="px-3">Replace Practices</v-btn>
              </v-btn-toggle>
            </v-row>

            <v-row v-if="limingData.length > 0">
              <v-col md="12">
                <v-card
                  v-for="liming in limingData"
                  :key="liming.idx"
                  class="mt-3"
                  elevation="6"
                >
                  <v-card-title
                    class="cursor blue-grey lighten-5"
                    @click="liming.showAllInfo = !liming.showAllInfo"
                  >
                    Liming #{{ limingData.indexOf(liming) + 1 }}
                    <v-icon
                      v-if="!liming.showAllInfo"
                      @click="liming.showAllInfo = true"
                      >mdi-chevron-right</v-icon
                    >
                    <v-icon v-else @click="liming.showAllInfo = false"
                      >mdi-chevron-down</v-icon
                    >
                    <AudioRecorder
                      model="liming"
                      :practiceIndex="limingData.indexOf(liming)"
                      @practice-response="handleSpeechToPractice"
                      :year="Number(year)"
                    />

                    <v-tooltip top
                      ><template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          class="ml-auto"
                          :color="
                            isLimingComplete(liming)
                              ? 'green accent-4'
                              : 'red darken-1'
                          "
                        >
                          {{
                            isLimingComplete(liming)
                              ? "mdi-check-circle-outline"
                              : "mdi-alert-circle-outline"
                          }}
                        </v-icon> </template
                      ><span>{{
                        isLimingComplete(liming)
                          ? "Liming is good to go!"
                          : "You're missing a few fields, please complete this liming to save and continue!"
                      }}</span></v-tooltip
                    >
                    <v-btn
                      class="ml-2"
                      @click.stop="
                        handleRemoveLiming(limingData.indexOf(liming))
                      "
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text v-if="liming.showAllInfo">
                    <v-row align="end">
                      <v-col md="4">
                        <div class="mt-3 mb-2">
                          <b
                            >On which crop was this applied?<span
                              class="red--text"
                              >*</span
                            ></b
                          >
                        </div>
                        <div class="input-holder">
                          <v-select
                            dense
                            outlined
                            hide-details
                            label="Crop"
                            :disabled="
                              cropSelect ? cropSelect.length == 1 : false
                            "
                            :items="cropChoicesFiltered"
                            v-model="liming.crop_id"
                            clearable
                          >
                          </v-select>
                        </div>
                      </v-col>
                      <v-col md="4">
                        <div class="mt-3 mb-2">
                          <b>Liming Date<span class="red--text">*</span></b>
                        </div>
                        <div class="input-holder">
                          <v-menu
                            v-model="liming.menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="liming.date"
                                label="Liming Date"
                                dense
                                outlined
                                readonly
                                hide-details
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="liming.date"
                              :picker-date.sync="liming.pickerDate"
                              :min="yearPriorOne + '-06-01'"
                              :max="yearExtendedOne + '-12-31'"
                              @input="liming.menu = false"
                              scrollable
                            ></v-date-picker>
                          </v-menu>
                        </div>
                      </v-col>
                    </v-row>
                    <!-- Type and Amount -->
                    <v-row align="end">
                      <v-col md="4">
                        <div class="mb-2">
                          <b class="text-h7">
                            Describe your liming type:<span class="red--text"
                              >*</span
                            >
                          </b>
                        </div>
                        <div class="input-holder">
                          <PracticeChoiceDropdown
                            dense
                            outlined
                            hide-details
                            label="Type"
                            :items="limingChoices"
                            item-text="display_name"
                            item-value="value"
                            v-model="liming.type"
                            choiceName="liming_type"
                            clearable
                          />
                        </div>
                      </v-col>
                      <v-col md="4">
                        <div class="mb-2">
                          <b class="text-h7"
                            >How much lime did you apply (in tons)?</b
                          >
                        </div>
                        <div class="input-holder">
                          <v-text-field
                            dense
                            outlined
                            hide-details
                            clearable
                            label="Liming Amount"
                            suffix="tons"
                            v-model="liming.amount"
                            :rules="[numberValidator]"
                            type="number"
                          ></v-text-field>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-btn class="mt-4" block @click="handleAddEventClick"
                  >Add Liming Event<v-icon>mdi-plus</v-icon></v-btn
                >
              </v-col>
            </v-row>
            <v-row v-if="limingData.length == 0">
              <v-card class="mx-auto mt-4" elevation="0">
                <v-card-text>
                  <div class="text-center">
                    <b class="text-h5 text-bold"
                      >No Lime Applied for {{ this.year }}</b
                    >
                  </div>
                </v-card-text>
              </v-card>
            </v-row>
          </v-card-text>
          <!-- Save Spinner View -->
          <v-card-text class="min-step-height text-center" v-if="showSaveView">
            <v-progress-circular
              class="mt-4"
              :size="100"
              :width="8"
              color="green"
              indeterminate
            >
            </v-progress-circular>
            <div>
              <h3>Saving your liming information</h3>
            </div>
          </v-card-text>
          <!-- Summary View -->
          <v-card-text class="min-step-height" v-if="showSummaryView">
            <div class="d-flex">
              <h3>Fields Updated</h3>
              <v-btn class="ml-auto" @click="handleAddMoreLiming">
                Add More Liming
              </v-btn>
            </div>
            <div class="d-flex">
              <v-chip
                class="mx-2"
                v-for="fieldName in summaryFields"
                :key="fieldName.idx"
                >{{ fieldName }}</v-chip
              >
            </div>
            <br />
            <h3 v-if="failedFields.length > 0">Some Fields Failed to Save</h3>
            <div v-if="failedFields.length > 0" class="d-flex">
              <v-chip
                color="red lighten-3"
                class="mx-2"
                v-for="fieldName in failedFields"
                :key="fieldName.idx"
                >{{ fieldName }}</v-chip
              >
            </div>
            <br />
            <h3>Liming Data Submitted</h3>
            <div>
              <v-simple-table class="mx-4 mb-4">
                <thead>
                  <tr>
                    <th v-for="key in summaryKeys" :key="key.idx">
                      {{ key == "crop_id" ? "Crop" : key | cleanSnake }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="data in summaryData" :key="data.idx">
                    <td v-for="key in summaryKeys" :key="key.idx">
                      <span v-if="key == 'crop_id'">{{
                        cropDisplay[data[key].value]
                      }}</span>
                      <span v-else>
                        {{
                          data[key].value
                            ? data[key].value
                            : "None" | cleanSnake
                        }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>
          </v-card-text>
        </v-card>
      </div>

      <!-- Fields, Data Comp, and Add Practices -->
      <v-row
        class="mt-4 mb-2 row-margin-correct"
        v-if="!showPracticesView && !showSaveView"
      >
        <div>
          <span class="text-h5 ml-1"
            >{{ opsSettingsByField.length }} fields</span
          >
          <span class="text-h5 ml-2"
            >({{ dataCompletionForYear }}% Complete)</span
          >
        </div>
        <div class="ml-auto d-flex">
          <div>
            <v-btn
              v-for="y in yearListModified"
              :key="y.idx"
              :color="
                parseInt(y) === parseInt(year) ? 'blue-grey lighten-4' : 'white'
              "
              elevation="0"
              tile
              dense
              class="mx-1 py-1"
              @click="handleSeasonSelection(y)"
            >
              {{ y }}
            </v-btn>
          </div>
          <div class="text-container ml-2">
            <v-text-field
              outlined
              dense
              hide-details
              v-model="tableFilterText"
              label="Filter Fields"
            ></v-text-field>
          </div>
          <div class="ml-2">
            <v-btn
              v-if="
                showPracticesView === false &&
                showSaveView === false &&
                showSummaryView === false
              "
              class="white--text"
              color="green"
              x-large
              :disabled="fieldsInSelection.length === 0"
              @click="handleAddPracticesClick"
              >Add Practices</v-btn
            >
          </div>
        </div>
      </v-row>
      <v-row class="mt-4 mb-2 row-margin-correct" v-else>
        <div>
          <span class="text-button ml-1">Receiving fields</span>
        </div>
      </v-row>

      <!-- Quick Filtering Buttons -->
      <v-row class="mt-4 row-margin-correct">
        <v-btn
          dense
          tile
          :color="cropSelect == null ? 'blue-grey lighten-4' : 'white'"
          @click="handleSelectAll(true, true)"
          class="px-2 mx-2 py-1 text-none letter-spacing-0"
          elevation="0"
        >
          All ({{ opsSettingsByField.length }})
        </v-btn>
        <v-btn
          v-for="sharedCropPlan in historicalPlantings"
          :key="sharedCropPlan.idx"
          dense
          tile
          :color="
            JSON.stringify(cropSelect) ==
            JSON.stringify(sharedCropPlan.crops.split(','))
              ? 'blue-grey lighten-4'
              : 'white'
          "
          @click="handleCropFilterButton(sharedCropPlan)"
          class="px-2 mx-2 py-1 text-none"
          elevation="0"
        >
          {{ mapCropNames(sharedCropPlan.crops) }} ({{
            sharedCropPlan.fieldIds.length
          }})
        </v-btn>
      </v-row>

      <!-- Field Filtering Table -->
      <v-row class="pt-3">
        <v-col>
          <v-card>
            <v-card-text v-if="showSelectedFields">
              <v-simple-table>
                <thead>
                  <tr>
                    <th class="three">
                      <div class="d-flex">
                        <v-simple-checkbox
                          color="blue"
                          v-model="selectAllBox"
                          @input="handleSelectAll($event)"
                        ></v-simple-checkbox>
                      </div>
                    </th>
                    <th class="ten">Name</th>
                    <th class="three">Farm</th>
                    <th class="three">Locked</th>
                    <th class="three">Crops</th>
                    <th>Liming</th>
                    <th class="three">Copy</th>
                    <th v-if="showPracticesView">Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="field in opsSettingsByFieldFiltered"
                    :key="field.idx"
                  >
                    <td>
                      <v-simple-checkbox
                        color="blue"
                        :value="
                          fieldsInSelection.includes(parseInt(field.fieldId))
                        "
                        :disabled="
                          disableCheckbox(field.noYearData) || field.fieldLocked
                        "
                        @input="fieldChecked(field, $event)"
                      ></v-simple-checkbox>
                    </td>
                    <td>{{ field.fieldName }}</td>
                    <td class="overflow-hidden">{{ field.farmName }}</td>
                    <td>
                      <v-icon
                        size="20"
                        v-if="field.fieldLocked"
                        :color="field.fieldLocked ? 'red' : 'green'"
                      >
                        {{ field.fieldLocked ? "mdi-lock" : "mdi-lock-open" }}
                      </v-icon>
                    </td>
                    <td v-if="'cropNames' in field">
                      <div v-for="crop in field.cropNames" :key="crop">
                        {{ crop }}
                      </div>
                    </td>
                    <td v-else>
                      <div>No Crop</div>
                    </td>
                    <td class="py-2" v-if="!field.noYearData">
                      <div
                        v-for="opsSingle in field.opsInfo"
                        :key="opsSingle.idx"
                      >
                        <v-row
                          class="mx-0"
                          :class="
                            opsSingle.limings.indexOf(limingInfo) !=
                            opsSingle.length - 1
                              ? 'row-bottom'
                              : ''
                          "
                          dense
                          v-for="limingInfo in opsSingle.limings.sort(
                            (a, b) => a.crop_id.value - b.crop_id.value
                          )"
                          :key="limingInfo.idx"
                        >
                          <v-col md="2">
                            <span
                              :class="
                                limingInfo.date.value
                                  ? 'black--text'
                                  : 'red--text font-weight-bold'
                              "
                            >
                              {{
                                limingInfo.date.value
                                  ? limingInfo.date.value
                                  : "No Date"
                              }}
                            </span>
                          </v-col>
                          <v-col md="3">
                            <span
                              :class="
                                limingInfo.type.value
                                  ? 'black--text'
                                  : 'red--text font-weight-bold'
                              "
                            >
                              {{
                                limingInfo.type.value
                                  ? limingInfo.type.value
                                  : "No Type" | cleanSnake
                              }}
                            </span>
                          </v-col>
                          <v-col md="4">
                            <span
                              :class="
                                limingInfo.amount.value !== null
                                  ? 'black--text'
                                  : 'red--text font-weight-bold'
                              "
                            >
                              {{
                                limingInfo.amount.value !== null
                                  ? limingInfo.amount.value
                                  : "No Amount"
                              }}
                              {{
                                limingInfo.amount.value !== null ? "tons" : ""
                              }}
                            </span>
                          </v-col>
                          <v-col md="3">
                            <!-- Data Complete -->
                            <div
                              class="d-flex"
                              v-if="limingComplete(limingInfo)"
                            >
                              <v-lazy>
                                <v-tooltip top
                                  ><template v-slot:activator="{ on, attrs }"
                                    ><v-icon
                                      v-bind="attrs"
                                      v-on="on"
                                      color="success"
                                      >mdi-check-circle</v-icon
                                    ></template
                                  ><span
                                    >You've filled out enough information for
                                    Arva data models to run. Thank you!</span
                                  ></v-tooltip
                                >
                              </v-lazy>
                              <span class="ml-2">Data Complete</span>
                            </div>
                            <!-- Needs Confirmation -->
                            <div
                              class="d-flex"
                              v-else-if="limingNeedsConfirm(limingInfo)"
                            >
                              <v-lazy>
                                <v-tooltip top
                                  ><template v-slot:activator="{ on, attrs }"
                                    ><v-icon
                                      v-bind="attrs"
                                      v-on="on"
                                      color="yellow"
                                      >mdi-alert-rhombus</v-icon
                                    ></template
                                  ><span
                                    >This information is coming from a public
                                    source. Please include this field when
                                    setting information to confirm it's
                                    data!</span
                                  ></v-tooltip
                                >
                              </v-lazy>
                              <span
                                class="ml-2 cursor blue--text lighten-1 text-bold"
                                @click="handleConfirm(field)"
                                >Confirm Data</span
                              >
                            </div>
                            <!-- Missing Data -->
                            <div
                              class="d-flex"
                              v-else-if="limingMissingData(limingInfo)"
                            >
                              <v-lazy>
                                <v-tooltip top
                                  ><template v-slot:activator="{ on, attrs }"
                                    ><v-icon
                                      v-bind="attrs"
                                      v-on="on"
                                      color="red"
                                      >mdi-alert-circle</v-icon
                                    ></template
                                  ><span
                                    >This field is missing data. Please fill in
                                    its data using the wizard!</span
                                  ></v-tooltip
                                >
                              </v-lazy>
                              <span class="ml-2">Missing Data</span>
                            </div>
                          </v-col>
                        </v-row>
                      </div>
                    </td>
                    <td class="py-2" v-else>
                      <div @click="handleShowRedirect(field)">
                        <v-lazy>
                          <v-tooltip top
                            ><template v-slot:activator="{ on, attrs }"
                              ><v-icon v-bind="attrs" v-on="on" color="red"
                                >mdi-alert-circle</v-icon
                              ></template
                            ><span
                              >This field doesn't have data for this year. Add
                              another year in the data spreadsheet or upload
                              data from this year.</span
                            ></v-tooltip
                          >
                        </v-lazy>
                        <span class="ml-2 cursor red--text text-bold"
                          >No Data for Year</span
                        >
                      </div>
                    </td>
                    <td class="cursor" @click="handleCopy(field)">
                      <span class="blue--text lighten-1 text-bold">Copy</span>
                    </td>
                    <td v-if="showPracticesView">
                      <span
                        class="py-2"
                        v-if="
                          !field.noYearData && allLimingsComplete().length === 0
                        "
                      >
                        <div
                          v-for="opsSingle in field.opsInfo"
                          :key="opsSingle.idx"
                        >
                          <v-row
                            class="mx-0"
                            :class="
                              opsSingle.limings.indexOf(limingInfo) !=
                              opsSingle.length - 1
                                ? 'row-bottom'
                                : ''
                            "
                            dense
                            v-for="limingInfo in opsSingle.limings.sort(
                              (a, b) => a.crop_id.value - b.crop_id.value
                            )"
                            :key="limingInfo.idx"
                          >
                            <v-tooltip
                              v-if="
                                stackPractices && isAnyDuplicate(limingInfo)
                              "
                              top
                              max-width="300"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" color="orange"
                                  >mdi-alert</v-icon
                                >
                              </template>
                              <span
                                >Can not stack duplicate practice on this
                                field.</span
                              >
                            </v-tooltip>
                            <v-tooltip
                              v-if="isUntargetedCrop(limingInfo)"
                              top
                              max-width="300"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" color="orange"
                                  >mdi-selection-ellipse-remove</v-icon
                                >
                              </template>
                              <span
                                >No practice data is currently being added for
                                {{
                                  cropDisplay[limingInfo.crop_id.value]
                                }}.</span
                              >
                            </v-tooltip>
                          </v-row>
                        </div>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- No Fields Selected Alert -->
      <AlertErrorModal
        v-if="alertErrorModalOpen"
        titleText="No Fields Selected"
        :errorMessage="[
          'You currently have no fields selected to save management practices for. Please select a field to continue.',
        ]"
        @close-modal="alertErrorModalOpen = false"
      />

      <!-- Wizard Confirm Modal -->
      <WizardConfirmModal
        v-if="showConfirmModal"
        :confirmationField="confirmationField"
        :confirmationData="confirmationLimings"
        :wantedKeys="['crop_id', 'date', 'type', 'amount']"
        :year="year"
        :loadState="loadState"
        @close-template-modal="handleCloseConfirm"
        @confirm-data="handleModalConfirm"
      />

      <ConfirmModal
        v-if="showSaveConfirmModal"
        titleText="Confirm Practice Replacement"
        :confirmText="saveConfirmText"
        @confirm="handleSave"
        @close-modal="showSaveConfirmModal = false"
      />

      <!-- Redirect -->
      <WizardRedirectModal
        v-if="showRedirectModal"
        :redirectField="redirectField"
        :year="year"
        @close-template-modal="handleCloseRedirect"
        @redirect-confirm="handleRedirectConfirm"
      />
    </div>
    <div v-else>
      <v-card elevation="0">
        <v-card-text class="text-center">
          <span class="text-h4"
            >No Fields Selected. Please select a field to apply management
            practices.</span
          >
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import _ from "lodash"
import { mapActions, mapGetters, mapMutations, mapState } from "vuex"
import { Defaults, Organization } from "@/store/modules"
import { CROP_KEY, CROP_DISPLAY_NAME, CROP_ID_KEY } from "@/constants"
import { MAX_DEFAULT_FIELDS } from "@/constants/defaults"
import { numberValidator } from "@/utility"
import AlertErrorModal from "@/components/modals/AlertErrorModal"
import WizardRedirectModal from "@/components/modals/WizardRedirectModal"
import ConfirmModal from "@/components/modals/ConfirmModal"
import PracticeChoiceDropdown from "@/components/dropdowns/PracticeChoiceDropdown"
import { dupeCheck, mapPayloadKeysToUserInput } from "./utils"
import AudioRecorder from "./AudioRecorder.vue"
import Vue from "vue"

export default {
  name: "NewLimingWizard",
  props: {
    selectedFields: { type: Array },
    year: { type: String },
    yearList: { type: Array },
    dataCompletionMaster: { type: Object },
  },
  components: {
    AlertErrorModal,
    WizardRedirectModal,
    PracticeChoiceDropdown,
    ConfirmModal,
    AudioRecorder,
  },
  data() {
    return {
      // constants
      cropDisplay: CROP_DISPLAY_NAME,
      MAX_DEFAULT_FIELDS: MAX_DEFAULT_FIELDS,

      // data values
      limingData: [],
      stackPractices: true,
      // table related
      showSelectedFields: true,
      fieldsInSelection: [],
      filterCrops: [],
      filterCropsReflection: [],
      tableFilterText: "",
      selectAllBox: true,
      numberValidator,
      // full validation for cover crop completeness
      showIncompleteModal: false,
      incompleteLimings: null,
      alertErrorModalOpen: false,
      // confirmation
      confirmationField: null,
      confirmationLimings: null,
      showConfirmModal: false,
      showSaveConfirmModal: false,
      loadState: null,
      // redirection
      showRedirectModal: false,
      redirectField: null,
      // select crop type UX
      cropSelect: null,
      //   new UI
      showPracticesView: false,
      showSaveView: false,
      showSummaryView: false,
      summaryFields: null,
      failedFields: [],
      summaryKeys: null,
      summaryData: null,
    }
  },
  computed: {
    ...mapState({
      opsSettings: state => state.Defaults.newFieldSettings,
    }),
    ...mapGetters({
      recentWizardData: Defaults.Getters.getRecentWizardData,
      practiceChoices: Defaults.Getters.getPracticeChoices,
    }),
    limingChoices() {
      return this.practiceChoices["liming_type"]
    },
    cropChoicesFiltered() {
      if (this.cropSelect != null) {
        const filteredCropList = Object.entries(CROP_KEY)
          .filter(o => {
            if (this.cropSelect.length > 1) {
              return this.cropSelect.includes(o[0])
            } else {
              return true
            }
          })
          .map(list => list[1])
        return filteredCropList
      } else {
        const selectedOpSettings = this.opsSettingsByField.filter(field =>
          this.fieldsInSelection.includes(parseInt(field.fieldId))
        )
        const selectedCrops = new Set(
          selectedOpSettings.flatMap(field => field.crops)
        )
        const selectedCropNames = Array.from(selectedCrops).map(
          c => CROP_KEY[c]
        )
        return selectedCropNames.filter(i => i != undefined)
      }
    },
    hasPracticeData() {
      return this.limingData.length > 0 ? true : false
    },
    dataCompletionForYear() {
      if (!this.year || this.selectedFields.length == 0) {
        return 0
      }
      let yearDataComps = this.dataCompletionMaster["Liming"].map(o => {
        if ("by_year" in o.data_comp) {
          let temp = o.data_comp.by_year.find(
            yearObj => yearObj.year == this.year
          )
          if (temp) {
            return temp.avg_for_year
          }
        } else {
          return 0
        }
      })
      let average = array => array.reduce((a, b) => a + b) / array.length
      if (yearDataComps.length > 0) {
        return Math.ceil(average(yearDataComps))
      } else {
        return 0
      }
    },
    fieldLocks() {
      let fieldLocks = {}
      for (const field of this.opsSettingsByField) {
        fieldLocks[field.fieldId] = field.fieldLocked
      }
      return fieldLocks
    },
    saveConfirmText() {
      let countText
      if (this.limingData.length) {
        countText =
          `${this.limingData.length} liming` +
          (this.limingData.length > 1 ? "s" : "")
      } else {
        countText = `No Lime Applied`
      }
      return `
        Are you sure you want to replace practices on the ${this.fieldsInSelection.length} selected fields for ${this.year}?
        <br /><br />
        Existing limings will be removed and replaced with ${countText}.`
    },
    opsSettingsByField() {
      let opsDict = {}
      let yearInConsideration = this.year
      Object.entries(this.opsSettings).forEach(opsDefault => {
        const fieldKey = opsDefault[0]

        const selField = this.selectedFields.find(field => field.id == fieldKey)
        if (!selField) {
          return
        }
        opsDict[fieldKey] = {}
        const fieldSettingObj = opsDefault[1]
        const idSettings = {
          fieldId: fieldKey,
          fieldName: selField.name,
          farmName: selField.farm.name,
          fieldImg: selField.boundary_image,
          opsId: fieldSettingObj.operation_id,
        }

        const hasYearData = fieldSettingObj.year_data.some(
          y => y.year == yearInConsideration
        )

        fieldSettingObj.year_data.forEach(yearData => {
          if (!hasYearData) {
            opsDict[fieldKey] = idSettings
            opsDict[fieldKey]["crops"] = ["No Data"]
            opsDict[fieldKey]["cropNames"] = ["No Data"]
            opsDict[fieldKey]["noYearData"] = true
          } else if (yearData.year == yearInConsideration) {
            opsDict[fieldKey] = idSettings
            opsDict[fieldKey]["crops"] = []
            opsDict[fieldKey]["cropNames"] = []
            opsDict[fieldKey]["cropSearch"] = ""
            opsDict[fieldKey]["plantings"] = []
            opsDict[fieldKey]["opsInfo"] = []

            opsDict[fieldKey]["isSelected"] = this.fieldsInSelection.includes(
              parseInt(fieldKey)
            )
              ? true
              : false

            const cultivations = yearData.cultivations.sort(
              (a, b) => a.arva_crop_id - b.arva_crop_id
            )
            cultivations.forEach(cultivation => {
              opsDict[fieldKey]["crops"].push(cultivation.arva_crop_id)
              opsDict[fieldKey]["cropNames"].push(
                CROP_DISPLAY_NAME[cultivation.arva_crop_id]
              )
              opsDict[fieldKey]["cropSearch"] =
                opsDict[fieldKey]["cropSearch"] +
                CROP_DISPLAY_NAME[cultivation.arva_crop_id]
              opsDict[fieldKey]["plantings"] = opsDict[fieldKey][
                "plantings"
              ].concat(cultivation.plantings)
              opsDict[fieldKey]["opsInfo"].push(cultivation.operations)

              opsDict[fieldKey]["crops"].sort()
              opsDict[fieldKey]["cropNames"].sort()
              opsDict[fieldKey]["fieldLocked"] = cultivation.year_data_locked
              opsDict[fieldKey]["noYearData"] = false
            })
          }
        })
      })
      return Object.values(opsDict)
    },
    opsSettingsByFieldFiltered() {
      // returns sorted list for table, only reduces list when filtering by name
      let arrayToReturn = _.cloneDeep(this.opsSettingsByField)

      if (this.tableFilterText) {
        const searchString = this.tableFilterText.toLocaleLowerCase()
        arrayToReturn = arrayToReturn.filter(field =>
          field.fieldName.toLowerCase().includes(searchString)
        )
      }

      if (this.cropSelect) {
        arrayToReturn = arrayToReturn.filter(field => {
          const found = this.cropSelect.every(
            c => field.crops.indexOf(parseInt(c)) >= 0
          )
          return found
        })
      }

      const sortColumns = ["fieldName"]
      const sortDirections = ["desc", "asc"]
      return _.orderBy(arrayToReturn, sortColumns, sortDirections)
    },
    historicalPlantings() {
      const something = _(this.opsSettingsByField)
        .groupBy("crops")
        .map((items, crops) => ({
          crops: crops,
          fieldIds: _.map(items, "fieldId"),
          fieldNames: _.map(items, "fieldName"),
        }))
        .value()
      return something
    },
    yearListModified() {
      return this.yearList.filter(o => !isNaN(o))
    },
    yearPriorOne() {
      return String(Number(this.year) - 1)
    },
    yearExtendedOne() {
      return String(Number(this.year) + 1)
    },
  },
  methods: {
    ...mapActions({
      updateYear: Organization.Actions.updateYear,
      updateLiming: Defaults.Actions.updateLiming,
    }),
    ...mapMutations({
      setRecentWizardData: Defaults.Mutations.setRecentWizardData,
    }),
    disableCheckbox(noYearData) {
      if (noYearData) {
        return true
      } else {
        return false
      }
    },
    fieldChecked(field, e) {
      if (
        this.fieldsInSelection.includes(parseInt(field.fieldId)) &&
        e == false
      ) {
        this.fieldsInSelection = this.fieldsInSelection.filter(
          f => f != parseInt(field.fieldId)
        )
      }
      if (e == true) {
        this.fieldsInSelection.push(parseInt(field.fieldId))
      }
    },
    isLimingComplete(liming) {
      return (
        liming.crop_id && liming.amount !== null && liming.type && liming.date
      )
    },
    allLimingsComplete() {
      if (!this.limingData) return [0]
      const appCompleteness = this.limingData.map(liming =>
        this.isLimingComplete(liming)
      )
      const appsMissing = appCompleteness.map((value, index) =>
        value == null || value == false ? index : ""
      )
      const indexOfMissing = appsMissing.filter(String)
      return indexOfMissing
    },
    handleRemoveLiming(appIndex) {
      this.limingData.splice(appIndex, 1)
      if (this.limingData.length == 0) {
        this.stackPractices = false
      }
    },
    handleSpeechToPractice(practiceResponse) {
      const practiceIndex = practiceResponse["index"]
      const practiceData = practiceResponse["response"]
      const blankPractice = this.blankLimingData()
      const newPractice = { ...blankPractice, ...practiceData }
      if (!this.cropChoicesFiltered.includes(newPractice.crop_id)) {
        newPractice.crop_id = null
      }
      Vue.set(this.limingData, practiceIndex, newPractice)
    },
    handleAddPracticesClick() {
      if (this.showPracticesView == false) {
        this.showSaveView = false
        this.showSummaryView = false
        this.showPracticesView = true

        const cropValue =
          this.cropSelect != null && this.cropSelect.length == 1
            ? CROP_KEY[parseInt(this.cropSelect[0])]
            : null
        const newLimingData = this.blankLimingData()
        newLimingData.crop_id = cropValue
        this.limingData = [newLimingData]
      }
    },
    handleCancelClick() {
      this.showSaveView = false
      this.showSummaryView = false
      this.showPracticesView = false
      this.limingData = []
    },
    blankLimingData(cropValue = null) {
      return {
        amount: null,
        crop_id: cropValue,
        date: this.year + "-01-01",
        type: null,
        // for UI control
        showAllInfo: true,
        menu: false, // boolean for v-menu
        pickerDate: this.year + "-01",
      }
    },
    handleAddEventClick() {
      const cropValue =
        this.cropSelect != null && this.cropSelect.length == 1
          ? CROP_KEY[parseInt(this.cropSelect[0])]
          : null
      const newLimingData = this.blankLimingData(cropValue)
      newLimingData.crop_id = cropValue
      this.limingData.push(newLimingData)
    },
    handleCropFilterButton(sharedCropPlan) {
      if (
        JSON.stringify(this.cropSelect) ==
        JSON.stringify(sharedCropPlan.crops.split(","))
      ) {
        this.cropSelect = null
        this.filterCrops = []
      } else {
        this.cropSelect = sharedCropPlan.crops.split(",")
        this.filterCrops = [this.mapCropNames(sharedCropPlan.crops)]
        if (this.limingData.length > 0) {
          const cropValue =
            this.cropSelect != null && this.cropSelect.length == 1
              ? CROP_KEY[parseInt(this.cropSelect[0])]
              : null
          this.limingData.forEach(o => {
            o.crop_id = cropValue
          })
        }
      }
    },
    isAnyDuplicate(existingLiming) {
      for (const newLiming of this.limingData) {
        if (
          this.isLimingDuplicate(
            existingLiming,
            mapPayloadKeysToUserInput(newLiming)
          )
        ) {
          return true
        }
      }
      return false
    },
    isUntargetedCrop(liming) {
      if (this.limingData.length == 0) {
        return false
      }
      const targetedCrops = this.limingData.map(lim => CROP_ID_KEY[lim.crop_id])
      return (
        liming.crop_id.value && !targetedCrops.includes(liming.crop_id.value)
      )
    },
    isLimingDuplicate(liming1, liming2) {
      return (
        liming1.crop_id.value == liming2.crop_id.value &&
        liming1.date.value == liming2.date.value &&
        liming1.type.value == liming2.type.value &&
        dupeCheck(liming1.amount.value, liming2.amount.value)
      )
    },
    isLimingNone(liming) {
      return (
        (liming.amount == 0 || liming.amount == null) &&
        (liming.type == "none" || liming.type == null)
      )
    },
    mergeFieldPayload(field, limingPayload) {
      if (this.limingData.length == 0 || !this.stackPractices) {
        return limingPayload
      }
      let fieldLimingData = this.extractLimingData(field)
      fieldLimingData = fieldLimingData.filter(cp => !this.isLimingNone(cp))
      let mappedData = fieldLimingData.map(cp => {
        return mapPayloadKeysToUserInput(cp)
      })
      mappedData = mappedData.filter(
        existingLiming =>
          !limingPayload.some(newLiming =>
            this.isLimingDuplicate(existingLiming, newLiming)
          )
      )

      const mergedPayload = limingPayload.concat(mappedData)
      return mergedPayload
    },
    handleCopy(field) {
      this.cropSelect = null

      const copiedData = this.extractLimingData(field)
      for (const practice of copiedData) {
        if (!this.cropChoicesFiltered.includes(practice.crop_id)) {
          practice.crop_id = null
        }
      }
      this.limingData = copiedData

      this.showSummaryView = false
      this.showPracticesView = true

      const myElement = document.getElementById("input-card-container")
      myElement.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      })
    },
    extractLimingData(field) {
      let fieldLiming = []
      field.opsInfo.forEach(opsData => {
        opsData.limings.forEach(lime => fieldLiming.push(lime))
      })

      let copiedLimingData = []
      fieldLiming.forEach(lime => {
        copiedLimingData.push({
          amount: lime.amount.value,
          date: lime.date.value,
          type: lime.type.value,
          crop_id: CROP_KEY[lime.crop_id.value],
          showAllInfo: true,
          menu: false,
          pickerDate: this.year + "-01",
        })
      })
      return copiedLimingData
    },
    handlePreSave() {
      if (this.stackPractices) {
        this.handleSave()
      } else {
        this.showSaveConfirmModal = true
      }
    },
    async handleSave() {
      this.showSaveConfirmModal = false
      if (this.fieldsInSelection.length == 0) {
        this.alertErrorModalOpen = true
        return
      }
      const limingCompleteness = this.limingData.map(fertApp =>
        this.isLimingComplete(fertApp)
      )
      const limingMissing = limingCompleteness.map((value, index) =>
        value == null ? index : ""
      )
      const indexOfMissing = limingMissing.filter(String)

      // find which fert applications are missing info
      if (indexOfMissing.length > 0) {
        this.incompleteApplications = indexOfMissing
        this.showIncompleteModal = true
        return
      } else {
        // save liming to db
        let dataClone = _.cloneDeep(this.limingData)
        let limingPayload = null
        if (this.limingData.length > 0) {
          limingPayload = dataClone.map(liming => {
            // remove showAllInfo and menu to prevent issues with replace_existing in the backend
            delete liming["showAllInfo"]
            delete liming["menu"]
            delete liming["pickerDate"]

            liming = mapPayloadKeysToUserInput(liming)

            return liming
          })
        } else {
          const fieldsBeingSaved = this.opsSettingsByFieldFiltered.filter(f =>
            this.fieldsInSelection.includes(Number(f.fieldId))
          )
          const cropsBeingSaved = fieldsBeingSaved
            .map(f => f.crops)
            .reduce((acc, arr) => acc.concat(arr), [])
            .filter((item, index, self) => self.indexOf(item) === index)
          limingPayload = []
          cropsBeingSaved.forEach(cropId => {
            limingPayload.push({
              amount: { value: 0, source: "User Input" },
              crop_id: { value: cropId, source: "User Input" },
              date: { value: this.year + "-01-01", source: "User Input" },
              type: { value: "none", source: "User Input" },
            })
          })
        }

        this.showPracticesView = false
        this.showSaveView = true

        let updateLimings = []
        this.opsSettingsByFieldFiltered.forEach(field => {
          if (this.fieldsInSelection.includes(parseInt(field.fieldId))) {
            if (!field.noYearData && !field.fieldLocked) {
              updateLimings.push({
                fieldId: field.fieldId,
                promise: this.updateLiming({
                  fieldId: field.fieldId,
                  year: this.year,
                  limingPayload: this.mergeFieldPayload(field, limingPayload),
                }),
              })
            } else {
              console.log("no year data for ", field)
            }
          }
        })
        const results = await Promise.allSettled(
          updateLimings.map(u => u.promise)
        )

        const failedFields = updateLimings
          .filter((item, index) => results[index].status === "rejected")
          .map(item => item.fieldId)
        const successFieldNames = this.opsSettingsByFieldFiltered
          .filter(f => this.fieldsInSelection.includes(parseInt(f.fieldId)))
          .filter(f => !failedFields.includes(f.fieldId))
          .map(f => f.fieldName)
        const failedFieldNames = this.opsSettingsByFieldFiltered
          .filter(f => this.fieldsInSelection.includes(parseInt(f.fieldId)))
          .filter(f => failedFields.includes(f.fieldId))
          .map(f => f.fieldName)

        const limingWantedKeys = ["crop_id", "date", "type", "amount"]
        this.setRecentWizardData({
          wizard: "liming",
          data: this.limingData,
        })

        this.summaryKeys = limingWantedKeys
        this.summaryData = limingPayload
        this.summaryFields = successFieldNames
        this.failedFields = failedFieldNames
        this.showSaveView = false
        this.showSummaryView = true
        const cropValue =
          this.cropSelect != null && this.cropSelect.length == 1
            ? CROP_KEY[parseInt(this.cropSelect[0])]
            : null
        this.limingData = [this.blankLimingData(cropValue)]
        this.stackPractices = true

        this.$emit("update-data-complete")
      }
    },
    handleConfirm(field) {
      this.confirmationField = _.cloneDeep(field)
      this.confirmationLimings = []
      field.opsInfo.forEach(ops => {
        ops.limings.forEach(lime => {
          this.confirmationLimings.push(_.cloneDeep(lime))
        })
      })
      this.confirmationLimings.forEach(lime => {
        for (const key in lime) {
          lime[key].source = "User Input"
        }
      })
      this.showConfirmModal = true
    },
    async handleModalConfirm() {
      this.loadState = "loading"
      await this.updateLiming({
        fieldId: this.confirmationField.fieldId,
        year: this.year,
        limingPayload: this.confirmationLimings,
      })
      this.loadState = "confirmed"
      this.$emit("update-data-complete")
      await new Promise(resolve => setTimeout(resolve, 1000))
      this.handleCloseConfirm()
    },
    handleCloseConfirm() {
      this.showConfirmModal = false
      this.confirmationField = null
      this.confirmationLimings = null
      this.loadState = null
    },
    handleSeasonSelection(newYear) {
      this.updateYear(newYear)
    },
    handleSelectAll(e, resetCropSelect = false) {
      if (e == false) this.fieldsInSelection = []
      if (e == true) {
        this.fieldsInSelection = this.selectedFields
          .map(f => f.id)
          .filter(f => !this.fieldLocks[f])
      }
      if (resetCropSelect) {
        this.cropSelect = null
      }
    },
    handleShowRedirect(field) {
      this.redirectField = field
      this.showRedirectModal = true
    },
    handleCloseRedirect() {
      this.showRedirectModal = false
      this.redirectField = null
    },
    handleRedirectConfirm() {
      this.$router.push(`/data-spreadsheet/${this.redirectField.fieldId}`)
    },
    handleNoLimingClick() {
      this.limingData = []
      this.stackPractices = false
    },
    handleYesLimingClick() {
      if (this.limingData.length == 0) {
        // coming from "no liming" state
        this.stackPractices = true
      }
      const cropValue =
        this.cropSelect != null && this.cropSelect.length == 1
          ? CROP_KEY[parseInt(this.cropSelect[0])]
          : null
      const newLimingData = this.blankLimingData()
      newLimingData.crop_id = cropValue
      this.limingData = [newLimingData]
    },
    mapCropNames(cropIdString) {
      if (cropIdString == "No Data") {
        return cropIdString
      } else if (cropIdString.includes(",")) {
        const cropIdVals = cropIdString.split(",")
        return cropIdVals.map(v => this.cropDisplay[v]).join(", ")
      } else {
        return this.cropDisplay[cropIdString]
      }
    },
    handleAddMoreLiming() {
      this.showSummaryView = false
      this.showPracticesView = true
    },
    limingComplete(liming) {
      if (!liming) return null
      return (
        liming.date.source == "User Input" &&
        liming.date.value != null &&
        liming.type.source == "User Input" &&
        liming.type.value != null &&
        liming.amount.source == "User Input" &&
        liming.amount.value !== null
      )
    },
    limingNeedsConfirm(liming) {
      if (!liming) return null
      return (
        liming.date.source != "User Input" &&
        liming.date.value != null &&
        liming.type.source != "User Input" &&
        liming.type.value != null &&
        liming.amount.source != "User Input" &&
        liming.amount.value != null
      )
    },
    limingMissingData(liming) {
      if (!liming) return null
      return (
        liming.date.source != "User Input" ||
        liming.date.value == null ||
        liming.type.source != "User Input" ||
        liming.type.value == null ||
        liming.amount.source != "User Input" ||
        liming.amount.value == null
      )
    },
  },
  mounted() {
    this.fieldsInSelection = this.selectedFields
      .map(f => f.id)
      .filter(f => !this.fieldLocks[f])
  },
  watch: {
    cropChoicesFiltered(newChoices) {
      for (const practice of this.limingData) {
        const existsInNewChoices = newChoices.some(
          choice => choice === practice.crop_id
        )
        if (!existsInNewChoices) {
          practice.crop_id = null
        }
      }
    },
    filterCrops: {
      deep: true,
      handler(myArray) {
        this.filterCropsReflection = _.cloneDeep(myArray)

        let arrayToFilter = _.cloneDeep(this.opsSettingsByField)

        if (this.filterCropsReflection.length > 0) {
          let cropsToFilter = _.flatten(
            this.filterCropsReflection.map(i => {
              return i.split(", ")
            })
          )
          arrayToFilter = arrayToFilter.filter(field =>
            cropsToFilter.every(c => field.cropNames.indexOf(c) >= 0)
          )
        }

        this.fieldsInSelection = arrayToFilter
          .map(f => parseInt(f.fieldId))
          .filter(f => !this.fieldLocks[f])
      },
    },
  },
}
</script>
<style scoped>
.min-step-height {
  min-height: 400px;
}

.row-margin-correct {
  margin-left: 0px;
  margin-right: 0px;
}

.text-container {
  width: 200px;
}

.three {
  width: 3%;
}

.ten {
  width: 10%;
}

.cursor {
  cursor: pointer;
}
</style>
