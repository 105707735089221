<template>
  <div class="evidencing-btns" :class="activeBtn == EVIDENCING_MAP ? 'map-view-active' : ''">
    <EvidencingGroupEditingSidebar
      :visible="showEvidencingGroupEditingSidebar"
      @closeSidebar="handleEvidencingGroupEditingClose"
    />

    <v-btn
      v-if="isSuperUser && !areViewingAllEvidencingData && !viewingOrgWideEvidencing"
      outlined
      height="32"
      @click="handleEditClick"
    >
      <img :src="editIcon" />
      <v-icon class="superuser-crown">mdi-crown</v-icon>
    </v-btn>

    <v-btn
      v-if="!areViewingAllEvidencingData && !viewingOrgWideEvidencing"
      outlined
      height="32"
      :class="validationViewActive ? 'activated' : ''"
      @click="handleValidationClick"
    >
      <img :src="validateIcon" />
    </v-btn>

    <v-btn
      v-if="!areViewingAllEvidencingData && !viewingOrgWideEvidencing"
      outlined
      height="32"
      class="superuser-separator"
      :class="dashboardViewActive ? 'activated' : ''"
      @click="handleDashboardClick"
    >
      <img :src="barChartIcon" />
    </v-btn>

    <v-btn
      outlined
      height="32"
      :class="mapViewActive ? 'activated' : ''"
      @click="handleMapClick"
    >
      <img :src="mapIcon" />
    </v-btn>

    <v-btn
      outlined
      height="32"
      :class="tableViewActive ? 'activated' : ''"
      @click="handleTableClick"
    >
      <img :src="tableIcon" />
    </v-btn>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import EvidencingGroupEditingSidebar from "@/components/evidencing/EvidencingGroupEditingSidebar";
import router from "@/router";
import { Evidencing } from "@/store/modules";
import { updateGroup } from "@/api/EvidencingAPI";
import editIcon from "@/assets/images/edit-icon.svg";
import validateIcon from "@/assets/images/validate-icon.svg";
import mapIcon from "@/assets/images/map-icon.svg";
import tableIcon from "@/assets/images/table-icon.svg";
import barChartIcon from "@/assets/images/bar-chart-icon.svg";
import uploadIcon from "@/assets/images/upload-icon.svg";
import {
  EVIDENCING_MAP,
  EVIDENCING_TABLE,
  EVIDENCING_VALIDATION,
  EVIDENCING_DASHBOARD,
} from "@/constants/evidencing";

export default {
  name: "EvidencingActionButtons",
  components: { EvidencingGroupEditingSidebar },
  props: {
    activeBtn: { required: true },
    viewingAllEvidencing: { required: false }
  },
  emits: ["toggleDataView"],
  data() {
    return {
      showEvidencingGroupEditingSidebar: false,
      router,
      validateIcon,
      editIcon,
      uploadIcon,
      mapIcon,
      tableIcon,
      barChartIcon,
      EVIDENCING_MAP
    }
  },
  computed: {
    ...mapState({
      activeEvidencingGroup: state => state.Evidencing.activeEvidencingGroup,
      viewingOrgWideEvidencing: state => state.Evidencing.viewingOrgWideEvidencing,
      user: state => state.User.user,
      year: state => state.Organization.year
    }),
    groupId() {
      return this.activeEvidencingGroup['id'];
    },
    mapViewActive() {
      return this.activeBtn == EVIDENCING_MAP
    },
    tableViewActive() {
      return this.activeBtn == EVIDENCING_TABLE
    },
    validationViewActive() {
      return this.activeBtn == EVIDENCING_VALIDATION
    },
    dashboardViewActive() {
      return this.activeBtn == EVIDENCING_DASHBOARD
    },
    isSuperUser() {
      if (this.user != null) {
        return this.user['is_superuser'];
      }
      
      return false;
    },
    areViewingAllEvidencingData() {
      if (this.viewingAllEvidencing != null && this.viewingAllEvidencing) {
        return true
      }

      return false
    }
  },
  methods: {
    ...mapActions({
      fetchEvidencingGroupData: Evidencing.Actions.fetchEvidencingGroupData,
    }),
    handleEvidencingGroupEditingClose(cancelled, editSummary) {
      if (!cancelled) {
        updateGroup({ ...editSummary, year: this.year, groupId: this.activeEvidencingGroup['id'] })
        .then(response => {
          if (response['status'] == 200) {
            this.fetchEvidencingGroupData({ year: this.year, groupId: this.activeEvidencingGroup['id'] });
          }
        })
        .catch(err => {
          console.log('error!', err);
        });
      }

      this.showEvidencingGroupEditingSidebar = false;
    },
    handleEditClick() {
      this.showEvidencingGroupEditingSidebar = true;
    },
    handleValidationClick() {
      if (this.activeBtn != EVIDENCING_VALIDATION) {
        router.push(`/evidencing/validate/`);
      }
    },
    handleDashboardClick() {
      if (this.activeBtn != EVIDENCING_DASHBOARD) {
        router.push(`/evidencing/dashboard/`)
      }
    },
    handleUploadClick() {
      router.push('/uploads?openUpload=practice-confirmations&initialState=select-data-type');
    },
    handleMapClick() {
      // if we're on the correct view, toggle
      // otherwise navigate to the viewing page for this evidencing group
      if (this.activeBtn == EVIDENCING_TABLE) {
        this.$emit("toggleDataView");
      }
      else if ([EVIDENCING_DASHBOARD, EVIDENCING_VALIDATION].includes(this.activeBtn)) {
        router.push(`/evidencing/view?openView=${EVIDENCING_MAP}`);
      }
    },
    handleTableClick() {
      // if we're on the correct view, toggle
      // otherwise navigate to the viewing page for this evidencing group
      if (this.activeBtn == EVIDENCING_MAP) {
        this.$emit("toggleDataView");
      }
      else if ([EVIDENCING_DASHBOARD, EVIDENCING_VALIDATION].includes(this.activeBtn)) {
        router.push(`/evidencing/view?openView=${EVIDENCING_TABLE}`);
      }
    }
  }
}
</script>

<style scoped>
.evidencing-btns {
  display: flex;
  position: absolute;
  top: 64px;
  right: 24px;
  padding: 8px;
  z-index: 4;
  transition: top 0.25s ease-in-out;
}

.evidencing-btns.map-view-active {
  background: #ffffff;
  border-radius: 6px;
  top: 96px;
}

.evidencing-btns>button,
.evidencing-btns>a {
  width: 32px;
  padding: 0 !important;
  min-width: unset !important;
  opacity: 0.5;
  margin-left: 6px;
}

.evidencing-btns> :first-child {
  margin-left: 0;
}

.evidencing-btns>button:hover {
  opacity: 1;
}

.evidencing-btns>button.activated {
  background-color: #001039;
  opacity: 1;
}

.evidencing-btns>button> ::v-deep(.v-btn__content img) {
  transition: filter 0.25s;
}

.evidencing-btns>button.activated> ::v-deep(.v-btn__content img),
.evidencing-btns>button.activated ::v-deep(.v-btn__content i:not(.superuser-crown)) {
  filter: invert(1) !important;
}
.superuser-crown {
  position: absolute;
  top: -16px;
  right: -8px;
  transform: rotate(15deg);
  font-size: 16px;
  color: #47a82f !important;
}
</style>