<template>
  <div class="pdf-wrapper">
    <div class="row">
      <div class="col-12 pb-0">
        <div class="title">{{ responseData.title_page.title }}</div>
        <div class="subtitle">Microclimate</div>
      </div>
      <div class="top-logo">
        <img
          class="profile-img img-fluid mt-2"
          src="/assets/images/logos/arva-logo.png"
          alt="User"
        />
      </div>
    </div>
    <div class="row background-dim mx-0">
      <div class="col" />
    </div>
    <div class="row">
      <div class="col-6 py-0">
        <report-info-table :dataTableObject="descriptionTable" />
      </div>
      <div class="col-6 py-0">
        <div class="map-wrapper">
          <div id="mapbox-map" class="fill-wrapper"></div>
        </div>
        <div class="control-wrapper">
          <div class="d-flex">
            <div class="fa fa-star checked pt-1"></div>
            <div class="ml-auto dot-text table-font-inc font-weight-bold">Field</div>
          </div>
        </div>
      </div>
    </div>
    <div class="row background-dim mx-0">
      <div class="col" />
    </div>
    <div class="row">
      <div class="col-6 py-0">
        <apexchart
          v-if="responseData"
          type="boxPlot"
          height="350"
          :options="barchartOptions"
          :series="barchartSeries"
        />
      </div>
      <div class="col-6 py-0">
        <apexchart
          v-if="responseData"
          type="radar"
          height="350"
          :options="radarOptions"
          :series="radarSeries"
        />
      </div>
    </div>
    <div class="row background-dim mx-0">
      <div class="col" />
    </div>
    <div class="row">
      <div class="col-12">
        <table class="table table-striped table-bordered table-font-inc pt-0">
          <thead>
            <tr>
              <th>AGT</th>
              <th>Acres</th>
              <th>Coverage (%)</th>
              <th>Trial Avg. Yield Change (bu/ac)</th>
              <th>Total Potential Increase in Microclimate (bu)</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(agt, index) in summaryData['AGT']" :key="agt.idx">
              <td>{{ summaryData["AGT"][index] }}</td>
              <td>{{ summaryData["Acres"][index] }}</td>
              <td>{{ summaryData["% of Microclimate"][index] | floatHundredth }}</td>
              <td
                :class="
                  summaryData['Yield Change from Treatment (bu/ac)'][index] < 0
                    ? 'negative-yield'
                    : 'positive-yield'
                "
              >
                {{
                  summaryData["Yield Change from Treatment (bu/ac)"][index]
                    | floatTenth
                }}
              </td>
              <td
                :class="
                  parseInt(summaryData['Potential Treatment Opportunity in Region (bu)'][index]) < 0
                    ? 'negative-yield'
                    : 'positive-yield'
                "
              >
                {{
                  summaryData["Potential Treatment Opportunity in Region (bu)"][index]
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="footer d-flex align-items-center justify-content-between">
      <div class="ml-2">
        Confidential
      </div>
      <div class="mr-3">
        <i class="fa fa-copyright" /> 2021 Arva Intelligence
        <i class="fa fa-trademark" />
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash"
import { GeoJsonLayer } from "@deck.gl/layers"
import { MapboxLayer } from "@deck.gl/mapbox"
import mapboxgl from "mapbox-gl"

import { MAPBOX_TOKEN } from "@/constants/map"
import ReportInfoTable from "./ReportInfoTable.vue"
export default {
  components: { ReportInfoTable },
  name: "MicroclimateReport",
  props: ["responseData"],

  computed: {
    descriptionTable() {
      if (this.responseData) {
        return this.responseData["agt_opportunity"]["description_df"]
      }
      return null
    },
    barchartOptions() {
      if (this.responseData) {
        return {
          chart: this.responseData["agt_opportunity"]["barchart-env"]["chart"],
          legend: this.responseData["agt_opportunity"]["barchart-env"][
            "legend"
          ],
          plotOptions: this.responseData["agt_opportunity"]["barchart-env"][
            "plotOptions"
          ],
          title: this.responseData["agt_opportunity"]["barchart-env"]["title"],
          xaxis: this.responseData["agt_opportunity"]["barchart-env"]["xaxis"],
          yaxis: this.responseData["agt_opportunity"]["barchart-env"]["yaxis"],
        }
      }
      return null
    },
    barchartSeries() {
      if (this.responseData) {
        const output = this.responseData["agt_opportunity"]["barchart-env"][
          "series"
        ]
        return output
      }
      return null
    },
    radarOptions() {
      if (this.responseData) {
        return {
          chart: this.responseData["agt_opportunity"]["radar"]["chart"],
          subtitle: this.responseData["agt_opportunity"]["radar"]["subtitle"],
          title: this.responseData["agt_opportunity"]["radar"]["title"],
          xaxis: this.responseData["agt_opportunity"]["radar"]["xaxis"],
          // yaxis: this.responseData["agt_opportunity"]["radar"]["yaxis"],
        }
      }
      return null
    },
    radarSeries() {
      if (this.responseData) {
        const output = this.responseData["agt_opportunity"]["radar"]["series"]
        return output
      }
      return null
    },
    summaryData() {
      if (this.responseData) {
        return this.responseData["agt_opportunity"]["summary_table"]
      }
      return null
    },
  },

  methods: {
    fitBoundsRotated(bounds, options, eventData, map) {
      options = Object.assign(
        {
          padding: {
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
          },
          offset: [0, 0],
          maxZoom: 5.6,
        },
        options
      )

      if (typeof options.padding === "number") {
        const p = options.padding
        options.padding = {
          top: p,
          bottom: p,
          right: p,
          left: p,
        }
      }

      bounds = mapboxgl.LngLatBounds.convert(bounds)

      // paddingOffset affects the map's center, used in the options for
      // `easeTo` and `flyTo` in subsequent calls
      const paddingOffset = [
          options.padding.left - options.padding.right,
          options.padding.top - options.padding.bottom,
        ],
        lateralPadding = Math.min(options.padding.right, options.padding.left),
        verticalPadding = Math.min(options.padding.top, options.padding.bottom)

      options.offset = [
        options.offset[0] + paddingOffset[0],
        options.offset[1] + paddingOffset[1],
      ]
      options.bearing = options.bearing || this.getBearing()

      const offset = mapboxgl.Point.convert(options.offset),
        tr = map.transform,
        nw = tr.project(bounds.getNorthWest()),
        se = tr.project(bounds.getSouthEast()),
        size = se.sub(nw)

      // START CUSTOM ROTATION
      // "cropped rectangle rotation method"
      // https://stackoverflow.com/questions/33866535/how-to-scale-a-rotated-rectangle-to-always-fit-another-rectangle
      const theta = options.bearing * (Math.PI / 180),
        W =
          size.x * Math.abs(Math.cos(theta)) +
          size.y * Math.abs(Math.sin(theta)),
        H =
          size.x * Math.abs(Math.sin(theta)) +
          size.y * Math.abs(Math.cos(theta)),
        rotatedSize = { x: W, y: H },
        // END CUSTOM ROTATION
        scaleX =
          (tr.width - lateralPadding * 2 - Math.abs(offset.x) * 2) /
          rotatedSize.x,
        scaleY =
          (tr.height - verticalPadding * 2 - Math.abs(offset.y) * 2) /
          rotatedSize.y

      if (scaleY < 0 || scaleX < 0) {
        if (typeof console !== "undefined")
          console.warn(
            "Map cannot fit within canvas with the given bounds, padding, and/or offset."
          )
        return map
      }

      options.center = tr.unproject(nw.add(se).div(2))
      options.zoom = Math.min(
        tr.scaleZoom(tr.scale * Math.min(scaleX, scaleY)),
        options.maxZoom
      )

      return options.linear
        ? map.easeTo(options, eventData)
        : map.flyTo(options, eventData)
    },
  },

  mounted() {
    mapboxgl.accessToken = MAPBOX_TOKEN
    const mapData = this.responseData
      ? this.responseData["agt_opportunity"]["map"]
      : null

    const map = new mapboxgl.Map({
      preserveDrawingBuffer: true,
      container: "mapbox-map",
      height: "100%",
      width: "100%",
      style: "mapbox://styles/mapbox/satellite-v9",
      // center: [80, 34.4],
      center: [-91.6, 34.4],
      zoom: 9,
      pitch: 0,
    })

    // calculate microclimate boundaries for zooming
    const calculateExtentBounds = fieldBoundData => {
      if (!fieldBoundData) return {}
      const latLongValues = fieldBoundData.features.map(
        feat => feat.geometry.coordinates
      )

      // aggregates latitude/longitude values in coordinates [[lat, lng]...]
      function getLong(n) {
        return n[0].map(subarr => subarr[0])
      }
      function getLat(n) {
        return n[0].map(subarr => subarr[1])
      }
      const longVals = _.flatMap(latLongValues, getLong)
      const latVals = _.flatMap(latLongValues, getLat)

      const minLatitude = Math.min.apply(null, latVals)
      const maxLatitude = Math.max.apply(null, latVals)
      const minLongitude = Math.min.apply(null, longVals)
      const maxLongitude = Math.max.apply(null, longVals)

      return { minLatitude, maxLatitude, minLongitude, maxLongitude }
    }
    let climateExtentBounds = calculateExtentBounds(mapData["microclimate"])
    this.fitBoundsRotated(
      [
        [climateExtentBounds.minLongitude, climateExtentBounds.minLatitude],
        [climateExtentBounds.maxLongitude, climateExtentBounds.maxLatitude],
      ],
      { bearing: -0.0001 },
      null,
      map
    )

    const microclimateLayer = new MapboxLayer({
      id: "microclimate-bound-layer",
      type: GeoJsonLayer,
      data: mapData["microclimate"],
      stroked: true,
      filled: true,
      lineWidthMinPixels: 3,
      getFillColor: () => [255, 0, 0, 70],
      getLineColor: () => [255, 0, 0, 255],
    })

    map.on("load", () => {
      map.addLayer(microclimateLayer)
    })

    // add star for field location
    const el = document.createElement("div")
    const width = 42
    const height = 42
    el.className = "fa fa-star fa-lg checked"
    el.style.width = `${width}px`
    el.style.height = `${height}px`
    el.style.color = 'orange'
    el.style.backgroundSize = "100%"
    new mapboxgl.Marker(el)
      .setLngLat(
        mapData["field_center"]["features"][0]["geometry"]["coordinates"]
      )
      .addTo(map)
  },
}
</script>
<style>
@page {
  size: 8.5in 11in;
  margin: 0;
}
</style>
<style scoped>
.background-dim {
  background-color: #dcdcdc;
}

.pdf-wrapper {
  width: 1000px;
  height: 1283px;
  position: fixed;
}

.map-wrapper {
  height: 350px;
  position: relative;
  background: #1b1b1d;
  overflow: hidden;
  padding-right: 25px;
}

.fill-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.control-wrapper {
  position: absolute;
  top: 6px;
  right: 26px;
  height: 42px;
  width: 80px;
  z-index: 500;
  background: white;
  border: 1pt solid #eee;
  box-shadow: 0px 0px 4px #ccc;
  padding: 9px;
  border-radius: 7px;
}

.title {
  color: black;
  font-family: Montserrat, sans-serif;
  font-size: 32px;
  font-weight: 500;
  margin-left: 25px;
}

.subtitle {
  color: black;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 500;
  margin-left: 25px;
}

.top-logo {
  position: absolute;
  top: 10px;
  right: 25px;
}

.footer {
  position: absolute;
  bottom: 0px;
  height: 30px;
  width: 100%;
  background: #444;
  font-family: Montserrat, sans-serif;
  font-size: 13px;
  color: #fff;
  font-weight: bold;
}

.positive-yield {
  color: green;
}

.negative-yield {
  color: red;
}

.table-font-inc {
  font-size: 15px;
}

.checked {
  color: orange;
}
</style>
