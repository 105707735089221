<template>
  <div>
    <Permissions package="seed-selector" />
    <div v-if="showSeedSelector">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex">
              <h2>Seed Placement Templates</h2>
              <div v-if="!isReadOnly" class="add-placement-btn">
                <button class="btn btn-primary" @click="openAddPlacementsModal">
                  <i class="fa fa-plus" />Add New Seed Placement Plan
                </button>
              </div>
            </div>
            <div>
              <PlacementRow
                v-for="plan in placementsListSorted"
                :key="plan.id"
                :planInfo="plan"
              />
            </div>
          </div>
        </div>
      </div>

      <ArvaModal
        v-if="showAddPlacementModal"
        name="addPlacementsModal"
        title="Add New Seed Placement Plan"
        @close-modal="showAddPlacementModal = false"
      >
        <div class="name-edit-form">
          <div v-if="!isLoading">
            <div class="row">
              <div class="col-12">
                <form @submit.prevent>
                  <div class="form-group">
                    <label>Seed Placement Plan Name</label>
                    <input
                      class="form-control"
                      type="text"
                      v-model="addPlacementName"
                      ref="addPlacementName"
                    />
                    <div class="row">
                      <div class="col-6 pr-2">
                        <label>Crop Type</label>
                        <v-select-old
                          label="display_name"
                          :options="cropChoices"
                          :reduce="o => o.value"
                          :disabled="disableCropType"
                          v-model="cropType"
                        />
                      </div>
                      <div class="col-6 d-flex align-items-end">
                        <div class="fields-in-focus">
                          <div>
                            Fields in Focus: {{ selectedFields.length }}
                          </div>
                          <div>
                            Recommended Bags:
                            <span
                              class="rec-bags-error"
                              v-if="recommendedBags > 0 && cropType !== null"
                              >{{ recommendedBags | prettyInteger }}</span
                            >
                            <span
                              class="rec-bags-success"
                              v-if="recommendedBags < 0 && cropType !== null"
                              ><i class="fa fa-check-circle"
                            /></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6 pr-2">
                        <label>Select Company</label>
                        <v-select-old
                          class="select-style"
                          label="display_name"
                          :options="companyChoices"
                          :reduce="o => o.id"
                          v-model="companyFocus"
                        />
                        <label>No. of Bags</label>
                        <input
                          class="form-control"
                          type="number"
                          v-model="bagAmount"
                        />
                      </div>
                      <div class="col-6">
                        <label>Choose Seed</label>
                        <v-select-old
                          class="select-style"
                          label="display_name"
                          :options="seedChoices"
                          v-model="seedForAdd"
                        />
                        <button
                          class="btn btn-primary mt-4 w-100"
                          :class="{ disabled: !canAddSeed }"
                          @click="handleAddSeed"
                        >
                          Add Seed
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="row table-responsive">
              <table class="table" v-if="seedBagList.length > 0">
                <thead>
                  <tr>
                    <th class="w-60">Seed</th>
                    <th class="w-30">Bags</th>
                    <th class="w-10" />
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="row in seedBagList" :key="row.idx">
                    <td>{{ row.seedName }}</td>
                    <td>{{ row.bagAmount }}</td>
                    <td>
                      <i
                        class="pull-right pt-1 fa fa-times-circle"
                        @click="handleDeleteSeed(row)"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="d-flex justify-content-center pb-2" v-if="isLoading">
            <div
              class="seed-plan-calculate text-bold pr-2"
              :class="{
                'text-success': loadingSuccess,
                'text-danger': !loadingSuccess,
              }"
            >
              {{ loadingText }}
            </div>
            <div
              class="spinner-border spinner-border-sm text-success mt-2"
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
        <ArvaModalSaveFooter
          confirmText="Save"
          cancelText="Cancel"
          :disabled="!canSavePlan"
          @on-confirm="handleAddSeedPlan"
          @on-cancel="showAddPlacementModal = false"
        />
      </ArvaModal>
    </div>
  </div>
</template>

<script>
import _ from "lodash"
import { mapActions, mapGetters, mapState } from "vuex"

import SeedSelectorAPI from "@/api/SeedSelectorAPI"

import ArvaModal from "@/components/modals/ArvaModal"
import ArvaModalSaveFooter from "@/components/modals/ArvaModalSaveFooter"
import { CROP_SEEDING_RATES, CROP_SEED_BAG_RATES } from "@/constants"
import PlacementRow from "@/components/seedPlacement/PlacementRow"
import { Filter, Placements } from "@/store/modules"
import Permissions from "@/components/permissions/Permissions"

export default {
  name: "SeedPlacementsList",
  components: { ArvaModal, ArvaModalSaveFooter, PlacementRow, Permissions },

  data() {
    return {
      addPlacementName: null,
      bagAmount: null,
      companyChoices: [],
      companyFocus: null,
      cropType: null,
      isLoading: false,
      loadingText: null,
      loadingSuccess: true,
      seedChoices: [],
      seedForAdd: null,
      seedBagList: [],
      showAddPlacementModal: false,
    }
  },

  computed: {
    ...mapGetters({
      selectedFields: Filter.Getters.getSelectedFields,
      placementsList: Placements.Getters.getPlacementsList,
    }),

    ...mapState({
      isReadOnly: state => state.User.user.permission === "Read-Only",
      cropsWithTrials: state => state.Placements.cropsWithTrials,
      seedCompanyOptions: state => state.Dashboard.seedCompanies,
      showSeedSelector: state =>
        state.Organization.organization.showSeedSelector,
    }),

    canAddSeed() {
      const { bagAmount, companyFocus, cropType, seedForAdd } = this
      return !!bagAmount && !!companyFocus && !!cropType && !!seedForAdd
    },

    canSavePlan() {
      if (this.seedBagList.length > 0 && this.addPlacementName) return true
      return false
    },

    cropChoices() {
      return this.cropsWithTrials
    },

    disableCropType() {
      if (this.seedBagList.length > 0) {
        return true
      }
      return false
    },

    recommendedBags() {
      if (!this.cropType) return ""
      const totalAcreage = _.sum(
        this.selectedFields.map(field => field.acreage)
      )

      // TODO need the rest of seed bag rates
      const seedsPerBag = CROP_SEED_BAG_RATES[this.cropType]
        ? CROP_SEED_BAG_RATES[this.cropType]
        : 60000
      const recommendation =
        (totalAcreage * CROP_SEEDING_RATES[this.cropType]) / seedsPerBag

      const totalInputtedBags = _.sum(
        this.seedBagList.map(seed => parseInt(seed.bagAmount))
      )

      return Math.round(recommendation, 2) - totalInputtedBags
    },

    placementsListSorted() {
      const sortedByDate = _.orderBy(
        this.placementsList,
        plan => {
          return new Date(plan.created_at)
        },
        ["desc"]
      )
      return sortedByDate
    },
  },

  methods: {
    ...mapActions({
      fetchSeedPlacementPlans: Placements.Actions.fetchSeedPlacementPlans,
    }),

    async fetchCompanies() {
      await SeedSelectorAPI.fetchCompaniesByCrop(this.cropType).then(
        response => {
          this.companyChoices = response.data
        }
      )
    },

    async fetchSeeds() {
      await SeedSelectorAPI.fetchSeedsByCompany(
        this.companyFocus,
        this.cropType
      ).then(response => {
        this.seedChoices = response.data
      })
    },

    async handleAddSeedPlan() {
      this.loadingSuccess = true
      this.loadingText = "Calculating seed plan"
      this.isLoading = true
      const fieldIds = this.selectedFields.map(f => f.id)
      await SeedSelectorAPI.createSeedPlan({
        name: this.addPlacementName,
        crop_type: this.cropType,
        field_list: fieldIds,
        bags_per_seed: this.seedBagList,
      }).then(
        async () => {
          this.loadingText = "Fetching placement plans"
          await this.fetchSeedPlacementPlans()
          this.isLoading = false
          this.showAddPlacementModal = false

          // clear form
          this.addPlacementName = null
          this.cropType = null
          this.seedBagList = []
          this.seedForAdd = null
          this.bagAmount = null
          this.companyFocus = null
        },
        reason => {
          console.log(reason)
          this.loadingSuccess = false
          this.loadingText = "Could not calculate placement plan"
          this.isLoading = false

          // clear form
          this.addPlacementName = null
          this.cropType = null
          this.seedBagList = []
          this.seedForAdd = null
          this.bagAmount = null
          this.companyFocus = null
        }
      )
    },

    handleAddSeed() {
      const row = {
        seedId: this.seedForAdd.value,
        seedName: this.seedForAdd.display_name,
        bagAmount: this.bagAmount,
      }
      this.seedBagList.push(row)
      this.seedForAdd = null
      this.bagAmount = null
      this.companyFocus = null
    },

    handleDeleteSeed(seed) {
      const newSeeds = _.without(this.seedBagList, seed)
      this.seedBagList = newSeeds
    },

    openAddPlacementsModal() {
      this.showAddPlacementModal = true
      setTimeout(() => this.$refs.addPlacementName.focus())
    },
  },

  watch: {
    companyFocus(newValue, oldValue) {
      if (newValue && this.cropType) {
        this.fetchSeeds()
      }
      if (newValue !== oldValue) {
        this.seedForAdd = null
      }
    },

    cropType(newValue, oldValue) {
      if (newValue === oldValue) return
      if (newValue !== null) {
        this.fetchCompanies()
      }
      this.companyFocus = null
      this.seedChoices = []
      this.seedForAdd = null
    },
  },
}
</script>
<style scoped>
h2 {
  font-size: 21px;
  font-weight: 500;
}

.add-placement-btn {
  flex: 1;
  text-align: right;
}

.add-placement-btn i {
  margin-right: 8px;
}

.fields-in-focus {
  font-weight: 600;
  font-size: 14px;
}

.form-group {
  margin: 0px;
}

.name-edit-form {
  margin: 25px 70px 10px 70px;
}

.seed-plan-calculate {
  font-size: 16px;
  padding-top: 6px;
}

.table-responsive {
  height: 150px;
  width: 100%;
  overflow: scroll;
}

thead tr:nth-child(1) th {
  background: white;
  position: sticky;
  top: 0;
  z-index: 10;
}

.fa-times-circle {
  cursor: pointer;
  color: #e60000;
}

.w-60 {
  width: 60%;
}
.w-30 {
  width: 30%;
}
.w-10 {
  width: 10%;
}

.rec-bags-error {
  color: #e60000;
}

.rec-bags-success {
  color: #61b100;
}
</style>
