<template>
  <ArvaModal
    name="assignedOrgModal"
    title="Assigned Organization Modal"
    @close-modal="handleCloseModal"
  >
    <AlertErrorModal
      v-if="alertErrorModalOpen"
      :titleText="modalTitleText"
      :errorMessage="errorMessage"
      @close-modal="alertErrorModalOpen = false"
    />

    <div class="row ml-3 mb-5">
      <div class="col-lg-11 col-md-4 col-sm-6 col-xs-12">
        <span class="default-input-title">Corporation</span>
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            v-model.trim="corporation.name"
            readonly
          />
        </div>
      </div>
    </div>

    <div
      v-if="hasSelectedAssignedOrg"
      class="ml-3 col-lg-11 col-md-4 col-sm-6 col-xs-12"
    >
      <span class="default-input-title">Organization</span>
      <div class="input-group">
        <v-select
          v-model="selectedOrg"
          :items="organizationOptions"
          item-text="name"
          return-object
          solo
          label="Select Organization"
        >
          <template #item="{ item, attrs, on }">
            <div :style="locationItemStyle(item)" v-bind="attrs" v-on="on">
              {{ item.name }}
            </div>
          </template>
        </v-select>
      </div>
    </div>
    <div v-else class="ml-3 col-lg-11 col-md-4 col-sm-6 col-xs-12">
      <span class="default-input-title">Organization</span>
      <div class="input-group">
        <v-select
          v-model="selectedOrg"
          :items="organizationOptions"
          item-text="name"
          return-object
          solo
          label="Select Organization"
        >
          <template #item="{ item, attrs, on }">
            <div :style="locationItemStyle(item)" v-bind="attrs" v-on="on">
              {{ item.name }}
            </div>
          </template>
        </v-select>
      </div>
    </div>

    <div class="row ml-3">
      <div class="col-lg-5 col-md-6 col-sm-6 col-xs-12">
        <span class="default-input-title">Role</span>
        <div class="input-group">
          <v-select
            v-model.trim="selectedRole"
            :items="roleOptions"
            solo
            label="Select Role"
          ></v-select>
        </div>
      </div>
    </div>

    <div v-if="hasSelectedAssignedOrg" class="row ml-3">
      <ArvaModalSaveFooter
        confirmText="Save"
        cancelText="Cancel"
        @on-cancel="handleCloseModal"
        @on-confirm="editAssignedOrg"
      />
    </div>
    <div v-else class="row ml-3">
      <ArvaModalSaveFooter
        confirmText="Add"
        cancelText="Cancel"
        @on-cancel="handleCloseModal"
        @on-confirm="addNewAssignedOrg"
      />
    </div>
  </ArvaModal>
</template>

<script>
import ArvaModal from "@/components/modals/ArvaModal"
import ArvaModalSaveFooter from "@/components/modals/ArvaModalSaveFooter"
import AlertErrorModal from "@/components/modals/AlertErrorModal"

export default {
  name: "AssignedOrgModal",
  props: [
    "selectedUserId",
    "selectedAssignedOrg",
    "corporation",
    "organizationOptions",
  ],
  components: {
    ArvaModal,
    ArvaModalSaveFooter,
    AlertErrorModal,
  },

  data() {
    return {
      roleOptions: ["Organization Admin", "Member"],
      statusOptions: ["Active", "Inactive"],
      selectedRole:
        Object.keys(this.selectedAssignedOrg).length > 0
          ? this.selectedAssignedOrg.role
          : "Member",
      selectedOrg:
        Object.keys(this.selectedAssignedOrg).length > 0
          ? this.selectedAssignedOrg.organization
          : null,
      showErrorAlert: false,
      errorMessage: [],
      alertErrorModalOpen: false,
      modalTitleText: null,
      noDataAvailableText: "No Data Available",
    }
  },

  computed: {
    hasSelectedAssignedOrg() {
      if (Object.keys(this.selectedAssignedOrg).length > 0) return true
      return false
    },
  },

  methods: {
    locationItemStyle(item) {
      if (!item.depth) return null

      return item.depth > 1
        ? { "padding-left": item.depth + 1 + ".75em" }
        : null
    },

    handleCloseModal() {
      this.$emit("close-modal")
    },

    addNewAssignedOrg() {
      const hasError = this.validateInputs()
      if (!hasError) {
        const payload = {
          corporation_id: this.corporation.id,
          selected_user_id: this.selectedUserId,
          selected_role: this.selectedRole,
          selected_org: this.selectedOrg,
        }
        this.$emit("add-new-assigned-org", payload)
      }
    },

    editAssignedOrg() {
      const hasError = this.validateInputs()
      if (!hasError) {
        const payload = {
          corporation_id: this.corporation.id,
          selected_user_id: this.selectedUserId,
          selected_role: this.selectedRole,
          selected_org: this.selectedOrg,
          selected_assigned_org_id: this.selectedAssignedOrg.id,
        }
        this.$emit("edit-existing-assigned-org", payload)
      }
    },

    // Validate all the inputs
    //  Return True if found at least 1 error
    //  Return False if found no error
    validateInputs() {
      this.resetError()
      if (!this.selectedRole) this.errorMessage.push("Role must be selected.")
      if (!this.selectedOrg)
        this.errorMessage.push("Organization must be selected.")

      if (this.errorMessage.length > 0) {
        if (this.hasSelectedAssignedOrg)
          this.modalTitleText =
            "Failed To Edit A Selected Assigned Organization"
        else this.modalTitleText = "Failed To Assign A New Organization"
        this.alertErrorModalOpen = true
        return true
      }
      return false
    },

    resetError() {
      this.showErrorAlert = false
      this.errorMessage = []
    },
  },
}
</script>

<style scoped>
.org-options-style {
  max-height: 200px;
  overflow-y: scroll;
}
</style>
