<template>
  <div class="client-row">
    <div class="d-flex justify-content-between align-items-center">
      <div class="client-name">
        <input
          type="checkbox"
          class="checkbox"
          v-model="client.is_active"
        />
        {{ client.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "JDIntegrationClientRow",
  props: {
    client: { type: Object },
  },
}
</script>

<style scoped>
.client-row {
  padding: 18px 20px;
  border-bottom: 1pt solid #d5d5d5;
  margin-left: 25px;
  margin-right: 25px;
}

.client-row:hover {
  background: #fafafa;
}

.client-name {
  color: #333;
  font-size: 15px;
  font-weight: 500;
}

.client-row input {
  margin: 0 15px;
}

.checkbox {
  min-width: 17px;
  min-height: 17px;
}

</style>
