<template>
  <div
    class="side-nav"
    ref="sideNav"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <div class="side-nav">
      <div class="side-nav-inner">
        <div class="side-nav-logo">
          <a href="/">
            <div class="logo" :class="logoClass" />
          </a>
          <div class="mobile-toggle side-nav-toggle">
            <a @click="$emit('toggle-mobile')" href="javascript:void(0);">
              <i class="ti-arrow-circle-left"></i>
            </a>
          </div>
        </div>

        <div v-if="isLoading">
          <v-overlay :value="overlay">
            <div
              class="spinner-border spinner-border-sm spinner"
              role="status"
            />
            Loading Selected Organization...
          </v-overlay>
        </div>

        <ul
          v-if="
            corporationPackage != undefined &&
            corporationPackage.length === 0 &&
            (hover === true || navWidth > 70)
          "
          class="side-nav-menu scrollable"
        >
          <div class="left-panel-text">
            <span>
              Products are currently deactivated.
              <br />
              Please use the Support Chat Box (lower right corner) for Products
              and Feature Enquiries
            </span>
          </div>
        </ul>

        <ul class="side-nav-menu scrollable">
          <li
            v-if="seasonOptions.length === 0"
            class="nav-item top-spacer"
          ></li>

          <li class="nav-item">
            <v-layout class="mbt-0 pbt-0">
              <v-select
                v-if="seasonOptions.length > 0"
                :items="seasonOptions"
                :value="String(year)"
                @input="handleSeasonSelection"
              >
                <template v-slot:selection="{ item }">
                  <v-list-item-content class="season-select-v-list">{{
                    item
                  }}</v-list-item-content>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content v-if="yearHasData(item)" class="ml-3">{{
                    item
                  }}</v-list-item-content>
                  <v-tooltip v-else top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item-content
                        v-bind="attrs"
                        v-on="on"
                        :style="{ color: 'red' }"
                        class="ml-3"
                      >
                        {{ item }}
                      </v-list-item-content>
                    </template>
                    <span
                      >This year currently lacks either operational or confirmed
                      default data</span
                    >
                  </v-tooltip>
                </template>
              </v-select>
            </v-layout>
          </li>

          <!-- <li
            @mouseenter="brainHovered = true"
            @mouseleave="brainHovered = false"
            class="nav-item dropdown"
            :class="{ open: dataOpen }"
          >
            <a
              class="dropdown-toggle"
              href="javascript:void(0);"
              @click="setDataOpen(!dataOpen)"
            >
              <span class="icon-holder">
                <img
                  :class="{ 'img-fluid': true, brainHovered }"
                  src="/assets/images/side-nav/brain.png"
                  width="20"
                />
              </span>
              <span class="title">Data</span>
              <span class="arrow">
                <i class="fa fa-angle-right" />
              </span>
            </a>
            <ul class="dropdown-menu">
              <li class="nav-item">
                <router-link :to="{ name: 'data-spreadsheet' }">
                  <span class="icon-holder">
                    <i class="fa fa-list-ul mr-3"></i>
                  </span>
                  <span class="title">Spreadsheet</span>
                </router-link>
              </li>
            </ul>
          </li> -->

          <li class="nav-item">
            <router-link
              :to="{ name: 'notifications' }"
              class="notifications-link"
            >
              <span class="icon-holder">
                <img
                  class="img-fluid"
                  src="/assets/images/side-nav/notification-bell.svg"
                />
              </span>
              <span>Notifications</span>
              <span
                v-if="unreadNotifications > 0"
                class="notifications-count"
                >{{ unreadNotifications }}</span
              >
            </router-link>
          </li>

          <li
            v-if="showFields"
            @mouseenter="cloudCompHovered = true"
            @mouseleave="cloudCompHovered = false"
            class="nav-item dropdown"
            :class="{ open: fieldsOpen }"
          >
            <a
              class="dropdown-toggle"
              href="javascript:void(0);"
              @click="setFieldsOpen(!fieldsOpen)"
            >
              <span class="icon-holder">
                <img
                  :class="{ 'img-fluid': true, cloudCompHovered }"
                  src="/assets/images/side-nav/map.svg"
                  width="20"
                />
              </span>
              <span>Fields</span>
              <span class="arrow">
                <i class="fa fa-angle-right" />
              </span>
            </a>
            <ul class="dropdown-menu">
              <li class="nav-item">
                <router-link :to="{ name: 'MapView' }">
                  <span class="icon-holder">
                    <i class="fa fa-map-marker mr-3"></i>
                  </span>
                  <span>Map View</span>
                </router-link>
              </li>
              <li v-if="!isReadOnly" class="nav-item">
                <router-link :to="{ name: 'boundary-editor' }">
                  <span class="icon-holder">
                    <i class="fa fa-upload mr-3"></i>
                  </span>
                  <span>Boundary Editor</span>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link :to="{ name: 'fields' }">
                  <span class="icon-holder">
                    <i class="fa fa-list-ul mr-3"></i>
                  </span>
                  <span>List View</span>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link :to="{ name: 'field-reports' }">
                  <span class="icon-holder">
                    <i class="fa fa-file-text-o mr-3"></i>
                  </span>
                  <span>Field Reports</span>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link :to="{ name: 'uploads' }">
                  <span class="icon-holder">
                    <i class="fa fa-upload mr-3"></i>
                  </span>
                  <span>Data Import</span>
                </router-link>
              </li>
              <!-- <li class="nav-item">
                <router-link :to="{ name: 'defaults-list' }">
                  <span class="icon-holder">
                    <i class="fa fa-pencil mr-3"></i>
                  </span>
                  <span class="title">Field-Level Information</span>
                </router-link>
              </li> -->
            </ul>
          </li>

          <li
            v-if="showOpportunitySummary"
            @mouseenter="reportSumHovered = true"
            @mouseleave="reportSumHovered = false"
            class="nav-item"
          >
            <router-link :to="{ name: 'dashboard-summary' }">
              <span class="icon-holder">
                <img
                  :class="{ 'img-fluid': true, reportSumHovered }"
                  src="/assets/images/side-nav/analytics.svg"
                  width="20"
                />
              </span>
              <span>Opportunity Summary</span>
            </router-link>
          </li>

          <li
            @mouseenter="planningHovered = true"
            @mouseleave="planningHovered = false"
            class="nav-item dropdown"
            :class="{ open: planningOpen }"
            v-if="showCropDesigner || showSeedSelector"
          >
            <a
              class="dropdown-toggle"
              href="javascript:void(0);"
              @click="setPlanningOpen(!planningOpen)"
            >
              <span class="icon-holder">
                <img
                  :class="{ 'img-fluid': true, planningHovered }"
                  src="/assets/images/side-nav/levels.svg"
                  width="20"
                />
              </span>
              <span>AI Planning</span>
              <span class="arrow">
                <i class="fa fa-angle-right" />
              </span>
            </a>

            <ul class="dropdown-menu">
              <li class="nav-item" v-if="showSeedSelector">
                <router-link :to="{ name: 'seed-selector' }">
                  <span class="icon-holder">
                    <i class="fa fa-envira mr-3"></i>
                  </span>
                  <span>Seed Selector</span>
                </router-link>
              </li>

              <li class="nav-item" v-if="showSeedSelector">
                <router-link :to="{ name: 'seed-placements-list' }">
                  <span class="icon-holder">
                    <i class="fa fa-pagelines mr-3"></i>
                  </span>
                  <span>Seed Placement Plans</span>
                </router-link>
              </li>

              <li class="nav-item" v-if="showCropDesigner">
                <router-link :to="{ name: 'arva-crop-designer' }">
                  <span class="icon-holder">
                    <i class="fa fa-dot-circle-o mr-3"></i>
                  </span>
                  <span>Arva Crop Designer</span>
                  <span class="ml-1 badge badge-success">Beta</span>
                </router-link>
              </li>

              <li class="nav-item" v-if="showCropDesigner">
                <router-link :to="{ name: 'profit-and-loss' }">
                  <span class="icon-holder">
                    <i class="fa fa-usd mr-4"></i>
                  </span>
                  <span>Planning P + L</span>
                  <span class="ml-1 badge badge-success">Beta</span>
                </router-link>
              </li>
            </ul>
          </li>

          <li
            @mouseenter="insightsHovered = true"
            @mouseleave="insightsHovered = false"
            class="nav-item dropdown"
            :class="{ open: insightsOpen }"
            v-if="showCropPerformance || showAGTs"
          >
            <a
              class="dropdown-toggle"
              href="javascript:void(0);"
              @click="setInsightsOpen(!insightsOpen)"
            >
              <span class="icon-holder">
                <img
                  :class="{ 'img-fluid': true, insightsHovered }"
                  src="/assets/images/side-nav/speedometer.svg"
                  width="20"
                />
              </span>
              <span>Field Insights</span>
              <span class="arrow">
                <i class="fa fa-angle-right" />
              </span>
            </a>

            <ul class="dropdown-menu">
              <li class="nav-item" v-if="showCropPerformance">
                <router-link :to="{ name: 'historical-profit-and-loss' }">
                  <span class="icon-holder">
                    <i class="fa fa-usd mr-3"></i>
                  </span>
                  <span>Historical P + L</span>
                </router-link>
              </li>

              <li class="nav-item" v-if="showCropPerformance">
                <router-link :to="{ name: 'hybrid-placement' }">
                  <span class="icon-holder">
                    <i class="fa fa-pagelines mr-3"></i>
                  </span>
                  <span>Hybrid Placement</span>
                  <span class="ml-1 mb-auto badge badge-success">Beta</span>
                </router-link>
              </li>

              <li class="nav-item" v-if="showCropPerformance">
                <router-link :to="{ name: 'agt-performance' }">
                  <span class="icon-holder">
                    <i class="fa fa-bar-chart mr-2"></i>
                  </span>
                  <span>AGT Performance</span>
                </router-link>
              </li>

              <li class="nav-item" v-if="showAGTs">
                <router-link :to="{ name: 'agts' }">
                  <span class="icon-holder">
                    <i class="fa fa-pie-chart mr-2"></i>
                  </span>
                  <span>Arva Ground Types</span>
                </router-link>
              </li>
            </ul>
          </li>

          <li
            @mouseenter="sustainHovered = true"
            @mouseleave="sustainHovered = false"
            class="nav-item dropdown"
            :class="{ open: sustainOpen }"
            v-if="showSustainability"
          >
            <a
              class="dropdown-toggle"
              href="javascript:void(0);"
              @click="setSustainOpen(!sustainOpen)"
            >
              <span class="icon-holder">
                <i class="fa fa-lg fa-globe pl-2 mr-3"></i>
              </span>
              <span>Sustainability</span>
              <span class="arrow">
                <i class="fa fa-angle-right" />
              </span>
            </a>

            <ul class="dropdown-menu">
              <li class="nav-item" v-if="!showEnrollment">
                <router-link :to="{ name: 'sustainability' }">
                  <span class="icon-holder">
                    <i class="fa fa-envira mr-3"></i>
                    <span>Carbon Enrollment</span>
                  </span>
                </router-link>
              </li>

              <li class="nav-item">
                <router-link :to="{ name: 'historical-practices' }">
                  <span class="icon-holder">
                    <i class="fa fa-columns mr-3"></i>
                    <span>Management Practices</span>
                  </span>
                </router-link>
              </li>

              <li class="nav-item">
                <router-link :to="{ name: 'evidencing' }">
                  <span class="icon-holder">
                    <i class="fa fa-columns mr-3"></i>
                    <span>Evidencing</span>
                  </span>
                </router-link>
              </li>

              <li v-if="isAssigned && !showEnrollment" class="nav-item">
                <router-link :to="{ name: 'enroll-fields' }">
                  <span class="icon-holder">
                    <i class="fa fa-address-book mr-3"></i>
                    <span>Enroll Fields</span>
                  </span>
                </router-link>
              </li>

              <li v-if="isAssigned && !showEnrollment" class="nav-item">
                <router-link :to="{ name: 'enroll-manager' }">
                  <span class="icon-holder">
                    <i class="fa fa-cog mr-3"></i>
                    <span>Manage Enrollments</span>
                  </span>
                </router-link>
              </li>

              <li v-if="isAssigned" class="nav-item">
                <router-link :to="{ name: 'soil-sample-ui' }">
                  <span class="icon-holder">
                    <i class="fa fa-arrow-circle-o-down mr-3"></i>
                    <span>Soil Sampling Plans</span>
                  </span>
                </router-link>
              </li>
            </ul>
          </li>

          <li
            @mouseenter="insetHovered = true"
            @mouseleave="insetHovered = false"
            class="nav-item dropdown"
            :class="{ open: insetOpen }"
            v-if="showEnrollment && isSuperUser"
          >
            <a
              class="dropdown-toggle"
              href="javascript:void(0);"
              @click="setInsetOpen(!insetOpen)"
            >
              <span class="icon-holder">
                <i class="fa fa-lg fa-rotate-right pl-2 mr-3"></i>
              </span>
              <span>Program Management</span>
              <span class="arrow">
                <i class="fa fa-angle-right" />
              </span>
            </a>

            <ul class="dropdown-menu">
              <li v-if="isAssigned" class="nav-item">
                <router-link :to="{ name: 'enrollment-groups' }">
                  <span class="icon-holder">
                    <i class="fa fa-address-book mr-3"></i>
                    <span>Enrollment Confirmation</span>
                  </span>
                </router-link>
              </li>

              <li v-if="isAssigned && isSuperUser" class="nav-item">
                <router-link
                  :to="{
                    name: 'enroll-manager-inset',
                    query: { type: 'inset' },
                  }"
                >
                  <span class="icon-holder">
                    <i class="fa fa-cog mr-3"></i>
                    <span>Allocate Acreage</span>
                  </span>
                </router-link>
              </li>

              <li v-if="isAssigned && isSuperUser" class="nav-item">
                <router-link :to="{ name: 'buyer-inset-filter' }">
                  <span class="icon-holder">
                    <i class="fa fa-cog mr-3"></i>
                    <span>Manage EA Programs</span>
                  </span>
                </router-link>
              </li>
            </ul>
          </li>

          <li
            @mouseenter="offsetHovered = true"
            @mouseleave="offsetHovered = false"
            class="nav-item dropdown"
            :class="{ open: offsetOpen }"
            v-if="showEnrollment"
          >
            <a
              class="dropdown-toggle"
              href="javascript:void(0);"
              @click="setOffsetOpen(!offsetOpen)"
            >
              <span class="icon-holder">
                <i class="fa fa-lg fa-tree pl-2 mr-3"></i>
              </span>
              <span>Carbon Offsets</span>
              <span class="arrow">
                <i class="fa fa-angle-right" />
              </span>
            </a>

            <ul class="dropdown-menu">
              <li class="nav-item">
                <router-link :to="{ name: 'sustainability' }">
                  <span class="icon-holder">
                    <i class="fa fa-envira mr-3"></i>
                    <span>Carbon Enrollment</span>
                  </span>
                </router-link>
              </li>

              <li v-if="isAssigned" class="nav-item">
                <router-link :to="{ name: 'enroll-fields' }">
                  <span class="icon-holder">
                    <i class="fa fa-address-book mr-3"></i>
                    <span>Enroll Fields</span>
                  </span>
                </router-link>
              </li>

              <li v-if="isAssigned" class="nav-item">
                <router-link :to="{ name: 'enroll-manager' }">
                  <span class="icon-holder">
                    <i class="fa fa-cog mr-3"></i>
                    <span>Manage Enrollments</span>
                  </span>
                </router-link>
              </li>
            </ul>
          </li>

          <li
            @mouseenter="imageryHovered = true"
            @mouseleave="imageryHovered = false"
            class="nav-item dropdown"
            :class="{ open: imageryOpen }"
            v-if="showImagery"
          >
            <a
              class="dropdown-toggle"
              href="javascript:void(0);"
              @click="setImageryOpen(!imageryOpen)"
            >
              <span class="icon-holder">
                <i class="fa fa-lg fa-camera pl-2 mr-3"></i>
              </span>

              <!--<span class="icon-holder">
                <img
                  :class="{ 'img-fluid': true, imageryHovered }"
                  src="/assets/images/side-nav/map.svg"
                  width="20"
                />
              </span>-->
              <span>Field Imagery</span>
              <span class="arrow">
                <i class="fa fa-angle-right" />
              </span>
            </a>

            <ul class="dropdown-menu">
              <li class="nav-item">
                <router-link :to="{ name: 'field-imagery' }">
                  <span class="icon-holder">
                    <i class="fa fa-map-marker mr-3"></i>
                  </span>
                  <span>Map View</span>
                </router-link>
              </li>

              <li class="nav-item">
                <router-link :to="{ name: 'order-imagery' }">
                  <span class="icon-holder">
                    <i class="fa fa-shopping-cart mr-3"></i>
                  </span>
                  <span>Order Imagery</span>
                </router-link>
              </li>

              <li class="nav-item">
                <router-link :to="{ name: 'imagery-list' }">
                  <span class="icon-holder">
                    <i class="fa fa-list-ul mr-3"></i>
                  </span>
                  <span>Imagery Analytics</span>
                </router-link>
              </li>
            </ul>
          </li>

          <li
            @mouseenter="zonesHovered = true"
            @mouseleave="zonesHovered = false"
            class="nav-item dropdown"
            :class="{ open: zonesOpen }"
            v-if="showRxZones"
          >
            <a
              class="dropdown-toggle"
              href="javascript:void(0);"
              @click="setZonesOpen(!zonesOpen)"
            >
              <span class="icon-holder">
                <i class="fa fa-lg fa-flask pl-2 mr-3"></i>
              </span>
              <span>Rx Zones</span>
              <span class="arrow">
                <i class="fa fa-angle-right" />
              </span>
            </a>

            <ul class="dropdown-menu">
              <li class="nav-item">
                <router-link :to="{ name: 'zones-agt' }">
                  <span class="icon-holder">
                    <i class="fa fa-globe mr-3"></i>
                  </span>
                  <span>AGT Zones</span>
                </router-link>
              </li>

              <li class="nav-item">
                <router-link :to="{ name: 'zones-yield' }">
                  <span class="icon-holder">
                    <i class="fa fa-signal mr-3"></i>
                  </span>
                  <span>Yield Zones</span>
                </router-link>
              </li>

              <li class="nav-item">
                <router-link :to="{ name: 'imagery-list' }">
                  <span class="icon-holder">
                    <i class="fa fa-camera mr-3"></i>
                  </span>
                  <span>Imagery Zones</span>
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div style="min-width: 480px"></div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex"
import { Organization, User } from "@/store/modules"

export default {
  data() {
    return {
      gpsIconHovered: false,
      reportSumHovered: false,
      performanceHovered: false,
      sliderHovered: false,
      brainHovered: false,
      sustainHovered: false,
      insetHovered: false,
      offsetHovered: false,
      pnlHovered: false,
      cloudCompHovered: false,
      planningHovered: false,
      insightsHovered: false,
      userSettingsHovered: false,
      imageryHovered: false,
      zonesHovered: false,
      navWidth: null,
      hover: false,
      showIndividualImagery: false,
    }
  },

  computed: {
    ...mapState({
      organization: state => state.Organization.organization,
      years: state => state.Organization.organization.years,
      field_crop_years: state =>
        state.Organization.organization.field_crop_years,
      year: state => state.Organization.year,
      dataOpen: state => state.User.dataOpen,
      fieldsOpen: state => state.User.fieldsOpen,
      planningOpen: state => state.User.planningOpen,
      insightsOpen: state => state.User.insightsOpen,
      imageryOpen: state => state.User.imageryOpen,
      sustainOpen: state => state.User.sustainOpen,
      insetOpen: state => state.User.insetOpen,
      offsetOpen: state => state.User.offsetOpen,
      isSuperUser: state => state.User.user.is_superuser,

      zonesOpen: state => state.User.zonesOpen,
      isLoading: state => state.User.loading,
      isAssigned: state => state.User.user.is_in_assigned_org,
      isReadOnly: state => state.User.user.permission === "Read-Only",
      unreadNotifications: state => state.Notifications.incompleteTasks,
    }),

    logoClass() {
      if (
        this.organization &&
        this.organization.corporation &&
        this.organization.corporation.id
      ) {
        if (this.organization.corporation.id === 10) return "logo-indigo"
        if (this.organization.corporation.id === 69) return "logo-truterra"
        if (this.hover == false && this.navWidth < 100) return "logo-arva-sm"
        return "logo-arva"
      }
      return ""
    },

    seasonOptions() {
      if (!this.years || typeof this.years === "undefined") return []

      /*function toNumber(value) {
        return Number(value);
      }*/
      return this.years //.map(toNumber);
    },

    corporationPackage() {
      return (
        this.organization &&
        this.organization.corporation &&
        this.organization.corporation.package
      )
    },

    showFields() {
      return (
        this.organization &&
        this.organization.app_views &&
        this.corporationPackage &&
        ["base"].some(pack => this.corporationPackage.includes(pack))
      )
    },

    showOpportunitySummary() {
      return (
        this.organization &&
        this.organization.app_views &&
        this.organization.app_views.show_opportunity_summary &&
        this.corporationPackage &&
        ["base"].some(pack => this.corporationPackage.includes(pack))
      )
    },

    showCropPerformance() {
      return (
        this.organization &&
        this.organization.app_views &&
        this.organization.app_views.show_crop_performance
      )
    },

    showSeedSelector() {
      return (
        this.organization &&
        this.organization.app_views &&
        this.corporationPackage &&
        ["seed-selector"].some(pack => this.corporationPackage.includes(pack))
      )
    },

    showAGTs() {
      return (
        this.organization &&
        this.organization.app_views &&
        this.organization.app_views.show_agts
      )
    },

    showCropDesigner() {
      return (
        this.organization &&
        this.organization.app_views &&
        this.organization.app_views.show_crop_designer &&
        this.corporationPackage &&
        ["crop-planner"].some(pack => this.corporationPackage.includes(pack))
      )
    },

    showSustainability() {
      return (
        this.organization &&
        this.organization.app_views &&
        this.corporationPackage &&
        this.corporationPackage.includes("sustainability")
      )
    },

    showImagery() {
      return (
        this.organization &&
        this.organization.corporation &&
        this.corporationPackage &&
        this.corporationPackage.includes("imagery")
      )
    },

    showRxZones() {
      return (
        this.organization &&
        this.organization.corporation &&
        this.corporationPackage &&
        this.corporationPackage.includes("rx-zones")
      )
    },
    showEnrollment() {
      return (
        this.organization &&
        this.organization.corporation &&
        this.corporationPackage &&
        this.corporationPackage.includes("inset-enrollment")
      )
    },
  },

  created() {
    window.addEventListener("resize", this.onResize)
  },

  destroyed() {
    window.removeEventListener("resize", this.onResize)
  },

  methods: {
    ...mapActions({
      updateYear: Organization.Actions.updateYear,
    }),

    ...mapMutations({
      setDataOpen: User.Mutations.setDataOpen,
      setFieldsOpen: User.Mutations.setFieldsOpen,
      setPlanningOpen: User.Mutations.setPlanningOpen,
      setInsightsOpen: User.Mutations.setInsightsOpen,
      setImageryOpen: User.Mutations.setImageryOpen,
      setSustainOpen: User.Mutations.setSustainOpen,
      setInsetOpen: User.Mutations.setInsetOpen,
      setOffsetOpen: User.Mutations.setOffsetOpen,

      setZonesOpen: User.Mutations.setZonesOpen,
    }),

    handleSeasonSelection(newYear) {
      this.updateYear(newYear)
    },

    yearHasData(year) {
      if (this.field_crop_years.includes(String(year))) {
        return true
      } else {
        return false
      }
    },

    onResize() {
      this.navWidth = this.$refs.sideNav.clientWidth
    },
  },

  mounted() {
    if (this.$refs.sideNav != undefined) {
      this.navWidth = this.$refs.sideNav.clientWidth
    }

    const currentRoute = this.$router.history.current.name

    const dataRoutes = ["spreadsheet"]
    const fieldRoutes = [
      "MapView",
      "fields",
      "field-reports",
      "uploads",
      "defaults-list",
    ]
    const planningRoutes = [
      "seed-selector",
      "arva-crop-designer",
      "profit-and-loss",
      "sustainability",
    ]
    const insightRoutes = [
      "historical-profit-and-loss",
      "hybrid-placement",
      "agt-performance",
      "agts",
    ]
    const imageryRoutes = ["field-imagery", "order-imagery"]

    if (dataRoutes.includes(currentRoute)) this.setDataOpen(true)
    if (fieldRoutes.includes(currentRoute)) this.setFieldsOpen(true)
    if (planningRoutes.includes(currentRoute)) this.setPlanningOpen(true)
    if (insightRoutes.includes(currentRoute)) this.setInsightsOpen(true)
    if (imageryRoutes.includes(currentRoute)) this.setImageryOpen(true)
  },

  watch: {
    hover: function () {
      if (this.hover) {
        if (this.$refs.sideNav != undefined) {
          this.navWidth = this.$refs.sideNav.clientWidth
        }
      }
    },
  },
}
</script>

<style>
.left-panel-text {
  margin-top: 100%;
  padding: 10px;
  text-align: center;
  color: red;
}

.season-select .vs__dropdown-toggle {
  border: none;
  border-bottom: 1pt solid #ddd;
  border-radius: 0;
}

.season-select .vs__selected-options {
  padding-left: 102px;
  font-size: 16px;
  font-weight: 500;
  color: #ddd;
}

.season-select-v-list {
  padding-left: 102px;
  font-size: 16px;
  font-weight: 500;
}
</style>

<style scoped>
.shared {
  font-style: italic;
}

.side-nav .side-nav-inner .side-nav-menu li a.disabled {
  color: #cccccc;
}

.side-nav-logo {
  text-align: center;
}

@media only screen and (min-width: 1440px) {
  .logo-arva-sm {
    background-image: url("/assets/images/logos/arva-logo.png");
  }
}

@media only screen and (max-width: 1439px) {
  .logo-arva-sm {
    background-image: url("/assets/images/logos/arva-logo-sm.png");
  }
}

.logo-arva {
  background-image: url("/assets/images/logos/arva-logo.png");
}

.logo-indigo {
  background-image: url("/assets/images/logos/indigo-logo.png");
  background-size: contain;
}

.logo-truterra {
  background-image: url("/assets/images/logos/Truterra.png");
  background-size: contain;
}

.fa-angle-right {
  font-size: 18px;
}

.dropdown-menu {
  z-index: 0;
}

/*
CSS magic to get provided png closer in appearance to existing icons.
This may not work on all browsers.
*/

.gpsIconHovered {
  filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(190deg)
    brightness(100%) contrast(119%);
}
.reportSumHovered {
  filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(190deg)
    brightness(100%) contrast(119%);
}
.performanceHovered {
  filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(190deg)
    brightness(100%) contrast(119%);
}
.sliderHovered {
  filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(190deg)
    brightness(100%) contrast(119%);
}
.brainHovered {
  filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(190deg)
    brightness(100%) contrast(119%);
}
.sustainHovered {
  filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(190deg)
    brightness(100%) contrast(119%);
}
.offsetHovered {
  filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(190deg)
    brightness(100%) contrast(119%);
}
.insetHovered {
  filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(190deg)
    brightness(100%) contrast(119%);
}
.pnlHovered {
  filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(190deg)
    brightness(100%) contrast(119%);
}
.cloudCompHovered {
  filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(190deg)
    brightness(100%) contrast(119%);
}
.userSettingsHovered {
  filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(190deg)
    brightness(100%) contrast(119%);
}
.imageryHovered {
  filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(190deg)
    brightness(100%) contrast(119%);
}
.zonesHovered {
  filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(190deg)
    brightness(100%) contrast(119%);
}
.router-link-active {
  color: black !important;
  font-weight: 900;
}

.top-spacer {
  height: 2em;
}

.notifications-link {
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
}

.notifications-count {
  position: absolute;
  right: 50px;
  font-size: 12px;
  line-height: 24px;
  background: #f4f8fd;
  border-radius: 6px;
  width: 24px;
  text-align: center;
}
</style>
