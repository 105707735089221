<template>
  <ArvaModal
    name="wizardRedirectModal"
    title="Add Missing Year Data"
    :wide="true"
    :width="1000"
    @close-modal="handleClose"
  >
    <v-card-text>
      <div class="ml-2 mb-3 text-h5">
        {{ redirectField.fieldName }} is missing data for
        {{ year }}, you can update this on the data spreadsheet page or
        connecting your harvest data for {{ year }} from JD or another source.
      </div>
    </v-card-text>
    <ArvaModalSaveFooter
      confirmText="Data Spreadsheet"
      cancelText="Cancel"
      @on-cancel="handleClose"
      @on-confirm="handleConfirm"
    />
  </ArvaModal>
</template>
<script>
import ArvaModal from "./ArvaModal"
import ArvaModalSaveFooter from "@/components/modals/ArvaModalSaveFooter"

export default {
  name: "WizardRedirectModal",
  props: ["redirectField", "year"],
  components: {
    ArvaModal,
    ArvaModalSaveFooter,
  },
  methods: {
    handleClose() {
      this.$emit("close-template-modal")
    },
    handleConfirm() {
      this.$emit("redirect-confirm")
    },
  },
}
</script>
