<template>
  <img
    class="img-responsive inline-block bounce-logo"
    src="assets/images/logos/arva-square-grey-v1.png"
    width="100"
    alt=""
  />
</template>

<script>
export default {
  name: "LoginLogo",
}
</script>

<style scoped>
@keyframes bounceAnimation {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(-40%);
  }
  100% {
    transform: translateY(0%);
  }
}

.bounce-logo {
  animation: bounceAnimation 2s infinite 0s linear;
}
</style>
