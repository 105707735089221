<template>
  <svg width="100%" height="100%" style="position:absolute">
    <rect
      :x="correctedDragData.x"
      :y="correctedDragData.y"
      :width="correctedDragData.width"
      :height="correctedDragData.height"
      style="fill:rgba(0,0,255, 0);stroke-width:3;stroke:rgb(255,255,255)"
    />
  </svg>
</template>

<script>
export default {
  name: "BoundingBox",
  data() {
    return {
      dragData: { firstx: 0, firsty: 0, lastx: 0, lasty: 0 },
      correctedDragData: { x: 0, y: 0, width: 0, height: 0 },
    }
  },

  methods: {
    clearEventHandlers() {
      this.$el.addEventListener("mousedown", () => {})
      this.$el.addEventListener("mousemove", () => {})
      this.$el.addEventListener("mouseup", () => {})
    },

    calculateCorrectedDragData() {
      if (this.dragData.firstx > this.dragData.lastx) {
        this.$set(this.correctedDragData, "x", this.dragData.lastx)
        this.$set(
          this.correctedDragData,
          "width",
          this.dragData.firstx - this.dragData.lastx
        )
      } else {
        this.$set(this.correctedDragData, "x", this.dragData.firstx)
        this.$set(
          this.correctedDragData,
          "width",
          this.dragData.lastx - this.dragData.firstx
        )
      }
      if (this.dragData.firsty > this.dragData.lasty) {
        this.$set(this.correctedDragData, "y", this.dragData.lasty)
        this.$set(
          this.correctedDragData,
          "height",
          this.dragData.firsty - this.dragData.lasty
        )
      } else {
        this.$set(this.correctedDragData, "y", this.dragData.firsty)
        this.$set(
          this.correctedDragData,
          "height",
          this.dragData.lasty - this.dragData.firsty
        )
      }
    },

    convertDragDataToSVGCoords() {
      let pt = this.$el.createSVGPoint()
      pt.x = this.correctedDragData.x
      pt.y = this.correctedDragData.y
      const svgP = pt.matrixTransform(this.$el.getScreenCTM().inverse())

      this.$set(this.correctedDragData, "x", svgP.x)
      this.$set(this.correctedDragData, "y", svgP.y)
    },
  },

  mounted() {
    this.$el.addEventListener("mousedown", e => {
      this.$set(this.dragData, "firstx", e.x)
      this.$set(this.dragData, "firsty", e.y)
      this.calculateCorrectedDragData()
      this.convertDragDataToSVGCoords()
      this.$el.addEventListener("mousemove", e => {
        this.$set(this.dragData, "lastx", e.x)
        this.$set(this.dragData, "lasty", e.y)
        this.calculateCorrectedDragData()
        this.convertDragDataToSVGCoords()
      })
      this.$el.addEventListener("mouseup", () => {
        this.$el.addEventListener("mousemove", () => {})
      })
    })
  },

  beforeDestroy() {
    this.clearEventHandlers()
    this.$emit("select-boundaries", this.correctedDragData)
  },
}
</script>

<style scoped></style>
