<template>
  <tr>
    <td><img :src="field.boundary_image" style="width:50px" /></td>
    <td>
      <nobr>{{ field.name }}</nobr>
    </td>
    <td class="text-right">{{ field.acreage | integer }}</td>
    <td>{{ field.crop_designer.last_planted_crop | capitalize }}</td>
    <td>
      <v-select-old
        v-if="cropOptions.length > 1"
        :style="{ width: '140px' }"
        :options="cropOptions"
        :value="nextCrop"
        @input="handleCropSelection"
      />
      <div v-else-if="cropOptions.length === 1">{{ cropOptions[0].label }}</div>
    </td>
    <td class="text-right">{{ predictedYield | prettyInteger }}</td>
    <td class="prediction-confidence" :class="yieldPredictionConfidence">
      {{ yieldPredictionConfidence | capitalize }}
    </td>
    <td class="text-right">
      <input
        class="target-yield"
        type="number"
        :value="targetYield"
        @input="handleTargetYieldChange"
      />
    </td>
    <td class="hybrid">
      <div v-for="hybrid in hybrids" :key="hybrid.agt">
        <div class="d-flex align-items-center hybrids">
          <div
            class="nowrap d-flex pointer w-100 justify-content-between align-items-center"
            @click="handleHybridClick(hybrid)"
          >
            <div class="hybrid-select d-flex">
              <div>
                <div v-if="hybrid.variety === selectedVariety" />
              </div>
            </div>
            <div class="d-flex hybrids">
              <div class="nowrap">{{ hybrid.variety }}</div>
              <div class="nowrap">
                {{ hybrid.yield_per_acre | prettyInteger }}/ac
              </div>
            </div>
          </div>
        </div>
      </div>
    </td>
    <td class="nRec text-right font-med">{{ nUnits | prettyInteger }}</td>
    <td class="nRec text-right font-med">{{ nUnitsAcre | prettyInteger }}</td>
    <td class="pRec text-right font-med">{{ pUnits | prettyInteger }}</td>
    <td class="pRec text-right font-med">{{ pUnitsAcre | prettyInteger }}</td>
    <td class="kRec text-right font-med">{{ kUnits | prettyInteger }}</td>
    <td class="kRec text-right font-med">{{ kUnitsAcre | prettyInteger }}</td>
    <td>{{ fertCalculation }}</td>
  </tr>
</template>

<script>
import { mapActions, mapState } from "vuex"
import { CROP_DISPLAY_NAME } from "@/constants"
import { Dashboard } from "@/store/modules"
import { fertScaleFactor } from "@/utility"

export default {
  name: "CropDesignerRow",
  props: ["field", "nextCropSelection", "targetYields"],

  data() {
    return {
      selectedVariety: null,
    }
  },

  computed: {
    ...mapState({
      agtPerformance: state => state.Dashboard.agtPerformance,
    }),

    cropOptions() {
      return Object.keys(this.field.crop_designer.crops).map(cropId => ({
        label: CROP_DISPLAY_NAME[cropId],
        value: cropId,
      }))
    },

    nextCrop() {
      const nextCropId = this.nextCropSelection
      return {
        label: CROP_DISPLAY_NAME[nextCropId],
        value: nextCropId,
      }
    },

    cropInfo() {
      const { field, nextCropSelection } = this
      return field.crop_designer.crops[nextCropSelection]
    },

    agts() {
      return this.cropInfo && this.cropInfo.agts
    },

    predictedYield() {
      const yieldPerAcre =
        this.cropInfo && this.cropInfo.predicted_yield / this.field.acreage
      return yieldPerAcre
    },

    targetYield() {
      const targetYield = this.targetYields[this.nextCrop.value]
      return typeof targetYield !== "undefined"
        ? targetYield
        : Math.round(this.predictedYield)
    },

    yieldPredictionConfidence() {
      return this.cropInfo && this.cropInfo.yield_prediction_confidence
    },

    hybrids() {
      return this.cropInfo && this.cropInfo.top_hybrids.slice(0, 3)
    },

    fertScaleFactor() {
      const nextCropId = parseInt(this.nextCrop.value)
      return fertScaleFactor(nextCropId, this.predictedYield, this.targetYield)
    },

    fertCalculation() {
      const calcType = this.cropInfo
        ? this.cropInfo.fert_rec_calc
        : "Crop Removal"
      return calcType === null ? "Soil/CEC" : calcType
    },

    nUnits() {
      return this.cropInfo && this.cropInfo.n_rec * this.fertScaleFactor
    },

    nUnitsAcre() {
      return this.cropInfo && this.cropInfo.n_acre * this.fertScaleFactor
    },

    pUnits() {
      return this.cropInfo && this.cropInfo.p_rec * this.fertScaleFactor
    },

    pUnitsAcre() {
      return this.cropInfo && this.cropInfo.p_acre * this.fertScaleFactor
    },

    kUnits() {
      return this.cropInfo && this.cropInfo.k_rec * this.fertScaleFactor
    },

    kUnitsAcre() {
      return this.cropInfo && this.cropInfo.k_acre * this.fertScaleFactor
    },
  },

  methods: {
    ...mapActions({
      setNextCrop: Dashboard.Actions.setSelectedCrop,
      setSelectedHybrid: Dashboard.Actions.setSelectedHybrid,
      setTargetYield: Dashboard.Actions.setTargetYield,
    }),

    handleCropSelection(nextCrop) {
      this.setNextCrop({
        nextCrop: nextCrop && nextCrop.value,
        fieldId: this.field.id,
        settingsId: this.field.crop_designer_setting.id,
      })
    },

    handleHybridClick(hybrid) {
      this.selectedVariety = hybrid.variety
      this.setSelectedHybrid({
        fieldId: this.field.id,
        acreage: this.field.acreage,
        nextCrop: this.nextCrop.value,
        totalYield: hybrid.total_yield,
        variety: hybrid.variety,
        yieldPerAcre: hybrid.yield_per_acre,
      })
    },

    handleTargetYieldChange(e) {
      const { target } = e
      const nextCropId = this.nextCrop.value
      const targetYields = { ...this.targetYields }
      targetYields[nextCropId] = target.value
      this.setTargetYield({
        fieldId: this.field.id,
        targetYields,
        settingsId: this.field.crop_designer_setting.id,
      })
    },
  },

  watch: {
    nextCrop() {
      if (this.cropInfo && this.cropInfo.top_hybrids.length > 0) {
        this.selectedVariety = this.cropInfo.top_hybrids[0].variety
        this.setSelectedHybrid({
          fieldId: this.field.id,
          acreage: this.field.acreage,
          nextCrop: this.nextCrop.value,
          totalYield: this.cropInfo.top_hybrids[0].total_yield,
          variety: this.cropInfo.top_hybrids[0].variety,
          yieldPerAcre: this.cropInfo.top_hybrids[0].yield_per_acre,
        })
      }
    },
  },

  mounted() {
    const { cropOptions, field } = this
    if (cropOptions.length === 1) {
      this.setNextCrop({
        nextCrop: cropOptions[0].value,
        fieldId: field.id,
      })
    }
    if (this.cropInfo.top_hybrids && this.cropInfo.top_hybrids.length > 0) {
      this.selectedVariety = this.cropInfo.top_hybrids[0].variety
      this.setSelectedHybrid({
        fieldId: this.field.id,
        acreage: this.field.acreage,
        nextCrop: this.nextCrop.value,
        totalYield: this.cropInfo.top_hybrids[0].total_yield,
        variety: this.cropInfo.top_hybrids[0].variety,
        yieldPerAcre: this.cropInfo.top_hybrids[0].yield_per_acre,
      })
    }
  },
}
</script>

<style scoped>
.agts > div {
  margin: 4px 0px;
}

.nRec {
  background: rgba(91, 137, 165, 1) !important;
  color: white !important;
}

.pRec {
  background: rgba(107, 153, 206, 1) !important;
  color: white !important;
}

.kRec {
  background: rgba(227, 126, 87, 1) !important;
  color: white !important;
}

.hybrids {
  margin: 2px 0px;
  font-size: 13px;
}

.hybrids > div {
  margin: 0px 4px;
}

.nowrap {
  white-space: nowrap;
}

.font-med {
  font-weight: 500;
}

.agt-acreage {
  margin-left: 10px;
  font-size: 11px;
  font-weight: 500;
}

.hybrid-select {
  width: 24px;
}

.hybrid-select > div {
  background: #dadada;
  width: 15px;
  height: 15px;
  border-radius: 15px;
}

.hybrid-select > div > div {
  background: #7574e0;
  width: 9px;
  height: 9px;
  border-radius: 9px;
  margin-top: 3px;
  margin-left: 3px;
}

.prediction-confidence {
  font-weight: bold;
  font-size: 12px !important;
}

.low {
  color: red;
}

.medium {
  color: orange;
}

.high {
  color: #28a745;
}

.target-yield {
  border-bottom: 1pt solid #ccc;
  width: 70px;
}

.target-yield:focus {
  border-bottom: 1pt solid #777;
}
</style>
