<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-4 d-flex flex-row">
            <DashboardTitle
              title="Arva Ground Types"
              class="pt-1"
              faClass="fa-pie-chart"
              tooltip="ARVA AI uses over 300 input variable types to analyze crop performance by crop per AGT zone. AI models take in over 300 input variables in multiple data-sets including localized micro-climates, soil chemistry, environmental variables and more to determine not only predictive yields but also the crop limiters, enhancers and nutrient co-dependencies."
            />
          </div>
          <!--<div class="col-3 d-flex flex-row">
            <span class="pl-1 mt-3">
              Show AGTs for:
            </span>
            <<v-select-old
              v-if="agts"
              class="pl-2 mt-1 pt-2"
              :clearable="false"
              :style="{ width: '140px', height: '40px', fontSize: '12px' }"
              :options="dropdownValues"
              :reduce="o => o.value"
              :value="selectedCrop"
              @input="handleCropSelection"
              label="display_name"
            />
            <i
              class="pl-1 pt-1 mt-3 fa fa-info-circle"
              data-toggle="tooltip"
              data-placement="top"
              title="AGTs are specific to a single crop-type because different crop types behave and cluster differently based on soil and environment."
            />
          </div>-->
          <div class="col-2 pt-1">
            <div v-if="agts" class="position-relative">
              <label class="search-label" for="filter-agt">Search AGTs</label>
              <input
                type="number"
                class="form-control form-control-sm input-sm py-0"
                id="filter-agt"
                :value="agtSearchVal"
                @blur="handleSearchAgt"
                @keyup.enter="handleSearchAgt"
              />
              <i
                v-if="agtSearchVal"
                class="leland-test pull-right fa fa-times-circle"
                @click="agtSearchVal = null"
              />
            </div>
          </div>
        </div>
        <div v-if="!agts" class="zero-state">
          <div>Loading</div>
        </div>
        <div class="row" v-if="selectedCrop">
          <!--<div class="col-4">
            <div class="card">
              <div class="card-body">
                <div class="card-title">
                  % Total Predicted Yield by AGT
                  <i
                    class="fa fa-info-circle"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Shows AI estimated predicted yield percentage per AGT per total fields in focus. Fields in focus are selected from the field navigation bar above."
                  />
                </div>
                <apexchart
                  v-if="piegraphSeries.length > 0"
                  height="255"
                  type="pie"
                  :options="piegraphOptions"
                  :series="piegraphSeries"
                  ref="realTimeChart"
                />
              </div>
            </div>
          </div>-->
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="card-title">
                  Available Acreage by AGT
                  <i
                    class="fa fa-info-circle"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Shows the total available acreage per AGT for the in-focus fields. Fields in focus are selected from the field navigation bar above."
                  />
                </div>
                <apexchart
                  height="220"
                  type="bar"
                  :options="barchartOptions"
                  :series="barchartSeries"
                />
              </div>
            </div>
          </div>
        </div>
        <AgtCard
          v-for="agt in agtsByAcreage"
          :key="agt.id + '_' + agt.crop_id"
          :agt="agt"
          :agtYield="0"
        />
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash"
import { mapGetters, mapMutations, mapState } from "vuex"

import AgtCard from "@/components/misc/AgtCard"
import { CROP_DISPLAY_NAME } from "@/constants"
import DashboardTitle from "@/components/dashboard/DashboardTitle"
import { Dashboard, Filter } from "@/store/modules"
import { getAgtColor } from "@/utility"

export default {
  name: "AgtView",
  components: {
    AgtCard,
    DashboardTitle,
  },

  data() {
    return {
      agtSearchVal: null,
    }
  },

  computed: {
    ...mapGetters({
      activeCrops: Filter.Getters.getDashboardCrops,
      activeFields: Filter.Getters.getSelectedFields,
    }),

    ...mapState({
      agts: state => state.Dashboard.agts,
      fieldAgtAcreage: state => state.Dashboard.fieldAgtAcreage,
      fieldAgtYield: state => state.Dashboard.fieldAgtYield,
      selectedCrop: state => state.Dashboard.agtSelectedCrop,
      organization: state => state.Organization.organization,
      year: state => state.Organization.year,
    }),

    activeFieldIds() {
      return this.activeFields.map(({ id }) => id)
    },

    agtsByAcreage() {
      const activeFieldIds = this.activeFieldIds
      const agts = {}
      const agtsByAcreage = this.filteredAgts.map(agt => {
        if (!agts[agt.agt]) agts[agt.agt] = 0
        const rows = this.fieldAgtAcreage.filter(
          row =>
            row.agt === agt.agt &&
            row.crop_id === this.selectedCrop &&
            activeFieldIds.includes(row.field_id)
        )
        const totalAcreage = rows.reduce((prev, curr) => prev + curr.acreage, 0)
        return { ...agt, acreage: Math.round(totalAcreage) }
        // return { agt: agt.agt, acreage: Math.round(totalAcreage) }
      })
      return agtsByAcreage
        .sort((a, b) => b.acreage - a.acreage)
        .filter(row => row.acreage > 0)
    },

    barchartSeries() {
      if (!this.agts || !this.selectedCrop) return []
      const data = this.agtsByAcreage.map(({ acreage }) => acreage)
      return [{ name: "Acreage", data }]
    },

    barchartOptions() {
      if (!this.agts || !this.selectedCrop) return {}
      const agtNums = this.agtsByAcreage.map(({ agt }) => agt)
      return {
        chart: { id: "acreage-by-agt" },
        colors: agtNums.map(agt => getAgtColor(agt)),
        dataLabels: { enabled: false },
        legend: {
          show: false,
        },
        xaxis: {
          categories: agtNums.map(agt => `AGT-${agt}`),
        },
        yaxis: {
          min: 0,
          max: Math.max(...this.agtsByAcreage.map(({ acreage }) => acreage)),
        },
        plotOptions: {
          bar: { distributed: true },
        },
      }
    },

    yieldByAgt() {
      const yieldByAgt = {}
      this.fieldAgtYield
        .filter(
          ({ crop_id, field_id }) =>
            this.selectedCrop === crop_id &&
            this.activeFieldIds.includes(field_id)
        )
        .forEach(({ yield: agtYield, agt }) => {
          if (!yieldByAgt[agt]) yieldByAgt[agt] = 0
          yieldByAgt[agt] += agtYield
        })
      return yieldByAgt
    },

    piegraphSeries() {
      return this.filteredAgts.map(agt =>
        this.yieldByAgt[agt.agt] ? Math.round(this.yieldByAgt[agt.agt]) : 0
      )
    },

    piegraphOptions() {
      const agtNums = this.filteredAgts.map(agt => agt.agt)
      const labels = agtNums.map(agt => `AGT-${agt}`)
      const showLegend = labels.length > 10 ? false : true
      return {
        chart: {
          width: 380,
          type: "pie",
        },
        colors: agtNums.map(agt => getAgtColor(agt)),
        labels: labels,
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        legend: {
          show: showLegend,
        },
      }
    },

    dropdownValues() {
      if (this.agts) {
        const agtCrops = [...new Set(this.agts.map(x => x.crop_id))]
        const dropdownOptions = agtCrops.map(cropId => ({
          value: cropId,
          display_name: CROP_DISPLAY_NAME[cropId],
        }))
        return dropdownOptions
      }
      return []
    },

    filteredAgts() {
      if (this.selectedCrop) {
        const filteredByCrop = this.agts
          .filter(
            agt =>
              agt.crop_id === this.selectedCrop &&
              _.intersection(this.activeFieldIds, agt.field_ids).length > 0
          )
          .sort((a, b) => parseInt(a.agt) - parseInt(b.agt))
        if (this.agtSearchVal) {
          const filteredBySearch = filteredByCrop.filter(a =>
            a.agt.toString().startsWith(this.agtSearchVal)
          )
          return _.uniqBy(filteredBySearch, "agt")
        }
        return _.uniqBy(filteredByCrop, "agt")
      }
      return []
    },
  },

  methods: {
    ...mapMutations({
      setSelectedCrop: Dashboard.Mutations.setAgtSelectedCrop,
    }),

    handleCropSelection(cropId) {
      this.setSelectedCrop(cropId)
    },

    handleSearchAgt(e) {
      this.agtSearchVal = e.target.valueAsNumber
    },
  },
}
</script>

<style scoped>
.card-title {
  font-size: 16px;
  font-weight: 500;
}

.infocus-bar {
  font-size: 18px;
  padding-bottom: 5px;
}

.zero-state {
  text-align: center;
  padding: 60px;
}

.zero-state > div {
  font-size: 16px;
  font-weight: bold;
}

/* hiding number input arrows Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.search-label {
  font-size: 12px;
  padding: 0;
  margin: 0;
}

.leland-test {
  position: absolute;
  right: 6px;
  top: 28px;
}

#filter-agt {
  height: 30px !important;
}
</style>
