import SamplingAPI from "@/api/SamplingAPI"
import { SoilSampling } from "@/store/modules"

const state = {
  samplingPlans: [],
  finishedLoadingSamplePlans: false,
}

const mutations = {
  [SoilSampling.Mutations.setSamplingPlans](state, plans) {
    state.samplingPlans = plans
    state.finishedLoadingSamplePlans = true
  },
}

const actions = {
  [SoilSampling.Actions.fetchSamplePlans]({ commit }, orgId) {
    return new Promise(resolve => {
      const payload = {
        org_node_id: orgId,
      }

      SamplingAPI.getSamplingPlans(payload).then(response => {
        commit(SoilSampling.Mutations.setSamplingPlans, response.data)
        resolve()
      })
    })
  },
}

export default {
  state,
  mutations,
  actions,
}
