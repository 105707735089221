import API from "./API"

const get = payload => API.post("/trial-report-order/", payload)

const createReport = payload => API.post("/trial-report-create/", payload)

const postTrialReport = () => API.post("/trial-report-order/")

const list = () => API.get("/trial-reports/")

export default { get, postTrialReport, createReport, list }