<template>
  <tr :class="showColorText()">
    <td>
      <v-checkbox
        v-model="userSelectedCheckbox"
        @click="sendCheckEvents"
        name="selected"
        ref="userSelectedCheckbox"
      />
    </td>
    <td>{{ assignedOrg.user.first_name }}</td>
    <td>{{ assignedOrg.user.last_name }}</td>
    <td>{{ assignedOrg.user.email }}</td>
    <td>{{ userStatus }}</td>
    <td
      class="text-center"
      @mouseover="mouseOver = true"
      @mouseout="mouseOver = false"
    >
      {{ assignedOrg.total_assigned_orgs }}
    </td>
    <td>
      <v-btn class="ml-auto" small @click="openEditUserModal"> Edit </v-btn>
    </td>

    <v-dialog v-model="mouseOver" width="500" offset-x>
      <v-card>
        <v-card-title class="text-h6 grey lighten-2">
          Assigned Organization
        </v-card-title>

        <v-card-text>
          <v-simple-table>
            <thead>
              <tr>
                <th class="text-primary">Organization</th>
                <th class="text-primary">Role</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="currentAssignedOrg in assignedOrg.assigned_org"
                :key="currentAssignedOrg.id"
              >
                <td width="35%">
                  {{ currentAssignedOrg.organization.name }}
                </td>

                <td width="30%">
                  {{ currentAssignedOrg.role }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </tr>
</template>

<script>
export default {
  name: "UserListRow",

  props: ["assignedOrg"],

  data() {
    return {
      userSelectedCheckbox: false,
      mouseOver: false,
    }
  },

  computed: {
    userStatus() {
      if (this.assignedOrg.user.is_active === true) return "Active"
      return "Inactive"
    },
  },

  methods: {
    sendCheckEvents() {
      if (this.userSelectedCheckbox)
        this.$emit("checked", this.assignedOrg.user.id)
      else this.$emit("unchecked", this.assignedOrg.user.id)
    },

    setCheckbox(checked) {
      this.userSelectedCheckbox = checked
      this.sendCheckEvents()
    },

    openEditUserModal() {
      this.$emit("openEditUserModal", this.assignedOrg)
    },

    showColorText() {
      if (this.userStatus === "Active") return "showActiveText"
      else if (this.userStatus === "Inactive") return "showInactiveText"
    },
  },
}
</script>

<style scoped>
.showInactiveText {
  color: #850000;
}

.showActiveText {
  color: #324a75;
}
</style>
