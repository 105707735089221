<template>
  <tr>
    <td>
      <input
        @click="checkboxClicked"
        type="checkbox"
        name="selected"
        ref="fieldSelectedCheckbox"
      />
    </td>
    <td>
      <img
        class="my-2"
        :src="field.boundary_image"
        style="width:50px; min-width:50px"
      />
    </td>
    <td class="nowrap">{{ field.name }}</td>
    <td class="text-center">{{ field.acreage | prettyInteger }}</td>
    <td class="ghg text-center">
      <div class="d-flex">
        <v-simple-checkbox
          color="blue darken-2"
          :value="scenarioNoTill.is_selected"
          :disabled="scenarioNoTill.is_locked"
          @input="checkboxChanged(field, $event, 'no-till')"
          ref="practiceCheckboxTillage"
        ></v-simple-checkbox>
        <span class="text-subtitle-1">{{
          field.no_till.value | floatHundredth
        }}</span>
      </div>
    </td>
    <td class="ghg text-center">
      <div class="d-flex">
        <v-simple-checkbox
          color="blue darken-2"
          :value="scenarioCoverCrop.is_selected"
          :disabled="scenarioCoverCrop.is_locked"
          @input="checkboxChanged(field, $event, 'cover-crop')"
          ref="practiceCheckboxCoverCrop"
        ></v-simple-checkbox>
        <span class="text-subtitle-1">{{
          field.cover_crop.value | floatHundredth
        }}</span>
      </div>
    </td>
    <td class="ghg text-center">
      <div class="d-flex">
        <v-simple-checkbox
          color="blue darken-2"
          :value="scenarioInhibitor.is_selected"
          :disabled="scenarioInhibitor.is_locked"
          @input="checkboxChanged(field, $event, 'nitrogen-inhibitor')"
          ref="practiceCheckboxInhibitor"
        ></v-simple-checkbox>
        <span class="text-subtitle-1">{{
          field.inhibitor.value | floatHundredth
        }}</span>
      </div>
    </td>
    <td class="ghg text-center">
      <div class="d-flex">
        <v-simple-checkbox
          color="blue darken-2"
          :value="scenarioAWD.is_selected"
          :disabled="scenarioAWD.is_locked"
          @input="checkboxChanged(field, $event, 'awd')"
          ref="practiceCheckboxAWD"
        ></v-simple-checkbox>
        <span class="text-subtitle-1">{{
          field.awd.value | floatHundredth
        }}</span>
      </div>
    </td>
    <td class="organic-carbon text-center">
      <span class="text-body-1 text-bold">{{
        field.co2EquivalentSelected | floatHundredth
      }}</span>
    </td>
    <td>
      <v-tooltip top v-if="!field.is_additional_practice" max-width="300">
        <template v-slot:activator="{ on, attrs }">
          <v-chip
            :color="field.creditAmount > 0 ? '#fedb80' : 'red'"
            text-color="white"
            v-bind="attrs"
            v-on="on"
          >
            {{ field.creditAmount > 0 ? field.creditAmount : 0 | currency }}
          </v-chip>
        </template>
        <span
          >The selected practices on this field are not expected to exceed the
          additionality criteria for many carbon registeries as too many growers
          in this area have already implemented them. Try including additional
          practices to surpass the 20% compound practice adoption rate threshold
          required.</span
        >
      </v-tooltip>

      <v-chip
        v-if="field.is_additional_practice"
        :color="field.creditAmount > 0 ? 'green' : 'red'"
        text-color="white"
      >
        {{ field.creditAmount > 0 ? field.creditAmount : 0 | currency }}
      </v-chip>
    </td>
    <td class="text-center">
      <v-btn
        :color="button_color"
        :disabled="isReadOnly"
        small
        @click="handleSimulationModal(field)"
        >{{ field.status | capitalize }}</v-btn
      >
    </td>
  </tr>
</template>

<script>
//import CarbonReadyAPI from "@/api/CarbonReadyAPI"
import { mapActions, mapState } from "vuex"

export default {
  name: "SustainabilityRow",
  props: ["field", "nextCropSelection"],

  data() {
    return {
      regenerativePracticeOptions: [
        "Conventional",
        "Protocol 1",
        "Protocol 2",
        "Protocol 3",
      ],
      regenerativePractice: null,
      irrigationPracticeOptions: [
        "Flood",
        "Pivot",
        "Non Irrigated",
        "AWS (Rice)",
      ],
      irrigationPractice: null,
      pricePerTon: 20,
    }
  },
  computed: {
    ...mapState({
      isReadOnly: state => state.User.user.permission === "Read-Only",
    }),
    disableTotalCo2Equivalent() {
      return (
        this.field.no_till.is_locked ||
        this.field.cover_crop.is_locked ||
        this.field.awd.is_locked ||
        this.field.inhibitor.is_locked
      )
    },
    is_complete() {
      if (this.field.status == "complete") {
        return true
      } else {
        return false
      }
    },
    button_color() {
      if (this.field.status == "complete") {
        return "#72CC6B"
      } else if (this.field.status == "error") {
        return "#AF9500"
      } else {
        return "#CCB33B"
      }
    },
    scenarioNoTill() {
      const scenario = this.field.scenario
      if (scenario.some(o => o.regen_practice.name == "no-till")) {
        return scenario.find(o => o.regen_practice.name == "no-till")
      } else {
        return false
      }
    },
    scenarioCoverCrop() {
      const scenario = this.field.scenario
      if (scenario.some(o => o.regen_practice.name == "cover-crop")) {
        return scenario.find(o => o.regen_practice.name == "cover-crop")
      } else {
        return false
      }
    },
    scenarioInhibitor() {
      const scenario = this.field.scenario
      if (scenario.some(o => o.regen_practice.name == "nitrogen-inhibitor")) {
        return scenario.find(o => o.regen_practice.name == "nitrogen-inhibitor")
      } else {
        return false
      }
    },
    scenarioAWD() {
      const scenario = this.field.scenario
      if (scenario.some(o => o.regen_practice.name == "awd")) {
        return scenario.find(o => o.regen_practice.name == "awd")
      } else {
        return false
      }
    },
  },
  methods: {
    ...mapActions({
      updateRegenPractice: "Fields/updateRegenPractice",
    }),

    async checkboxChanged(field, e, name) {
      let loop_name = [name]
      if (name == "total") {
        loop_name = ["no-till", "cover-crop", "nitrogen-inhibitor", "awd"]
      }
      let scenario = field.scenario

      for (let name_idx in loop_name) {
        name = loop_name[name_idx]

        let temp = scenario.filter(x => {
          return x.regen_practice.name == name
        })
        if (temp.length > 0) {
          temp.forEach(this_practice => (this_practice.is_selected = e))
        } else {
          scenario.push({
            is_selected: e,
            regen_practice: { name: name },
            value: 0,
          })
        }

        this.updateRegenPractice({
          field_id: field.fieldId,
          scenario: scenario,
        })
        this.fieldSettingHelper(field, name, e)
      }

      if (scenario.some(x => x.is_selected)) {
        this.$emit("checked", field.id, field.fieldId)
      } else {
        this.$emit("unchecked", field.id, field.fieldId)
      }
    },

    fieldSettingHelper(field, name, e) {
      if (name == "no-till") {
        if (e == true) {
          field.scenario_settings["tillage"] = "no-till"
        } else {
          field.scenario_settings["tillage"] = "conventional"
        }
      }
      if (name == "cover-crop") {
        if (e == true) {
          field.scenario_settings["cover_crop"] = true
        } else {
          field.scenario_settings["cover_crop"] = false
        }
      }
      if (name == "nitrogen-inhibitor") {
        if (e == true) {
          field.scenario_settings["inhibitor"] = 1
        } else {
          field.scenario_settings["inhibitor"] = 0
        }
      }
      if (name == "awd") {
        if (e == true) {
          field.scenario_settings["awd"] = true
        } else {
          field.scenario_settings["awd"] = false
        }
      }
    },

    handleSimulationModal(field) {
      this.$emit("detail_field", field, this.is_complete)
    },

    sendCheckEvents(checkbox) {
      if (checkbox.checked)
        this.$emit("checked", this.field.id, this.field.fieldId)
      else this.$emit("unchecked", this.field.id, this.field.fieldId)
    },

    checkboxClicked() {
      const checkbox = this.$refs.fieldSelectedCheckbox
      this.sendCheckEvents(checkbox)
    },

    setCheckbox(checked) {
      const checkbox = this.$refs.fieldSelectedCheckbox
      checkbox.checked = checked
      this.sendCheckEvents(checkbox)
      let checkboxTillage = this.$refs.practiceCheckboxTillage
      checkboxTillage.value = checked
      let checkboxCoverCrop = this.$refs.practiceCheckboxCoverCrop
      checkboxCoverCrop.value = checked
      let checkboxInhibitor = this.$refs.practiceCheckboxInhibitor
      checkboxInhibitor.value = checked
      let checkboxAWD = this.$refs.practiceCheckboxAWD
      checkboxAWD.value = checked

      this.checkboxChanged(this.field, checked, "total")
    },

    setFieldSelectCheckbox(checked) {
      const checkbox = this.$refs.fieldSelectedCheckbox
      checkbox.checked = checked
      this.sendCheckEvents(checkbox)
    },

    setNoTillCheckbox(checked) {
      this.checkboxChanged(this.field, checked, "no-till")
    },

    setCoverCropCheckbox(checked) {
      this.checkboxChanged(this.field, checked, "cover-crop")
    },

    setInhibitorCheckbox(checked) {
      this.checkboxChanged(this.field, checked, "nitrogen-inhibitor")
    },

    setAWDCheckbox(checked) {
      this.checkboxChanged(this.field, checked, "awd")
    },
  },
}
</script>

<style scoped>
.badge {
  font-size: 12px;
}
</style>
