<template>
  <div>
    <div class="d-flex">
      <div
        class="logo logo-john-deere"
        title="My John Deere account registered."
      />
      <h2 class="ml-2">John Deere Connections</h2>

      <button
        class="btn btn-light btn-sm"
        :class="{ disabled: johnDeereURL === null }"
        @click="openJD"
      >
        <i class="fa fa-plus" />
      </button>

      <div class="action-btn">
        <button
          class="btn btn-primary btn-sm"
          :class="{ disabled: numCheckedAccounts === 0 }"
          @click="handleOpenConfigureOrgModal"
        >
          <span>Update mapping</span>
        </button>

        <button
          class="btn btn-primary btn-sm download-text align-right"
          :class="{ disabled: readyToSync === false }"
          @click="handleOpenOrgImportModal"
        >
          <span>Sync selected orgs</span>
        </button>
      </div>
    </div>
    <div v-if="!isLoading">
      <JDIntegrationRow
        v-for="account in connectedJohnDeereAccounts"
        :key="account.idx"
        :account="account"
        :orgs="orgs"
        @checked="checked"
        @unchecked="unchecked"
        @configure="configureClients"
      />
    </div>
    <div v-else class="d-flex justify-center">
      <v-progress-circular indeterminate color="green"></v-progress-circular>
    </div>

    <ConfirmModal
      v-if="orgImportModalOpen"
      title-text="Sync selected orgs"
      confirm-text="Choose whether to import field boundaries, field operations, or both."
      @confirm="handleDataImport"
      @close-modal="orgImportModalOpen = false"
    >
      <div class="form-group">
        <label for="datatype">Import</label>
        <v-select-old v-model="dataType" :options="dataTypes" />
      </div>
    </ConfirmModal>

    <ConfirmModal
      v-if="configureOrgModalOpen"
      title-text="Update connection mapping"
      :confirm-text="configureOrgText"
      @confirm="confirmOrgChange"
      @close-modal="configureOrgModalOpen = false"
    >
      <SelectOrg :org-nodes="orgNodes" @change-org-id="changeOrgId" />
    </ConfirmModal>

    <ConfirmModal
      v-if="configureClientsModal !== false"
      title-text="Configure clients"
      :confirm-text="configureClientsText"
      @confirm="confirmConfigureClients"
      @close-modal="configureClientsModal = false"
    >
      <div class="integration-clients">
        <JDIntegrationClientRow
          v-for="client in updateClientsList"
          :key="client.jd_id"
          :client="client"
        />
      </div>
    </ConfirmModal>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { Filter } from "@/store/modules";
import ConfirmModal from "@/components/modals/ConfirmModal";
import JohnDeereAPI from "@/api/JohnDeereAPI";
import JDIntegrationRow from "@/components/uploads/JDIntegrationRow";
import JDIntegrationClientRow from "@/components/uploads/JDIntegrationClientRow";
import SelectOrg from "@/components/uploads/SelectOrg";

export default {
  components: {
    ConfirmModal,
    JDIntegrationRow,
    JDIntegrationClientRow,
    SelectOrg,
  },

  data() {
    return {
      johnDeereURL: null,
      johnDeereAccounts: [],
      orgImportModalOpen: false,
      configureOrgModalOpen: false,
      configureClientsModal: false,
      isLoading: false,
      checkedAccounts: {},
      dataType: null,
    };
  },

  computed: {
    ...mapState({
      orgs: (state) => state.Filter.orgNodes,
    }),

    ...mapGetters({
      orgNodes: Filter.Getters.getSelectedOrgNodes,
    }),

    numCheckedAccounts() {
      return Object.keys(this.checkedAccounts).length;
    },

    readyToSync() {
      const configured = this.connectedJohnDeereAccounts.filter(
        (account) => account.pk in this.checkedAccounts && account.org_node
      );
      return (
        configured.length > 0 && configured.length === this.numCheckedAccounts
      );
    },

    connectedJohnDeereAccounts() {
      return this.johnDeereAccounts.filter((account) => account.access_enabled);
    },

    configureOrgText() {
      const plural = this.numCheckedAccounts === 1 ? "" : "s";
      return `Data from ${this.numCheckedAccounts} MyJohnDeere connection${plural} will be loaded into the following location:`;
    },

    configureClientsText() {
      const account = this.johnDeereAccounts.filter((obj) => {
        return obj.pk === this.configureClientsModal;
      });
      const text = `Configure active clients for ${account[0].name}`;
      return text;
    },

    dataTypes() {
      const dts = ["Boundaries", "Operations", "Everything"];
      return dts.map((dt) => ({
        label: dt,
        value: dt.toLowerCase(),
      }));
    },
  },

  async mounted() {
    this.dataType = this.dataTypes[0];
    await this.fetchOrgs();

    const { data: jdUrl } = await JohnDeereAPI.fetchJohnDeereUrls();
    this.johnDeereURL = jdUrl.connections;
  },


  methods: {
    async fetchOrgs() {
      this.isLoading = true
      await JohnDeereAPI.fetchJohnDeereOrgs().then((response) => {
        this.johnDeereAccounts = response.data || [];
      });
      this.isLoading = false
    },

    configureClients(jdorg_id) {
      // clear data (trigger loader?)
      this.updateClientsList = undefined;

      // open modal
      this.configureClientsModal = jdorg_id;

      // set data to result of API call
      const account = this.johnDeereAccounts.filter((obj) => {
        return obj.pk === jdorg_id;
      });
      if (account) {
        this.updateClientsList = account[0].clients;
      }
    },

    checked(org_info) {
      const checkedAccounts = { ...this.checkedAccounts };
      checkedAccounts[org_info.pk] = { name: org_info.name };
      this.checkedAccounts = checkedAccounts;
    },

    unchecked(org_info) {
      const checkedAccounts = { ...this.checkedAccounts };
      delete checkedAccounts[org_info.pk];
      this.checkedAccounts = checkedAccounts;
    },

    openJD() {
      if (this.johnDeereURL) {
        window.open(this.johnDeereURL);
      }
    },

    changeOrgId(orgId) {
      for (const org in this.checkedAccounts) {
        this.checkedAccounts[org].org_node = orgId;
      }
    },

    handleOpenOrgImportModal() {
      if (this.numCheckedAccounts > 0) this.orgImportModalOpen = true;
    },

    handleOpenConfigureOrgModal() {
      if (this.numCheckedAccounts > 0) this.configureOrgModalOpen = true;
    },

    async handleDataImport() {
      await JohnDeereAPI.importJohnDeereData(
        this.dataType.value,
        this.checkedAccounts
      );
      this.orgImportModalOpen = false;
    },

    async confirmOrgChange() {
      await JohnDeereAPI.configureJohnDeereOrg(this.checkedAccounts);
      this.configureOrgModalOpen = false;
      this.fetchOrgs();
    },

    async confirmConfigureClients() {
      await JohnDeereAPI.updateJohnDeereClients(
        this.configureClientsModal,
        this.updateClientsList
      );
      this.configureClientsModal = false;
      this.fetchOrgs();
    },
  },
};
</script>
<style scoped>
h2 {
  font-size: 21px;
  font-weight: 500;
}

.form-group {
  margin: 0px auto 50px auto;
  padding: 0px 100px;
}

/* begin row css */
.upload-row {
  position: relative;
  padding: 18px;
  border-top: 1pt solid #d5d5d5;
}

.action-btn {
  flex: 1;
  text-align: right;
}

.failed {
  color: #cc0101;
}

.complete {
  color: #00a200;
}

.divider {
  height: 40px;
  width: 1px;
  border-left: 1pt solid #e0e0e0;
  display: block;
  margin: auto 18px;
}

.icon-info {
  min-width: 270px;
}

.icon-info > i {
  font-size: 25px;
}

.icon-text {
  font-size: 15px;
  margin-left: 10px;
  font-weight: 500;
}

.integration-clients {
  max-height: 425px;
  overflow: scroll;
}

.logo-john-deere {
  width: 36px;
  height: 36px;
  background-image: url("/assets/images/logos/john-deere-logo.png");
  background-size: contain;
}
</style>
