<template>
  <b-tr>
    <!-- start date -->
    <b-td
      class="cell-table medium-column"
      :class="getClassForCell(covercrop.cover_crop_start_date)"
    >
      <v-menu
        v-model="showDateStart"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <div class="d-flex">
            <div class="date-holder">
              <v-text-field
                v-model="coverCropStartDate"
                dense
                readonly
                hide-details="true"
                v-bind="attrs"
                v-on="on"
                :append-icon="getAppendIconForCell(covercrop.cover_crop_start_date)"
                @click:append="
                  handleClickSubmit('cover_crop_start_date', coverCropStartDate)
                "
              ></v-text-field>
            </div>
            <!-- <div class="cell-text date-button">
              <b-iconstack
                v-if="
                  covercrop.cover_crop_start_date.source !== 'User Input' &&
                    covercrop.cover_crop_start_date.value !== null
                "
                class="input-check"
                font-scale="1.75"
                @click="handleClickSubmit('date', coverCropStartDate)"
              >
                <b-icon stacked class="bg-white" icon="circle-fill"></b-icon>
                <b-icon
                  stacked
                  class="submit-icon"
                  icon="check-circle-fill"
                ></b-icon>
                <b-icon stacked icon="circle"></b-icon>
              </b-iconstack>
            </div> -->
          </div>
        </template>
        <v-date-picker
          v-model="coverCropStartDate"
          :min="yearPriorOne + '-01-01'"
          :max="yearExtendedOne + '-12-31'"
          @input="showDateStart = false"
          scrollable
        ></v-date-picker>
      </v-menu>
    </b-td>
    <!-- cover crop family -->
    <b-td
      class="cell-table medium-column"
      :class="getClassForCell(covercrop.family)"
    >
      <PracticeChoiceDropdown
        clearable
        dense
        hide-details="true"
        :items="familyChoices"
        item-text="display_name"
        item-value="value"
        v-model="family"
        choiceName="cover_crop_family"
        :append-icon="getAppendIconForCell(covercrop.family)"
        @click:append="handleClickSubmit('family', family)"
      />
    </b-td>
    <!-- cover crop type -->
    <b-td
      class="cell-table medium-column"
      :class="getClassForCell(covercrop.type)"
    >
      <PracticeChoiceDropdown
        clearable
        dense
        hide-details="true"
        :items="typeChoices"
        item-text="display_name"
        item-value="value"
        v-model="ccType"
        choiceName="cover_crop_type"
        dependentChoiceName="cover_crop_family"
        :dependentChoiceValue="family"
        :append-icon="getAppendIconForCell(covercrop.type)"
        @click:append="handleClickSubmit('type', ccType)"
      />
    </b-td>
    <!-- interseed cover -->
    <b-td
      class="cell-table medium-column"
      :class="getClassForCell(covercrop.interseed_cover)"
    >
      <v-select
        clearable
        dense
        hide-details="true"
        :items="yesNoOptions"
        item-text="display_name"
        item-value="value"
        v-model="interseedCover"
        :append-icon="getAppendIconForCell(covercrop.interseed_cover)"
        @click:append="handleClickSubmit('interseed_cover', interseedCover)"
      />
    </b-td>
    <!-- end date -->
    <b-td
      class="cell-table medium-column"
      :class="getClassForCell(covercrop.cover_crop_end_date)"
    >
      <v-menu
        v-model="showDateEnd"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <div class="d-flex">
            <div class="date-holder">
              <v-text-field
                v-model="coverCropEndDate"
                dense
                readonly
                hide-details="true"
                v-bind="attrs"
                v-on="on"
                :append-icon="getAppendIconForCell(covercrop.cover_crop_end_date)"
                @click:append="
                  handleClickSubmit('cover_crop_end_date', coverCropEndDate)
                "
              ></v-text-field>
            </div>
          </div>
        </template>
        <v-date-picker
          v-model="coverCropEndDate"
          :min="yearPriorOne + '-01-01'"
          :max="yearExtendedOne + '-12-31'"
          @input="showDateEnd = false"
          scrollable
        ></v-date-picker>
      </v-menu>
    </b-td>
    <b-td class="cell-table black">
      <v-btn icon x-small color="white" @click="handleAdd">
        <v-icon>mdi-plus-circle-outline</v-icon>
      </v-btn>
      <v-btn icon x-small color="white" @click="handleDelete">
        <v-icon>mdi-close-circle-outline</v-icon>
      </v-btn>
    </b-td>
  </b-tr>
</template>
<script>
import { mapMutations, mapGetters } from "vuex"
import { YES_NO_OPTIONS } from "@/constants"
import { Defaults } from "@/store/modules"
import { getAppendIconForCell, getClassForCell } from "../utils"
import PracticeChoiceDropdown from "@/components/dropdowns/PracticeChoiceDropdown"

export default {
  name: "CoverCropRow",
  components: {
    PracticeChoiceDropdown,
  },
  props: {
    additionView: { type: Boolean, default: false },
    cropId: { type: Number },
    field: { type: String },
    covercrop: { type: Object },
    rowIndex: { type: Number },
    year: { type: String },
  },
  data() {
    return {
      yesNoOptions: YES_NO_OPTIONS,
      showDateStart: false,
      showDateEnd: false,
      getClassForCell,
      getAppendIconForCell,
    }
  },
  computed: {
    ...mapGetters({
      practiceChoices: Defaults.Getters.getPracticeChoices,
    }),
    familyChoices() {
      return this.practiceChoices["cover_crop_family"]
    },
    yearPriorOne() {
      return String(Number(this.year) - 1)
    },
    yearExtendedOne() {
      return String(Number(this.year) + 1)
    },
    coverCropStartDate: {
      get() {
        return this.covercrop.cover_crop_start_date.value
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "cover_crop",
          dataSetting: "cover_crop_start_date",
          value: val,
          rowIndex: this.rowIndex,
        }
        if (!this.additionView) {
          this.spreadsheetMutate(updatePayload)
        } else {
          this.covercrop.cover_crop_start_date.value = val
        }
      },
    },
    coverCropEndDate: {
      get() {
        return this.covercrop.cover_crop_end_date.value
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "cover_crop",
          dataSetting: "cover_crop_end_date",
          value: val,
          rowIndex: this.rowIndex,
        }
        if (!this.additionView) {
          this.spreadsheetMutate(updatePayload)
        } else {
          this.covercrop.cover_crop_end_date.value = val
        }
      },
    },
    ccType: {
      get() {
        return this.covercrop.type.value
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "cover_crop",
          dataSetting: "type",
          value: val,
          rowIndex: this.rowIndex,
        }
        if (!this.additionView) {
          this.spreadsheetMutate(updatePayload)
        } else {
          this.covercrop.type.value = val
        }
      },
    },
    family: {
      get() {
        return this.covercrop.family.value
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "cover_crop",
          dataSetting: "family",
          value: val,
          rowIndex: this.rowIndex,
        }
        if (!this.additionView) {
          this.spreadsheetMutate(updatePayload)
        } else {
          this.covercrop.family.value = val
        }
      },
    },
    interseedCover: {
      get() {
        return this.covercrop.interseed_cover.value
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "cover_crop",
          dataSetting: "interseed_cover",
          value: val,
          rowIndex: this.rowIndex,
        }
        if (!this.additionView) {
          this.spreadsheetMutate(updatePayload)
        } else {
          this.covercrop.interseed_cover.value = val
        }
      },
    },
    typeChoices() {
      if (this.family) {
        return this.practiceChoices["cover_crop_type"].filter(
          c => c.dependent_value == this.family || !c.dependent_value
        )
      }
      return [
        { value: "other", display_name: "Other" },
        { value: "none", display_name: "None" },
      ]
    },
  },
  methods: {
    ...mapMutations({
      addFieldSettingRow: Defaults.Mutations.addFieldSettingRow,
      deleteNewFieldSetting: Defaults.Mutations.deleteNewFieldSetting,
      updateNewFieldSetting: Defaults.Mutations.updateNewFieldSetting,
      spreadsheetMutate: Defaults.Mutations.spreadsheetMutate,
    }),
    handleClickSubmit(setting, val) {
      this[setting] = val
    },
    handleCopy() {
      this.$emit("handle-copy", this.covercrop)
    },
    handleAdd() {
      const payloadObj = {
        cover_crop_start_date: { source: "User Input", value: null },
        cover_crop_end_date: { source: "User Input", value: null },
        cover_crop_exists: { source: "User Input", value: null },
        crop_id: { source: "User Input", value: this.cropId },
        family: { source: "User Input", value: null },
        grass_cover: { source: "User Input", value: null },
        interseed_cover: { source: "User Input", value: null },
        legume_cover: { source: "User Input", value: null },
        perennial_cover: { source: "User Input", value: null },
        removal_cover: { source: "User Input", value: null },
        type: { source: "User Input", value: null },
      }
      this.addFieldSettingRow({
        fieldId: this.field,
        cropId: this.cropId,
        dataCategory: "cover_crop",
        dataObj: payloadObj,
        year: this.year,
      })
    },
    handleDelete() {
      var defaultObj = {}
      for (const [key, value] of Object.entries(this.covercrop)) {
        if (key === "crop_id") defaultObj[key] = value
        else defaultObj[key] = { source: "User Input", value: null }
      }

      this.deleteNewFieldSetting({
        fieldId: this.field,
        cropId: this.cropId,
        dataCategory: "cover_crop",
        defaultObj: defaultObj,
        rowIndex: this.rowIndex,
        year: this.year,
      })
    },
    handleValidateValue(val, type, length = 24) {
      function isNumeric(str) {
        if (typeof str != "string") return false // we only process strings!
        return (
          !isNaN(str) && !isNaN(parseFloat(str)) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        ) // ...and ensure strings of whitespace fail
      }

      if (val === null) {
        return true
      }

      if (val.length > length) {
        this.$emit(
          "bad-input",
          "Inputted value length was too long (24 characters allowed)."
        )
        return false
      }

      if (type == "number") {
        if (isNumeric(val)) {
          return true
        } else {
          this.$emit(
            "bad-input",
            "Look out! A numerical value is expected here."
          )
          return false
        }
      } else if (type == "string") {
        return true
      }
    },
  },
}
</script>
<style scoped>
.arva-alert {
  background-color: #f7c0be;
}

.cell-table {
  /* padding: 0; */
  padding: 2px 6px;
}

.pre-gen {
  background-color: #fedb80;
}

.bg-white {
  color: white;
}

.cell-text {
  font-weight: 600;
  color: white;
}

.complete {
  background-color: #c7e3c1;
}

.machine {
  background-color: #c0dce2;
}

.copy-icon {
  cursor: pointer;
  color: #0f9aee;
}

.delete {
  color: #cc0101;
  cursor: pointer;
}

.input-check {
  cursor: pointer;
}

.select-check {
  margin-top: 14px;
  cursor: pointer;
}

.submit-icon {
  color: #c7e3c1;
}

.medium-column {
  width: 140px;
}

.large-column {
  width: 240px;
}

.marge-column {
  width: 180px;
}

.v-input {
  font-size: 18px;
}
</style>
