<template>
  <div>
    <div v-if="selectedFields.length > MAX_DEFAULT_FIELDS">
      <v-card elevation="0">
        <v-card-text class="text-center">
          <span class="text-h4"
            >Please select fewer than {{ MAX_DEFAULT_FIELDS }} fields using the
            top navigation tools.</span
          >
        </v-card-text>
      </v-card>
    </div>
    <div v-else-if="selectedFields.length > 0">
      <!-- Cover Crop Info -->
      <div id="input-card-container">
        <v-card
          class="my-auto mx-auto"
          v-if="showPracticesView || showSaveView || showSummaryView"
        >
          <v-card-text class="min-step-height" v-if="showPracticesView">
            <v-row class="mx-1 mt-7">
              <div class="text-h5">Add Cover Cropping for {{ year }}</div>

              <v-btn class="ml-auto" @click="handleCancelClick()">
                <v-icon>mdi-close</v-icon> Cancel
              </v-btn>

              <v-btn
                class="ml-4"
                color="green lighten-4"
                :disabled="allCoverCropsComplete().length > 0"
                @click="handlePreSave()"
              >
                Save and Continue
              </v-btn>
            </v-row>

            <v-row class="mx-1 mt-7">
              <v-btn-toggle
                v-model="hasPracticeData"
                readonly
                divided
                dense
                mandatory
                active-class="light-blue lighten-4"
              >
                <v-btn
                  :value="true"
                  class="px-3"
                  @click="handleYesCoverCropClick"
                >
                  Cover Crop Planted <v-icon>mdi-check-bold</v-icon>
                </v-btn>
                <v-btn
                  :value="false"
                  class="px-3"
                  @click="handleNoCoverCropClick"
                >
                  No Cover Crop <v-icon>mdi-close-thick</v-icon>
                </v-btn>
              </v-btn-toggle>

              <v-btn-toggle
                class="ml-5"
                v-model="stackPractices"
                divided
                dense
                active-class="light-blue lighten-4"
                mandatory
              >
                <v-btn :value="true" :disabled="!hasPracticeData" class="px-3"
                  >Stack Practices</v-btn
                >
                <v-btn :value="false" class="px-3">Replace Practices</v-btn>
              </v-btn-toggle>
            </v-row>

            <v-row v-if="coverCropData.length > 0">
              <v-col md="12">
                <v-card
                  v-for="cc in coverCropData"
                  :key="cc.idx"
                  class="mt-3"
                  elevation="6"
                >
                  <v-card-title
                    class="cursor blue-grey lighten-5"
                    @click="cc.showAllInfo = !cc.showAllInfo"
                  >
                    Cover Crop #{{ coverCropData.indexOf(cc) + 1 }}
                    <v-icon
                      v-if="!cc.showAllInfo"
                      @click="cc.showAllInfo = true"
                      >mdi-chevron-right</v-icon
                    >
                    <v-icon v-else @click="cc.showAllInfo = false"
                      >mdi-chevron-down</v-icon
                    >
                    <AudioRecorder
                      model="cover_crop"
                      :practiceIndex="coverCropData.indexOf(cc)"
                      :year="Number(year)"
                      @practice-response="handleSpeechToPractice"
                    />
                    <v-tooltip top
                      ><template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          class="ml-auto"
                          :color="
                            isCoverCropComplete(cc)
                              ? 'green accent-4'
                              : 'red darken-1'
                          "
                        >
                          {{
                            isCoverCropComplete(cc)
                              ? "mdi-check-circle-outline"
                              : "mdi-alert-circle-outline"
                          }}
                        </v-icon> </template
                      ><span>{{
                        isCoverCropComplete(cc)
                          ? "Application is good to go!"
                          : "You're missing a few fields, please complete this application to save and continue!"
                      }}</span></v-tooltip
                    >
                    <v-btn
                      class="ml-2"
                      @click.stop="handleRemoveCC(coverCropData.indexOf(cc))"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text v-if="cc.showAllInfo">
                    <v-row align="end">
                      <v-col md="4">
                        <div class="mt-3 mb-2">
                          <b
                            >On which crop was this applied?<span
                              class="red--text"
                              >*</span
                            ></b
                          >
                        </div>
                        <div class="input-holder">
                          <v-select
                            dense
                            outlined
                            hide-details
                            label="Crop"
                            :disabled="
                              cropSelect ? cropSelect.length == 1 : false
                            "
                            :items="cropChoicesFiltered"
                            v-model="cc.crop_id"
                            clearable
                          >
                          </v-select>
                        </div>
                      </v-col>
                      <v-col md="4">
                        <div class="mt-3 mb-2">
                          <b
                            >Cover Crop Planted Date<span class="red--text"
                              >*</span
                            ></b
                          >
                        </div>
                        <div class="input-holder">
                          <v-menu
                            v-model="cc.startMenu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="cc.cover_crop_start_date"
                                label="Planted Date"
                                dense
                                outlined
                                readonly
                                hide-details
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="cc.cover_crop_start_date"
                              :picker-date.sync="cc.startPicker"
                              :min="yearPriorOne + '-01-01'"
                              :max="yearExtendedOne + '-12-31'"
                              @input="cc.startMenu = false"
                              scrollable
                            ></v-date-picker>
                          </v-menu>
                        </div>
                      </v-col>
                      <v-col md="4">
                        <div class="mt-3 mb-2">
                          <b>Cover Crop Removal Date</b>
                        </div>
                        <div class="input-holder">
                          <v-menu
                            v-model="cc.endMenu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="cc.cover_crop_end_date"
                                label="Removal Date"
                                dense
                                outlined
                                readonly
                                hide-details
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="cc.cover_crop_end_date"
                              :picker-date.sync="cc.endPicker"
                              :min="yearPriorOne + '-01-01'"
                              :max="yearExtendedOne + '-12-31'"
                              @input="cc.endMenu = false"
                              scrollable
                            ></v-date-picker>
                          </v-menu>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row align="end">
                      <v-col md="4">
                        <div class="mb-2">
                          <b class="text-h7"
                            >Cover Crop Family<span class="red--text"
                              >*</span
                            ></b
                          >
                        </div>
                        <div class="input-holder">
                          <PracticeChoiceDropdown
                            dense
                            outlined
                            hide-details
                            label="Family"
                            :items="familyChoices"
                            item-text="display_name"
                            item-value="value"
                            v-model="cc.family"
                            choiceName="cover_crop_family"
                            clearable
                          />
                        </div>
                      </v-col>
                      <v-col md="4">
                        <div class="mb-2">
                          <b class="text-h7"
                            >Cover Crop Type<span class="red--text">*</span></b
                          >
                        </div>
                        <div class="input-holder">
                          <PracticeChoiceDropdown
                            dense
                            outlined
                            hide-details
                            label="Type"
                            :items="typesByFamily(cc.family)"
                            item-text="display_name"
                            item-value="value"
                            v-model="cc.type"
                            choiceName="cover_crop_type"
                            dependentChoiceName="cover_crop_family"
                            :dependentChoiceValue="cc.family"
                            clearable
                          />
                        </div>
                      </v-col>
                      <v-col md="4">
                        <div class="mb-2">
                          <b class="text-h7"
                            >Did you interseed?<span class="red--text"
                              >*</span
                            ></b
                          >
                        </div>
                        <div class="pt-2">
                          <v-btn
                            rounded
                            outlined
                            @click="cc.interseed_cover = true"
                            :input-value="cc.interseed_cover === true"
                            >Yes</v-btn
                          >
                          <v-btn
                            class="ml-4"
                            rounded
                            outlined
                            @click="cc.interseed_cover = false"
                            :input-value="cc.interseed_cover === false"
                            >No</v-btn
                          >
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-btn class="mt-4" block @click="handleAddEventClick">
                  Add Cover Crop <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="coverCropData.length == 0">
              <v-card class="mx-auto mt-4" elevation="0">
                <v-card-text>
                  <div class="text-center">
                    <b class="text-h5 text-bold"
                      >No Cover Cropping for {{ this.year }}</b
                    >
                  </div>
                </v-card-text>
              </v-card>
            </v-row>
          </v-card-text>
          <!-- Save Spinner View -->
          <v-card-text class="min-step-height text-center" v-if="showSaveView">
            <v-progress-circular
              class="mt-4"
              :size="100"
              :width="8"
              color="green"
              indeterminate
            >
            </v-progress-circular>
            <div>
              <h3>Saving your cover crop information</h3>
            </div>
          </v-card-text>
          <!-- Summary View -->
          <v-card-text class="min-step-height" v-if="showSummaryView">
            <div class="d-flex">
              <h3>Fields Updated</h3>
              <v-btn class="ml-auto" @click="handleAddMoreCC">
                Add More Cover Crops
              </v-btn>
            </div>
            <div class="d-flex">
              <v-chip
                class="mx-2"
                v-for="fieldName in summaryFields"
                :key="fieldName.idx"
                >{{ fieldName }}</v-chip
              >
            </div>
            <br />
            <h3 v-if="failedFields.length > 0">Some Fields Failed to Save</h3>
            <div v-if="failedFields.length > 0" class="d-flex">
              <v-chip
                color="red lighten-3"
                class="mx-2"
                v-for="fieldName in failedFields"
                :key="fieldName.idx"
                >{{ fieldName }}</v-chip
              >
            </div>
            <br />
            <h3>Cover Crop Data Submitted</h3>
            <div>
              <v-simple-table class="mx-4 mb-4">
                <thead>
                  <tr>
                    <th v-for="key in summaryKeys" :key="key.idx">
                      {{ key == "crop_id" ? "Crop" : key | cleanSnake }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="data in summaryData" :key="data.idx">
                    <td v-for="key in summaryKeys" :key="key.idx">
                      <span v-if="key == 'crop_id'">{{
                        cropDisplay[data[key].value]
                      }}</span>
                      <span v-else>
                        {{
                          data[key].value
                            ? data[key].value
                            : "None" | cleanSnake
                        }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>
          </v-card-text>
        </v-card>
      </div>
      <!-- Fields, Data Comp, and Add Practices -->
      <v-row
        class="mt-4 mb-2 row-margin-correct"
        v-if="!showPracticesView && !showSaveView"
      >
        <div>
          <span class="text-h5 ml-1"
            >{{ opsSettingsByField.length }} fields</span
          >
          <span class="text-h5 ml-2"
            >({{ dataCompletionForYear }}% Complete)</span
          >
        </div>
        <div class="ml-auto d-flex">
          <div>
            <v-btn
              v-for="y in yearListModified"
              :key="y.idx"
              :color="
                parseInt(y) === parseInt(year) ? 'blue-grey lighten-4' : 'white'
              "
              elevation="0"
              tile
              dense
              class="mx-1 py-1"
              @click="handleSeasonSelection(y)"
            >
              {{ y }}
            </v-btn>
          </div>
          <div class="text-container ml-2">
            <v-text-field
              outlined
              dense
              hide-details
              v-model="tableFilterText"
              label="Filter Fields"
            ></v-text-field>
          </div>
          <div class="ml-2">
            <v-btn
              v-if="
                showPracticesView === false &&
                showSaveView === false &&
                showSummaryView === false
              "
              class="white--text"
              color="green"
              x-large
              :disabled="fieldsInSelection.length === 0"
              @click="handleAddPracticesClick"
              >Add Practices</v-btn
            >
          </div>
        </div>
      </v-row>
      <v-row class="mt-4 mb-2 row-margin-correct" v-else>
        <div>
          <span class="text-button ml-1">Receiving fields</span>
        </div>
      </v-row>

      <!-- Quick Filtering Buttons -->
      <v-row class="mt-4 row-margin-correct">
        <v-btn
          dense
          tile
          :color="cropSelect == null ? 'blue-grey lighten-4' : 'white'"
          @click="handleSelectAll(true, true)"
          class="px-2 mx-2 py-1 text-none letter-spacing-0"
          elevation="0"
        >
          All ({{ opsSettingsByField.length }})
        </v-btn>
        <v-btn
          v-for="sharedCropPlan in historicalPlantings"
          :key="sharedCropPlan.idx"
          dense
          tile
          :color="
            JSON.stringify(cropSelect) ==
            JSON.stringify(sharedCropPlan.crops.split(','))
              ? 'blue-grey lighten-4'
              : 'white'
          "
          @click="handleCropFilterButton(sharedCropPlan)"
          class="px-2 mx-2 py-1 text-none"
          elevation="0"
        >
          {{ mapCropNames(sharedCropPlan.crops) }} ({{
            sharedCropPlan.fieldIds.length
          }})
        </v-btn>
      </v-row>

      <!-- Field Filtering Table -->
      <v-row class="pt-3">
        <v-col>
          <v-card>
            <v-card-text v-if="showSelectedFields">
              <v-simple-table>
                <thead>
                  <tr>
                    <th class="three">
                      <div class="d-flex">
                        <v-simple-checkbox
                          color="blue"
                          v-model="selectAllBox"
                          @input="handleSelectAll($event)"
                        ></v-simple-checkbox>
                      </div>
                    </th>
                    <th class="ten">Name</th>
                    <th class="three">Farm</th>
                    <th class="three">Locked</th>
                    <th class="three">Crops</th>
                    <th>Cover Cropping</th>
                    <th class="three">
                      <div>
                        <span> Copy </span>
                      </div>
                    </th>
                    <th v-if="showPracticesView">Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="field in opsSettingsbyFieldFiltered"
                    :key="field.idx"
                  >
                    <td>
                      <v-simple-checkbox
                        color="blue"
                        :value="
                          fieldsInSelection.includes(parseInt(field.fieldId))
                        "
                        :disabled="
                          disableCheckbox(field.noYearData) || field.fieldLocked
                        "
                        @input="fieldChecked(field, $event)"
                      ></v-simple-checkbox>
                    </td>
                    <td>{{ field.fieldName }}</td>
                    <td class="overflow-hidden">{{ field.farmName }}</td>
                    <td>
                      <v-icon
                        size="20"
                        v-if="field.fieldLocked"
                        :color="field.fieldLocked ? 'red' : 'green'"
                      >
                        {{ field.fieldLocked ? "mdi-lock" : "mdi-lock-open" }}
                      </v-icon>
                    </td>
                    <td v-if="'cropNames' in field">
                      <div v-for="crop in field.cropNames" :key="crop">
                        {{ crop }}
                      </div>
                    </td>
                    <td v-else>
                      <div>No Crop</div>
                    </td>
                    <td class="py-2" v-if="!field.noYearData">
                      <div
                        v-for="opsSingle in field.opsInfo"
                        :key="opsSingle.idx"
                      >
                        <v-row
                          class="mx-0"
                          :class="
                            opsSingle.cover_crop.indexOf(covercropInfo) !=
                            opsSingle.length - 1
                              ? 'row-bottom'
                              : ''
                          "
                          dense
                          v-for="covercropInfo in opsSingle.cover_crop.sort(
                            (a, b) => a.crop_id.value - b.crop_id.value
                          )"
                          :key="covercropInfo.idx"
                        >
                          <v-col
                            md="2"
                            v-if="covercropInfo.cover_crop_exists.value == true"
                          >
                            <span
                              :class="
                                covercropInfo.cover_crop_start_date.value
                                  ? 'black--text'
                                  : 'red--text font-weight-bold'
                              "
                            >
                              {{
                                covercropInfo.cover_crop_start_date.value
                                  ? covercropInfo.cover_crop_start_date.value
                                  : "No Start"
                              }}
                            </span>
                          </v-col>
                          <v-col
                            md="2"
                            v-if="covercropInfo.cover_crop_exists.value == true"
                          >
                            <span
                              :class="
                                covercropInfo.cover_crop_end_date.value
                                  ? 'black--text'
                                  : 'red--text font-weight-bold'
                              "
                            >
                              {{
                                covercropInfo.cover_crop_end_date.value
                                  ? covercropInfo.cover_crop_end_date.value
                                  : "No End"
                              }}
                            </span>
                          </v-col>
                          <v-col
                            md="2"
                            v-if="covercropInfo.cover_crop_exists.value == true"
                          >
                            <span
                              :class="
                                covercropInfo.family.value
                                  ? 'black--text'
                                  : 'red--text font-weight-bold'
                              "
                            >
                              {{
                                covercropInfo.family.value
                                  ? covercropInfo.family.value
                                  : "No Family" | cleanSnake
                              }}
                            </span>
                          </v-col>
                          <v-col
                            md="2"
                            v-if="covercropInfo.cover_crop_exists.value == true"
                          >
                            <span
                              :class="
                                covercropInfo.type.value
                                  ? 'black--text'
                                  : 'red--text font-weight-bold'
                              "
                            >
                              {{
                                covercropInfo.type.value
                                  ? covercropInfo.type.value
                                  : "No Type" | cleanSnake
                              }}
                            </span>
                          </v-col>
                          <v-col
                            md="2"
                            v-if="covercropInfo.cover_crop_exists.value == true"
                          >
                            <span
                              :class="
                                covercropInfo.interseed_cover.value !== null
                                  ? 'black--text'
                                  : 'red--text font-weight-bold'
                              "
                            >
                              {{
                                covercropInfo.interseed_cover.value !== null
                                  ? covercropInfo.interseed_cover.value
                                    ? "Interseeded"
                                    : "Not interseeded"
                                  : "No Interseed" | cleanSnake
                              }}
                            </span>
                          </v-col>
                          <v-col
                            md="2"
                            v-if="covercropInfo.cover_crop_exists.value == true"
                          >
                            <!-- Data Complete -->
                            <div
                              class="d-flex"
                              v-if="coverComplete(covercropInfo)"
                            >
                              <v-lazy>
                                <v-tooltip top
                                  ><template v-slot:activator="{ on, attrs }"
                                    ><v-icon
                                      v-bind="attrs"
                                      v-on="on"
                                      color="success"
                                      >mdi-check-circle</v-icon
                                    ></template
                                  ><span
                                    >You've filled out enough information for
                                    Arva data models to run. Thank you!</span
                                  ></v-tooltip
                                >
                              </v-lazy>
                              <span class="ml-2">Data Complete</span>
                            </div>
                            <!-- Needs Confirmation -->
                            <div
                              class="d-flex"
                              v-else-if="coverNeedsConfirm(covercropInfo)"
                            >
                              <v-lazy>
                                <v-tooltip top
                                  ><template v-slot:activator="{ on, attrs }"
                                    ><v-icon
                                      v-bind="attrs"
                                      v-on="on"
                                      color="yellow"
                                      >mdi-alert-rhombus</v-icon
                                    ></template
                                  ><span
                                    >This information is coming from a public
                                    source. Please include this field when
                                    setting information to confirm it's
                                    data!</span
                                  ></v-tooltip
                                >
                              </v-lazy>
                              <span
                                class="ml-2 cursor blue--text lighten-1 text-bold"
                                @click="handleConfirm(field)"
                                >Confirm Data</span
                              >
                            </div>
                            <!-- Missing Data -->
                            <div
                              class="d-flex"
                              v-else-if="coverMissingData(covercropInfo)"
                            >
                              <v-lazy>
                                <v-tooltip top
                                  ><template v-slot:activator="{ on, attrs }"
                                    ><v-icon
                                      v-bind="attrs"
                                      v-on="on"
                                      color="red"
                                      >mdi-alert-circle</v-icon
                                    ></template
                                  ><span
                                    >This field is missing data. Please fill in
                                    its data using the wizard!</span
                                  ></v-tooltip
                                >
                              </v-lazy>
                              <span class="ml-2">Missing Data</span>
                            </div>
                          </v-col>
                          <v-col
                            md="10"
                            v-if="
                              covercropInfo.cover_crop_exists.value == false ||
                              covercropInfo.cover_crop_exists.value == null
                            "
                            >No Cover Cropping</v-col
                          >
                          <v-col
                            md="2"
                            v-if="
                              covercropInfo.cover_crop_exists.value == false ||
                              covercropInfo.cover_crop_exists.value == null
                            "
                          >
                            <div
                              class="d-flex"
                              v-if="
                                covercropInfo.cover_crop_exists.value ==
                                  false &&
                                covercropInfo.cover_crop_exists.source ==
                                  'User Input'
                              "
                            >
                              <v-lazy>
                                <v-tooltip top
                                  ><template v-slot:activator="{ on, attrs }"
                                    ><v-icon
                                      v-bind="attrs"
                                      v-on="on"
                                      color="success"
                                      >mdi-check-circle</v-icon
                                    ></template
                                  ><span
                                    >You've filled out enough information for
                                    Arva data models to run. Thank you!</span
                                  ></v-tooltip
                                >
                              </v-lazy>
                              <span class="ml-2">Data Complete</span>
                            </div>
                            <!-- Needs Confirmation -->
                            <div
                              class="d-flex"
                              v-else-if="
                                covercropInfo.cover_crop_exists.value ==
                                  false &&
                                covercropInfo.cover_crop_exists.source !=
                                  'User Input'
                              "
                            >
                              <v-lazy>
                                <v-tooltip top
                                  ><template v-slot:activator="{ on, attrs }"
                                    ><v-icon
                                      v-bind="attrs"
                                      v-on="on"
                                      color="yellow"
                                      >mdi-alert-rhombus</v-icon
                                    ></template
                                  ><span
                                    >This information is coming from a public
                                    source. Please include this field when
                                    setting information to confirm it's
                                    data!</span
                                  ></v-tooltip
                                >
                              </v-lazy>
                              <span
                                class="ml-2 cursor blue--text lighten-1 text-bold"
                                @click="handleConfirm(field)"
                                >Confirm Data</span
                              >
                            </div>
                            <!-- Missing Data -->
                            <div
                              class="d-flex"
                              v-else-if="
                                covercropInfo.cover_crop_exists.value == null &&
                                covercropInfo.cover_crop_exists.source !=
                                  'User Input'
                              "
                            >
                              <v-lazy>
                                <v-tooltip top
                                  ><template v-slot:activator="{ on, attrs }"
                                    ><v-icon
                                      v-bind="attrs"
                                      v-on="on"
                                      color="red"
                                      >mdi-alert-circle</v-icon
                                    ></template
                                  ><span
                                    >This field is missing data. Please fill in
                                    its data using the wizard!</span
                                  ></v-tooltip
                                >
                              </v-lazy>
                              <span class="ml-2">Missing Data</span>
                            </div>
                          </v-col>
                        </v-row>
                      </div>
                    </td>
                    <td class="py-2" v-else>
                      <div @click="handleShowRedirect(field)">
                        <v-tooltip top
                          ><template v-slot:activator="{ on, attrs }"
                            ><v-icon v-bind="attrs" v-on="on" color="red"
                              >mdi-alert-circle</v-icon
                            ></template
                          ><span
                            >This field doesn't have data for this year. Add
                            another year in the data spreadsheet or upload data
                            from this year.</span
                          ></v-tooltip
                        ><span class="ml-2 cursor red--text text-bold"
                          >No Data for Year</span
                        >
                      </div>
                    </td>
                    <td class="cursor" @click="handleCopy(field)">
                      <span class="blue--text lighten-1 text-bold">Copy</span>
                    </td>
                    <td v-if="showPracticesView">
                      <span
                        class="py-2"
                        v-if="
                          !field.noYearData &&
                          allCoverCropsComplete().length === 0
                        "
                      >
                        <div
                          v-for="opsSingle in field.opsInfo"
                          :key="opsSingle.idx"
                        >
                          <v-row
                            class="mx-0"
                            :class="
                              opsSingle.cover_crop.indexOf(ccInfo) !=
                              opsSingle.length - 1
                                ? 'row-bottom'
                                : ''
                            "
                            dense
                            v-for="ccInfo in opsSingle.cover_crop.sort(
                              (a, b) => a.crop_id.value - b.crop_id.value
                            )"
                            :key="ccInfo.idx"
                          >
                            <v-tooltip
                              v-if="stackPractices && isAnyDuplicate(ccInfo)"
                              top
                              max-width="300"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" color="orange"
                                  >mdi-alert</v-icon
                                >
                              </template>
                              <span
                                >Can not stack duplicate practice on this
                                field.</span
                              >
                            </v-tooltip>
                            <v-tooltip
                              v-if="isUntargetedCrop(ccInfo)"
                              top
                              max-width="300"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" color="orange"
                                  >mdi-selection-ellipse-remove</v-icon
                                >
                              </template>
                              <span
                                >No practice data is currently being added for
                                {{ cropDisplay[ccInfo.crop_id.value] }}.</span
                              >
                            </v-tooltip>
                          </v-row>
                        </div>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- No Fields Selected Alert -->
      <AlertErrorModal
        v-if="alertErrorModalOpen"
        titleText="No Fields Selected"
        :errorMessage="[
          'You currently have no fields selected to save management practices for. Please select a field to continue.',
        ]"
        @close-modal="alertErrorModalOpen = false"
      />

      <!-- Wizard Confirm Modal -->
      <WizardConfirmModal
        v-if="showConfirmModal"
        :confirmationField="confirmationField"
        :confirmationData="confirmationCoverCrops"
        :wantedKeys="[
          'crop_id',
          'cover_crop_exists',
          'cover_crop_start_date',
          'cover_crop_end_date',
          'family',
          'type',
          'interseed_cover',
        ]"
        :year="year"
        :loadState="loadState"
        @close-template-modal="handleCloseConfirm"
        @confirm-data="handleModalConfirm"
      />

      <ConfirmModal
        v-if="showSaveConfirmModal"
        titleText="Confirm Practice Replacement"
        :confirmText="saveConfirmText"
        @confirm="handleSave"
        @close-modal="showSaveConfirmModal = false"
      />

      <!-- Redirect -->
      <WizardRedirectModal
        v-if="showRedirectModal"
        :redirectField="redirectField"
        :year="year"
        @close-template-modal="handleCloseRedirect"
        @redirect-confirm="handleRedirectConfirm"
      />
    </div>
    <div v-else>
      <v-card elevation="0">
        <v-card-text class="text-center">
          <span class="text-h4"
            >No Fields Selected. Please select a field to apply management
            practices.</span
          >
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import _ from "lodash"
import { mapActions, mapGetters, mapMutations, mapState } from "vuex"
import { Defaults, Organization } from "@/store/modules"
import {
  CROP_ID_KEY,
  CROP_KEY,
  CROP_DISPLAY_NAME,
  YES_NO_OPTIONS,
} from "@/constants"
import { MAX_DEFAULT_FIELDS } from "@/constants/defaults"
import AlertErrorModal from "@/components/modals/AlertErrorModal"
import WizardConfirmModal from "@/components/modals/WizardConfirmModal"
import WizardRedirectModal from "@/components/modals/WizardRedirectModal"
import ConfirmModal from "@/components/modals/ConfirmModal"
import PracticeChoiceDropdown from "@/components/dropdowns/PracticeChoiceDropdown"
import { mapPayloadKeysToUserInput } from "./utils"
import AudioRecorder from "./AudioRecorder.vue"
import Vue from "vue"

export default {
  name: "NewCoverCropWizard",
  props: {
    selectedFields: { type: Array },
    year: { type: String },
    yearList: { type: Array },
    dataCompletionMaster: { type: Object },
  },
  components: {
    AlertErrorModal,
    WizardConfirmModal,
    WizardRedirectModal,
    PracticeChoiceDropdown,
    ConfirmModal,
    AudioRecorder,
  },
  data() {
    return {
      // constants
      cropDisplay: CROP_DISPLAY_NAME,
      // data values
      numCoverCrops: 0,
      coverCropData: [],
      stackPractices: true,
      // options
      cropChoices: Object.values(CROP_KEY),
      yesNoOptions: YES_NO_OPTIONS,
      MAX_DEFAULT_FIELDS: MAX_DEFAULT_FIELDS,
      // table related
      showSelectedFields: true,
      fieldsInSelection: [],
      filterCrops: [],
      filterCropsReflection: [],
      tableFilterText: "",
      selectAllBox: true,
      // full validation for cover crop completeness
      showIncompleteModal: false,
      incompleteCoverCrops: null,
      alertErrorModalOpen: false,
      // confirmation
      confirmationField: null,
      confirmationCoverCrops: null,
      showConfirmModal: false,
      showSaveConfirmModal: false,
      loadState: null,
      // redirection
      showRedirectModal: false,
      redirectField: null,
      // select crop type UX
      cropSelect: null,
      //   new UI
      showPracticesView: false,
      showSaveView: false,
      showSummaryView: false,
      summaryFields: null,
      failedFields: [],
      summaryKeys: null,
      summaryData: null,
    }
  },
  computed: {
    ...mapState({
      opsSettings: state => state.Defaults.newFieldSettings,
    }),
    ...mapGetters({
      recentWizardData: Defaults.Getters.getRecentWizardData,
      practiceChoices: Defaults.Getters.getPracticeChoices,
    }),
    familyChoices() {
      return this.practiceChoices["cover_crop_family"]
    },
    cropChoicesFiltered() {
      if (this.cropSelect != null) {
        const filteredCropList = Object.entries(CROP_KEY)
          .filter(o => {
            if (this.cropSelect.length > 1) {
              return this.cropSelect.includes(o[0])
            } else {
              return true
            }
          })
          .map(list => list[1])
        return filteredCropList
      } else {
        const selectedOpSettings = this.opsSettingsByField.filter(field =>
          this.fieldsInSelection.includes(parseInt(field.fieldId))
        )
        const selectedCrops = new Set(
          selectedOpSettings.flatMap(field => field.crops)
        )
        const selectedCropNames = Array.from(selectedCrops).map(
          c => CROP_KEY[c]
        )
        return selectedCropNames.filter(i => i != undefined)
      }
    },
    hasPracticeData() {
      return this.coverCropData.length > 0 ? true : false
    },
    dataCompletionForYear() {
      if (!this.year || this.selectedFields.length == 0) {
        return 0
      }
      let yearDataComps = this.dataCompletionMaster["Cover Cropping"].map(o => {
        if ("by_year" in o.data_comp) {
          let temp = o.data_comp.by_year.find(
            yearObj => yearObj.year == this.year
          )
          if (temp) {
            return temp.avg_for_year
          }
        } else {
          return 0
        }
      })
      let average = array => array.reduce((a, b) => a + b) / array.length
      if (yearDataComps.length > 0) {
        return Math.ceil(average(yearDataComps))
      } else {
        return 0
      }
    },
    fieldLocks() {
      let fieldLocks = {}
      for (const field of this.opsSettingsByField) {
        fieldLocks[field.fieldId] = field.fieldLocked
      }
      return fieldLocks
    },
    saveConfirmText() {
      let countText
      if (this.coverCropData.length) {
        countText =
          `${this.coverCropData.length} cover crop` +
          (this.coverCropData.length > 1 ? "s" : "")
      } else {
        countText = `No Cover Crop`
      }
      return `
        Are you sure you want to replace practices on the ${this.fieldsInSelection.length} selected fields for ${this.year}?
        <br /><br />
        Existing cover crops will be removed and replaced with ${countText}.`
    },
    opsSettingsByField() {
      let opsDict = {}
      let yearInConsderation = this.year

      Object.entries(this.opsSettings).forEach(opsDefault => {
        const fieldKey = opsDefault[0]

        const selField = this.selectedFields.find(field => field.id == fieldKey)
        if (!selField) {
          return
        }
        opsDict[fieldKey] = {}
        const fieldSettingObj = opsDefault[1]
        const idSettings = {
          fieldId: fieldKey,
          fieldName: selField.name,
          farmName: selField.farm.name,
          fieldImg: selField.boundary_image,
          opsId: fieldSettingObj.operation_id,
        }

        const hasYearData = fieldSettingObj.year_data.some(
          y => y.year == yearInConsderation
        )

        fieldSettingObj.year_data.forEach(yearData => {
          if (!hasYearData) {
            opsDict[fieldKey] = idSettings
            opsDict[fieldKey]["crops"] = ["No Data"]
            opsDict[fieldKey]["cropNames"] = ["No Data"]
            opsDict[fieldKey]["noYearData"] = true
          } else if (yearData.year == yearInConsderation) {
            opsDict[fieldKey] = idSettings
            opsDict[fieldKey]["crops"] = []
            opsDict[fieldKey]["cropNames"] = []
            opsDict[fieldKey]["cropSearch"] = ""
            opsDict[fieldKey]["plantings"] = []
            opsDict[fieldKey]["opsInfo"] = []

            opsDict[fieldKey]["isSelected"] = this.fieldsInSelection.includes(
              parseInt(fieldKey)
            )
              ? true
              : false

            const cultivations = yearData.cultivations.sort(
              (a, b) => a.arva_crop_id - b.arva_crop_id
            )
            cultivations.forEach(cultivation => {
              opsDict[fieldKey]["crops"].push(cultivation.arva_crop_id)
              opsDict[fieldKey]["cropNames"].push(
                CROP_DISPLAY_NAME[cultivation.arva_crop_id]
              )
              opsDict[fieldKey]["cropSearch"] =
                opsDict[fieldKey]["cropSearch"] +
                CROP_DISPLAY_NAME[cultivation.arva_crop_id]
              opsDict[fieldKey]["plantings"] = opsDict[fieldKey][
                "plantings"
              ].concat(cultivation.plantings)
              opsDict[fieldKey]["opsInfo"].push(cultivation.operations)

              opsDict[fieldKey]["crops"].sort()
              opsDict[fieldKey]["cropNames"].sort()
              opsDict[fieldKey]["fieldLocked"] = cultivation.year_data_locked
              opsDict[fieldKey]["noYearData"] = false
            })
          }
        })
      })
      return Object.values(opsDict)
    },
    opsSettingsbyFieldFiltered() {
      // returns sorted list for table, only reduces list when filtering by name
      let arrayToReturn = _.cloneDeep(this.opsSettingsByField)

      if (this.tableFilterText) {
        const searchString = this.tableFilterText.toLocaleLowerCase()
        arrayToReturn = arrayToReturn.filter(field =>
          field.fieldName.toLowerCase().includes(searchString)
        )
      }

      if (this.cropSelect) {
        arrayToReturn = arrayToReturn.filter(field => {
          const found = this.cropSelect.every(
            c => field.crops.indexOf(parseInt(c)) >= 0
          )
          return found
        })
      }

      const sortColumns = ["fieldName"]
      const sortDirections = ["desc", "asc"]
      return _.orderBy(arrayToReturn, sortColumns, sortDirections)
    },
    historicalPlantings() {
      const something = _(this.opsSettingsByField)
        .groupBy("crops")
        .map((items, crops) => ({
          crops: crops,
          fieldIds: _.map(items, "fieldId"),
          fieldNames: _.map(items, "fieldName"),
        }))
        .value()
      return something
    },
    filterCropList() {
      return [
        ...new Set(this.opsSettingsByField.map(f => f.cropNames.join(", "))),
      ]
    },
    yearListModified() {
      return this.yearList.filter(o => !isNaN(o))
    },
    yearPriorOne() {
      return String(Number(this.year) - 1)
    },
    yearExtendedOne() {
      return String(Number(this.year) + 1)
    },
  },
  methods: {
    ...mapActions({
      updateYear: Organization.Actions.updateYear,
      updateCoverCropping: Defaults.Actions.updateCoverCropping,
    }),
    ...mapMutations({
      updateNewFieldSetting: Defaults.Mutations.updateNewFieldSetting,
      setRecentWizardData: Defaults.Mutations.setRecentWizardData,
    }),
    blankCoverCropData(cropValue = null) {
      return {
        type: null,
        family: null,
        crop_id: cropValue,
        grass_cover: null,
        legume_cover: null,
        removal_cover: null,
        interseed_cover: false,
        cover_crop_exists: null,
        cover_crop_end_date: null,
        cover_crop_start_date: null,
        // for UI control
        showAllInfo: true,
        startMenu: false, // boolean for v-menu
        startPicker: this.year + "-01",
        endMenu: false,
        endPicker: this.year + "-01",
      }
    },
    extractCoverCropData(field) {
      const fieldCoverCropping = []
      field.opsInfo.forEach(opsData => {
        opsData.cover_crop.forEach(cc => fieldCoverCropping.push(cc))
      })
      const numCoverCrops = fieldCoverCropping.length
      this.numCoverCrops = numCoverCrops

      const extractedData = []
      fieldCoverCropping.forEach(cc => {
        extractedData.push({
          type: cc.type.value,
          family: cc.family.value,
          crop_id: CROP_KEY[cc.crop_id.value],
          grass_cover: cc.grass_cover.value,
          legume_cover: cc.legume_cover.value,
          removal_cover: cc.removal_cover.value,
          interseed_cover: cc.interseed_cover.value,
          cover_crop_exists: cc.cover_crop_exists.value,
          cover_crop_end_date: cc.cover_crop_end_date.value,
          cover_crop_start_date: cc.cover_crop_start_date.value,
          showAllInfo: true,
          startMenu: false,
          startPicker: this.year + "-01",
          endMenu: false,
          endPicker: this.year + "-01",
        })
      })
      return extractedData
    },
    handleCopy(field) {
      this.cropSelect = null

      const copiedData = this.extractCoverCropData(field)
      for (const practice of copiedData) {
        if (!this.cropChoicesFiltered.includes(practice.crop_id)) {
          practice.crop_id = null
        }
      }
      this.coverCropData = copiedData

      this.showSummaryView = false
      this.showPracticesView = true

      const myElement = document.getElementById("input-card-container")
      myElement.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      })
    },

    isCoverCropComplete(cc) {
      return (
        cc.type &&
        cc.family &&
        cc.crop_id &&
        cc.interseed_cover != null &&
        cc.cover_crop_start_date
      )
    },
    allCoverCropsComplete() {
      if (!this.coverCropData) return [0]
      const covercropCompleteness = this.coverCropData.map(cc =>
        this.isCoverCropComplete(cc)
      )
      const covercropsMissing = covercropCompleteness.map((value, index) =>
        value == null ? index : ""
      )
      const indexOfMissing = covercropsMissing.filter(String)
      return indexOfMissing
    },
    handleRemoveCC(ccIndex) {
      this.coverCropData.splice(ccIndex, 1)
    },
    handleSpeechToPractice(practiceResponse) {
      const practiceIndex = practiceResponse["index"]
      const practiceData = practiceResponse["response"]
      const blankPractice = this.blankCoverCropData()
      const newPractice = { ...blankPractice, ...practiceData }
      if (!this.cropChoicesFiltered.includes(newPractice.crop_id)) {
        newPractice.crop_id = null
      }
      Vue.set(this.coverCropData, practiceIndex, newPractice)
    },
    handlePreSave() {
      if (this.stackPractices) {
        this.handleSave()
      } else {
        this.showSaveConfirmModal = true
      }
    },
    isAnyDuplicate(existingCc) {
      for (const newCc of this.coverCropData) {
        if (
          this.isCoverCropDuplicate(
            existingCc,
            mapPayloadKeysToUserInput(newCc)
          )
        ) {
          return true
        }
      }
      return false
    },
    isUntargetedCrop(cc) {
      if (this.coverCropData.length == 0) {
        return false
      }
      const targetedCrops = this.coverCropData.map(
        cc => CROP_ID_KEY[cc.crop_id]
      )
      return cc.crop_id.value && !targetedCrops.includes(cc.crop_id.value)
    },
    isCoverCropDuplicate(cc1, cc2) {
      return (
        cc1.crop_id.value == cc2.crop_id.value &&
        cc1.cover_crop_start_date.value == cc2.cover_crop_start_date.value &&
        cc1.cover_crop_end_date.value == cc2.cover_crop_end_date.value &&
        cc1.family.value == cc2.family.value &&
        cc1.type.value == cc2.type.value &&
        cc1.interseed_cover.value == cc2.interseed_cover.value
      )
    },
    isCoverCropNone(cc) {
      return (
        (cc.type == "none" || cc.type == null) &&
        (cc.family == "none" || cc.family == null)
      )
    },
    mergeFieldPayload(field, coverCropPayload) {
      if (this.coverCropData.length == 0 || !this.stackPractices) {
        return coverCropPayload
      }

      let fieldCcData = this.extractCoverCropData(field)
      fieldCcData = fieldCcData.filter(cp => !this.isCoverCropNone(cp))
      let mappedData = fieldCcData.map(cp => {
        return mapPayloadKeysToUserInput(cp)
      })
      mappedData = mappedData.filter(
        existingCc =>
          !coverCropPayload.some(newCc =>
            this.isCoverCropDuplicate(existingCc, newCc)
          )
      )

      const mergedPayload = coverCropPayload.concat(mappedData)
      return mergedPayload
    },
    async handleSave() {
      this.showSaveConfirmModal = false
      if (this.fieldsInSelection.length == 0) {
        this.alertErrorModalOpen = true
        return
      }
      let ccPayload = null
      if (this.coverCropData.length == 0) {
        const fieldsBeingSaved = this.opsSettingsbyFieldFiltered.filter(f =>
          this.fieldsInSelection.includes(Number(f.fieldId))
        )
        const cropsBeingSaved = fieldsBeingSaved
          .map(f => f.crops)
          .reduce((acc, arr) => acc.concat(arr), [])
          .filter((item, index, self) => self.indexOf(item) === index)
        ccPayload = []
        cropsBeingSaved.forEach(cropId => {
          ccPayload.push({
            cover_crop_exists: { value: false, source: "User Input" },
            crop_id: { value: cropId, source: "User Input" },
            grass_cover: { value: null, source: "User Input" },
            legume_cover: { value: null, source: "User Input" },
            interseed_cover: { value: false, source: "User Input" },
            cover_crop_start_date: {
              value: `${this.year}-01-01`,
              source: "User Input",
            },
            cover_crop_end_date: {
              value: `${this.year}-01-01`,
              source: "User Input",
            },
            perennial_cover: { value: null, source: "User Input" },
            removal_cover: { value: null, source: "User Input" },
            type: { value: "none", source: "User Input" },
            family: { value: "none", source: "User Input" },
          })
        })
      } else {
        const covercropCompleteness = this.coverCropData.map(cc =>
          this.isCoverCropComplete(cc)
        )
        const ccMissing = covercropCompleteness.map((value, index) =>
          value == null ? index : ""
        )
        const indexOfMissing = ccMissing.filter(String)

        // which cc missing info
        if (indexOfMissing.length > 0) {
          this.incompleteCoverCrops = indexOfMissing
          this.showIncompleteModal = true
          return
        } else {
          if (this.coverCropData.length > 0) {
            let dataClone = _.cloneDeep(this.coverCropData)
            ccPayload = dataClone.map(cc => {
              delete cc["showAllInfo"]
              delete cc["startMenu"]
              delete cc["startPicker"]
              delete cc["endMenu"]
              delete cc["endPicker"]

              cc = mapPayloadKeysToUserInput(cc)
              if (
                cc.family.value != null &&
                cc.family.value.toLowerCase() != "none"
              ) {
                cc["cover_crop_exists"] = { value: true, source: "User Input" }
              } else {
                cc["cover_crop_exists"] = { value: false, source: "User Input" }
              }

              return cc
            })
          }
        }
      }

      this.showPracticesView = false
      this.showSaveView = true

      let coverCroppingUpdates = []
      this.opsSettingsbyFieldFiltered.forEach(field => {
        if (this.fieldsInSelection.includes(parseInt(field.fieldId))) {
          if (!field.noYearData && !field.fieldLocked) {
            coverCroppingUpdates.push({
              fieldId: field.fieldId,
              promise: this.updateCoverCropping({
                fieldId: field.fieldId,
                year: this.year,
                coverCropping: this.mergeFieldPayload(field, ccPayload),
                noCoverCrop: this.coverCropData.length == 0,
              }),
            })
          } else {
            console.log("no year data for ", field)
          }
        }
      })
      const results = await Promise.allSettled(
        coverCroppingUpdates.map(u => u.promise)
      )
      const failedFields = coverCroppingUpdates
        .filter((item, index) => results[index].status === "rejected")
        .map(item => item.fieldId)
      const successFieldNames = this.opsSettingsbyFieldFiltered
        .filter(f => this.fieldsInSelection.includes(parseInt(f.fieldId)))
        .filter(f => !failedFields.includes(f.fieldId))
        .map(f => f.fieldName)
      const failedFieldNames = this.opsSettingsbyFieldFiltered
        .filter(f => this.fieldsInSelection.includes(parseInt(f.fieldId)))
        .filter(f => failedFields.includes(f.fieldId))
        .map(f => f.fieldName)

      this.$emit("update-data-complete")

      const coverCropWantedKeys = [
        "crop_id",
        "cover_crop_exists",
        "cover_crop_start_date",
        "cover_crop_end_date",
        "family",
        "type",
        "interseed_cover",
      ]
      this.setRecentWizardData({
        wizard: "covercrop",
        data: this.coverCropData,
      })

      this.summaryKeys = coverCropWantedKeys
      this.summaryData = ccPayload
      this.summaryFields = successFieldNames
      this.failedFields = failedFieldNames
      this.showSaveView = false
      this.showSummaryView = true
      this.coverCropData = [this.blankCoverCropData()]
      this.stackPractices = true
      const cropValue =
        this.cropSelect != null && this.cropSelect.length == 1
          ? CROP_KEY[parseInt(this.cropSelect[0])]
          : null
      this.coverCropData = [this.blankCoverCropData(cropValue)]
    },
    handleConfirm(field) {
      this.confirmationField = _.cloneDeep(field)
      this.confirmationCoverCrops = []
      field.opsInfo.forEach(ops => {
        ops.cover_crop.forEach(cc => {
          this.confirmationCoverCrops.push(_.cloneDeep(cc))
        })
      })
      this.confirmationCoverCrops.forEach(cc => {
        for (const key in cc) {
          cc[key].source = "User Input"
        }
      })
      this.showConfirmModal = true
    },
    async handleModalConfirm() {
      this.loadState = "loading"
      const noCoverCropConfirm =
        !this.confirmationCoverCrops[0]["cover_crop_exists"]["value"]
      if (noCoverCropConfirm == true) {
        let confirmLength = this.confirmationCoverCrops.length
        this.confirmationCoverCrops = []
        for (let i = 0; i < confirmLength; i++) {
          this.confirmationCoverCrops.push({
            cover_crop_exists: { value: false, source: "User Input" },
            crop_id: {
              value: this.confirmationField.crops[i],
              source: "User Input",
            },
            grass_cover: { value: null, source: "User Input" },
            legume_cover: { value: null, source: "User Input" },
            interseed_cover: { value: false, source: "User Input" },
            cover_crop_start_date: {
              value: `${this.year}-01-01`,
              source: "User Input",
            },
            cover_crop_end_date: {
              value: `${this.year}-01-01`,
              source: "User Input",
            },
            perennial_cover: { value: null, source: "User Input" },
            removal_cover: { value: null, source: "User Input" },
            type: { value: "none", source: "User Input" },
            family: { value: "none", source: "User Input" },
          })
        }
      }
      await this.updateCoverCropping({
        fieldId: this.confirmationField.fieldId,
        year: this.year,
        coverCropping: this.confirmationCoverCrops,
        noCoverCrop: noCoverCropConfirm,
      })
      this.loadState = "confirmed"
      this.$emit("update-data-complete")
      await new Promise(resolve => setTimeout(resolve, 1000))
      this.handleCloseConfirm()
    },
    handleCloseConfirm() {
      this.showConfirmModal = false
      this.confirmationField = null
      this.confirmationCoverCrops = null
      this.loadState = null
    },
    fieldChecked(field, e) {
      if (
        this.fieldsInSelection.includes(parseInt(field.fieldId)) &&
        e == false
      ) {
        this.fieldsInSelection = this.fieldsInSelection.filter(
          f => f != parseInt(field.fieldId)
        )
      }
      if (e == true) {
        this.fieldsInSelection.push(parseInt(field.fieldId))
      }
    },
    handleSelectAll(e, resetCropSelect = false) {
      if (e == false) this.fieldsInSelection = []
      if (e == true) {
        this.fieldsInSelection = this.selectedFields
          .map(f => f.id)
          .filter(f => !this.fieldLocks[f])
      }
      if (resetCropSelect) {
        this.cropSelect = null
      }
    },
    handleShowRedirect(field) {
      this.redirectField = field
      this.showRedirectModal = true
    },
    handleCloseRedirect() {
      this.showRedirectModal = false
      this.redirectField = null
    },
    handleRedirectConfirm() {
      this.$router.push(`/data-spreadsheet/${this.redirectField.fieldId}`)
    },
    coverComplete(cover) {
      if (!cover) return null
      return (
        cover.cover_crop_start_date.source == "User Input" &&
        cover.cover_crop_start_date.value != null &&
        cover.family.source == "User Input" &&
        cover.family.value != null &&
        cover.type.source == "User Input" &&
        cover.type.value != null &&
        cover.interseed_cover.source == "User Input" &&
        cover.interseed_cover.value != null
      )
    },
    coverNeedsConfirm(cover) {
      if (!cover) return null
      return (
        cover.cover_crop_start_date.source != "User Input" &&
        cover.cover_crop_start_date.value != null &&
        cover.cover_crop_end_date.source != "User Input" &&
        cover.cover_crop_end_date.value != null &&
        cover.family.source != "User Input" &&
        cover.family.value != null &&
        cover.type.source != "User Input" &&
        cover.type.value != null &&
        cover.interseed_cover.source != "User Input" &&
        cover.interseed_cover.value != null
      )
    },
    coverMissingData(cover) {
      if (!cover) return null
      return (
        cover.cover_crop_start_date.source != "User Input" ||
        cover.cover_crop_start_date.value == null ||
        cover.cover_crop_end_date.source != "User Input" ||
        cover.cover_crop_end_date.value == null ||
        cover.family.source != "User Input" ||
        cover.family.value == null ||
        cover.type.source != "User Input" ||
        cover.type.value == null ||
        cover.interseed_cover.source != "User Input" ||
        cover.interseed_cover.value == null
      )
    },
    mapCropNames(cropIdString) {
      if (cropIdString == "No Data") {
        return cropIdString
      } else if (cropIdString.includes(",")) {
        const cropIdVals = cropIdString.split(",")
        return cropIdVals.map(v => this.cropDisplay[v]).join(", ")
      } else {
        return this.cropDisplay[cropIdString]
      }
    },
    typesByFamily(family) {
      return this.practiceChoices["cover_crop_type"].filter(
        c => c.dependent_value === family || !c.dependent_value
      )
    },
    handleSeasonSelection(newYear) {
      this.updateYear(newYear)
    },
    disableCheckbox(noYearData) {
      if (noYearData) {
        return true
      } else {
        return false
      }
    },
    handleCropFilterButton(sharedCropPlan) {
      if (
        JSON.stringify(this.cropSelect) ==
        JSON.stringify(sharedCropPlan.crops.split(","))
      ) {
        this.cropSelect = null
        this.filterCrops = []
      } else {
        this.cropSelect = sharedCropPlan.crops.split(",")
        this.filterCrops = [this.mapCropNames(sharedCropPlan.crops)]
        if (this.coverCropData.length > 0) {
          const cropValue =
            this.cropSelect != null && this.cropSelect.length == 1
              ? CROP_KEY[parseInt(this.cropSelect[0])]
              : null
          this.coverCropData.forEach(o => {
            o.crop_id = cropValue
          })
        }
      }
    },
    handleAddPracticesClick() {
      if (this.showSummaryView == false) {
        this.showSaveView = false
        this.showSummaryView = false
        this.showPracticesView = true

        const cropValue =
          this.cropSelect != null && this.cropSelect.length == 1
            ? CROP_KEY[parseInt(this.cropSelect[0])]
            : null
        let newCoverCropData = this.blankCoverCropData()
        newCoverCropData.crop_id = cropValue
        this.coverCropData = [newCoverCropData]
      }
    },
    handleCancelClick() {
      this.showSaveView = false
      this.showSummaryView = false
      this.showPracticesView = false
      this.coverCropData = []
    },
    handleAddEventClick() {
      const newCoverCropData = this.blankCoverCropData()
      newCoverCropData.crop_id =
        this.cropSelect != null && this.cropSelect.length == 1
          ? CROP_KEY[parseInt(this.cropSelect[0])]
          : null
      this.coverCropData.push(newCoverCropData)
    },
    handleNoCoverCropClick() {
      this.coverCropData = []
      this.stackPractices = false
    },
    handleYesCoverCropClick() {
      if (this.coverCropData.length == 0) {
        this.stackPractices = true
      }
      const newCoverCropData = this.blankCoverCropData()
      newCoverCropData.crop_id =
        this.cropSelect != null && this.cropSelect.length == 1
          ? CROP_KEY[parseInt(this.cropSelect[0])]
          : null
      this.coverCropData = [newCoverCropData]
    },
    handleAddMoreCC() {
      this.showSummaryView = false
      this.showPracticesView = true
    },
  },
  mounted() {
    this.fieldsInSelection = this.selectedFields
      .map(f => f.id)
      .filter(f => !this.fieldLocks[f])
    if (this.recentWizardData.wizard == "covercrop") {
      this.numCoverCrops = this.recentWizardData.data.length
      this.coverCropData = _.cloneDeep(this.recentWizardData.data)
    }
  },
  watch: {
    cropChoicesFiltered(newChoices) {
      for (const practice of this.coverCropData) {
        const existsInNewChoices = newChoices.some(
          choice => choice === practice.crop_id
        )
        if (!existsInNewChoices) {
          practice.crop_id = null
        }
      }
    },
    filterCrops: {
      deep: true,
      handler(myArray) {
        this.filterCropsReflection = _.cloneDeep(myArray)

        let arrayToFilter = _.cloneDeep(this.opsSettingsByField)

        if (this.filterCropsReflection.length > 0) {
          let cropsToFilter = _.flatten(
            this.filterCropsReflection.map(i => {
              return i.split(", ")
            })
          )
          arrayToFilter = arrayToFilter.filter(field =>
            cropsToFilter.every(c => field.cropNames.indexOf(c) >= 0)
          )
        }
        this.fieldsInSelection = arrayToFilter
          .map(f => parseInt(f.fieldId))
          .filter(f => !this.fieldLocks[f])
      },
    },
  },
}
</script>
<style scoped>
.second-q {
  padding-top: 48px;
}

.input-date-text {
  font-weight: 700;
  font-size: 22px;
}

.step-height {
  min-height: 400px;
  max-height: 400px;
  height: 400px;
}

.min-step-height {
  min-height: 400px;
}

.row-margin-correct {
  margin-left: 0px;
  margin-right: 0px;
}

.text-container {
  width: 200px;
}

.fixed-table {
  table-layout: fixed;
}

.three {
  width: 3%;
}

.five {
  width: 5%;
}

.ten {
  width: 10%;
}

.twenty {
  width: 20%;
}

.twenty {
  width: 20%;
}

.cursor {
  cursor: pointer;
}
</style>
