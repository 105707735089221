<template>
  <ArvaModal
    name="confirmModal"
    :title="titleText"
    @close-modal="handleCloseModal"
  >
    <div v-if="isProcessing" class="zero-state span-text">
      <div class="spinner-border spinner-border-sm ml-1" role="status"></div>
      <span> The system is processing your request. Please wait... </span>
    </div>

    <div v-else>
      <div class="confirm-text" v-html="confirmText"></div>
      <slot />

      <ArvaModalSaveFooter
        confirmText="Confirm"
        cancelText="Cancel"
        :isClosingWithoutSaving="isClosingWithoutSaving"
        @on-close-without-saving="handleCloseWithoutSaving"
        @on-cancel="handleCloseModal"
        @on-confirm="handleConfirm"
      />
    </div>
  </ArvaModal>
</template>

<script>
import ArvaModal from "./ArvaModal"
import ArvaModalSaveFooter from "./ArvaModalSaveFooter"

export default {
  name: "DataImportModal",
  props: {
    confirmText: { type: String },
    titleText: { type: String },
    isProcessing: { type: Boolean },
    isClosingWithoutSaving: { type: Boolean },
  },
  components: { ArvaModal, ArvaModalSaveFooter },

  methods: {
    handleCloseModal() {
      this.$emit("close-modal")
    },

    handleConfirm() {
      this.$emit("confirm")
    },

    handleCloseWithoutSaving() {
      this.$emit("close-without-saving")
    }
  },
}
</script>

<style scoped>
.confirm-text {
  font-size: 15px;
  font-weight: 500;
  padding: 30px 30px;
}
</style>
