<template>
  <div>
    <Permissions package="base" />
    <div v-if="showBase" class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="dashboard-title">
            Opportunity Summary
            <i
              class="fa fa-info-circle opp-info"
              data-toggle="tooltip"
              data-placement="top"
              title="Summary of historical crop operations including estimated increases when Arva AI planning is used."
            />
          </div>
          <div class="infocus-bar">
            {{ this.organization.name }}, {{ numFields | noDecimals }} Fields,
            {{ numAcres | noDecimals }} Acres
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-5 col-md-5 col-12">
          <div class="card dollar-card">
            <div
              class="card-body d-flex justify-content-between align-items-center"
            >
              <div>
                <div class="total-title">Total Estimated Profit Increase</div>
                <div class="profit-subtext">
                  With optimial hybrid placement and carbon sequestration
                </div>
              </div>
              <div class="total-span">
                {{ totalGrossProfitIncrease | largeCurrency }}
              </div>
            </div>
          </div>

          <div class="card dollar-card">
            <div
              class="card-body d-flex justify-content-between align-items-center"
            >
              <div>
                <div class="total-title">Hybrid Placement</div>
                <div class="profit-subtext">
                  With optimal hybrid placement in Arva Ground Types (AGTs)
                  <br />
                  on <b>{{ hybridPlacementAcreage | prettyInteger }}</b> acres
                  with adequate data
                </div>
              </div>
              <div class="total-span">
                {{ hybridPlacement | largeCurrency }}
              </div>
            </div>
          </div>

          <div class="card dollar-card">
            <div
              class="card-body d-flex justify-content-between align-items-center"
            >
              <div>
                <div class="total-title">Carbon Sequestration</div>
                <div v-if="carbonSequester > 0" class="profit-subtext">
                  <b>{{ carbonSequester | prettyInteger }}</b> tons of
                  sequestration opportunity on
                  <b>{{ carbonSequesterAcreage | prettyInteger }}</b> acres with
                  adequate data
                </div>
                <div v-else class="profit-subtext">
                  Upload soil samples with organic matter measurements to
                  estimate carbon sequestration opportunity
                </div>
              </div>
              <div class="total-span">
                {{ carbonSequesterProfit | largeCurrency }}
              </div>
            </div>
          </div>

          <div class="card dollar-card">
            <div
              class="card-body d-flex justify-content-between align-items-center"
            >
              <div>
                <div class="total-title">CI Potential</div>

                <div v-if="ciScore == 0" class="profit-subtext">
                  With optimial hybrid placement and carbon sequestration
                </div>

                <div v-else class="profit-subtext d-flex">
                  <p
                    v-b-toggle.sidebar-right
                    class="inline-ci-button inline-ci-button-left"
                  >
                    Update
                  </p>
                  your calculation
                </div>
              </div>

              <CIForm />
            </div>
          </div>

          <div class="card dollar-card">
            <div
              class="card-body d-flex justify-content-between align-items-center"
            >
              <div>
                <div class="total-title">Download Bulk CI Estimates</div>

                <div class="profit-subtext">
                  For all selected fields and crops
                </div>
              </div>

              <BulkCIForm />
            </div>
          </div>
        </div>

        <div class="col-lg-7 col-md-7 col-12">
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <h3 class="data-comp">
                  {{
                    includeFieldLevelInfo
                      ? "Agronomic + Sustainability"
                      : "Agronomic"
                  }}
                  Data Completion
                </h3>
                <div
                  class="toggle-checkbox toggle-sm toggle-success mr-2 d-flex align-items-center"
                >
                  <span
                    >Include Field Level Info
                    <i
                      class="fa fa-info-circle"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Field-Level information will be included in data completeness. Turn off for only measured data completeness."
                  /></span>
                  <input
                    type="checkbox"
                    class="checked mx-3"
                    :checked="includeFieldLevelInfo"
                    id="completionDefaultsCheckbox"
                    @click="updateIncludeDefaults(!includeFieldLevelInfo)"
                  />
                  <label
                    for="completionDefaultsCheckbox"
                    class="form-check-label"
                  />
                </div>
              </div>
              <div v-if="includeFieldLevelInfo == false">
                <DataCompletionChart
                  :data="completionData"
                  :totalAcreage="numAcres"
                />
              </div>
              <div v-else>
                <apexchart
                  height="230"
                  :options="chartOptions"
                  :series="completionData.map(i => i.completion)"
                />
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <h3 class="data-comp">Sustainability Data Completion</h3>
              </div>
              <!-- sustainability practices only data vis -->
              <div>
                <apexchart
                  height="230"
                  :options="sustainabilityChartOptions"
                  :series="sustainabilityCompletionData.map(i => i.completion)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="roiSummary && roiSummary.length > 0">
        <h3>{{ year }} Historical Financials</h3>
        <div class="summary-table">
          <v-simple-table>
            <thead>
              <tr>
                <th />
                <th>Crop</th>
                <th class="text-right">Total Acres</th>
                <th class="text-right">Yield</th>
                <th class="text-right">Revenue</th>
                <th class="text-right">
                  Seed Costs
                  <i
                    class="fa fa-info-circle"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Default seed costs may not reflect your actual costs. To improve and/or adjust your ROI financials, click on FIELDS | LIST VIEW from the menu on the left, then edit the 'Financials / ROI' settings to set specific field settings information."
                  />
                </th>
                <th class="text-right">
                  Fertilizer Costs
                  <i
                    class="fa fa-info-circle"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Default fertilizer costs may not reflect your actual costs. To improve and/or adjust your ROI financials, click on FIELDS | LIST VIEW from the menu on the left, then edit the 'Financials / ROI' settings to set specific field settings information."
                  />
                </th>
                <th class="text-right">
                  Estimated Op Costs
                  <i
                    class="fa fa-info-circle"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Est. Operating Costs include chemicals, irrigation, labor, and fuel. It excludes land rent and equipment recovery costs."
                  />
                </th>
                <th class="text-right">Gross Profit</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="row in roiSummary" :key="row.crop">
                <td><img class="crop-icon" :src="cropIconUrls[row.crop]" /></td>
                <td>{{ row.crop | crop }}</td>
                <td class="text-right">{{ row.acreage | prettyInteger }}</td>
                <td class="text-right">
                  <div>{{ row.yield | prettyInteger }}</div>
                  <div class="per-ac">
                    {{ (row.yield / row.acreage) | prettyInteger }}
                    {{ row.crop | yieldUnits }}
                  </div>
                </td>
                <td class="text-right">
                  <div>{{ row.revenue | intCurrency }}</div>
                  <div class="per-ac">
                    {{ (row.revenue / row.acreage) | intCurrency }}
                  </div>
                </td>
                <td class="text-right">
                  <div>{{ row.seedCost | intCurrency }}</div>
                  <div class="per-ac">
                    {{ (row.seedCost / row.acreage) | intCurrency }} / ac.
                  </div>
                </td>
                <td class="text-right">
                  <div>{{ row.fertCost | intCurrency }}</div>
                  <div class="per-ac">
                    {{ (row.fertCost / row.acreage) | intCurrency }} / ac.
                  </div>
                </td>
                <td class="text-right">
                  <div>{{ row.opCost | intCurrency }}</div>
                  <div class="per-ac">
                    {{ (row.opCost / row.acreage) | intCurrency }} / ac.
                  </div>
                </td>
                <td class="text-right">
                  <div>{{ row.profit | intCurrency }}</div>
                  <div class="per-ac">
                    {{ row.profitPerAcre | intCurrency }} / ac.
                  </div>
                </td>
              </tr>
              <tr class="total-row">
                <td />
                <td>Total</td>
                <td class="text-right">{{ totalAcres | prettyInteger }}</td>
                <td class="text-right">{{ totalYield | prettyInteger }}</td>
                <td class="text-right">{{ totalRevenue | intCurrency }}</td>
                <td class="text-right">{{ totalSeedCost | intCurrency }}</td>
                <td class="text-right">{{ totalFertCost | intCurrency }}</td>
                <td class="text-right">{{ totalOpCost | intCurrency }}</td>
                <td class="text-right">{{ totalGrossProfit | intCurrency }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex"

import DataCompletionChart from "@/components/dashboard/DataCompletionChart"
import CIForm from "@/components/ci/CIForm.vue"
import BulkCIForm from "@/components/ci/BulkCIForm.vue"
import { CROP_ID_TO_ICON_URL } from "@/constants"
import { Dashboard, Fields, Filter, Map } from "@/store/modules"
import Permissions from "@/components/permissions/Permissions"

export default {
  name: "DashboardSummary",
  components: { DataCompletionChart, CIForm, BulkCIForm, Permissions },
  data() {
    return {
      cropIconUrls: CROP_ID_TO_ICON_URL,
    }
  },
  computed: {
    ...mapGetters({
      fields: Fields.Getters.getFields,
      activeFields: Filter.Getters.getSelectedFields,
      selectedCrops: Filter.Getters.getSelectedCrops,
      activeCrops: Filter.Getters.getDashboardCrops,
      roiSummary: Dashboard.Getters.getRoiSummary,
      includeFieldLevelInfo: Map.Getters.getIncludeDefaults,
    }),

    ...mapState({
      organization: state => state.Organization.organization,
      summaryData: state => state.Dashboard.summary,
      year: state => state.Organization.year,
      carbonCreditDollarValue: state => state.Dashboard.carbonCreditDollarValue,
      ciScore: state => state.Dashboard.ciScore,
      showBase: state => state.Organization.organization.showBase,
    }),

    activeFieldIds() {
      return this.activeFields.map(field => field.id)
    },

    numFields() {
      return this.activeFields.length
    },

    numAcres() {
      return this.activeFields.reduce((prev, curr) => prev + curr.acreage, 0)
    },

    hybridPlacementAcreage() {
      const acresWithHybridPlacement = this.activeFields
        .map(field => {
          const val = field.historicalROI[this.year]
            ? field.historicalROI[this.year]
                .hybrid_placement_revenue_improvement
            : 0
          return [field.acreage, val > 0]
        })
        .filter(tuple => tuple[1] === true)
        .map(tuple => tuple[0])
        .reduce((prev, curr) => {
          return prev + curr
        }, 0)
      return acresWithHybridPlacement
    },

    hybridPlacement() {
      return this.activeFields.reduce((prev, curr) => {
        const next = curr.historicalROI[this.year]
          ? curr.historicalROI[this.year].hybrid_placement_revenue_improvement
          : 0
        return prev + next
      }, 0)
    },

    carbonSequesterAcreage() {
      const acresWithCarbonCalc = this.activeFields
        .map(field => {
          const val = field.daycent
            ? field.daycent
                .map(
                  d =>
                    (d.sysc * 3.666 * 4046.86 +
                      d.ch4 * 24 * 4046.86 +
                      d.n20 * 298 * 4046.86) /
                    1000000
                )
                .reduce((prev, curr) => prev + curr, 0)
            : 0
          return [field.acreage, val > 0]
        })
        .filter(tuple => tuple[1] === true)
        .map(tuple => tuple[0])
        .reduce((prev, curr) => {
          return prev + curr
        }, 0)
      return acresWithCarbonCalc
    },

    carbonSequester() {
      const basicCalc = this.activeFields.reduce((prev, curr) => {
        const next = curr.historicalROI[this.year]
          ? curr.historicalROI[this.year].carbon_opportunity
          : 0
        return prev + next
      }, 0)

      const daycentCalc = []
      this.activeFields.forEach(field => {
        const credits = field.daycent.map(d => {
          const co2Equiv =
            (d.sysc * 3.666 * 4046.86 +
              d.ch4 * -1 * 24 * 4046.86 +
              d.n20 * -1 * 298 * 4046.86) /
            1000000
          const creditAmount = co2Equiv * field.acreage
          return creditAmount
        })
        daycentCalc.push(...credits)
      })

      // if any fields have daycent information, calculate from that
      return daycentCalc.length > 0
        ? daycentCalc.reduce((prev, curr) => prev + curr, 0)
        : basicCalc
    },

    carbonSequesterProfit() {
      const carbonPricePerTon = this.carbonCreditDollarValue
      const calc = this.carbonSequester * carbonPricePerTon
      return calc
    },

    totalGrossProfitIncrease() {
      return this.carbonSequesterProfit + this.hybridPlacement
    },

    completionData() {
      const activeFields = this.fields.filter(field =>
        this.activeFieldIds.includes(field.id)
      )
      const average = array => array.reduce((a, b) => a + b) / array.length

      if (this.includeFieldLevelInfo) {
        if (activeFields.length > 0) {
          const plantingCompletion = activeFields.map(f => {
            let res = f.defaults_data_completion.planting.by_year.find(
              planting => planting.year == parseInt(this.year)
            )
            if (res) {
              return res.avg_for_year
            } else {
              return 0
            }
          })
          const harvestCompletion = activeFields.map(f => {
            let res = f.defaults_data_completion.harvest.by_year.find(
              harvest => harvest.year == parseInt(this.year)
            )
            if (res) {
              return res.avg_for_year
            } else {
              return 0
            }
          })
          const fertilizerCompletion = activeFields.map(f => {
            let res = f.defaults_data_completion.fert.by_year.find(
              fert => fert.year == parseInt(this.year)
            )
            if (res) {
              return res.avg_for_year
            } else {
              return 0
            }
          })

          const soilSamplesAcres = activeFields.reduce((prev, field) => {
            let { data_completion } = field
            // TODO: the "null" below is for the soil-only data comp.
            data_completion = data_completion || {}
            data_completion =
              data_completion[this.year] || data_completion["null"] || {}
            const { soil_samples_acres: soilSampleAcres = 0 } = data_completion
            const value = soilSampleAcres
            return prev + value
          }, 0)

          const soilTextureAcres = activeFields.reduce((prev, field) => {
            let { data_completion } = field

            // TODO: the "null" below is for the soil-only data comp.
            data_completion = data_completion || {}
            data_completion =
              data_completion[this.year] || data_completion["null"] || {}
            const { soil_texture_acres: soilTextureAcres = 0 } = data_completion
            const value = soilTextureAcres
            return prev + value
          }, 0)

          const output = [
            {
              label: "Planting",
              completion: Math.round(average(plantingCompletion)),
            },
            {
              label: "Harvest",
              completion: Math.round(average(harvestCompletion)),
            },
            {
              label: "Fertilizer",
              completion: Math.round(average(fertilizerCompletion)),
            },
            {
              label: "Soil Samples",
              completion: Math.round((soilSamplesAcres / this.numAcres) * 100),
            },
            {
              label: "Soil Texture",
              completion: Math.round((soilTextureAcres / this.numAcres) * 100),
            },
          ]
          return output
        } else {
          const noFieldsOutput = [
            {
              label: "Planting",
              completion: 0,
            },
            {
              label: "Harvest",
              completion: 0,
            },
            {
              label: "Fertilizer",
              completion: 0,
            },
            {
              label: "Soil Samples",
              completion: 0,
            },
            {
              label: "Soil Texture",
              completion: 0,
            },
          ]
          return noFieldsOutput
        }
      } else {
        if (activeFields.length > 0) {
          const plantingAcres = activeFields.reduce((prev, field) => {
            let { data_completion, defaults_data_completion } = field

            if (!data_completion && !this.includeFieldLevelInfo) return prev

            data_completion = data_completion || {}
            data_completion = data_completion[this.year] || {}
            if (defaults_data_completion) {
              defaults_data_completion =
                defaults_data_completion[this.year] || {}
            } else {
              defaults_data_completion = {}
            }

            const { planting_acres = 0 } = data_completion
            const { planting_acres: defaultsPlantingAcres = 0 } =
              defaults_data_completion

            const value = this.includeFieldLevelInfo
              ? Math.max(planting_acres, defaultsPlantingAcres)
              : planting_acres

            return prev + value
          }, 0)

          const harvestAcres = activeFields.reduce((prev, field) => {
            let { data_completion, defaults_data_completion } = field

            if (!data_completion && !this.includeFieldLevelInfo) return prev

            data_completion = data_completion || {}
            data_completion = data_completion[this.year] || {}
            if (defaults_data_completion) {
              defaults_data_completion =
                defaults_data_completion[this.year] || {}
            } else {
              defaults_data_completion = {}
            }

            const { harvest_acres = 0 } = data_completion
            const { harvest_acres: defaultsHarvestAcres = 0 } =
              defaults_data_completion

            const value = this.includeFieldLevelInfo
              ? Math.max(harvest_acres, defaultsHarvestAcres)
              : harvest_acres
            return prev + value
          }, 0)

          const fertAcres = activeFields.reduce((prev, field) => {
            let { data_completion, defaults_data_completion } = field

            if (!data_completion && !this.includeFieldLevelInfo) return prev

            data_completion = data_completion || {}
            data_completion = data_completion[this.year] || {}
            if (defaults_data_completion) {
              defaults_data_completion =
                defaults_data_completion[this.year] || {}
            } else {
              defaults_data_completion = {}
            }

            const { fertilizer_acres = 0 } = data_completion
            const { fertilizer_acres: defaultsFertAcres = 0 } =
              defaults_data_completion

            const value = this.includeFieldLevelInfo
              ? Math.max(fertilizer_acres, defaultsFertAcres)
              : fertilizer_acres

            return prev + value
          }, 0)

          const soilSamplesAcres = activeFields.reduce((prev, field) => {
            let { data_completion, defaults_data_completion } = field

            if (!data_completion && !this.includeFieldLevelInfo) return prev

            // TODO: the "null" below is for the soil-only data comp.
            data_completion = data_completion || {}
            data_completion =
              data_completion[this.year] || data_completion["null"] || {}
            defaults_data_completion = defaults_data_completion || {}

            const { soil_samples_acres: soilSampleAcres = 0 } = data_completion
            const { soil_samples_acres: defaultsSoilSamplesAcres = 0 } =
              defaults_data_completion

            const value = this.includeFieldLevelInfo
              ? Math.max(soilSampleAcres, defaultsSoilSamplesAcres)
              : soilSampleAcres
            return prev + value
          }, 0)

          const soilTextureAcres = activeFields.reduce((prev, field) => {
            let { data_completion, defaults_data_completion } = field

            if (!data_completion && !this.includeFieldLevelInfo) return prev

            // TODO: the "null" below is for the soil-only data comp.
            data_completion = data_completion || {}
            data_completion =
              data_completion[this.year] || data_completion["null"] || {}
            defaults_data_completion = defaults_data_completion || {}

            const { soil_texture_acres: soilTextureAcres = 0 } = data_completion
            const { soil_texture_acres: defaultsSoilTextureAcres = 0 } =
              defaults_data_completion

            const value = this.includeFieldLevelInfo
              ? Math.max(soilTextureAcres, defaultsSoilTextureAcres)
              : soilTextureAcres
            return prev + value
          }, 0)

          return [
            { label: "Planting", acreage: plantingAcres },
            { label: "Harvest", acreage: harvestAcres },
            { label: "Fertilizer", acreage: fertAcres },
            { label: "Soil Samples", acreage: soilSamplesAcres },
            { label: "Soil Texture", acreage: soilTextureAcres },
          ]
        } else {
          const noFieldsOutput = [
            {
              label: "Planting",
              completion: 0,
            },
            {
              label: "Harvest",
              completion: 0,
            },
            {
              label: "Fertilizer",
              completion: 0,
            },
            {
              label: "Soil Samples",
              completion: 0,
            },
            {
              label: "Soil Texture",
              completion: 0,
            },
          ]
          return noFieldsOutput
        }
      }
    },

    sustainabilityCompletionData() {
      const activeFields = this.fields.filter(field =>
        this.activeFieldIds.includes(field.id)
      )

      var output = []
      if (activeFields.length > 0) {
        const average = array => array.reduce((a, b) => a + b) / array.length

        const coverCroppingCompletion = activeFields.map(f => {
          let res = f.defaults_data_completion.cover_cropping.by_year.find(
            cc => cc.year == parseInt(this.year)
          )
          if (res) {
            return res.avg_for_year
          } else {
            return 0
          }
        })
        const cropProtectionCompletion = activeFields.map(f => {
          let res = f.defaults_data_completion.crop_protection.by_year.find(
            cp => cp.year == parseInt(this.year)
          )
          if (res) {
            return res.avg_for_year
          } else {
            return 0
          }
        })
        const irrigationCompletion = activeFields.map(f => {
          let res = f.defaults_data_completion.irrig.by_year.find(
            irrig => irrig.year == parseInt(this.year)
          )
          if (res) {
            return res.avg_for_year
          } else {
            return 0
          }
        })
        const limingCompletion = activeFields.map(f => {
          let res = f.defaults_data_completion.liming.by_year.find(
            lime => lime.year == parseInt(this.year)
          )
          if (res) {
            return res.avg_for_year
          } else {
            return 0
          }
        })
        const tillageCompletion = activeFields.map(f => {
          let res = f.defaults_data_completion.tillage.by_year.find(
            till => till.year == parseInt(this.year)
          )
          if (res) {
            return res.avg_for_year
          } else {
            return 0
          }
        })

        output = [
          {
            label: "Cover Cropping",
            completion: Math.round(average(coverCroppingCompletion)),
          },
          {
            label: "Crop Protection",
            completion: Math.round(average(cropProtectionCompletion)),
          },
          {
            label: "Irrigation",
            completion: Math.round(average(irrigationCompletion)),
          },
          {
            label: "Liming",
            completion: Math.round(average(limingCompletion)),
          },
          {
            label: "Tillage",
            completion: Math.round(average(tillageCompletion)),
          },
        ]
      } else {
        output = [
          {
            label: "Cover Cropping",
            completion: 0,
          },
          {
            label: "Crop Protection",
            completion: 0,
          },
          {
            label: "Irrigation",
            completion: 0,
          },
          {
            label: "Liming",
            completion: 0,
          },
          {
            label: "Tillage",
            completion: 0,
          },
        ]
      }

      return output
    },

    totalAcres() {
      let totalAcres = 0
      for (const row of this.roiSummary) totalAcres += row.acreage
      return totalAcres
    },

    totalYield() {
      let totalYield = 0
      for (const row of this.roiSummary) totalYield += row.yield
      return totalYield
    },

    totalRevenue() {
      let totalRevenue = 0
      for (const row of this.roiSummary) totalRevenue += row.revenue
      return totalRevenue
    },

    totalSeedCost() {
      let totalSeedCost = 0
      for (const row of this.roiSummary) totalSeedCost += row.seedCost
      return totalSeedCost
    },

    totalFertCost() {
      let totalFertCost = 0
      for (const row of this.roiSummary) totalFertCost += row.fertCost
      return totalFertCost
    },

    totalOpCost() {
      let totalOpCost = 0
      for (const row of this.roiSummary) totalOpCost += row.opCost
      return totalOpCost
    },

    totalGrossProfit() {
      let totalProfit = 0
      for (const row of this.roiSummary) totalProfit += row.profit
      return totalProfit
    },

    chartOptions() {
      const labels = this.completionData.map(d => d.label)
      const totalLabel = `${Math.round(this.numAcres)} ac`
      return {
        chart: { type: "radialBar" },
        colors: [
          "#17986A",
          "#046E94",
          "#2E85D0",
          "#0A3389",
          "#18FFFF",
          "#558B2F",
        ],
        plotOptions: {
          radialBar: {
            // NOTE - Rotates polar clock angle
            // startAngle: -180,
            // endAngle: 180,
            dataLabels: {
              name: { fontSize: "22px" },
              value: { fontSize: "16px" },
              total: {
                show: true,
                label: totalLabel,
                formatter: () => "",
              },
            },
            hollow: {
              margin: 0,
              size: "40%",
              background: "transparent",
            },
          },
        },
        stroke: { lineCap: "round" },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "diagonal1",
            gradientToColors: [
              "#17986A",
              "#046E94",
              "#2E85D0",
              "#0A3389",
              "#18FFFF",
              "#558B2F",
            ],
            stops: [0, 100],
          },
        },
        labels,
        legend: {
          show: true,
          floating: true,
          position: "left",
          offsetY: 5,
        },
      }
    },

    sustainabilityChartOptions() {
      const labels = this.sustainabilityCompletionData.map(d => d.label)
      // const totalLabel = "Carbon Ready"
      return {
        chart: { type: "radialBar" },
        colors: [
          "#17986A",
          "#046E94",
          "#2E85D0",
          "#0A3389",
          "#18FFFF",
          "#558B2F",
        ],
        plotOptions: {
          radialBar: {
            // NOTE - Rotates polar clock angle
            // startAngle: -180,
            // endAngle: 180,
            dataLabels: {
              name: { fontSize: "22px" },
              value: { fontSize: "16px" },
              // total: {
              //   show: true,
              //   label: totalLabel,
              //   formatter: () => "",
              // },
            },
            hollow: {
              margin: 0,
              size: "40%",
              background: "transparent",
            },
          },
        },
        stroke: { lineCap: "round" },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "diagonal1",
            gradientToColors: [
              "#17986A",
              "#046E94",
              "#2E85D0",
              "#0A3389",
              "#18FFFF",
              "#558B2F",
            ],
            stops: [0, 100],
          },
        },
        labels,
        legend: {
          show: true,
          floating: true,
          position: "left",
          offsetY: 5,
        },
      }
    },
  },
  methods: {
    ...mapMutations({
      updateIncludeDefaults: Map.Mutations.updateIncludeDefaults,
    })
  },

  filters: {
    largeCurrency: function (value) {
      if (!value) return "$ --"
      let unit = ""
      if (value >= 1000000) {
        value = value / 1000000
        unit = "M"
      } else if (value >= 1000) {
        value = value / 1000
        unit = "K"
      }

      return `+$${Intl.NumberFormat().format(
        Math.round(value * 10) / 10
      )}${unit}`
    },
  },

  mounted() {
    const { $ } = window
    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
    })
  },
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

td {
  line-height: 1.5 !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

h3 {
  font-weight: bold;
}

h3.data-comp {
  margin-bottom: 0px;
}

.container-fluid {
  padding-left: 0px;
  height: 100%;
}

.summary-table {
  border: 1pt solid #e6ecf5;
}

.card-body p {
  font-size: 12px;
}

.total-span {
  float: right;
  color: #79c61c;
  font-size: 20px;
  font-weight: 500;
  min-width: 40px;
  white-space: nowrap;
}

.total-title {
  font-size: 17px;
  color: #444;
  font-weight: 500;
}

.dashboard-title {
  font-size: 22px;
  color: black;
  font-weight: 500;
}

.infocus-bar {
  font-size: 15px;
  padding-bottom: 5px;
  margin-top: 5px;
}

.profit-subtext {
  font-size: 12px;
}

.dollar-card {
  min-height: 101px;
  margin-bottom: 10px;
}

.toggle-checkbox {
  font-size: 12px;
}

.toggle-checkbox > span {
  margin-right: 10px;
  font-weight: 500;
}

.crop-icon {
  width: 30px;
  height: 30px;
}

.per-ac {
  font-size: 13px;
  color: #888;
}

.opp-info {
  font-size: 16px;
  color: #777;
}

.total-row {
  border-top: 2pt solid #ccc;
}

.inline-ci-button {
  display: flex;
  align-items: center;
  color: rgb(0, 110, 199);
  line-height: 1;
  margin: 0;
}

.inline-ci-button-left {
  margin-right: 3px;
}

.inline-ci-button-right {
  margin-left: 3px;
}

::v-deep .b-sidebar-backdrop {
  cursor: pointer;
  opacity: 0.1;
}
</style>
