<template>
  <div class="px-3">
    <div v-if="view === 'list'">
      <div class="font-weight-bold">
        List of Zonation Plans
      </div>
      <table class="table">
        <thead>
          <tr>
            <th>Plan</th>
            <th>Date</th>
            <th></th>
          </tr>
        </thead>
        <tr v-for="plan in fieldZones" :key="plan.id">
          <td class="hover" @click="handleZonePlanSelect(plan)">
            {{ plan.name ? plan.name : "Zone Plan " + plan.id }}
          </td>
          <td class="hover" @click="handleZonePlanSelect(plan)">
            {{ plan.updated_at | date }}
          </td>
          <td v-if="!isReadOnly">
            <i class="fa fa-times-circle" @click="handleDeletePlan(plan.id)" />
          </td>
        </tr>
      </table>

      <button
        v-if="!isReadOnly"
        type="button"
        class="btn btn-primary update-btn py-2"
        @click="viewCreatePlan"
      >
        Create New Plan
      </button>
    </div>
    <div v-if="view === 'create'">
      <div class="font-weight-bold text-primary hover" @click="viewList">
        <i class="fa fa-arrow-circle-left" />
        Return to List
      </div>
      <div class="font-weight-bold">
        Create New Zone Prescription
      </div>
      <div>
        <div>Name</div>
        <input
          v-model="createPlanName"
          class="w-100 form-control"
          type="text"
        />
      </div>
      <div class="mt-3 mb-3">
        <div>Dates Available for Field</div>
        <div class="info-accent pb-1">Select one or more dates</div>
        <v-select-old
          multiple
          v-model="datesSelected"
          :options="dateSelect"
          :appendToBody="true"
        />
      </div>
      <div>
        <div>Number of Zones</div>
        <div class="mt-2 mb-5 px-2">
          <v-slider
            v-model="numZones"
            :min="2"
            :max="9"
            :step="1"
            :tick-labels="[2,3,4,5,6,7,8,9]"
            :tick-size="2"
          />
        </div>
      </div>
      <div>
        <div>Minimum Acreage</div>
        <div class="mt-2 mb-5 px-2">
          <v-slider
            v-model="minAcreage"
            :min="0.5"
            :max="10"
            :step="0.5"
            :tick-labels="[0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10]"
            :ticks="always"
            :tick-size="2"
          />
        </div>
      </div>
      <div class="d-flex">
        <button
          type="button"
          class="btn btn-primary update-btn py-2"
          @click="viewList(true)"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-primary update-btn py-2"
          :class="{ disabled: !canSubmitPlan }"
          @click="handlePlanSubmit"
        >
          Submit Plan
          <div
            v-if="isPlanLoading"
            class="spinner-border spinner-border-sm text-light ml-1"
            role="status"
          ></div>
        </button>
      </div>
      <div v-if="createPlanError">
        <div class="text-danger mb-3">
          An error occurred when creating the zone plan.
        </div>
      </div>
    </div>
    <div v-if="view === 'plan'">
      <div class="font-weight-bold text-primary hover" @click="viewList(true)">
        <i class="fa fa-arrow-circle-left" />
        Return to List
      </div>
      <div v-if="!isReadOnly" class="mb-3">
        <div class="font-weight-bold">
          Edit Zone Prescription Name
        </div>
        <div>
          <input
            class="form-control"
            type="text"
            :value="selectedZone.name"
            @input="handleZoneNameUpdate"
          />
        </div>
      </div>
      <div v-if="!isReadOnly">
        <div class="font-weight-bold">
          Select/Add Product to Zones
        </div>
        <div class="d-flex flex-row pb-2">
          <div class="w-100">
            <!-- <input class="form-control" type="text" v-model="productToAdd" /> -->
            <v-select-old
              class="select-style"
              taggable
              push-tags
              v-model="productToAdd"
              label="display_name"
              :reduce="o => o.value"
              :options="allFertChoices"
            />
          </div>
        </div>
        <div class="d-flex flex-row">
          <div class="w-50 pr-2">
            <span class="default-input-title">Amount</span>
            <input class="form-control" type="number" v-model="amountToAdd" />
          </div>
          <div class="w-50">
            <span class="default-input-title">Units</span>
            <v-select-old
              class="select-style"
              v-model="unitsToAdd"
              :options="zoneUnitsOptions"
            />
          </div>
        </div>

        <div class="d-flex flex-row pt-2 pb-4">
          <div class="w-50 pr-2">
            <span class="default-input-title">Select Zones</span>
            <v-select-old
              class="select-style"
              multiple
              v-model="zonesToAdd"
              :reduce="zToAdd => zToAdd.cluster"
              :options="zonesAddOptions"
            />
          </div>
          <div class="w-50">
            <button
              type="button"
              class="btn btn-primary wide-btn"
              :class="{ disabled: !canAddProduct }"
              @click="handleAddProduct"
            >
              <i class="fa fa-plus" />Add Product
            </button>
          </div>
        </div>
      </div>
      <div>
        <table class="table table-striped">
          <thead>
            <tr>
              <th class="w-35">Zone</th>
              <th class="w-65">Products</th>
            </tr>
          </thead>
          <tbody>
            <ZonesControllerRow
              v-for="zone in selectedZone.zones"
              :key="zone.cluster"
              :agt="false"
              :rx="false"
              :zone="zone"
              :zoneIndex="zone.cluster"
              totalZones="0"
              @delete-product="handleDeleteProduct"
            />
          </tbody>
        </table>
        <div class="mb-3">
          <div class="font-weight-bold">Product Total</div>
          <table class="table">
            <tbody>
              <tr
                v-for="product in Object.keys(zonesTotalProduct)"
                :key="product"
              >
                <td>{{ product }}</td>
                <td class="text-right">
                  <div class="amt">
                    {{ zonesTotalProduct[product]["amount"] | prettyInteger }}
                    {{ zonesTotalProduct[product]["units"].slice(0, -3) }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="!isReadOnly">
          <button
            type="button"
            class="btn btn-primary update-btn py-2 mr-0 float-right"
            @click="handleExport"
          >
            Export Shapefile
            <div
              v-if="isZipExportLoading"
              class="spinner-border spinner-border-sm text-light ml-1"
              role="status"
            ></div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash"

import { mapState } from "vuex"
import PlanetOrderAPI from "@/api/PlanetOrderAPI"
import SatelliteAPI from "@/api/SatelliteAPI"
import ZonesControllerRow from "@/components/fieldImageryMap/ZonesControllerRow"
import { ZONES_PRODUCT_UNITS } from "@/constants/map.js"
import { ALL_FERT_CHOICES } from "@/constants/defaults"

export default {
  name: "ZonesController",
  props: ["dateInfo", "fieldId", "fieldZones", "tab"],
  components: {
    ZonesControllerRow,
  },
  data() {
    return {
      allFertChoices: ALL_FERT_CHOICES,
      amountToAdd: null,
      clusterData: [],
      createPlanError: false,
      createPlanName: null,
      datesSelected: [],
      isPlanLoading: false,
      isUpdatePlanLoading: false,
      isZipExportLoading: false,
      minAcreage: 5.5,
      numZones: 2,
      productToAdd: null,
      selectedZone: null,
      unitsToAdd: null,
      view: "list",
      zonesToAdd: null,
      zoneUnitsOptions: ZONES_PRODUCT_UNITS,
    }
  },
  computed: {
    ...mapState({
      isReadOnly: state => state.User.user.permission === "Read-Only",
    }),

    canSubmitPlan() {
      const checkInputs = [this.createPlanName, this.minAcreage, this.numZones]
      const anyNull = val => val === null
      return !checkInputs.some(anyNull) && this.datesSelected.length >= 1
    },
    canAddProduct() {
      const { amountToAdd, productToAdd, unitsToAdd, zonesToAdd } = this
      return !!amountToAdd && !!productToAdd && !!unitsToAdd && !!zonesToAdd
    },
    dateSelect() {
      if (this.dateInfo) {
        const data = _.orderBy(
          this.dateInfo.map((ndviDate, index) => {
            return { dateVal: new Date(ndviDate.date), index: index }
          }),
          ["dateVal"],
          ["asc"]
        )
        const dataTextFormat = data.map(obj =>
          this.$options.filters.shortISODate(obj.dateVal)
        )
        return dataTextFormat
      }
      return []
    },
    zonesAddOptions() {
      if (this.selectedZone) {
        const options = this.selectedZone.zones.map(z => ({
          label: "Zone " + z.cluster,
          cluster: z.cluster,
        }))
        return options
      }
      return []
    },

    zonesTotalProduct() {
      if (this.selectedZone) {
        let productsTotal = {}
        this.selectedZone.zones.forEach(z => {
          const zoneAcreage = z.ac
          if (z.products) {
            z.products.forEach(prod => {
              if (!productsTotal[prod.product]) {
                productsTotal[prod.product] = {
                  amount: parseInt(prod.amount) * zoneAcreage,
                  units: prod.units,
                }
              } else {
                productsTotal[prod.product].amount +=
                  parseInt(prod.amount) * zoneAcreage
              }
            })
          }
        })
        return productsTotal
      }
      return {}
    },
  },
  methods: {
    viewCreatePlan() {
      this.view = "create"
    },
    viewList(reset) {
      if (reset) {
        this.selectedZone = null
        this.$emit("zones-updated", null)
      }
      this.createPlanError = false
      this.view = "list"
    },
    viewPlan() {
      this.view = "plan"
    },
    handleAddProduct() {
      const amount = this.amountToAdd
      const product = this.productToAdd
      const units = this.unitsToAdd
      const zones = this.zonesToAdd

      zones.forEach(zone => {
        const matchingZone = this.selectedZone.zones.findIndex(
          z => z.cluster === zone
        )
        const zoneToAdd = this.selectedZone.zones[matchingZone]
        if (!zoneToAdd.products) {
          zoneToAdd.products = []
        }
        zoneToAdd.products.push({ amount, product, units })
      })

      // need to use Object assign to have Vue become reactive
      const selectedZoneCopy = _.clone(this.selectedZone.zones)
      this.selectedZone = Object.assign({}, this.selectedZone, selectedZoneCopy)

      this.amountToAdd = null
      this.zonesToAdd = null

      this.handleUpdate()
    },
    async handleDeletePlan(planId) {
      const payload = { id: planId }
      await PlanetOrderAPI.deleteZone(payload)
      this.$emit("update-list")
    },
    handleDeleteProduct(product, zone) {
      const matchingZone = this.selectedZone.zones.findIndex(
        z => z.cluster === zone.cluster
      )
      const zoneToFilter = this.selectedZone.zones[matchingZone]
      zoneToFilter.products = _.reject(zoneToFilter.products, product)
      const selectedZoneCopy = _.clone(this.selectedZone)
      this.selectedZone = selectedZoneCopy
      this.handleUpdate()
    },
    async handleExport() {
      const payload = {
        zone_plan_id: this.selectedZone.id,
        name: this.selectedZone.name,
        zones: this.selectedZone.zones,
      }
      await SatelliteAPI.fetchZonesExport(payload).then(response => {
        const link = document.createElement("a")
        link.href = response.data.signed_url
        link.setAttribute("download", "file.zip")
        link.click()
        link.remove()
      })

      this.isZipExportLoading = false
    },
    async handleUpdate() {
      await PlanetOrderAPI.updateZones(this.selectedZone)
      this.$emit("update-list")
    },
    async handlePlanSubmit() {
      this.isPlanLoading = true
      const payload = {
        name: this.createPlanName,
        field_id: parseInt(this.fieldId),
        selected_dates: this.datesSelected,
        num_clusters: this.numZones,
        min_acreage: this.minAcreage,
      }
      await SatelliteAPI.fetchImageryZones(payload)
        .then(response => {
          const zoneObject = response.data
          this.selectedZone = zoneObject
          this.selectedZoneName = zoneObject.name
          this.clusterData = zoneObject.zones
          const parsedGeoJSON = JSON.parse(zoneObject.geometry)
          this.$emit("zones-updated", parsedGeoJSON)
          this.view = "plan"

          // removes error view upon successful creation
          this.createPlanError = false
        })
        .catch(() => {
          //console.log(err)
          this.createPlanError = true
        })
      this.isPlanLoading = false
      this.createPlanName = null
      this.$emit("update-list")
    },
    handleZoneNameUpdate(e) {
      const { target } = e
      const inputValue = target.value
      this.selectedZone.name = inputValue
    },
    handleZonePlanSelect(selectedZone) {
      this.selectedZone = selectedZone
      this.view = "plan"
      const parsedGeoJSON = JSON.parse(selectedZone.geometry)
      this.$emit("zones-updated", parsedGeoJSON)
      this.selectedZoneName = this.selectedZone.name
    },
  },
  watch: {
    tab(newTab, oldTab) {
      if (oldTab === 2 || newTab !== 2) {
        this.view = "list"
        this.selectedZone = null
      }
    },
  },
}
</script>
<style scoped>
.default-input-title {
  font-size: 14px;
}

.fa-times-circle {
  cursor: pointer;
  color: #e60000;
}

.hover {
  cursor: pointer;
}

.info-accent {
  color: #757575;
  font-size: 12px;
  font-weight: bold;
}

.select-style .vs__dropdown-toggle {
  height: 37px;
}

.wide-btn {
  width: 100%;
  margin: 0;
  margin-top: 20px;
  padding-bottom: 8px;
}

.w-35 {
  width: 35%;
}

.w-65 {
  width: 65%;
}

.amt {
  font-size: 16px;
  font-weight: bold;
}

.units {
  font-size: 10px;
}
</style>
