<template>
  <ArvaModal
    name="sampleLocationCollectorModal"
    title="Change/Confirm Soil Collection Companies and Soil Labs"
    @close-modal="handleClose"
  >
    <v-card-text>
      <form @submit.prevent="submit">
        <div class="pa-3">
          <h5>Reselect Soil Collection Agency for Selected Sampling Plan:</h5>

          <v-select-old
            class="mx-2 tillage-select"
            v-model="soilCollectionAgency"
            :options="soilCollectionAgencies"
            placeholder="Reselect Soil Collection Agency"
            @input="collectionAgencyChange"
            solo
          ></v-select-old>
        </div>
        <div class="pa-3">
          <h5>
            Assign a User Account to Collect
            {{ samplesLocationsToList.length }} Selected Samples:
          </h5>

          <v-select-old
            class="mx-2 tillage-select"
            v-model="soilCollector"
            :options="eligibleCollectors"
            placeholder=""
            solo
          ></v-select-old>
        </div>

        <div class="pa-3">
          <h5>Reselect Lab to Perform Analysis on Selected Sampling Plan:</h5>

          <v-select-old
            class="mx-2 tillage-select"
            v-model="soilLab"
            :options="soilLabs"
            placeholder="Reselect Sample Analysis Lab"
            solo
          ></v-select-old>
        </div>

        <br />

        <v-btn class="mr-4" type="submit">
          Assign / Re-assign to Selected Fields
        </v-btn>
        <v-btn class="mr-4" @click="cancel">
          Cancel
        </v-btn>
      </form>
    </v-card-text>
  </ArvaModal>
</template>

<script>
import ArvaModal from "./ArvaModal"
import SamplingAPI from "@/api/SamplingAPI"

export default {
  name: "SampleLocationCollectorModal",
  props: ["selectedPlan", "samplesLocationsToList"],
  components: {
    ArvaModal,
  },
  data() {
    return {
      soilCollector: null,
      soilCollectors: [],

      soilCollectionAgency: null,
      soilCollectionAgencies: [],

      soilLab: null,
      soilLabs: [],
      eligibleCollectors: [],
    }
  },

  mounted() {
    //get soil collectors associated with this orders sample team
    this.getCollectors()
  },
  methods: {
    handleClose() {
      this.$emit("close-template-modal")
    },
    collectionAgencyChange() {
      if (this.soilCollectionAgency != null) {
        this.eligibleCollectors = this.soilCollectors.filter(e => {
          return e.collector_id == this.soilCollectionAgency.id
        })
      } else {
        this.eligibleCollectors = []
      }
    },
    async getCollectors() {
      let payload = {
        order_id: this.selectedPlan.id,
      }
      await SamplingAPI.getSoilCollectors(payload).then(response => {
        let resp = response.data
        this.soilCollectors = resp.collectors
        this.soilCollectionAgencies = resp.collection_agencies
        this.soilLabs = resp.labs
        this.soilLab = this.soilLabs.filter(e => {
          return e.is_selected == true
        })

        if (this.soilLab.length > 0) {
          this.soilLab = this.soilLab[0]
        }

        this.soilCollectionAgency = this.soilCollectionAgencies.filter(e => {
          return e.is_selected == true
        })
        if (this.soilCollectionAgency.length > 0) {
          this.soilCollectionAgency = this.soilCollectionAgency[0]
        }

        if (this.soilCollectionAgency != null) {
          this.eligibleCollectors = this.soilCollectors.filter(e => {
            return e.collector_id == this.soilCollectionAgency.id
          })
        }
      })
    },
    async submit() {
      let collector_id = this.soilCollector ? this.soilCollector.id : null
      let payload = {
        collector_user_id: collector_id,
        sample_ids: this.samplesLocationsToList.map(x => x.id),

        sample_lab: this.soilLab,
        sample_collection_agency: this.soilCollectionAgency,
      }

      await SamplingAPI.postSoilSampleLocation(payload).then(response => {
        if (response.data == "Reset Collector") {
          this.selectedPlan.label_pdf = "-"
        }
      })
      this.samplesLocationsToList.map(o =>
        Object.assign(o, { collector_user_id: collector_id })
      )
      this.$emit("close-template-modal", this.soilCollectionAgency)
    },
    cancel() {
      this.$emit("close-template-modal")
    },
  },
}
</script>

<style scoped>
.error-message {
  font-size: 15px;
  font-weight: 500;
  padding: 30px 30px;
  color: red;
}

.tillage-select {
  /*max-width: 100%*/
}
</style>
