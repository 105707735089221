<template>
  <div class="card-body">
    <div class="row px-3">
      <div class="col-6 p-0">
        <h2>{{ field.name }}</h2>
      </div>
      <div class="col-6 px-0 py-2">
        <div
          class="toggle-checkbox toggle-sm toggle-success mr-2 d-flex align-items-center justify-content-end"
        >
          <span
            >Include Field Level Info
            <i
              class="fa fa-info-circle"
              data-toggle="tooltip"
              data-placement="bottom"
              title="Use this selection to add field level information with equipment measured information and display total data completion percentages."
          /></span>
          <input
            type="checkbox"
            class="checked mx-3"
            :checked="includeFieldLevelInfo"
            id="completionDefaultsCheckbox"
            @click="updateIncludeDefaults(!includeFieldLevelInfo)"
          />
          <label for="completionDefaultsCheckbox" class="form-check-label" />
        </div>
      </div>
    </div>

    <div class="row bottom-spacer">
      <div class="col">
        <div class="summary-section">
          <div>{{ farm.name }}</div>
          <div>Farm</div>
        </div>
      </div>

      <div class="col">
        <div class="summary-section">
          <div>{{ field.acreage | prettyInteger }} acres</div>
          <div>Acreage</div>
        </div>
      </div>

      <div v-if="crops" class="col">
        <div class="summary-section">
          <div>{{ crops }}</div>
          <div>Crops</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="summary-section">
          <div>
            {{ plantingPercent | percent }}
          </div>
          <div>Planting Data</div>
        </div>
      </div>

      <div class="col">
        <div class="summary-section">
          <div>
            {{ fertPercent | percent }}
          </div>
          <div>Applied Data</div>
        </div>
      </div>

      <div class="col">
        <div class="summary-section">
          <div>
            {{ harvestPercent | percent }}
          </div>
          <div>Harvest Data</div>
        </div>
      </div>

      <div class="col">
        <div class="summary-section">
          <div>
            {{ soilPercent | percent }}
          </div>
          <div>Soil Data</div>
        </div>
      </div>

      <div class="col">
        <div class="summary-section">
          <div>
            {{ texturePercent | percent }}
          </div>
          <div>Texture Data</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex"
import { CROP_DISPLAY_NAME } from "@/constants"
import { Map } from "@/store/modules"

export default {
  name: "FieldViewSummary",
  props: {
    field: {
      type: Object,
      required: true,
    },
    farm: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      includeFieldLevelInfo: Map.Getters.getIncludeDefaults,
    }),

    ...mapState({
      year: state => state.Organization.year,
    }),

    plantingPercent() {
      let { acreage, data_completion, defaults_data_completion } = this.field

      if (!data_completion && !this.includeFieldLevelInfo) return 0

      data_completion = data_completion || {}
      data_completion = data_completion[this.year] || {}
      defaults_data_completion = defaults_data_completion || {}

      const { planting_acres = 0 } = data_completion
      const {
        planting_acres: defaultsPlantingAcres = 0,
      } = defaults_data_completion

      const acres = this.includeFieldLevelInfo
        ? Math.max(planting_acres, defaultsPlantingAcres)
        : planting_acres

      return acres / acreage
    },

    fertPercent() {
      let { acreage, data_completion, defaults_data_completion } = this.field

      if (!data_completion && !this.includeFieldLevelInfo) return 0

      data_completion = data_completion || {}
      data_completion = data_completion[this.year] || {}
      defaults_data_completion = defaults_data_completion || {}

      const { fertilizer_acres = 0 } = data_completion
      const {
        fertilizer_acres: defaultsFertAcres = 0,
      } = defaults_data_completion

      const acres = this.includeFieldLevelInfo
        ? Math.max(fertilizer_acres, defaultsFertAcres)
        : fertilizer_acres

      return acres / acreage
    },

    harvestPercent() {
      let { acreage, data_completion, defaults_data_completion } = this.field

      if (!data_completion && !this.includeFieldLevelInfo) return 0

      data_completion = data_completion || {}
      data_completion = data_completion[this.year] || {}
      defaults_data_completion = defaults_data_completion || {}

      const { harvest_acres = 0 } = data_completion
      const {
        harvest_acres: defaultsHarvestAcres = 0,
      } = defaults_data_completion

      const acres = this.includeFieldLevelInfo
        ? Math.max(harvest_acres, defaultsHarvestAcres)
        : harvest_acres

      return acres / acreage
    },

    soilPercent() {
      let { acreage, data_completion, defaults_data_completion } = this.field

      if (!data_completion && !this.completionincludeFieldLevelInfo) return 0

      data_completion = data_completion || {}
      data_completion = data_completion[this.year] || {}
      defaults_data_completion = defaults_data_completion || {}

      const { soil_samples_acres: soilSampleAcres = 0 } = data_completion
      const {
        soil_samples_acres: defaultsSoilSamplesAcres = 0,
      } = defaults_data_completion

      const soilSamplesPercent = soilSampleAcres / acreage
      const defaultsSoilSamplesPercent = defaultsSoilSamplesAcres / acreage

      const percent = this.includeFieldLevelInfo
        ? Math.max(soilSamplesPercent, defaultsSoilSamplesPercent)
        : soilSamplesPercent

      return percent
    },

    texturePercent() {
      let { acreage, data_completion, defaults_data_completion } = this.field

      if (!data_completion && !this.completionincludeFieldLevelInfo) return 0

      data_completion = data_completion || {}
      data_completion = data_completion[this.year] || {}
      defaults_data_completion = defaults_data_completion || {}

      const { soil_texture_acres: soilTextureAcres = 0 } = data_completion
      const {
        soil_texture_acres: defaultsSoilTextureAcres = 0,
      } = defaults_data_completion

      const soilTexturePercent = soilTextureAcres / acreage
      const defaultsSoilTexturePercent = defaultsSoilTextureAcres / acreage

      const percent = this.includeFieldLevelInfo
        ? Math.max(soilTexturePercent, defaultsSoilTexturePercent)
        : soilTexturePercent

      return percent
    },

    crops() {
      const { field_crops = [] } = this.field
      return field_crops
        .filter(({ year }) => this.year === year)
        .map(({ crop_id }) => CROP_DISPLAY_NAME[crop_id])
        .join(", ")
    },
  },

  methods: {
    ...mapMutations({
      updateIncludeDefaults: Map.Mutations.updateIncludeDefaults,
    }),
  },

  mounted() {
    const { $ } = window
    $(function() {
      $('[data-toggle="tooltip"]').tooltip()
    })
  },
}
</script>

<style scoped>
.bottom-spacer {
  margin-bottom: 15px;
}

.summary-section {
  text-align: center;
  font-size: 15px;
}

.summary-section > div:first-of-type {
  font-weight: 500;
  font-size: 17px;
  color: #666;
}

.summary-section > div:last-of-type {
  font-size: 12px;
}

.toggle-checkbox {
  font-size: 12px;
}

.toggle-checkbox > span {
  margin-right: 10px;
  font-weight: 500;
}
</style>
