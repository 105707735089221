<template>
  <tr>
    <td>
      <input
        @click="checkboxClicked"
        :disabled="!canMakeOrder"
        type="checkbox"
        name="selected"
        ref="fieldSelectedCheckbox"
      />
    </td>
    <td>
      <img :src="field.boundary_image" style="width: 50px" />
    </td>
    <td>
      {{ field.name }}
    </td>
    <td>{{ field.acreage | prettyInteger }}</td>
    <td>{{ field.farm.name }}</td>
    <td>
      <div>
        <div
          v-for="order in fieldOrders"
          :key="order.planet_order.uuid"
          class="order"
        >
          {{ order.planet_order.contract_start_date | date }} -
          {{ order.planet_order.contract_end_date | date }}
          <i
            id="order-tooltip"
            class="fa fa-info-circle"
            data-toggle="tooltip"
            data-placement="top"
            :title="`Order ID: ${order.planet_order.uuid}`"
          />
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  name: "FieldImageryOrderRow",
  props: ["field", "orders", "newContractStartDate", "newContractEndDate"],

  computed: {
    fieldOrders() {
      return this.orders.filter(order => order.field_id === this.field.id)
    },

    canMakeOrder() {
      if (this.fieldOrders.length === 0) return true

      const mostRecentOrder = this.fieldOrders[this.fieldOrders.length - 1]
      return (
        this.newContractStartDate >
        new Date(mostRecentOrder.planet_order.contract_end_date)
      )
    },
  },

  methods: {
    sendCheckEvents(checkbox) {
      if (checkbox.checked) this.$emit("checked", this.field.id)
      else this.$emit("unchecked", this.field.id)
    },

    checkboxClicked() {
      const checkbox = this.$refs.fieldSelectedCheckbox
      this.sendCheckEvents(checkbox)
    },

    setCheckbox(checked) {
      if (!this.canMakeOrder) return
      const checkbox = this.$refs.fieldSelectedCheckbox
      checkbox.checked = checked
      this.sendCheckEvents(checkbox)
    },
  },

  mounted() {
    const { $ } = window
    setTimeout(() =>
      $(function() {
        $('[data-toggle="tooltip"]').tooltip()
      }, 500)
    )
  },
}
</script>

<style>
.tooltip-inner {
  font-size: 11px;
}
</style>

<style scoped>
.order {
  width: 175px;
  background: #888;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  font-size: 12px;
  margin: 5px 0px;
}

.fa-info-circle {
  margin-left: 5px;
}
</style>
