<template>
  <v-app>
    <v-main>
      <Theme v-if="loggedIn" />
      <PDFGeneratorView v-else-if="pdfGenerator" />
      <PDFTrialReportView v-else-if="pdfTrialReport" />
      <PDFSoilLabel v-else-if="qrCodeGenerator" />
      <Login v-else />
      <v-snackbar
        v-model="showNotificationsSnackbar"
        vertical
        absolute
        shaped
        top
        elevation="96"
        :timeout="-1"
      >
        <h3 class="snackbar-content">{{ snackbarContent.title }}</h3>
        <p class="snackbar-content">{{ snackbarContent.message }}</p>

        <template v-slot:action>
          <v-btn
            color="green"
            variant="text"
            @click="showNotificationsSnackbar=false; navigateToNotifications()"
            class="notifications-route-snackbar"
          >
            Go To Notifications
          </v-btn>
          <v-btn
            color="green"
            variant="text"
            @click="showNotificationsSnackbar=false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapState } from "vuex"
import { DEPLOYMENT_ENVIRONMENT } from "@/constants"
import Theme from "@/components/layout/Theme"
import router from "@/router"
import { User } from "@/store/modules"
import Login from "@/views/Login"
import PDFGeneratorView from "@/views/PDFGeneratorView"
import PDFTrialReportView from "@/views/PDFTrialReportView"
import LeafAPI from "@/api/LeafAPI"
import PDFSoilLabel from "@/views/PDFSoilLabel"

export default {
  components: {
    Theme,
    PDFGeneratorView,
    PDFTrialReportView,
    PDFSoilLabel,
    Login,
  },

  data() {
    return {
      showNotificationsSnackbar: false,
      snackbarContent: {
        title: null,
        message: null
      }
    }
  },

  computed: {
    ...mapState({
      userOrgNodeId: state => state.User.orgNodeId,
      userToken: state => state.User.token,
      temporaryAlertNotifications: state => state.Notifications.temporaryAlertNotifications
    }),

    loggedIn() {
      return !!this.userToken
    },

    pdfGenerator() {
      return router.history.current.name === "arva-pdf-generator"
    },

    pdfTrialReport() {
      return (
        router.history.current.name === "trial-title-page" ||
        router.history.current.name === "trial-report-generator" ||
        router.history.current.name === "trial-env-generator" ||
        router.history.current.name === "trial-microclimate" ||
        router.history.current.name === "trial-rollup-generator"
      )
    },

    qrCodeGenerator() {
      return router.history.current.name === "soil-label-generator"
    },

    currentlyOnNotificationsPage() {
      return router.history.current.name.includes('notifications')
    },
    currentlyOnWizardsPage() {
      return router.history.current.name.includes('historical-practices')
    }
  },

  methods: {
    ...mapActions({
      fetchUser: User.Actions.fetchUser,
      fetchAllNotifications: "Notifications/fetchAllNotifications",
    }),
    navigateToNotifications() {
      router.push('/notifications');
    }
  },

  async mounted() {
    if (!["production", "qa", "demo"].includes(DEPLOYMENT_ENVIRONMENT)) {
      const titleEnv = DEPLOYMENT_ENVIRONMENT.charAt(0).toUpperCase() + DEPLOYMENT_ENVIRONMENT.substr(1)
      document.title = `${titleEnv} - ${document.title}`
    }

    if (this.loggedIn) {
      await this.fetchUser()
      this.fetchAllNotifications();

      // checks for code for Leaf API
      if (this.$route.query.code) {
        const code = this.$route.query.code
        const targetOrgNodeId = this.$route.query.target_org
        // const orgId = this.$store.state.Organization.organization.id
        LeafAPI.postLeafCode({ code, org_node_id: targetOrgNodeId })
      }
    } else if (
      !this.pdfGenerator &&
      !this.pdfTrialReport &&
      !this.qrCodeGenerator
    )
      router.push("/login")
  },
  watch: {
    temporaryAlertNotifications(curr) {
      if (curr.length > 0) {

        const notifForSnackbar = curr[curr.length-1];

        const notifType = notifForSnackbar['data']['type'];

        if (notifType == 'moveclient') {
          this.snackbarContent = {
            title: "Move Client",
            message: "A new move client request has been generated."
          }
        }

        if (!this.currentlyOnNotificationsPage) {
          if (notifForSnackbar['data']['type'] == 'assignevidencing') {
            if (!this.currentlyOnWizardsPage) {
              this.showNotificationsSnackbar = true;
            }
          }
          else {
            this.showNotificationsSnackbar = true;
          }
        }
      }
    }
  }
}
</script>
<style>
.v-slider__tick-label {
  font-size: 12px;
}
.v-snack__wrapper {
  margin-top: 72px;
}

.v-snack__wrapper .notifications-route-snackbar {
  margin-right: 12px;
}

.snackbar-content {
  color: #FFFFFF;
}
</style>