<template>
  <ArvaModal
    name="enrollmentThankYouModal"
    title="Thank You for Enrolling Carbon Ready Program"
    :wide="true"
    @close-modal="handleCloseModal"
    style="z-index: 10000"
  >
    <div class="zero-state span-text">
      <span class="font-weight-bold">THANK YOU</span>
      <span>
        for enrolling fields into the Arva Carbon Ready Program. <br />
        Together we will improve grower bottom lines with improved soil health
        and crop yields.<br />
      </span>

      <div v-if="downloadUrl">
        <br />
        <a :href="downloadUrl" target="_blank">
          <i class="fa fa-download" /> Download Agreement
        </a>
      </div>
    </div>
  </ArvaModal>
</template>

<script>
import ArvaModal from "@/components/modals/ArvaModal"

export default {
  name: "EnrollmentThankYouModal",
  components: {
    ArvaModal,
  },
  props: {
    downloadUrl: {
      type: String,
      default: null,
    },
  },
  methods: {
    handleCloseModal() {
      this.$emit("close-modal")
    },
  },
}
</script>

<style scoped>
.span-text {
  padding: 20px;
  font-size: 15px;
  text-align: center;
}
</style>
