<template>
  <div>
    <div class="row">
      <div class="col">
        <div
          class="settings-section-title"
          @click="showPlantingData = !showPlantingData"
        >
          Planting Plans
          <i class="fa fa-chevron-down" v-if="!showPlantingData" />
          <i class="fa fa-chevron-up" v-else />
        </div>
      </div>
    </div>
    <div v-if="showPlantingData" class="settings-table">
      <!-- <SettingsRow
        v-on:clear-input="
          plantingData.previous_year_crop = null
          handleSubmit()
        "
        v-on:submit-row="handleSubmit"
      >
        <template v-slot:title>
          Previous Crop
        </template>
        <template v-slot:display-value>
          {{ displaySetting("previous_year_crop") | crop }}
        </template>
        <template v-slot:input>
          <v-select-old
            class="select-style flex-fill"
            :options="cropChoices"
            :reduce="o => o.value"
            v-model="plantingData.previous_year_crop"
            label="display_name"
            :placeholder="cropFilter(displaySetting('previous_year_crop'))"
          />
        </template>
        <template v-slot:default-type>
          <div
            v-bind:class="{ 'default-name': isLink('previous_year_crop') }"
            @click="handleLinkClick('previous_year_crop')"
          >
            {{ defaultType("previous_year_crop") }}
            <i
              v-if="isLink('previous_year_crop')"
              class="fa fa-arrow-circle-right"
            />
          </div>
        </template>
      </SettingsRow> -->

      <!-- <SettingsRow
        v-on:clear-input="
          plantingData.current_year_crop = null
          handleSubmit()
        "
        v-on:submit-row="handleSubmit"
      >
        <template v-slot:title>
          Current Crop
        </template>
        <template v-slot:display-value>
          {{ displaySetting("current_year_crop") | crop }}
        </template>
        <template v-slot:input>
          <v-select-old
            class="select-style flex-fill"
            :options="cropChoices"
            :reduce="o => o.value"
            v-model="plantingData.current_year_crop"
            label="display_name"
            :placeholder="cropFilter(displaySetting('current_year_crop'))"
          />
        </template>
        <template v-slot:default-type>
          <div
            v-bind:class="{ 'default-name': isLink('current_year_crop') }"
            @click="handleLinkClick('current_year_crop')"
          >
            {{ defaultType("current_year_crop") }}
            <i
              v-if="isLink('current_year_crop')"
              class="fa fa-arrow-circle-right"
            />
          </div>
        </template>
      </SettingsRow> -->

      <!-- <SettingsRow
        v-on:clear-input="
          plantingData.monitor_type = null
          handleSubmit()
        "
        v-on:submit-row="handleSubmit"
      >
        <template v-slot:title>
          Monitor Type
        </template>
        <template v-slot:display-value>
          {{ displaySetting("monitor_type") }}
        </template>
        <template v-slot:input>
          <v-select-old
            class="select-style flex-fill"
            :options="monitorChoices"
            :reduce="o => o.value"
            v-model="plantingData.monitor_type"
            label="display_name"
            :placeholder="displaySetting('monitor_type')"
          />
        </template>
        <template v-slot:default-type>
          <div
            v-bind:class="{ 'default-name': isLink('monitor_type') }"
            @click="handleLinkClick('monitor_type')"
          >
            {{ defaultType("monitor_type") }}
            <i v-if="isLink('monitor_type')" class="fa fa-arrow-circle-right" />
          </div>
        </template>
      </SettingsRow> -->

      <!-- <SettingsRow
        v-on:clear-input="
          plantingData.starter_fertilizer = null
          handleSubmit()
        "
        v-on:submit-row="handleSubmit"
      >
        <template v-slot:title>
          Starter Fertilizer
        </template>
        <template v-slot:display-value>
          {{ displaySetting("starter_fertilizer") }}
        </template>
        <template v-slot:input>
          <v-select-old
            class="select-style flex-fill"
            :options="starterFertilizerChoices"
            :reduce="o => o.value"
            v-model="plantingData.starter_fertilizer"
            label="display_name"
            :placeholder="displaySetting('starter_fertilizer')"
          />
        </template>
        <template v-slot:default-type>
          <div
            v-bind:class="{ 'default-name': isLink('starter_fertilizer') }"
            @click="handleLinkClick('starter_fertilizer')"
          >
            {{ defaultType("starter_fertilizer") }}
            <i
              v-if="isLink('starter_fertilizer')"
              class="fa fa-arrow-circle-right"
            />
          </div>
        </template>
      </SettingsRow> -->

      <!-- <SettingsRow
        v-on:clear-input="
          plantingData.starter_fertilizer_rate = null
          handleSubmit()
        "
        v-on:submit-row="handleSubmit"
        v-on:focus-input="focusInput('starter_fertilizer_rate')"
      >
        <template v-slot:title>
          Starter Fertilizer Rate
        </template>
        <template v-slot:display-value>
          {{ displaySetting("starter_fertilizer_rate") }}
        </template>
        <template v-slot:input="{ handleEnter }">
          <input
            v-model="plantingData.starter_fertilizer_rate"
            type="number"
            :placeholder="displaySetting('starter_fertilizer_rate')"
            ref="starter_fertilizer_rate"
            @keyup="handleEnter"
          />
        </template>
        <template v-slot:default-type>
          <div
            v-bind:class="{ 'default-name': isLink('starter_fertilizer_rate') }"
            @click="handleLinkClick('starter_fertilizer_rate')"
          >
            {{ defaultType("starter_fertilizer_rate") }}
            <i
              v-if="isLink('starter_fertilizer_rate')"
              class="fa fa-arrow-circle-right"
            />
          </div>
        </template>
      </SettingsRow> -->

      <!-- <SettingsRow
        v-on:clear-input="
          plantingData.biological_name = null
          handleSubmit()
        "
        v-on:submit-row="handleSubmit"
        v-on:focus-input="focusInput('biological_name')"
      >
        <template v-slot:title>
          Biological Name
        </template>
        <template v-slot:display-value>
          {{ displaySetting("biological_name") }}
        </template>
        <template v-slot:input="{ handleEnter }">
          <input
            v-model="plantingData.biological_name"
            type="text"
            :placeholder="displaySetting('biological_name')"
            ref="biological_name"
            @keyup="handleEnter"
          />
        </template>
        <template v-slot:default-type>
          <div
            v-bind:class="{ 'default-name': isLink('biological_name') }"
            @click="handleLinkClick('biological_name')"
          >
            {{ defaultType("biological_name") }}
            <i
              v-if="isLink('biological_name')"
              class="fa fa-arrow-circle-right"
            />
          </div>
        </template>
      </SettingsRow> -->

      <div class="row px-2 ml-2 mt-2 mb-4">
        <div class="col py-0">
          <div class="row">
            <div
              class="col-8 pb-0 mt-1 text-bold click-me"
              @click="showSeedPlans = !showSeedPlans"
            >
              Field Seed Plans
              <i class="fa fa-chevron-down" v-if="!showSeedPlans" />
              <i class="fa fa-chevron-up" v-else />
            </div>
            <!-- <div class="col-4 pl-0 pb-0">
              <div
                type="button"
                class="btn btn-primary py-1 btn-sm"
                @click="seedPlanModalOpen = true"
              >
                Add Seed
              </div>
            </div> -->
          </div>
          <div class="row pl-2 pr-4">
            <table class="table" v-if="showSeedPlans">
              <thead>
                <tr>
                  <th>Seed</th>
                  <th>Seeding Rate</th>
                  <th>Planting Date</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="seed in carbonSeedsData" :key="seed.idx">
                  <td>
                    <div>{{ seed.variety.value }}</div>
                    <div>{{ seed.manufacturer.value }}</div>
                  </td>
                  <td>{{ seed.rate.value | floatHundredth }} {{ seed.unit.value }}</td>
                  <td>{{ seed.date.value | date }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <SeedPlanModal
        v-if="seedPlanModalOpen"
        @close-template-modal="seedPlanModalOpen = false"
        @submit-seed-plan="handleAddSeed($event)"
      />
    </div>
  </div>
</template>
<script>
import _ from "lodash"
import { mapActions } from "vuex"
import DefaultsAPI from "@/api/DefaultsAPI"
import { CROP_DROPDOWN_OPTIONS, CROP_DISPLAY_NAME } from "@/constants"
import {
  MONITOR_CHOICES,
  STARTER_FERTILIZER_CHOICES,
} from "@/constants/defaults"
import { Fields } from "@/store/modules"
import SeedPlanModal from "@/components/modals/fieldSettings/SeedPlanModal"

export default {
  name: "PlantingFieldSettings",
  components: {
    SeedPlanModal,
  },
  props: {
    carbonSettings: Array,
    plantingData: { type: Object, required: true },
    plantingDefaults: { type: Object, required: true },
    generalDefaults: { type: Object, required: true },
    defaultsName: String,
    year: String,
  },
  data() {
    return {
      // view toggle
      showPlantingData: false,
      // initial values
      seedRateValue: null,
      seedManufacturerValue: null,
      seedVarietyValue: null,
      plantingDateValue: null,
      // dropdown choices
      cropChoices: CROP_DROPDOWN_OPTIONS,
      monitorChoices: MONITOR_CHOICES,
      starterFertilizerChoices: STARTER_FERTILIZER_CHOICES,
      // seed plan modal
      showSeedPlans: false,
      seedPlanModalOpen: false,
    }
  },
  computed: {
    carbonSeedsData() {
      return this.carbonSettings
    },
    seedsDataForYear() {
      const data = this.plantingData.data.find(
        d => d.year === parseInt(this.year)
      )
      if (!data) {
        this.addDataForYear()
        return this.plantingData.data.find(d => d.year === parseInt(this.year))
      }

      return data
    },
    seedsData() {
      // if (
      //   this.plantingData.seeds !== null &&
      //   this.plantingData.seeds.length > 0
      // ) {
      //   return {
      //     data: _.orderBy(this.plantingData.seeds, ["planting_date"], ["asc"]),
      //     location: "plantingData",
      //   }
      // }
      if (this.seedsDataForYear !== null) {
        return {
          data: _.orderBy(
            this.seedsDataForYear.plantings,
            ["planting_date"],
            ["asc"]
          ),
          location: "plantingData",
        }
      }
      if (
        this.plantingDefaults.seeds !== null &&
        this.plantingDefaults.seeds.length > 0
      ) {
        return {
          data: _.orderBy(
            this.plantingDefaults.seeds,
            ["planting_date"],
            ["asc"]
          ),
          location: "plantingDefaults",
        }
      }
      return {
        data: _.orderBy(this.generalDefaults.seeds, ["planting_date"], ["asc"]),
        location: "generalDefaults",
      }
    },
    plantingDateDisplay() {
      if (this.plantingDateValue) return this.plantingDateValue
      return this.displaySeedSetting("planting_date")
    },
    seedsDataLocationMapper() {
      const mapper = {
        plantingData: "Field Specific",
        plantingDefaults: this.defaultsName,
        generalDefaults: "Field Level Information",
      }
      return mapper
    },
  },
  methods: {
    ...mapActions({
      fetchFields: Fields.Actions.fetchFields,
    }),
    // handleAddSeed(val) {
    //   this.seedPlanModalOpen = false
    //   if (!this.plantingData.seeds) this.plantingData.seeds = []
    //   this.plantingData.seeds.push(val)
    //   this.handleSubmit()
    // },
    addDataForYear() {
      this.plantingData.data.push({
        plantings: [],
        year: parseInt(this.year),
      })
    },
    handleAddSeed(val) {
      this.seedPlanModalOpen = false
      if (!this.seedsDataForYear.plantings) this.seedsDataForYear.plantings = []
      this.seedsDataForYear.plantings.push(val)
      this.handleSubmit()
    },
    async handleSubmit() {
      await DefaultsAPI.update(
        "planting-defaults",
        this.plantingData.id,
        this.plantingData
      )
      this.fetchFields()
    },
    cropFilter(cropId) {
      if (!cropId || !CROP_DISPLAY_NAME[cropId]) return ""
      return CROP_DISPLAY_NAME[cropId]
    },
    defaultType(defaultName) {
      if (this.plantingData[defaultName]) return "Field Setting"
      if (this.plantingDefaults[defaultName]) return this.defaultsName
      return "Field Level Information"
    },
    displaySetting(settingName) {
      if (this.plantingData[settingName]) return this.plantingData[settingName]
      if (this.plantingDefaults[settingName])
        return this.plantingDefaults[settingName]
      if (this.generalDefaults.planting_data[settingName])
        return this.generalDefaults.planting_data[settingName]
      return "--"
    },
    isLink(defaultName) {
      if (this.plantingData[defaultName]) return false
      if (this.plantingDefaults[defaultName]) return true
      return true
    },
    handleLinkClick(defaultName) {
      if (
        !this.plantingData[defaultName] &&
        this.plantingDefaults[defaultName]
      ) {
        this.$router.push(
          `/settings/defaults/${this.plantingDefaults.defaults_template}`
        )
      }
      if (
        !this.plantingData[defaultName] &&
        !this.plantingDefaults[defaultName]
      ) {
        this.$router.push(`/settings/defaults/${this.generalDefaults.id}`)
      }
    },
    updateDate(date) {
      this.plantingDateValue = date
    },
    clearSetting(settingName) {
      this.plantingData.seeds[0][settingName] = null
      this.handleSubmit()
    },
    focusInput(input) {
      this.$nextTick(() => {
        this.$refs[input].focus()
      })
    },
    handleEnter() {},
    handleDeleteSeed(location, seed) {
      // if (location === "plantingData") {
      //   const newSeeds = _.without(this.plantingData.seeds, seed)
      //   this.plantingData.seeds = newSeeds
      //   this.handleSubmit()
      // }
      if (location === "plantingData") {
        const newSeeds = _.without(this.seedsDataForYear.plantings, seed)
        this.seedsDataForYear.plantings = newSeeds
        this.handleSubmit()
      }
      if (location === "plantingDefaults") {
        this.$router.push(
          `/settings/defaults/${this.plantingDefaults.defaults_template}`
        )
      }
      if (location === "generalDefaults") {
        this.$router.push(`/settings/defaults/${this.generalDefaults.id}`)
      }
    },
  },
}
</script>
<style scoped>
.click-me {
  cursor: pointer;
}
.w-15 {
  width: 15%;
}
.w-18 {
  width: 18%;
}
.fa-times-circle {
  cursor: pointer;
  color: #e60000;
}
</style>
