<template>
  <div class="upload-row">
    <div class="d-flex align-items-center">
      <div
        v-if="isUserMapping"
        class="d-flex align-items-center icon-info action-required"
      >
        <i class="fa fa-exclamation-triangle" />
        <div class="icon-text">Action Required - Map Columns</div>
      </div>
      <div v-else-if="isProcessing" class="d-flex align-items-center icon-info">
        <div class="spinner-border spinner-border-sm spinner" role="status" />
        <div class="icon-text processing">{{upload.stage_display}}</div>
      </div>
      <div
        v-else-if="isError || isShpError"
        class="d-flex align-items-center icon-info failed"
      >
        <i class="fa fa-times-circle" />
        <div class="icon-text processing">{{upload.stage_display}}</div>
      </div>
      <div
        v-else-if="isPartial"
        class="d-flex align-items-center icon-info complete"
      >
        <i class="fa fa-circle-o" />
        <div class="icon-text processing">{{upload.stage_display}}</div>
      </div>
      <div
        v-else-if="isComplete"
        class="d-flex align-items-center icon-info complete"
      >
        <i class="fa fa-check-circle" />
        <div class="icon-text processing">{{upload.stage_display}}</div>
      </div>
      <div class="divider" />
      <div>
        <div class="upload-name">{{ upload.name }}</div>
        <div class="upload-user">
          {{ upload.dataset_type_display }} -
          {{ upload.created_at | shortDatetime }}
        </div>
      </div>
      <div class="actions">
        <div class="delete">
          <i
            class="fa fa-times-circle"
            @click="showConfirmDeleteModal = true"
          />
        </div>

        <div v-if="isUserMapping" @click="handleAddMappings">
          Add Column Mappings
          <i class="fa fa-arrow-circle-right" />
        </div>

        <div
          v-else-if="isError || isShpError || isPartial || isComplete"
          @click="handleViewDetails"
        >
          View Details
          <i class="fa fa-arrow-circle-right" />
        </div>
      </div>
    </div>

    <ConfirmModal
      v-if="showConfirmDeleteModal"
      titleText="Confirm Upload Deletion"
      confirmText="Are you sure you want to delete this upload?"
      @confirm="handleDelete"
      @close-modal="showConfirmDeleteModal = false"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex"
import UploadsAPI from "@/api/UploadsAPI"
import ConfirmModal from "@/components/modals/ConfirmModal"
import { Uploads } from "@/store/modules"

export default {
  name: "UploadRow",
  props: {
    upload: { type: Object },
    currentPage: { type: Number },
  },
  components: { ConfirmModal },

  data() {
    return {
      showConfirmDeleteModal: false,
    }
  },

  computed: {
    isUserMapping() {
      return this.upload.stage_display === "user-mapping"
    },

    isProcessing() {
      return this.upload.stage_display.includes("Processing")
    },

    isPartial() {
      return this.upload.stage_display.includes("Partially Imported")
    },

    isComplete() {
      return this.upload.stage_display.includes("Import Complete")
    },

    isError() {
      return this.upload.stage_display.includes("Failed To Process")
    },

    isShpError() {
      return this.upload.stage_display.includes("Shapefile Error")
    },
  },

  methods: {
    ...mapActions({
      fetchUploads: Uploads.Actions.fetch,
    }),

    ...mapMutations({
      setDetailsModalUploadID: Uploads.Mutations.setDetailsModalUploadID,
      setMappingModalUploadID: Uploads.Mutations.setMappingModalUploadID,
    }),

    handleAddMappings() {
      this.setMappingModalUploadID(this.upload.id)
    },

    handleViewDetails() {
      this.setDetailsModalUploadID(this.upload.id)
    },

    async handleDelete() {
      await UploadsAPI.deleteUpload(this.upload.id)
      this.fetchUploads(this.currentPage)
    },
  },
}
</script>

<style scoped>
.upload-row {
  position: relative;
  padding: 18px;
  border-top: 1pt solid #d5d5d5;
}

.upload-row:hover {
  background: #fafafa;
}

.icon-info {
  min-width: 270px;
}

.icon-info > i {
  font-size: 25px;
}

.icon-text {
  font-size: 15px;
  margin-left: 10px;
  font-weight: 500;
}

.action-required {
  color: #fedb80;
}

.failed {
  color: #cc0101;
}

.complete {
  color: #00a200;
}

.spinner {
  font-size: 15px;
  font-weight: 500;
}

.divider {
  height: 40px;
  width: 1px;
  border-left: 1pt solid #e0e0e0;
  display: block;
  margin: auto 18px;
}

.upload-name {
  color: #333;
  font-size: 15px;
  font-weight: 500;
}

.upload-user {
  font-size: 13px;
}

.actions {
  text-align: right;
  color: #0095ff;
  font-weight: 500;
  cursor: pointer;
  position: absolute;
  right: 10px;
}

.actions > div {
  display: block;
}

.actions > div:hover {
  color: #888;
}

.action-text {
  font-weight: 500;
  margin-right: 5px;
  font-size: 13px;
}

.delete > i {
  color: #aaa;
}

.delete > i:hover {
  color: red;
}
</style>
