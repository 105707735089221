<template>
  <b-tr>
    <!-- date -->
    <b-td
      class="cell-table medium-column"
      :class="getClassForCell(planting.date)"
    >
      <v-menu
        v-model="showDate"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <div class="d-flex">
            <div class="date-holder">
              <v-text-field
                v-model="date"
                dense
                readonly
                hide-details="true"
                v-bind="attrs"
                v-on="on"
                :append-icon="getAppendIconForCell(planting.date)"
                @click:append="handleClickSubmit('date', date)"
              ></v-text-field>
            </div>
            <!-- <div class="cell-text date-button">
              <b-iconstack
                v-if="
                  planting.date.source !== 'User Input' &&
                    planting.date.value !== null
                "
                class="input-check"
                font-scale="1.75"
                @click="handleClickSubmit('date', date)"
              >
                <b-icon stacked class="bg-white" icon="circle-fill"></b-icon>
                <b-icon
                  stacked
                  class="submit-icon"
                  icon="check-circle-fill"
                ></b-icon>
                <b-icon stacked icon="circle"></b-icon>
              </b-iconstack>
            </div> -->
          </div>
        </template>
        <v-date-picker
          v-model="date"
          :min="yearPriorOne + '-01-01'"
          :max="yearExtendedOne + '-12-31'"
          @input="showDate = false"
          scrollable
        ></v-date-picker>
      </v-menu>
    </b-td>
    <!-- rate -->
    <b-td
      class="cell-table medium-column"
      :class="getClassForCell(planting.rate)"
    >
      <v-text-field
        clearable
        dense
        v-model="rate"
        hide-details="true"
        :append-icon="getAppendIconForCell(planting.rate)"
        @keypress="filter($event, 'planting-rate')"
        @click:append="handleClickSubmit('rate', rate)"
      >
      </v-text-field>
    </b-td>
    <!-- unit -->
    <b-td
      class="cell-table medium-column"
      :class="getClassForCell(planting.unit)"
    >
      <v-select
        clearable
        dense
        hide-details="true"
        :items="seedRateUnitChoices"
        item-text="display_name"
        item-value="value"
        v-model="unit"
        :append-icon="getAppendIconForCell(planting.unit)"
        @click:append="handleClickSubmit('unit', unit)"
      />
      <!-- <v-select-old
        class="style-chooser text-bold mb-0"
        label="display_name"
        :options="seedRateUnitChoices"
        :reduce="o => o.value"
        v-model="unit"
      /> -->
    </b-td>
    <!-- manufacturer -->
    <b-td
      class="cell-table large-column"
      :class="getClassForCell(planting.manufacturer)"
    >
      <v-text-field
        clearable
        dense
        v-model="manufacturer"
        hide-details="true"
        :append-icon="getAppendIconForCell(planting.manufacturer)"
        @click:append="handleClickSubmit('manufacturer', manufacturer)"
      >
      </v-text-field>
    </b-td>
    <!-- variety -->
    <b-td
      class="cell-table large-column"
      :class="getClassForCell(planting.variety)"
    >
      <v-text-field
        clearable
        dense
        v-model="variety"
        hide-details="true"
        :append-icon="getAppendIconForCell(planting.variety)"
        @click:append="handleClickSubmit('variety', variety)"
      >
      </v-text-field>
    </b-td>
    <!-- seeds per bag -->
    <b-td
      class="cell-table marge-column"
      :class="getClassForCell(planting.seeds_per_bag)"
    >
      <v-text-field
        clearable
        dense
        v-model="seedsPerBag"
        hide-details="true"
        :append-icon="getAppendIconForCell(planting.seeds_per_bag)"
        @keypress="filter($event, 'seeds-per-bag')"
        @click:append="handleClickSubmit('seeds_per_bag', seedsPerBag)"
      >
      </v-text-field>
    </b-td>
    <!-- cost per bag -->
    <b-td
      class="cell-table marge-column"
      :class="getClassForCell(planting.cost_per_bag)"
    >
      <v-text-field
        clearable
        dense
        v-model="cost"
        hide-details="true"
        prefix="$"
        suffix="USD"
        :append-icon="getAppendIconForCell(planting.cost_per_bag)"
        @keypress="filter($event, 'cost-per-bag')"
        @click:append="handleClickSubmit('cost_per_bag', cost)"
      >
      </v-text-field>
    </b-td>
    <!-- biological -->
    <b-td
      class="cell-table large-column"
      :class="getClassForCell(planting.biological)"
    >
      <v-text-field
        clearable
        dense
        v-model="biological"
        hide-details="true"
        :append-icon="getAppendIconForCell(planting.biological)"
        @click:append="handleClickSubmit('biological', biological)"
      >
      </v-text-field>
    </b-td>
    <!-- monitor type -->
    <b-td
      class="cell-table medium-column"
      :class="getClassForCell(planting.monitor)"
    >
      <v-text-field
        clearable
        dense
        v-model="monitorType"
        hide-details="true"
        :append-icon="getAppendIconForCell(planting.monitor_type)"
        @click:append="handleClickSubmit('monitor_type', monitorType)"
      >
      </v-text-field>
    </b-td>
    <b-td class="cell-table black">
      <v-btn icon x-small color="white" @click="handleAdd">
        <v-icon>mdi-plus-circle-outline</v-icon>
      </v-btn>
      <v-btn icon x-small color="white" @click="handleDelete">
        <v-icon>mdi-close-circle-outline</v-icon>
      </v-btn>
    </b-td>
    <!-- <b-td v-if="!additionView" class="align-middle"> -->
    <!-- <i class="fa fa-times-circle delete" @click="handleDelete" /> -->
    <!-- </b-td> -->
  </b-tr>
</template>
<script>
import { mapMutations } from "vuex"
import { CROP_DROPDOWN_OPTIONS } from "@/constants"
import { SEEDING_RATE_UNIT_CHOICES } from "@/constants/defaults"
import { Defaults } from "@/store/modules"
import { getClassForCell, getAppendIconForCell } from "../utils"

export default {
  name: "PlantingRow",
  props: {
    additionView: { type: Boolean, default: false },
    cropId: { type: Number },
    field: { type: String },
    planting: { type: Object },
    rowIndex: { type: Number },
    year: { type: String },
  },
  data() {
    return {
      cropChoices: CROP_DROPDOWN_OPTIONS,
      seedRateUnitChoices: SEEDING_RATE_UNIT_CHOICES,
      showDate: false,
      getClassForCell,
      getAppendIconForCell
    }
  },
  computed: {
    crop: {
      get() {
        return this.planting.crop_id.value
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "plantings",
          dataSetting: "crop",
          value: val,
          rowIndex: this.rowIndex,
        }
        this.spreadsheetMutate(updatePayload)
      },
    },
    date: {
      get() {
        const dateReturn = this.planting.date.value
          ? this.planting.date.value
          : `${this.year}-01-01`
        return dateReturn
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "plantings",
          dataSetting: "date",
          value: val,
          rowIndex: this.rowIndex,
        }
        this.spreadsheetMutate(updatePayload)
      },
    },
    rate: {
      get() {
        return this.planting.rate.value
      },
      set(val) {
        if (val === "") {
          val = null
        }
        let validVal = this.handleValidateValue(val, "number")
        if (validVal) {
          const updatePayload = {
            fieldId: this.field,
            year: this.year,
            crop: this.cropId,
            dataCategory: "plantings",
            dataSetting: "rate",
            value: val,
            rowIndex: this.rowIndex,
          }
          this.spreadsheetMutate(updatePayload)
        }
      },
    },
    unit: {
      get() {
        return this.planting.unit.value
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "plantings",
          dataSetting: "unit",
          value: val,
          rowIndex: this.rowIndex,
        }
        this.spreadsheetMutate(updatePayload)
      },
    },
    manufacturer: {
      get() {
        return this.planting.manufacturer.value
      },
      set(val) {
        if (val === "") {
          val = null
        }
        let validVal = this.handleValidateValue(val, "string")
        if (validVal) {
          const updatePayload = {
            fieldId: this.field,
            year: this.year,
            crop: this.cropId,
            dataCategory: "plantings",
            dataSetting: "manufacturer",
            value: val,
            rowIndex: this.rowIndex,
          }
          this.spreadsheetMutate(updatePayload)
        }
      },
    },
    monitorType: {
      get() {
        return this.planting.monitor_type.value
      },
      set(val) {
        if (val === "") {
          val = null
        }
        let validVal = this.handleValidateValue(val, "string")
        if (validVal) {
          const updatePayload = {
            fieldId: this.field,
            year: this.year,
            crop: this.cropId,
            dataCategory: "plantings",
            dataSetting: "monitor_type",
            value: val,
            rowIndex: this.rowIndex,
          }
          this.spreadsheetMutate(updatePayload)
        }
      },
    },
    variety: {
      get() {
        return this.planting.variety.value
      },
      set(val) {
        if (val === "") {
          val = null
        }
        let validVal = this.handleValidateValue(val, "string")
        if (validVal) {
          const updatePayload = {
            fieldId: this.field,
            year: this.year,
            crop: this.cropId,
            dataCategory: "plantings",
            dataSetting: "variety",
            value: val,
            rowIndex: this.rowIndex,
          }
          this.spreadsheetMutate(updatePayload)
        }
      },
    },
    seedsPerBag: {
      get() {
        return this.planting.seeds_per_bag.value
      },
      set(val) {
        if (val === "") {
          val = null
        }
        let validVal = this.handleValidateValue(val, "number")
        if (validVal) {
          const updatePayload = {
            fieldId: this.field,
            year: this.year,
            crop: this.cropId,
            dataCategory: "plantings",
            dataSetting: "seeds_per_bag",
            value: val,
            rowIndex: this.rowIndex,
          }
          this.spreadsheetMutate(updatePayload)
        }
      },
    },
    cost: {
      get() {
        return this.planting.cost_per_bag.value
      },
      set(val) {
        if (val === "") {
          val = null
        }
        let validVal = this.handleValidateValue(val, "number")
        if (validVal) {
          const updatePayload = {
            fieldId: this.field,
            year: this.year,
            crop: this.cropId,
            dataCategory: "plantings",
            dataSetting: "cost_per_bag",
            value: val,
            rowIndex: this.rowIndex,
          }
          this.spreadsheetMutate(updatePayload)
        }
      },
    },
    biological: {
      get() {
        return this.planting.biological.value
      },
      set(val) {
        if (val === "") {
          val = null
        }
        let validVal = this.handleValidateValue(val, "string")
        if (validVal) {
          const updatePayload = {
            fieldId: this.field,
            year: this.year,
            crop: this.cropId,
            dataCategory: "plantings",
            dataSetting: "biological",
            value: val,
            rowIndex: this.rowIndex,
          }
          this.spreadsheetMutate(updatePayload)
        }
      },
    },
    yearPriorOne() {
      return String(Number(this.year) - 1)
    },
    yearExtendedOne() {
      return String(Number(this.year) + 1)
    },
  },
  methods: {
    ...mapMutations({
      addFieldSettingRow: Defaults.Mutations.addFieldSettingRow,
      deleteNewFieldSetting: Defaults.Mutations.deleteNewFieldSetting,
      updateNewFieldSetting: Defaults.Mutations.updateNewFieldSetting,
      spreadsheetMutate: Defaults.Mutations.spreadsheetMutate,
    }),
    handleClickSubmit(setting, val) {
      this[setting] = val
    },
    handleCopy() {
      this.$emit("handle-copy", this.planting)
    },
    handleAdd() {
      const payloadObj = {
        biological: { source: "User Input", value: null },
        cost_per_bag: { source: "User Input", value: null },
        crop_id: { source: "User Input", value: this.cropId },
        date: { source: "User Input", value: null },
        manufacturer: { source: "User Input", value: null },
        monitor_type: { source: "User Input", value: null },
        rate: { source: "User Input", value: null },
        seeds_per_bag: { source: "User Input", value: null },
        unit: { source: "User Input", value: null },
        variety: { source: "User Input", value: null },
      }
      this.addFieldSettingRow({
        fieldId: this.field,
        cropId: this.cropId,
        dataCategory: "plantings",
        dataObj: payloadObj,
        year: this.year,
      })
    },
    handleDelete() {
      var defaultObj = {}
      for (const [key, value] of Object.entries(this.planting)) {
        if (key === "crop_id") defaultObj[key] = value
        else defaultObj[key] = { source: "User Input", value: null }
      }

      this.deleteNewFieldSetting({
        fieldId: this.field,
        cropId: this.cropId,
        dataCategory: "plantings",
        defaultObj: defaultObj,
        rowIndex: this.rowIndex,
        year: this.year,
      })
    },
    handleValidateValue(val, type, length = 24, range = null) {
      function isNumeric(str) {
        if (typeof str == "number") return true
        return (
          !isNaN(str) && !isNaN(parseFloat(str)) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        ) // ...and ensure strings of whitespace fail
      }

      if (val === null) {
        return true
      }

      if (val.length > length) {
        this.$emit(
          "bad-input",
          "Inputted value length was too long (24 characters allowed)."
        )
        return false
      }

      if (type == "number") {
        if (isNumeric(val)) {
          if (range !== null) {
            if (val >= range[0] && val <= range[1]) {
              return true
            } else {
              this.$emit(
                "bad-input",
                `Look out! Only a value between ${range[0]} and ${range[1]} can be entered here.`
              )
              return false
            }
          }
          return true
        } else {
          this.$emit(
            "bad-input",
            "Look out! A numerical value is expected here."
          )
          return false
        }
      } else if (type == "string") {
        return true
      }
    },
    filter(event, fieldInput) {
      event = event ? event : window.event
      let expect = event.target.value.toString() + event.key.toString()

      let range = null
      let inputType = "number"
      if (fieldInput === "planting-rate") range = [0, 4000000]

      let validVal = this.handleValidateValue(expect, inputType, 24, range)
      if (!validVal) event.preventDefault()
    },
  },
}
</script>
<style scoped>
.arva-alert {
  background-color: #f7c0be;
}

.cell-table {
  /* padding: 0; */
  padding: 2px 6px;
}

.medium-column {
  width: 140px;
}

.large-column {
  width: 240px;
}

.marge-column {
  width: 180px;
}

.pre-gen {
  background-color: #fedb80;
}
.machine {
  background-color: #c0dce2;
}

.bg-white {
  color: white;
}

.cell-text {
  font-weight: 600;
  color: white;
}

.complete {
  background-color: #c7e3c1;
}

.copy-icon {
  cursor: pointer;
  color: #0f9aee;
}

.delete {
  color: #cc0101;
  cursor: pointer;
}

.input-check {
  cursor: pointer;
}

.select-check {
  margin-top: 14px;
  cursor: pointer;
}

.submit-icon {
  color: #c7e3c1;
}

.v-input {
  font-size: 18px;
}

.date-button {
  margin-top: 12px;
  margin-left: 12px;
}

/* hide arrows/spin button for number input */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
