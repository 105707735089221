<template>
  <div class="pdf-wrapper">
    <div class="row">
      <div class="col-12 pb-0">
        <div class="title">{{ responseData.title_page.title }}</div>
        <div class="subtitle">Trial Rollup</div>
      </div>
      <div class="top-logo">
        <img
          class="profile-img img-fluid mt-2"
          src="/assets/images/logos/arva-logo.png"
          alt="User"
        />
      </div>
    </div>
    <div class="row background-dim mx-0">
      <div class="col" />
    </div>
    <div class="row">
      <div class="col-12 pt-0">
        <table class="table table-striped table-bordered table-font-inc pt-0">
          <thead>
            <tr>
              <th>Environment</th>
              <th>Seed</th>
              <th>Acres Treated</th>
              <th>Acres Control</th>
              <th>Treatment Yield</th>
              <th>Control Yield</th>
              <th>Yield Difference</th>
              <th>T-stat</th>
              <th>P-value</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(env, index) in environment" :key="env.idx">
              <td>{{ env === "all" ? "" : "AGT - " }}{{ env }}</td>
              <td>{{ responseData["rollup"]["Seed"][index] }}</td>
              <td>{{ responseData["rollup"]["acres_treated"][index] }}</td>
              <td>{{ responseData["rollup"]["acres_control"][index] }}</td>
              <td>{{ responseData["rollup"]["yield_treatment"][index] }}</td>
              <td>{{ responseData["rollup"]["yield_control"][index] }}</td>
              <td
                :class="
                  responseData['rollup']['yield_diff'][index] < 0
                    ? 'negative-yield'
                    : 'positive-yield'
                "
              >
                {{ responseData["rollup"]["yield_diff"][index] }}
              </td>
              <td>
                {{ responseData["rollup"]["t-stat"][index] | floatTenth }}
              </td>
              <td>{{ responseData["rollup"]["p_val"][index] | floatHundredth }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="footer d-flex align-items-center justify-content-between">
      <div class="ml-2">
        Confidential
      </div>
      <div class="mr-3">
        <i class="fa fa-copyright" /> 2021 Arva Intelligence
        <i class="fa fa-trademark" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TrialReportRollup",
  props: ["responseData"],

  computed: {
    environment() {
      if (this.responseData) {
        return this.responseData["rollup"]["Environment"]
      }
      return null
    },
  },
}
</script>
<style>
@page {
  size: 8.5in 11in;
  margin: 0;
}
</style>
<style scoped>
.background-dim {
  background-color: #dcdcdc;
}

.pdf-wrapper {
  width: 1000px;
  height: 1283px;
  position: fixed;
}

.title {
  color: black;
  font-family: Montserrat, sans-serif;
  font-size: 32px;
  font-weight: 500;
  margin-left: 25px;
}

.subtitle {
  color: black;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 500;
  margin-left: 25px;
}

.top-logo {
  position: absolute;
  top: 10px;
  right: 25px;
}

.footer {
  position: absolute;
  bottom: 0px;
  height: 30px;
  width: 100%;
  background: #444;
  font-family: Montserrat, sans-serif;
  font-size: 13px;
  color: #fff;
  font-weight: bold;
}

.positive-yield {
  color: green;
}

.negative-yield {
  color: red;
}

.table-font-inc {
  font-size: 15px;
}
</style>
