<template>
  <b-sidebar id="evidencing-rejection-message-sidebar" width="500px" title="Reject Evidencing Data" v-model="show" right shadow backdrop>
    <div class="sidebar-body">
      <p>
        Please enter a reason why this evidencing upload is to be rejected. End users will be able 
        to view this message and respond to it.
      </p>

      <v-textarea outlined label="Rejection Reason" placeholder="Enter reason for rejection" v-model="rejectionMessage" />
    </div>

    <div class="sidebar-footer">
      <v-btn @click="cancel" outlined height="48px">Cancel</v-btn>
      <v-btn @click="reject" outlined height="48px" :disabled="!isRejectable">Reject</v-btn>
    </div>
  </b-sidebar>
</template>

<script>
export default {
  name: "EvidencingGroupEditingSidebar",
  emits: ["closeSidebar"],
  components: {},
  props: {
    uploadId: { required: true },
    visible: { type: Boolean, required: true },
  },
  data() {
    return {
      rejectionMessage: '',
      cancelled: true,
      show: false,
    }
  },
  computed: {
    isRejectable() {
      return this.rejectionMessage.length > 0
    }
  },
  methods: {
    cancel() {
      this.cancelled = true;
      this.show = false;
    },
    reject() {
      this.cancelled = false;
      this.show = false;
    },
    removeSidebar() {
      this.$emit('closeSidebar', this.cancelled, this.uploadId, this.rejectionMessage);
      this.cancelled = true;
      this.rejectionMessage = '';
    },
  },
  watch: {
    visible(curr) {
      this.show = curr;
    },
    show(curr) {
      if (!curr) this.removeSidebar(true);
    }
  }
}
</script>

<style scoped>
::v-deep(#evidencing-rejection-message-sidebar) {
  background: #FFFFFF !important;
  z-index: 99;
}

p {
  color: #000000;
  line-height: 1.5;
  font-size: 16px;
  margin: 24px 0 0;
}

::v-deep #evidencing-rejection-message-sidebar header {
  padding: 24px 36px;
}

::v-deep #evidencing-rejection-message-sidebar header .close {
  margin-right: 16px !important;
}

::v-deep #evidencing-rejection-message-sidebar header strong {
  margin-right: auto;
  text-transform: capitalize;
}

.v-input {
  margin-top: 24px;
}

.sidebar-body {
  padding: 0 36px 36px;
  position: relative;
  margin-bottom: 96px;
}

.sidebar-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 72px;
  background: #FFFFFF;
  border-top: 1px solid rgba(0, 0, 0, 0.25);
  padding: 0 36px;
  display: flex;
  align-items: center;
  z-index: 9;
}

.sidebar-footer > button {
  float: left;
  margin-right: 16px;
}
</style>