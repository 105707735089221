<template>
  <div>
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="d-flex">
            <h2>Field Settings Templates</h2>
            <div class="add-defaults-btn">
              <button class="btn btn-primary" @click="openAddDefaultsModal">
                <i class="fa fa-plus" />Add New Settings Template
              </button>
            </div>
          </div>
          <div>
            <DefaultsRow
              v-for="defaults in defaultsList"
              :key="defaults.id"
              :defaults="defaults"
            />
          </div>
        </div>
      </div>
    </div>

    <ArvaModal
      v-if="showAddDefaultsModal"
      name="addDefaultsModal"
      title="Add New Settings Template"
      @close-modal="showAddDefaultsModal = false"
    >
      <div class="name-edit-form">
        <div class="row">
          <div class="col-12">
            <form @submit.prevent="handleAddTemplate">
              <div class="form-group">
                <label>Settings Template Name</label>
                <input
                  class="form-control"
                  type="text"
                  v-model="addTemplateName"
                  ref="addTemplateName"
                />
              </div>

              <div class="form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="copyTemplateCheck"
                  v-model="isCopyTemplate"
                />
                <label class="form-check-label" for="copyTemplateCheck"
                  >Copy An Existing Settings Template</label
                >
              </div>

              <div v-if="isCopyTemplate" class="form-group m-top">
                <label>Settings Template to Copy</label>
                <v-select-old
                  :options="copyTemplateOptions"
                  :reduce="type => type.id"
                  label="name"
                  v-model="copyTemplate"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <ArvaModalSaveFooter
        confirmText="Save"
        cancelText="Cancel"
        :disabled="!canSubmitCreate"
        @on-cancel="handleCancelAddTemplate"
        @on-confirm="handleAddTemplate"
      />
    </ArvaModal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"

import DefaultsAPI from "@/api/DefaultsAPI"
import ArvaModal from "@/components/modals/ArvaModal"
import ArvaModalSaveFooter from "@/components/modals/ArvaModalSaveFooter"
import DefaultsRow from "@/components/defaults/DefaultsRow"
import { Defaults } from "@/store/modules"

export default {
  name: "DefaultsList",
  components: { ArvaModal, ArvaModalSaveFooter, DefaultsRow },

  data() {
    return {
      showAddDefaultsModal: false,
      addTemplateName: null,
      isCopyTemplate: false,
      copyTemplate: null,
    }
  },

  computed: {
    ...mapGetters({
      defaultsList: Defaults.Getters.getDefaultsList,
    }),

    copyTemplateOptions() {
      return this.defaultsList.map(({ id, name }) => ({ id, name }))
    },

    canSubmitCreate() {
      if (!this.addTemplateName) return false
      if (this.isCopyTemplate && !this.copyTemplate) return false
      return true
    },
  },

  methods: {
    ...mapActions({
      fetchDefaults: Defaults.Actions.fetchDefaults,
    }),

    openAddDefaultsModal() {
      this.showAddDefaultsModal = true
      setTimeout(() => this.$refs.addTemplateName.focus())
    },

    async handleAddTemplate() {
      if (!this.canSubmitCreate) return

      const copyTemplate = this.isCopyTemplate ? this.copyTemplate : null
      await DefaultsAPI.create({
        name: this.addTemplateName,
        copy_template_id: copyTemplate,
      })
      this.fetchDefaults()
      this.showAddDefaultsModal = false
      this.addTemplateName = null
    },

    handleCancelAddTemplate() {
      this.showAddDefaultsModal = false
      this.addTemplateName = null
    },
  },
}
</script>

<style scoped>
h2 {
  font-size: 21px;
  font-weight: 500;
}

.add-defaults-btn {
  flex: 1;
  text-align: right;
}

.add-defaults-btn i {
  margin-right: 8px;
}

.name-edit-form {
  margin: 25px 70px;
}

.form-check-label {
  padding-top: 2px;
}

.m-top {
  margin-top: 20px;
}
</style>
