<template>
  <div>
    <DashboardTitle
      title="Customer Admin"
      imgSrc="/assets/images/side-nav/admin.png"
    />

    <div class="col-12 scroll-col">
      <v-card>
        <v-toolbar flat>
          <v-tabs grow v-model="tab">
            <v-tabs-slider color="blue"></v-tabs-slider>
            <v-tab v-for="item in tabTitles" :key="item.index">
              {{ item.name }}
            </v-tab>
          </v-tabs>
        </v-toolbar>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in tabTitles" :key="item.index">
            <v-card flat>
              <div v-if="item.name === 'User Management'" class="pt-3 card">
                <UserManagement />
              </div>
              <div v-if="item.name === 'Data Sharing'" class="pt-3">
                <DataSharing />
              </div>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex"
import DashboardTitle from "@/components/dashboard/DashboardTitle"
import { User } from "@/store/modules"
import UserManagement from "@/components/customerAdmin/UserManagement"
import DataSharing from "@/components/customerAdmin/DataSharing"

export default {
  name: "AccountView",
  components: {
    DashboardTitle,
    UserManagement,
    DataSharing,
  },

  data() {
    return {
      tab: 0,
      tabTitles: [
        { index: 0, name: "User Management" },
        { index: 1, name: "Data Sharing" },
      ],
    }
  },

  computed: {
    ...mapState({
      adminUser: state => state.User.user,
    })
  },

  methods: {
    ...mapActions({
      fetchUser: User.Actions.fetchUser,
    }),
  },

  async beforeMount(){
    await this.fetchUser()
  },

  mounted() {},
}
</script>

<style scoped>
</style>
