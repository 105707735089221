import {
  CROP_ID_KEY
} from "@/constants"
import _ from "lodash"

export const mapPayloadKeysToUserInput = (payload) => {
  const payloadCopy = _.cloneDeep(payload);

  Object.keys(payloadCopy).forEach(payloadKey => {
    if (payloadKey == "crop_id") {
      payloadCopy[payloadKey] = {
        value: CROP_ID_KEY[payloadCopy[payloadKey]],
        source: "User Input",
      }
    } else {
      payloadCopy[payloadKey] = {
        value: payloadCopy[payloadKey],
        source: "User Input",
      }
    }
  })
  return payloadCopy;
}

export const dupeCheck = (val1, val2) =>{
  if (val1 === val2) {
    return true
  }
  return Math.abs(val1 - val2) / val1 <= 0.01
}