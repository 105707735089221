import { Evidencing } from "@/store/modules";
import { fetchClientBasedGroups, deleteEvidencingGroup, fetchGroupData, getEvidencingByOrg } from "@/api/EvidencingAPI";
import {
  EVIDENCING_GROUP_LIST,
  ACTIVE_EVIDENCING_GROUP,
  DELETION,
  REVIEW_ACCEPTED,
  REVIEW_REJECTED,
  EVIDENCING_STATUSES
} from "@/constants/evidencing";
import { EVIDENCING_TYPE_CHOICES } from "@/constants/defaults";

const state = {
  viewingOrgWideEvidencing: false,
  evidencingGroupList: null,
  activeEvidencingGroup: null,
  loading: {
    activeEvidencingGroup: false,
    evidencingGroupList: false,
  },
  errors: {
    activeEvidencingGroup: null,
    evidencingGroupList: null
  }
}

const mutations = {
  [Evidencing.Mutations.setViewingOrgWideEvidencing](state, { viewing }) {
    state.viewingOrgWideEvidencing = viewing;
  },
  [Evidencing.Mutations.setOrgWideEvidencing](state, { evidencingData }) {
    state.activeEvidencingGroup = evidencingData;
  },
  [Evidencing.Mutations.setActiveEvidencingGroup](state, { evidencingGroup }) {
    state.activeEvidencingGroup = evidencingGroup
  },
  [Evidencing.Mutations.setEvidencingGroups](state, { evidencingGroupList }) {
    state.evidencingGroupList = evidencingGroupList
  },
  [Evidencing.Mutations.removeEvidencingGroup](state, { groupId }) {
    state.evidencingGroupList = state.evidencingGroupList.filter(({ id }) => id != groupId);
  },
  [Evidencing.Mutations.setEvidencingError](state, { errorKey, errorMsg }) {
    state.errors[errorKey] = errorMsg
  },
  [Evidencing.Mutations.resetEvidencingError](state, { errorKey }) {
    state.errors[errorKey] = null;
  },
  [Evidencing.Mutations.setLoadingState](state, { loadingKey, status }) {
    state.loading[loadingKey] = status;
  }
}

const actions = {
  [Evidencing.Actions.fetchAllOrgEvidencingData]({ commit }, { year, orgNodeId }) {
    commit(Evidencing.Mutations.setViewingOrgWideEvidencing, { viewing: true });
    commit(Evidencing.Mutations.resetEvidencingError, { errorKey: ACTIVE_EVIDENCING_GROUP });
    commit(Evidencing.Mutations.setLoadingState, { loadingKey: ACTIVE_EVIDENCING_GROUP, status: true });

    getEvidencingByOrg({ year, orgNodeId })
    .then(response => {
      commit(Evidencing.Mutations.setOrgWideEvidencing, { evidencingData: response['data'] });
      commit(Evidencing.Mutations.setLoadingState, { loadingKey: ACTIVE_EVIDENCING_GROUP, status: false });
    })
    .catch(err => {
      commit(Evidencing.Mutations.setEvidencingError, { errorKey: ACTIVE_EVIDENCING_GROUP, errorMsg: String(err) });
      commit(Evidencing.Mutations.setLoadingState, { loadingKey: ACTIVE_EVIDENCING_GROUP, status: false });
    })
  },
  [Evidencing.Actions.fetchEvidencingGroupData]({ commit }, { year, groupId }) {
    commit(Evidencing.Mutations.setViewingOrgWideEvidencing, { viewing: false });
    commit(Evidencing.Mutations.resetEvidencingError, { errorKey: ACTIVE_EVIDENCING_GROUP });
    commit(Evidencing.Mutations.setLoadingState, { loadingKey: ACTIVE_EVIDENCING_GROUP, status: true });

    fetchGroupData({ year, groupId })
    .then(response => {
      commit(Evidencing.Mutations.setActiveEvidencingGroup, { evidencingGroup: response['data'] });
      commit(Evidencing.Mutations.setLoadingState, { loadingKey: ACTIVE_EVIDENCING_GROUP, status: false });
    })
    .catch(err => {
      commit(Evidencing.Mutations.setEvidencingError, { errorKey: ACTIVE_EVIDENCING_GROUP, errorMsg: String(err) });
      commit(Evidencing.Mutations.setLoadingState, { loadingKey: ACTIVE_EVIDENCING_GROUP, status: false });
    })
  },
  [Evidencing.Actions.fetchEvidencingByOrgNode]({ commit }, { year, org_node_id }) {
    commit(Evidencing.Mutations.resetEvidencingError, { errorKey: EVIDENCING_GROUP_LIST });
    commit(Evidencing.Mutations.setLoadingState, { loadingKey: EVIDENCING_GROUP_LIST, status: true });

    fetchClientBasedGroups({ year, org_node_id })
    .then(response => {
      if (response['data'] != null) {
        commit(Evidencing.Mutations.setEvidencingGroups, { evidencingGroupList: response['data'] });
        commit(Evidencing.Mutations.setLoadingState, { loadingKey: EVIDENCING_GROUP_LIST, status: false })
      }
    })
    .catch(err => {
      commit(Evidencing.Mutations.setEvidencingError, { errorKey: EVIDENCING_GROUP_LIST, errorMsg: String(err) });
      commit(Evidencing.Mutations.setLoadingState, { loadingKey: EVIDENCING_GROUP_LIST, status: false })
    })
  },
  [Evidencing.Actions.deleteEvidencingGroup]({ commit }, { groupId }) {
    commit(Evidencing.Mutations.resetEvidencingError, { errorKey: DELETION });
    commit(Evidencing.Mutations.setLoadingState, { loadingKey: DELETION, status: true });

    deleteEvidencingGroup({ groupId })
    .then(response => {
      if (response != null && response['data'] != null) {
        const groupId = response['data']['group_id'];
        commit(Evidencing.Mutations.removeEvidencingGroup, { groupId });
      }
      commit(Evidencing.Mutations.setLoadingState, { loadingKey: DELETION, status: false });
    })
    .catch(err => {
      commit(Evidencing.Mutations.setEvidencingError, { errorKey: DELETION, errorMsg: err });
      commit(Evidencing.Mutations.setLoadingState, { loadingKey: DELETION, status: false });
    })
  }
}

const getters = {
  [Evidencing.Getters.allEvidencingTypesWithFields]: (state, getters) => {
    const allEvidencingTypes = {};

    for (const { value } of EVIDENCING_TYPE_CHOICES) {
      allEvidencingTypes[value] = [];
    }

    if (state.activeEvidencingGroup != null) {
      for (const { fields, confirmation_type } of state.activeEvidencingGroup['confirmations']) {
        for (const field of fields) {
          if (allEvidencingTypes[confirmation_type].includes(field['id'])) continue
          allEvidencingTypes[confirmation_type].push(field['id']);
        }
      }
    }

    return allEvidencingTypes
  },
  [Evidencing.Getters.allFields]: state => {
    const allFields = [];

    if (state.activeEvidencingGroup != null) {
      for (const { fields } of state.activeEvidencingGroup['confirmations']) {
        for (const field of fields) {
          const found = allFields.find(({ id }) => field['id'] == id);
          if (found == null) allFields.push(field);
        }
      }
    }

    return allFields
  },
  [Evidencing.Getters.allFieldIds]: state => {
    const allIds = [];

    if (state.activeEvidencingGroup != null) {
      for (const { fields } of state.activeEvidencingGroup['confirmations']) {
        for (const { id } of fields) {
          if (!allIds.includes(id)) {
            allIds.push(id);
          }
        }
      }
    }

    return allIds
  },
  [Evidencing.Getters.getFormattedEvidencing]: state => {
    const formatted = [];

    if (state.activeEvidencingGroup != null) {
      const evidencingList = state.viewingOrgWideEvidencing && !('confirmations' in state.activeEvidencingGroup)
        ? state.activeEvidencingGroup 
        : state.activeEvidencingGroup['confirmations'];

      for (const evidencing of evidencingList) {
        const formattedType = EVIDENCING_TYPE_CHOICES.find(ct => ct["value"] == evidencing["confirmation_type"])
        evidencing["formatted_confirmation_type"] = formattedType != null ? formattedType["name"] : "None"
        evidencing["hasUploads"] = evidencing["uploads"].length > 0
        formatted.push(evidencing)
      }
    }

    return formatted
  },
  [Evidencing.Getters.getAllFieldCrops]: state => {
    const allFieldCrops = {};

    if (state.activeEvidencingGroup != null) {
      for (const evidencing of state.activeEvidencingGroup['confirmations']) {
        for (const field of evidencing['fields']) {
          if (!(field['id'] in allFieldCrops)) {
            allFieldCrops[field['id']] = { id: field['id'], name: field['name'], crops: {} };
  
            for (const crop of evidencing['crops']) {
              allFieldCrops[field['id']]['crops'][crop['id']] = crop;
            }
          }
          else {
            for (const crop of evidencing['crops']) {
              if (crop['id'] in allFieldCrops[field['id']]['crops']) {
                for (const planting of crop['plantings']) {
                  if (!allFieldCrops[field['id']]['crops'][crop['id']]['plantings'].includes(planting)) {
                    allFieldCrops[field['id']]['crops'][crop['id']]['plantings'].push(planting);
                  }
                }
              }
              else {
                allFieldCrops[field['id']]['crops'][crop['id']] = crop;
              }
            }
          }
        }
      }
    }

    return allFieldCrops
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
}
