<template>
  <ArvaModal
    name="saveLoaderModal"
    :title="titleText"
    @close-modal="handleCloseModal"
  >
    <v-card-text>
      <div class="d-flex" v-if="loading">
        <v-progress-circular indeterminate color="green"></v-progress-circular>
        <span class="ml-2 text-h6">{{ savingText }}</span>
      </div>
      <div class="d-flex" v-if="loadComplete">
        <v-icon color="green">mdi-check-circle</v-icon>
        <span class="ml-2 text-h6">{{ loadCompleteText }}</span>
      </div>
    </v-card-text>
  </ArvaModal>
</template>

<script>
import ArvaModal from "./ArvaModal"

export default {
  name: "SaveLoaderModal",
  props: {
    loading: { type: Boolean },
    loadComplete: { type: Boolean },
    loadCompleteText: { type: String },
    savingText: { type: String },
    titleText: { type: String },
  },
  components: { ArvaModal },

  methods: {
    handleCloseModal() {
      this.$emit("close-modal")
    },
  },
}
</script>
