<template>
  <div>
    <DashboardTitle
      :title="`Historical Profits and Losses - ${year}`"
      faClass="fa-usd"
    />
    <div v-if="totalYield > 0">
      <div class="row">
        <div class="col">
          <h3 class="pt-1">Summary</h3>
        </div>
        <div class="col">
          <div
            v-if="!isReadOnly"
            class="btn btn-primary btn-sm float-right mt-4"
            @click="csvSummaryExport(roiSummary)"
          >
            Download CSV
          </div>
        </div>
      </div>

      <v-simple-table>
        <thead>
          <tr>
            <th />
            <th>Crop</th>
            <th class="text-right">Total Acres</th>
            <th class="text-right">Total Yield / Ac.</th>
            <th class="text-right">Total Yield</th>
            <th class="text-right">Revenue</th>
            <th class="text-right">
              Seed Costs
              <i
                class="fa fa-info-circle"
                data-toggle="tooltip"
                data-placement="top"
                title="Default seed costs may not reflect your actual costs. To improve and/or adjust your ROI financials, click on FIELDS | LIST VIEW from the menu on the left, then edit the 'Financials / ROI' settings to set specific field settings information."
              />
            </th>
            <th class="text-right">
              Fertilizer Costs
              <i
                class="fa fa-info-circle"
                data-toggle="tooltip"
                data-placement="top"
                title="Default fertilizer costs may not reflect your actual costs. To improve and/or adjust your ROI financials, click on FIELDS | LIST VIEW from the menu on the left, then edit the 'Financials / ROI' settings to set specific field settings information."
              />
            </th>
            <th class="text-right">
              Estimated Op Costs
              <i
                class="fa fa-info-circle"
                data-toggle="tooltip"
                data-placement="top"
                title="Est. Operating Costs include chemicals, irrigation, labor, and fuel. It excludes land rent and equipment recovery costs."
              />
            </th>
            <th class="text-right">Gross Profit</th>
            <th class="text-right gross-profit">Gross Profit / Ac.</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in roiSummary" :key="row.crop">
            <td><img class="crop-icon" :src="cropIconUrls[row.crop]" /></td>
            <td>{{ row.crop | crop }}</td>
            <td class="text-right">{{ row.acreage | prettyInteger }}</td>
            <td class="text-right">
              {{ (row.yield / row.acreage) | prettyInteger }}
            </td>
            <td class="text-right">{{ row.yield | prettyInteger }}</td>
            <td class="text-right">{{ row.revenue | intCurrency }}</td>
            <td class="text-right">{{ row.seedCost | intCurrency }}</td>
            <td class="text-right">{{ row.fertCost | intCurrency }}</td>
            <td class="text-right">{{ row.opCost | intCurrency }}</td>
            <td class="text-right">{{ row.profit | intCurrency }}</td>
            <td class="text-right gross-profit">
              {{ row.profitPerAcre | intCurrency }}
            </td>
          </tr>
          <tr class="total-row">
            <td />
            <td>Total</td>
            <td class="text-right">{{ totalAcres | prettyInteger }}</td>
            <td class="text-right">
              {{ (totalYield / totalAcres) | prettyInteger }}
            </td>
            <td class="text-right">{{ totalYield | prettyInteger }}</td>
            <td class="text-right">{{ totalRevenue | intCurrency }}</td>
            <td class="text-right">{{ totalSeedCost | intCurrency }}</td>
            <td class="text-right">{{ totalFertCost | intCurrency }}</td>
            <td class="text-right">{{ totalOpCost | intCurrency }}</td>
            <td class="text-right">{{ totalGrossProfit | intCurrency }}</td>
            <td class="text-right gross-profit">
              {{ totalGrossProfitPerAcre | intCurrency }}
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <div class="row">
        <div class="col">
          <h3 class="pt-1">By Field</h3>
        </div>
        <div class="col">
          <div
            v-if="!isReadOnly"
            class="btn btn-primary btn-sm float-right mt-4"
            @click="csvFieldExport(fieldRows)"
          >
            Download CSV
          </div>
        </div>
      </div>

      <v-simple-table fixed-header height="calc(100vh - 470px)">
        <thead>
          <tr>
            <th />
            <th>Field</th>
            <th class="text-right">Acreage</th>
            <th>Crop</th>
            <th>Variety</th>
            <th class="text-right">Avg. Yield / Ac.</th>
            <th class="text-right">Total Yield</th>
            <th class="text-right">Revenue</th>
            <th class="text-right">
              Seed Costs
              <i
                class="fa fa-info-circle"
                data-toggle="tooltip"
                data-placement="top"
                title="Default seed costs may not reflect your actual costs. To improve and/or adjust your ROI financials, click on FIELDS | LIST VIEW from the menu on the left, then edit the 'Financials / ROI' settings to set specific field settings information."
              />
            </th>
            <th class="text-right">
              Fertilizer Costs
              <i
                class="fa fa-info-circle"
                data-toggle="tooltip"
                data-placement="top"
                title="Default fertilizer costs may not reflect your actual costs. To improve and/or adjust your ROI financials, click on FIELDS | LIST VIEW from the menu on the left, then edit the 'Financials / ROI' settings to set specific field settings information."
              />
            </th>
            <th class="text-right">
              Estimated Op Costs
              <i
                class="fa fa-info-circle"
                data-toggle="tooltip"
                data-placement="top"
                title="Est. Operating Costs include chemicals, irrigation, labor, and fuel. It excludes land rent and equipment recovery costs."
              />
            </th>
            <th class="text-right">Gross Profit</th>
            <th class="text-right gross-profit">Gross Profit / Ac.</th>
          </tr>
        </thead>
        <tbody>
          <HistoricalProfitAndLossRow
            v-for="field in fieldRows"
            :key="field.id"
            :field="field"
            :year="year"
          />
        </tbody>
      </v-simple-table>
    </div>
    <div v-else>
      <PageMissing />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import DashboardTitle from "@/components/dashboard/DashboardTitle"
import HistoricalProfitAndLossRow from "@/components/profitAndLoss/HistoricalProfitAndLossRow"
import { CROP_DISPLAY_NAME, CROP_ID_TO_ICON_URL } from "@/constants"
import { Dashboard, Filter } from "@/store/modules"
import PageMissing from "@/components/misc/PageMissing"
export default {
  name: "HistoricalProfitsAndLosses",

  components: { DashboardTitle, HistoricalProfitAndLossRow, PageMissing },

  data() {
    return {
      cropIconUrls: CROP_ID_TO_ICON_URL,
    }
  },

  computed: {
    ...mapGetters({
      activeFields: Filter.Getters.getSelectedFields,
      roiSummary: Dashboard.Getters.getRoiSummary,
    }),

    ...mapState({
      isReadOnly: state => state.User.user.permission === "Read-Only",
      year: state => state.Organization.year,
      historicalROISummary: state => state.Fields.historicalROISummary,
    }),

    fieldRows() {
      return this.activeFields.filter(field => field.historicalROI[this.year])
    },

    totalAcres() {
      let totalAcres = 0
      for (const row of this.fieldRows) totalAcres += row.acreage
      return totalAcres
    },

    totalYield() {
      let totalYield = 0
      for (const row of this.roiSummary) totalYield += row.yield
      return totalYield
    },

    totalRevenue() {
      let totalRevenue = 0
      for (const row of this.roiSummary) totalRevenue += row.revenue
      return totalRevenue
    },

    totalSeedCost() {
      let totalSeedCost = 0
      for (const row of this.roiSummary) totalSeedCost += row.seedCost
      return totalSeedCost
    },

    totalFertCost() {
      let totalFertCost = 0
      for (const row of this.roiSummary) totalFertCost += row.fertCost
      return totalFertCost
    },

    totalOpCost() {
      let totalOpCost = 0
      for (const row of this.roiSummary) totalOpCost += row.opCost
      return totalOpCost
    },

    totalGrossProfit() {
      let totalProfit = 0
      for (const row of this.roiSummary) totalProfit += row.profit
      return totalProfit
    },

    totalGrossProfitPerAcre() {
      let totalAcreageCalculated = 0
      for (const row of this.roiSummary)
        totalAcreageCalculated += row.acreageCalculated
      return this.totalGrossProfit / totalAcreageCalculated
    },
  },

  methods: {
    csvFieldExport(fieldsData) {
      const headers = [
        "Field",
        "Acreage",
        "Crop",
        "Variety",
        "Yield/Ac",
        "Yield",
        "Revenue",
        "Seed Costs",
        "Fertilizer Costs",
        "Estimated Op Costs",
        "Gross Profit",
        "Gross Profit/Ac",
      ].join(",")
      const tableData = fieldsData.map(field => {
        const name = field.name
        const acreage = field.acreage
        const historicalROI = field.historicalROI[this.year]
        if (!historicalROI) {
          return [
            name,
            parseInt(acreage),
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
          ].join(",")
        }
        const {
          crop,
          seed_varieties,
          field_yield,
          revenue,
          seed_cost,
          fert_cost,
          opcost,
          profit,
          profit_per_acre,
        } = historicalROI
        const yieldPerAcre = parseInt(field_yield / field.acreage)
        const seedVarieties = seed_varieties ? seed_varieties : ""

        const intDollar = num => `$${num.toFixed(2)}`

        const arrayToJoin = [
          name,
          parseInt(acreage),
          CROP_DISPLAY_NAME[crop],
          seedVarieties.replaceAll(",", ";"),
          yieldPerAcre,
          parseInt(field_yield),
          intDollar(revenue),
          intDollar(seed_cost),
          intDollar(fert_cost),
          intDollar(opcost),
          intDollar(profit),
          intDollar(profit_per_acre),
        ].join(",")
        return arrayToJoin
      })
      const csvContent = [headers, ...tableData]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "")
      const csvData = new Blob([csvContent], { type: "text/csv" })
      const link = document.createElement("a")
      link.setAttribute("href", URL.createObjectURL(csvData))
      link.setAttribute("download", `Profit_and_Loss_Fields_${this.year}.csv`)
      link.click()
    },

    csvSummaryExport(summaryData) {
      const headers = [
        "Crop",
        "Total Acres",
        "Yield/Ac",
        "Total Yield",
        "Revenue",
        "Seed Costs",
        "Fertilizer Costs",
        "Estimated Op Costs",
        "Gross Profit",
        "Gross Profit/Ac",
      ].join(",")
      const tableData = summaryData.map(summary => {
        const {
          crop,
          acreage,
          revenue,
          seedCost,
          fertCost,
          opCost,
          profit,
          profitPerAcre,
        } = summary
        const yieldPerAcre = this.$options.filters.prettyInteger(
          summary.yield / acreage
        )

        const intDollar = num => `$${num.toFixed(2)}`

        const arrayToJoin = [
          CROP_DISPLAY_NAME[parseInt(crop)],
          parseInt(acreage),
          yieldPerAcre,
          parseInt(summary.yield),
          intDollar(revenue),
          intDollar(seedCost),
          intDollar(fertCost),
          intDollar(opCost),
          intDollar(profit),
          intDollar(profitPerAcre),
        ].join(",")
        return arrayToJoin
      })
      const csvContent = [headers, ...tableData]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "")
      const csvData = new Blob([csvContent], { type: "text/csv" })
      const link = document.createElement("a")
      link.setAttribute("href", URL.createObjectURL(csvData))
      link.setAttribute("download", `Profit_and_Loss_Summary_${this.year}.csv`)
      link.click()
    },
  },

  mounted() {
    const { $ } = window
    $(function() {
      $('[data-toggle="tooltip"]').tooltip()
    })
  },
}
</script>

<style scoped>
h3 {
  font-size: 16px;
  font-weight: 500;
  margin-top: 25px;
  margin-bottom: 10px;
}

th {
  white-space: nowrap;
}

.total-row {
  border-top: 2pt solid #ccc;
}

.crop-icon {
  width: 30px;
  height: 30px;
}

.gross-profit {
  background: #d1ecfe !important;
}
</style>
