<template>
  <div class="pdf-wrapper">
    <div class="row">
      <div class="col-12">
        <div class="title">YIELD</div>
        <div class="sub-title d-flex justify-content-between">
          <div>Harvest Analytics</div>
          <div>{{ reportData.field_name }} - {{ reportData.year }}</div>
        </div>
      </div>
      <div class="top-logo">
        <img
          class="profile-img img-fluid mt-2"
          src="/assets/images/logos/arva-logo.png"
          alt="User"
        />
      </div>
    </div>
    <div class="row background-dim mx-0">
      <div class="col" />
    </div>
    <div class="row">
      <div class="col-6 pt-0">
        <table class="table">
          <tbody>
            <tr>
              <td class="text-nowrap text-left pl-4">
                Grower:
              </td>
              <td class="text-nowrap font-weight-bold dk-grey text-right">
                {{ reportData.client }}
              </td>
            </tr>
            <tr>
              <td class="text-nowrap text-left pl-4">
                Farm:
              </td>
              <td class="text-right text-nowrap font-weight-bold dk-grey">
                {{ reportData.farm }}
              </td>
            </tr>
            <tr>
              <td class="text-nowrap text-left pl-4">
                Acreage:
              </td>
              <td class="text-right text-nowrap font-weight-bold dk-grey">
                <span v-if="plantedAcreage > 0">Planted {{ plantedAcreage | prettyInteger }} ac / </span>
                <span>{{ reportData.acreage | prettyInteger }} ac</span>
              </td>
            </tr>
            <tr>
              <td class="text-nowrap text-left pl-4">
                Crop:
              </td>
              <td class="text-right text-nowrap font-weight-bold dk-grey">
                {{ reportData.crop | crop }}
              </td>
            </tr>
            <tr>
              <td class="text-nowrap text-left pl-4">
                Avg. Yield:
              </td>
              <td class="green-cell text-right">
                {{ yieldPerAcre | floatTenth }}
                {{ reportData.crop | yieldUnits }}
              </td>
            </tr>
            <tr v-if="hasHybridData">
              <td class="text-nowrap text-left pl-4">Seed Varieties:</td>
              <td class="green-cell text-right">
                <div v-for="hybrid in hybridVarieties" :key="hybrid">
                  {{ hybrid }}
                </div>
              </td>
            </tr>
            <tr v-if="reportData.planting_date">
              <td class="text-nowrap text-left pl-4">Planting Date:</td>
              <td class="green-cell text-right">
                {{ reportData.planting_date | date }}
              </td>
            </tr>
            <tr v-if="reportData.harvest_date">
              <td class="text-nowrap text-left pl-4">Harvest Date:</td>
              <td class="green-cell text-right">
                {{ reportData.harvest_date }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-6 pt-0">
        <div class="map-wrapper">
          <div id="mapbox-map" class="fill-wrapper"></div>
          <div class="map-footer">
            <div class="elevation-scale">Yield Scale</div>
            <div class="row mx-0 color-bar">
              <div class="col red-fill"></div>
              <div class="col orange-fill"></div>
              <div class="col yellow-fill"></div>
              <div class="col light-green-fill"></div>
              <div class="col bright-green-fill"></div>
              <div class="col green-fill"></div>
            </div>
          </div>
        </div>
        <div class="row scale-text mx-0">
          <div class="col text-center font-weight-bold">
            {{ colorValueRanges[0] | prettyInteger }}-{{
              colorValueRanges[1] | prettyInteger
            }}
          </div>
          <div class="col text-center font-weight-bold">
            {{ colorValueRanges[1] | prettyInteger }}-{{
              colorValueRanges[2] | prettyInteger
            }}
          </div>
          <div class="col text-center font-weight-bold">
            {{ colorValueRanges[2] | prettyInteger }}-{{
              colorValueRanges[3] | prettyInteger
            }}
          </div>
          <div class="col text-center font-weight-bold">
            {{ colorValueRanges[3] | prettyInteger }}-{{
              colorValueRanges[5] | prettyInteger
            }}
          </div>
          <div class="col text-center font-weight-bold">
            {{ colorValueRanges[5] | prettyInteger }}-{{
              colorValueRanges[6] | prettyInteger
            }}
          </div>
          <div class="col text-center font-weight-bold">
            {{ colorValueRanges[6] | prettyInteger }}-{{
              colorValueRanges[8] | prettyInteger
            }}
          </div>
        </div>
      </div>
    </div>
    <div class="row background-dim mx-0">
      <div class="col" />
    </div>
    <div class="row">
      <div
        v-if="hasHybridData"
        :class="hasAgtData ? 'col-6' : 'col'"
        class="pl-5 pr-5"
      >
        <div class="chart-title">Yield By Hybrid</div>
        <apexchart
          type="bar"
          height="300"
          :options="yieldByHybridOptions"
          :series="yieldByHybridSeries"
        />
      </div>
      <div
        v-if="hasAgtData"
        :class="hasHybridData ? 'col-6' : 'col'"
        class="pl-5 pr-5"
      >
        <div class="chart-title">Yield By Arva Ground Type (AGT)</div>
        <apexchart
          type="bar"
          height="300"
          :options="yieldByAgtOptions"
          :series="yieldByAgtSeries"
        />
      </div>
    </div>
    <div class="row bottom-row">
      <div class="col-2"></div>
      <div class="col-8 revenue-card">
        <div class="gradient-text">
          <div class="text-center revenue-amount">
            <span class="dollar-sign">$</span>
            <span class="revenue-per-acre">{{
              revenuePerAcre | prettyInteger
            }}</span>
          </div>
          <div class="text-center revenue-text">Average Revenue Per Acre</div>
        </div>
      </div>
      <div class="col-2"></div>
    </div>

    <div class="footer d-flex align-items-center justify-content-between">
      <div class="ml-2">
        Confidential
      </div>
      <div class="mr-3">
        <i class="fa fa-copyright" /> 2021 Arva Intelligence
        <i class="fa fa-trademark" />
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash"
import { GeoJsonLayer } from "@deck.gl/layers"
import { MapboxLayer } from "@deck.gl/mapbox"
import mapboxgl from "mapbox-gl"

import { MAPBOX_TOKEN } from "@/constants/map"
import {
  getRangesBasedOnStdDeviationYieldNew,
  HARVEST_YIELD_COLORS,
} from "@/components/map/layers"
import { limitTextLength } from "@/utility"

export default {
  name: "FieldPerformanceReport",
  props: ["reportData", "voxelGeom"],
  data() {
    return {
      colorValueRanges: [],
      cropAcreage: null,
    }
  },

  computed: {
    hybridVarieties() {
      return this.reportData.hybrid_data.map(d => d.seed_variety)
    },

    plantedAcreage() {
      const arrayOfAcreage = this.reportData.hybrid_data.map(o => o.voxel_acreage)
      return _.sum(arrayOfAcreage)
    },

    yieldPerAcre() {
      const { acreage, total_yield } = this.reportData
      return total_yield / acreage
    },

    revenuePerAcre() {
      const { acreage, total_revenue } = this.reportData
      return total_revenue / acreage
    },

    yieldByAgtSeries() {
      const sortedAgts = _.orderBy(this.reportData.agt_yield, ["agt"], ["asc"])
      const data = sortedAgts.map(d => parseInt(d.yield_per_agt))
      const series = [
        {
          name: "AGT Yield",
          data: data,
        },
      ]
      return series
    },

    yieldByAgtOptions() {
      const sortedAgts = _.orderBy(this.reportData.agt_yield, ["agt"], ["asc"])
      const agts = sortedAgts.map(d => `AGT - ${d.agt}`)
      const options = {
        chart: {
          height: 400,
          type: "bar",
          toolbar: { show: false },
          offsetY: -10,
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: 5,
          style: {
            fontFamily: "Montserrat, sans-serif",
            fontSize: "14px",
            fontWeight: "600",
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            shadeIntensity: 1,
            type: "vertical",
            colorStops: [
              {
                offset: 0,
                color: "#79C61C",
                opacity: 1,
              },
              {
                offset: 80,
                color: "#F05400",
                opacity: 1,
              },
            ],
          },
        },
        xaxis: {
          labels: {
            rotate: -45,
            rotateAlways: true,
            style: {
              fontWeight: "600",
            },
          },
          categories: agts,
          tickPlacement: "on",
        },
      }
      return options
    },

    yieldByHybridData() {
      return this.reportData.hybrid_data.filter(d => d.seed_variety)
    },

    hasHybridData() {
      return this.yieldByHybridData.length > 0
    },

    hasAgtData() {
      return this.reportData.agt_yield.length > 0
    },

    yieldByHybridSeries() {
      const data = this.yieldByHybridData.map(d =>
        parseInt(d.yield_sum / d.voxel_acreage)
      )
      const series = [
        {
          name: "Hybrid Yield",
          data,
        },
      ]
      return series
    },

    yieldByHybridOptions() {
      const hybrids = this.yieldByHybridData
        .filter(d => d.seed_variety)
        .map(d => limitTextLength(d.seed_variety, 13))
      const options = {
        chart: {
          height: 400,
          type: "bar",
          toolbar: { show: false },
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: 5,
          style: {
            fontFamily: "Montserrat, sans-serif",
            fontSize: "14px",
            fontWeight: "600",
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            shadeIntensity: 1,
            type: "vertical",
            colorStops: [
              {
                offset: 0,
                color: "#79C61C",
                opacity: 1,
              },
              {
                offset: 80,
                color: "#F05400",
                opacity: 1,
              },
            ],
          },
        },
        xaxis: {
          labels: {
            style: {
              fontWeight: "600",
            },
          },
          categories: hybrids,
          tickPlacement: "on",
        },
      }
      return options
    },
  },

  methods: {
    getColorForYield(properties, valueRanges) {
      const { cropId, yieldPerAcreNew } = properties
      if (yieldPerAcreNew == undefined) return null

      const valueRange = valueRanges[cropId]
      const objWithValue = yieldPerAcreNew.find(o =>
        Object.keys(o).includes(cropId.toString())
      )

      if (objWithValue[cropId] <= 0 || objWithValue[cropId] === null)
        return [0, 0, 0, 0]

      const colorIdx =
        valueRange.length -
        valueRange.filter(cutoffVal => objWithValue[cropId] <= cutoffVal).length

      return HARVEST_YIELD_COLORS[colorIdx]
    },

    fitBoundsRotated(bounds, options, eventData, map) {
      options = Object.assign(
        {
          padding: {
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
          },
          offset: [0, 0],
          maxZoom: map.transform.maxZoom,
        },
        options
      )

      if (typeof options.padding === "number") {
        const p = options.padding
        options.padding = {
          top: p,
          bottom: p,
          right: p,
          left: p,
        }
      }

      bounds = mapboxgl.LngLatBounds.convert(bounds)

      // paddingOffset affects the map's center, used in the options for
      // `easeTo` and `flyTo` in subsequent calls
      const paddingOffset = [
          options.padding.left - options.padding.right,
          options.padding.top - options.padding.bottom,
        ],
        lateralPadding = Math.min(options.padding.right, options.padding.left),
        verticalPadding = Math.min(options.padding.top, options.padding.bottom)

      options.offset = [
        options.offset[0] + paddingOffset[0],
        options.offset[1] + paddingOffset[1],
      ]
      options.bearing = options.bearing || this.getBearing()

      const offset = mapboxgl.Point.convert(options.offset),
        tr = map.transform,
        nw = tr.project(bounds.getNorthWest()),
        se = tr.project(bounds.getSouthEast()),
        size = se.sub(nw)

      // START CUSTOM ROTATION
      // "cropped rectangle rotation method"
      // https://stackoverflow.com/questions/33866535/how-to-scale-a-rotated-rectangle-to-always-fit-another-rectangle
      const theta = options.bearing * (Math.PI / 180),
        W =
          size.x * Math.abs(Math.cos(theta)) +
          size.y * Math.abs(Math.sin(theta)),
        H =
          size.x * Math.abs(Math.sin(theta)) +
          size.y * Math.abs(Math.cos(theta)),
        rotatedSize = { x: W, y: H },
        // END CUSTOM ROTATION
        scaleX =
          (tr.width - lateralPadding * 2 - Math.abs(offset.x) * 2) /
          rotatedSize.x,
        scaleY =
          (tr.height - verticalPadding * 2 - Math.abs(offset.y) * 2) /
          rotatedSize.y

      if (scaleY < 0 || scaleX < 0) {
        if (typeof console !== "undefined")
          console.warn(
            "Map cannot fit within canvas with the given bounds, padding, and/or offset."
          )
        return map
      }

      options.center = tr.unproject(nw.add(se).div(2))
      options.zoom = Math.min(
        tr.scaleZoom(tr.scale * Math.min(scaleX, scaleY)),
        options.maxZoom
      )

      return options.linear
        ? map.easeTo(options, eventData)
        : map.flyTo(options, eventData)
    },

    setColorValueRanges(ranges) {
      this.colorValueRanges = ranges
    },
  },

  mounted() {
    mapboxgl.accessToken = MAPBOX_TOKEN
    const map = new mapboxgl.Map({
      preserveDrawingBuffer: true,
      container: "mapbox-map",
      height: "100%",
      width: "100%",
      style: "mapbox://styles/mapbox/satellite-v9",
      center: [-91.6, 34.4],
      zoom: 9,
      pitch: 60,
    })

    const selectedFieldId = this.reportData.field_id
    const mapElevation = 5

    // create a feature collection for visualizing yield by voxel
    const yieldFeatures = []
    for (const voxel in this.voxelGeom) {
      const {
        cropId,
        elevation,
        geom,
        voxel_id,
        yieldPerAcre,
        yieldPerAcreNew,
      } = this.voxelGeom[voxel]

      if (cropId == this.reportData.crop) {
        yieldFeatures.push({
          type: "Feature",
          geometry: geom,
          properties: {
            yieldPerAcre: yieldPerAcre,
            yieldPerAcreNew: yieldPerAcreNew,
            cropId,
            elevation,
            selectedFieldId,
            voxel_id,
          },
        })
      }
    }
    const voxelFeatureCollection = {
      type: "FeatureCollection",
      features: yieldFeatures,
    }

    // for voxel color by yield
    const valueRanges = getRangesBasedOnStdDeviationYieldNew(
      voxelFeatureCollection.features
    )
    // this.setColorValueRanges(_.flatMap(Object.values(valueRanges)))
    this.setColorValueRanges(valueRanges[this.reportData.crop])

    const yieldLayer = new MapboxLayer({
      id: "yieldLayer",
      type: GeoJsonLayer,
      data: voxelFeatureCollection,
      stroked: false,
      filled: true,
      extruded: true,
      autoHighlight: false,
      pointRadiusScale: 5,
      getElevation: () => mapElevation,
      getFillColor: feat => this.getColorForYield(feat.properties, valueRanges),
      getLineColor: () => [0, 0, 0, 255],
      lineWidthMinPixels: 0,
    })

    // calculate fieldBoundaries for zooming
    const calculateExtentBounds = fieldBoundData => {
      if (!fieldBoundData) return {}
      const latLongValues = fieldBoundData.features.map(
        feat => feat.geometry.coordinates[0][0]
      )

      const longVals = latLongValues.flat().map(subarr => subarr[0])
      const latVals = latLongValues.flat().map(subarr => subarr[1])

      const minLatitude = Math.min.apply(null, latVals)
      const maxLatitude = Math.max.apply(null, latVals)
      const minLongitude = Math.min.apply(null, longVals)
      const maxLongitude = Math.max.apply(null, longVals)

      return { minLatitude, maxLatitude, minLongitude, maxLongitude }
    }
    let fieldExtentBounds = calculateExtentBounds(voxelFeatureCollection)
    this.fitBoundsRotated(
      [
        [fieldExtentBounds.minLongitude, fieldExtentBounds.minLatitude],
        [fieldExtentBounds.maxLongitude, fieldExtentBounds.maxLatitude],
      ],
      { bearing: -45 },
      null,
      map
    )

    map.on("load", () => map.addLayer(yieldLayer))
  },
}
</script>

<style>
@page {
  size: 8.5in 11in;
  margin: 0;
}
</style>

<style scoped>
.pdf-wrapper {
  width: 1000px;
  height: 1283px;
  position: fixed;
}

.map-wrapper {
  height: 500px;
  position: relative;
  background: #1b1b1d;
  overflow: hidden;
  padding-right: 25px;
}

.fill-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.map-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1000;
}

.background-dim {
  background-color: #dcdcdc;
}

.color-bar {
  height: 44px;
  margin-top: 12px;
}

.scale-text {
  font-size: 12px;
  font-family: Montserrat, sans-serif;
}

.elevation-scale {
  color: white;
  font-weight: bold;
  font-size: 16px;
  margin-left: 6px;
}

.title {
  color: black;
  font-family: Montserrat, sans-serif;
  font-size: 45px;
  font-weight: 500;
  margin-left: 25px;
}

.title-bar {
  color: black;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 16px;
}

.sub-title {
  font-size: 32px;
  font-family: Montserrat, sans-serif;
  margin-left: 25px;
  margin-right: 25px;
  font-weight: 500;
}

.top-logo {
  position: absolute;
  top: 10px;
  right: 25px;
}

td {
  font-family: Montserrat, sans-serif;
  font-size: 18px;
}

td:first-of-type {
  text-align: right;
}

.dk-grey {
  color: #444;
}

.green-cell {
  font-weight: bold;
  color: #79c61c;
}

.red-fill {
  background-color: #ff0000;
}

.orange-fill {
  background-color: #ff5c00;
}

.light-orange-fill {
  background-color: #ff8200;
}

.yellow-fill {
  background-color: #ffd500;
}

.bright-yellow-fill {
  background-color: #ffff00;
}

.light-green-fill {
  background-color: #c8e632;
}

.bright-green-fill {
  background-color: #b4fa00;
}

.green-fill {
  background-color: #40ff00;
}

.revenue-card {
  background-color: #f0f0f0;
  border: 1pt solid #79c61c;
  border-radius: 10px;
  border-width: 1px;
  margin-top: 15px;
}

.dollar-sign {
  font-family: Montserrat, sans-serif;
  font-size: 30px;
  font-weight: bold;
}

.revenue-per-acre {
  font-family: Montserrat, sans-serif;
  font-size: 30px;
  font-weight: bold;
}

.revenue-text {
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 25px;
}

.gradient-text {
  background-color: #79c61c;
  background-image: linear-gradient(90deg, #383838, #79c61c);
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.chart-title {
  font-size: 24px;
  font-weight: 500;
  color: #555;
  margin-top: 20px;
}

.footer {
  position: absolute;
  bottom: 0px;
  height: 30px;
  width: 100%;
  background: #444;
  font-family: Montserrat, sans-serif;
  font-size: 13px;
  color: #fff;
  font-weight: bold;
}

.bottom-row {
  width: 100%;
  position: absolute;
  bottom: 0;
}
</style>
