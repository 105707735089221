<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="row">
          <DashboardTitle
            title="Harvest Analytics"
            faClass="fa-bar-chart"
            tooltip="Per crop harvest analytics shows an actual accounting of average yield per AGT zone in selected fields that are in focus. Included in harvest analytics is seed product names as planted by AGT zone and possible financial outcomes when precision planting is used per AGT zone."
          />
          <v-select-old
            v-if="hasCropPerformanceValues && varietiesList.length > 0"
            class="ml-3 mt-1"
            :clearable="false"
            :style="{ width: '140px', fontSize: '12px' }"
            :options="cropOptions"
            :reduce="o => o.value"
            label="display_name"
            v-model="selectedCrop"
          />
        </div>
      </div>
    </div>
    <div
      v-if="
        !hasCropPerformanceValues &&
          activeFields.length === 0 &&
          varietiesList.length >= 1
      "
      class="zero-state"
    >
      <div>
        Select fields to see crop performance.
      </div>
    </div>
    <div
      v-if="
        (!hasCropPerformanceValues && activeFields.length > 0) ||
          varietiesList.length < 1
      "
    >
      <div class="mt-6">
        <PageMissing />
      </div>
    </div>

    <div class="row" v-if="selectedCrop">
      <div class="col-12">
        <div class="mt-1">
          <h3>Seed Variety Performance by AGT in {{ currentYear }}</h3>
          <v-simple-table fixed-header>
            <thead>
              <tr>
                <th class="variety-header"></th>
                <th v-for="agt in agtList" :key="agt">
                  <Agt :key="agt" :agt="agt" />
                </th>
                <th>Variety Avg Yield</th>
              </tr>
            </thead>
            <tbody v-if="varietiesList">
              <tr v-for="variety in varietiesList" :key="variety">
                <td>{{ variety }}</td>
                <td v-for="agt in agtList" :key="variety + '_' + agt">
                  <span v-if="agtVariety[agt][variety]">{{
                    agtVariety[agt][variety].yieldPerAcre | prettyInteger
                  }}</span>
                  <span v-else>-</span>
                </td>
                <td class="farm-avg-yield-cell">
                  {{ varietyYield[variety].yieldPerAcre | prettyInteger }}
                </td>
              </tr>
              <tr>
                <td class="agt-avg-yield-cell">Farm Avg Yield by AGT</td>
                <td
                  class="agt-avg-yield-cell"
                  v-for="agt in agtList"
                  :key="'avg_yield_' + agt"
                >
                  {{
                    agtPerformanceSummary[selectedCrop][agt].avgYieldPerAcre
                      | prettyInteger
                  }}
                </td>
                <td />
              </tr>
              <tr>
                <td class="acres-cell">Acres in view</td>
                <td
                  class="acres-cell"
                  v-for="agt in agtList"
                  :key="'acres_' + agt"
                >
                  {{
                    agtPerformanceSummary[selectedCrop][agt].acreage
                      | prettyInteger
                  }}
                </td>
                <td />
              </tr>
            </tbody>
          </v-simple-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash"
import { mapGetters } from "vuex"

import Agt from "@/components/misc/Agt"
import { CROP_DISPLAY_NAME } from "@/constants"
import DashboardTitle from "@/components/dashboard/DashboardTitle"
// import TopPerformersRow from "@/components/dashboard/TopPerformersRow"
import { Dashboard, Filter, Organization } from "@/store/modules"
//import { getAgtColor } from "@/utility"
import PageMissing from "@/components/misc/PageMissing"

export default {
  name: "DashboardCropPerformance",
  components: {
    Agt,
    DashboardTitle,
    PageMissing,
    // TopPerformersRow,
  },

  data() {
    return {
      selectedCrop: null,
      selectedSort: "dollar",
    }
  },

  computed: {
    ...mapGetters({
      activeFields: Filter.Getters.getSelectedFields,
      agtPerformanceSummary: Dashboard.Getters.getAgtPerformanceSummary,
      cropAgtVarietySummary: Dashboard.Getters.getCropAgtVarietySummary,
      topPerformers: Dashboard.Getters.getTopPerformingVarieties,
      currentYear: Organization.Getters.getYear,
    }),

    hasCropPerformanceValues() {
      return !_.isEmpty(this.cropAgtVarietySummary)
    },

    cropOptions() {
      return Object.keys(this.cropAgtVarietySummary).map(cropId => ({
        value: cropId,
        display_name: CROP_DISPLAY_NAME[cropId],
      }))
    },

    agtList() {
      return Object.keys(this.cropAgtVarietySummary[this.selectedCrop])
    },

    varietiesList() {
      if (
        !this.selectedCrop ||
        _.isEmpty(this.topPerformers[this.selectedCrop])
      )
        return []

      return [
        ...new Set(
          this.topPerformers[this.selectedCrop].map(row => row.variety)
        ),
      ]
    },

    varietyYield() {
      const varietyYield = {}
      this.topPerformersRows.forEach(({ acreage, totalYield, variety }) => {
        if (!varietyYield[variety]) {
          varietyYield[variety] = { acreage: 0, totalYield: 0 }
        }
        varietyYield[variety].acreage += acreage
        varietyYield[variety].totalYield += totalYield
        varietyYield[variety].yieldPerAcre =
          varietyYield[variety].totalYield / varietyYield[variety].acreage
      })

      return varietyYield
    },

    agtVariety() {
      return this.cropAgtVarietySummary[this.selectedCrop]
    },

    topPerformersRows() {
      if (
        !this.selectedCrop ||
        _.isEmpty(this.topPerformers[this.selectedCrop])
      )
        return []

      const yieldSort = (a, b) => b.yieldPerAcre - a.yieldPerAcre
      const revenueSort = (a, b) => b.dollarDifference - a.dollarDifference
      const sorts = {
        yield: yieldSort,
        dollar: revenueSort,
      }
      return this.topPerformers[this.selectedCrop]
        .slice()
        .sort(sorts[this.selectedSort])
    },
  },

  mounted() {
    const activeCrops = Object.keys(this.cropAgtVarietySummary)
    if (activeCrops.length > 0) this.selectedCrop = activeCrops[0]
  },

  watch: {
    cropOptions: function(newVal) {
      if (newVal.length === 0) {
        this.selectedCrop = null
        return
      }
      if (this.selectedCrop) {
        const cropIds = newVal.map(({ value }) => parseInt(value))
        if (cropIds.includes(this.selectedCrop)) return
      }
      this.selectedCrop = newVal[0].value
    },
  },
}
</script>

<style scoped>
.container-fluid {
  height: 100%;
}

h3 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}

.form-check {
  margin-left: 12px;
}

.top-performers {
  max-height: 470px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.infocus-bar {
  font-size: 18px;
  margin-top: 5px;
}

.big-number {
  font-weight: 500;
  font-size: 28px;
}

.card-title {
  font-size: 16px;
  font-weight: 500;
}

.acres-cell {
  background-color: #fae96e;
  font-weight: bold;
}

.agt-avg-yield-cell {
  background-color: #e9ecef;
  font-weight: bold;
}

.farm-avg-yield-cell {
  background-color: #8acea5;
  font-weight: bold;
}

.variety-header {
  min-width: 100px;
}

.zero-state {
  text-align: center;
  padding: 60px;
}

.zero-state > div {
  font-size: 16px;
  font-weight: bold;
}
</style>
