<template>
  <div class="evidencing-tab-btns">
    <a @click="handleDashboardClick" :class="dashboardViewActive ? 'active-tab' : ''">Overview</a>
    <a @click="handleTableClick" :class="tableViewActive || mapViewActive ? 'active-tab' : ''">Request list</a>
  </div>
</template>

<script>
import {
  EVIDENCING_MAP,
  EVIDENCING_TABLE,
  EVIDENCING_VALIDATION,
  EVIDENCING_DASHBOARD,
} from "@/constants/evidencing";
import router from "@/router";

export default {
  name: "EvidencingPageTabs",
  props: {
    activeBtn: { required: true },
  },
  data() {
    return {
      EVIDENCING_MAP,
      EVIDENCING_TABLE,
      EVIDENCING_VALIDATION,
      EVIDENCING_DASHBOARD
    }
  },
  computed: {
    mapViewActive() {
      return this.activeBtn == EVIDENCING_MAP
    },
    tableViewActive() {
      return this.activeBtn == EVIDENCING_TABLE
    },
    validationViewActive() {
      return this.activeBtn == EVIDENCING_VALIDATION
    },
    dashboardViewActive() {
      return this.activeBtn == EVIDENCING_DASHBOARD
    },
  },
  methods: {
    handleDashboardClick() {
      if (this.activeBtn != EVIDENCING_DASHBOARD) {
        router.push(`/evidencing/dashboard/`)
      }
    },
    handleTableClick() {
      if ([EVIDENCING_DASHBOARD, EVIDENCING_VALIDATION].includes(this.activeBtn)) {
        router.push(`/evidencing/view?openView=${EVIDENCING_TABLE}`);
      }
    }
  }
}
</script>

<style scoped>
.evidencing-tab-btns {
  display: flex;
  justify-content: flex-start;
  position: relative;
  margin-top: 8px;
}
.evidencing-tab-btns::after {
  position: absolute;
  content: '';
  bottom: 0;
  left: 0;
  height: 1px;
  width: 100%;
  background: rgba(0, 0, 0, 0.25);
}
.evidencing-tab-btns > a {
  padding: 10px 16px;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  border-bottom: 2px solid transparent;
}
.evidencing-tab-btns > a.active-tab {
  font-weight: bold;
  border-bottom: 2px solid #000000;
}
</style>
