<template>
  <div>
    <apexchart height="230" :options="chartOptions" :series="dataSeries" />
  </div>
</template>

<script>
export default {
  name: "DataCompletionChart",
  props: ["data", "totalAcreage"],

  computed: {
    dataSeries() {
      let { data, totalAcreage } = this
      totalAcreage = Math.round(totalAcreage)
      if (!totalAcreage) return data.map(() => 0)
      return data.map(row => {
        const rowAcreage = Math.min(row.acreage, totalAcreage)
        return Math.round((rowAcreage / totalAcreage) * 100)
      })
    },

    chartOptions() {
      const labels = this.data.map(row => row.label)
      const showAcres = typeof this.totalAcreage !== "undefined"
      const totalAcreage = Math.round(this.totalAcreage)
      const totalLabel = showAcres ? `${totalAcreage.toLocaleString()} ac` : ""
      return {
        chart: { type: "radialBar" },
        colors: [
          "#17986A",
          "#046E94",
          "#2E85D0",
          "#0A3389",
          "#18FFFF",
          "#558B2F",
        ],
        plotOptions: {
          radialBar: {
            // NOTE - Rotates polar clock angle
            // startAngle: -180,
            // endAngle: 180,
            dataLabels: {
              name: { fontSize: "22px" },
              value: { fontSize: "16px" },
              total: {
                show: true,
                label: totalLabel,
                formatter: () => "",
              },
            },
            hollow: {
              margin: 0,
              size: "40%",
              background: "transparent",
            },
          },
        },
        stroke: { lineCap: "round" },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "diagonal1",
            gradientToColors: [
              "#17986A",
              "#046E94",
              "#2E85D0",
              "#0A3389",
              "#18FFFF",
              "#558B2F",
            ],
            stops: [0, 100],
          },
        },
        labels,
        legend: {
          show: true,
          floating: true,
          position: "left",
          offsetY: 5,
        },
      }
    },
  },
}
</script>
