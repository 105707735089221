<template>
  <div class="d-flex align-items-center">
    <img v-if="imgSrc" :src="imgSrc" width="25px" height="25px" />
    <i v-else-if="faClass" class="fa logo" :class="faClass" />
    <h2>{{ title }}</h2>
    <div v-if="beta" class="beta-tag">Beta</div>
    <i
      class="fa fa-info-circle"
      data-toggle="tooltip"
      data-placement="top"
      v-if="tooltip"
      :title="tooltip"
    />
  </div>
</template>

<script>
export default {
  name: "DashboardTitle",
  props: ["imgSrc", "faClass", "title", "tooltip", "beta"],

  mounted() {
    if (this.tooltip) {
      const { $ } = window
      $(function() {
        $('[data-toggle="tooltip"]').tooltip()
      })
    }
  },
}
</script>

<style scoped>
h2 {
  font-size: 20px;
  font-weight: bold;
  margin-left: 10px;
  margin-bottom: 0px;
}

.logo {
  font-size: 22px;
}

.fa-info-circle {
  margin-left: 5px;
  font-size: 16px;
}

.beta-tag {
  margin-left: 6px;
  font-weight: bold;
  font-size: 12px;
  color: white;
  background-color: #28a745;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
}
</style>
