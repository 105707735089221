<template>
  <div>
    <Permissions package="imagery" />
    <div v-if="showRxZones || showImagery" class="row reverse-margin">
      <div class="col-5 scroll-col">
        <v-card>
          <v-toolbar flat>
            <v-toolbar-title>{{ field.name }} </v-toolbar-title>
            <div
              v-if="isLoading"
              class="spinner-border spinner-border-sm text-success ml-1"
              role="status"
            ></div>
            <template v-slot:extension>
              <v-tabs grow v-model="tab">
                <v-tabs-slider color="blue"></v-tabs-slider>
                <v-tab v-for="item in tabTitles" :key="item.index">
                  {{ item.name }}
                </v-tab>
              </v-tabs>
            </template>
          </v-toolbar>
          <v-tabs-items v-model="tab">
            <v-tab-item v-for="item in tabTitles" :key="item.index">
              <v-card flat>
                <div v-if="item.name === 'Fields'" class="pt-3">
                  <FieldImageryListView
                    :canAccess="showImagery"
                    :tabView="true"
                    :zonesView="false"
                  />
                </div>
                <div v-if="item.name === 'Analysis'" class="pt-3">
                  <div v-if="geotiffData">
                    <div class="px-3">
                      <div class="font-weight-bold">
                        Average NDVI Over Time
                        <i
                          class="fa fa-info-circle info-tip"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="This line chart displays the field average NDVI at each date. Drag the right slider on the map view to select a new view date. Drag the left slider to any date after the initial one to show a pixel by pixel change in NDVI image between the two selected dates."
                        />
                      </div>
                      <apexchart
                        type="line"
                        height="200"
                        :options="timelineChartData.options"
                        :series="timelineChartData.series"
                      />
                    </div>
                    <v-card-text>
                      <div class="d-flex">
                        <div>
                          <div class="font-weight-bold pb-1">Map Settings</div>
                          <div>
                            Now Showing:
                            <span :class="mapShowingClass">{{
                              mapShowing
                            }}</span>
                          </div>
                        </div>
                        <button
                          v-if="!isReadOnly"
                          type="button"
                          class="btn btn-primary update-btn py-2 mt-2 ml-auto"
                          @click="handleExport"
                        >
                          Export Geotiff
                          <div
                            v-if="isExportLoading"
                            class="spinner-border spinner-border-sm text-light ml-1"
                            role="status"
                          ></div>
                        </button>
                      </div>
                      <v-row>
                        <v-col>
                          <div class="control-wrapper pt-1">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="ndviType"
                                id="ndviRelative"
                                value="relative"
                                v-model="ndviType"
                              />
                              <label
                                class="form-check-label"
                                for="ndviRelative"
                              >
                                Relative NDVI
                              </label>
                            </div>
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="ndviType"
                                id="ndviAbsolute"
                                value="absolute"
                                v-model="ndviType"
                              />
                              <label
                                class="form-check-label"
                                for="ndviAbsolute"
                              >
                                Absolute NDVI
                              </label>
                            </div>
                          </div>
                        </v-col>
                        <v-col>
                          <div>Color Scheme:</div>
                          <v-select-old
                            :clearable="false"
                            label="name"
                            :reduce="o => o.value"
                            v-model="geotiffColor"
                            :options="geotiffColorOptions"
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <div class="px-3">
                      <div class="font-weight-bold">
                        Distribution of NDVI Values On Field
                        <i
                          class="fa fa-info-circle info-tip"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="This histogram shows the pixel distribution of NDVI (or change in NDVI) in the image currently displayed on the map. Use the dropdown to change your color scheme. Click and drag along the curve of this distribution to change your displayed color range and reveal more insights in your imagery."
                        />
                      </div>
                      <apexchart
                        type="line"
                        height="200"
                        :options="ndviChartData.options"
                        :series="ndviChartData.series"
                      />
                    </div>
                  </div>

                  <div
                    v-else-if="isLoading"
                    class="d-flex justify-content-center py-3"
                  >
                    <div
                      class="spinner-border text-success"
                      role="status"
                    ></div>
                    <div class="loading-text text-success ml-3 mt-1">
                      Loading Geotiff...
                    </div>
                  </div>
                  <div
                    v-else-if="missingGeotiff"
                    class="d-flex justify-content-center py-3"
                  >
                    <div class="loading-text text-danger ml-3 mt-1">
                      An error occurred when processing this Geotiff.
                    </div>
                  </div>
                </div>
                <div v-if="item.name === 'Zones'" class="pt-3">
                  <ZonesController
                    :dateInfo="dateInfo"
                    :fieldId="fieldId"
                    :fieldZones="fieldZones"
                    :tab="tab"
                    @update-list="fetchZonePlans"
                    @zones-updated="updateZoneGeoJSON"
                  />
                </div>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </div>
      <div class="col-7 map-col">
        <div class="map-wrapper" oncontextmenu="return false">
          <FieldImageryMapIndividual
            :boundaries="fieldBounds"
            :dateInfo="dateInfo"
            :fields="fieldBounds"
            :fieldId="fieldId"
            :geotiffColorScale="geotiffColor"
            :isGeotiffLoading="isLoading"
            :selectedDateInfo="selectedDateInfo"
            :valueSelection="ndviValueSelection"
            :zoneGeoJSON="zoneGeoJSON"
            @update-loading="updateLoading"
            @on-geotiff-load="updateGeotiffData"
            @on-slider-change="updateDateRange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex"
import _ from "lodash"

import { percentile, TIFF_COLORSCALES } from "@/constants/map"
//import MapAPI from "@/api/MapAPI"
import PlanetOrderAPI from "@/api/PlanetOrderAPI"
import SatelliteAPI from "@/api/SatelliteAPI"
import FieldImageryListView from "@/views/FieldImageryListView"
import FieldImageryMapIndividual from "@/components/fieldImageryMap/FieldImageryMapIndividual"
import ZonesController from "@/components/fieldImageryMap/ZonesController"
import { Filter } from "@/store/modules"
import Permissions from "@/components/permissions/Permissions"

export default {
  name: "FieldView",
  props: ["fieldId"],
  components: {
    FieldImageryListView,
    FieldImageryMapIndividual,
    ZonesController,
    Permissions,
  },

  data() {
    return {
      dateInfo: [],
      exportDates: null,
      selectedDateInfo: [],
      fieldZones: [],
      geotiffColor: "rgb",
      geotiffColorOptions: TIFF_COLORSCALES,
      geotiffData: null,
      isLoading: false,
      isExportLoading: false,
      missingGeotiff: false,
      ndviType: "relative",
      tab: 1,
      tabTitles: [
        {
          index: 0,
          name: "Fields",
        },
        {
          index: 1,
          name: "Analysis",
        },
        {
          index: 2,
          name: "Zones",
        },
      ],
      ndviValueSelection: [1, 100],
      zoneGeoJSON: null,
    }
  },

  computed: {
    ...mapGetters({
      selectedFields: Filter.Getters.getSelectedFields,
    }),

    ...mapState({
      // hoverData: state => state.Map.hoverData,
      isReadOnly: state => state.User.user.permission === "Read-Only",
      deckLayers: state => state.Map.layers,
      isFetching: state => state.Map.isFetching,
      pitchMode: state => state.Map.pitchMode,
      fields: state => state.Fields.fields,
      mapFieldData: state => state.Map.fieldData,
      fieldBoundaries: state => state.Map.fieldBoundaries,
      showImagery: state => state.Organization.organization.showImagery,
      showRxZones: state => state.Organization.organization.showRxZones,
    }),

    field() {
      return this.fields[this.fieldId] ? this.fields[this.fieldId] : {}
    },

    fieldBounds() {
      const bounds = this.fieldBoundaries.filter(
        row => row.properties.field.id === parseInt(this.fieldId)
      )
      return bounds
    },

    farm() {
      return this.field && this.field.farm ? this.field.farm : {}
    },

    mapShowing() {
      return this.selectedDateInfo[0] === 0
        ? "Single Geotiff View"
        : "NDVI Comparison View"
    },

    mapShowingClass() {
      return {
        single: this.selectedDateInfo[0] === 0,
        comparison: this.selectedDateInfo[0] !== 0,
      }
    },

    ndviAxisLabel() {
      return this.selectedDateInfo[0] === 0 ? "NDVI" : "Change in NDVI"
    },

    ndviChartData() {
      if (!this.geotiffData)
        return {
          series: [],
          options: {},
        }
      const tupleFormat = Object.keys(this.geotiffData).map(key => [
        Number(key),
        this.geotiffData[key],
      ])
      const tupleSorted = _.sortBy(tupleFormat, x => x[0])

      const options = {
        chart: {
          height: 200,
          type: "line",
          events: {
            zoomed: (chartContext, { xaxis }) => this.zoomHandler(xaxis),
          },
          zoom: {
            enabled: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 4,
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"],
            opacity: 0.5,
          },
        },
        xaxis: {
          title: {
            text: this.ndviAxisLabel,
            offsetY: 6,
          },
          type: "numeric",
          min: this.ndviValueSelection[0],
          max: this.ndviValueSelection[1],
          tickAmount: 6,
        },
        yaxis: {
          title: {
            text: "# of Pixels",
          },
        },
      }

      const series = [
        {
          name: "NDVI",
          data: tupleSorted,
        },
      ]

      return {
        series: series,
        options: options,
      }
    },

    timelineChartData() {
      const options = {
        chart: {
          type: "line",
          height: 200,
        },
        colors: ["#269ffb", "#fdd023"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          dashArray: [0, 5],
          width: [4, 4],
        },
        tooltip: {
          enabledOnSeries: [0],
        },
        xaxis: {
          type: "datetime",
        },
        yaxis: {
          labels: {
            formatter: val => val.toFixed(2),
          },
          title: {
            text: "NDVI",
          },
          min: 0,
          max: 1,
        },
      }

      const data = _.orderBy(
        this.dateInfo.map(ndviDate => {
          return {
            x: new Date(ndviDate.date),
            y: ndviDate.value,
          }
        }),
        ["x"],
        ["asc"]
      )

      if (!_.isEmpty(this.selectedDateInfo)) {
        const series = [
          {
            name: "Average NDVI",
            type: "line",
            data: data,
          },
          {
            name: "Dates in View",
            type: "line",
            data: data.slice(
              this.selectedDateInfo[0],
              this.selectedDateInfo[1] + 1
            ),
          },
        ]
        return {
          series: series,
          options: options,
        }
      }

      const series = [
        {
          name: "Average NDVI",
          type: "column",
          data: data,
        },
      ]
      return {
        series: series,
        options: options,
      }
    },
  },

  methods: {
    downloadGeotiff(url) {
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", "file.tiff")
      link.click()
      link.remove()
    },

    fetchZonePlans() {
      PlanetOrderAPI.getZones({
        field_id: this.fieldId,
      }).then(response => {
        this.fieldZones = response.data
      })
    },

    handleExport() {
      this.isExportLoading = true

      const firstDate = this.exportDates[0]
      const lastDate = this.exportDates[1]

      if (this.selectedDateInfo[0] === 0) {
        const payload = {
          field_id: this.fieldId,
          dates: [lastDate],
        }
        SatelliteAPI.fetchGeotiffExport(payload).then(response => {
          this.isExportLoading = false
          this.downloadGeotiff(response.data.signed_url)
        })
      } else {
        const payload = {
          field_id: this.fieldId,
          dates: [firstDate, lastDate],
        }
        SatelliteAPI.fetchGeotiffExport(payload).then(response => {
          this.isExportLoading = false
          this.downloadGeotiff(response.data.signed_url)
        })
      }
    },

    updateDateRange(d) {
      const { sliderIndexes, sliderDates } = d
      this.selectedDateInfo = sliderIndexes
      this.exportDates = sliderDates
    },

    updateGeotiffData(d, error = false) {
      if (error === true || _.isEmpty(d)) {
        this.missingGeotiff = true
        this.geotiffData = null
        return
      }
      this.geotiffData = d

      if (this.selectedDateInfo[0] !== 0) {
        const comparisonRange = _.sortBy(Object.keys(this.geotiffData), obj =>
          parseInt(obj)
        )
        this.ndviValueSelection = [
          Math.round(percentile(comparisonRange, 0.02)),
          Math.round(percentile(comparisonRange, 0.98)),
        ]
        return
      }

      // TODO this will need to change if backend trims 0 values
      // slice(1) to remove values of 0
      const ndviRange = Object.keys(this.geotiffData).slice(1)
      if (this.ndviType === "relative") {
        this.ndviValueSelection = [
          Math.round(percentile(ndviRange, 0.02)),
          Math.round(percentile(ndviRange, 0.98)),
        ]
        return
      } else if (this.ndviType === "absolute") {
        this.ndviValueSelection = [20, 90]
        return
      }
    },

    updateLoading(bool) {
      this.isLoading = bool
    },

    updateZoneGeoJSON(d) {
      this.zoneGeoJSON = d
    },

    zoomHandler(
      xaxis = {
        min: undefined,
        max: undefined,
      }
    ) {
      if (xaxis.min === undefined && xaxis.max === undefined) {
        const ndviRange = Object.keys(this.geotiffData).slice(1)

        if (this.selectedDateInfo[0] !== 0) {
          const comparisonRange = _.sortBy(ndviRange, obj => parseInt(obj))
          this.ndviValueSelection = [
            Math.round(percentile(comparisonRange, 0.02)),
            Math.round(percentile(comparisonRange, 0.98)),
          ]
          return
        }

        if (this.ndviType === "absolute") {
          this.ndviValueSelection = [20, 90]
          return
        } else {
          this.ndviValueSelection = [
            Math.round(percentile(ndviRange, 0.02)),
            Math.round(percentile(ndviRange, 0.98)),
          ]
          return
        }
      }
      this.ndviValueSelection = [xaxis.min, xaxis.max]
    },
  },

  watch: {
    ndviType() {
      this.zoomHandler()
    },
    tab(newTab, oldTab) {
      if (oldTab === 2 || newTab !== 2) {
        this.zoneGeoJSON = null
      }
    },
  },

  async mounted() {
    const { $ } = window
    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
    })

    const fieldInfoPayload = {
      field_id: this.fieldId,
      datatype: "planet-3m-sr",
      attribute: "ndvi",
      metric: "median",
    }

    await SatelliteAPI.fetchImagingDates(fieldInfoPayload).then(response => {
      this.dateInfo = JSON.parse(response.data.result)
    })

    await PlanetOrderAPI.getZones({
      field_id: this.fieldId,
    }).then(response => {
      this.fieldZones = response.data
    })
  },
}
</script>

<style scoped>
h2 {
  font-weight: bold;
  border-bottom: 1pt solid #bbb;
  padding-bottom: 9px;
  margin-bottom: 20px;
}

.map-wrapper {
  height: calc(100vh - 65px);
  width: 100%;
  margin-top: -30px;
  margin-bottom: -17px;
  position: relative;
  background: #1b1b1d;
}

.fill-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.scroll-col {
  max-height: 85vh;
  overflow: scroll;
  padding-right: 40px;
}

.reverse-margin {
  margin-bottom: -60px;
}

.single {
  font-weight: bold;
  color: green;
}

.comparison {
  font-weight: bold;
  color: purple;
}

.loading-text {
  font-weight: bold;
  font-size: 16px;
}

.info-tip {
  color: #666666;
}
</style>
