export const GREET_DEFAULTS = {
    'corn': {
        'cropYield': 178.4,
        'diesel': 7.2,
        'gasoline': 1.3,
        'naturalGas': 87.0,
        'liqPetrGas': 2.2,
        'electricity': 69.3,
        'ammonia': 49.0,
        'urea': 36.3,
        'ammoniumNitrate': 3.2,
        'ammoniumSulfate': 3.2,
        'ureaAmmoniumNitrate': 50.5,
        'nitrogenMap': 6.3,
        'nitrogenDap': 9.5,
        'phosMap': 29.6,
        'phosDap': 29.6,
        'potash': 59.9,
        'lime': 573.0,
        'herbicide': 1044.2,
        'insecticide': 2.2,

        'coverCrop': 'Cover crop',
        'coverCropFarmEnergy': 62060,
        'coverCropHerbicideRate': 612,
        'coverCropYield': 1.214,

        'manure': 'Manure',
        'swineManure': 1.9,
        'cowManure': 3.3,
        'beefManure': 1.7,
        'chickenManure': 0.9,
        'manureApplicationEnergy': 221366,
        'manureTranspDistance': 0.367,
        'manureTranspEnergy': 10416,

        'tillage': 'Conventional tillage',

        'climate_zone': 'No consideration',
        'nitrogenManagement': 'Business as usual',
        'ammoniaSource': 'Conventional',
    },
    'soybeans': {
        'cropYield': 50.6,
        'diesel': 3.7,
        'gasoline': 0.9,
        'naturalGas': 9.1,
        'liqPetrGas': 0.4,
        'electricity': 21.8,
        'ammonia': 1.5,
        'urea': 1.1,
        'ammoniumNitrate': 0.1,
        'ammoniumSulfate': 0.1,
        'ureaAmmoniumNitrate': 1.6,
        'nitrogenMap': 0.2,
        'nitrogenDap': 0.3,
        'phosMap': 11.6,
        'phosDap': 11.6,
        'potash': 36.8,
        'lime': 0.0,
        'herbicide': 983.2,
        'insecticide': 14.2,

        'coverCrop': 'Cover crop',

        'manure': 'Manure',

        'tillage': 'Conventional tillage',

        'ammoniaSource': 'Conventional',
    },
    'rice': {
        'cropYield': 76.9,
        'diesel': 17.3,
        'gasoline': 2.2,
        'naturalGas': 119.7,
        'liqPetrGas': 0.5,
        'electricity': 279.0,
        'ammonia': 53.2,
        'urea': 39.5,
        'ammoniumNitrate': 3.4,
        'ammoniumSulfate': 3.4,
        'ureaAmmoniumNitrate': 54.9,
        'nitrogenMap': 6.9,
        'nitrogenDap': 10.3,
        'phosMap': 20.2,
        'phosDap': 20.2,
        'potash': 39.5,
        'lime': 0,
        'herbicide': 1372.2,
        'insecticide': 6.4,

        'ammoniaSource': 'Conventional',

        'waterRegimeDuringCult': 'Continuously flooded',
        'waterRegimePreseasonCult': 'Non flooded pre-season <180 d',
        'strawIncorporationTime': 'Straw incorporated shortly (<30 days) before cultivation',
    }
}

export const SUPPORTED_FIPS_2022 = {
    'corn': [
        1003,
        1009,
        1015,
        1031,
        1033,
        1035,
        1039,
        1043,
        1047,
        1053,
        1055,
        1061,
        1065,
        1067,
        1069,
        1077,
        1079,
        1083,
        1089,
        1093,
        1095,
        1099,
        1103,
        1105,
        1109,
        1119,
        1121,
        17001,
        17003,
        17005,
        17007,
        17009,
        17011,
        17013,
        17015,
        17017,
        17019,
        17021,
        17023,
        17025,
        17027,
        17029,
        17031,
        17033,
        17035,
        17037,
        17039,
        17041,
        17043,
        17045,
        17047,
        17049,
        17051,
        17053,
        17055,
        17057,
        17059,
        17061,
        17063,
        17065,
        17067,
        17069,
        17071,
        17073,
        17075,
        17077,
        17079,
        17081,
        17083,
        17085,
        17087,
        17089,
        17091,
        17093,
        17095,
        17097,
        17099,
        17101,
        17103,
        17105,
        17107,
        17109,
        17111,
        17113,
        17115,
        17117,
        17119,
        17121,
        17123,
        17125,
        17127,
        17129,
        17131,
        17133,
        17135,
        17137,
        17139,
        17141,
        17143,
        17145,
        17147,
        17149,
        17151,
        17153,
        17155,
        17157,
        17159,
        17161,
        17163,
        17165,
        17167,
        17169,
        17171,
        17173,
        17175,
        17177,
        17179,
        17181,
        17183,
        17185,
        17187,
        17189,
        17191,
        17193,
        17195,
        17197,
        17199,
        17201,
        17203,
        18001,
        18003,
        18005,
        18007,
        18009,
        18011,
        18015,
        18017,
        18019,
        18021,
        18023,
        18027,
        18029,
        18031,
        18033,
        18035,
        18037,
        18039,
        18041,
        18043,
        18045,
        18047,
        18049,
        18051,
        18053,
        18055,
        18057,
        18059,
        18061,
        18063,
        18065,
        18067,
        18069,
        18071,
        18073,
        18075,
        18077,
        18079,
        18081,
        18083,
        18085,
        18087,
        18089,
        18091,
        18093,
        18095,
        18097,
        18099,
        18101,
        18103,
        18105,
        18107,
        18109,
        18111,
        18113,
        18115,
        18117,
        18119,
        18121,
        18123,
        18125,
        18127,
        18129,
        18131,
        18133,
        18135,
        18137,
        18139,
        18141,
        18143,
        18145,
        18147,
        18149,
        18151,
        18153,
        18155,
        18157,
        18159,
        18161,
        18163,
        18165,
        18167,
        18169,
        18171,
        18173,
        18175,
        18177,
        18179,
        18181,
        18183,
        19001,
        19003,
        19005,
        19007,
        19009,
        19011,
        19013,
        19015,
        19017,
        19019,
        19021,
        19023,
        19025,
        19027,
        19029,
        19031,
        19033,
        19035,
        19037,
        19039,
        19041,
        19043,
        19045,
        19047,
        19049,
        19051,
        19053,
        19055,
        19057,
        19059,
        19061,
        19063,
        19065,
        19067,
        19069,
        19071,
        19073,
        19075,
        19077,
        19079,
        19081,
        19083,
        19085,
        19087,
        19089,
        19091,
        19093,
        19095,
        19097,
        19099,
        19101,
        19103,
        19105,
        19107,
        19109,
        19111,
        19113,
        19115,
        19117,
        19119,
        19121,
        19123,
        19125,
        19127,
        19129,
        19131,
        19133,
        19135,
        19137,
        19139,
        19141,
        19143,
        19145,
        19147,
        19149,
        19151,
        19153,
        19155,
        19157,
        19159,
        19161,
        19163,
        19165,
        19167,
        19169,
        19171,
        19173,
        19175,
        19177,
        19179,
        19181,
        19183,
        19185,
        19187,
        19189,
        19191,
        19193,
        19195,
        19197,
        20001,
        20003,
        20005,
        20007,
        20009,
        20011,
        20013,
        20015,
        20017,
        20019,
        20021,
        20023,
        20025,
        20027,
        20029,
        20031,
        20033,
        20035,
        20037,
        20039,
        20041,
        20043,
        20045,
        20047,
        20049,
        20051,
        20053,
        20055,
        20057,
        20059,
        20061,
        20063,
        20065,
        20067,
        20069,
        20071,
        20073,
        20077,
        20079,
        20081,
        20083,
        20085,
        20087,
        20089,
        20091,
        20093,
        20095,
        20097,
        20099,
        20101,
        20103,
        20105,
        20107,
        20109,
        20111,
        20113,
        20115,
        20117,
        20119,
        20121,
        20123,
        20125,
        20127,
        20129,
        20131,
        20133,
        20135,
        20137,
        20139,
        20141,
        20143,
        20145,
        20147,
        20149,
        20151,
        20153,
        20155,
        20157,
        20159,
        20161,
        20163,
        20165,
        20167,
        20169,
        20171,
        20173,
        20175,
        20177,
        20179,
        20181,
        20183,
        20185,
        20189,
        20191,
        20193,
        20195,
        20197,
        20199,
        20201,
        20203,
        20205,
        20207,
        20209,
        24003,
        24005,
        24011,
        24013,
        24015,
        24017,
        24019,
        24021,
        24025,
        24027,
        24029,
        24031,
        24033,
        24035,
        24037,
        24039,
        24041,
        24043,
        24045,
        24047,
        26005,
        26007,
        26011,
        26015,
        26017,
        26021,
        26023,
        26025,
        26027,
        26035,
        26037,
        26045,
        26049,
        26051,
        26057,
        26059,
        26063,
        26065,
        26067,
        26069,
        26073,
        26075,
        26077,
        26081,
        26087,
        26091,
        26093,
        26099,
        26105,
        26107,
        26111,
        26115,
        26117,
        26119,
        26121,
        26123,
        26125,
        26127,
        26129,
        26139,
        26141,
        26145,
        26147,
        26149,
        26151,
        26155,
        26157,
        26159,
        26161,
        26163,
        27011,
        27013,
        27015,
        27019,
        27023,
        27033,
        27037,
        27043,
        27047,
        27053,
        27055,
        27063,
        27067,
        27073,
        27079,
        27081,
        27083,
        27085,
        27091,
        27093,
        27101,
        27103,
        27105,
        27117,
        27127,
        27129,
        27131,
        27133,
        27139,
        27143,
        27147,
        27151,
        27157,
        27163,
        27165,
        27171,
        27173,
        29001,
        29003,
        29005,
        29007,
        29009,
        29011,
        29013,
        29015,
        29017,
        29019,
        29021,
        29023,
        29025,
        29027,
        29031,
        29033,
        29037,
        29039,
        29041,
        29045,
        29047,
        29049,
        29051,
        29053,
        29057,
        29061,
        29063,
        29069,
        29071,
        29073,
        29075,
        29077,
        29079,
        29081,
        29083,
        29085,
        29087,
        29089,
        29095,
        29097,
        29099,
        29101,
        29103,
        29105,
        29107,
        29109,
        29111,
        29113,
        29115,
        29117,
        29121,
        29125,
        29127,
        29129,
        29131,
        29133,
        29135,
        29137,
        29139,
        29141,
        29143,
        29145,
        29147,
        29151,
        29155,
        29157,
        29159,
        29163,
        29165,
        29167,
        29171,
        29173,
        29175,
        29177,
        29181,
        29183,
        29185,
        29186,
        29187,
        29189,
        29195,
        29197,
        29199,
        29201,
        29205,
        29207,
        29211,
        29217,
        29219,
        29223,
        29225,
        29227,
        31001,
        31003,
        31011,
        31015,
        31017,
        31019,
        31021,
        31023,
        31025,
        31027,
        31029,
        31031,
        31033,
        31035,
        31037,
        31039,
        31041,
        31043,
        31047,
        31051,
        31053,
        31055,
        31057,
        31059,
        31061,
        31063,
        31065,
        31067,
        31069,
        31071,
        31073,
        31077,
        31079,
        31081,
        31083,
        31085,
        31087,
        31089,
        31093,
        31095,
        31097,
        31099,
        31101,
        31103,
        31107,
        31109,
        31111,
        31113,
        31115,
        31119,
        31121,
        31125,
        31127,
        31129,
        31131,
        31133,
        31135,
        31137,
        31139,
        31141,
        31143,
        31145,
        31147,
        31149,
        31151,
        31153,
        31155,
        31159,
        31163,
        31167,
        31169,
        31173,
        31175,
        31177,
        31179,
        31181,
        31183,
        31185,
        34005,
        34011,
        34015,
        34019,
        34021,
        34023,
        34025,
        34035,
        34041,
        36003,
        36009,
        36011,
        36013,
        36017,
        36019,
        36021,
        36023,
        36029,
        36037,
        36043,
        36045,
        36051,
        36053,
        36055,
        36057,
        36063,
        36065,
        36067,
        36069,
        36073,
        36075,
        36077,
        36091,
        36097,
        36099,
        36101,
        36109,
        36115,
        36117,
        36121,
        36123,
        37001,
        37003,
        37007,
        37013,
        37017,
        37025,
        37027,
        37031,
        37033,
        37035,
        37053,
        37057,
        37059,
        37069,
        37073,
        37077,
        37079,
        37081,
        37083,
        37095,
        37097,
        37101,
        37103,
        37105,
        37109,
        37117,
        37119,
        37125,
        37127,
        37129,
        37131,
        37133,
        37135,
        37137,
        37139,
        37141,
        37143,
        37145,
        37151,
        37153,
        37157,
        37159,
        37161,
        37163,
        37167,
        37169,
        37171,
        37179,
        37183,
        37185,
        37187,
        37191,
        37193,
        37195,
        38015,
        38029,
        38055,
        38059,
        39001,
        39003,
        39005,
        39007,
        39009,
        39011,
        39015,
        39017,
        39019,
        39021,
        39023,
        39025,
        39027,
        39029,
        39031,
        39033,
        39037,
        39039,
        39041,
        39043,
        39045,
        39047,
        39049,
        39051,
        39053,
        39055,
        39057,
        39059,
        39061,
        39063,
        39065,
        39067,
        39069,
        39071,
        39073,
        39075,
        39077,
        39079,
        39083,
        39087,
        39089,
        39091,
        39093,
        39095,
        39097,
        39099,
        39101,
        39103,
        39105,
        39107,
        39109,
        39113,
        39115,
        39117,
        39119,
        39123,
        39125,
        39127,
        39129,
        39131,
        39133,
        39135,
        39137,
        39139,
        39141,
        39143,
        39145,
        39147,
        39149,
        39151,
        39153,
        39155,
        39157,
        39159,
        39161,
        39165,
        39167,
        39169,
        39171,
        39173,
        39175,
        40003,
        40007,
        40011,
        40015,
        40017,
        40023,
        40033,
        40035,
        40039,
        40047,
        40049,
        40053,
        40061,
        40071,
        40073,
        40079,
        40081,
        40083,
        40087,
        40091,
        40093,
        40097,
        40101,
        40103,
        40105,
        40107,
        40109,
        40111,
        40115,
        40119,
        40125,
        40131,
        40135,
        40139,
        40141,
        40145,
        40147,
        40149,
        42001,
        42005,
        42007,
        42009,
        42011,
        42013,
        42015,
        42017,
        42019,
        42021,
        42025,
        42027,
        42029,
        42031,
        42035,
        42037,
        42039,
        42041,
        42043,
        42049,
        42051,
        42055,
        42057,
        42061,
        42063,
        42065,
        42067,
        42071,
        42073,
        42075,
        42077,
        42079,
        42081,
        42085,
        42087,
        42089,
        42091,
        42093,
        42095,
        42097,
        42099,
        42107,
        42109,
        42111,
        42117,
        42119,
        42121,
        42125,
        42129,
        42133,
        45007,
        45009,
        45011,
        45031,
        45033,
        45041,
        45051,
        45055,
        45057,
        45061,
        45067,
        45071,
        45073,
        45079,
        45081,
        45083,
        45085,
        45089,
        46003,
        46005,
        46009,
        46011,
        46013,
        46015,
        46017,
        46021,
        46023,
        46025,
        46027,
        46029,
        46031,
        46035,
        46037,
        46039,
        46043,
        46045,
        46049,
        46051,
        46053,
        46057,
        46059,
        46061,
        46065,
        46067,
        46069,
        46073,
        46077,
        46079,
        46083,
        46085,
        46087,
        46089,
        46091,
        46097,
        46099,
        46101,
        46103,
        46107,
        46109,
        46111,
        46115,
        46119,
        46121,
        46123,
        46125,
        46127,
        46129,
        46135,
        48037,
        48039,
        48051,
        48057,
        48065,
        48069,
        48085,
        48089,
        48097,
        48113,
        48117,
        48119,
        48139,
        48145,
        48147,
        48153,
        48157,
        48179,
        48181,
        48189,
        48195,
        48201,
        48231,
        48233,
        48239,
        48257,
        48277,
        48279,
        48303,
        48309,
        48321,
        48341,
        48357,
        48369,
        48387,
        48395,
        48421,
        48437,
        48469,
        48481,
        51001,
        51003,
        51007,
        51011,
        51015,
        51025,
        51031,
        51033,
        51037,
        51041,
        51043,
        51047,
        51053,
        51057,
        51061,
        51067,
        51069,
        51075,
        51083,
        51085,
        51107,
        51109,
        51117,
        51127,
        51135,
        51137,
        51139,
        51143,
        51149,
        51153,
        51159,
        51165,
        51171,
        51177,
        51179,
        51550,
        51810,
        55001,
        55009,
        55011,
        55015,
        55021,
        55023,
        55025,
        55027,
        55033,
        55039,
        55043,
        55045,
        55047,
        55049,
        55055,
        55057,
        55059,
        55061,
        55063,
        55065,
        55071,
        55077,
        55079,
        55081,
        55087,
        55089,
        55091,
        55093,
        55101,
        55103,
        55105,
        55111,
        55117,
        55121,
        55123,
        55127,
        55131,
        55133,
        55135,
        55137,
        55139,
        55141
    ],
    'soybeans': [
        1003,
        1009,
        1015,
        1019,
        1031,
        1033,
        1035,
        1039,
        1043,
        1047,
        1053,
        1055,
        1061,
        1065,
        1067,
        1069,
        1077,
        1079,
        1083,
        1089,
        1093,
        1095,
        1099,
        1103,
        1105,
        1109,
        1119,
        1121,
        17001,
        17003,
        17005,
        17007,
        17009,
        17011,
        17013,
        17015,
        17017,
        17019,
        17021,
        17023,
        17025,
        17027,
        17029,
        17031,
        17033,
        17035,
        17037,
        17039,
        17041,
        17043,
        17045,
        17047,
        17049,
        17051,
        17053,
        17055,
        17057,
        17059,
        17061,
        17063,
        17065,
        17067,
        17069,
        17071,
        17073,
        17075,
        17077,
        17079,
        17081,
        17083,
        17085,
        17087,
        17089,
        17091,
        17093,
        17095,
        17097,
        17099,
        17101,
        17103,
        17105,
        17107,
        17109,
        17111,
        17113,
        17115,
        17117,
        17119,
        17121,
        17123,
        17125,
        17127,
        17129,
        17131,
        17133,
        17135,
        17137,
        17139,
        17141,
        17143,
        17145,
        17147,
        17149,
        17151,
        17153,
        17155,
        17157,
        17159,
        17161,
        17163,
        17165,
        17167,
        17169,
        17171,
        17173,
        17175,
        17177,
        17179,
        17181,
        17183,
        17185,
        17187,
        17189,
        17191,
        17193,
        17195,
        17197,
        17199,
        17201,
        17203,
        18001,
        18003,
        18005,
        18007,
        18009,
        18011,
        18015,
        18017,
        18019,
        18021,
        18023,
        18027,
        18029,
        18031,
        18033,
        18035,
        18037,
        18039,
        18041,
        18043,
        18045,
        18047,
        18049,
        18051,
        18053,
        18055,
        18057,
        18059,
        18061,
        18063,
        18065,
        18067,
        18069,
        18071,
        18073,
        18075,
        18077,
        18079,
        18081,
        18083,
        18085,
        18087,
        18089,
        18091,
        18093,
        18095,
        18097,
        18099,
        18101,
        18103,
        18105,
        18107,
        18109,
        18111,
        18113,
        18115,
        18117,
        18119,
        18121,
        18123,
        18125,
        18127,
        18129,
        18131,
        18133,
        18135,
        18137,
        18139,
        18141,
        18143,
        18145,
        18147,
        18149,
        18151,
        18153,
        18155,
        18157,
        18159,
        18161,
        18163,
        18165,
        18167,
        18169,
        18171,
        18173,
        18175,
        18177,
        18179,
        18181,
        18183,
        19001,
        19003,
        19005,
        19007,
        19009,
        19011,
        19013,
        19015,
        19017,
        19019,
        19021,
        19023,
        19025,
        19027,
        19029,
        19031,
        19033,
        19035,
        19037,
        19039,
        19041,
        19043,
        19045,
        19047,
        19049,
        19051,
        19053,
        19055,
        19057,
        19059,
        19061,
        19063,
        19065,
        19067,
        19069,
        19071,
        19073,
        19075,
        19077,
        19079,
        19081,
        19083,
        19085,
        19087,
        19091,
        19093,
        19095,
        19097,
        19099,
        19101,
        19103,
        19105,
        19107,
        19109,
        19111,
        19113,
        19115,
        19117,
        19119,
        19121,
        19123,
        19125,
        19127,
        19129,
        19131,
        19133,
        19135,
        19137,
        19139,
        19141,
        19143,
        19145,
        19147,
        19149,
        19151,
        19153,
        19155,
        19157,
        19159,
        19161,
        19163,
        19165,
        19167,
        19169,
        19171,
        19173,
        19175,
        19177,
        19179,
        19181,
        19183,
        19185,
        19187,
        19189,
        19191,
        19193,
        19195,
        19197,
        20001,
        20003,
        20005,
        20007,
        20009,
        20011,
        20013,
        20015,
        20017,
        20019,
        20021,
        20023,
        20025,
        20027,
        20029,
        20031,
        20033,
        20035,
        20037,
        20039,
        20041,
        20043,
        20045,
        20047,
        20049,
        20051,
        20053,
        20055,
        20057,
        20059,
        20061,
        20063,
        20065,
        20067,
        20069,
        20071,
        20073,
        20077,
        20079,
        20081,
        20083,
        20085,
        20087,
        20089,
        20091,
        20093,
        20095,
        20097,
        20099,
        20101,
        20103,
        20105,
        20107,
        20109,
        20111,
        20113,
        20115,
        20117,
        20119,
        20121,
        20123,
        20125,
        20127,
        20129,
        20131,
        20133,
        20135,
        20137,
        20139,
        20141,
        20143,
        20145,
        20147,
        20149,
        20151,
        20153,
        20155,
        20157,
        20159,
        20161,
        20163,
        20165,
        20167,
        20169,
        20171,
        20173,
        20175,
        20177,
        20179,
        20181,
        20183,
        20185,
        20189,
        20191,
        20193,
        20195,
        20197,
        20199,
        20201,
        20203,
        20205,
        20207,
        24003,
        24005,
        24011,
        24013,
        24015,
        24017,
        24019,
        24021,
        24025,
        24027,
        24029,
        24031,
        24033,
        24035,
        24037,
        24039,
        24041,
        24043,
        24045,
        24047,
        26005,
        26007,
        26011,
        26015,
        26017,
        26021,
        26023,
        26025,
        26027,
        26037,
        26045,
        26049,
        26051,
        26057,
        26059,
        26063,
        26065,
        26067,
        26069,
        26073,
        26075,
        26077,
        26081,
        26087,
        26091,
        26093,
        26099,
        26105,
        26107,
        26111,
        26115,
        26117,
        26119,
        26121,
        26123,
        26125,
        26127,
        26129,
        26139,
        26141,
        26145,
        26147,
        26149,
        26151,
        26155,
        26157,
        26159,
        26161,
        26163,
        27013,
        27015,
        27019,
        27023,
        27033,
        27037,
        27043,
        27047,
        27053,
        27055,
        27063,
        27073,
        27079,
        27083,
        27085,
        27091,
        27093,
        27101,
        27103,
        27105,
        27117,
        27127,
        27129,
        27133,
        27139,
        27143,
        27151,
        27157,
        27163,
        27165,
        27173,
        29001,
        29003,
        29005,
        29007,
        29009,
        29011,
        29013,
        29015,
        29017,
        29019,
        29021,
        29023,
        29025,
        29027,
        29031,
        29033,
        29037,
        29039,
        29041,
        29045,
        29047,
        29049,
        29051,
        29053,
        29057,
        29061,
        29063,
        29069,
        29071,
        29073,
        29075,
        29077,
        29079,
        29081,
        29083,
        29085,
        29087,
        29089,
        29095,
        29097,
        29099,
        29101,
        29103,
        29105,
        29107,
        29109,
        29111,
        29113,
        29115,
        29117,
        29121,
        29125,
        29127,
        29129,
        29131,
        29133,
        29135,
        29137,
        29139,
        29141,
        29143,
        29145,
        29147,
        29151,
        29155,
        29157,
        29159,
        29163,
        29165,
        29167,
        29171,
        29173,
        29175,
        29177,
        29181,
        29183,
        29185,
        29186,
        29187,
        29189,
        29195,
        29197,
        29199,
        29201,
        29205,
        29207,
        29211,
        29217,
        29219,
        29223,
        29225,
        29227,
        31001,
        31003,
        31011,
        31015,
        31017,
        31019,
        31021,
        31023,
        31025,
        31027,
        31029,
        31031,
        31033,
        31035,
        31037,
        31039,
        31041,
        31043,
        31047,
        31051,
        31053,
        31055,
        31057,
        31059,
        31061,
        31063,
        31065,
        31067,
        31069,
        31071,
        31073,
        31077,
        31079,
        31081,
        31083,
        31085,
        31087,
        31089,
        31093,
        31095,
        31097,
        31099,
        31101,
        31103,
        31107,
        31109,
        31111,
        31113,
        31115,
        31119,
        31121,
        31125,
        31127,
        31129,
        31131,
        31133,
        31135,
        31137,
        31139,
        31141,
        31143,
        31145,
        31147,
        31149,
        31151,
        31153,
        31155,
        31159,
        31163,
        31167,
        31169,
        31173,
        31175,
        31177,
        31179,
        31181,
        31183,
        31185,
        34005,
        34011,
        34015,
        34019,
        34021,
        34023,
        34025,
        34035,
        34041,
        36003,
        36009,
        36011,
        36013,
        36017,
        36021,
        36023,
        36029,
        36037,
        36043,
        36051,
        36053,
        36055,
        36057,
        36063,
        36065,
        36067,
        36069,
        36073,
        36075,
        36077,
        36091,
        36097,
        36099,
        36101,
        36109,
        36115,
        36117,
        36121,
        36123,
        37001,
        37003,
        37007,
        37013,
        37015,
        37017,
        37019,
        37025,
        37027,
        37031,
        37033,
        37035,
        37037,
        37045,
        37049,
        37053,
        37057,
        37059,
        37065,
        37067,
        37069,
        37077,
        37081,
        37083,
        37093,
        37095,
        37097,
        37101,
        37103,
        37105,
        37109,
        37119,
        37125,
        37127,
        37129,
        37133,
        37135,
        37137,
        37139,
        37141,
        37143,
        37145,
        37151,
        37153,
        37155,
        37157,
        37159,
        37161,
        37165,
        37167,
        37169,
        37171,
        37179,
        37183,
        37185,
        37187,
        37191,
        37193,
        37195,
        37197,
        38015,
        38029,
        38055,
        38059,
        39001,
        39003,
        39005,
        39007,
        39009,
        39011,
        39015,
        39017,
        39019,
        39021,
        39023,
        39025,
        39027,
        39029,
        39031,
        39033,
        39037,
        39039,
        39041,
        39043,
        39045,
        39047,
        39049,
        39051,
        39053,
        39055,
        39057,
        39059,
        39061,
        39063,
        39065,
        39067,
        39069,
        39071,
        39073,
        39075,
        39077,
        39079,
        39083,
        39087,
        39089,
        39091,
        39093,
        39095,
        39097,
        39099,
        39101,
        39103,
        39105,
        39107,
        39109,
        39113,
        39115,
        39117,
        39119,
        39123,
        39125,
        39127,
        39129,
        39131,
        39133,
        39135,
        39137,
        39139,
        39141,
        39143,
        39145,
        39147,
        39149,
        39151,
        39153,
        39155,
        39157,
        39159,
        39161,
        39165,
        39167,
        39169,
        39171,
        39173,
        39175,
        40003,
        40007,
        40011,
        40013,
        40015,
        40017,
        40023,
        40027,
        40033,
        40035,
        40039,
        40047,
        40049,
        40053,
        40061,
        40071,
        40073,
        40079,
        40081,
        40083,
        40087,
        40089,
        40091,
        40093,
        40097,
        40101,
        40103,
        40105,
        40107,
        40109,
        40111,
        40115,
        40119,
        40125,
        40131,
        40135,
        40139,
        40141,
        40145,
        40147,
        40149,
        42001,
        42005,
        42007,
        42009,
        42011,
        42013,
        42015,
        42017,
        42019,
        42021,
        42025,
        42027,
        42029,
        42031,
        42035,
        42037,
        42039,
        42041,
        42043,
        42049,
        42051,
        42055,
        42057,
        42061,
        42063,
        42065,
        42067,
        42071,
        42073,
        42075,
        42077,
        42079,
        42081,
        42085,
        42087,
        42089,
        42091,
        42093,
        42095,
        42097,
        42099,
        42107,
        42109,
        42111,
        42117,
        42119,
        42121,
        42125,
        42129,
        42133,
        45003,
        45005,
        45007,
        45009,
        45011,
        45025,
        45027,
        45031,
        45033,
        45041,
        45043,
        45051,
        45055,
        45057,
        45061,
        45067,
        45069,
        45071,
        45073,
        45079,
        45081,
        45083,
        45085,
        45089,
        45091,
        46003,
        46005,
        46009,
        46011,
        46013,
        46015,
        46017,
        46021,
        46023,
        46025,
        46027,
        46029,
        46031,
        46035,
        46037,
        46039,
        46043,
        46045,
        46049,
        46051,
        46053,
        46057,
        46059,
        46061,
        46065,
        46067,
        46069,
        46073,
        46077,
        46079,
        46083,
        46085,
        46087,
        46089,
        46097,
        46099,
        46101,
        46103,
        46107,
        46109,
        46111,
        46115,
        46119,
        46121,
        46123,
        46125,
        46127,
        46129,
        46135,
        48037,
        48039,
        48051,
        48057,
        48065,
        48069,
        48085,
        48089,
        48097,
        48113,
        48117,
        48119,
        48139,
        48145,
        48147,
        48153,
        48157,
        48179,
        48181,
        48189,
        48195,
        48201,
        48231,
        48233,
        48239,
        48257,
        48277,
        48279,
        48303,
        48309,
        48321,
        48341,
        48357,
        48369,
        48387,
        48395,
        48421,
        48437,
        48469,
        48481,
        51001,
        51003,
        51007,
        51011,
        51015,
        51025,
        51031,
        51033,
        51036,
        51037,
        51041,
        51043,
        51047,
        51053,
        51057,
        51061,
        51067,
        51069,
        51075,
        51083,
        51085,
        51093,
        51097,
        51099,
        51101,
        51107,
        51109,
        51113,
        51117,
        51127,
        51133,
        51135,
        51137,
        51139,
        51143,
        51149,
        51153,
        51159,
        51165,
        51171,
        51177,
        51179,
        51550,
        51810,
        55001,
        55009,
        55011,
        55015,
        55021,
        55023,
        55025,
        55027,
        55039,
        55043,
        55045,
        55047,
        55049,
        55055,
        55057,
        55059,
        55061,
        55063,
        55065,
        55071,
        55077,
        55079,
        55081,
        55087,
        55089,
        55091,
        55093,
        55101,
        55103,
        55105,
        55111,
        55117,
        55121,
        55123,
        55127,
        55131,
        55133,
        55135,
        55137,
        55139
    ],
    'rice': []
}

/*
export const GREET_DEFAULTS = {
    'corn': {
        'crop_yield': 178.4,
        'diesel': 7.2,
        'gasoline': 1.3,
        'natural_gas': 87.0,
        'liq_petr_gas': 2.2,
        'electricity': 69.3,
        'ammonia': 49.0,
        'urea': 36.3,
        'ammonium_nitrate': 3.2,
        'ammonium_sulfate': 3.2,
        'urea_ammonium_nitrate': 50.5,
        'nitrogen_map': 6.3,
        'nitrogen_dap': 9.5,
        'phosphorus_map': 29.6,
        'phosphorus_dap': 29.6,
        'potash': 59.9,
        'lime': 573.0,
        'herbicide': 1044.2,
        'insecticide': 2.2,

        'cover_crop_rotation': 'Cover crop',
        'cover_crop_energy': 62060,
        'cover_crop_herbicide_ar': 612,
        'cover_crop_yield': 1.214,

        'manure': 'Manure',
        'manure_swine': 1.9,
        'manure_dairycow': 3.3,
        'manure_beefcattle': 1.7,
        'manure_chicken': 0.9,
        'manure_energy': 221366,
        'manure_transport_distance': 0.367,
        'manure_transport_energy': 10416,

        'tillage': 'Conventional tillage',

        'climate_zone': 'No consideration',
        'nitrogen_management': 'Business as usual',
        'ammonia_source': 'Conventional',
    },
    'soybeans': {
        'crop_yield': 50.6,
        'diesel': 3.7,
        'gasoline': 0.9,
        'natural_gas': 9.1,
        'liq_petr_gas': 0.4,
        'electricity': 21.8,
        'ammonia': 1.5,
        'urea': 1.1,
        'ammonium_nitrate': 0.1,
        'ammonium_sulfate': 0.1,
        'urea_ammonium_nitrate': 1.6,
        'nitrogen_map': 0.2,
        'nitrogen_dap': 0.3,
        'phosphorus_map': 11.6,
        'phosphorus_dap': 11.6,
        'potash': 36.8,
        'lime': 0.0,
        'herbicide': 983.2,
        'insecticide': 14.2,

        'cover_crop_rotation': 'Cover crop',

        'manure': 'Manure',

        'tillage': 'Conventional tillage',

        'ammonia_source': 'Conventional',
    },
    'rice': {
        'crop_yield': 76.9,
        'diesel': 17.3,
        'gasoline': 2.2,
        'natural_gas': 119.7,
        'liq_petr_gas': 0.5,
        'electricity': 279.0,
        'ammonia': 53.2,
        'urea': 39.5,
        'ammonium_nitrate': 3.4,
        'ammonium_sulfate': 3.4,
        'urea_ammonium_nitrate': 54.9,
        'nitrogen_map': 6.9,
        'nitrogen_dap': 10.3,
        'phosphorus_map': 20.2,
        'phosphorus_dap': 20.2,
        'potash': 39.5,
        'lime': 0,
        'herbicide': 1372.2,
        'insecticide': 6.4,

        'ammonia_source': 'Conventional',

        'water_regime_during_cult': 'Continuously flooded',
        'water_regime_preseason_cult': 'Non flooded pre-season <180 d',
        'straw_incorporation_time': 'Straw incorporated shortly (<30 days) before cultivation',
    }
}
*/

export const GREET_US_STATES_ABBREV = [
    "AK", "AL", "AR", "AZ",
    "CA", "CO", "CT",
    "DC", "DE",
    "FL",
    "GA",
    "HI",
    "IA", "ID", "IL", "IN",
    "KS", "KY",
    "LA",
    "MA", "MD", "ME", "MI", "MN", "MO", "MS", "MT",
    "NC", "ND", "NE", "NH", "NJ", "NM", "NV", "NY",
    "OH", "OK", "OR",
    "PA",
    "RI",
    "SC", "SD",
    "TN", "TX",
    "UT",
    "VA", "VT",
    "WA", "WI", "WV", "WY",
];

export const GREET_US_STATES_COUNTIES = {
    "AK": [
        ["Aleutians East", 2013],
        ["Aleutians West", 2016],
        ["Anchorage", 2020],
        ["Bethel", 2050],
        ["Bristol Bay", 2060],
        ["Denali", 2068],
        ["Dillingham", 2070],
        ["Fairbanks North Star", 2090],
        ["Haines", 2100],
        ["Juneau", 2110],
        ["Kenai Peninsula", 2122],
        ["Ketchikan Gateway", 2130],
        ["Kodiak Island", 2150],
        ["Lake and Peninsula", 2164],
        ["Matanuska-Susitna", 2170],
        ["Nome", 2180],
        ["North Slope", 2185],
        ["Northwest Arctic", 2188],
        ["Prince of Wales-Outer Ketchikan", 2201],
        ["Sitka", 2220],
        ["Skagway-Hoonah-Angoon", 2232],
        ["Southeast Fairbanks", 2240],
        ["Valdez-Cordova", 2261],
        ["Wade Hampton", 2270],
        ["Wrangell-Petersburg", 2280],
        ["Yakutat", 2282],
        ["Yukon-Koyukuk", 2290],
    ],
    "AL": [
        ["Autauga", 1001],
        ["Baldwin", 1003],
        ["Barbour", 1005],
        ["Bibb", 1007],
        ["Blount", 1009],
        ["Bullock", 1011],
        ["Butler", 1013],
        ["Calhoun", 1015],
        ["Chambers", 1017],
        ["Cherokee", 1019],
        ["Chilton", 1021],
        ["Choctaw", 1023],
        ["Clarke", 1025],
        ["Clay", 1027],
        ["Cleburne", 1029],
        ["Coffee", 1031],
        ["Colbert", 1033],
        ["Conecuh", 1035],
        ["Coosa", 1037],
        ["Covington", 1039],
        ["Crenshaw", 1041],
        ["Cullman", 1043],
        ["Dale", 1045],
        ["Dallas", 1047],
        ["De Kalb", 1049],
        ["Elmore", 1051],
        ["Escambia", 1053],
        ["Etowah", 1055],
        ["Fayette", 1057],
        ["Franklin", 1059],
        ["Geneva", 1061],
        ["Greene", 1063],
        ["Hale", 1065],
        ["Henry", 1067],
        ["Houston", 1069],
        ["Jackson", 1071],
        ["Jefferson", 1073],
        ["Lamar", 1075],
        ["Lauderdale", 1077],
        ["Lawrence", 1079],
        ["Lee", 1081],
        ["Limestone", 1083],
        ["Lowndes", 1085],
        ["Macon", 1087],
        ["Madison", 1089],
        ["Marengo", 1091],
        ["Marion", 1093],
        ["Marshall", 1095],
        ["Mobile", 1097],
        ["Monroe", 1099],
        ["Montgomery", 1101],
        ["Morgan", 1103],
        ["Perry", 1105],
        ["Pickens", 1107],
        ["Pike", 1109],
        ["Randolph", 1111],
        ["Russell", 1113],
        ["St Clair", 1115],
        ["Shelby", 1117],
        ["Sumter", 1119],
        ["Talladega", 1121],
        ["Tallapoosa", 1123],
        ["Tuscaloosa", 1125],
        ["Walker", 1127],
        ["Washington", 1129],
        ["Wilcox", 1131],
        ["Winston", 1133]
    ],
    "AR": [
        ["Arkansas", 5001],
        ["Ashley", 5003],
        ["Baxter", 5005],
        ["Benton", 5007],
        ["Boone", 5009],
        ["Bradley", 5011],
        ["Calhoun", 5013],
        ["Carroll", 5015],
        ["Chicot", 5017],
        ["Clark", 5019],
        ["Clay", 5021],
        ["Cleburne", 5023],
        ["Cleveland", 5025],
        ["Columbia", 5027],
        ["Conway", 5029],
        ["Craighead", 5031],
        ["Crawford", 5033],
        ["Crittenden", 5035],
        ["Cross", 5037],
        ["Dallas", 5039],
        ["Desha", 5041],
        ["Drew", 5043],
        ["Faulkner", 5045],
        ["Franklin", 5047],
        ["Fulton", 5049],
        ["Garland", 5051],
        ["Grant", 5053],
        ["Greene", 5055],
        ["Hempstead", 5057],
        ["Hot Spring", 5059],
        ["Howard", 5061],
        ["Independence", 5063],
        ["Izard", 5065],
        ["Jackson", 5067],
        ["Jefferson", 5069],
        ["Johnson", 5071],
        ["Lafayette", 5073],
        ["Lawrence", 5075],
        ["Lee", 5077],
        ["Lincoln", 5079],
        ["Little River", 5081],
        ["Logan", 5083],
        ["Lonoke", 5085],
        ["Madison", 5087],
        ["Marion", 5089],
        ["Miller", 5091],
        ["Mississippi", 5093],
        ["Monroe", 5095],
        ["Montgomery", 5097],
        ["Nevada", 5099],
        ["Newton", 5101],
        ["Ouachita", 5103],
        ["Perry", 5105],
        ["Phillips", 5107],
        ["Pike", 5109],
        ["Poinsett", 5111],
        ["Polk", 5113],
        ["Pope", 5115],
        ["Prairie", 5117],
        ["Pulaski", 5119],
        ["Randolph", 5121],
        ["St Francis", 5123],
        ["Saline", 5125],
        ["Scott", 5127],
        ["Searcy", 5129],
        ["Sebastian", 5131],
        ["Sevier", 5133],
        ["Sharp", 5135],
        ["Stone", 5137],
        ["Union", 5139],
        ["Van Buren", 5141],
        ["Washington", 5143],
        ["White", 5145],
        ["Woodruff", 5147],
        ["Yell", 5149],
    ],
    "AZ": [
        ["Apache", 4001],
        ["Cochise", 4003],
        ["Coconino", 4005],
        ["Gila", 4007],
        ["Graham", 4009],
        ["Greenlee", 4011],
        ["La Paz", 4012],
        ["Maricopa", 4013],
        ["Mohave", 4015],
        ["Navajo", 4017],
        ["Pima", 4019],
        ["Pinal", 4021],
        ["Santa Cruz", 4023],
        ["Yavapai", 4025],
        ["Yuma", 4027],
    ],
    "CA": [
        ["Alameda", 6001],
        ["Alpine", 6003],
        ["Amador", 6005],
        ["Butte", 6007],
        ["Calaveras", 6009],
        ["Colusa", 6011],
        ["Contra Costa", 6013],
        ["Del Norte", 6015],
        ["El Dorado", 6017],
        ["Fresno", 6019],
        ["Glenn", 6021],
        ["Humboldt", 6023],
        ["Imperial", 6025],
        ["Inyo", 6027],
        ["Kern", 6029],
        ["Kings", 6031],
        ["Lake", 6033],
        ["Lassen", 6035],
        ["Los Angeles", 6037],
        ["Madera", 6039],
        ["Marin", 6041],
        ["Mariposa", 6043],
        ["Mendocino", 6045],
        ["Merced", 6047],
        ["Modoc", 6049],
        ["Mono", 6051],
        ["Monterey", 6053],
        ["Napa", 6055],
        ["Nevada", 6057],
        ["Orange", 6059],
        ["Placer", 6061],
        ["Plumas", 6063],
        ["Riverside", 6065],
        ["Sacramento", 6067],
        ["San Benito", 6069],
        ["San Bernardino", 6071],
        ["San Diego", 6073],
        ["San Francisco", 6075],
        ["San Joaquin", 6077],
        ["San Luis Obispo", 6079],
        ["San Mateo", 6081],
        ["Santa Barbara", 6083],
        ["Santa Clara", 6085],
        ["Santa Cruz", 6087],
        ["Shasta", 6089],
        ["Sierra", 6091],
        ["Siskiyou", 6093],
        ["Solano", 6095],
        ["Sonoma", 6097],
        ["Stanislaus", 6099],
        ["Sutter", 6101],
        ["Tehama", 6103],
        ["Trinity", 6105],
        ["Tulare", 6107],
        ["Tuolumne", 6109],
        ["Ventura", 6111],
        ["Yolo", 6113],
        ["Yuba", 6115],
    ],
    "CO": [
        ["Adams", 8001],
        ["Alamosa", 8003],
        ["Arapahoe", 8005],
        ["Archuleta", 8007],
        ["Baca", 8009],
        ["Bent", 8011],
        ["Boulder", 8013],
        ["Chaffee", 8015],
        ["Cheyenne", 8017],
        ["Clear Creek", 8019],
        ["Conejos", 8021],
        ["Costilla", 8023],
        ["Crowley", 8025],
        ["Custer", 8027],
        ["Delta", 8029],
        ["Denver", 8031],
        ["Dolores", 8033],
        ["Douglas", 8035],
        ["Eagle", 8037],
        ["Elbert", 8039],
        ["El Paso", 8041],
        ["Fremont", 8043],
        ["Garfield", 8045],
        ["Gilpin", 8047],
        ["Grand", 8049],
        ["Gunnison", 8051],
        ["Hinsdale", 8053],
        ["Huerfano", 8055],
        ["Jackson", 8057],
        ["Jefferson", 8059],
        ["Kiowa", 8061],
        ["Kit Carson", 8063],
        ["Lake", 8065],
        ["La Plata", 8067],
        ["Larimer", 8069],
        ["Las Animas", 8071],
        ["Lincoln", 8073],
        ["Logan", 8075],
        ["Mesa", 8077],
        ["Mineral", 8079],
        ["Moffat", 8081],
        ["Montezuma", 8083],
        ["Montrose", 8085],
        ["Morgan", 8087],
        ["Otero", 8089],
        ["Ouray", 8091],
        ["Park", 8093],
        ["Phillips", 8095],
        ["Pitkin", 8097],
        ["Prowers", 8099],
        ["Pueblo", 8101],
        ["Rio Blanco", 8103],
        ["Rio Grande", 8105],
        ["Routt", 8107],
        ["Saguache", 8109],
        ["San Juan", 8111],
        ["San Miguel", 8113],
        ["Sedgwick", 8115],
        ["Summit", 8117],
        ["Teller", 8119],
        ["Washington", 8121],
        ["Weld", 8123],
        ["Yuma", 8125],
    ],
    "CT": [
        ["Fairfield", 9001],
        ["Hartford", 9003],
        ["Litchfield", 9005],
        ["Middlesex", 9007],
        ["New Haven", 9009],
        ["New London", 9011],
        ["Tolland", 9013],
        ["Windham", 9015],
    ],
    "DC": [
        ["Washington", 11001]
    ],
    "DE": [
        ["Kent", 10001],
        ["New Castle", 10003],
        ["Sussex", 10005],
    ],
    "FL": [
        ["Alachua", 12001],
        ["Baker", 12003],
        ["Bay", 12005],
        ["Bradford", 12007],
        ["Brevard", 12009],
        ["Broward", 12011],
        ["Calhoun", 12013],
        ["Charlotte", 12015],
        ["Citrus", 12017],
        ["Clay", 12019],
        ["Collier", 12021],
        ["Columbia", 12023],
        ["Dade", 12025],
        ["De Soto", 12027],
        ["Dixie", 12029],
        ["Duval", 12031],
        ["Escambia", 12033],
        ["Flagler", 12035],
        ["Franklin", 12037],
        ["Gadsden", 12039],
        ["Gilchrist", 12041],
        ["Glades", 12043],
        ["Gulf", 12045],
        ["Hamilton", 12047],
        ["Hardee", 12049],
        ["Hendry", 12051],
        ["Hernando", 12053],
        ["Highlands", 12055],
        ["Hillsborough", 12057],
        ["Holmes", 12059],
        ["Indian River", 12061],
        ["Jackson", 12063],
        ["Jefferson", 12065],
        ["Lafayette", 12067],
        ["Lake", 12069],
        ["Lee", 12071],
        ["Leon", 12073],
        ["Levy", 12075],
        ["Liberty", 12077],
        ["Madison", 12079],
        ["Manatee", 12081],
        ["Marion", 12083],
        ["Martin", 12085],
        ["Monroe", 12087],
        ["Nassau", 12089],
        ["Okaloosa", 12091],
        ["Okeechobee", 12093],
        ["Orange", 12095],
        ["Osceola", 12097],
        ["Palm Beach", 12099],
        ["Pasco", 12101],
        ["Pinellas", 12103],
        ["Polk", 12105],
        ["Putnam", 12107],
        ["St Johns", 12109],
        ["St Lucie", 12111],
        ["Santa Rosa", 12113],
        ["Sarasota", 12115],
        ["Seminole", 12117],
        ["Sumter", 12119],
        ["Suwannee", 12121],
        ["Taylor", 12123],
        ["Union", 12125],
        ["Volusia", 12127],
        ["Wakulla", 12129],
        ["Walton", 12131],
        ["Washington", 12133],
    ],
    "GA": [
        ["Appling", 13001],
        ["Atkinson", 13003],
        ["Bacon", 13005],
        ["Baker", 13007],
        ["Baldwin", 13009],
        ["Banks", 13011],
        ["Barrow", 13013],
        ["Bartow", 13015],
        ["Ben Hill", 13017],
        ["Berrien", 13019],
        ["Bibb", 13021],
        ["Bleckley", 13023],
        ["Brantley", 13025],
        ["Brooks", 13027],
        ["Bryan", 13029],
        ["Bulloch", 13031],
        ["Burke", 13033],
        ["Butts", 13035],
        ["Calhoun", 13037],
        ["Camden", 13039],
        ["Candler", 13043],
        ["Carroll", 13045],
        ["Catoosa", 13047],
        ["Charlton", 13049],
        ["Chatham", 13051],
        ["Chattahoochee", 13053],
        ["Chattooga", 13055],
        ["Cherokee", 13057],
        ["Clarke", 13059],
        ["Clay", 13061],
        ["Clayton", 13063],
        ["Clinch", 13065],
        ["Cobb", 13067],
        ["Coffee", 13069],
        ["Colquitt", 13071],
        ["Columbia", 13073],
        ["Cook", 13075],
        ["Coweta", 13077],
        ["Crawford", 13079],
        ["Crisp", 13081],
        ["Dade", 13083],
        ["Dawson", 13085],
        ["Decatur", 13087],
        ["De Kalb", 13089],
        ["Dodge", 13091],
        ["Dooly", 13093],
        ["Dougherty", 13095],
        ["Douglas", 13097],
        ["Early", 13099],
        ["Echols", 13101],
        ["Effingham", 13103],
        ["Elbert", 13105],
        ["Emanuel", 13107],
        ["Evans", 13109],
        ["Fannin", 13111],
        ["Fayette", 13113],
        ["Floyd", 13115],
        ["Forsyth", 13117],
        ["Franklin", 13119],
        ["Fulton", 13121],
        ["Gilmer", 13123],
        ["Glascock", 13125],
        ["Glynn", 13127],
        ["Gordon", 13129],
        ["Grady", 13131],
        ["Greene", 13133],
        ["Gwinnett", 13135],
        ["Habersham", 13137],
        ["Hall", 13139],
        ["Hancock", 13141],
        ["Haralson", 13143],
        ["Harris", 13145],
        ["Hart", 13147],
        ["Heard", 13149],
        ["Henry", 13151],
        ["Houston", 13153],
        ["Irwin", 13155],
        ["Jackson", 13157],
        ["Jasper", 13159],
        ["Jeff Davis", 13161],
        ["Jefferson", 13163],
        ["Jenkins", 13165],
        ["Johnson", 13167],
        ["Jones", 13169],
        ["Lamar", 13171],
        ["Lanier", 13173],
        ["Laurens", 13175],
        ["Lee", 13177],
        ["Liberty", 13179],
        ["Lincoln", 13181],
        ["Long", 13183],
        ["Lowndes", 13185],
        ["Lumpkin", 13187],
        ["McDuffie", 13189],
        ["McIntosh", 13191],
        ["Macon", 13193],
        ["Madison", 13195],
        ["Marion", 13197],
        ["Meriwether", 13199],
        ["Miller", 13201],
        ["Mitchell", 13205],
        ["Monroe", 13207],
        ["Montgomery", 13209],
        ["Morgan", 13211],
        ["Murray", 13213],
        ["Muscogee", 13215],
        ["Newton", 13217],
        ["Oconee", 13219],
        ["Oglethorpe", 13221],
        ["Paulding", 13223],
        ["Peach", 13225],
        ["Pickens", 13227],
        ["Pierce", 13229],
        ["Pike", 13231],
        ["Polk", 13233],
        ["Pulaski", 13235],
        ["Putnam", 13237],
        ["Quitman", 13239],
        ["Rabun", 13241],
        ["Randolph", 13243],
        ["Richmond", 13245],
        ["Rockdale", 13247],
        ["Schley", 13249],
        ["Screven", 13251],
        ["Seminole", 13253],
        ["Spalding", 13255],
        ["Stephens", 13257],
        ["Stewart", 13259],
        ["Sumter", 13261],
        ["Talbot", 13263],
        ["Taliaferro", 13265],
        ["Tattnall", 13267],
        ["Taylor", 13269],
        ["Telfair", 13271],
        ["Terrell", 13273],
        ["Thomas", 13275],
        ["Tift", 13277],
        ["Toombs", 13279],
        ["Towns", 13281],
        ["Treutlen", 13283],
        ["Troup", 13285],
        ["Turner", 13287],
        ["Twiggs", 13289],
        ["Union", 13291],
        ["Upson", 13293],
        ["Walker", 13295],
        ["Walton", 13297],
        ["Ware", 13299],
        ["Warren", 13301],
        ["Washington", 13303],
        ["Wayne", 13305],
        ["Webster", 13307],
        ["Wheeler", 13309],
        ["White", 13311],
        ["Whitfield", 13313],
        ["Wilcox", 13315],
        ["Wilkes", 13317],
        ["Wilkinson", 13319],
        ["Worth", 13321],
    ],
    "HI": [
        ["Hawaii", 15001],
        ["Honolulu", 15003],
        ["Kauai", 15007],
        ["Maui", 15009],
    ],
    "IA": [
        ["Adair", 19001],
        ["Adams", 19003],
        ["Allamakee", 19005],
        ["Appanoose", 19007],
        ["Audubon", 19009],
        ["Benton", 19011],
        ["Black Hawk", 19013],
        ["Boone", 19015],
        ["Bremer", 19017],
        ["Buchanan", 19019],
        ["Buena Vista", 19021],
        ["Butler", 19023],
        ["Calhoun", 19025],
        ["Carroll", 19027],
        ["Cass", 19029],
        ["Cedar", 19031],
        ["Cerro Gordo", 19033],
        ["Cherokee", 19035],
        ["Chickasaw", 19037],
        ["Clarke", 19039],
        ["Clay", 19041],
        ["Clayton", 19043],
        ["Clinton", 19045],
        ["Crawford", 19047],
        ["Dallas", 19049],
        ["Davis", 19051],
        ["Decatur", 19053],
        ["Delaware", 19055],
        ["Des Moines", 19057],
        ["Dickinson", 19059],
        ["Dubuque", 19061],
        ["Emmet", 19063],
        ["Fayette", 19065],
        ["Floyd", 19067],
        ["Franklin", 19069],
        ["Fremont", 19071],
        ["Greene", 19073],
        ["Grundy", 19075],
        ["Guthrie", 19077],
        ["Hamilton", 19079],
        ["Hancock", 19081],
        ["Hardin", 19083],
        ["Harrison", 19085],
        ["Henry", 19087],
        ["Howard", 19089],
        ["Humboldt", 19091],
        ["Ida", 19093],
        ["Iowa", 19095],
        ["Jackson", 19097],
        ["Jasper", 19099],
        ["Jefferson", 19101],
        ["Johnson", 19103],
        ["Jones", 19105],
        ["Keokuk", 19107],
        ["Kossuth", 19109],
        ["Lee", 19111],
        ["Linn", 19113],
        ["Louisa", 19115],
        ["Lucas", 19117],
        ["Lyon", 19119],
        ["Madison", 19121],
        ["Mahaska", 19123],
        ["Marion", 19125],
        ["Marshall", 19127],
        ["Mills", 19129],
        ["Mitchell", 19131],
        ["Monona", 19133],
        ["Monroe", 19135],
        ["Montgomery", 19137],
        ["Muscatine", 19139],
        ["O Brien", 19141],
        ["Osceola", 19143],
        ["Page", 19145],
        ["Palo Alto", 19147],
        ["Plymouth", 19149],
        ["Pocahontas", 19151],
        ["Polk", 19153],
        ["Pottawattamie", 19155],
        ["Poweshiek", 19157],
        ["Ringgold", 19159],
        ["Sac", 19161],
        ["Scott", 19163],
        ["Shelby", 19165],
        ["Sioux", 19167],
        ["Story", 19169],
        ["Tama", 19171],
        ["Taylor", 19173],
        ["Union", 19175],
        ["Van Buren", 19177],
        ["Wapello", 19179],
        ["Warren", 19181],
        ["Washington", 19183],
        ["Wayne", 19185],
        ["Webster", 19187],
        ["Winnebago", 19189],
        ["Winneshiek", 19191],
        ["Woodbury", 19193],
        ["Worth", 19195],
        ["Wright", 19197],
    ],
    "ID": [
        ["Ada", 16001],
        ["Adams", 16003],
        ["Bannock", 16005],
        ["Bear Lake", 16007],
        ["Benewah", 16009],
        ["Bingham", 16011],
        ["Blaine", 16013],
        ["Boise", 16015],
        ["Bonner", 16017],
        ["Bonneville", 16019],
        ["Boundary", 16021],
        ["Butte", 16023],
        ["Camas", 16025],
        ["Canyon", 16027],
        ["Caribou", 16029],
        ["Cassia", 16031],
        ["Clark", 16033],
        ["Clearwater", 16035],
        ["Custer", 16037],
        ["Elmore", 16039],
        ["Franklin", 16041],
        ["Fremont", 16043],
        ["Gem", 16045],
        ["Gooding", 16047],
        ["Idaho", 16049],
        ["Jefferson", 16051],
        ["Jerome", 16053],
        ["Kootenai", 16055],
        ["Latah", 16057],
        ["Lemhi", 16059],
        ["Lewis", 16061],
        ["Lincoln", 16063],
        ["Madison", 16065],
        ["Minidoka", 16067],
        ["Nez Perce", 16069],
        ["Oneida", 16071],
        ["Owyhee", 16073],
        ["Payette", 16075],
        ["Power", 16077],
        ["Shoshone", 16079],
        ["Teton", 16081],
        ["Twin Falls", 16083],
        ["Valley", 16085],
        ["Washington", 16087],
    ],
    "IL": [
        ["Adams", 17001],
        ["Alexander", 17003],
        ["Bond", 17005],
        ["Boone", 17007],
        ["Brown", 17009],
        ["Bureau", 17011],
        ["Calhoun", 17013],
        ["Carroll", 17015],
        ["Cass", 17017],
        ["Champaign", 17019],
        ["Christian", 17021],
        ["Clark", 17023],
        ["Clay", 17025],
        ["Clinton", 17027],
        ["Coles", 17029],
        ["Cook", 17031],
        ["Crawford", 17033],
        ["Cumberland", 17035],
        ["De Kalb", 17037],
        ["De Witt", 17039],
        ["Douglas", 17041],
        ["Du Page", 17043],
        ["Edgar", 17045],
        ["Edwards", 17047],
        ["Effingham", 17049],
        ["Fayette", 17051],
        ["Ford", 17053],
        ["Franklin", 17055],
        ["Fulton", 17057],
        ["Gallatin", 17059],
        ["Greene", 17061],
        ["Grundy", 17063],
        ["Hamilton", 17065],
        ["Hancock", 17067],
        ["Hardin", 17069],
        ["Henderson", 17071],
        ["Henry", 17073],
        ["Iroquois", 17075],
        ["Jackson", 17077],
        ["Jasper", 17079],
        ["Jefferson", 17081],
        ["Jersey", 17083],
        ["Jo Daviess", 17085],
        ["Johnson", 17087],
        ["Kane", 17089],
        ["Kankakee", 17091],
        ["Kendall", 17093],
        ["Knox", 17095],
        ["Lake", 17097],
        ["La Salle", 17099],
        ["Lawrence", 17101],
        ["Lee", 17103],
        ["Livingston", 17105],
        ["Logan", 17107],
        ["McDonough", 17109],
        ["McHenry", 17111],
        ["McLean", 17113],
        ["Macon", 17115],
        ["Macoupin", 17117],
        ["Madison", 17119],
        ["Marion", 17121],
        ["Marshall", 17123],
        ["Mason", 17125],
        ["Massac", 17127],
        ["Menard", 17129],
        ["Mercer", 17131],
        ["Monroe", 17133],
        ["Montgomery", 17135],
        ["Morgan", 17137],
        ["Moultrie", 17139],
        ["Ogle", 17141],
        ["Peoria", 17143],
        ["Perry", 17145],
        ["Piatt", 17147],
        ["Pike", 17149],
        ["Pope", 17151],
        ["Pulaski", 17153],
        ["Putnam", 17155],
        ["Randolph", 17157],
        ["Richland", 17159],
        ["Rock Island", 17161],
        ["St Clair", 17163],
        ["Saline", 17165],
        ["Sangamon", 17167],
        ["Schuyler", 17169],
        ["Scott", 17171],
        ["Shelby", 17173],
        ["Stark", 17175],
        ["Stephenson", 17177],
        ["Tazewell", 17179],
        ["Union", 17181],
        ["Vermilion", 17183],
        ["Wabash", 17185],
        ["Warren", 17187],
        ["Washington", 17189],
        ["Wayne", 17191],
        ["White", 17193],
        ["Whiteside", 17195],
        ["Will", 17197],
        ["Williamson", 17199],
        ["Winnebago", 17201],
        ["Woodford", 17203],
    ],
    "IN": [
        ["Adams", 18001],
        ["Allen", 18003],
        ["Bartholomew", 18005],
        ["Benton", 18007],
        ["Blackford", 18009],
        ["Boone", 18011],
        ["Brown", 18013],
        ["Carroll", 18015],
        ["Cass", 18017],
        ["Clark", 18019],
        ["Clay", 18021],
        ["Clinton", 18023],
        ["Crawford", 18025],
        ["Daviess", 18027],
        ["Dearborn", 18029],
        ["Decatur", 18031],
        ["De Kalb", 18033],
        ["Delaware", 18035],
        ["Dubois", 18037],
        ["Elkhart", 18039],
        ["Fayette", 18041],
        ["Floyd", 18043],
        ["Fountain", 18045],
        ["Franklin", 18047],
        ["Fulton", 18049],
        ["Gibson", 18051],
        ["Grant", 18053],
        ["Greene", 18055],
        ["Hamilton", 18057],
        ["Hancock", 18059],
        ["Harrison", 18061],
        ["Hendricks", 18063],
        ["Henry", 18065],
        ["Howard", 18067],
        ["Huntington", 18069],
        ["Jackson", 18071],
        ["Jasper", 18073],
        ["Jay", 18075],
        ["Jefferson", 18077],
        ["Jennings", 18079],
        ["Johnson", 18081],
        ["Knox", 18083],
        ["Kosciusko", 18085],
        ["La Grange", 18087],
        ["Lake", 18089],
        ["La Porte", 18091],
        ["Lawrence", 18093],
        ["Madison", 18095],
        ["Marion", 18097],
        ["Marshall", 18099],
        ["Martin", 18101],
        ["Miami", 18103],
        ["Monroe", 18105],
        ["Montgomery", 18107],
        ["Morgan", 18109],
        ["Newton", 18111],
        ["Noble", 18113],
        ["Ohio", 18115],
        ["Orange", 18117],
        ["Owen", 18119],
        ["Parke", 18121],
        ["Perry", 18123],
        ["Pike", 18125],
        ["Porter", 18127],
        ["Posey", 18129],
        ["Pulaski", 18131],
        ["Putnam", 18133],
        ["Randolph", 18135],
        ["Ripley", 18137],
        ["Rush", 18139],
        ["St Joseph", 18141],
        ["Scott", 18143],
        ["Shelby", 18145],
        ["Spencer", 18147],
        ["Starke", 18149],
        ["Steuben", 18151],
        ["Sullivan", 18153],
        ["Switzerland", 18155],
        ["Tippecanoe", 18157],
        ["Tipton", 18159],
        ["Union", 18161],
        ["Vanderburgh", 18163],
        ["Vermillion", 18165],
        ["Vigo", 18167],
        ["Wabash", 18169],
        ["Warren", 18171],
        ["Warrick", 18173],
        ["Washington", 18175],
        ["Wayne", 18177],
        ["Wells", 18179],
        ["White", 18181],
        ["Whitley", 18183],
    ],
    "KS": [
        ["Allen", 20001],
        ["Anderson", 20003],
        ["Atchison", 20005],
        ["Barber", 20007],
        ["Barton", 20009],
        ["Bourbon", 20011],
        ["Brown", 20013],
        ["Butler", 20015],
        ["Chase", 20017],
        ["Chautauqua", 20019],
        ["Cherokee", 20021],
        ["Cheyenne", 20023],
        ["Clark", 20025],
        ["Clay", 20027],
        ["Cloud", 20029],
        ["Coffey", 20031],
        ["Comanche", 20033],
        ["Cowley", 20035],
        ["Crawford", 20037],
        ["Decatur", 20039],
        ["Dickinson", 20041],
        ["Doniphan", 20043],
        ["Douglas", 20045],
        ["Edwards", 20047],
        ["Elk", 20049],
        ["Ellis", 20051],
        ["Ellsworth", 20053],
        ["Finney", 20055],
        ["Ford", 20057],
        ["Franklin", 20059],
        ["Geary", 20061],
        ["Gove", 20063],
        ["Graham", 20065],
        ["Grant", 20067],
        ["Gray", 20069],
        ["Greeley", 20071],
        ["Greenwood", 20073],
        ["Hamilton", 20075],
        ["Harper", 20077],
        ["Harvey", 20079],
        ["Haskell", 20081],
        ["Hodgeman", 20083],
        ["Jackson", 20085],
        ["Jefferson", 20087],
        ["Jewell", 20089],
        ["Johnson", 20091],
        ["Kearny", 20093],
        ["Kingman", 20095],
        ["Kiowa", 20097],
        ["Labette", 20099],
        ["Lane", 20101],
        ["Leavenworth", 20103],
        ["Lincoln", 20105],
        ["Linn", 20107],
        ["Logan", 20109],
        ["Lyon", 20111],
        ["McPherson", 20113],
        ["Marion", 20115],
        ["Marshall", 20117],
        ["Meade", 20119],
        ["Miami", 20121],
        ["Mitchell", 20123],
        ["Montgomery", 20125],
        ["Morris", 20127],
        ["Morton", 20129],
        ["Nemaha", 20131],
        ["Neosho", 20133],
        ["Ness", 20135],
        ["Norton", 20137],
        ["Osage", 20139],
        ["Osborne", 20141],
        ["Ottawa", 20143],
        ["Pawnee", 20145],
        ["Phillips", 20147],
        ["Pottawatomie", 20149],
        ["Pratt", 20151],
        ["Rawlins", 20153],
        ["Reno", 20155],
        ["Republic", 20157],
        ["Rice", 20159],
        ["Riley", 20161],
        ["Rooks", 20163],
        ["Rush", 20165],
        ["Russell", 20167],
        ["Saline", 20169],
        ["Scott", 20171],
        ["Sedgwick", 20173],
        ["Seward", 20175],
        ["Shawnee", 20177],
        ["Sheridan", 20179],
        ["Sherman", 20181],
        ["Smith", 20183],
        ["Stafford", 20185],
        ["Stanton", 20187],
        ["Stevens", 20189],
        ["Sumner", 20191],
        ["Thomas", 20193],
        ["Trego", 20195],
        ["Wabaunsee", 20197],
        ["Wallace", 20199],
        ["Washington", 20201],
        ["Wichita", 20203],
        ["Wilson", 20205],
        ["Woodson", 20207],
        ["Wyandotte", 20209],
    ],
    "KY": [
        ["Adair", 21001],
        ["Allen", 21003],
        ["Anderson", 21005],
        ["Ballard", 21007],
        ["Barren", 21009],
        ["Bath", 21011],
        ["Bell", 21013],
        ["Boone", 21015],
        ["Bourbon", 21017],
        ["Boyd", 21019],
        ["Boyle", 21021],
        ["Bracken", 21023],
        ["Breathitt", 21025],
        ["Breckinridge", 21027],
        ["Bullitt", 21029],
        ["Butler", 21031],
        ["Caldwell", 21033],
        ["Calloway", 21035],
        ["Campbell", 21037],
        ["Carlisle", 21039],
        ["Carroll", 21041],
        ["Carter", 21043],
        ["Casey", 21045],
        ["Christian", 21047],
        ["Clark", 21049],
        ["Clay", 21051],
        ["Clinton", 21053],
        ["Crittenden", 21055],
        ["Cumberland", 21057],
        ["Daviess", 21059],
        ["Edmonson", 21061],
        ["Elliott", 21063],
        ["Estill", 21065],
        ["Fayette", 21067],
        ["Fleming", 21069],
        ["Floyd", 21071],
        ["Franklin", 21073],
        ["Fulton", 21075],
        ["Gallatin", 21077],
        ["Garrard", 21079],
        ["Grant", 21081],
        ["Graves", 21083],
        ["Grayson", 21085],
        ["Green", 21087],
        ["Greenup", 21089],
        ["Hancock", 21091],
        ["Hardin", 21093],
        ["Harlan", 21095],
        ["Harrison", 21097],
        ["Hart", 21099],
        ["Henderson", 21101],
        ["Henry", 21103],
        ["Hickman", 21105],
        ["Hopkins", 21107],
        ["Jackson", 21109],
        ["Jefferson", 21111],
        ["Jessamine", 21113],
        ["Johnson", 21115],
        ["Kenton", 21117],
        ["Knott", 21119],
        ["Knox", 21121],
        ["Larue", 21123],
        ["Laurel", 21125],
        ["Lawrence", 21127],
        ["Lee", 21129],
        ["Leslie", 21131],
        ["Letcher", 21133],
        ["Lewis", 21135],
        ["Lincoln", 21137],
        ["Livingston", 21139],
        ["Logan", 21141],
        ["Lyon", 21143],
        ["McCracken", 21145],
        ["McCreary", 21147],
        ["McLean", 21149],
        ["Madison", 21151],
        ["Magoffin", 21153],
        ["Marion", 21155],
        ["Marshall", 21157],
        ["Martin", 21159],
        ["Mason", 21161],
        ["Meade", 21163],
        ["Menifee", 21165],
        ["Mercer", 21167],
        ["Metcalfe", 21169],
        ["Monroe", 21171],
        ["Montgomery", 21173],
        ["Morgan", 21175],
        ["Muhlenberg", 21177],
        ["Nelson", 21179],
        ["Nicholas", 21181],
        ["Ohio", 21183],
        ["Oldham", 21185],
        ["Owen", 21187],
        ["Owsley", 21189],
        ["Pendleton", 21191],
        ["Perry", 21193],
        ["Pike", 21195],
        ["Powell", 21197],
        ["Pulaski", 21199],
        ["Robertson", 21201],
        ["Rockcastle", 21203],
        ["Rowan", 21205],
        ["Russell", 21207],
        ["Scott", 21209],
        ["Shelby", 21211],
        ["Simpson", 21213],
        ["Spencer", 21215],
        ["Taylor", 21217],
        ["Todd", 21219],
        ["Trigg", 21221],
        ["Trimble", 21223],
        ["Union", 21225],
        ["Warren", 21227],
        ["Washington", 21229],
        ["Wayne", 21231],
        ["Webster", 21233],
        ["Whitley", 21235],
        ["Wolfe", 21237],
        ["Woodford", 21239],
    ],
    "LA": [
        ["Acadia", 22001],
        ["Allen", 22003],
        ["Ascension", 22005],
        ["Assumption", 22007],
        ["Avoyelles", 22009],
        ["Beauregard", 22011],
        ["Bienville", 22013],
        ["Bossier", 22015],
        ["Caddo", 22017],
        ["Calcasieu", 22019],
        ["Caldwell", 22021],
        ["Cameron", 22023],
        ["Catahoula", 22025],
        ["Claiborne", 22027],
        ["Concordia", 22029],
        ["De Soto", 22031],
        ["East Baton Rouge", 22033],
        ["East Carroll", 22035],
        ["East Feliciana", 22037],
        ["Evangeline", 22039],
        ["Franklin", 22041],
        ["Grant", 22043],
        ["Iberia", 22045],
        ["Iberville", 22047],
        ["Jackson", 22049],
        ["Jefferson", 22051],
        ["Jefferson Davis", 22053],
        ["Lafayette", 22055],
        ["Lafourche", 22057],
        ["La Salle", 22059],
        ["Lincoln", 22061],
        ["Livingston", 22063],
        ["Madison", 22065],
        ["Morehouse", 22067],
        ["Natchitoches", 22069],
        ["Orleans", 22071],
        ["Ouachita", 22073],
        ["Plaquemines", 22075],
        ["Pointe Coupee", 22077],
        ["Rapides", 22079],
        ["Red River", 22081],
        ["Richland", 22083],
        ["Sabine", 22085],
        ["St Bernard", 22087],
        ["St Charles", 22089],
        ["St Helena", 22091],
        ["St James", 22093],
        ["St John the Baptist", 22095],
        ["St Landry", 22097],
        ["St Martin", 22099],
        ["St Mary", 22101],
        ["St Tammany", 22103],
        ["Tangipahoa", 22105],
        ["Tensas", 22107],
        ["Terrebonne", 22109],
        ["Union", 22111],
        ["Vermilion", 22113],
        ["Vernon", 22115],
        ["Washington", 22117],
        ["Webster", 22119],
        ["West Baton Rouge", 22121],
        ["West Carroll", 22123],
        ["West Feliciana", 22125],
        ["Winn", 22127],
    ],
    "MA": [
        ["Barnstable", 25001],
        ["Berkshire", 25003],
        ["Bristol", 25005],
        ["Dukes", 25007],
        ["Essex", 25009],
        ["Franklin", 25011],
        ["Hampden", 25013],
        ["Hampshire", 25015],
        ["Middlesex", 25017],
        ["Nantucket", 25019],
        ["Norfolk", 25021],
        ["Plymouth", 25023],
        ["Suffolk", 25025],
        ["Worcester", 25027],
    ],
    "MD": [
        ["Allegany", 24001],
        ["Anne Arundel", 24003],
        ["Baltimore", 24005],
        ["Calvert", 24009],
        ["Caroline", 24011],
        ["Carroll", 24013],
        ["Cecil", 24015],
        ["Charles", 24017],
        ["Dorchester", 24019],
        ["Frederick", 24021],
        ["Garrett", 24023],
        ["Harford", 24025],
        ["Howard", 24027],
        ["Kent", 24029],
        ["Montgomery", 24031],
        ["Prince Georges", 24033],
        ["Queen Annes", 24035],
        ["St Marys", 24037],
        ["Somerset", 24039],
        ["Talbot", 24041],
        ["Washington", 24043],
        ["Wicomico", 24045],
        ["Worcester", 24047],
        ["Baltimore City", 24510],
    ],
    "ME": [
        ["Androscoggin", 23001],
        ["Aroostook", 23003],
        ["Cumberland", 23005],
        ["Franklin", 23007],
        ["Hancock", 23009],
        ["Kennebec", 23011],
        ["Knox", 23013],
        ["Lincoln", 23015],
        ["Oxford", 23017],
        ["Penobscot", 23019],
        ["Piscataquis", 23021],
        ["Sagadahoc", 23023],
        ["Somerset", 23025],
        ["Waldo", 23027],
        ["Washington", 23029],
        ["York", 23031],
    ],
    "MI": [
        ["Alcona", 26001],
        ["Alger", 26003],
        ["Allegan", 26005],
        ["Alpena", 26007],
        ["Antrim", 26009],
        ["Arenac", 26011],
        ["Baraga", 26013],
        ["Barry", 26015],
        ["Bay", 26017],
        ["Benzie", 26019],
        ["Berrien", 26021],
        ["Branch", 26023],
        ["Calhoun", 26025],
        ["Cass", 26027],
        ["Charlevoix", 26029],
        ["Cheboygan", 26031],
        ["Chippewa", 26033],
        ["Clare", 26035],
        ["Clinton", 26037],
        ["Crawford", 26039],
        ["Delta", 26041],
        ["Dickinson", 26043],
        ["Eaton", 26045],
        ["Emmet", 26047],
        ["Genesee", 26049],
        ["Gladwin", 26051],
        ["Gogebic", 26053],
        ["Grand Traverse", 26055],
        ["Gratiot", 26057],
        ["Hillsdale", 26059],
        ["Houghton", 26061],
        ["Huron", 26063],
        ["Ingham", 26065],
        ["Ionia", 26067],
        ["Iosco", 26069],
        ["Iron", 26071],
        ["Isabella", 26073],
        ["Jackson", 26075],
        ["Kalamazoo", 26077],
        ["Kalkaska", 26079],
        ["Kent", 26081],
        ["Keweenaw", 26083],
        ["Lake", 26085],
        ["Lapeer", 26087],
        ["Leelanau", 26089],
        ["Lenawee", 26091],
        ["Livingston", 26093],
        ["Luce", 26095],
        ["Mackinac", 26097],
        ["Macomb", 26099],
        ["Manistee", 26101],
        ["Marquette", 26103],
        ["Mason", 26105],
        ["Mecosta", 26107],
        ["Menominee", 26109],
        ["Midland", 26111],
        ["Missaukee", 26113],
        ["Monroe", 26115],
        ["Montcalm", 26117],
        ["Montmorency", 26119],
        ["Muskegon", 26121],
        ["Newaygo", 26123],
        ["Oakland", 26125],
        ["Oceana", 26127],
        ["Ogemaw", 26129],
        ["Ontonagon", 26131],
        ["Osceola", 26133],
        ["Oscoda", 26135],
        ["Otsego", 26137],
        ["Ottawa", 26139],
        ["Presque Isle", 26141],
        ["Roscommon", 26143],
        ["Saginaw", 26145],
        ["St Clair", 26147],
        ["St Joseph", 26149],
        ["Sanilac", 26151],
        ["Schoolcraft", 26153],
        ["Shiawassee", 26155],
        ["Tuscola", 26157],
        ["Van Buren", 26159],
        ["Washtenaw", 26161],
        ["Wayne", 26163],
        ["Wexford", 26165],
    ],
    "MN": [
        ["Aitkin", 27001],
        ["Anoka", 27003],
        ["Becker", 27005],
        ["Beltrami", 27007],
        ["Benton", 27009],
        ["Big Stone", 27011],
        ["Blue Earth", 27013],
        ["Brown", 27015],
        ["Carlton", 27017],
        ["Carver", 27019],
        ["Cass", 27021],
        ["Chippewa", 27023],
        ["Chisago", 27025],
        ["Clay", 27027],
        ["Clearwater", 27029],
        ["Cook", 27031],
        ["Cottonwood", 27033],
        ["Crow Wing", 27035],
        ["Dakota", 27037],
        ["Dodge", 27039],
        ["Douglas", 27041],
        ["Faribault", 27043],
        ["Fillmore", 27045],
        ["Freeborn", 27047],
        ["Goodhue", 27049],
        ["Grant", 27051],
        ["Hennepin", 27053],
        ["Houston", 27055],
        ["Hubbard", 27057],
        ["Isanti", 27059],
        ["Itasca", 27061],
        ["Jackson", 27063],
        ["Kanabec", 27065],
        ["Kandiyohi", 27067],
        ["Kittson", 27069],
        ["Koochiching", 27071],
        ["Lac qui Parle", 27073],
        ["Lake", 27075],
        ["Lake of the Woods", 27077],
        ["Le Sueur", 27079],
        ["Lincoln", 27081],
        ["Lyon", 27083],
        ["McLeod", 27085],
        ["Mahnomen", 27087],
        ["Marshall", 27089],
        ["Martin", 27091],
        ["Meeker", 27093],
        ["Mille Lacs", 27095],
        ["Morrison", 27097],
        ["Mower", 27099],
        ["Murray", 27101],
        ["Nicollet", 27103],
        ["Nobles", 27105],
        ["Norman", 27107],
        ["Olmsted", 27109],
        ["Otter Tail", 27111],
        ["Pennington", 27113],
        ["Pine", 27115],
        ["Pipestone", 27117],
        ["Polk", 27119],
        ["Pope", 27121],
        ["Ramsey", 27123],
        ["Red Lake", 27125],
        ["Redwood", 27127],
        ["Renville", 27129],
        ["Rice", 27131],
        ["Rock", 27133],
        ["Roseau", 27135],
        ["St Louis", 27137],
        ["Scott", 27139],
        ["Sherburne", 27141],
        ["Sibley", 27143],
        ["Stearns", 27145],
        ["Steele", 27147],
        ["Stevens", 27149],
        ["Swift", 27151],
        ["Todd", 27153],
        ["Traverse", 27155],
        ["Wabasha", 27157],
        ["Wadena", 27159],
        ["Waseca", 27161],
        ["Washington", 27163],
        ["Watonwan", 27165],
        ["Wilkin", 27167],
        ["Winona", 27169],
        ["Wright", 27171],
        ["Yellow Medicine", 27173],
    ],
    "MO": [
        ["Adair", 29001],
        ["Andrew", 29003],
        ["Atchison", 29005],
        ["Audrain", 29007],
        ["Barry", 29009],
        ["Barton", 29011],
        ["Bates", 29013],
        ["Benton", 29015],
        ["Bollinger", 29017],
        ["Boone", 29019],
        ["Buchanan", 29021],
        ["Butler", 29023],
        ["Caldwell", 29025],
        ["Callaway", 29027],
        ["Camden", 29029],
        ["Cape Girardeau", 29031],
        ["Carroll", 29033],
        ["Carter", 29035],
        ["Cass", 29037],
        ["Cedar", 29039],
        ["Chariton", 29041],
        ["Christian", 29043],
        ["Clark", 29045],
        ["Clay", 29047],
        ["Clinton", 29049],
        ["Cole", 29051],
        ["Cooper", 29053],
        ["Crawford", 29055],
        ["Dade", 29057],
        ["Dallas", 29059],
        ["Daviess", 29061],
        ["De Kalb", 29063],
        ["Dent", 29065],
        ["Douglas", 29067],
        ["Dunklin", 29069],
        ["Franklin", 29071],
        ["Gasconade", 29073],
        ["Gentry", 29075],
        ["Greene", 29077],
        ["Grundy", 29079],
        ["Harrison", 29081],
        ["Henry", 29083],
        ["Hickory", 29085],
        ["Holt", 29087],
        ["Howard", 29089],
        ["Howell", 29091],
        ["Iron", 29093],
        ["Jackson", 29095],
        ["Jasper", 29097],
        ["Jefferson", 29099],
        ["Johnson", 29101],
        ["Knox", 29103],
        ["Laclede", 29105],
        ["Lafayette", 29107],
        ["Lawrence", 29109],
        ["Lewis", 29111],
        ["Lincoln", 29113],
        ["Linn", 29115],
        ["Livingston", 29117],
        ["McDonald", 29119],
        ["Macon", 29121],
        ["Madison", 29123],
        ["Maries", 29125],
        ["Marion", 29127],
        ["Mercer", 29129],
        ["Miller", 29131],
        ["Mississippi", 29133],
        ["Moniteau", 29135],
        ["Monroe", 29137],
        ["Montgomery", 29139],
        ["Morgan", 29141],
        ["New Madrid", 29143],
        ["Newton", 29145],
        ["Nodaway", 29147],
        ["Oregon", 29149],
        ["Osage", 29151],
        ["Ozark", 29153],
        ["Pemiscot", 29155],
        ["Perry", 29157],
        ["Pettis", 29159],
        ["Phelps", 29161],
        ["Pike", 29163],
        ["Platte", 29165],
        ["Polk", 29167],
        ["Pulaski", 29169],
        ["Putnam", 29171],
        ["Ralls", 29173],
        ["Randolph", 29175],
        ["Ray", 29177],
        ["Reynolds", 29179],
        ["Ripley", 29181],
        ["St Charles", 29183],
        ["St Clair", 29185],
        ["Ste. Genevieve", 29186],
        ["St Francois", 29187],
        ["St Louis", 29189],
        ["Saline", 29195],
        ["Schuyler", 29197],
        ["Scotland", 29199],
        ["Scott", 29201],
        ["Shannon", 29203],
        ["Shelby", 29205],
        ["Stoddard", 29207],
        ["Stone", 29209],
        ["Sullivan", 29211],
        ["Taney", 29213],
        ["Texas", 29215],
        ["Vernon", 29217],
        ["Warren", 29219],
        ["Washington", 29221],
        ["Wayne", 29223],
        ["Webster", 29225],
        ["Worth", 29227],
        ["Wright", 29229],
        ["St Louis City", 29510],
    ],
    "MS": [
        ["Adams", 28001],
        ["Alcorn", 28003],
        ["Amite", 28005],
        ["Attala", 28007],
        ["Benton", 28009],
        ["Bolivar", 28011],
        ["Calhoun", 28013],
        ["Carroll", 28015],
        ["Chickasaw", 28017],
        ["Choctaw", 28019],
        ["Claiborne", 28021],
        ["Clarke", 28023],
        ["Clay", 28025],
        ["Coahoma", 28027],
        ["Copiah", 28029],
        ["Covington", 28031],
        ["De Soto", 28033],
        ["Forrest", 28035],
        ["Franklin", 28037],
        ["George", 28039],
        ["Greene", 28041],
        ["Grenada", 28043],
        ["Hancock", 28045],
        ["Harrison", 28047],
        ["Hinds", 28049],
        ["Holmes", 28051],
        ["Humphreys", 28053],
        ["Issaquena", 28055],
        ["Itawamba", 28057],
        ["Jackson", 28059],
        ["Jasper", 28061],
        ["Jefferson", 28063],
        ["Jefferson Davis", 28065],
        ["Jones", 28067],
        ["Kemper", 28069],
        ["Lafayette", 28071],
        ["Lamar", 28073],
        ["Lauderdale", 28075],
        ["Lawrence", 28077],
        ["Leake", 28079],
        ["Lee", 28081],
        ["Leflore", 28083],
        ["Lincoln", 28085],
        ["Lowndes", 28087],
        ["Madison", 28089],
        ["Marion", 28091],
        ["Marshall", 28093],
        ["Monroe", 28095],
        ["Montgomery", 28097],
        ["Neshoba", 28099],
        ["Newton", 28101],
        ["Noxubee", 28103],
        ["Oktibbeha", 28105],
        ["Panola", 28107],
        ["Pearl River", 28109],
        ["Perry", 28111],
        ["Pike", 28113],
        ["Pontotoc", 28115],
        ["Prentiss", 28117],
        ["Quitman", 28119],
        ["Rankin", 28121],
        ["Scott", 28123],
        ["Sharkey", 28125],
        ["Simpson", 28127],
        ["Smith", 28129],
        ["Stone", 28131],
        ["Sunflower", 28133],
        ["Tallahatchie", 28135],
        ["Tate", 28137],
        ["Tippah", 28139],
        ["Tishomingo", 28141],
        ["Tunica", 28143],
        ["Union", 28145],
        ["Walthall", 28147],
        ["Warren", 28149],
        ["Washington", 28151],
        ["Wayne", 28153],
        ["Webster", 28155],
        ["Wilkinson", 28157],
        ["Winston", 28159],
        ["Yalobusha", 28161],
        ["Yazoo", 28163],
    ],
    "MT": [
        ["Beaverhead", 30001],
        ["Big Horn", 30003],
        ["Blaine", 30005],
        ["Broadwater", 30007],
        ["Carbon", 30009],
        ["Carter", 30011],
        ["Cascade", 30013],
        ["Chouteau", 30015],
        ["Custer", 30017],
        ["Daniels", 30019],
        ["Dawson", 30021],
        ["Deer Lodge", 30023],
        ["Fallon", 30025],
        ["Fergus", 30027],
        ["Flathead", 30029],
        ["Gallatin", 30031],
        ["Garfield", 30033],
        ["Glacier", 30035],
        ["Golden Valley", 30037],
        ["Granite", 30039],
        ["Hill", 30041],
        ["Jefferson", 30043],
        ["Judith Basin", 30045],
        ["Lake", 30047],
        ["Lewis and Clark", 30049],
        ["Liberty", 30051],
        ["Lincoln", 30053],
        ["McCone", 30055],
        ["Madison", 30057],
        ["Meagher", 30059],
        ["Mineral", 30061],
        ["Missoula", 30063],
        ["Musselshell", 30065],
        ["Park", 30067],
        ["Petroleum", 30069],
        ["Phillips", 30071],
        ["Pondera", 30073],
        ["Powder River", 30075],
        ["Powell", 30077],
        ["Prairie", 30079],
        ["Ravalli", 30081],
        ["Richland", 30083],
        ["Roosevelt", 30085],
        ["Rosebud", 30087],
        ["Sanders", 30089],
        ["Sheridan", 30091],
        ["Silver Bow", 30093],
        ["Stillwater", 30095],
        ["Sweet Grass", 30097],
        ["Teton", 30099],
        ["Toole", 30101],
        ["Treasure", 30103],
        ["Valley", 30105],
        ["Wheatland", 30107],
        ["Wibaux", 30109],
        ["Yellowstone", 30111],
        ["Yellowstone Nat Park", 30113],
    ],
    "NC": [
        ["Alamance", 37001],
        ["Alexander", 37003],
        ["Alleghany", 37005],
        ["Anson", 37007],
        ["Ashe", 37009],
        ["Avery", 37011],
        ["Beaufort", 37013],
        ["Bertie", 37015],
        ["Bladen", 37017],
        ["Brunswick", 37019],
        ["Buncombe", 37021],
        ["Burke", 37023],
        ["Cabarrus", 37025],
        ["Caldwell", 37027],
        ["Camden", 37029],
        ["Carteret", 37031],
        ["Caswell", 37033],
        ["Catawba", 37035],
        ["Chatham", 37037],
        ["Cherokee", 37039],
        ["Chowan", 37041],
        ["Clay", 37043],
        ["Cleveland", 37045],
        ["Columbus", 37047],
        ["Craven", 37049],
        ["Cumberland", 37051],
        ["Currituck", 37053],
        ["Dare", 37055],
        ["Davidson", 37057],
        ["Davie", 37059],
        ["Duplin", 37061],
        ["Durham", 37063],
        ["Edgecombe", 37065],
        ["Forsyth", 37067],
        ["Franklin", 37069],
        ["Gaston", 37071],
        ["Gates", 37073],
        ["Graham", 37075],
        ["Granville", 37077],
        ["Greene", 37079],
        ["Guilford", 37081],
        ["Halifax", 37083],
        ["Harnett", 37085],
        ["Haywood", 37087],
        ["Henderson", 37089],
        ["Hertford", 37091],
        ["Hoke", 37093],
        ["Hyde", 37095],
        ["Iredell", 37097],
        ["Jackson", 37099],
        ["Johnston", 37101],
        ["Jones", 37103],
        ["Lee", 37105],
        ["Lenoir", 37107],
        ["Lincoln", 37109],
        ["McDowell", 37111],
        ["Macon", 37113],
        ["Madison", 37115],
        ["Martin", 37117],
        ["Mecklenburg", 37119],
        ["Mitchell", 37121],
        ["Montgomery", 37123],
        ["Moore", 37125],
        ["Nash", 37127],
        ["New Hanover", 37129],
        ["Northampton", 37131],
        ["Onslow", 37133],
        ["Orange", 37135],
        ["Pamlico", 37137],
        ["Pasquotank", 37139],
        ["Pender", 37141],
        ["Perquimans", 37143],
        ["Person", 37145],
        ["Pitt", 37147],
        ["Polk", 37149],
        ["Randolph", 37151],
        ["Richmond", 37153],
        ["Robeson", 37155],
        ["Rockingham", 37157],
        ["Rowan", 37159],
        ["Rutherford", 37161],
        ["Sampson", 37163],
        ["Scotland", 37165],
        ["Stanly", 37167],
        ["Stokes", 37169],
        ["Surry", 37171],
        ["Swain", 37173],
        ["Transylvania", 37175],
        ["Tyrrell", 37177],
        ["Union", 37179],
        ["Vance", 37181],
        ["Wake", 37183],
        ["Warren", 37185],
        ["Washington", 37187],
        ["Watauga", 37189],
        ["Wayne", 37191],
        ["Wilkes", 37193],
        ["Wilson", 37195],
        ["Yadkin", 37197],
        ["Yancey", 37199],
    ],
    "ND": [
        ["Adams", 38001],
        ["Barnes", 38003],
        ["Benson", 38005],
        ["Billings", 38007],
        ["Bottineau", 38009],
        ["Bowman", 38011],
        ["Burke", 38013],
        ["Burleigh", 38015],
        ["Cass", 38017],
        ["Cavalier", 38019],
        ["Dickey", 38021],
        ["Divide", 38023],
        ["Dunn", 38025],
        ["Eddy", 38027],
        ["Emmons", 38029],
        ["Foster", 38031],
        ["Golden Valley", 38033],
        ["Grand Forks", 38035],
        ["Grant", 38037],
        ["Griggs", 38039],
        ["Hettinger", 38041],
        ["Kidder", 38043],
        ["La Moure", 38045],
        ["Logan", 38047],
        ["McHenry", 38049],
        ["McIntosh", 38051],
        ["McKenzie", 38053],
        ["McLean", 38055],
        ["Mercer", 38057],
        ["Morton", 38059],
        ["Mountrail", 38061],
        ["Nelson", 38063],
        ["Oliver", 38065],
        ["Pembina", 38067],
        ["Pierce", 38069],
        ["Ramsey", 38071],
        ["Ransom", 38073],
        ["Renville", 38075],
        ["Richland", 38077],
        ["Rolette", 38079],
        ["Sargent", 38081],
        ["Sheridan", 38083],
        ["Sioux", 38085],
        ["Slope", 38087],
        ["Stark", 38089],
        ["Steele", 38091],
        ["Stutsman", 38093],
        ["Towner", 38095],
        ["Traill", 38097],
        ["Walsh", 38099],
        ["Ward", 38101],
        ["Wells", 38103],
        ["Williams", 38105],
    ],
    "NE": [
        ["Adams", 31001],
        ["Antelope", 31003],
        ["Arthur", 31005],
        ["Banner", 31007],
        ["Blaine", 31009],
        ["Boone", 31011],
        ["Box Butte", 31013],
        ["Boyd", 31015],
        ["Brown", 31017],
        ["Buffalo", 31019],
        ["Burt", 31021],
        ["Butler", 31023],
        ["Cass", 31025],
        ["Cedar", 31027],
        ["Chase", 31029],
        ["Cherry", 31031],
        ["Cheyenne", 31033],
        ["Clay", 31035],
        ["Colfax", 31037],
        ["Cuming", 31039],
        ["Custer", 31041],
        ["Dakota", 31043],
        ["Dawes", 31045],
        ["Dawson", 31047],
        ["Deuel", 31049],
        ["Dixon", 31051],
        ["Dodge", 31053],
        ["Douglas", 31055],
        ["Dundy", 31057],
        ["Fillmore", 31059],
        ["Franklin", 31061],
        ["Frontier", 31063],
        ["Furnas", 31065],
        ["Gage", 31067],
        ["Garden", 31069],
        ["Garfield", 31071],
        ["Gosper", 31073],
        ["Grant", 31075],
        ["Greeley", 31077],
        ["Hall", 31079],
        ["Hamilton", 31081],
        ["Harlan", 31083],
        ["Hayes", 31085],
        ["Hitchcock", 31087],
        ["Holt", 31089],
        ["Hooker", 31091],
        ["Howard", 31093],
        ["Jefferson", 31095],
        ["Johnson", 31097],
        ["Kearney", 31099],
        ["Keith", 31101],
        ["Keya Paha", 31103],
        ["Kimball", 31105],
        ["Knox", 31107],
        ["Lancaster", 31109],
        ["Lincoln", 31111],
        ["Logan", 31113],
        ["Loup", 31115],
        ["McPherson", 31117],
        ["Madison", 31119],
        ["Merrick", 31121],
        ["Morrill", 31123],
        ["Nance", 31125],
        ["Nemaha", 31127],
        ["Nuckolls", 31129],
        ["Otoe", 31131],
        ["Pawnee", 31133],
        ["Perkins", 31135],
        ["Phelps", 31137],
        ["Pierce", 31139],
        ["Platte", 31141],
        ["Polk", 31143],
        ["Red Willow", 31145],
        ["Richardson", 31147],
        ["Rock", 31149],
        ["Saline", 31151],
        ["Sarpy", 31153],
        ["Saunders", 31155],
        ["Scotts Bluff", 31157],
        ["Seward", 31159],
        ["Sheridan", 31161],
        ["Sherman", 31163],
        ["Sioux", 31165],
        ["Stanton", 31167],
        ["Thayer", 31169],
        ["Thomas", 31171],
        ["Thurston", 31173],
        ["Valley", 31175],
        ["Washington", 31177],
        ["Wayne", 31179],
        ["Webster", 31181],
        ["Wheeler", 31183],
        ["York", 31185],
    ],
    "NH": [
        ["Belknap", 33001],
        ["Carroll", 33003],
        ["Cheshire", 33005],
        ["Coos", 33007],
        ["Grafton", 33009],
        ["Hillsborough", 33011],
        ["Merrimack", 33013],
        ["Rockingham", 33015],
        ["Strafford", 33017],
        ["Sullivan", 33019],
    ],
    "NJ": [
        ["Atlantic", 34001],
        ["Bergen", 34003],
        ["Burlington", 34005],
        ["Camden", 34007],
        ["Cape May", 34009],
        ["Cumberland", 34011],
        ["Essex", 34013],
        ["Gloucester", 34015],
        ["Hudson", 34017],
        ["Hunterdon", 34019],
        ["Mercer", 34021],
        ["Middlesex", 34023],
        ["Monmouth", 34025],
        ["Morris", 34027],
        ["Ocean", 34029],
        ["Passaic", 34031],
        ["Salem", 34033],
        ["Somerset", 34035],
        ["Sussex", 34037],
        ["Union", 34039],
        ["Warren", 34041],
    ],
    "NM": [
        ["Bernalillo", 35001],
        ["Catron", 35003],
        ["Chaves", 35005],
        ["Cibola", 35006],
        ["Colfax", 35007],
        ["Curry", 35009],
        ["De Baca", 35011],
        ["Dona Ana", 35013],
        ["Eddy", 35015],
        ["Grant", 35017],
        ["Guadalupe", 35019],
        ["Harding", 35021],
        ["Hidalgo", 35023],
        ["Lea", 35025],
        ["Lincoln", 35027],
        ["Los Alamos", 35028],
        ["Luna", 35029],
        ["McKinley", 35031],
        ["Mora", 35033],
        ["Otero", 35035],
        ["Quay", 35037],
        ["Rio Arriba", 35039],
        ["Roosevelt", 35041],
        ["Sandoval", 35043],
        ["San Juan", 35045],
        ["San Miguel", 35047],
        ["Santa Fe", 35049],
        ["Sierra", 35051],
        ["Socorro", 35053],
        ["Taos", 35055],
        ["Torrance", 35057],
        ["Union", 35059],
        ["Valencia", 35061],
    ],
    "NV": [
        ["Churchill", 32001],
        ["Clark", 32003],
        ["Douglas", 32005],
        ["Elko", 32007],
        ["Esmeralda", 32009],
        ["Eureka", 32011],
        ["Humboldt", 32013],
        ["Lander", 32015],
        ["Lincoln", 32017],
        ["Lyon", 32019],
        ["Mineral", 32021],
        ["Nye", 32023],
        ["Pershing", 32027],
        ["Storey", 32029],
        ["Washoe", 32031],
        ["White Pine", 32033],
        ["Carson City", 32510],
    ],
    "NY": [
        ["Albany", 36001],
        ["Allegany", 36003],
        ["Bronx", 36005],
        ["Broome", 36007],
        ["Cattaraugus", 36009],
        ["Cayuga", 36011],
        ["Chautauqua", 36013],
        ["Chemung", 36015],
        ["Chenango", 36017],
        ["Clinton", 36019],
        ["Columbia", 36021],
        ["Cortland", 36023],
        ["Delaware", 36025],
        ["Dutchess", 36027],
        ["Erie", 36029],
        ["Essex", 36031],
        ["Franklin", 36033],
        ["Fulton", 36035],
        ["Genesee", 36037],
        ["Greene", 36039],
        ["Hamilton", 36041],
        ["Herkimer", 36043],
        ["Jefferson", 36045],
        ["Kings", 36047],
        ["Lewis", 36049],
        ["Livingston", 36051],
        ["Madison", 36053],
        ["Monroe", 36055],
        ["Montgomery", 36057],
        ["Nassau", 36059],
        ["New York", 36061],
        ["Niagara", 36063],
        ["Oneida", 36065],
        ["Onondaga", 36067],
        ["Ontario", 36069],
        ["Orange", 36071],
        ["Orleans", 36073],
        ["Oswego", 36075],
        ["Otsego", 36077],
        ["Putnam", 36079],
        ["Queens", 36081],
        ["Rensselaer", 36083],
        ["Richmond", 36085],
        ["Rockland", 36087],
        ["St Lawrence", 36089],
        ["Saratoga", 36091],
        ["Schenectady", 36093],
        ["Schoharie", 36095],
        ["Schuyler", 36097],
        ["Seneca", 36099],
        ["Steuben", 36101],
        ["Suffolk", 36103],
        ["Sullivan", 36105],
        ["Tioga", 36107],
        ["Tompkins", 36109],
        ["Ulster", 36111],
        ["Warren", 36113],
        ["Washington", 36115],
        ["Wayne", 36117],
        ["Westchester", 36119],
        ["Wyoming", 36121],
        ["Yates", 36123],
    ],
    "OH": [
        ["Adams", 39001],
        ["Allen", 39003],
        ["Ashland", 39005],
        ["Ashtabula", 39007],
        ["Athens", 39009],
        ["Auglaize", 39011],
        ["Belmont", 39013],
        ["Brown", 39015],
        ["Butler", 39017],
        ["Carroll", 39019],
        ["Champaign", 39021],
        ["Clark", 39023],
        ["Clermont", 39025],
        ["Clinton", 39027],
        ["Columbiana", 39029],
        ["Coshocton", 39031],
        ["Crawford", 39033],
        ["Cuyahoga", 39035],
        ["Darke", 39037],
        ["Defiance", 39039],
        ["Delaware", 39041],
        ["Erie", 39043],
        ["Fairfield", 39045],
        ["Fayette", 39047],
        ["Franklin", 39049],
        ["Fulton", 39051],
        ["Gallia", 39053],
        ["Geauga", 39055],
        ["Greene", 39057],
        ["Guernsey", 39059],
        ["Hamilton", 39061],
        ["Hancock", 39063],
        ["Hardin", 39065],
        ["Harrison", 39067],
        ["Henry", 39069],
        ["Highland", 39071],
        ["Hocking", 39073],
        ["Holmes", 39075],
        ["Huron", 39077],
        ["Jackson", 39079],
        ["Jefferson", 39081],
        ["Knox", 39083],
        ["Lake", 39085],
        ["Lawrence", 39087],
        ["Licking", 39089],
        ["Logan", 39091],
        ["Lorain", 39093],
        ["Lucas", 39095],
        ["Madison", 39097],
        ["Mahoning", 39099],
        ["Marion", 39101],
        ["Medina", 39103],
        ["Meigs", 39105],
        ["Mercer", 39107],
        ["Miami", 39109],
        ["Monroe", 39111],
        ["Montgomery", 39113],
        ["Morgan", 39115],
        ["Morrow", 39117],
        ["Muskingum", 39119],
        ["Noble", 39121],
        ["Ottawa", 39123],
        ["Paulding", 39125],
        ["Perry", 39127],
        ["Pickaway", 39129],
        ["Pike", 39131],
        ["Portage", 39133],
        ["Preble", 39135],
        ["Putnam", 39137],
        ["Richland", 39139],
        ["Ross", 39141],
        ["Sandusky", 39143],
        ["Scioto", 39145],
        ["Seneca", 39147],
        ["Shelby", 39149],
        ["Stark", 39151],
        ["Summit", 39153],
        ["Trumbull", 39155],
        ["Tuscarawas", 39157],
        ["Union", 39159],
        ["Van Wert", 39161],
        ["Vinton", 39163],
        ["Warren", 39165],
        ["Washington", 39167],
        ["Wayne", 39169],
        ["Williams", 39171],
        ["Wood", 39173],
        ["Wyandot", 39175],
    ],
    "OK": [
        ["Adair", 40001],
        ["Alfalfa", 40003],
        ["Atoka", 40005],
        ["Beaver", 40007],
        ["Beckham", 40009],
        ["Blaine", 40011],
        ["Bryan", 40013],
        ["Caddo", 40015],
        ["Canadian", 40017],
        ["Carter", 40019],
        ["Cherokee", 40021],
        ["Choctaw", 40023],
        ["Cimarron", 40025],
        ["Cleveland", 40027],
        ["Coal", 40029],
        ["Comanche", 40031],
        ["Cotton", 40033],
        ["Craig", 40035],
        ["Creek", 40037],
        ["Custer", 40039],
        ["Delaware", 40041],
        ["Dewey", 40043],
        ["Ellis", 40045],
        ["Garfield", 40047],
        ["Garvin", 40049],
        ["Grady", 40051],
        ["Grant", 40053],
        ["Greer", 40055],
        ["Harmon", 40057],
        ["Harper", 40059],
        ["Haskell", 40061],
        ["Hughes", 40063],
        ["Jackson", 40065],
        ["Jefferson", 40067],
        ["Johnston", 40069],
        ["Kay", 40071],
        ["Kingfisher", 40073],
        ["Kiowa", 40075],
        ["Latimer", 40077],
        ["Le Flore", 40079],
        ["Lincoln", 40081],
        ["Logan", 40083],
        ["Love", 40085],
        ["McClain", 40087],
        ["McCurtain", 40089],
        ["McIntosh", 40091],
        ["Major", 40093],
        ["Marshall", 40095],
        ["Mayes", 40097],
        ["Murray", 40099],
        ["Muskogee", 40101],
        ["Noble", 40103],
        ["Nowata", 40105],
        ["Okfuskee", 40107],
        ["Oklahoma", 40109],
        ["Okmulgee", 40111],
        ["Osage", 40113],
        ["Ottawa", 40115],
        ["Pawnee", 40117],
        ["Payne", 40119],
        ["Pittsburg", 40121],
        ["Pontotoc", 40123],
        ["Pottawatomie", 40125],
        ["Pushmataha", 40127],
        ["Roger Mills", 40129],
        ["Rogers", 40131],
        ["Seminole", 40133],
        ["Sequoyah", 40135],
        ["Stephens", 40137],
        ["Texas", 40139],
        ["Tillman", 40141],
        ["Tulsa", 40143],
        ["Wagoner", 40145],
        ["Washington", 40147],
        ["Washita", 40149],
        ["Woods", 40151],
        ["Woodward", 40153],
    ],
    "OR": [
        ["Baker", 41001],
        ["Benton", 41003],
        ["Clackamas", 41005],
        ["Clatsop", 41007],
        ["Columbia", 41009],
        ["Coos", 41011],
        ["Crook", 41013],
        ["Curry", 41015],
        ["Deschutes", 41017],
        ["Douglas", 41019],
        ["Gilliam", 41021],
        ["Grant", 41023],
        ["Harney", 41025],
        ["Hood River", 41027],
        ["Jackson", 41029],
        ["Jefferson", 41031],
        ["Josephine", 41033],
        ["Klamath", 41035],
        ["Lake", 41037],
        ["Lane", 41039],
        ["Lincoln", 41041],
        ["Linn", 41043],
        ["Malheur", 41045],
        ["Marion", 41047],
        ["Morrow", 41049],
        ["Multnomah", 41051],
        ["Polk", 41053],
        ["Sherman", 41055],
        ["Tillamook", 41057],
        ["Umatilla", 41059],
        ["Union", 41061],
        ["Wallowa", 41063],
        ["Wasco", 41065],
        ["Washington", 41067],
        ["Wheeler", 41069],
        ["Yamhill", 41071],
    ],
    "PA": [
        ["Adams", 42001],
        ["Allegheny", 42003],
        ["Armstrong", 42005],
        ["Beaver", 42007],
        ["Bedford", 42009],
        ["Berks", 42011],
        ["Blair", 42013],
        ["Bradford", 42015],
        ["Bucks", 42017],
        ["Butler", 42019],
        ["Cambria", 42021],
        ["Cameron", 42023],
        ["Carbon", 42025],
        ["Centre", 42027],
        ["Chester", 42029],
        ["Clarion", 42031],
        ["Clearfield", 42033],
        ["Clinton", 42035],
        ["Columbia", 42037],
        ["Crawford", 42039],
        ["Cumberland", 42041],
        ["Dauphin", 42043],
        ["Delaware", 42045],
        ["Elk", 42047],
        ["Erie", 42049],
        ["Fayette", 42051],
        ["Forest", 42053],
        ["Franklin", 42055],
        ["Fulton", 42057],
        ["Greene", 42059],
        ["Huntingdon", 42061],
        ["Indiana", 42063],
        ["Jefferson", 42065],
        ["Juniata", 42067],
        ["Lackawanna", 42069],
        ["Lancaster", 42071],
        ["Lawrence", 42073],
        ["Lebanon", 42075],
        ["Lehigh", 42077],
        ["Luzerne", 42079],
        ["Lycoming", 42081],
        ["McKean", 42083],
        ["Mercer", 42085],
        ["Mifflin", 42087],
        ["Monroe", 42089],
        ["Montgomery", 42091],
        ["Montour", 42093],
        ["Northampton", 42095],
        ["Northumberland", 42097],
        ["Perry", 42099],
        ["Philadelphia", 42101],
        ["Pike", 42103],
        ["Potter", 42105],
        ["Schuylkill", 42107],
        ["Snyder", 42109],
        ["Somerset", 42111],
        ["Sullivan", 42113],
        ["Susquehanna", 42115],
        ["Tioga", 42117],
        ["Union", 42119],
        ["Venango", 42121],
        ["Warren", 42123],
        ["Washington", 42125],
        ["Wayne", 42127],
        ["Westmoreland", 42129],
        ["Wyoming", 42131],
        ["York", 42133],
    ],
    "RI": [
        ["Bristol", 44001],
        ["Kent", 44003],
        ["Newport", 44005],
        ["Providence", 44007],
        ["Washington", 44009],
    ],
    "SC": [
        ["Abbeville", 45001],
        ["Aiken", 45003],
        ["Allendale", 45005],
        ["Anderson", 45007],
        ["Bamberg", 45009],
        ["Barnwell", 45011],
        ["Beaufort", 45013],
        ["Berkeley", 45015],
        ["Calhoun", 45017],
        ["Charleston", 45019],
        ["Cherokee", 45021],
        ["Chester", 45023],
        ["Chesterfield", 45025],
        ["Clarendon", 45027],
        ["Colleton", 45029],
        ["Darlington", 45031],
        ["Dillon", 45033],
        ["Dorchester", 45035],
        ["Edgefield", 45037],
        ["Fairfield", 45039],
        ["Florence", 45041],
        ["Georgetown", 45043],
        ["Greenville", 45045],
        ["Greenwood", 45047],
        ["Hampton", 45049],
        ["Horry", 45051],
        ["Jasper", 45053],
        ["Kershaw", 45055],
        ["Lancaster", 45057],
        ["Laurens", 45059],
        ["Lee", 45061],
        ["Lexington", 45063],
        ["McCormick", 45065],
        ["Marion", 45067],
        ["Marlboro", 45069],
        ["Newberry", 45071],
        ["Oconee", 45073],
        ["Orangeburg", 45075],
        ["Pickens", 45077],
        ["Richland", 45079],
        ["Saluda", 45081],
        ["Spartanburg", 45083],
        ["Sumter", 45085],
        ["Union", 45087],
        ["Williamsburg", 45089],
        ["York", 45091],
    ],
    "SD": [
        ["Aurora", 46003],
        ["Beadle", 46005],
        ["Bennett", 46007],
        ["Bon Homme", 46009],
        ["Brookings", 46011],
        ["Brown", 46013],
        ["Brule", 46015],
        ["Buffalo", 46017],
        ["Butte", 46019],
        ["Campbell", 46021],
        ["Charles Mix", 46023],
        ["Clark", 46025],
        ["Clay", 46027],
        ["Codington", 46029],
        ["Corson", 46031],
        ["Custer", 46033],
        ["Davison", 46035],
        ["Day", 46037],
        ["Deuel", 46039],
        ["Dewey", 46041],
        ["Douglas", 46043],
        ["Edmunds", 46045],
        ["Fall River", 46047],
        ["Faulk", 46049],
        ["Grant", 46051],
        ["Gregory", 46053],
        ["Haakon", 46055],
        ["Hamlin", 46057],
        ["Hand", 46059],
        ["Hanson", 46061],
        ["Harding", 46063],
        ["Hughes", 46065],
        ["Hutchinson", 46067],
        ["Hyde", 46069],
        ["Jackson", 46071],
        ["Jerauld", 46073],
        ["Jones", 46075],
        ["Kingsbury", 46077],
        ["Lake", 46079],
        ["Lawrence", 46081],
        ["Lincoln", 46083],
        ["Lyman", 46085],
        ["McCook", 46087],
        ["McPherson", 46089],
        ["Marshall", 46091],
        ["Meade", 46093],
        ["Mellette", 46095],
        ["Miner", 46097],
        ["Minnehaha", 46099],
        ["Moody", 46101],
        ["Pennington", 46103],
        ["Perkins", 46105],
        ["Potter", 46107],
        ["Roberts", 46109],
        ["Sanborn", 46111],
        ["Shannon", 46113],
        ["Spink", 46115],
        ["Stanley", 46117],
        ["Sully", 46119],
        ["Todd", 46121],
        ["Tripp", 46123],
        ["Turner", 46125],
        ["Union", 46127],
        ["Walworth", 46129],
        ["Yankton", 46135],
        ["Ziebach", 46137],
    ],
    "TN": [
        ["Anderson", 47001],
        ["Bedford", 47003],
        ["Benton", 47005],
        ["Bledsoe", 47007],
        ["Blount", 47009],
        ["Bradley", 47011],
        ["Campbell", 47013],
        ["Cannon", 47015],
        ["Carroll", 47017],
        ["Carter", 47019],
        ["Cheatham", 47021],
        ["Chester", 47023],
        ["Claiborne", 47025],
        ["Clay", 47027],
        ["Cocke", 47029],
        ["Coffee", 47031],
        ["Crockett", 47033],
        ["Cumberland", 47035],
        ["Davidson", 47037],
        ["Decatur", 47039],
        ["De Kalb", 47041],
        ["Dickson", 47043],
        ["Dyer", 47045],
        ["Fayette", 47047],
        ["Fentress", 47049],
        ["Franklin", 47051],
        ["Gibson", 47053],
        ["Giles", 47055],
        ["Grainger", 47057],
        ["Greene", 47059],
        ["Grundy", 47061],
        ["Hamblen", 47063],
        ["Hamilton", 47065],
        ["Hancock", 47067],
        ["Hardeman", 47069],
        ["Hardin", 47071],
        ["Hawkins", 47073],
        ["Haywood", 47075],
        ["Henderson", 47077],
        ["Henry", 47079],
        ["Hickman", 47081],
        ["Houston", 47083],
        ["Humphreys", 47085],
        ["Jackson", 47087],
        ["Jefferson", 47089],
        ["Johnson", 47091],
        ["Knox", 47093],
        ["Lake", 47095],
        ["Lauderdale", 47097],
        ["Lawrence", 47099],
        ["Lewis", 47101],
        ["Lincoln", 47103],
        ["Loudon", 47105],
        ["McMinn", 47107],
        ["McNairy", 47109],
        ["Macon", 47111],
        ["Madison", 47113],
        ["Marion", 47115],
        ["Marshall", 47117],
        ["Maury", 47119],
        ["Meigs", 47121],
        ["Monroe", 47123],
        ["Montgomery", 47125],
        ["Moore", 47127],
        ["Morgan", 47129],
        ["Obion", 47131],
        ["Overton", 47133],
        ["Perry", 47135],
        ["Pickett", 47137],
        ["Polk", 47139],
        ["Putnam", 47141],
        ["Rhea", 47143],
        ["Roane", 47145],
        ["Robertson", 47147],
        ["Rutherford", 47149],
        ["Scott", 47151],
        ["Sequatchie", 47153],
        ["Sevier", 47155],
        ["Shelby", 47157],
        ["Smith", 47159],
        ["Stewart", 47161],
        ["Sullivan", 47163],
        ["Sumner", 47165],
        ["Tipton", 47167],
        ["Trousdale", 47169],
        ["Unicoi", 47171],
        ["Union", 47173],
        ["Van Buren", 47175],
        ["Warren", 47177],
        ["Washington", 47179],
        ["Wayne", 47181],
        ["Weakley", 47183],
        ["White", 47185],
        ["Williamson", 47187],
        ["Wilson", 47189],
    ],
    "TX": [
        ["Anderson", 48001],
        ["Andrews", 48003],
        ["Angelina", 48005],
        ["Aransas", 48007],
        ["Archer", 48009],
        ["Armstrong", 48011],
        ["Atascosa", 48013],
        ["Austin", 48015],
        ["Bailey", 48017],
        ["Bandera", 48019],
        ["Bastrop", 48021],
        ["Baylor", 48023],
        ["Bee", 48025],
        ["Bell", 48027],
        ["Bexar", 48029],
        ["Blanco", 48031],
        ["Borden", 48033],
        ["Bosque", 48035],
        ["Bowie", 48037],
        ["Brazoria", 48039],
        ["Brazos", 48041],
        ["Brewster", 48043],
        ["Briscoe", 48045],
        ["Brooks", 48047],
        ["Brown", 48049],
        ["Burleson", 48051],
        ["Burnet", 48053],
        ["Caldwell", 48055],
        ["Calhoun", 48057],
        ["Callahan", 48059],
        ["Cameron", 48061],
        ["Camp", 48063],
        ["Carson", 48065],
        ["Cass", 48067],
        ["Castro", 48069],
        ["Chambers", 48071],
        ["Cherokee", 48073],
        ["Childress", 48075],
        ["Clay", 48077],
        ["Cochran", 48079],
        ["Coke", 48081],
        ["Coleman", 48083],
        ["Collin", 48085],
        ["Collingsworth", 48087],
        ["Colorado", 48089],
        ["Comal", 48091],
        ["Comanche", 48093],
        ["Concho", 48095],
        ["Cooke", 48097],
        ["Coryell", 48099],
        ["Cottle", 48101],
        ["Crane", 48103],
        ["Crockett", 48105],
        ["Crosby", 48107],
        ["Culberson", 48109],
        ["Dallam", 48111],
        ["Dallas", 48113],
        ["Dawson", 48115],
        ["Deaf Smith", 48117],
        ["Delta", 48119],
        ["Denton", 48121],
        ["De Witt", 48123],
        ["Dickens", 48125],
        ["Dimmit", 48127],
        ["Donley", 48129],
        ["Duval", 48131],
        ["Eastland", 48133],
        ["Ector", 48135],
        ["Edwards", 48137],
        ["Ellis", 48139],
        ["El Paso", 48141],
        ["Erath", 48143],
        ["Falls", 48145],
        ["Fannin", 48147],
        ["Fayette", 48149],
        ["Fisher", 48151],
        ["Floyd", 48153],
        ["Foard", 48155],
        ["Fort Bend", 48157],
        ["Franklin", 48159],
        ["Freestone", 48161],
        ["Frio", 48163],
        ["Gaines", 48165],
        ["Galveston", 48167],
        ["Garza", 48169],
        ["Gillespie", 48171],
        ["Glasscock", 48173],
        ["Goliad", 48175],
        ["Gonzales", 48177],
        ["Gray", 48179],
        ["Grayson", 48181],
        ["Gregg", 48183],
        ["Grimes", 48185],
        ["Guadalupe", 48187],
        ["Hale", 48189],
        ["Hall", 48191],
        ["Hamilton", 48193],
        ["Hansford", 48195],
        ["Hardeman", 48197],
        ["Hardin", 48199],
        ["Harris", 48201],
        ["Harrison", 48203],
        ["Hartley", 48205],
        ["Haskell", 48207],
        ["Hays", 48209],
        ["Hemphill", 48211],
        ["Henderson", 48213],
        ["Hidalgo", 48215],
        ["Hill", 48217],
        ["Hockley", 48219],
        ["Hood", 48221],
        ["Hopkins", 48223],
        ["Houston", 48225],
        ["Howard", 48227],
        ["Hudspeth", 48229],
        ["Hunt", 48231],
        ["Hutchinson", 48233],
        ["Irion", 48235],
        ["Jack", 48237],
        ["Jackson", 48239],
        ["Jasper", 48241],
        ["Jeff Davis", 48243],
        ["Jefferson", 48245],
        ["Jim Hogg", 48247],
        ["Jim Wells", 48249],
        ["Johnson", 48251],
        ["Jones", 48253],
        ["Karnes", 48255],
        ["Kaufman", 48257],
        ["Kendall", 48259],
        ["Kenedy", 48261],
        ["Kent", 48263],
        ["Kerr", 48265],
        ["Kimble", 48267],
        ["King", 48269],
        ["Kinney", 48271],
        ["Kleberg", 48273],
        ["Knox", 48275],
        ["Lamar", 48277],
        ["Lamb", 48279],
        ["Lampasas", 48281],
        ["La Salle", 48283],
        ["Lavaca", 48285],
        ["Lee", 48287],
        ["Leon", 48289],
        ["Liberty", 48291],
        ["Limestone", 48293],
        ["Lipscomb", 48295],
        ["Live Oak", 48297],
        ["Llano", 48299],
        ["Loving", 48301],
        ["Lubbock", 48303],
        ["Lynn", 48305],
        ["McCulloch", 48307],
        ["McLennan", 48309],
        ["McMullen", 48311],
        ["Madison", 48313],
        ["Marion", 48315],
        ["Martin", 48317],
        ["Mason", 48319],
        ["Matagorda", 48321],
        ["Maverick", 48323],
        ["Medina", 48325],
        ["Menard", 48327],
        ["Midland", 48329],
        ["Milam", 48331],
        ["Mills", 48333],
        ["Mitchell", 48335],
        ["Montague", 48337],
        ["Montgomery", 48339],
        ["Moore", 48341],
        ["Morris", 48343],
        ["Motley", 48345],
        ["Nacogdoches", 48347],
        ["Navarro", 48349],
        ["Newton", 48351],
        ["Nolan", 48353],
        ["Nueces", 48355],
        ["Ochiltree", 48357],
        ["Oldham", 48359],
        ["Orange", 48361],
        ["Palo Pinto", 48363],
        ["Panola", 48365],
        ["Parker", 48367],
        ["Parmer", 48369],
        ["Pecos", 48371],
        ["Polk", 48373],
        ["Potter", 48375],
        ["Presidio", 48377],
        ["Rains", 48379],
        ["Randall", 48381],
        ["Reagan", 48383],
        ["Real", 48385],
        ["Red River", 48387],
        ["Reeves", 48389],
        ["Refugio", 48391],
        ["Roberts", 48393],
        ["Robertson", 48395],
        ["Rockwall", 48397],
        ["Runnels", 48399],
        ["Rusk", 48401],
        ["Sabine", 48403],
        ["San Augustine", 48405],
        ["San Jacinto", 48407],
        ["San Patricio", 48409],
        ["San Saba", 48411],
        ["Schleicher", 48413],
        ["Scurry", 48415],
        ["Shackelford", 48417],
        ["Shelby", 48419],
        ["Sherman", 48421],
        ["Smith", 48423],
        ["Somervell", 48425],
        ["Starr", 48427],
        ["Stephens", 48429],
        ["Sterling", 48431],
        ["Stonewall", 48433],
        ["Sutton", 48435],
        ["Swisher", 48437],
        ["Tarrant", 48439],
        ["Taylor", 48441],
        ["Terrell", 48443],
        ["Terry", 48445],
        ["Throckmorton", 48447],
        ["Titus", 48449],
        ["Tom Green", 48451],
        ["Travis", 48453],
        ["Trinity", 48455],
        ["Tyler", 48457],
        ["Upshur", 48459],
        ["Upton", 48461],
        ["Uvalde", 48463],
        ["Val Verde", 48465],
        ["Van Zandt", 48467],
        ["Victoria", 48469],
        ["Walker", 48471],
        ["Waller", 48473],
        ["Ward", 48475],
        ["Washington", 48477],
        ["Webb", 48479],
        ["Wharton", 48481],
        ["Wheeler", 48483],
        ["Wichita", 48485],
        ["Wilbarger", 48487],
        ["Willacy", 48489],
        ["Williamson", 48491],
        ["Wilson", 48493],
        ["Winkler", 48495],
        ["Wise", 48497],
        ["Wood", 48499],
        ["Yoakum", 48501],
        ["Young", 48503],
        ["Zapata", 48505],
        ["Zavala", 48507],
    ],
    "UT": [
        ["Beaver", 49001],
        ["Box Elder", 49003],
        ["Cache", 49005],
        ["Carbon", 49007],
        ["Daggett", 49009],
        ["Davis", 49011],
        ["Duchesne", 49013],
        ["Emery", 49015],
        ["Garfield", 49017],
        ["Grand", 49019],
        ["Iron", 49021],
        ["Juab", 49023],
        ["Kane", 49025],
        ["Millard", 49027],
        ["Morgan", 49029],
        ["Piute", 49031],
        ["Rich", 49033],
        ["Salt Lake", 49035],
        ["San Juan", 49037],
        ["Sanpete", 49039],
        ["Sevier", 49041],
        ["Summit", 49043],
        ["Tooele", 49045],
        ["Uintah", 49047],
        ["Utah", 49049],
        ["Wasatch", 49051],
        ["Washington", 49053],
        ["Wayne", 49055],
        ["Weber", 49057],
    ],
    "VA": [
        ["Accomack", 51001],
        ["Albemarle", 51003],
        ["Alleghany", 51005],
        ["Amelia", 51007],
        ["Amherst", 51009],
        ["Appomattox", 51011],
        ["Arlington", 51013],
        ["Augusta", 51015],
        ["Bath", 51017],
        ["Bedford", 51019],
        ["Bland", 51021],
        ["Botetourt", 51023],
        ["Brunswick", 51025],
        ["Buchanan", 51027],
        ["Buckingham", 51029],
        ["Campbell", 51031],
        ["Caroline", 51033],
        ["Carroll", 51035],
        ["Charles City", 51036],
        ["Charlotte", 51037],
        ["Chesterfield", 51041],
        ["Clarke", 51043],
        ["Craig", 51045],
        ["Culpeper", 51047],
        ["Cumberland", 51049],
        ["Dickenson", 51051],
        ["Dinwiddie", 51053],
        ["Essex", 51057],
        ["Fairfax", 51059],
        ["Fauquier", 51061],
        ["Floyd", 51063],
        ["Fluvanna", 51065],
        ["Franklin", 51067],
        ["Frederick", 51069],
        ["Giles", 51071],
        ["Gloucester", 51073],
        ["Goochland", 51075],
        ["Grayson", 51077],
        ["Greene", 51079],
        ["Greensville", 51081],
        ["Halifax", 51083],
        ["Hanover", 51085],
        ["Henrico", 51087],
        ["Henry", 51089],
        ["Highland", 51091],
        ["Isle of Wight", 51093],
        ["James City", 51095],
        ["King and Queen", 51097],
        ["King George", 51099],
        ["King William", 51101],
        ["Lancaster", 51103],
        ["Lee", 51105],
        ["Loudoun", 51107],
        ["Louisa", 51109],
        ["Lunenburg", 51111],
        ["Madison", 51113],
        ["Mathews", 51115],
        ["Mecklenburg", 51117],
        ["Middlesex", 51119],
        ["Montgomery", 51121],
        ["Nelson", 51125],
        ["New Kent", 51127],
        ["Northampton", 51131],
        ["Northumberland", 51133],
        ["Nottoway", 51135],
        ["Orange", 51137],
        ["Page", 51139],
        ["Patrick", 51141],
        ["Pittsylvania", 51143],
        ["Powhatan", 51145],
        ["Prince Edward", 51147],
        ["Prince George", 51149],
        ["Prince William", 51153],
        ["Pulaski", 51155],
        ["Rappahannock", 51157],
        ["Richmond", 51159],
        ["Roanoke", 51161],
        ["Rockbridge", 51163],
        ["Rockingham", 51165],
        ["Russell", 51167],
        ["Scott", 51169],
        ["Shenandoah", 51171],
        ["Smyth", 51173],
        ["Southampton", 51175],
        ["Spotsylvania", 51177],
        ["Stafford", 51179],
        ["Surry", 51181],
        ["Sussex", 51183],
        ["Tazewell", 51185],
        ["Warren", 51187],
        ["Washington", 51191],
        ["Westmoreland", 51193],
        ["Wise", 51195],
        ["Wythe", 51197],
        ["York", 51199],
        ["Alexandria City", 51510],
        ["Bedford City", 51515],
        ["Bristol City", 51520],
        ["Buena Vista City", 51530],
        ["Charlottesville City", 51540],
        ["Chesapeake City", 51550],
        ["Clifton Forge City", 51560],
        ["Colonial Heights Cit", 51570],
        ["Covington City", 51580],
        ["Danville City", 51590],
        ["Emporia City", 51595],
        ["Fairfax City", 51600],
        ["Falls Church City", 51610],
        ["Franklin City", 51620],
        ["Fredericksburg City", 51630],
        ["Galax City", 51640],
        ["Hampton City", 51650],
        ["Harrisonburg City", 51660],
        ["Hopewell City", 51670],
        ["Lexington City", 51678],
        ["Lynchburg City", 51680],
        ["Manassas City", 51683],
        ["Manassas Park City", 51685],
        ["Martinsville City", 51690],
        ["Newport News City", 51700],
        ["Norfolk City", 51710],
        ["Norton City", 51720],
        ["Petersburg City", 51730],
        ["Poquoson City", 51735],
        ["Portsmouth City", 51740],
        ["Radford City", 51750],
        ["Richmond City", 51760],
        ["Roanoke City", 51770],
        ["Salem City", 51775],
        ["South Boston City", 51780],
        ["Staunton City", 51790],
        ["Suffolk City", 51800],
        ["Virginia Beach City", 51810],
        ["Waynesboro City", 51820],
        ["Williamsburg City", 51830],
        ["Winchester City", 51840],
    ],
    "VT": [
        ["Addison", 50001],
        ["Bennington", 50003],
        ["Caledonia", 50005],
        ["Chittenden", 50007],
        ["Essex", 50009],
        ["Franklin", 50011],
        ["Grand Isle", 50013],
        ["Lamoille", 50015],
        ["Orange", 50017],
        ["Orleans", 50019],
        ["Rutland", 50021],
        ["Washington", 50023],
        ["Windham", 50025],
        ["Windsor", 50027],
    ],
    "WA": [
        ["Adams", 53001],
        ["Asotin", 53003],
        ["Benton", 53005],
        ["Chelan", 53007],
        ["Clallam", 53009],
        ["Clark", 53011],
        ["Columbia", 53013],
        ["Cowlitz", 53015],
        ["Douglas", 53017],
        ["Ferry", 53019],
        ["Franklin", 53021],
        ["Garfield", 53023],
        ["Grant", 53025],
        ["Grays Harbor", 53027],
        ["Island", 53029],
        ["Jefferson", 53031],
        ["King", 53033],
        ["Kitsap", 53035],
        ["Kittitas", 53037],
        ["Klickitat", 53039],
        ["Lewis", 53041],
        ["Lincoln", 53043],
        ["Mason", 53045],
        ["Okanogan", 53047],
        ["Pacific", 53049],
        ["Pend Oreille", 53051],
        ["Pierce", 53053],
        ["San Juan", 53055],
        ["Skagit", 53057],
        ["Skamania", 53059],
        ["Snohomish", 53061],
        ["Spokane", 53063],
        ["Stevens", 53065],
        ["Thurston", 53067],
        ["Wahkiakum", 53069],
        ["Walla Walla", 53071],
        ["Whatcom", 53073],
        ["Whitman", 53075],
        ["Yakima", 53077],
    ],
    "WI": [
        ["Adams", 55001],
        ["Ashland", 55003],
        ["Barron", 55005],
        ["Bayfield", 55007],
        ["Brown", 55009],
        ["Buffalo", 55011],
        ["Burnett", 55013],
        ["Calumet", 55015],
        ["Chippewa", 55017],
        ["Clark", 55019],
        ["Columbia", 55021],
        ["Crawford", 55023],
        ["Dane", 55025],
        ["Dodge", 55027],
        ["Door", 55029],
        ["Douglas", 55031],
        ["Dunn", 55033],
        ["Eau Claire", 55035],
        ["Florence", 55037],
        ["Fond du Lac", 55039],
        ["Forest", 55041],
        ["Grant", 55043],
        ["Green", 55045],
        ["Green Lake", 55047],
        ["Iowa", 55049],
        ["Iron", 55051],
        ["Jackson", 55053],
        ["Jefferson", 55055],
        ["Juneau", 55057],
        ["Kenosha", 55059],
        ["Kewaunee", 55061],
        ["La Crosse", 55063],
        ["Lafayette", 55065],
        ["Langlade", 55067],
        ["Lincoln", 55069],
        ["Manitowoc", 55071],
        ["Marathon", 55073],
        ["Marinette", 55075],
        ["Marquette", 55077],
        ["Menominee", 55078],
        ["Milwaukee", 55079],
        ["Monroe", 55081],
        ["Oconto", 55083],
        ["Oneida", 55085],
        ["Outagamie", 55087],
        ["Ozaukee", 55089],
        ["Pepin", 55091],
        ["Pierce", 55093],
        ["Polk", 55095],
        ["Portage", 55097],
        ["Price", 55099],
        ["Racine", 55101],
        ["Richland", 55103],
        ["Rock", 55105],
        ["Rusk", 55107],
        ["St Croix", 55109],
        ["Sauk", 55111],
        ["Sawyer", 55113],
        ["Shawano", 55115],
        ["Sheboygan", 55117],
        ["Taylor", 55119],
        ["Trempealeau", 55121],
        ["Vernon", 55123],
        ["Vilas", 55125],
        ["Walworth", 55127],
        ["Washburn", 55129],
        ["Washington", 55131],
        ["Waukesha", 55133],
        ["Waupaca", 55135],
        ["Waushara", 55137],
        ["Winnebago", 55139],
        ["Wood", 55141],
    ],
    "WV": [
        ["Barbour", 54001],
        ["Berkeley", 54003],
        ["Boone", 54005],
        ["Braxton", 54007],
        ["Brooke", 54009],
        ["Cabell", 54011],
        ["Calhoun", 54013],
        ["Clay", 54015],
        ["Doddridge", 54017],
        ["Fayette", 54019],
        ["Gilmer", 54021],
        ["Grant", 54023],
        ["Greenbrier", 54025],
        ["Hampshire", 54027],
        ["Hancock", 54029],
        ["Hardy", 54031],
        ["Harrison", 54033],
        ["Jackson", 54035],
        ["Jefferson", 54037],
        ["Kanawha", 54039],
        ["Lewis", 54041],
        ["Lincoln", 54043],
        ["Logan", 54045],
        ["McDowell", 54047],
        ["Marion", 54049],
        ["Marshall", 54051],
        ["Mason", 54053],
        ["Mercer", 54055],
        ["Mineral", 54057],
        ["Mingo", 54059],
        ["Monongalia", 54061],
        ["Monroe", 54063],
        ["Morgan", 54065],
        ["Nicholas", 54067],
        ["Ohio", 54069],
        ["Pendleton", 54071],
        ["Pleasants", 54073],
        ["Pocahontas", 54075],
        ["Preston", 54077],
        ["Putnam", 54079],
        ["Raleigh", 54081],
        ["Randolph", 54083],
        ["Ritchie", 54085],
        ["Roane", 54087],
        ["Summers", 54089],
        ["Taylor", 54091],
        ["Tucker", 54093],
        ["Tyler", 54095],
        ["Upshur", 54097],
        ["Wayne", 54099],
        ["Webster", 54101],
        ["Wetzel", 54103],
        ["Wirt", 54105],
        ["Wood", 54107],
        ["Wyoming", 54109],
    ],
    "WY": [
        ["Albany", 56001],
        ["Big Horn", 56003],
        ["Campbell", 56005],
        ["Carbon", 56007],
        ["Converse", 56009],
        ["Crook", 56011],
        ["Fremont", 56013],
        ["Goshen", 56015],
        ["Hot Springs", 56017],
        ["Johnson", 56019],
        ["Laramie", 56021],
        ["Lincoln", 56023],
        ["Natrona", 56025],
        ["Niobrara", 56027],
        ["Park", 56029],
        ["Platte", 56031],
        ["Sheridan", 56033],
        ["Sublette", 56035],
        ["Sweetwater", 56037],
        ["Teton", 56039],
        ["Uinta", 56041],
        ["Washakie", 56043],
        ["Weston", 56045],
    ]
}
