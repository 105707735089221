export const UPLOAD_DATA_ACTION = "Upload Data";
export const ASSIGN_USERS_ACTION = "Assign Users";
export const EXPORT_TABLE_ACTION = "Export";
export const UNTRACKED_EVIDENCING = "Untracked";

export const CLIENT_SPECIFICITY = "Client";
export const FIELD_SPECIFICITY = "Field";
export const FARM_SPECIFICITY = "Farm";

export const EVIDENCING_REMOVAL = "remove";
export const EVIDENCING_CREATION = "create";

export const LOADING_SUCCESS = 0;
export const LOADING_FAILURE = 1;
export const LOADING_PROCESSING = 2;

export const ACTIVE_EVIDENCING_GROUP = 'activeEvidencingGroup';
export const EVIDENCING_GROUP_LIST = 'evidencingGroupList';
export const DELETION = 'deletion';

export const EVIDENCING_MAP = 'map';
export const EVIDENCING_TABLE = 'table';
export const EVIDENCING_VALIDATION = 'validation';
export const EVIDENCING_DASHBOARD = 'dashboard';

export const EVIDENCING_BY_PRACTICE = 'practice';
export const EVIDENCING_BY_FIELDCROP = 'fieldcrop';

// not ideal, but for now we're hard coding date deadlines for evidencing
// date editing is possible, but configuring on creation is not scoped out yet
// dates confirmed w/ ariah, referenced here as of June 13 2024
// https://docs.google.com/spreadsheets/d/1apL1gcBwPZIDwxHSd8nA0c9WD0LOVGKoywnvPPcgmEg/edit?gid=0#gid=0
export const EVIDENCING_DATE_RULES = {
    4: new Date('11/05/24').toLocaleDateString(), // corn
    5: new Date('11/05/24').toLocaleDateString(), // soybeans
    6: new Date('11/01/24').toLocaleDateString(), // rice
    7: new Date('11/05/24').toLocaleDateString(), // wheat
    30: new Date('08/15/24').toLocaleDateString(), // winter wheat
    14: new Date('10/05/24').toLocaleDateString(), // oats
    22: new Date('10/05/24').toLocaleDateString(), // barley
    // sugarbeets?
}

export const EVIDENCING_SOURCES = {
    CROP_SCAPE: "crop_scape",
    CROP_PLAN_UPLOAD: "crop_plan_upload",
    NUTRIENT_PLAN_UPLOAD: "nutrient_plan_upload",
    CROP_AND_NUTRIENT_PLAN: "crop_and_nutrient_plan",
    CROPFORCE_WIZARD: "cropforce_wizard",
    CROPFORCE_UPLOAD: "cropforce_upload",
    WORK_ORDER_UPLOAD: "work_order_upload",
    INSURANCE_DOCUMENTATION: "insurance_documentation",
    IMAGE_CAPTURE: "image_capture",
    MANUAL_ATTESTATION: "manual_attestation",
    MACHINE_DATA_JD: "machine_data_jd",
    PLANET_LABS: "planet_labs",
    SHAPEFILE: "shapefile",
    OTHER: "other"
}

export const EVIDENCING_SOURCE_DETAILS = {
    ARTIFACT: "artifact",
    SATELLITE: "satellite",
    MANUAL_DATA: "manual_data",
    MACHINE_DATA: "machine_data",
    FIELD_IMAGE: "field_image",
    PUBLIC_DATABASE: "public_database",
    PRIVATE_DATABASE: "private_database"
}

export const EVIDENCING_STATUSES = {
    UNSUBMITTED: "unsubmitted",
    SUBMITTED: "submitted",
    VALIDATED: "validated",
}

export const REVIEW_ACCEPTED = "accepted";
export const REVIEW_REJECTED = "rejected";
export const NEEDS_REVIEW = "needs_review";
export const REVIEW_MESSAGE = "message";
