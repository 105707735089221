<template>
  <ArvaModal
    name="carbonReadyFieldModal"
    title="Carbon Ready Field Enrollment"
    :wide="true"
    :width="1000"
    @close-modal="handleCloseModal"
    style="z-index: 10000"
  >
    <div v-if="hasEnrolled" class="zero-state span-text">
      <span class="font-weight-bold">THANK YOU</span>
      <span>
        for enrolling fields into the Arva Carbon Ready Program. <br />
        Together we will improve grower bottom lines with improved soil health
        and crop yields.<br />
      </span>

      <div v-if="downloadUrl">
        <span>
          <span class="font-weight-bold">NEXT STEP</span> - Please download and
          sign the Grower Contract, then email the signed agreement to
          <a href="mailto:contracts@arvaintelligence.com"
            >contracts@arvaintelligence.com</a
          >
          . If necessary, Arva will review the contract and confirm your Carbon
          Ready enrollment status. Someone from Arva Sales and Support will also
          follow up with a contact to see if you have any other questions.
        </span>
        <br />
        <a :href="downloadUrl" target="_blank">
          <i class="fa fa-download" /> Download Agreement
        </a>
      </div>
    </div>

    <div v-else-if="hasError">
      <span class="error-message ml-5 p-1"> Failed To Enroll Fields: </span>
      <div
        class="error-message ml-5 p-1"
        v-for="message in errorMessage"
        :key="message"
      >
        <li>{{ message }}</li>
      </div>
    </div>

    <div v-else>
      <div v-if="processingEnrollment" class="zero-state span-text">
        <div class="spinner-border spinner-border-sm ml-1" role="status"></div>
        <span>
          Processing Field(s) Enrollment. Please wait until this process is
          complete!
        </span>
      </div>

      <div v-else-if="!autoApproveEnrollment" class="zero-state span-text">
        <div class="row ml-9">
          <div class="col">
            <span class="default-input-title font-weight-bold"
              >Please Enter Grower Name:
            </span>
            <v-tooltip top max-width="300"
              ><template v-slot:activator="{ on, attrs }"
                ><v-icon v-bind="attrs" v-on="on"
                  >mdi-information</v-icon
                ></template
              ><span>Fill in the box to the right with a grower full name.</span
            ></v-tooltip>
          </div>

          <div class="col">
            <div class="input-group">
              <input type="text" class="fieldInput" v-model.trim="growerName" />
            </div>
          </div>
        </div>

        <div class="row ml-9">
          <div class="col">
            <span class="default-input-title font-weight-bold"
              >Please Enter Enrollment Name:
            </span>
            <i
              class="fa fa-info-circle"
              data-toggle="tooltip"
              data-placement="top"
              title="This Enrollment Name will be used on the Manage Emrollments page."
            />
          </div>

          <div class="col">
            <div class="input-group">
              <input
                type="text"
                class="fieldInput"
                v-model.trim="enrollmentName"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <ArvaModalSaveFooter
            confirmText="Enroll"
            cancelText="Cancel"
            @on-cancel="handleCloseModal"
            @on-confirm="handleEnrollment"
          />
        </div>
      </div>

      <div v-else>
        <div class="row">
          <div class="col-2 ml-5 mt-5 p-3">
            <div class="row">
              <v-stepper v-model="step" non-linear>
                <v-stepper-header>
                  <v-stepper-step
                    step="1"
                    editable
                    class="customizeStepper"
                    @click="updateCurrentStep('Acceptance')"
                  >
                    <span> Acceptance </span>
                  </v-stepper-step>
                </v-stepper-header>
              </v-stepper>
            </div>

            <div class="row">
              <span class="font-weight-bold"> Review Grower Contract </span>
            </div>

            <div class="row">
              <v-stepper v-model="step" non-linear>
                <v-stepper-header>
                  <v-stepper-step
                    editable
                    step=""
                    class="customizeStepper"
                    @click="updateCurrentStep('Overall Agreement')"
                  >
                    <span> Overall Agreement </span>
                  </v-stepper-step>
                </v-stepper-header>
              </v-stepper>
            </div>

            <div class="row">
              <v-stepper v-model="step" non-linear>
                <v-stepper-header>
                  <v-stepper-step
                    step=""
                    editable
                    class="customizeStepper"
                    @click="updateCurrentStep('Practice Start Date & Terms')"
                  >
                    <span> Practice Start Date & Terms </span>
                  </v-stepper-step>
                </v-stepper-header>
              </v-stepper>
            </div>

            <div class="row">
              <v-stepper v-model="step" non-linear>
                <v-stepper-header>
                  <v-stepper-step
                    step=""
                    editable
                    class="customizeStepper"
                    @click="updateCurrentStep('Payment Terms')"
                  >
                    <span> Payment Terms </span>
                  </v-stepper-step>
                </v-stepper-header>
              </v-stepper>
            </div>

            <div class="row">
              <v-stepper v-model="step" non-linear>
                <v-stepper-header>
                  <v-stepper-step
                    step=""
                    editable
                    class="customizeStepper"
                    @click="updateCurrentStep('Regenerative Practices')"
                  >
                    <span> Regenerative Practices </span>
                  </v-stepper-step>
                </v-stepper-header>
              </v-stepper>
            </div>
          </div>

          <div class="col">
            <div v-if="atAgreementStep">
              <div class="row">
                <div class="col-12">
                  <div class="agreement">
                    <div class="row">
                      <div class="col-2">
                        <div class="logo" />
                      </div>
                      <div class="col">
                        <h1>Grower Contract Agreement</h1>
                      </div>
                    </div>

                    <span>
                      These Arva Carbon Ready Offset Program Terms are entered
                      into by and between Arva Intelligence Corp. (“Arva”) and
                      <strong>
                        {{ user.first_name }} {{ user.last_name }}
                      </strong>
                      (the “Customer”, together with Arva, the “Parties”, and
                      each a “Party”). These Arva Carbon Ready Offset Program
                      Terms, together with all attached Addenda and Exhibits,
                      are collectively referred to as the “Agreement”. The
                      Agreement constitutes the entire agreement between the
                      Parties and supersedes and replaces all prior agreements
                      relating to the subject matter of this Agreement and may
                      only be amended by written amendment of the Parties.
                      Capitalized terms have the meaning ascribed in the
                      Agreement. The “Effective Date” of this Agreement is
                      <strong> {{ newEnrollmentStartDate | date }} </strong>.
                      <br /><br />
                      Arva will use commercially reasonable efforts to provide
                      Customer with all mutually agreed Support (as hereinafter
                      defined) to generate, register and monetize carbon offset
                      credits, other environmental offset credits,
                      sustainability certifications, and/or the benefit of all
                      of the environmental, carbon sequestration, greenhouse gas
                      (“GHG”) reduction, and climate benefits, reductions,
                      attributes, and rights (collectively referred to as
                      “Environmental Assets”) from regenerative agricultural
                      practices on Customer’s Enrolled Acres (as hereinafter
                      defined) (“Regen Practices”). Regen Practices involve both
                      reductions in GHG emissions and improved soil organic
                      carbon (“SOC”) storage. The “Arva Platform” allows the
                      Customer to evaluate its carbon offset potential, enroll
                      fields, upload data, and monitor Environmental Asset
                      generation. Once Environmental Assets are generated, Arva
                      will assist Customer with registering the Customer
                      generated Environmental Assets and use commercially
                      reasonable efforts to sell the Customer generated
                      Environmental Assets, either in one-off sales to third
                      parties or as part of larger sales transactions alongside
                      the Environmental Assets being sold for Arva and/or its
                      other customers.
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div v-else-if="atPracticeStartDateAndTerm">
              <div class="row">
                <div class="col-12">
                  <div class="agreement">
                    <div class="row">
                      <div class="col-2">
                        <div class="logo" />
                      </div>
                      <div class="col">
                        <h1>Practice Start Date</h1>
                      </div>
                    </div>
                    <span>
                      The “Practice Start Date” is the earliest date on which
                      Customer begins/began implementing Regen Practices on the
                      Enrolled Acres in accordance with this Agreement;
                      provided, however, that such date shall not, in any case
                      be any earlier than three (3) years prior to the Effective
                      Date.
                    </span>
                    <br /><br />
                    <h1>Term</h1>
                    <span>
                      This Agreement has an “Initial Term” of five (5) years and
                      will be effective beginning on the later of the Practice
                      Start Date and the Effective Date. The “Term” refers to
                      the duration of this Agreement and will begin on the
                      Effective Date and end on the expiry of the Initial Term
                      as extended. The Term will automatically be renewed for
                      additional periods of five (5) years each unless either
                      Party gives six (6) months’ written notice prior to the
                      end of the then-current Term, as may be extended, of its
                      intent not to continue. Customer obligations under this
                      Agreement that result from the creation of Environmental
                      Assets shall endure beyond the Term of this Agreement
                      until completed in accordance with the requirements of the
                      Regen Practice(s).
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div v-else-if="atPaymentTerms">
              <div class="row">
                <div class="col-12">
                  <div class="agreement">
                    <div class="row">
                      <div class="col-2">
                        <div class="logo" />
                      </div>
                      <div class="col">
                        <h1>Enrolled Acreage, Net Proceed Payments & Timing</h1>
                      </div>
                    </div>
                    <span>
                      Each of the Enrolled Acres, Net Proceed Payments and
                      Payment Timing shall be as set forth in
                      <i class="fa" /><a
                        class="pdf-link"
                        :href="pathToExhibitA"
                        target="blank"
                        >Exhibit A</a
                      >
                      of this Agreement, which is incorporated by reference and
                      attached hereto.
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div v-else-if="atRegenerativeAgriculturePractices">
              <div class="row">
                <div class="col-12">
                  <div class="agreement">
                    <div class="row">
                      <div class="col-2">
                        <div class="logo" />
                      </div>
                      <div class="col">
                        <h1>Regenerative Agriculture Practices</h1>
                      </div>
                    </div>
                    <span>
                      Throughout the Term, Arva shall be Customer’s sole and
                      exclusive provider of Environmental Asset creation,
                      services and support (together, “Support”) on the Enrolled
                      Acres. In order to be credited for any Regen Practice
                      change, Customer shall promptly notify Arva of any Regen
                      Practice adopted that reduces GHG emissions and/or
                      improves soil organic carbon (“SOC”) storage, such as, but
                      not limited to:
                    </span>
                    <ul>
                      <li>Reducing fertilizer application</li>
                      <li>Improving water management/irrigation</li>
                      <li>Reducing tillage/improve residue management</li>
                      <li>Improving crop planting and harvest</li>
                      <li>Improving grazing practices management</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div v-else-if="atCustomerAcceptance">
              <div class="row">
                <div class="col-12">
                  <div class="agreement">
                    <div class="row">
                      <div class="col-2">
                        <div class="logo" />
                      </div>
                      <div class="col">
                        <h1>Grower Contract</h1>
                      </div>
                    </div>

                    <span>
                      To receive your Environmental Asset payments for
                      participating in the agreed practices on your enrolled
                      fields, click on the ENROLL button below then download and
                      sign the corresponding PDF agreement(s). Once signed
                      please email the fully executed agreement(s) to;
                      <strong> contracts@arvaintelligence.com </strong>
                      or call your Arva representative to make other
                      arrangements.
                      <br /><br />
                      Note that Arva can only make payments when the changes in
                      practice are validated and Arva receives the executed PDF
                      agreements.
                      <br /><br />
                      Thank you for your participation in keeping our planet
                      sustainable!
                      <br /><br />
                      IN WITNESS WHEREOF, the Parties have caused this Agreement
                      to be executed by their duly authorized representatives to
                      be effective as of the Effective Date.
                      <br /><br />
                      Arva Intelligence Corp.
                    </span>
                  </div>
                </div>
              </div>

              <div class="row ml-9">
                <div class="col">
                  <span class="default-input-title font-weight-bold"
                    >Please Enter Grower Name:
                  </span>
                  <v-tooltip top max-width="300"
                    ><template v-slot:activator="{ on, attrs }"
                      ><v-icon v-bind="attrs" v-on="on"
                        >mdi-information</v-icon
                      ></template
                    ><span>Fill in the box to the right with a grower full name.</span
                  ></v-tooltip>
                </div>

                <div class="col">
                  <div class="input-group">
                    <input
                      type="text"
                      class="fieldInput"
                      v-model.trim="growerName"
                    />
                  </div>
                </div>
              </div>

              <div class="row ml-9">
                <div class="col">
                  <span class="default-input-title font-weight-bold"
                    >Please Enter Enrollment Name:
                  </span>
                  <!-- <i
                    class="fa fa-info-circle"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Fill in the box to the right with a name you choose to label and track this Grower Contract. ie."
                  /> -->
                  <v-tooltip top max-width="300"
                    ><template v-slot:activator="{ on, attrs }"
                      ><v-icon v-bind="attrs" v-on="on"
                        >mdi-information</v-icon
                      ></template
                    ><span
                      >Fill in the box to the right with a name you choose to
                      label and track this Grower Contract. ie.
                      {{ enrollmentNamePlaceholder }}</span
                    ></v-tooltip
                  >
                </div>

                <div class="col">
                  <div class="input-group">
                    <input
                      type="text"
                      class="fieldInput"
                      v-model.trim="enrollmentName"
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <ArvaModalSaveFooter
                  confirmText="Enroll"
                  cancelText="Cancel"
                  @on-cancel="handleCloseModal"
                  @on-confirm="handleEnrollment"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ArvaModal>
</template>

<script>
import _ from "lodash"
import moment from "moment"

import { mapGetters } from "vuex"
import { Filter } from "@/store/modules"
import CarbonReadyAPI from "@/api/CarbonReadyAPI"
import ArvaModal from "@/components/modals/ArvaModal"
import ArvaModalSaveFooter from "@/components/modals/ArvaModalSaveFooter"

export default {
  name: "CarbonReadyFieldModal",
  props: [
    "user",
    "fields",
    "newEnrollmentStartDate",
    "totalAcres",
    "autoApproveEnrollment",
  ],
  components: {
    ArvaModal,
    ArvaModalSaveFooter,
  },

  data() {
    return {
      pathToAddendum: "./assets/pdf/Addendum.pdf",
      pathToExhibitA: "./assets/pdf/Exhibit_A.pdf",
      selectStartDate: false,
      minDate: new Date(2016, 6, 1),
      maxDate: null,
      isScroll: false,
      hasEnrolled: false,
      hasError: false,
      errorMessage: null,
      processingEnrollment: false,
      downloadUrl: null,
      username: null,
      step: "1",
      enrollmentName: null,
      growerName: null,
      currentStep: "Acceptance",
    }
  },

  computed: {
    ...mapGetters({
      farms: Filter.Getters.getFarms,
      clients: Filter.Getters.getClients,
      getFields: Filter.Getters.getFields,
    }),
    mobilePhoneNumber() {
      return this.convertPhoneFormat(this.user.mobile_phone_number)
    },
    userDateJoined() {
      return this.convertDate(this.user.date_joined)
    },
    atCustomerAcceptance() {
      return this.currentStep === "Acceptance"
    },
    atAgreementStep() {
      return this.currentStep === "Overall Agreement"
    },
    atPracticeStartDateAndTerm() {
      return this.currentStep === "Practice Start Date & Terms"
    },
    atPaymentTerms() {
      return this.currentStep === "Payment Terms"
    },
    atRegenerativeAgriculturePractices() {
      return this.currentStep === "Regenerative Practices"
    },
    enrollmentNamePlaceholder() {
      const selectedFarmId = this.getFields.find(
        field => (field.id = Object.keys(this.fields)[0])
      ).farmId
      const selectedFarmObj = this.farms.find(farm => farm.id == selectedFarmId)
      const selectClientObj = this.clients.find(
        client => client.id == selectedFarmObj.clientId
      )

      return (
        selectClientObj.name +
        "_" +
        moment(this.newEnrollmentStartDate).format("YYYY-MM-DD") +
        "_" +
        Object.keys(this.fields).length
      )
    },
  },

  methods: {
    updateCurrentStep(step) {
      this.currentStep = step
    },
    handleCloseModal() {
      this.$emit("close-modal")
    },
    openStartDate() {
      this.selectStartDate = true
      this.maxDate = new Date(this.newEnrollmentStartDate)
    },
    closeStartDate() {
      this.selectStartDate = false
    },
    async handleEnrollment() {
      if (!this.enrollmentName) {
        this.enrollmentName = this.enrollmentNamePlaceholder
      }

      this.processingEnrollment = true
      await CarbonReadyAPI.enrollFields({
        enrollment_start_date: moment(this.newEnrollmentStartDate).format(
          "YYYY-MM-DD"
        ),
        fields: this.fields,
        grower_name: this.growerName,
        enrollment_name: this.enrollmentName,
        autoApproveEnrollment: this.autoApproveEnrollment,
      })
        .then(response => {
          this.downloadUrl = response.data[0].enroll_agreement_pdf
          this.processingEnrollment = false
          this.hasEnrolled = true
          this.$emit("success")
        })
        .catch(error => {
          this.processingEnrollment = false
          this.hasError = true
          this.errorMessage = error.response.data
        })
    },
    convertPhoneFormat(phoneNumber) {
      var phoneNumberFormatted = phoneNumber
      if (phoneNumber) {
        if (phoneNumber.length === 10) {
          phoneNumberFormatted =
            "(" +
            phoneNumber.substring(0, 3) +
            ")-" +
            phoneNumber.substring(3, 6) +
            " " +
            phoneNumber.substring(6)
        }
      }
      return phoneNumberFormatted
    },
    convertDate(date) {
      var dateFormatted = date
      if (date) {
        if (date.length >= 10) {
          dateFormatted = date.substring(0, 10)
        }
      }
      return dateFormatted
    },
  },

  created() {
    this.handleEnrollment = _.throttle(this.handleEnrollment, 4000)
    this.enrollmentName = this.enrollmentNamePlaceholder
  },
}
</script>

<style scoped>
.info-text {
  margin: 20px 0px;
  font-size: 15px;
  text-align: center;
}

.span-text {
  padding: 20px;
  font-size: 15px;
  text-align: center;
}

.order-row {
  padding: 0px 140px;
  font-weight: 500;
}

.order-row > div {
  margin: 5px 0px;
}

.agreement {
  padding: 20px 35px;
  background: #f9f9f9;
  border: 1pt solid #f0f0f0;
  max-height: 550px;
  overflow-y: scroll;
}

.agreement .logo {
  background-image: url("/assets/images/logos/arva-logo.png");
  background-size: cover;
  height: 40px;
  width: 120px;
  margin-left: -5px;
  margin-bottom: 12px;
}

.confirm {
  margin-left: 3%;
}

.pdf-link {
  font-weight: 500;
}

.importantText {
  color: green;
}

h1,
h3,
h5 {
  font-weight: bold;
}

.headerText {
  font-weight: bold;
  font-size: 15px;
  display: inline-block;
  width: 105px;
}

.normalText {
  padding-left: 30px;
}

.customizeStepper {
  min-width: 180px;
}

.fieldInput {
  border: 1px solid #050607;
  border-radius: 2px;
  box-shadow: none;
  height: 30px;
  width: 300px;
  font-size: 14px;
  color: #0a0a0d;
  transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
}

.carbonReadyFieldModal {
  width: 1000px;
}

.error-message {
  font-size: 15px;
  font-weight: 500;
  color: red;
}
</style>
