import API from "./API"

const defaultsEndpoint = "/defaults/"
const fieldSettingsEndpoint = "/field-settings/"
const newCarbonSettingsEndpoint = "/new-carbon-settings/"

const list = () => API.get(defaultsEndpoint)

const listFieldSettings = () => API.get(fieldSettingsEndpoint)

const create = payload => API.post(defaultsEndpoint, payload)

const update = (endpoint, defaultsId, payload) => {
  for (const key in payload) {
    if (payload[key] === "") payload[key] = null
  }
  return API.put(`/${endpoint}/${defaultsId}/`, payload)
}

const deleteSettingsTemplate = defaultsId =>
  API.delete(`${defaultsEndpoint}${defaultsId}/`)

const applyFieldDefaults = ({ id, field_ids }) =>
  API.put(`${defaultsEndpoint}${id}/apply_fields_defaults/`, { field_ids })

const getNewFieldSettings = () => API.get(newCarbonSettingsEndpoint)

const getCarbonSettingsByField = payload =>
  API.post(newCarbonSettingsEndpoint, payload, { timeout: 240000 })

const getCarbonPlantingsByField = payload =>
  API.post("/planting-carbon-settings/", payload, { timeout: 240000 })

const getCarbonOpsByField = payload =>
  API.post("/ops-carbon-settings/", payload, { timeout: 240000 })

const getCarbonFertByField = payload =>
  API.post("/fert-carbon-settings/", payload, { timeout: 240000 })

const updateFieldSetting = (endpoint, defaultsId, payload, queryParams) => {
  return API.put(`/${endpoint}/${defaultsId}/`, payload, {
    params: queryParams,
  })
}

const getCarbonDataCompletion = payload =>
  API.post("/cready-data-completion/", payload)

const updateFieldCropByFieldYear = payload =>
  API.post("/field-crop-update/", payload)

const createDataVersion = payload => API.post("/data-version/", payload)

const exportPracticesCSV = payload => API.post(`export-practice-csv/`, payload)

const speechToPractices = payload => API.post(`speech-to-practice/`, payload)


const getPracticeChoices = () => API.get("/practices-schema/")

const requestPracticeChoice = (payload) => API.post('/practice-choice-request/', payload)

const getFsaFieldData = () => API.get('/fsa-field-data/')

const saveFsaFieldData = (payload) => API.post('/fsa-field-data/', payload)
const updateFsaFieldData = (id, payload) => API.put(`/fsa-field-data/${id}/`, payload)
const deleteFsaFieldData = (id) => API.delete(`/fsa-field-data/${id}/`)

const listConservationProgramData = () => API.get('/conservation-programs/')
const updateConservationProgramData = (payload) => API.post(`/conservation-programs/`, payload)

const searchFert = (searchString) => API.get(`/fert-search/?search_string=${searchString}`)
export default {
  list,
  listFieldSettings,
  create,
  update,
  deleteSettingsTemplate,
  applyFieldDefaults,
  getNewFieldSettings,
  getCarbonSettingsByField,
  updateFieldSetting,
  getCarbonPlantingsByField,
  getCarbonOpsByField,
  getCarbonFertByField,
  getCarbonDataCompletion,
  updateFieldCropByFieldYear,
  createDataVersion,
  exportPracticesCSV,
  getPracticeChoices,
  requestPracticeChoice,
  speechToPractices,
  getFsaFieldData,
  saveFsaFieldData,
  updateFsaFieldData,
  deleteFsaFieldData,
  listConservationProgramData,
  updateConservationProgramData,
  searchFert
}
