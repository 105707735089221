<template>
  <ArvaModal
    name="spreadsheetExtensionModal"
    title="Model Settings"
    @close-modal="handleClose"
  >
    <div v-if="field" class="px-3 py-2">
      <div class="row">
        <div class="col-3 pr-0">
          <img :src="field.boundary_image" style="width: 100px" />
        </div>
        <div class="col-9 pl-0">
          <v-simple-table>
            <tr>
              <td class="ghg text-left text-h4 text--primary">
                <h5>Field:</h5>
              </td>
              <h5>
                <td class="ghg text-left text-h4 text--primary">
                  {{ field.name }}
                </td>
              </h5>
            </tr>

            <!--<tr>
              <td class="ghg text-left">Status:</td>
              <td class="ghg text-left">
                <span v-if="field.status === 'complete'" class="badge badge-pill badge-success">{{ field.status | capitalize }}</span>
                <span v-if="field.status === 'processing'" class="badge badge-pill badge-warning">{{ field.status | capitalize }}</span>
              </td>
            </tr>-->
          </v-simple-table>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <h5>Set Additional Year and Land Use</h5>
          <v-select-old
            class="mx-2 tillage-select"
            v-model="selectedYear"
            :value="selectedYear"
            :options="eligibleYears"
            placeholder="Select Year"
            label="label"
            width="100"
          ></v-select-old>
          <v-select-old
            v-if="selectedYear"
            class="mx-2 tillage-select"
            v-model="selectedCrop"
            label="display_name"
            placeholder="Select Crop"
            :reduce="o => o.value"
            :options="cropChoices"
            width="100"
          ></v-select-old>
        </div>
      </div>
      <div class="col d-flex">
        <v-btn
          class="ml-auto"
          type="submit"
          @click="submit()"
          :loading="isLoading"
        >
          Add Additional Crop Year
        </v-btn>
      </div>
      <p v-if="showWarning" class="warning-text">
        Please select valid year and landcover to submit
      </p>
    </div>
  </ArvaModal>
</template>

<script>
import ArvaModal from "./ArvaModal"
import CarbonReadyAPI from "@/api/CarbonReadyAPI"
import { CROP_DROPDOWN_OPTIONS } from "@/constants"

export default {
  name: "SpreadsheetExtensionModal",
  props: ["field", "years"],
  components: {
    ArvaModal,
  },
  data() {
    return {
      cropChoices: CROP_DROPDOWN_OPTIONS,
      selectedCrop: null,
      selectedYear: null,
      showWarning: false,
      isLoading: false,
    }
  },

  computed: {
    eligibleYears() {
      let currentYear = new Date().getFullYear()
      let potentialYears = this.range(currentYear - 5, currentYear + 2, 1)
      return potentialYears.filter(x => !this.years.includes(x))
    },
  },

  methods: {
    range(start, stop, step) {
      var a = [start],
        b = start
      while (b < stop) {
        a.push((b += step || 1))
      }
      return a
    },

    handleClose() {
      this.$emit("close-template-modal")
    },

    async submit() {
      if (this.selectedCrop && this.selectedYear) {
        this.isLoading = true
        let payload = {
          year: this.selectedYear,
          arva_crop: this.selectedCrop,
          field_id: this.field.id,
        }

        await CarbonReadyAPI.postAdditionalSpreadsheetYear(payload).then(
          response => {
            this.showWarning = false
            this.$emit("submit-year-append")
            this.$emit("close-template-modal")
            this.isLoading = false
            return response
          }
        )
      } else {
        this.showWarning = true
      }
    },
  },
}
</script>

<style scoped>
.badge {
  font-size: 12px;
}

.check-spacer {
  padding-top: 42px;
}

.confirm-text {
  font-size: 15px;
  font-weight: 500;
  padding: 30px 30px;
}

.warning-text {
  font-size: 15px;
  font-weight: 500;
  padding: 30px 30px;
  color: red;
}

.tillage-select {
  max-width: 225px;
}
</style>
