<template>
  <ArvaModal
    name="editUserModal"
    title="Edit User"
    @close-modal="handleCloseModal"
  >
    <b-alert v-model="showErrorAlert" variant="danger" dismissible>
      <span> Failed To Edit User:</span>
      <div
        class="error-message ml-5 p-1"
        v-for="message in errorMessage"
        :key="message"
      >
        <li>{{ message }}</li>
      </div>
    </b-alert>

    <div class="row ml-3 mt-3">
      <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
        <span class="default-input-title">First Name</span>
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            v-model.trim="firstName"
          />
        </div>
      </div>

      <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
        <span class="default-input-title">Last Name</span>
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            v-model.trim="lastName"
          />
        </div>
      </div>

      <div class="mt-2 col-lg-4 col-md-4 col-sm-6 col-xs-12">
        <v-switch
              v-model="selectedStatus"
              :label="`${selectedStatus? 'Active':'Inactive'}`"
              inset
            ></v-switch>
      </div>
    </div>

    <div class="row ml-3">
      <div class="col-lg-11 col-md-4 col-sm-6 col-xs-12">
        <span class="default-input-title">Email</span>
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            v-model.trim="assignedOrg.user.email"
            readonly
          />
        </div>
      </div>
    </div>

    <div class="row ml-3">
      <div class="col-lg-11 col-md-4 col-sm-6 col-xs-12">
        <span class="default-input-title">Corporation</span>
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            v-model.trim="assignedOrg.assigned_org[0].corporation.name"
            readonly
          />
        </div>
      </div>
    </div>

    <div class="row ml-1">
      <div class="col-lg-12">
      <v-simple-table class="assignedOrgTable">
      <thead>
        <tr>
          <th>Organization</th>
          <th>Role</th>
          <th>
            <v-btn
              class="float-right"
              small
              @click="addAssignedOrg()"
              >Add</v-btn
            >
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="currentAssignedOrg in assignedOrg.assigned_org"
          :key="currentAssignedOrg.id"
        >
          <td width="35%">
            {{currentAssignedOrg.organization.name}}
          </td>

          <td width="30%">
            {{currentAssignedOrg.role}}
          </td>
          
          <td width="35%" class="btnCol">
            <v-btn
              small
              @click="editAssignedOrg(currentAssignedOrg)"
              >Edit</v-btn
            >

            <v-btn
              small
              class="ml-2"
              @click="removeAssignedOrg(currentAssignedOrg)"
              >Remove</v-btn
            >
          </td>
        </tr>
      </tbody>
      </v-simple-table>
      </div>
    </div>

    <div class="row ml-3">
      <ArvaModalSaveFooter
        confirmText="Save"
        cancelText="Cancel"
        @on-cancel="handleCloseModal"
        @on-confirm="confirmChanges"
      />
    </div>
  </ArvaModal>
</template>

<script>
import ArvaModal from "@/components/modals/ArvaModal"
import ArvaModalSaveFooter from "@/components/modals/ArvaModalSaveFooter"

export default {
  name: "EditUserModal",
  props: ["assignedOrg", "assignedOrgList", "organizationOptions", "adminUserId"],
  components: {
    ArvaModal,
    ArvaModalSaveFooter,
  },

  data() {
    return {
      roleOptions: ["Organization Admin", "Member"],
      statusOptions: ["Active", "Inactive"],
      firstName: this.assignedOrg.user.first_name,
      lastName: this.assignedOrg.user.last_name,
      selectedStatus: this.assignedOrg.user.is_active,
      showErrorAlert: false,
      errorMessage: [],
    }
  },

  computed: {
  },

  methods: {
    handleCloseModal() {
      this.$emit("close-modal")
    },

    confirmChanges() {
      const hasError = this.validateInputs()
      if (!hasError) {
        const payload = {
          admin_user_id: this.adminUserId,
          selected_user: this.assignedOrg.user,
          first_name: this.firstName,
          last_name: this.lastName,
          selected_status: this.selectedStatus,
        }
        this.$emit("save-changes", payload)
      }
    },

    removeAssignedOrg(selectedAssignedOrg) {
      const payload = {
        selected_user_id: this.assignedOrg.user.id,
        assigned_org: selectedAssignedOrg,
      }
      this.$emit("remove-assigned-org", payload)
    },

    editAssignedOrg(selectedAssignedOrg) {
      const payload = {
        selected_user_id: this.assignedOrg.user.id,
        assigned_org: selectedAssignedOrg
      }
      this.$emit("edit-assigned-org-modal", payload)
    },

    addAssignedOrg() {
      this.$emit("open-assigned-org-modal", this.assignedOrg.user.id)
    },

    // Validate all the inputs
    //  Return True if found at least 1 error
    //  Return False if found no error
    validateInputs() {
      this.resetError()

      if (!this.firstName) this.errorMessage.push('First Name must be filled out.')
      if (!this.lastName) this.errorMessage.push('Last Name must be filled out.')

      if (this.errorMessage.length > 0) {
        this.showErrorAlert = true
        return true
      }
      return false
    },

    resetError() {
      this.showErrorAlert = false,
      this.errorMessage = []
    },
  },
}
</script>
<style scoped>
.assignedOrgTable {
  height: 200px;
  overflow: scroll;
}

.v-text-field--box .v-input__slot,
.v-text-field--outline .v-input__slot {
  min-height: auto!important;
  display: flex!important;
  align-items: center!important;
}
.btnCol {
  text-align: right;
}
</style>
