<template>
  <div>
    <DashboardTitle
      :title="`Hybrid Placement - ${year}`"
      faClass="fa-pagelines"
      :beta="true"
    />
    <div v-if="totalYield > 0">
      <div class="row">
        <div class="col">
          <h3 class="pt-1">Summary</h3>
        </div>
      </div>

      <v-simple-table>
        <thead>
          <tr>
            <th />
            <th>Crop</th>
            <th class="text-right">Total Acres</th>
            <th class="text-right">Total Yield / Ac.</th>
            <th class="text-right">Total Yield</th>
            <th class="text-right">Revenue</th>
            <th v-if="useHybridPlacementColumns" class="text-right">
              Est. Additional Revenue w/ Optimal Hybrid Placement
            </th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="row in roiSummary" :key="row.crop">
            <td><img class="crop-icon" :src="cropIconUrls[row.crop]" /></td>
            <td>{{ row.crop | crop }}</td>
            <td class="text-right">{{ row.acreage | prettyInteger }}</td>
            <td class="text-right">
              {{ (row.yield / row.acreage) | prettyInteger }}
            </td>
            <td class="text-right">{{ row.yield | prettyInteger }}</td>
            <td class="text-right">{{ row.revenue | intCurrency }}</td>
            <td v-if="useHybridPlacementColumns" class="text-right currency">
              <span v-if="row.hybridPlacementRevenueImprovement"
                >+{{
                  row.hybridPlacementRevenueImprovement | intCurrency
                }}</span
              >
            </td>
          </tr>
          <tr class="total-row">
            <td />
            <td>Total</td>
            <td class="text-right">{{ totalAcres | prettyInteger }}</td>
            <td class="text-right">
              {{ (totalYield / totalAcres) | prettyInteger }}
            </td>
            <td class="text-right">{{ totalYield | prettyInteger }}</td>
            <td class="text-right">{{ totalRevenue | intCurrency }}</td>
            <td v-if="useHybridPlacementColumns" class="text-right currency">
              <span v-if="totalHybridPlacementRevenueImprovement"
                >+{{
                  totalHybridPlacementRevenueImprovement | intCurrency
                }}</span
              >
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <div class="row">
        <div class="col">
          <h3 class="pt-1">By Field</h3>
        </div>
      </div>

      <v-simple-table fixed-header height="calc(100vh - 470px)">
        <thead>
          <tr>
            <th />
            <th>Field</th>
            <th class="text-right">Acreage</th>
            <th>Crop</th>
            <th>Planted Variety</th>
            <th class="text-right">Measured Yield / Ac.</th>
            <th class="text-right">Revenue</th>
            <th v-if="useHybridPlacementColumns" class="text-right">
              Optimal Variety
            </th>
            <th v-if="useHybridPlacementColumns" class="text-right">
              Est. Optimal Yield / Ac.
            </th>
            <th v-if="useHybridPlacementColumns" class="text-right">
              Est. Additional Revenue
            </th>
          </tr>
        </thead>
        <tbody>
          <HybridPlacementRow
            v-for="field in fieldRows"
            :key="field.id"
            :field="field"
            :year="year"
          />
        </tbody>
      </v-simple-table>
    </div>
    <div v-else>
      <PageMissing />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import DashboardTitle from "@/components/dashboard/DashboardTitle"
import HybridPlacementRow from "@/components/hybridPlacement/HybridPlacementRow"
import { CROP_ID_TO_ICON_URL } from "@/constants"
import { Dashboard, Filter } from "@/store/modules"
import PageMissing from "@/components/misc/PageMissing"

export default {
  name: "HybridPlacementView",

  components: { DashboardTitle, HybridPlacementRow, PageMissing },

  data() {
    return {
      cropIconUrls: CROP_ID_TO_ICON_URL,
    }
  },

  computed: {
    ...mapGetters({
      activeFields: Filter.Getters.getSelectedFields,
      roiSummary: Dashboard.Getters.getRoiSummary,
    }),

    ...mapState({
      year: state => state.Organization.year,
    }),

    fieldRows() {
      return this.activeFields.filter(field => field.historicalROI[this.year])
    },

    totalAcres() {
      let totalAcres = 0
      for (const row of this.fieldRows) totalAcres += row.acreage
      return totalAcres
    },

    totalYield() {
      let totalYield = 0
      for (const row of this.roiSummary) totalYield += row.yield
      return totalYield
    },

    totalRevenue() {
      let totalRevenue = 0
      for (const row of this.roiSummary) totalRevenue += row.revenue
      return totalRevenue
    },

    totalHybridPlacementRevenueImprovement() {
      let totalRevenueImprovement = 0
      for (const row of this.roiSummary)
        totalRevenueImprovement += row.hybridPlacementRevenueImprovement
      return totalRevenueImprovement
    },
    useHybridPlacementColumns() {
      if (this.totalHybridPlacementRevenueImprovement != 0) {
        return true
      } else {
        return false
      }
    },
  },
}
</script>

<style scoped>
h3 {
  font-size: 16px;
  font-weight: 500;
  margin-top: 25px;
  margin-bottom: 10px;
}

th {
  white-space: nowrap;
}

.crop-icon {
  width: 30px;
  height: 30px;
}

.currency {
  color: #61b100;
  font-weight: bold;
}
</style>
