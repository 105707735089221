<template>
  <div class="pdf-wrapper">
    <div class="row mx-0">
      <div class="col-12 pb-0">
        <div class="title">{{ responseData.title_page.title }}</div>
        <div class="subtitle">Field</div>
      </div>
      <div class="top-logo">
        <img
          class="profile-img img-fluid mt-2"
          src="/assets/images/logos/arva-logo.png"
          alt="User"
        />
      </div>
    </div>
    <div class="row background-dim mx-0">
      <div class="col" />
    </div>
    <div class="row mx-0">
      <div class="col-6 pt-0">
        <report-info-table :dataTableObject="descriptionTable" />
      </div>
      <div class="col-6 pt-0">
        <report-info-table :dataTableObject="additionalInfoTable" />
      </div>
    </div>
    <div class="row mx-0">
      <div class="col-12 pt-0 px-0">
        <div class="map-wrapper">
          <div id="mapbox-map" class="fill-wrapper"></div>
        </div>
        <div class="control-wrapper">
          <div class="d-flex">
            <div class="control-dot"></div>
            <div class="dot-text lg-font">Control</div>
          </div>
          <div class="d-flex">
            <div class="trial-dot"></div>
            <div class="dot-text lg-font">Trial</div>
          </div>
          <div class="d-flex">
            <div class="field-dot"></div>
            <div class="dot-text lg-font">Field</div>
          </div>
        </div>
        <div class="map-footer">
          <div class="row mx-0 color-bar">
            <div class="col red-fill"></div>
            <div class="col orange-fill"></div>
            <div class="col yellow-fill"></div>
            <div class="col light-green-fill"></div>
            <div class="col bright-green-fill"></div>
            <div class="col green-fill"></div>
          </div>
        </div>
        <div class="row scale-text mx-0">
          <div class="col text-center font-weight-bold">
            {{ colorValueRanges[0] | prettyInteger }}-{{
              colorValueRanges[1] | prettyInteger
            }}
          </div>
          <div class="col text-center font-weight-bold">
            {{ colorValueRanges[1] | prettyInteger }}-{{
              colorValueRanges[2] | prettyInteger
            }}
          </div>
          <div class="col text-center font-weight-bold">
            {{ colorValueRanges[2] | prettyInteger }}-{{
              colorValueRanges[3] | prettyInteger
            }}
          </div>
          <div class="col text-center font-weight-bold">
            {{ colorValueRanges[3] | prettyInteger }}-{{
              colorValueRanges[5] | prettyInteger
            }}
          </div>
          <div class="col text-center font-weight-bold">
            {{ colorValueRanges[5] | prettyInteger }}-{{
              colorValueRanges[6] | prettyInteger
            }}
          </div>
          <div class="col text-center font-weight-bold">
            {{ colorValueRanges[6] | prettyInteger }}-{{
              colorValueRanges[8] | prettyInteger
            }}
          </div>
        </div>
      </div>
    </div>
    <div class="row mx-0 py-0 my-0">
      <div class="col-12 px-0 mx-0">
        <apexchart
          v-if="responseData"
          type="boxPlot"
          height="350"
          :options="chartOptions"
          :series="chartSeries"
        />
      </div>
    </div>
    <div class="footer d-flex align-items-center justify-content-between">
      <div class="ml-2">
        Confidential
      </div>
      <div class="mr-3">
        <i class="fa fa-copyright" /> 2021 Arva Intelligence
        <i class="fa fa-trademark" />
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash"
import { GeoJsonLayer } from "@deck.gl/layers"
import { MapboxLayer } from "@deck.gl/mapbox"
import mapboxgl from "mapbox-gl"

import { MAPBOX_TOKEN } from "@/constants/map"
import { HARVEST_YIELD_COLORS } from "@/components/map/layers"
import ReportInfoTable from "./ReportInfoTable.vue"

export default {
  components: { ReportInfoTable },
  name: "TreatmentAppliedReport",
  props: ["responseData"],
  data() {
    return {
      colorValueRanges: [],
    }
  },

  computed: {
    chartOptions() {
      if (this.responseData) {
        let dataLabels = this.responseData["all"]["boxplot"]["dataLabels"]
        let formatter_data = this.responseData["all"]["boxplot"][
          "formatter_data"
        ]
        dataLabels["formatter"] = function(
          value,
          { seriesIndex, dataPointIndex }
        ) {
          return formatter_data[seriesIndex][dataPointIndex]
        }
        return {
          chart: this.responseData["all"]["boxplot"]["chart"],
          legend: this.responseData["all"]["boxplot"]["legend"],
          title: this.responseData["all"]["boxplot"]["title"],
          plotOptions: this.responseData["all"]["boxplot"]["plotOptions"],
          xaxis: this.responseData["all"]["boxplot"]["xaxis"],
          yaxis: this.responseData["all"]["boxplot"]["yaxis"],
          colors: this.responseData["all"]["boxplot"]["colors"],
          tooltip: { enabled: false },
          dataLabels: dataLabels,
        }
      }
      return null
    },
    chartSeries() {
      if (this.responseData) {
        const output = this.responseData["all"]["boxplot"]["series"]
        return output
      }
      return null
    },
    descriptionTable() {
      if (this.responseData) {
        const descriptionDF = this.responseData["all"]["description_df"]
        return descriptionDF
      }
      return null
    },
    additionalInfoTable() {
      if (this.responseData) {
        const additionalDF = this.responseData["all"]["additional_info_df"]
        return additionalDF
      }
      return null
    },
  },

  methods: {
    fitBoundsRotated(bounds, options, eventData, map) {
      options = Object.assign(
        {
          padding: {
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
          },
          offset: [0, 0],
          maxZoom: 14.8,
          //   maxZoom: map.transform.maxZoom,
        },
        options
      )

      if (typeof options.padding === "number") {
        const p = options.padding
        options.padding = {
          top: p,
          bottom: p,
          right: p,
          left: p,
        }
      }

      bounds = mapboxgl.LngLatBounds.convert(bounds)

      // paddingOffset affects the map's center, used in the options for
      // `easeTo` and `flyTo` in subsequent calls
      const paddingOffset = [
          options.padding.left - options.padding.right,
          options.padding.top - options.padding.bottom,
        ],
        lateralPadding = Math.min(options.padding.right, options.padding.left),
        verticalPadding = Math.min(options.padding.top, options.padding.bottom)

      options.offset = [
        options.offset[0] + paddingOffset[0],
        options.offset[1] + paddingOffset[1],
      ]
      options.bearing = options.bearing || this.getBearing()

      const offset = mapboxgl.Point.convert(options.offset),
        tr = map.transform,
        nw = tr.project(bounds.getNorthWest()),
        se = tr.project(bounds.getSouthEast()),
        size = se.sub(nw)

      // START CUSTOM ROTATION
      // "cropped rectangle rotation method"
      // https://stackoverflow.com/questions/33866535/how-to-scale-a-rotated-rectangle-to-always-fit-another-rectangle
      const theta = options.bearing * (Math.PI / 180),
        W =
          size.x * Math.abs(Math.cos(theta)) +
          size.y * Math.abs(Math.sin(theta)),
        H =
          size.x * Math.abs(Math.sin(theta)) +
          size.y * Math.abs(Math.cos(theta)),
        rotatedSize = { x: W, y: H },
        // END CUSTOM ROTATION
        scaleX =
          (tr.width - lateralPadding * 2 - Math.abs(offset.x) * 2) /
          rotatedSize.x,
        scaleY =
          (tr.height - verticalPadding * 2 - Math.abs(offset.y) * 2) /
          rotatedSize.y

      if (scaleY < 0 || scaleX < 0) {
        if (typeof console !== "undefined")
          console.warn(
            "Map cannot fit within canvas with the given bounds, padding, and/or offset."
          )
        return map
      }

      options.center = tr.unproject(nw.add(se).div(2))
      options.zoom = Math.min(
        tr.scaleZoom(tr.scale * Math.min(scaleX, scaleY)),
        options.maxZoom
      )

      return options.linear
        ? map.easeTo(options, eventData)
        : map.flyTo(options, eventData)
    },

    getColorForYield(properties, valueRanges) {
      const { cropId, yieldPerAcre } = properties
      const valueRange = valueRanges[cropId]

      if (yieldPerAcre <= 0 || yieldPerAcre === null) return [0, 0, 0, 0]

      const colorIdx =
        valueRange.length -
        valueRange.filter(cutoffVal => yieldPerAcre <= cutoffVal).length

      return HARVEST_YIELD_COLORS[colorIdx]
    },

    getElevation(properties) {
      const { elevation } = properties
      const elevationScale = 0
      return elevation / elevationScale
    },

    setColorValueRanges(ranges) {
      this.colorValueRanges = ranges
    },
  },

  async mounted() {
    mapboxgl.accessToken = MAPBOX_TOKEN
    const map = new mapboxgl.Map({
      preserveDrawingBuffer: true,
      container: "mapbox-map",
      height: "100%",
      width: "100%",
      style: "mapbox://styles/mapbox/satellite-v9",
      center: [-91.6, 34.4],
      zoom: 9,
      pitch: 0,
    })

    // create a feature collection for visualizing yield by voxel
    const yieldFeatures = []
    for (const voxel in this.responseData["all"]["map"]["voxel"]) {
      const {
        seed_variety,
        topo_elevation,
        yield_vol_dry_bushels_per_ac,
        geom,
      } = this.responseData["all"]["map"]["voxel"][voxel]

      yieldFeatures.push({
        type: "Feature",
        geometry: geom,
        properties: {
          seed_variety,
          elevation: topo_elevation,
          yieldPerAcre: yield_vol_dry_bushels_per_ac,
        },
      })
    }
    const voxelFeatureCollection = {
      type: "FeatureCollection",
      features: yieldFeatures,
    }

    // for voxel color by yield
    this.setColorValueRanges(this.responseData["yield_thresholds"])

    const yieldLayer = new MapboxLayer({
      id: "yieldLayer",
      type: GeoJsonLayer,
      data: voxelFeatureCollection,
      stroked: false,
      filled: true,
      extruded: true,
      autoHighlight: false,
      pointRadiusScale: 5,
      getElevation: feat => this.getElevation(feat.properties),
      getFillColor: feat =>
        this.getColorForYield(feat.properties, {
          undefined: this.colorValueRanges,
        }),
      getLineColor: () => [0, 0, 0, 255],
      lineWidthMinPixels: 0,
    })

    // calculate fieldBoundaries for zooming
    const calculateExtentBounds = fieldBoundData => {
      if (!fieldBoundData) return {}
      const latLongValues = fieldBoundData.features.map(
        feat => feat.geometry.coordinates
      )

      // aggregates latitude/longitude values in coordinates [[lat, lng]...]
      function getLong(n) {
        return n[0].map(subarr => subarr[0])
      }
      function getLat(n) {
        return n[0].map(subarr => subarr[1])
      }
      const longVals = _.flatMap(latLongValues, getLong)
      const latVals = _.flatMap(latLongValues, getLat)

      const minLatitude = Math.min.apply(null, latVals)
      const maxLatitude = Math.max.apply(null, latVals)
      const minLongitude = Math.min.apply(null, longVals)
      const maxLongitude = Math.max.apply(null, longVals)

      return { minLatitude, maxLatitude, minLongitude, maxLongitude }
    }
    let fieldExtentBounds = calculateExtentBounds(voxelFeatureCollection)
    this.fitBoundsRotated(
      [
        [fieldExtentBounds.minLongitude, fieldExtentBounds.minLatitude],
        [fieldExtentBounds.maxLongitude, fieldExtentBounds.maxLatitude],
      ],
      { bearing: -0.0001 },
      null,
      map
    )

    const fieldBoundLayer = new MapboxLayer({
      id: "fieldbound-layer",
      type: GeoJsonLayer,
      data: this.responseData["all"]["map"]["field_boundary"],
      stroked: true,
      filled: false,
      extruded: false,
      lineWidthMinPixels: 3,
      getLineColor: () => [255, 204, 0, 255],
    })

    const controlBoundLayer = new MapboxLayer({
      id: "control-layer",
      type: GeoJsonLayer,
      data: this.responseData["all"]["map"]["control_boundary"],
      stroked: true,
      filled: false,
      extruded: false,
      lineWidthMinPixels: 2,
      getLineColor: () => [154, 18, 179, 255],
    })

    const trialBoundLayer = new MapboxLayer({
      id: "trial-layer",
      type: GeoJsonLayer,
      data: this.responseData["all"]["map"]["treatment_boundary"],
      stroked: true,
      filled: false,
      extruded: false,
      lineWidthMinPixels: 2,
      getLineColor: () => [0, 128, 128, 255],
    })

    map.on("load", () => {
      map.addLayer(fieldBoundLayer)
      map.addLayer(controlBoundLayer)
      map.addLayer(trialBoundLayer)
      map.addLayer(yieldLayer)
    })
  },
}
</script>
<style>
@page {
  size: 8.5in 11in;
  margin: 0;
}

@media print {
  html,
  body {
    margin: 0;
    height: 100%;
    overflow: hidden !important;
  }
}
</style>
<style scoped>
.background-dim {
  background-color: #dcdcdc;
}

.map-footer {
  margin-top: 12px;
  width: 100%;
  z-index: 1000;
}

.color-bar {
  height: 30px;
}

.scale-text {
  font-size: 12px;
  font-family: Montserrat, sans-serif;
}

.yield-scale {
  color: black;
  font-weight: bold;
  font-size: 16px;
  margin-left: 6px;
}

.control-wrapper {
  position: absolute;
  top: 6px;
  right: 26px;
  height: 90px;
  width: 100px;
  z-index: 500;
  background: white;
  border: 1pt solid #eee;
  box-shadow: 0px 0px 4px #ccc;
  padding: 8px;
  border-radius: 7px;
}

.pdf-wrapper {
  width: 1000px;
  height: 1283px;
  position: fixed;
}

.map-wrapper {
  height: 450px;
  position: relative;
  background: #1b1b1d;
  overflow: hidden;
  padding-right: 25px;
}

.fill-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.title {
  color: black;
  font-family: Montserrat, sans-serif;
  font-size: 32px;
  font-weight: 500;
  margin-left: 25px;
}

.subtitle {
  color: black;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 500;
  margin-left: 25px;
}

.top-logo {
  position: absolute;
  top: 10px;
  right: 25px;
}

td {
  font-family: Montserrat, sans-serif;
  font-size: 18px;
}

td:first-of-type {
  text-align: right;
  overflow-wrap: break-word;
}

.dk-grey {
  color: #444;
}

.df-text {
  font-size: 10px;
}

.footer {
  position: absolute;
  bottom: 0px;
  height: 30px;
  width: 100%;
  background: #444;
  font-family: Montserrat, sans-serif;
  font-size: 13px;
  color: #fff;
  font-weight: bold;
}

.green-cell {
  font-weight: bold;
  color: #79c61c;
}

.red-fill {
  background-color: #ff0000;
}

.orange-fill {
  background-color: #ff5c00;
}

.light-orange-fill {
  background-color: #ff8200;
}

.yellow-fill {
  background-color: #ffd500;
}

.bright-yellow-fill {
  background-color: #ffff00;
}

.light-green-fill {
  background-color: #c8e632;
}

.bright-green-fill {
  background-color: #b4fa00;
}

.green-fill {
  background-color: #40ff00;
}

.control-dot {
  height: 12px;
  width: 12px;
  background-color: #9a12b3;
  border-radius: 50%;
  display: inline-block;
  margin-top: 6px;
}

.trial-dot {
  height: 12px;
  width: 12px;
  background-color: #008080;
  border-radius: 50%;
  display: inline-block;
  margin-top: 6px;
}

.field-dot {
  height: 12px;
  width: 12px;
  background-color: #ffcc00;
  border-radius: 50%;
  display: inline-block;
  margin-top: 6px;
}

.dot-text {
  margin-left: 8px;
}

.lg-font {
  font-size: 16px;
}
</style>
