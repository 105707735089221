<template>
  <tr :class="showColorText()">
    <td>
      <v-simple-checkbox
        @click="sendCheckEvents"
        :disabled="!canEnroll"
        name="selected"
        ref="fieldSelectedCheckbox"
        v-model="fieldSelectedCheckbox"
      />
    </td>
    <td>
      <img :src="field.boundary_image" style="width: 50px" />
    </td>
    <td>
      <span class="default-name" @click="handleFieldClicked">{{
        field.name
      }}</span>
    </td>
    <td>{{ fieldDataCompletion }}%</td>
    <td>
      <v-chip :color="possiblePrice > 0 ? 'green' : 'red'" text-color="white">
        {{ possiblePrice > 0 ? possiblePrice : 0 | currency }}
      </v-chip>
    </td>
    <td>
      <v-simple-checkbox
        :disabled="!canEnroll"
        v-model="field.prioritize_control"
        @click="setPrioritizeControl"
      />
    </td>
    <td>
      <div v-if="!canEnroll || modalView" class="font-weight-bold">
        {{ ownership }}
      </div>
      <div v-else>
        <v-select-old
          label="display_name"
          :style="{ width: '138px' }"
          :options="ownershipOptions"
          :reduce="o => o.value"
          placeholder="Add Ownership"
          v-model="ownershipChoice"
          @input="setOwnership"
        />
      </div>
    </td>
    <td>
      <div v-for="scenario in scenarios" :key="scenario.regen_practice.name">
        <li v-if="scenario.is_selected">{{ scenario.regen_practice.name }}</li>
      </div>
    </td>

    <td>
      <div v-if="modalView">
        Enrollment Date
      </div>
      <div v-else>
        <div
          v-for="enrolledField in enrolledFields"
          :key="enrolledField.enrollment.uuid"
          class="enrolledField"
          v-bind:style="{
            background: isRequested(enrolledField.enrollment.status)
              ? '#850000'
              : isApproved(enrolledField.enrollment.status)
              ? '#CCB33B'
              : isConfirmed(enrolledField.enrollment.status)
              ? '#72CC6B'
              : `#000000`,
            width: '120px',
          }"
        >
          {{ enrolledField.enrollment.enrollment_start_date | date }}
          <i
            id="order-tooltip"
            class="fa fa-info-circle"
            data-toggle="tooltip"
            data-placement="top"
            :title="`Enrollment ID: ${enrolledField.enrollment.uuid}`"
          />
        </div>
      </div>
    </td>

    <td class="text-capitalize font-weight-bold" v-if="!modalView">
      {{ currentEnrollmentStatus }}
    </td>

    <td v-if="!modalView">
      <div v-if="enrollAgreementPDF" class="download">
        <a :href="enrollAgreementPDF">
          <i class="fa fa-download" />{{ currentEnrollmentID }}
        </a>
      </div>
    </td>
  </tr>
</template>

<script>
import _ from "lodash"

export default {
  name: "CarbonReadyFieldRow",
  props: [
    "dataCompletion",
    "field",
    "regenPracticeOptions",
    "carbonReadyEnrolledFields",
    "newEnrollmentStartDate",
    "modalView",
  ],

  data() {
    return {
      selectedRegenPractice: [],
      fieldSelectedCheckbox: false,
      currentRegenPractice: null,
      ownershipChoice: null,
      unenrollSelectedFieldModalOpen: false,
      ownershipOptions: [
        { value: "owned", display_name: "Owned" },
        { value: "leased", display_name: "Leased" },
      ],
    }
  },

  computed: {
    currentEnrollmentStatus() {
      if (this.enrolledFields.length === 0) return ""
      return this.enrolledFields[this.enrolledFields.length - 1].enrollment
        .status
    },

    currentEnrollmentID() {
      const mostRecentEnrolledField = this.enrolledFields[
        this.enrolledFields.length - 1
      ]
      return mostRecentEnrolledField.enrollment.contract_id
    },

    enrolledFields() {
      if (!this.carbonReadyEnrolledFields) return null
      return this.carbonReadyEnrolledFields.filter(
        enrolledField => enrolledField.field_id === this.field.id
      )

      //// code
    },

    scenarios() {
      if (this.field.daycent[0])
        return _.orderBy(
          this.field.daycent[0].scenario,
          "regen_practice.name",
          "asc"
        )
      return ""
    },

    canEnroll() {
      if (this.enrolledFields.length === 0) return true

      const mostRecentEnrolledField = this.enrolledFields[
        this.enrolledFields.length - 1
      ]
      if (
        this.isRequested(mostRecentEnrolledField.enrollment.status) ||
        this.isApproved(mostRecentEnrolledField.enrollment.status) ||
        this.isConfirmed(mostRecentEnrolledField.enrollment.status) ||
        mostRecentEnrolledField.enrollment.status
      )
        return false

      return true
    },

    hasSigned() {
      if (this.enrolledFields.length === 0) return "No"

      const mostRecentEnrolledField = this.enrolledFields[
        this.enrolledFields.length - 1
      ]
      if (mostRecentEnrolledField.enrollment.has_signed) return "Yes"

      return "No"
    },

    ownership() {
      if (this.enrolledFields.length === 0) return
      const mostRecentEnrolledField = this.enrolledFields[
        this.enrolledFields.length - 1
      ]
      var ownership = mostRecentEnrolledField.ownership
      return ownership[0].toUpperCase() + ownership.substring(1)
    },

    enrollAgreementPDF() {
      if (this.enrolledFields.length === 0) return
      const mostRecentEnrolledField = this.enrolledFields[
        this.enrolledFields.length - 1
      ]
      var agreementPDF = mostRecentEnrolledField.enrollment.enroll_agreement_pdf
      return agreementPDF
    },

    fieldDataCompletion() {
      if (this.dataCompletion) {
        const fieldData = this.dataCompletion.find(dc => dc.id == this.field.id)
        if (!fieldData) return null

        const total =
          fieldData["cover_cropping"]["total"] +
          fieldData["irrig"]["total"] +
          fieldData["fert"]["total"] +
          fieldData["tillage"]["total"]
        return Math.ceil(total / 4)
      }
      return null
    },

    possiblePrice() {
      if (this.field.daycent) {
        const aggregatePrice = this.field.daycent[0].creditAmount
        return aggregatePrice
      }
      return null
    },
  },

  methods: {
    sendCheckEvents() {
      if (this.fieldSelectedCheckbox) this.$emit("checked", this.field.id)
      else this.$emit("unchecked", this.field.id)
    },

    setCheckbox(checked) {
      if (!this.canEnroll) return
      this.fieldSelectedCheckbox = checked
      this.sendCheckEvents()
    },

    isRequested(enrollmentStatus) {
      return enrollmentStatus === "requested"
    },

    isApproved(enrollmentStatus) {
      return enrollmentStatus === "approved"
    },

    isConfirmed(enrollmentStatus) {
      return enrollmentStatus === "confirmed"
    },

    setOwnership(val) {
      this.$emit("ownership-choice", this.field.id, val)
    },

    setPrioritizeControl() {
      this.$emit(
        "set-prioritize-control",
        this.field.id,
        this.field.prioritize_control
      )
    },

    handleFieldClicked() {
      this.$emit("handle-field-clicked", this.field, this.enrolledFields)
    },

    showColorText() {
      if (this.enrolledFields.length === 0) {
        return "showBlackText"
      } else {
        const status = this.enrolledFields[this.enrolledFields.length - 1]
          .enrollment.status
        if (this.isRequested(status)) return "showRequestedText"
        else if (this.isApproved(status)) return "showApprovedText"
        else if (this.isConfirmed(status)) return "showConfirmedText"
        else return "showBlackText"
      }
    },
  },

  mounted() {
    const { $ } = window
    setTimeout(() =>
      $(function() {
        $('[data-toggle="tooltip"]').tooltip()
      }, 500)
    )
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.tooltip-inner {
  font-size: 11px;
}
</style>

<style scoped>
.enrolledField {
  width: 125px;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  font-size: 12px;
  margin: 5px 0px;
}

.fa-info-circle {
  margin-left: 5px;
}

.download {
  margin-top: 8px;
  font-weight: bold;
}

.download i {
  margin-right: 5px;
}

.default-name {
  color: #1979f1;
  font-weight: bold;
}

.showBlackText {
  color: #000000;
}

.showRequestedText {
  color: #850000;
}

.showApprovedText {
  color: #ccb33b;
}

.showConfirmedText {
  color: #72cc6b;
}

.highlightSelectedRow {
  background-color: rgba(233, 214, 228, 0.767);
}

.clearHighlightSelectedRow {
  background-color: "";
}

.AgreementPDF {
  width: "150px";
}
</style>
