<template>
  <div>
    <div
      class="title-row row d-flex align-items-center justify-content-between"
    >
      <div class="d-flex align-items-center">
        <h2>{{ defaultsName }}</h2>
        <div
          v-if="!isGeneralDefaults"
          class="edit-btn"
          @click="handleOpenEditName"
        >
          Edit Name
        </div>
      </div>
      <button
        v-if="!isGeneralDefaults"
        class="btn btn-outline-danger"
        @click="showConfirmDeleteModal = true"
      >
        Delete Template
      </button>
    </div>

    <OperationDataDefaults :operationData="operationData" />
    <PlantingDataDefaults :plantingData="plantingData" />
    <FertilizerDataDefaults :fertilizerData="fertilizerData" />
    <HarvestDataDefaults :harvestData="harvestData" />
    <OperationCostsDefaults :operationCosts="operationCosts" />
    <SoilSampleDataDefaults :soilSampleData="soilSampleData" />

    <ArvaModal
      v-if="showEditNameModal"
      name="editTemplateNameModal"
      title="Edit Defaults Template Name"
      @close-modal="showEditNameModal = false"
    >
      <div class="row">
        <div class="col-12">
          <form @submit.prevent="handleEditTemplateName">
            <div class="name-edit-form form-group">
              <label>Name</label>
              <input
                class="form-control"
                type="text"
                v-model="editTemplateName"
              />
            </div>
          </form>
        </div>
      </div>
      <ArvaModalSaveFooter
        confirmText="Save"
        cancelText="Cancel"
        @on-cancel="handleCancelEditName"
        @on-confirm="handleEditTemplateName"
      />
    </ArvaModal>

    <ConfirmModal
      v-if="showConfirmDeleteModal"
      titleText="Confirm Settings Template Deletion"
      confirmText="Are you sure you want to delete this settings template?"
      @confirm="deleteTemplate"
      @close-modal="showConfirmDeleteModal = false"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex"

import DefaultsAPI from "@/api/DefaultsAPI"
import { Defaults } from "@/store/modules"

import ArvaModal from "@/components/modals/ArvaModal"
import ArvaModalSaveFooter from "@/components/modals/ArvaModalSaveFooter"
import ConfirmModal from "@/components/modals/ConfirmModal"
import FertilizerDataDefaults from "@/components/defaults/FertilizerDataDefaults"
import HarvestDataDefaults from "@/components/defaults/HarvestDataDefaults"
import OperationCostsDefaults from "@/components/defaults/OperationCostsDefaults"
import OperationDataDefaults from "@/components/defaults/OperationDataDefaults"
import PlantingDataDefaults from "@/components/defaults/PlantingDataDefaults"
import SoilSampleDataDefaults from "@/components/defaults/SoilSampleDataDefaults"

export default {
  name: "DefaultSettings",
  props: ["defaultsTemplateId"],
  components: {
    ArvaModal,
    ArvaModalSaveFooter,
    ConfirmModal,
    FertilizerDataDefaults,
    HarvestDataDefaults,
    OperationCostsDefaults,
    OperationDataDefaults,
    PlantingDataDefaults,
    SoilSampleDataDefaults,
  },

  data() {
    return {
      editTemplateName: null,
      showEditNameModal: false,
      showConfirmDeleteModal: false,
    }
  },

  computed: {
    ...mapState({
      defaults: state => state.Defaults.defaults,
    }),

    defaultsTemplate() {
      return this.defaults[this.defaultsTemplateId]
    },

    defaultsName() {
      return this.defaultsTemplate ? this.defaultsTemplate.name : ""
    },

    isGeneralDefaults() {
      return this.defaultsTemplate
        ? this.defaultsTemplate.is_general_defaults
        : false
    },

    operationData() {
      return this.defaultsTemplate ? this.defaultsTemplate.operation_data : {}
    },

    operationCosts() {
      return this.defaultsTemplate ? this.defaultsTemplate.operation_costs : {}
    },

    plantingData() {
      return this.defaultsTemplate ? this.defaultsTemplate.planting_data : {}
    },

    fertilizerData() {
      return this.defaultsTemplate ? this.defaultsTemplate.fertilizer_data : {}
    },

    harvestData() {
      return this.defaultsTemplate ? this.defaultsTemplate.harvest_data : {}
    },

    soilSampleData() {
      return this.defaultsTemplate ? this.defaultsTemplate.soil_sample_data : {}
    },
  },

  methods: {
    ...mapActions({
      fetchDefaults: Defaults.Actions.fetchDefaults,
    }),

    handleOpenEditName() {
      this.editTemplateName = this.defaultsName
      this.showEditNameModal = true
    },

    handleCancelEditName() {
      this.showEditNameModal = false
    },

    async handleEditTemplateName() {
      if (this.editTemplateName) {
        await DefaultsAPI.update("defaults", this.defaultsTemplateId, {
          name: this.editTemplateName,
        })

        await this.fetchDefaults()
        this.showEditNameModal = false
      }
    },

    async deleteTemplate() {
      await DefaultsAPI.deleteSettingsTemplate(this.defaultsTemplateId)
      this.showConfirmDeleteModal = false
      await this.fetchDefaults()
      this.$router.push("/settings/defaults")
    },
  },
}
</script>

<style>
.col {
  padding: 12px;
}

.form-control {
  height: 37px !important;
}

.input-group-prepend {
  height: 37px !important;
}

.input-group-append {
  height: 37px !important;
}

.select-style .vs__dropdown-toggle {
  height: 37px;
}
</style>

<style scoped>
.title-row {
  margin-bottom: 15px;
}

.edit-btn {
  font-size: 11px;
  font-weight: 500;
  margin-left: 10px;
  color: #0095ff;
  cursor: pointer;
}

.edit-btn:hover {
  color: #888;
}

h2 {
  margin: 0;
}

.name-edit-form {
  margin: 25px 70px;
}
</style>
