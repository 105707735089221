<template>
  <tr>
    <td>
      <div class="d-flex">
        Zone {{ zone.cluster }}
        <div class="zone-color" :style="zoneColor" />
      </div>
      <div class="zone-accent">
        Acreage: {{ zone.ac ? zone.ac : zone.area | prettyInteger }}
      </div>
      <div class="zone-accent" v-if="!rx">Performance: {{ zone.vi }}</div>
      <div class="zone-accent" v-if="rx">
        Predicted Yield: {{ zone.yield | prettyInteger }}
      </div>
      <div class="zone-accent" v-if="rx">
        Target N: {{ zone.n !== -99 ? zone.n.toFixed(0) : "Missing Data" }}
      </div>
      <div class="zone-accent" v-if="rx">
        Target P: {{ zone.p !== -99 ? zone.p.toFixed(0) : "Missing Data" }}
      </div>
      <div class="zone-accent" v-if="rx">
        Target K: {{ zone.k !== -99 ? zone.k.toFixed(0) : "Missing Data" }}
      </div>
    </td>
    <td>
      <div
        class="d-flex"
        v-for="product in zone.products"
        :key="product.product"
      >
        <div class="product">{{ product.product }}</div>
        <div class="units">{{ product.amount }} {{ product.units }}</div>
        <div v-if="!isReadOnly" class="delete">
          <i class="fa fa-times-circle" @click="handleDeleteProduct(product)" />
        </div>
      </div>
    </td>
  </tr>
</template>
<script>
import { VIRIDIS, AGT_COLORS_OLD } from "@/constants/agts"
import { getAgtColor } from "@/utility"
import { mapState } from "vuex"

export default {
  name: "ZonesControllerRow",
  props: ["agt", "rx", "zone", "zoneIndex", "totalZones"],
  computed: {
    ...mapState({
      isReadOnly: state => state.User.user.permission === "Read-Only",
    }),
    zoneColor() {
      var yieldColorRamp = AGT_COLORS_OLD
      if (this.totalZones.toString() in VIRIDIS) {
        yieldColorRamp = VIRIDIS[this.totalZones.toString()]
      }
      return {
        background: this.agt
          ? getAgtColor(this.zone.cluster)
          : yieldColorRamp[this.zoneIndex],
      }
    },
  },
  methods: {
    handleDeleteProduct(product) {
      this.$emit("delete-product", product, this.zone)
    },
  },
}
</script>
<style scoped>
.zone-accent {
  font-size: 12px;
}
.delete {
  margin-left: 10px;
  width: 10%;
}
.fa-times-circle {
  cursor: pointer;
  color: #e60000;
}
.product {
  width: 55%;
}
.unit {
  width: 25%;
}
.zone-color {
  height: 12px;
  width: 12px;
  border-radius: 12px;
  margin-left: 5px;
  margin-top: 5px;
}
</style>
