<template>
    <div class="statistics-card">
        <p class="statistics-card-title">{{ title }}</p>
        <p class="statistics-card-statistic">{{ statistic }}</p>
        <p class="statistics-card-subtitle">{{ subtitle }}</p>
    </div>
</template>

<script>
export default {
    props: {
        title: { type: String, required: true },
        statistic: { type: String, required: true },
        subtitle: { type: String, required: true }
    }
}
</script>

<style scoped>
.statistics-card {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    border-radius: 6px;
    background-color: #FFFFFF;
    padding: 24px 16px;
}
.statistics-card p {
    width: 100%;
    font-weight: bold;
    color: #000000;
}
.statistics-card-title,
.statistics-card-subtitle {
    font-size: 14px;
    line-height: 26px;
}
.statistics-card-statistic {
    font-size: 44px;
    line-height: 1;
}
.statistics-card-subtitle {
    font-weight: normal !important;
    margin: 0;
}
</style>
