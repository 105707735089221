<template>
  <ArvaModal
    name="changePasswordModal"
    title="Change Password"
    @close-modal="handleCloseModal"
  >
    <form @submit.prevent="handleChangePassword">
      <div class="row">
        <div class="col-12">
          <div class="change-password-form">
            <div class="form-group">
              <label>Enter your new password</label>
              <input
                class="form-control"
                type="password"
                v-model="password1"
                ref="pw1"
              />
            </div>

            <div class="form-group">
              <label>Repeat new password</label>
              <input class="form-control" type="password" v-model="password2" />
            </div>

            <div class="pw-error">{{ passwordError }}</div>
          </div>
        </div>
      </div>

      <ArvaModalSaveFooter
        confirmText="Save"
        cancelText="Cancel"
        @on-cancel="handleCloseModal"
      />
    </form>
  </ArvaModal>
</template>

<script>
import UserAPI from "@/api/UserAPI"
import { successMessage } from "@/utility"
import ArvaModal from "./ArvaModal"
import ArvaModalSaveFooter from "./ArvaModalSaveFooter"

export default {
  name: "ChangePasswordModal",
  props: ["userId"],
  components: { ArvaModal, ArvaModalSaveFooter },

  data() {
    return { password1: null, password2: null, passwordError: null }
  },

  methods: {
    handleCloseModal() {
      this.$emit("close-modal")
    },

    handleChangePassword() {
      const minPwLength = 6
      const { password1, password2 } = this
      this.passwordError = null

      if (!password1 && !password2) {
        this.passwordError = "Please enter a new password"
        return
      }
      if (password1 !== password2) {
        this.passwordError = "Passwords must match"
        return
      }
      if (password1 && password1.length < minPwLength) {
        this.passwordError = `Password must be at least ${minPwLength} characters long`
        return
      }

      UserAPI.updatePassword(this.userId, this.password1)
      successMessage(this, "Password Updated", "top-right")
      this.handleCloseModal()
    },
  },
  mounted() {
    this.password1 = null
    this.password2 = null
    this.passwordError = null
    setTimeout(() => this.$refs.pw1.focus())
  },
}
</script>

<style scoped>
.change-password-form {
  margin: 25px 70px;
}

.pw-error {
  color: red;
  font-weight: 500;
}
</style>
