<template>
  <div class="card-body">
    <div class="row">
      <div class="col">
        <div class="settings-title">Field Settings</div>
      </div>
    </div>
    <div class="field-settings">
      <PlantingFieldSettings
        :plantingData="plantingData"
        :plantingDefaults="plantingDefaults"
        :carbonSettings="carbonSettingsPlantings"
        :defaultsName="defaultsName"
        :generalDefaults="generalDefaults"
        :year="year"
      />
      <FertilizerFieldSettings
        :fertilizerData="fertilizerData"
        :fertilizerDefaults="fertilizerDefaults"
        :carbonSettings="carbonSettingsFerts"
        :defaultsName="defaultsName"
        :generalDefaults="generalDefaults"
        :year="year"
      />
      <FinancialFieldSettings
        :financialData="financialData"
        :financialDefaults="financialDefaults"
        :defaultsName="defaultsName"
        :generalDefaults="generalDefaults"
      />
      <SoilFieldSettings
        :soilSampleData="soilSampleData"
        :soilSampleDefaults="soilSampleDefaults"
        :defaultsName="defaultsName"
        :generalDefaults="generalDefaults"
      />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex"
import FinancialFieldSettings from "@/components/fields/FinancialFieldSettings"
import PlantingFieldSettings from "@/components/fields/PlantingFieldSettings"
import FertilizerFieldSettings from "@/components/fields/FertilizerFieldSettings"
import SoilFieldSettings from "@/components/fields/SoilFieldSettings"

export default {
  name: "FieldViewSettings",
  props: ["carbonSettings", "field", "year"],
  components: {
    FinancialFieldSettings,
    PlantingFieldSettings,
    FertilizerFieldSettings,
    SoilFieldSettings,
  },
  computed: {
    ...mapState({
      defaults: state => state.Defaults.defaults,
      fieldSettings: state => state.Defaults.fieldSettings,
    }),

    carbonSettingsOperations() {
      if (this.carbonSettings) {
        return this.carbonSettings.cultivations[0].operations
      }
      return null
    },

    carbonSettingsPlantings() {
      if (this.carbonSettings) {
        return this.carbonSettings.cultivations[0].plantings
      }
      return null
    },

    carbonSettingsFerts() {
      if (this.carbonSettings) {
        return this.carbonSettings.cultivations[0].fert_applications
      }
      return null
    },

    carbonSettingsHarvest() {
      if (this.carbonSettings) {
        return this.carbonSettings.cultivations[0].harvests
      }
      return null
    },

    defaultsTemplate() {
      return this.field.defaults ? this.defaults[this.field.defaults.id] : null
    },

    generalDefaults() {
      return this.field.general_defaults
        ? this.defaults[this.field.general_defaults.id]
        : {}
    },

    defaultsName() {
      return this.defaultsTemplate ? this.defaultsTemplate.name : ""
    },

    fieldSettingsTemplate() {
      return this.field.field_settings
        ? this.fieldSettings[this.field.field_settings.id]
        : null
    },

    operationData() {
      return this.fieldSettingsTemplate
        ? this.fieldSettingsTemplate.operation_data
        : {}
    },

    operationDefaults() {
      return this.defaultsTemplate ? this.defaultsTemplate.operation_data : {}
    },

    financialData() {
      return this.fieldSettingsTemplate
        ? this.fieldSettingsTemplate.operation_costs
        : {}
    },

    financialDefaults() {
      return this.defaultsTemplate ? this.defaultsTemplate.operation_costs : {}
    },

    plantingData() {
      return this.fieldSettingsTemplate
        ? this.fieldSettingsTemplate.planting_data
        : {}
    },

    plantingDefaults() {
      return this.defaultsTemplate ? this.defaultsTemplate.planting_data : {}
    },

    fertilizerData() {
      return this.fieldSettingsTemplate
        ? this.fieldSettingsTemplate.fertilizer_data
        : {}
    },

    fertilizerDefaults() {
      return this.defaultsTemplate ? this.defaultsTemplate.fertilizer_data : {}
    },

    harvestData() {
      return this.fieldSettingsTemplate
        ? this.fieldSettingsTemplate.harvest_data
        : {}
    },

    harvestDefaults() {
      return this.defaultsTemplate ? this.defaultsTemplate.harvest_data : {}
    },

    soilSampleData() {
      return this.fieldSettingsTemplate
        ? this.fieldSettingsTemplate.soil_sample_data
        : {}
    },

    soilSampleDefaults() {
      return this.defaultsTemplate ? this.defaultsTemplate.soil_sample_data : {}
    },
  },
  watch: {
    carbonSettings() {
      // fix for users visiting the fieldview before carbon settings have completed fetching
      this.$forceUpdate()
    },
  },
}
</script>
<style>
.default-name {
  color: #1979f1;
  font-weight: bold;
  cursor: pointer;
}

.default-name:hover {
  color: #333;
}

.settings-title {
  font-weight: 500;
  font-size: 17px;
  color: #666;
  border-bottom: 1pt solid #bbb;
  padding-bottom: 5px;
}

.field-settings {
  max-height: 475px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.settings-section-title {
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  color: #888;
  padding-left: 5px;
}

.settings-section-title > i {
  margin-left: 5px;
}

.settings-table {
  margin-bottom: 20px;
  font-size: 13px;
}

.settings-row {
  padding-left: 30px;
  padding-right: 30px;
}

.settings-row > .col {
  border-bottom: 1pt solid #eee;
}

.settings-row > .col:first-of-type {
  font-weight: 500;
}

.settings-row .fa-pencil-square-o {
  margin-left: 10px;
  display: none;
  cursor: pointer;
}

.settings-row .fa-pencil-square-o:hover {
  color: #666;
}

.settings-row:hover .fa-pencil-square-o {
  display: inline;
}

.edit-icons {
  margin-left: 10px;
}

.edit-icons .fa-check {
  color: green;
  cursor: pointer;
}

.edit-icons .fa-times {
  color: red;
  cursor: pointer;
}
</style>
