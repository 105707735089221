import FieldsAPI from "@/api/FieldsAPI"
import { Dashboard, Fields, Filter, Map } from "@/store/modules"

const state = {
  fields: {},
  historicalROISummary: {},
  userSustainabilityBeforeRefresh: {},
  featureFlags: {},
}

const getters = {
  [Fields.Getters.getFields]: state => Object.values(state.fields),
  [Fields.Getters.getFeatureFlags]: state => state.featureFlags,
}


const mutations = {
  [Fields.Mutations.setFields](state, fields) {
    const fieldsDict = {}
    const historicalROISummary = {}
    fields.forEach(field => {
      const historicalROIByYear = {}
      const historicalROI = field.historical_roi || []
      historicalROI.forEach(roi => (historicalROIByYear[roi.year] = roi))
      field["historicalROI"] = historicalROIByYear
      fieldsDict[field.id] = field
    })

    state.fields = fieldsDict
    state.historicalROISummary = historicalROISummary
  },

  [Fields.Mutations.updateFields](state, fields) {
    for (const field of fields) {
      // this historical roi stuff really doesn't belong here (or in setFields)
      const historicalROIByYear = {}
      const historicalROI = field.historical_roi || []
      historicalROI.forEach(roi => (historicalROIByYear[roi.year] = roi))
      field["historicalROI"] = historicalROIByYear
      state.fields[field.id] = field
    }
  },

  [Fields.Mutations.updateRegenPractice](state, scenarioSummaries) {
    const fieldId = scenarioSummaries.field_id
    const scenarios = scenarioSummaries.scenario

    const scenarioByField = []
    scenarios.forEach(scenario => {
      scenarioByField.push(scenario)
    })

    state.fields[fieldId].daycent[0].scenario = scenarioByField
    state.fields = Object.assign({}, state.fields)
  },

  [Fields.Mutations.updateUserSustainability](state, sustainability) {
    state.userSustainabilityBeforeRefresh = sustainability
  },

  [Fields.Mutations.clearUserSustainability](state) {
    state.userSustainabilityBeforeRefresh = {}
  },

  [Fields.Mutations.setFeatureFlags](state, featureFlags) {
    for (const flag of featureFlags) {
      state.featureFlags[flag.feature_name] = flag.enabled
    }
  }
}

const actions = {
  [Fields.Actions.batchDelete]({ dispatch }, fieldIds) {
    return new Promise(resolve => {
      FieldsAPI.batchDelete(fieldIds).then(() => {
        dispatch(Fields.Actions.fetchFields)
        resolve()
      })
    })
  },

  [Fields.Actions.batchUpdate]({ dispatch }, payload) {
    return new Promise(resolve => {
      FieldsAPI.batchUpdate(payload).then(() => {
        dispatch(Fields.Actions.fetchFields)
        resolve()
      })
    })
  },

  [Fields.Actions.ignoreGeoPackages]({ dispatch }, payload) {
    return new Promise(resolve => {
      FieldsAPI.ignoreGeoPackages(payload).then(() => {
        dispatch(Fields.Actions.fetchFields)
        resolve()
      })
    })
  },

  [Fields.Actions.fetchFields]({ dispatch, commit }) {
    return new Promise((resolve, reject) => {
      try {
        FieldsAPI.list().then(response => {
          commit(Fields.Mutations.setFields, response.data)
          commit(
            Dashboard.Mutations.initializeCropDesignerSettings,
            response.data
          )
          commit(Filter.Mutations.setFields, response.data)
          dispatch(Map.Actions.fetchMapData, true)
          resolve()
        })
      } catch (e) {
        console.log("Fetch fields catch(e): ", e)
        commit(Map.Mutations.setIsFetching, false)
        reject(e)
      }
    })
  },

  [Fields.Actions.fetchField]({ commit }, payload) {
    return new Promise(resolve => {
      FieldsAPI.get(payload.fieldId).then(response => {
        commit(Fields.Mutations.updateFields, response.data)
        resolve()
      })
    })
  },

  [Fields.Actions.fetchFieldsSkinny]({ commit }) {
    // FieldsAPI.list().then(response => {
    //   commit(Fields.Mutations.setFields, response.data)
    // })
    return new Promise(resolve => {
      FieldsAPI.list().then(response => {
        commit(Fields.Mutations.setFields, response.data)
        resolve()
      })
    })
  },

  [Fields.Actions.updateRegenPractice]({ commit }, payload) {
    commit(Fields.Mutations.updateRegenPractice, payload)
  },

  [Fields.Actions.updateUserSustainability]({ commit }, payload) {
    commit(Fields.Mutations.updateUserSustainability, payload)
  },

  [Fields.Actions.clearUserSustainability]({ commit }) {
    commit(Fields.Mutations.clearUserSustainability)
  },

  [Fields.Actions.fetchFeatureFlags]({ commit }) {
    return new Promise(resolve => {
      FieldsAPI.getFeatureFlags().then(response => {
        commit(Fields.Mutations.setFeatureFlags, response.data)
        resolve()
      })
    })
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
}
