import axios from "axios"
import { BASE_URL } from "@/constants"
import store from "@/store"

const baseURL = `${BASE_URL}api/v1/`

const axio = axios.create({ baseURL })

axio.interceptors.request.use(config => {
  config.headers["Authorization"] = `Token ${store.state.User.token}`
  config.maxBodyLength = Infinity
  config.maxContentLength = Infinity
  return config
})

axio.interceptors.response.use(
  response => response,
  error => {
    error.config.retryCount = error.config.retryCount || 0
    // This is meant to retry on "instant" 504s from the AWS load balancer
    // The "Network Error" check is a hack because the load balancer doesn't provide CORS headers
    // and the browser doesn't send anything about the response here in that case
    if (
      (error.message == "Network Error" ||
        (error.response && error.response.status === 504)) &&
      error.config.retryCount < 2
    ) {
      error.config.retryCount += 1
      return axio.request(error.config)
    }
    return Promise.reject(error)
  }
)

export default axio
