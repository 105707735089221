<template>
  <ArvaModal
    name="dataAgreementModal"
    title="Arva Data Privacy and Use Agreement"
    :wide="true"
    :width="700"
    @close-modal="handleCloseModal"
  >
    <div>
      <div class="col-12">
        <div class="row">
          <div class="info-text">
            Please review and accept Arva's Data Privacy and Use Agreement.
            Please contact us at
            <a href="mailto:m.rohlik@arvaintelligence.com"
              >m.rohlik@arvaintelligence.com</a
            >
            if you have any questions.
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="agreement">
              <div class="logo" />
              <h1>DATA PRIVACY AND USE AGREEMENT</h1>
              <h3>You (The “Customer) control your data</h3>
              <p>
                In an increasingly connected world, technology makes it easy for
                you to share your operation’s data with others — if that’s what
                you choose to do. When you entrust your data to Arva
                Intelligence Inc. {“ARVA”} and its subsidiaries we safeguard
                that data and honor the permissions you set for sharing it with
                others. We {the “COMPANY” or “ARVA”} created this agreement to
                be clear about how we manage your data, provide you with details
                about how your data is used and get approval from you to
                anonymize your data and aggregate your data with other data in
                order to train our XAI algorithms and make better, lower risk
                predictions and to help you maximize your profit and operations.
              </p>
              <p>
                This agreement explains:
              </p>
              <ul>
                <li>
                  Your responsibilities for managing your data and sharing
                  permissions, as well as your options in the event that you do
                  not want ARVA to use your data to train it’s XAI models.
                </li>
                <li>The types of data we may collect from you</li>
                <li>How we may use or disclose that data</li>
                <li>
                  Our responsibilities for protecting and maintaining your data
                </li>
              </ul>
              <p>
                <b
                  >By accessing or using any ARVA Data Services, you agree that
                  we may collect and process your personal information for
                  administrative and support purposes as described in our
                  Privacy Policy, and you agree that we may use your data as
                  described below and in the applicable terms of use. If you do
                  not or cannot agree to these uses by ARVA, then you should not
                  use the ARVA Data Services.</b
                >
              </p>
              <h3>Types of data we collect</h3>
              <p>
                We collect two kinds of data through the ARVA Data Services
                application platform, which includes Production Data and
                Administrative Data as outlined below:
              </p>
              <p>
                <b>Production Data</b> is information about the work you do with
                your equipment and the land where you do that work. For example:
              </p>
              <ul>
                <li>Field task and boundary details</li>
                <li>Areas worked and Applied applications</li>
                <li>Typical practices and implementations</li>
                <li>Crop harvested and yield data</li>
                <li>Agronomic inputs applied</li>
                <li>Machine attachments, implements or headers</li>
              </ul>
              <p>
                You can upload Field/Farm file-sets and manage your Production
                Data using the web browser interface at https://cropforce.com.
              </p>
              <p>
                <b>Administrative Data</b> is information that helps us support
                your account and activities in our system.
              </p>
              <p>For example:</p>
              <ul>
                <li>Your data sharing permissions</li>
                <li>Users linked to your account</li>
                <li>Licenses linked to your account</li>
                <li>Number of acres and size of fields</li>
                <li>Information about how you use your account</li>
              </ul>
              <p>
                You can see and manage your Administrative Data directly from
                the username drop-down menu located in the top right corner of
                the Cropforce application window. Please select the MyAccount
                section of the Cropforce application to manage your
                administrative data.
              </p>
              <p>
                We do not use or collect user-generated content. Some of our
                systems enable you to store and share information you or others
                create. This user-generated content includes variable rate
                prescriptions, notes, recordings, photographs, PDFs and other
                file types. We store and share this content only as you direct
                and to comply with court orders and legal or regulatory
                requirements.
              </p>
              <h3>You control who sees your data</h3>
              <p>
                When you give us permission to use your data we keep it secure
                and protected and do not share your data without your written
                permission. We do however anonymize and aggregate your data with
                other data to train our Machine Learning algorithms to better
                service you. The larger the data we get from other Ag partners
                the better our confidence is in providing you the best
                predictions for your money. Here are your options for
                controlling your account information when you use ARVA Data
                Services.
              </p>
              <h3>Sharing data</h3>
              <p>
                You may share and disclose data by including file upload
                permission keys for upload of most types of field files for most
                Major hardware implement companies like John Deere, Raven, New
                Holland, Case and other manufacturer’s by setting permissions
                for ARVA to pull field data from your account automatically. You
                can also share ARVA Predictions, Prescriptions, Insights and
                Exports with other third parties by giving sharing permissions
                within the MyAccount section of the Cropforce application
                platform. Please note that when you share your information with
                someone other than ARVA, the recipient may decide to copy, use,
                modify, or distribute it to others, and ARVA has no control
                over, or responsibility for, any such activities.
              </p>
              <h3>Managing Data</h3>
              <p>
                You may view, analyze, and manage Production Data and some
                Administrative Data in your account via the Cropforce
                Application portal.
              </p>
              <h3>Exporting Data</h3>
              <p>
                You may download and export Production Data files from the
                Cropforce application to load as Prescription files to drive
                field application equipment and predict profit/loss ratios for
                different applications.
              </p>
              <h3>Deleting, Updating, and Amending</h3>
              <p>
                You may request that we delete, update or amend your Production
                Data, and Administrative Data in your account and we will honor
                your request within five business days. Please note that
                deleting data may limit our ability to support you and, in some
                cases, may constitute a termination under the terms of any
                applicable Data Service and Subscription contracts between you
                and ARVA and - subject to any applicable privacy laws - we may
                retain certain basic Administrative data for our record keeping
                purposes.
              </p>
              <h3>To learn from you</h3>
              <ul>
                <li>
                  We may use your data to develop and improve our products and
                  services. For example, analyzing your data may spotlight
                  trends that inform our product support, Agronomists and
                  warranty services, and diagnostic or prognostic activities.
                </li>
                <li>
                  We may combine your data with data from others and include
                  your data in anonymized data sets. These anonymized data sets
                  are proprietary to ARVA. ARVA is free to use and disclose the
                  anonymized data, and ARVA may promote information and services
                  derived from anonymized data. Anonymized data is never
                  traceable back to you or your specific operations.
                </li>
              </ul>
              <h3>To market to you</h3>
              <ul>
                <li>
                  We may use your data to market products and services to you,
                  targeting offerings to match your activity, interests, and
                  location if you provide any applicable consent. We will
                  communicate with you only according to the preferences you set
                  for your account.
                </li>
              </ul>
              <h3>To comply with the law</h3>
              <ul>
                <li>
                  We share your data as required by applicable laws, including
                  data privacy and consumer protection laws. Our privacy
                  statement is available at
                  <a
                    href="https://www.arvaintelligence.com/legal"
                    target="_blank"
                    >www.arvaintelligence.com/legal</a
                  >.
                </li>
                <li>
                  We may review and disclose your data to comply with court
                  orders and legal or regulatory requirements; to prevent
                  injury, death, losses, fraud or abuse; to protect ARVA’s
                  rights or to defend ARVA in legal proceedings; and to comply
                  with requests from you.
                </li>
              </ul>
              <h3>Safeguarding</h3>
              <p>
                We have implemented and will maintain standards and procedures
                designed to prevent misuse of information in your account:
              </p>
              <ul>
                <li>
                  We maintain physical and virtual computer and network security
                  provided by our cloud partners using the best threat
                  protection they can provide.
                </li>
                <li>
                  We educate our employees about the importance of data security
                  and customer service through standard operating procedures and
                  special training programs.
                </li>
                <li>
                  We maintain security standards and procedures to help prevent
                  unauthorized access to information about you, and we update
                  and test our technology to improve the protection of your
                  information.
                </li>
              </ul>
              <h3>Storing and processing</h3>
              <p>
                We store and process data on secure servers in data centers in
                the United States. In the management of our systems network, we
                may move data across jurisdictions and may store or process your
                information outside your home country. By using any ARVA Data
                Services you agree that we may process and store your data in
                the United States.
              </p>
              <h3>Deleting</h3>
              <p>
                Please note that ARVA may retain data unless you delete your
                information as described above. After expiration of any
                applicable Data Service or service contract, we may delete data
                at our discretion and subject to requirements in any applicable
                privacy, consumer protection, or other laws.
              </p>
              <h3>ARVA PRIVACY STATEMENT</h3>
              <p>
                In providing the ARVA Data Services, we may receive, collect,
                use, manage, analyze, segment, index, transmit, transfer, store
                and process personal information which can include names,
                contact data (telephone number, e-mail, address), and in some
                cases usage data (including website and mobile app use). Our
                Privacy Policy is available at
                <a href="https://www.arvaintelligence.com/legal"
                  >www.arvaintelligence.com/legal.</a
                >
              </p>
              <h3>CUSTOMER AGREEMENT AND ARVA COMMITMENT</h3>
              <p>
                By uploading data into your account you agree to accept the
                terms as outlined in our data privacy policy and you acknowledge
                our rights to use your data to improve our ability to service
                you and acknowledge that your data may be used in anonymized and
                aggregated format to help improve our services and our forward
                looking predictive models through Machine Learning Algorithms.
                You also understand that we pledge to keep your data safe and
                secure and that we practice strict account confidentiality as a
                part of how we do business.
              </p>
              <p>Thank You for being a valued Customer!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ArvaModalSaveFooter
      confirmText="I Accept"
      cancelText="Cancel"
      @on-cancel="handleCloseModal"
      @on-confirm="handleAcceptAgreement"
    />
  </ArvaModal>
</template>

<script>
import { mapActions, mapState } from "vuex"
import OrganizationAPI from "@/api/OrganizationAPI"
import { User } from "@/store/modules"
import ArvaModal from "./ArvaModal"
import ArvaModalSaveFooter from "./ArvaModalSaveFooter"

export default {
  name: "DataAgreementModal",
  components: { ArvaModal, ArvaModalSaveFooter },

  computed: {
    ...mapState({
      organization: state => state.Organization.organization,
    }),
  },

  methods: {
    ...mapActions({
      fetchUser: User.Actions.fetchUser,
    }),

    handleCloseModal() {
      this.$emit("close-modal")
    },

    handleAcceptAgreement() {
      OrganizationAPI.acceptDataAgreement(this.organization.id).then(() =>
        this.fetchUser()
      )
      this.$emit("accepted-agreement")
    },
  },
}
</script>

<style scoped>
.info-text {
  margin: 20px 0px;
  font-size: 15px;
  text-align: center;
}

.agreement {
  padding: 20px 35px;
  background: #f9f9f9;
  border: 1pt solid #f0f0f0;
  max-height: 550px;
  overflow-y: scroll;
}

.agreement .logo {
  background-image: url("/assets/images/logos/arva-logo.png");
  background-size: cover;
  height: 40px;
  width: 120px;
  margin-left: -5px;
  margin-bottom: 12px;
}

.agreement h1 {
  margin-top: 20px;
  font-size: 22px;
  font-weight: 500;
}

.agreement h3 {
  margin-top: 20px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500;
}

.agreement ul {
  margin: 10px 20px;
}
</style>
