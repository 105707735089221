import API from "./API";

const postClimateOrgTransfer = (payload) =>
  API.post("/climate-org-transfer/", payload);

const fetchClimateURLs = () => API.get("/climateurl");

const fetchClimateOrgs = () => API.get("/climate-org");

const importClimateData = (dataType, payload) =>
  API.post(`/climate-org/import_data/?data_type=${dataType}`, payload);

const configureClimateOrg = (payload) =>
  API.post("/climate-org/set_org/", payload);

export default {
  postClimateOrgTransfer,
  fetchClimateURLs,
  fetchClimateOrgs,
  importClimateData,
  configureClimateOrg,
};
