import API from "./API"

const getSamplingPlans = payload =>
  API.get("soil-sample-order/", { params: payload })

const getSamplingLocations = payload =>
  API.get(`soil-sample-order/${payload.id}/`, { payload })

const updateSamplingZone = payload =>
  API.put(`soil-sample-order/${payload.id}/`, payload)

const postSamplingPlan = payload => API.post("/soil-sample-order/", payload)

const fetchSampleLocationExport = payload =>
  API.post("/sampling-plan-export/", payload)

const postSoilLabs = payload => API.post("soil-sample-lab/", payload)

const postSoilCollectors = payload =>
  API.post("soil-sample-collector/", payload)

const getSoilCollectors = payload =>
  API.get("soil-sample-collector/", { params: payload })

const postSoilSampleLocation = payload =>
  API.post("soil-sample-location/", payload)

const postUpdateLocationTest = payload =>
  API.post("soil-sample-test-selection/", payload)

const fetchQRCodeLabelData = payload => API.post("qr-code-label-data/", payload)

export default {
  getSamplingPlans,
  getSamplingLocations,
  postSamplingPlan,
  fetchSampleLocationExport,
  postSoilLabs,
  postSoilCollectors,
  updateSamplingZone,
  postUpdateLocationTest,
  fetchQRCodeLabelData,
  getSoilCollectors,
  postSoilSampleLocation,
}
