<template>
  <div class="col-12">
    <v-row>
      <DashboardTitle title="Set FSA Attributes for Selected Fields" />
      <v-col class="d-flex justify-end">
        <v-btn class="my-auto" text @click="handleWizardReturn">
          <v-icon>mdi-chevron-left</v-icon>
          Return To Practices
        </v-btn>
      </v-col>

    </v-row>
    <v-row class="d-flex align-items-center mx-10">
      <label class="my-auto" for="year-select">Year: </label>
      <v-select id="year-select" v-model="selectedYear" :items="availableYears ? availableYears.slice().reverse() : []"
        item-text="year" item-value="year" dense class="ml-5 my-auto" style="max-width: 100px;"></v-select>
      <v-tooltip right color="black">
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="ml-2 pb-1" v-bind="attrs" v-on="on">
            mdi-information
          </v-icon>
        </template>
        <span>
          Use the <strong>earliest year</strong> for which the FSA data is
          valid.
          <br />
          If FSA data changes in later years, add data for updated fields in those years.
          <br />
          View most recent data for all fields with <em>"View latest".</em>
        </span>
      </v-tooltip>
      <v-switch v-model="viewLatest" label="View latest" hide-details dense class="ml-5 my-auto" />
      <v-col class="d-flex justify-end">
        <v-btn @click="saveFSAData" :disabled="savingFSAData || viewLatest" color="green lighten-4"
          class="mx-5 my-auto">
          <span>Save Changes</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-divider />

    <v-row v-if="loadingFSAData || savingFSAData">
      <v-progress-circular indeterminate color="green lighten-4" class="mt-10 mx-auto" size="80"></v-progress-circular>
    </v-row>
    <v-row v-else-if="selectedYear !== null">
      <v-simple-table dense class="ml-auto mr-auto">
        <thead>
          <tr>
            <th>Field</th>
            <th>Farm</th>
            <th></th>
            <th>State</th>
            <th>County</th>
            <th>FSA Producer Name</th>
            <th>FSA Farm Name</th>
            <th>FSA Farm ID</th>
            <th>FSA Tract ID</th>
            <th>FSA Field ID</th>
            <th v-if="viewLatest">Year</th>
            <th v-else></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(field, index) in selectedFields" :key="field.id">
            <td>{{ field.name }}</td>
            <td>{{ field.farm.name }}</td>
            <td><v-img :src="field.boundary_image" alt="Boundary Image" style="width: 50px"></v-img></td>
            <template v-if="viewLatest">
              <td>{{ getFsaData(field.id).state }}</td>
              <td>{{ getFsaData(field.id).county }}</td>
              <td>{{ getFsaData(field.id).fsa_producer_name }}</td>
              <td>{{ getFsaData(field.id).fsa_farm_name }}</td>
              <td>{{ getFsaData(field.id).fsa_farm_id }}</td>
              <td>{{ getFsaData(field.id).fsa_tract_id }}</td>
              <td>{{ getFsaData(field.id).fsa_field_id }}</td>
              <td>{{ getFsaData(field.id).year }}</td>
            </template>
            <template v-else>
              <td>
                <v-select v-if="candidateStates[field.id].length > 1" v-model="getFsaData(field.id).state"
                  :items="candidateStates[field.id]" item-text="state" item-value="state" dense></v-select>
                <span v-else>{{ getFsaData(field.id).state }}</span>
              </td>
              <td>
                <v-select v-if="candidateCounties[field.id].length > 1" v-model="getFsaData(field.id).county"
                  :items="candidateCounties[field.id]" item-text="county" item-value="county" dense></v-select>
                <span v-else>{{ getFsaData(field.id).county }}</span>
              </td>
              <td><v-text-field dense v-model="getFsaData(field.id).fsa_producer_name"></v-text-field></td>
              <td><v-text-field dense v-model="getFsaData(field.id).fsa_farm_name"></v-text-field></td>
              <td><v-text-field dense v-model="getFsaData(field.id).fsa_farm_id" :rules="[numberValidator]"
                  type="number"></v-text-field></td>
              <td><v-text-field dense v-model="getFsaData(field.id).fsa_tract_id" :rules="[numberValidator]"
                  type="number"></v-text-field></td>
              <td><v-text-field dense v-model="getFsaData(field.id).fsa_field_id" :rules="[numberValidator]"
                  type="number"></v-text-field></td>
              <td>
                <v-tooltip v-if="getFsaData(field.id).from_server" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn small plain color="red lighten-2" v-on="on" v-bind="attrs" @click="deleteFSAData(field.id)">
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete FSA data for year {{ selectedYear }} for field <em>{{ field.name }}</em></span>
                </v-tooltip>
              </td>
            </template>
          </tr>
        </tbody>
      </v-simple-table>
    </v-row>


  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import DefaultsAPI from "@/api/DefaultsAPI"
import { Filter } from "@/store/modules"
import { numberValidator } from '@/utility'
import DashboardTitle from "@/components/dashboard/DashboardTitle"

export default {
  data() {
    return {
      loadingFSAData: false,
      savingFSAData: false,
      viewLatest: false,
      selectedYear: null,
      candidateStates: [],
      candidateCounties: [],
      fsaDataDict: {},
      numberValidator,
    }
  },
  components: {
    DashboardTitle,
  },
  computed: {
    ...mapState({
      availableYears: state => state.Organization.organization.years,

    }),
    ...mapGetters({
      selectedFields: Filter.Getters.getSelectedFields,
    }),
  },
  mounted() {
    this.fetchFSAData();
  },
  methods: {
    getFsaData(fieldId) {
      if (!this.selectedYear) {
        return null;
      }

      if (this.viewLatest) {
        const completeYears = Object.keys(this.fsaDataDict[fieldId]).filter(year => this.isComplete(this.fsaDataDict[fieldId][year]));
        if (completeYears.length === 0) {
          let blankData = this.blankFsaData(fieldId);
          blankData.year = 'n/a';
          blankData.state = '';
          blankData.county = '';
          return blankData;
        }

        const latestYear = Math.max(...completeYears);
        return this.fsaDataDict[fieldId][latestYear];

      } else {

        if (!this.fsaDataDict[fieldId] || !this.fsaDataDict[fieldId][this.selectedYear]) {
          if (!this.fsaDataDict[fieldId]) {
            this.$set(this.fsaDataDict, fieldId, {});
          }

          this.$set(this.fsaDataDict[fieldId], this.selectedYear, this.blankFsaData(fieldId));
        }
        return this.fsaDataDict[fieldId][this.selectedYear];
      }
    },
    blankFsaData(fieldId) {
      return {
        field: fieldId,
        state: this.candidateStates[fieldId].length === 1 ? this.candidateStates[fieldId][0] : '',
        county: this.candidateCounties[fieldId].length === 1 ? this.candidateCounties[fieldId][0] : '',
        fsa_producer_name: '',
        fsa_farm_name: '',
        fsa_farm_id: null,
        fsa_tract_id: null,
        fsa_field_id: null,
        year: this.selectedYear,
      };
    },
    async fetchFSAData() {
      this.loadingFSAData = true;
      let yearsWithData = [];
      const response = await DefaultsAPI.getFsaFieldData();
      const { fsa_data, candidate_states, candidate_counties } = response.data;
      this.candidateStates = candidate_states;
      this.candidateCounties = candidate_counties;

      this.fsaDataDict = fsa_data.reduce((acc, entry) => {
        if (!acc[entry.field]) {
          acc[entry.field] = {};
        }
        yearsWithData.push(entry.year);
        entry.from_server = true;
        acc[entry.field][entry.year] = entry;
        return acc;
      }, {});

      if (!this.selectedYear) {
        this.selectedYear = yearsWithData.length ? Math.min(...yearsWithData).toString() : Math.min(...this.availableYears).toString();
      }
      this.loadingFSAData = false;
    },
    isComplete(fieldFsaData) {
      return Object.values(fieldFsaData).every(value => value !== null && value !== '');
    },
    handleWizardReturn() {
      this.$router.push(`/historical-practices`)
    },
    async saveFSAData() {
      this.savingFSAData = true;
      for (const field of this.selectedFields) {
        const fieldFsaData = this.fsaDataDict[field.id][this.selectedYear]
        if (this.isComplete(fieldFsaData)) {
          if (fieldFsaData.from_server) {
            await DefaultsAPI.updateFsaFieldData(fieldFsaData.id, fieldFsaData);
          } else {
            await DefaultsAPI.saveFsaFieldData(fieldFsaData);
          }
        }
      }
      await this.fetchFSAData();

      this.savingFSAData = false;
    },
    async deleteFSAData(fieldId) {
      const fsaToDelete = this.fsaDataDict[fieldId][this.selectedYear];
      await DefaultsAPI.deleteFsaFieldData(fsaToDelete.id);
      this.fsaDataDict[fieldId][this.selectedYear] = this.blankFsaData(fieldId);
    },
  },
}
</script>