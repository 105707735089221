<template>
  <tr>
    <td><img :src="field.boundary_image" style="width:50px" /></td>
    <td class="nowrap">{{ field.name }}</td>
    <td class="text-right">{{ field.acreage | prettyInteger }}</td>
    <td>{{ crop | crop }}</td>
    <td>{{ variety }}</td>
    <td class="text-right">
      {{ (fieldYield / field.acreage) | prettyInteger }}
    </td>
    <td class="text-right">
      {{ fieldYield | prettyInteger }}
    </td>
    <td class="text-right">{{ revenue | intCurrency }}</td>
    <td class="text-right">{{ seedCost | intCurrency }}</td>
    <td class="text-right">{{ fertCost | intCurrency }}</td>
    <td class="text-right">{{ opCost | intCurrency }}</td>
    <td class="text-right">{{ grossProfit | intCurrency }}</td>
    <td class="text-right gross-profit">
      {{ grossProfitPerAcre | intCurrency }}
    </td>
  </tr>
</template>

<script>
export default {
  name: "HistoricalProfitAndLossRow",
  props: ["field", "year"],

  computed: {
    fieldData() {
      return this.field.historicalROI[this.year]
    },

    crop() {
      return this.fieldData && this.fieldData.crop
    },

    variety() {
      return this.fieldData && this.fieldData.seed_varieties
    },

    fieldYield() {
      return this.fieldData && this.fieldData.field_yield
    },

    revenue() {
      return this.fieldData && this.fieldData.revenue
    },

    seedCost() {
      return this.fieldData && this.fieldData.seed_cost
    },

    fertCost() {
      return this.fieldData && this.fieldData.fert_cost
    },

    opCost() {
      return this.fieldData && this.fieldData.opcost
    },

    grossProfit() {
      return this.fieldData && this.fieldData.profit
    },

    grossProfitPerAcre() {
      return this.fieldData && this.fieldData.profit_per_acre
    },
  },
}
</script>

<style scoped>
.gross-profit {
  background: #d1ecfe !important;
}

.nowrap {
  white-space: nowrap;
}
</style>
