<template>
  <div>
    <div v-if="showImagery">
      <Permissions package="imagery" />
      <div class="d-flex justify-content-between mb-4">
        <DashboardTitle title="Order Field Imagery" faClass="fa-globe" />
        <div class="planet-logo" />
        <button
          v-if="!isReadOnly"
          class="btn btn-primary btn-sm download-text"
          :class="{ disabled: numCheckedFields === 0 }"
          @click="handleOpenOrderModal"
        >
          <span>Order Imagery For Selected Fields</span>
        </button>
      </div>

      <v-simple-table
        class="selector-table"
        fixed-header
        height="calc(100vh - 250px)"
      >
        <thead>
          <tr>
            <th>
              <input
                @click="selectAllCheckBox"
                type="checkbox"
                name="selected"
                ref="fieldSelectAllCheckbox"
              />
            </th>
            <th width="120" />
            <th class="hover" @click="handleSortFieldName">
              Field
              <i
                class="fa hover"
                :class="sortIcon(sortByFieldName)"
                @click="handleSortFieldName"
              />
            </th>
            <th class="hover" @click="handleSortAcreage">
              Acreage
              <i
                class="fa hover"
                :class="sortIcon(sortByAcreage)"
                @click="handleSortAcreage"
              />
            </th>
            <th class="hover" @click="handleSortFarm">
              Farm
              <i
                class="fa hover"
                :class="sortIcon(sortByFarm)"
                @click="handleSortFarm"
              />
            </th>
            <th>Orders</th>
          </tr>
        </thead>
        <tbody>
          <FieldImageryOrderRow
            v-for="field in sortedFields"
            :key="field.id"
            :field="field"
            :orders="orders"
            :newContractStartDate="newContractStartDate"
            :newContractEndDate="newContractEndDate"
            ref="fieldListRows"
            @checked="checked"
            @unchecked="unchecked"
          />
        </tbody>
      </v-simple-table>

      <FieldImageryOrderModal
        v-if="orderModalOpen"
        :totalAcres="totalAcres"
        :fieldIds="Object.keys(fieldsToOrder)"
        :contractStartDate="newContractStartDate"
        :contractEndDate="newContractEndDate"
        @close-modal="closeOrderModal"
      />
    </div>
  </div>
</template>

<script>
import _ from "lodash"
import moment from "moment"
import { mapGetters, mapState } from "vuex"

import PlanetOrderAPI from "@/api/PlanetOrderAPI"
import DashboardTitle from "@/components/dashboard/DashboardTitle"
import FieldImageryOrderModal from "@/components/fieldImageryOrder/FieldImageryOrderModal"
import FieldImageryOrderRow from "@/components/fieldImageryOrder/FieldImageryOrderRow"
import Permissions from "@/components/permissions/Permissions"
import { Filter } from "@/store/modules"

export default {
  name: "OrderImageryView",
  components: {
    DashboardTitle,
    FieldImageryOrderModal,
    FieldImageryOrderRow,
    Permissions,
  },

  data() {
    return {
      sortByAcreage: null,
      sortByFarm: null,
      sortByFieldName: null,
      orderModalOpen: false,
      fieldsToOrder: {},
      orders: [],
    }
  },

  computed: {
    ...mapState({
      isReadOnly: state => state.User.user.permission === "Read-Only",
      showImagery: state => state.Organization.organization.showImagery,
    }),
    ...mapGetters({
      activeFields: Filter.Getters.getSelectedFields,
    }),

    sortedFields() {
      const sortValues = {
        acreage: this.sortByAcreage,
        "farm.name": this.sortByFarm,
        name: this.sortByFieldName,
      }
      let sortColumns = []
      let sortDirections = []
      for (const column in sortValues) {
        if (sortValues[column] !== null) {
          sortColumns.push(column)
          sortDirections.push(sortValues[column])
        }
      }
      const res = _.orderBy(this.activeFields, sortColumns, sortDirections)
      return res
    },

    numCheckedFields() {
      return Object.keys(this.fieldsToOrder).length
    },
    totalAcres() {
      let fieldIds = Object.keys(this.fieldsToOrder)
      let tempFields = this.activeFields.filter(obj => {
        if (fieldIds.includes(obj.id.toString())) {
          return obj
        }
      })
      let acresArray = tempFields.map(obj => {
        return obj.acreage
      })
      let acres = _.sum(acresArray)
      return acres
    },

    newContractStartDate() {
      const now = new Date()
      const month = now.getMonth() + 1
      const dayOfMonth = now.getDate()
      const year = now.getFullYear()
      if (dayOfMonth >= 16) {
        const newYear = month === 12 ? year + 1 : year
        const newDate = new Date()
        newDate.setFullYear(newYear)
        newDate.setMonth(month)
        newDate.setDate(1)
        return newDate
      } else {
        return new Date(`${year}-${month}-16`)
      }
    },

    newContractEndDate() {
      return moment(this.newContractStartDate)
        .add(1, "years")
        .subtract(1, "days")
    },
  },

  methods: {
    handleOpenOrderModal() {
      if (this.numCheckedFields > 0) this.orderModalOpen = true
    },

    closeOrderModal() {
      this.orderModalOpen = false
      this.fetchOrders()
      this.deselectAllCheckbox()
    },

    checked(id) {
      const fieldsToOrder = {
        ...this.fieldsToOrder,
      }
      fieldsToOrder[id] = true
      this.fieldsToOrder = fieldsToOrder
    },

    unchecked(id) {
      const fieldsToOrder = {
        ...this.fieldsToOrder,
      }
      delete fieldsToOrder[id]
      this.fieldsToOrder = fieldsToOrder
    },

    selectAllCheckBox() {
      const checked = this.$refs.fieldSelectAllCheckbox.checked
      this.$refs.fieldListRows.forEach(fieldListRow =>
        fieldListRow.setCheckbox(checked)
      )

      if (!checked) this.fieldsToOrder = []
    },

    deselectAllCheckbox() {
      this.$refs.fieldListRows.forEach(fieldListRow =>
        fieldListRow.setCheckbox(false)
      )
    },

    sortIcon(sortValue) {
      if (sortValue === "asc") return "fa-sort-up"
      else if (sortValue === "desc") return "fa-sort-down"
      return "fa-sort"
    },

    handleSortAcreage() {
      if (!this.sortByAcreage) this.sortByAcreage = "asc"
      else if (this.sortByAcreage === "asc") this.sortByAcreage = "desc"
      else if (this.sortByAcreage === "desc") this.sortByAcreage = null
    },

    handleSortFarm() {
      if (!this.sortByFarm) this.sortByFarm = "asc"
      else if (this.sortByFarm === "asc") this.sortByFarm = "desc"
      else if (this.sortByFarm === "desc") this.sortByFarm = null
    },

    handleSortFieldName() {
      if (!this.sortByFieldName) this.sortByFieldName = "asc"
      else if (this.sortByFieldName === "asc") this.sortByFieldName = "desc"
      else if (this.sortByFieldName === "desc") this.sortByFieldName = null
    },

    fetchOrders() {
      PlanetOrderAPI.get().then(resp => (this.orders = resp.data))
    },
  },

  mounted() {
    this.fetchOrders()
  },
}
</script>

<style scoped>
.planet-logo {
  width: 122px;
  height: 50px;
  background-image: url("/assets/images/logos/planet_logo.svg");
  background-size: 67%;
  background-color: rgba(0, 0, 0, 0.7);
  background-position: center;
  border-radius: 7px;
}
</style>
