<template>
  <div class="label-wrapper">
    <div class="px-3 py-2">
      <div class="row-container d-flex">
        <div class="qr-container">
          <div class="qr-text">Sample #{{ data.id }}</div>
          <img :src="data.url_path" :width="128" :height="128" />
          <div class="qr-text">{{ data.qr_code }}</div>
        </div>
        <div class="double-container">
          <div class="address-container">
            <v-simple-table class="qr_table">
              <tr>
                <td>
                  {{ data.order__lab__name }}
                </td>
              </tr>
              <tr>
                <td>
                  {{ data.order__lab__address }}
                </td>
              </tr>
              <tr>
                <td v-if="data.order__lab__city">
                  {{ data.order__lab__city }},
                  {{ data.order__lab__state }}&nbsp;{{ data.order__lab__zip }}
                </td>
              </tr>
            </v-simple-table>
          </div>
          <div class="info-container">
            <v-simple-table class="qr_table">
              <tr>
                <td><b>Field:</b> {{ data.field__name | shortString }}</td>
              </tr>
              <tr>
                <td><b>Client:</b> {{ data.field__farm__client__name }}</td>
              </tr>
              <tr>
                <td><b>Plan:</b> {{ data.order__name | shortString }}</td>
              </tr>
              <tr>
                <td><b>Test Types:</b> {{ testTypes | shortString }}</td>
              </tr>
              <tr>
                <td>
                  <b>Corporation: </b>
                  {{ data.order__org_node__corporation__name | shortString }}
                </td>
              </tr>
              <tr>
                <td>
                  <b>Lng/Lat:</b>
                  {{ data.lnglat }}
                </td>
              </tr>
            </v-simple-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "QRCodeLabel",
  props: ["data"],
  computed: {
    testTypes() {
      const testMapper = {
        "1": "Bulk Density",
        "2": "SOC",
        "3": "Haney Test"
      }
      const testsArray = this.data.sample_tests.map(testId => testMapper[testId])

      return testsArray.join(", ")
    }
  },
}
</script>
<style>
@page {
  size: 8.5in 11in;
  margin: 0;
}
</style>
<style scoped>
.label-wrapper {
  width: 500px;
  height: 235px;
  padding-bottom: 20px;
}

.address-container {
  margin-left: 0px;
  padding-left: 0px !important;
}

.double-container {
  padding-top: 12px;
  padding-left: 28px;
}

.info-container {
  padding-top: 12px;
}

.qr-container {
  padding-top: 40px;
  padding-left: 12px;
}

.arva-logo {
  position: absolute;
  top: 350px;
  right: 340px;
}

.logo-size {
  height: 160px;
}

.row {
  width: 650px;
}

.qr-text {
  text-align: center;
}
</style>
