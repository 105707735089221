<template>
  <div>
    <Permissions package="base" />
    <div v-if="showBase" class="col-12">
      <div class="float-left" v-if="dataImportModalOpen">
        <file-upload
          ref="upload"
          v-model="files"
          :accept="eligibleFiles"
          :drop="true"
          :multiple="true"
          @input="updatedFile"
          @input-filter="fileInputFilter"
        />
      </div>

      <AlertErrorModal
        v-if="alertErrorModalOpen"
        titleText="Data Import Error Modal"
        :errorMessage="errorMessage"
        @close-modal="alertErrorModalOpen = false"
      />

      <DataAgreementModal
        v-if="dataAgreementModalOpen"
        @close-modal="dataAgreementModalOpen = false"
        @accepted-agreement="handleAcceptedDataAgreement"
      />

      <DataImportModal
        v-if="dataImportModalOpen"
        :initialDatasetType="initialDatasetType"
        :initialState="initialState"
        :orgNodes="orgNodes"
        :datasetTypes="datasetTypes"
        :dropActive="$refs.upload && $refs.upload.dropActive"
        :isUploading="isUploading"
        @close-modal="handleCloseDataImportModal"
        @change-dataset-type="changeDatasetType"
        @change-confirmation-client-specs="changeConfirmationClientSpecs"
        @change-org-id="changeOrgId"
        @open-upload="openUpload"
      />

      <UploadMappingModal
        v-if="mappingModalOpen"
        @close-modal="handleCloseMappingModal"
      />

      <UploadDetailModal
        v-if="detailsModalOpen"
        @close-modal="handleCloseDetailsModal"
      />

      <UploadFieldDetailModal
        v-if="detailsByFieldModalOpen"
        :uploadByFieldId="selectedUploadByFieldId"
        @get-signed-url="getSignedUrl"
        @close-modal="handleCloseDetailsByFieldModal"
      />

      <div v-if="isAssigned" class="row">
        <div class="col-9">
          <h2>{{ showPageTitle }}</h2>
        </div>
        <div class="col-3">
          <v-switch
            class="float-right mr-3"
            v-model="showByField"
            inset
            :label="showDataBtnText"
            @click="showUploadsByField"
          ></v-switch>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <div class="row">
            <button
              id="my-computer-btn"
              class="col-sm btn uploads-api-btns"
              v-bind:style="{
                'background-color':
                  dataImportSource === null ? '#7774e7' : '#ffffff',
              }"
              @click="handleOpenDirectUploads"
            >
              <div class="fa fa-laptop" />
              My Computer
            </button>

            <button
              id="jd-btn"
              class="col-sm btn uploads-api-btns"
              v-bind:style="{
                'background-color':
                  dataImportSource === 'john-deere-api' ? '#7774e7' : '#ffffff',
              }"
              @click="handleOpenJDUploads"
            >
              <div class="logo logo-john-deere" />
              John Deere
            </button>

            <button
              id="leaf-btn"
              class="col-sm btn uploads-api-btns"
              v-bind:style="{
                'background-color':
                  dataImportSource === 'leaf-api' ? '#7774e7' : '#ffffff',
              }"
              @click="handleOpenLeafUploads"
            >
              <div class="logo logo-climate" />
              Climate
            </button>
          </div>

          <div v-if="uploads != null && uploads.length > 0 && !showSpinner" class="row mb-1">
            <div class="col nameSearchBar-header">
              <SearchBar
                :searchString="searchString"
                @searching="handleSearch"
              />
            </div>

            <div v-if="!isReadOnly" class="col" align="right">
              <button
                class="btn btn-primary"
                @click="handleGotoApiManagementCenter"
              >
                Manage API's
              </button>

              <button class="btn btn-primary" @click="handleOpenImportModal">
                Import Data
              </button>
            </div>
          </div>

          <div class="uploadTable">
            <div v-if="showSpinner" class="zero-state">
              <div
                class="spinner-border spinner-border-sm ml-1"
                role="status"
              ></div>
            </div>
            <div v-if="!showSpinner">
              <div v-if="showByField">
                <UploadRowByField
                  v-for="uploadByField in searchFilteredItems"
                  :key="uploadByField.id"
                  :currentPage="currentPage"
                  :uploadByField="uploadByField"
                  @selected-upload-by-field-id="updateSelectedUploadByFieldId"
                />
              </div>
              <div v-else>
                <UploadRow
                  v-for="upload in searchFilteredItems"
                  :key="upload.id"
                  :currentPage="currentPage"
                  :upload="upload"
                />
              </div>
              <div v-if="uploads != null && uploads.length === 0 && loaded" class="zero-state">
                <div v-if="showByField">
                  Raw Data Uploads Have Not Yet Been Split To Field Boundaries
                </div>

                <div v-else-if="dataImportSource === 'leaf-api'">
                  Climate Data Can Take Several Hours to Appear After Enabling
                  Sync
                </div>
                <div v-else>
                  Your organization has not uploaded any data yet
                </div>
                <button
                  v-if="!isReadOnly"
                  class="btn btn-primary"
                  @click="handleOpenImportModal"
                >
                  Get Started
                </button>
              </div>
            </div>
          </div>

          <div v-if="uploads != null && uploads.length > 0 && !showSpinner">
            <div class="col" align="right">
              <v-btn
                v-if="cameFromEnrollment"
                class="mr-2"
                @click="handleEnrollmentClick"
                ><v-icon>mdi-arrow-left-circle</v-icon>
                Back to Enrollment
              </v-btn>
              <button
                v-if="showPrevButton"
                class="btn previous"
                @click="loadPrevPage"
              >
                Prev
              </button>
              <button
                v-if="showNextButton"
                class="btn next"
                @click="loadNextPage"
              >
                Next
              </button>
            </div>
            <div class="mb-1 mr-5" align="right">
              <small>{{ showPaginationInfo }}</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JSZip from "jszip"
import moment from "moment"
import { mapActions, mapGetters, mapMutations, mapState } from "vuex"
import { Filter, Uploads } from "@/store/modules"
import DataAgreementModal from "@/components/modals/DataAgreementModal"
import DataImportModal from "@/components/modals/DataImportModal"
import UploadDetailModal from "@/components/modals/UploadDetailModal"
import UploadFieldDetailModal from "@/components/modals/UploadFieldDetailModal"
import UploadMappingModal from "@/components/modals/UploadMappingModal"
import UploadRow from "@/components/uploads/UploadRow"
import UploadRowByField from "@/components/uploads/UploadRowByField"
import SearchBar from "@/components/misc/SearchBar"
import UploadsAPI from "@/api/UploadsAPI"
import router from "@/router"
import AlertErrorModal from "@/components/modals/AlertErrorModal"
import Permissions from "@/components/permissions/Permissions"
import {
  setIntervalAsync,
  clearIntervalAsync,
} from "set-interval-async/dynamic"
export default {
  components: {
    UploadDetailModal,
    UploadFieldDetailModal,
    UploadMappingModal,
    UploadRow,
    UploadRowByField,
    DataAgreementModal,
    DataImportModal,
    SearchBar,
    AlertErrorModal,
    Permissions,
  },

  data() {
    return {
      currentPage: 1,
      totalUploads: 0,
      showPrevButton: false,
      showNextButton: false,
      showPaginationInfo: null,
      dataAgreementModalOpen: false,
      dataImportModalOpen: false,
      initialDatasetType: null,
      initialState: null,
      isUploading: false,
      files: [],
      fetchIntervalID: null,
      fetchJohnDeereID: null,
      loaded: false,
      dataImportSource: null,
      /*dataImportSource:
        localStorage.getItem("dataImportSource") === "john-deere-api"
          ? "john-deere-api"
          : null,*/
      searchString: "",
      showSpinner: false,
      sourceButtonColor: "#ffffff",
      tab: 0,
      tabTitles: [
        { index: 0, name: "My Computer" },
        { index: 1, name: "John Deere" },
        { index: 2, name: "Climate" },
      ],
      showByField: false,
      selectedUploadByFieldId: null,
      alertErrorModalOpen: false,
      titleText: null,
      eligibleFiles: ".zip, .shp, .shx, .dbf, .prj",
      confirmationClientSpecs: {},
    }
  },

  computed: {
    ...mapGetters({
      orgNodes: Filter.Getters.getSelectedOrgNodes,
      selectedFields: Filter.Getters.getSelectedFields,
    }),

    ...mapState({
      isAssigned: state => state.User.user.is_in_assigned_org,
      isReadOnly: state => state.User.user.permission === "Read-Only",
      isAgreementSigned: state =>
        !!state.User.user.arva_data_agreement_accepted_at,
      datasetTypes: state => state.Uploads.datasetTypes,
      detailsModalOpen: state => !!state.Uploads.detailsModalUploadID,
      detailsByFieldModalOpen: state =>
        !!state.Uploads.detailsModalUploadFieldID,
      johnDeereStatus: state => state.Uploads.johnDeereStatus,
      mappingModalOpen: state => !!state.Uploads.mappingModalUploadID,
      organization: state => state.Organization.organization,
      showBase: state => state.Organization.organization.showBase,
      uploads: state => state.Uploads.uploads,
      year: state => state.Organization.year,
    }),

    searchFilteredItems() {
      if (!this.searchString) return this.uploads
      const searchString = this.searchString.toLocaleLowerCase()

      if (this.showByField) {
        return this.uploads.filter(function (uploadByField) {
          return (
            uploadByField.field.name.toLowerCase().includes(searchString) ||
            uploadByField.gpkg.upload.name
              .toLowerCase()
              .includes(searchString) ||
            uploadByField.gpkg.upload.dataset_type_display
              .toLowerCase()
              .includes(searchString)
          )
        })
      } else {
        return this.uploads.filter(function (upload) {
          return (
            upload.name.toLowerCase().includes(searchString) ||
            upload.stage_display.toLowerCase().includes(searchString)
          )
        })
      }
    },
    cameFromEnrollment() {
      if (router.history.router.prevRoute.path === "/historical-practices")
        return true
      return false
    },
    /*dataImportSource(){

      if(localStorage.getItem("dataImportSource") === "john-deere-api"){
        return "john-deere-api"
      }else if(localStorage.getItem("dataImportSource")==="leaf-api"){
        return "leaf-api"
      }else{
        return null
      }

    }*/
    showDataBtnText() {
      if (this.showByField) return "Switch to Show By Org"
      return "Switch to Show By Field"
    },
    showPageTitle() {
      if (this.showByField) return "Data Uploads Clipped by Field and Year"
      return "Raw Data Upload"
    },
  },

  watch: {
    visible() {
      this.searchString = ""
    },
  },

  methods: {
    ...mapActions({
      createUpload: Uploads.Actions.create,
      fetchUploads: Uploads.Actions.fetch,
      fetchJohnDeereStage: Uploads.Actions.fetchJohnDeereStage,
      listDatasetTypes: Uploads.Actions.listDatasetTypes,
    }),

    ...mapMutations({
      setDetailsModalUploadID: Uploads.Mutations.setDetailsModalUploadID,
      setDetailsModalUploadFieldID:
        Uploads.Mutations.setDetailsModalUploadFieldID,
      setMappingModalUploadID: Uploads.Mutations.setMappingModalUploadID,
      setUploadPercentage: Uploads.Mutations.setUploadPercentage,
      setUploads: Uploads.Mutations.setUploads,
    }),

    resetState() {
      this.currentPage = 1
      this.dataImportModalOpen = false
      this.datasetType = null
      this.files = []
      this.isUploading = false
      this.orgId = null
      this.setUploadPercentage(0)
    },

    async loadPrevPage() {
      this.currentPage -= 1
      this.showSpinner = true
      await this.fetchUploadsWithPagination()
      this.showSpinner = false
    },

    async loadNextPage() {
      this.currentPage += 1
      this.showSpinner = true
      await this.fetchUploadsWithPagination()
      this.showSpinner = false
    },

    async fetchUploadsWithPagination() {
      if (this.showByField) {
        let payload = {
          importSource: localStorage.getItem("dataImportSource"),
          currentPage: this.currentPage,
          fieldIds: this.selectedFields.map(field => field.id),
          year: Number(this.year),
        }
        await UploadsAPI.getByField(payload).then(response => {
          // Github ticket 478: Data import page switches back from MyJD to MyComputer on the list of data imports
          //  Added this check to make sure we only setUploads for the correct tab that we're on.
          if (
            payload.importSource === localStorage.getItem("dataImportSource")
          ) {
            this.showNextButton = false
            this.showPrevButton = false

            if (response.data.next) this.showNextButton = true
            if (response.data.previous) this.showPrevButton = true

            if (response.data.results.length < 50)
              this.showPaginationInfo =
                "Show " +
                this.currentPage * response.data.results.length +
                " of " +
                response.data.count +
                " records"
            else
              this.showPaginationInfo =
                "Show " +
                this.currentPage * 50 +
                " of " +
                response.data.count +
                " records"

            this.setUploads(response.data.results)
          }
        })
      } else {
        let payload = {
          importSource: localStorage.getItem("dataImportSource"),
          currentPage: this.currentPage,
        }
        await UploadsAPI.get(payload).then(response => {
          // Github ticket 478: Data import page switches back from MyJD to MyComputer on the list of data imports
          //  Added this check to make sure we only setUploads for the correct tab that we're on.
          if (
            payload.importSource === localStorage.getItem("dataImportSource")
          ) {
            this.showNextButton = false
            this.showPrevButton = false

            if (response.data.next) this.showNextButton = true
            if (response.data.previous) this.showPrevButton = true

            if (response.data.results.length < 50)
              this.showPaginationInfo =
                "Show " +
                this.currentPage * response.data.results.length +
                " of " +
                response.data.count +
                " records"
            else
              this.showPaginationInfo =
                "Show " +
                this.currentPage * 50 +
                " of " +
                response.data.count +
                " records"

            this.setUploads(response.data.results)
          }
        })
      }
    },

    async downloadDataSet() {
      await UploadsAPI.downloadDataSet(this.selectedUploadByFieldId)
        .then(response => {
          const downloadUrl = response.data
          window.open(downloadUrl, "_blank")
        })
        .catch(error => {
          this.titleText = "Failed to Download Dataset"
          this.errorMessage = [
            "Unable to download the dataset due to an unexpected error.",
          ]
          this.alertErrorModalOpen = true
          console.log(error)
        })
    },

    handleSearch(searchString) {
      this.searchString = searchString
    },

    handleOpenImportModal() {
      if (!this.isAgreementSigned) this.dataAgreementModalOpen = true
      else this.dataImportModalOpen = true
    },

    handleGotoApiManagementCenter() {
      this.$router.push("/api-management-center");
    },

    handleEnrollmentClick() {
      this.$router.push("/historical-practices")
    },

    async handleOpenJDUploads() {
      this.resetState()
      this.dataImportSource = "john-deere-api"
      localStorage.setItem("dataImportSource", "john-deere-api")

      this.showSpinner = true
      await this.fetchUploadsWithPagination()
      this.showSpinner = false
    },

    async handleOpenLeafUploads() {
      this.resetState()
      this.dataImportSource = "leaf-api"
      localStorage.setItem("dataImportSource", "leaf-api")
      this.showSpinner = true
      await this.fetchUploadsWithPagination()
      this.showSpinner = false
    },

    async handleOpenDirectUploads() {
      this.resetState()
      this.dataImportSource = null
      localStorage.setItem("dataImportSource", null)

      this.showSpinner = true
      await this.fetchUploadsWithPagination()
      this.showSpinner = false
    },

    async handleCloseDataImportModal() {
      this.resetState()
      this.showSpinner = true
      await this.fetchUploadsWithPagination()
      this.showSpinner = false
    },

    handleAcceptedDataAgreement() {
      this.dataAgreementModalOpen = false
      this.dataImportModalOpen = true
    },

    handleCloseDetailsModal() {
      this.setDetailsModalUploadID(null)
    },

    handleCloseDetailsByFieldModal() {
      this.setDetailsModalUploadFieldID(null)
    },

    handleCloseMappingModal() {
      this.setMappingModalUploadID(null)
      this.fetchUploadsWithPagination()
    },

    async getSignedUrl() {
      this.showSpinner = true
      await this.downloadDataSet()
      this.showSpinner = false
    },

    changeOrgId(orgId) {
      this.orgId = orgId
    },

    changeConfirmationClientSpecs(specs) {
      this.confirmationClientSpecs = {
        createNewConfirmation: true,
        ...specs
      }
    },

    changeDatasetType(datasetType) {
      this.datasetType = datasetType

      if (datasetType == "boundary") {
        this.eligibleFiles =
          ".zip, .shp, .shx, .dbf, .prj, .kml, .kmz, .geojson"
      } else if (datasetType == "practice-confirmations") {
        this.eligibleFiles = ".zip, .csv, .jpg, .jpeg, .png, .pdf"
      } else if (datasetType == "sustainability-quantification-report") {
        this.eligibleFiles = ".csv"
      } else {
        this.eligibleFiles = ".zip, .shp, .shx, .dbf, .prj"
      }
    },

    openUpload: function () {
      this.$refs.upload.$el.children[0].click()
    },

    updatedFile: function (files) {
      console.log("updated files?")
      console.log(files)
      if (!this.datasetType) return
      this.isUploading = true

      const uploadSpec = {
        orgId: this.orgId,
        datasetType: this.datasetType,
      }

      if (this.datasetType == "practice-confirmations") {
        const newSpec = Object.assign({}, this.confirmationClientSpecs)
        uploadSpec["specs"] = newSpec
      }

      if (
        files.length === 1 &&
        (files[0].name.includes("zip") ||
          (this.datasetType === "sustainability-quantification-report" &&
            files[0].name.includes("csv")))
      ) {
        const { file } = files[0]

        uploadSpec["file"] = file

        this.createUpload(uploadSpec)
      } else if (files.length > 0) {
        const zip = new JSZip()
        files.forEach(file => zip.file(file.name, file.file))
        zip
          .generateAsync({
            type: "blob",
          })
          .then(result => {
            const datetimeStr = moment().format("YYYY-MM-DD_h:mm:ss")
            const newZipName = `${this.organization.name}-${this.datasetType}-${datetimeStr}.zip`

            uploadSpec["file"] = new File([result], newZipName)

            this.createUpload(uploadSpec)
          })
      }
      this.showByField = false
    },

    fileInputFilter(newFile, oldFile, prevent) {
      if (this.datasetType == "boundary") {
        if (
          !/\.(zip?|shp?|shx?|prj?|dbf?|kml?|kmz?|geojson?)$/i.test(
            newFile.name
          )
        )
          return prevent()
      } else if (this.datasetType == "practice-confirmations") {
        if (!/\.(zip?|jpg?|jpeg?|png?|pdf?)$/i.test(newFile.name))
          return prevent()
      } else if (this.datasetType === "sustainability-quantification-report") {
        if (!/\.(csv?)$/i.test(newFile.name)) return prevent()
      } else {
        if (!/\.(zip?|shp?|shx?|prj?|dbf?)$/i.test(newFile.name))
          return prevent()
      }
    },

    async showUploadsByField() {
      this.showSpinner = true
      localStorage.setItem("showByField", this.showByField)
      await this.fetchUploadsWithPagination()
      this.showSpinner = false
    },

    updateSelectedUploadByFieldId(uploadByFieldId) {
      this.selectedUploadByFieldId = uploadByFieldId
    },
  },

  async mounted() {
    if (this.isReadOnly) {
      this.showUploadsByField()
    }

    if (this.cameFromEnrollment) {
      this.dataImportModalOpen = true
      this.initialDatasetType = "sustainability-quantification-report"
    }

    const { openUpload, initialState } = this.$route.query
    if (openUpload) {
      this.dataImportModalOpen = true
      this.initialDatasetType = openUpload

      if (initialState) {
        this.initialState = initialState
      }
    }

    if (localStorage.getItem("dataImportSource") === "john-deere-api") {
      this.dataImportSource = "john-deere-api"
    } else if (localStorage.getItem("dataImportSource") === "leaf-api") {
      this.dataImportSource = "leaf-api"
    } else {
      this.dataImportSource = null
    }

    if (localStorage.getItem("showByField")) {
      // this.showByField = true
    }

    this.listDatasetTypes()
    await this.fetchUploadsWithPagination()
    await this.fetchJohnDeereStage()
    this.loaded = true
    this.fetchIntervalID = setIntervalAsync(
      this.fetchUploadsWithPagination,
      10000
    )
    this.fetchJohnDeereID = setIntervalAsync(this.fetchJohnDeereStage, 3600000)
  },

  destroyed() {
    if (this.fetchIntervalID) clearIntervalAsync(this.fetchIntervalID)
    if (this.fetchJohnDeereID) clearIntervalAsync(this.fetchJohnDeereID)
  },
}
</script>

<style scoped>
.completed {
  font-size: 20px;
  color: #00a200;
  margin-top: 5px;
}

.failed {
  font-size: 20px;
  color: #cc0101;
  margin-top: 5px;
}

.logo-john-deere {
  float: left;
  margin-right: 5px;
  width: 20px;
  height: 20px;
  background-image: url("/assets/images/logos/john-deere-logo.png");
  background-size: contain;
}

.logo-climate {
  float: left;
  margin-right: 5px;
  width: 25px;
  height: 20px;
  background-image: url("/assets/images/logos/climate-logo.jpg");
  background-size: contain;
}

.uploadTable {
  height: calc(100vh - 415px);
  overflow: auto;
}

.jd-container {
  width: 30px;
  height: 30px;
}

h2 {
  font-size: 21px;
  font-weight: 500;
}

.uploads-api-menu-bar {
  flex: 1;
}

.uploads-api-btns {
  background-color: #ffffff;
  border-color: #7774e7;
  color: black;
}

.import-btn {
  flex: 1;
  text-align: right;
}

.previous {
  background-color: #f1f1f1;
  color: black;
}

.next {
  background-color: #04aa6d;
  color: white;
}

.zero-state {
  text-align: center;
  padding: 60px;
}

.zero-state > div {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
}

.paginationBtn {
  text-align: right;
}

.paginationText {
  margin-right: 5px;
}
</style>
