<template>
  <div class="pdf-wrapper">
    <div class="arva-logo">
      <img
        class="logo-size img-fluid mt-2"
        src="/assets/images/logos/arva-square-grey-v1.png"
        alt="User"
      />
    </div>
    <div class="subtitle text-nowrap text-center">
      <div>GROWER SOLUTIONS BY ENVIRONMENT</div>
      <div>FOR A PROFITABLE, SUSTAINABLE FUTURE.</div>
    </div>
    <div class="report-title text-nowrap text-center">
      <div>{{ reportTitle }}</div>
    </div>
    <div class="footer d-flex align-items-center justify-content-between">
      <div class="ml-2">
        Confidential
      </div>
      <div class="mr-3">
        <i class="fa fa-copyright" /> 2021 Arva Intelligence
        <i class="fa fa-trademark" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "MicroclimateReport",
  props: ["responseData"],

  computed: {
    reportTitle() {
      if (this.responseData) {
        return this.responseData["title_page"]["title"]
      }
      return null
    },
  },
}
</script>
<style>
@page {
  size: 8.5in 11in;
  margin: 0;
}
</style>
<style scoped>
.pdf-wrapper {
  width: 1000px;
  height: 1283px;
  position: relative;
}

.arva-logo {
  position: absolute;
  top: 350px;
  right: 400px;
}

.logo-size {
  height: 160px;
}

.subtitle {
  position: absolute;
  top: 560px;
  right: 200px;
  font-size: 28px;
  color: #8cc63f;
}

.footer {
  position: absolute;
  bottom: 0px;
  height: 30px;
  width: 100%;
  background: #444;
  font-family: Montserrat, sans-serif;
  font-size: 13px;
  color: #fff;
  font-weight: bold;
}

.report-title {
  position: absolute;
  color: darkblue;
  top: 800px;
  right: 200px;
  font-size: 42px;
  font-weight: 400;
}
</style>
