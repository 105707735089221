<template>
  <ArvaModal
    name="incompleteFertAppModal"
    title="Incomplete Fertilizer Applications"
    :wide="true"
    :width="800"
    @close-modal="handleClose"
  >
    <v-card-text>
      <div class="ml-2 text-h4">
        The following {{ dataCategory }}s are missing data:
      </div>
      <div class="ml-2 text-h6 red--text">
        <div v-for="app in incompleteApplications" :key="app.idx">
          {{ dataCategory | capitalize }} #{{ app + 1 }}
        </div>
      </div>
    </v-card-text>
  </ArvaModal>
</template>
<script>
import ArvaModal from "./ArvaModal"

export default {
  name: "IncompleteFertAppModal",
  props: ["incompleteApplications", "dataCategory"],
  components: {
    ArvaModal,
  },
  methods: {
    handleClose() {
      this.$emit("close-template-modal")
    },
  },
}
</script>
<style scoped>
.modal-body {
  max-height: 440px;
}
</style>
