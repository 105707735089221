<template>
  <div class="page">
    <div id="map" ref="mapElement"/>

    <div class="planet-logo-wrapper">
      <div class="planet-logo" />
    </div>

    <div class="slider-wrapper-wrapper">
      <div class="slider-wrapper">
        <div class="date-padding pt-2 d-flex mb-2">
          <div>Showing dates from</div>
          <div>
            <DatepickerImagery
              v-model="datePickerDates"
              :imageryDates="allDates"
            />
          </div>
        </div>
        <div class="slider-padding">
          <v-range-slider
            :value="selectedSliderIndices"
            @change="handleSliderChange"
            strict
            :ticks="true"
            min="0"
            :max="marksMapper.length - 1"
            :tick-labels="marksMapper"
          />
        </div>
      </div>
    </div>
    <Tooltip />
  </div>
</template>

<script>
import "leaflet/dist/leaflet.css"
import L from "leaflet"
import "leaflet-boundary-canvas"
import { fromUrl } from "geotiff"
import * as plotty from "plotty"
import "sean-leaflet-geotiff-2"
import _ from "lodash"
import { multiPolygon, point } from "@turf/helpers"
import booleanPointInPolygon from "@turf/boolean-point-in-polygon"
import moment from "moment"
import { mapMutations, mapState } from "vuex"

import { AGT_COLORS_OLD } from "@/constants/agts"
import { Map } from "@/store/modules"
import { MAPBOX_TOKEN, TIFF_DATA_SCALAR } from "@/constants/map"
import DatepickerImagery from "@/components/misc/DatepickerImagery"
import SatelliteAPI from "@/api/SatelliteAPI"
import Tooltip from "@/components/map/Tooltip"
import ticks from "@/utility/ticks"



export default {
  name: "FieldImageryViewIndividual",
  props: [
    "boundaries",
    "dateInfo",
    "fields",
    "fieldId",
    "geotiffColorScale",
    "isGeotiffLoading",
    "selectedDateInfo",
    "valueSelection",
    "zoneGeoJSON",
  ],
  components: { DatepickerImagery, Tooltip },

  data() {
    return {
      geoTiffData: null,
      geoTiffLayer: null,
      histogramData: null,
      lastMapAction: null,
      map: null,
      MAX_DATE_LAYERS: 3,
      plottySettings: {
        displayMin: 1,
        displayMax: 100,
        noDataValue: 0,
        clampLow: true,
        clampHigh: true,
      },
      datePickerDates: null,
      selectedDate: null,
      tiffUrl: "",
    }
  },

  computed: {
    ...mapState({
      organization: state => state.Organization.organization,
    }),

    corporationUUID() {
      return (
        this.organization &&
        this.organization.corporation &&
        this.organization.corporation.uuid
      )
    },

    bounds() {
      const geometry = { type: "MultiPolygon", coordinates: [] }
      for (const field of this.fields) {
        geometry.coordinates = geometry.coordinates.concat(
          field.geometry.coordinates
        )
      }
      return geometry
    },

    allDates() {
      const allDates = new Set()
      this.dateInfo.forEach(dateObj => allDates.add(dateObj.date))
      const allDatesArray = Array.from(allDates).sort()
      return allDatesArray
    },

    sliderDates() {
      if (!this.datePickerDates) {
        return this.allDates
      } else {
        const startDate = moment(this.datePickerDates.start).format(
          "YYYY-MM-DD"
        )
        const endDate = moment(this.datePickerDates.end).format("YYYY-MM-DD")
        const startIndex = this.allDates.indexOf(startDate)
        const endIndex = this.allDates.indexOf(endDate)
        return this.allDates.slice(startIndex, endIndex + 1)
      }
    },

    marksMapper() {
      const datesLength = this.sliderDates.length
      const tickNums = ticks(0, datesLength - 1, 3)

      const marks = this.sliderDates.map((sliderDate, i) => {
        return tickNums.includes(i)
          ? `${sliderDate}`
          : ""
      })
      return marks;
    },

    selectedSliderIndices() {
      const indices = [
        this.sliderDates.indexOf(this.selectedDate[0]),
        this.sliderDates.indexOf(this.selectedDate[1])
      ];
      return indices;
    },

  },


  methods: {
    ...mapMutations({
      setHoverData: Map.Mutations.setHoverData,
    }),

    boundsStyle() {
      return {
        color: "#FFCC00",
        weight: 2.5,
        opacity: 0.85,
        fill: false,
      }
    },

    boundsStyleZone() {
      return {
        weight: 2.5,
        opacity: 1,
        fill: true,
        fillOpacity: 1,
        stroke: false,
      }
    },

    getMapLayers() {
      const osmLayer = L.tileLayer(
        `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=${MAPBOX_TOKEN}`,
        {
          attribution:
            '© <a href="https://apps.mapbox.com/feedback/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
          minZoom: 5,
          maxZoom: 18,
        }
      )

      const boundsLayer = []
      boundsLayer.push(
        new L.GeoJSON(this.boundaries, {
          style: this.boundsStyle,
          pane: "boundary",
          onEachFeature: (feature, layer) => (layer.myTag = "boundsGeoJSON"),
        })
      )

      return { osmLayer, boundsLayer }
    },

    async initialGeotiff() {
      this.$emit("update-loading", true)

      plotty.addColorScale(
        "rgb",
        ["#a70025", "#fdfec0", "#006739"],
        [0, 0.5, 1]
      )
      const plottyRenderer = L.LeafletGeotiff.plotty(this.plottySettings)

      const payload = { field_id: this.fieldId, dates: [this.selectedDate[1]] }

      await SatelliteAPI.fetchGeotiffByFieldDate(payload)
        .then(response => {
          this.tiffUrl = response.data.signed_url
        })
        .catch(() => {
          //console.log(err)
          this.$emit("update-loading", false)
          this.$emit("on-geotiff-load", null, true)
        })

      const currentGeotiffLayer = L.leafletGeotiff(this.tiffUrl, {
        renderer: plottyRenderer,
        onEachFeature: (feature, layer) => (layer.myTag = "boundsGeoJSON"),
      }).addTo(this.map)

      currentGeotiffLayer.options.renderer.setColorScale(this.geotiffColorScale)

      this.geoTiffLayer = currentGeotiffLayer

      fromUrl(this.tiffUrl).then(async tiff => {
        const image = await tiff.getImage()
        const data = await image.readRasters()

        // data cleaning for geotiff for proper data on download
        const tiffData = Array.from(data[0])
        const properData = tiffData.map(val =>
          val === 0 ? 0 : val - TIFF_DATA_SCALAR
        )

        this.$emit("update-loading", false)
        this.$emit("on-geotiff-load", _.countBy(properData.filter(x => x > 0)))
      })

      return currentGeotiffLayer
    },

    async updateGeotiff() {
      plotty.addColorScale(
        "rgb",
        ["#a70025", "#fdfec0", "#006739"],
        [0, 0.5, 1]
      )

      const plottyRenderer = L.LeafletGeotiff.plotty(this.plottySettings)
      const dateSliderLeftLogic = this.selectedDate[0] === this.sliderDates[0]

      if (dateSliderLeftLogic === false) {
        this.$emit("update-loading", true)
        const payload = { field_id: this.fieldId, dates: this.selectedDate }
        await SatelliteAPI.fetchGeotiffByFieldDate(payload)
          .then(response => {
            this.tiffUrl = response.data.signed_url
          })
          .catch(() => {
            this.$emit("update-loading", false)
            this.$emit("on-geotiff-load", null, true)
          })
        const currentGeotiffLayer = L.leafletGeotiff(this.tiffUrl, {
          renderer: plottyRenderer,
          onEachFeature: (feature, layer) => (layer.myTag = "boundsGeoJSON"),
        }).addTo(this.map)

        currentGeotiffLayer.options.renderer.setColorScale(
          this.geotiffColorScale
        )

        this.geoTiffLayer = currentGeotiffLayer

        fromUrl(this.tiffUrl).then(async tiff => {
          const image = await tiff.getImage()
          const data = await image.readRasters()

          // data cleaning for geotiff for proper data on download
          const tiffData = Array.from(data[0])
          const properData = tiffData.map(val =>
            val === 0 ? 0 : val - TIFF_DATA_SCALAR
          )
          this.$emit("update-loading", false)

          if (this.selectedDateInfo[0] !== 0) {
            this.$emit("on-geotiff-load", _.countBy(properData))
          } else {
            this.$emit(
              "on-geotiff-load",
              _.countBy(properData.filter(x => x > 0))
            )
          }
        })
      } else {
        this.initialGeotiff()
      }
    },

    updateMap() {
      if (this.bounds.coordinates.length === 0) {
        if (this.map) {
          this.map.eachLayer(layer => {
            if (layer.options.boundary) this.map.removeLayer(layer)
          })
        }
        return
      }

      const { osmLayer, boundsLayer } = this.getMapLayers()
      const layers = [osmLayer].concat(boundsLayer)

      if (this.map) {
        this.map.eachLayer(layer => {
          if (layer.myTag && layer.myTag === "boundsGeoJSON")
            this.map.removeLayer(layer)
          if (layer.options.boundary) this.map.removeLayer(layer)
        })
        this.updateLayers()
      } else {
        const currentMap = L.map(this.$refs['mapElement'], {
          layers,
        })

        this.map = currentMap
        this.map.createPane("boundary")
        this.map.getPane("boundary").style.zIndex = 650

        this.initialGeotiff()
        this.map.on("mousemove", this.handleMapHover)

        setTimeout(() => {
          if (this.isGeotiffLoading) this.initialGeotiff()
          return
        }, 7000)
      }
    },

    updateLayers() {
      const { boundsLayer } = this.getMapLayers()

      this.map.eachLayer(layer => {
        if (layer.myTag && layer.myTag === "boundsGeoJSON")
          this.map.removeLayer(layer)
        if (layer.options.boundary) this.map.removeLayer(layer)
      })

      boundsLayer.forEach(layer => this.map.addLayer(layer))

      this.updateGeotiff()
    },

    updateZoneLayer() {
      if (this.zoneGeoJSON === null) {
        this.map.eachLayer(layer => {
          if (layer.myTag && layer.myTag === "zonesGeoJSON")
            this.map.removeLayer(layer)
        })
        return
      }
      L.geoJSON(this.zoneGeoJSON, {
        style: this.boundsStyleZone,
        onEachFeature: (feature, layer) => {
          layer.myTag = "zonesGeoJSON"
          layer.setStyle({ fillColor: AGT_COLORS_OLD[feature.id] })
        },
      }).addTo(this.map)
    },

    zoomToBounds() {
      if (this.bounds.coordinates.length === 0 || !this.map) return
      const geoJSON = L.geoJson(this.bounds)
      this.map.fitBounds(geoJSON.getBounds())
    },

    findSelectedFieldFromLatLng(latlng) {
      const { lat, lng } = latlng
      const pt = point([lng, lat])
      return this.fields.find(field => {
        const fieldMPoly = multiPolygon(field.geometry.coordinates)
        return booleanPointInPolygon(pt, fieldMPoly)
      })
    },

    handleMapHover(e) {
      const { latlng } = e
      const hoveredField = this.findSelectedFieldFromLatLng(latlng)

      if (hoveredField) {
        const ndviValue = this.geoTiffLayer.getValueAtLatLng(
          +e.latlng.lat,
          +e.latlng.lng
        )
        const { field } = hoveredField.properties
        const { x, y } = e.containerPoint
        this.setHoverData({
          x,
          y,
          fieldName: field.name,
          farmName: field.farm.name,
          acreage: field.acreage,
          ndviValue: ndviValue,
        })
      } else {
        this.setHoverData()
      }
    },

    handleSliderChange(val) {
      //return if any of the values are NaN
      if (val.some(_.isNaN)) {
        return
      }

      this.selectedDate = [
        this.sliderDates[val[0]],
        this.sliderDates[val[1]],
      ]
      const emitObj = {
        sliderIndexes: [
          this.allDates.indexOf(this.selectedDate[0]),
          this.allDates.indexOf(this.selectedDate[1])
        ],
        sliderDates: this.selectedDate,
      }

      this.$emit("on-slider-change", emitObj)
      this.updateMap()
    },
  },

  watch: {
    bounds() {
      this.updateMap()

      // if user deselects field, map won't snap to bounds
      if (this.lastMapAction === "click") {
        this.lastMapAction = null
        return
      }
      this.zoomToBounds()
    },

    dateInfo() {
      this.selectedDate = [
        this.allDates[0],
        this.allDates[this.allDates.length - 1],
      ]

      this.datePickerDates = {
        start: new Date(this.allDates[0].replace(/-/g, "/")),
        end: new Date(
          this.allDates[this.allDates.length - 1].replace(/-/g, "/")
        ),
      }
      this.handleSliderChange([0, this.allDates.length - 1])
    },

    datePickerDates() {
      const startDate = moment(this.datePickerDates.start).format("YYYY-MM-DD")
      const endDate = moment(this.datePickerDates.end).format("YYYY-MM-DD")
      this.selectedDate = [startDate, endDate]

      this.handleSliderChange([0, this.sliderDates.length - 1])

      this.updateMap()
    },

    geotiffColorScale() {
      if (this.geoTiffLayer) {
        this.geoTiffLayer.options.renderer.setColorScale(this.geotiffColorScale)
      }
    },

    valueSelection() {
      if (this.geoTiffLayer) {
        this.geoTiffLayer.options.renderer.setDisplayRange(
          this.valueSelection[0] + TIFF_DATA_SCALAR,
          this.valueSelection[1] + TIFF_DATA_SCALAR
        )
      }
    },

    zoneGeoJSON() {
      this.updateZoneLayer()
    },
  },
  updated() {
    this.updateMap()

    // if user deselects field, map won't snap to bounds
    if (this.lastMapAction === "click") {
      this.lastMapAction = null
      return
    }
    this.zoomToBounds()
  },
}
</script>

<style scoped>
body {
  margin: 0;
  padding: 0;
}

.page {
  position: relative;
}

#map {
  height: calc(100vh - 65px);
  position: relative;
  background: #1b1b1d;
  margin: -17px -29px -17px -29px;
}

.planet-logo-wrapper {
  position: absolute;
  top: 15px;
  right: -5px;
  z-index: 500;
}

.planet-logo {
  width: 122px;
  height: 50px;
  background-image: url("/assets/images/logos/planet_logo.svg");
  background-size: 67%;
  background-color: rgba(0, 0, 0, 0.7);
  background-position: center;
  border-radius: 7px;
}

.control-wrapper {
  position: absolute;
  top: 80px;
  right: -5px;
  height: 70px;
  width: 90px;
  z-index: 500;
  background: white;
  border: 1pt solid #eee;
  box-shadow: 0px 0px 4px #ccc;
  padding: 13px;
  border-radius: 7px;
}

.ndvi-legend {
  position: absolute;
  top: 13px;
  left: 35px;
  height: 32px;
  width: 385px;
  z-index: 1000;
  background: white;
  border: 1pt solid #eee;
  box-shadow: 0px 0px 4px #ccc;
  padding: 0px 11px 0px 15px;
  border-radius: 7px;
}

.ndvi-legend > div {
  background-image: url("/assets/images/legends/ndvi_legend.png");
  background-size: contain;
  height: 100%;
  width: 100%;
}

.slider-wrapper-wrapper {
  position: absolute;
  bottom: 25px;
  z-index: 1000;
  width: 92%;
  box-shadow: 0px 0px 4px #ccc;
  border-radius: 7px;
}

.slider-wrapper {
  position: relative;
  height: 90px;
  background: white;
  border: 1pt solid #eee;
  border-radius: 7px;
}

.date-padding {
  padding-left: 12px;
}

.slider-padding {
  padding: 7px 40px 0px 40px;
}

.v-slider__tick-label {
  font-size: 10px !important;
  padding-bottom: 3px !important;
}
</style>
