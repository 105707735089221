<template>
  <ArvaModal
    name="dataShareAgreementModal"
    title="Data Share Liability Agreement"
    :wide="true"
    :width="700"
    @close-modal="handleCloseModal"
  >
    <div>
      <div>
        <div class="col-12">
          <div class="row">
            <div class="info-text">
              Please review and accept Arva's Data Share Liability Agreement.
              Please contact us at
              <a href="mailto:app@arvaintelligence.com"
                >app@arvaintelligence.com</a
              >
              if you have any questions.
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="agreement">
                <div class="logo" />
                <h1>RELEASE OF LIABILITY AGREEMENT</h1>
                <p>
                  Arva Intelligence Inc. (Arva) platform users understand and
                  agree that Arva is not responsible for customer/partner data
                  privacy when data is shared to outside organizations using
                  this feature. Customer administrators when granting permission
                  to share organizational data must agree to hold Arva harmless
                  and indemnify Arva against any and all data breaches due to
                  sharing information through this feature.
                </p>
              </div>
            </div>
          </div>
        </div>

        <ArvaModalSaveFooter
          confirmText="I Agree"
          cancelText="Cancel"
          @on-cancel="handleCloseModal"
          @on-confirm="handleConfirm"
        />
      </div>
    </div>
  </ArvaModal>
</template>

<script>
import ArvaModal from "./ArvaModal"
import ArvaModalSaveFooter from "./ArvaModalSaveFooter"

export default {
  name: "DataShareAgreementModal",
  props: {
    confirmText: { type: String },
    isProcessing: { type: Boolean },
    source: { type: String },
  },
  components: { ArvaModal, ArvaModalSaveFooter },

  methods: {
    handleCloseModal() {
      this.$emit("close-modal")
    },

    handleConfirm() {
      this.$emit("agree", this.source)
    },
  },
}
</script>

<style scoped>
.info-text {
  margin: 20px 0px;
  font-size: 15px;
  text-align: center;
}

.agreement {
  padding: 20px 35px;
  background: #f9f9f9;
  border: 1pt solid #f0f0f0;
  max-height: 550px;
}

.agreement .logo {
  background-image: url("/assets/images/logos/arva-logo.png");
  background-size: cover;
  height: 40px;
  width: 120px;
  margin-left: -5px;
  margin-bottom: 12px;
}

.agreement h1 {
  margin-top: 20px;
  font-size: 22px;
  font-weight: 500;
}

.agreement h3 {
  margin-top: 20px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500;
}

.agreement ul {
  margin: 10px 20px;
}
</style>
