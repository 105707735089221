<template>
  <div class="elevation-slider">
    <v-slider class="my-0 py-0" :hide-details="true" v-model="elevationScale" />
  </div>
</template>

<script>

import { Map } from "@/store/modules"

export default {
  name: "ElevationScaleSlider",
  props: ["id"],

  computed: {
    elevationScale: {
      get() {
        return this.$store.getters[Map.Getters.getElevationScale][this.id]
      },
      set(elevationScale) {
        this.$store.commit(Map.Mutations.updateElevationScale, {
          elevationScale,
          layerId: this.id,
        })
      },
    },
  },
}
</script>

<style scoped>
.elevation-slider {
  width: 100%;
}
</style>
