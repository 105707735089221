<template>
  <ArvaModal
    name="daycentSimulationModal"
    title="Model Settings"
    @close-modal="handleClose"
  >
    <div class="px-3 py-2">
      <div class="row">
        <div v-if="field != null" class="col-3 pr-0">
          <img :src="field.boundary_image" style="width: 100px" />
        </div>
        <div class="col-9 pl-0">
          <v-simple-table v-if="field != null">
            <tr>
              <td class="ghg text-left text-h4 text--primary">
                <h5>Field:</h5>
              </td>
              <h5>
                <td class="ghg text-left text-h4 text--primary">
                  {{ field.name }}
                </td>
              </h5>
            </tr>
            <tr>
              <td class="ghg text-left">Last Updated:</td>
              <td class="ghg text-left">
                {{ new Date(field.updated_at).toDateString() }}
              </td>
            </tr>
            <tr>
              <td class="ghg text-left">Status:</td>
              <td class="ghg text-left">
                <span
                  v-if="field.status === 'complete'"
                  class="badge badge-pill badge-success"
                  >{{ field.status | capitalize }}</span
                >
                <span
                  v-if="field.status === 'processing'"
                  class="badge badge-pill badge-warning"
                  >{{ field.status | capitalize }}</span
                >
              </td>
            </tr>
          </v-simple-table>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <h5>Edit Model Simulation Settings:</h5>
          <v-select-old
            class="mx-2 tillage-select"
            v-model="selected_tillage"
            :value="selected_tillage"
            :options="tillage_practices"
            placeholder="Select Tillage Practice"
            width="100"
          ></v-select-old>

          <v-select-old
            class="mx-2 tillage-select"
            v-model="selected_grazing"
            :value="selected_grazing"
            :options="grazing_practices"
            placeholder="Grazing Practice (In Beta)"
            width="100"
          ></v-select-old>

          <v-select-old
            class="mx-2 tillage-select"
            v-model="control_plot_practice"
            :value="control_plot_practice"
            :options="control_plot_practices"
            placeholder="Soil Ammendments"
            width="100"
            multiple
          ></v-select-old>
        </div>
        <div class="col">
          <div class="row check-spacer">
            <div class="toggle-checkbox toggle-sm toggle-success d-flex">
              <input
                type="checkbox"
                id="covercrop_checkbox"
                v-model="covercrop_checkbox"
                value="covercrop_checkbox"
              />
              <label for="covercrop_checkbox"></label>
              <span class="ml-2">Use Cover Crop</span>
            </div>
            <div class="toggle-checkbox toggle-sm toggle-success d-flex">
              <input
                type="checkbox"
                id="awd_checkbox"
                v-model="awd_checkbox"
                value="awd_checkbox"
              />
              <label for="awd_checkbox"></label>
              <span class="ml-2">Use AWD on Rice Crop</span>
            </div>
          </div>
          <div class="row">
            <div class="toggle-checkbox toggle-sm toggle-success d-flex">
              <input
                type="checkbox"
                class="checked"
                id="ni_checkbox"
                v-model="ni_checkbox"
                value="ni_checkbox"
              />
              <label for="ni_checkbox"></label>
              <span class="ml-2">Nitrogen Inhibitor</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="eligiblePractices">
        <div class="col">
          <v-title-text>
            The options below are expected to count as 'additional' practice
            changes in your area. <br />Select a set of practice changes at
            least as aggressive as one of these to get full credit.
          </v-title-text>
          <v-simple-table dense class="pt-2">
            <tbody>
              <tr
                v-for="eligible_practice in eligiblePractices"
                :key="eligible_practice.idx"
              >
                <td>{{ eligible_practice | capitalize }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </div>
      </div>
      <div class="row pb-2">
        <div class="col d-flex">
          <v-btn
            :disabled="is_complete == false && anyLocked == true"
            class="ml-auto"
            type="submit"
            @click="validateInputs()"
          >
            Re-run Model
          </v-btn>
        </div>
      </div>
    </div>
    <ConfirmModal
      v-if="confirmUpdateModalOpen"
      titleText="Confirm Crop-Fields Updating"
      :confirmText="updateConfirmText"
      :isProcessing="rerunningModel"
      @confirm="submit"
      @close-modal="confirmUpdateModalOpen = false"
    />
  </ArvaModal>
</template>

<script>
import ArvaModal from "./ArvaModal"
import ConfirmModal from "@/components/modals/ConfirmModal"

import CarbonReadyAPI from "@/api/CarbonReadyAPI"
import { mapActions, mapGetters } from "vuex"
import { Fields } from "@/store/modules"

export default {
  name: "DaycentSimulationModal",
  props: ["field", "is_complete", "sustainabilityRowIds", "selectedFieldIds"],
  components: {
    ArvaModal,
    ConfirmModal,
  },
  data() {
    return {
      selected_tillage: null,
      selected_grazing: null,
      ni_checkbox: null,
      covercrop_checkbox: null,
      eligible_practices: null,
      awd_checkbox: null,
      control_plot_practice: [],
      tillage_practices: [
        {
          label: "Conventional Tillage",
          value: "conventional",
        },
        {
          label: "Minimum Till",
          value: "minimum-till",
        },
        {
          label: "No Till",
          value: "no-till",
        },
      ],
      grazing_practices: [
        {
          label: "No Grazing",
          value: "None",
        },
        {
          label: "Conventional Grazing",
          value: "conventional",
        },
        {
          label: "Rotational Grazing",
          value: "rotational",
        },
        {
          label: "Intensive Rotational Grazing",
          value: "intensive",
        },
      ],
      control_plot_practices: [],
      confirmUpdateModalOpen: false,
      errorMessage: null,
      rerunningModel: false,
      updateConfirmText:
        "Are you sure you want to re-run model for the selected crop-fields?",
    }
  },

  computed: {
    ...mapGetters({
      fields: Fields.Getters.getFields,
    }),
    eligiblePractices() {
      if (this.field) {
        if (this.field.scenario_settings.eligible_practices) {
          return this.field.scenario_settings.eligible_practices
        }
      }
      return null
    },
    anyLocked() {
      if (this.field) {
        if (
          this.field.scenario.some(x => {
            return x.is_locked == true
          })
        ) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
  },

  mounted() {
    if (this.field) {
      for (let idx in this.tillage_practices) {
        let tillage_practice = this.tillage_practices[idx]
        if (tillage_practice.value == this.field.scenario_settings.tillage) {
          this.selected_tillage = tillage_practice
        }
      }
      for (let idx in this.grazing_practices) {
        let grazing_practice = this.grazing_practices[idx]
        if (grazing_practice.value == this.field.scenario_settings.grazing) {
          this.selected_grazing = grazing_practice
        }
      }
      this.ni_checkbox = this.field.scenario_settings.inhibitor
      this.covercrop_checkbox = this.field.scenario_settings.cover_crop
      this.awd_checkbox = this.field.scenario_settings.awd
      this.eligible_practices = this.field.scenario_settings.eligible_practices
    }
    this.getRegenPractice()
  },

  methods: {
    ...mapActions({
      updateRegenPractice: "Fields/updateRegenPractice",
    }),

    async getRegenPractice() {
      await CarbonReadyAPI.getRegenPractice().then(response => {
        let temp_control_plot_practices = []

        for (let i in response.data) {
          if (response.data[i].needs_control_plot) {
            temp_control_plot_practices.push(response.data[i].name)
          }
        }
        this.control_plot_practices = temp_control_plot_practices
      })

      if (this.field) {
        this.control_plot_practice = this.field.scenario_settings.control_plot_practices
      }

      /*if (this.control_plot_practice == null) {
        this.control_plot_practice = "Other Ammendments"
      }*/
    },
    handleClose() {
      this.$emit("close-template-modal")
    },

    validateInputs() {
      // reset error alert
      if (this.field) {
        this.submit()
      } else {
        this.resetErrorAlert()

        if (this.sustainabilityRowIds.length === 0) {
          this.setErrorAlert("At least one field must be selected!")
          return
        }

        // display error alert if necessary
        if (this.selectedTillage === null) {
          this.setErrorAlert("Tillage value must be selected!")
          return
        }

        this.confirmUpdateModalOpen = true
      }
    },

    resetErrorAlert() {
      this.showErrorAlert = false
    },

    setErrorAlert(message) {
      this.errorMessage = message
      this.showErrorAlert = true
    },

    async submit() {
      this.ni_checkbox = +Boolean(this.ni_checkbox)
      this.covercrop_checkbox = Boolean(this.covercrop_checkbox)
      this.awd_checkbox = Boolean(this.awd_checkbox)

      if (this.selected_grazing == null) {
        this.selected_grazing = {
          value: "None",
          label: "No Grazing",
        }
      }
      let fieldIds = this.selectedFieldIds
      let simulationIDs = this.sustainabilityRowIds
      if (this.field) {
        fieldIds = [this.field.fieldId]
        simulationIDs = [this.field.id]
      }

      let payload = {
        field_ids: fieldIds,
        simulation_ids: simulationIDs,
        scenario_settings: {
          cover_crop: this.covercrop_checkbox,
          inhibitor: this.ni_checkbox,
          tillage: this.selected_tillage.value,
          grazing: this.selected_grazing.value,
          awd: this.awd_checkbox,
        },
      }

      if (this.control_plot_practice != null) {
        payload["scenario_settings"][
          "control_plot_practices"
        ] = this.control_plot_practice
      }

      //TODO in future this should also update the state so that when user goes to enrollment without refresh
      //this will still show up. Right now need to wait for refresh for a soil ammendment to show up

      //this.field.scenario_settings = payload["scenario_settings"]

      await CarbonReadyAPI.postTriggerDaycentSimulation(payload)
        .then(response => {
          fieldIds.forEach(fieldId => {
            let scenarios = []
            const practices = response.data.filter(
              practice => practice.field_id == fieldId
            )

            practices.forEach(practice => {
              const scenario = {
                is_selected: practice.is_selected,
                regen_practice: {
                  name: practice.regen_practice.name,
                },
                value: practice.value,
              }

              scenarios.push(scenario)
            })

            if (scenarios.length > 0) {
              this.updateRegenPractice({
                field_id: fieldId,
                scenario: scenarios,
              })
            }
          })

          this.rerunningModel = false
          this.$emit("success")
        })
        .catch(() => {
          this.updateConfirmText =
            "All fields in selection are currently in an approved enrollment. Cannot edit!"
          this.rerunningModel = false
        })
    },
  },
}
</script>

<style scoped>
.badge {
  font-size: 12px;
}

.check-spacer {
  padding-top: 42px;
}

.confirm-text {
  font-size: 15px;
  font-weight: 500;
  padding: 30px 30px;
}

.tillage-select {
  max-width: 225px;
}
</style>
