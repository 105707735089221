<template>
  <div class="zero-state" v-if="orders.length < 1 && initialResponseComplete">
    <div>
      You have not ordered any satellite imagery. To order, visit
      <router-link to="/order-imagery">Order imagery</router-link>
    </div>
  </div>
  <div v-else class="page">
    <div id="map" />

    <div class="planet-logo-wrapper">
      <div class="planet-logo" />
    </div>

    <div class="control-wrapper">
      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          name="layerType"
          id="layerTypeNdvi"
          value="ndvi"
          v-model="layerType"
        />
        <label class="form-check-label" for="layerTypeNdvi">
          NDVI
        </label>
      </div>
      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          name="layerType"
          id="layerTypeRgb"
          value="rgb"
          v-model="layerType"
        />
        <label class="form-check-label" for="layerTypeRgb">
          RGB
        </label>
      </div>
      <div class="d-flex mt-3">
        <div class="dot"></div>
        <div class="dot-text">Cloud</div>
      </div>
    </div>

    <div class="ndvi-legend" v-if="layerType === 'ndvi'">
      <div />
    </div>

    <div class="slider-wrapper-wrapper">
      <div class="slider-wrapper">
        <div class="date-padding pt-2 d-flex mb-2">
          <div>Showing dates from</div>
          <div>
            <DatepickerImagery
              v-model="datePickerDates"
              :imageryDates="allDates"
            />
          </div>
        </div>
        <div class="slider-padding">

            <v-slider
            v-model="selectedDateIndex"
            :data="sliderDates"
            :tick-labels="marksMapper"
            :ticks="true"
            min="0"
            :max="marksMapper.length - 1"            
          />
        </div>
      </div>
    </div>
    <Tooltip />
  </div>
</template>

<script>
import "leaflet/dist/leaflet.css"
import L from "leaflet"
import "leaflet-boundary-canvas"
import { multiPolygon, point } from "@turf/helpers"
import booleanPointInPolygon from "@turf/boolean-point-in-polygon"
import moment from "moment"
import _ from "lodash"


import _default, { mapMutations, mapState } from "vuex"

import FieldImageryAPI from "@/api/FieldImageryAPI"
import PlanetOrderAPI from "@/api/PlanetOrderAPI"

import DatepickerImagery from "@/components/misc/DatepickerImagery"
import Tooltip from "@/components/map/Tooltip"
import { DROPDOWN } from "@/constants"
import { Filter, Map } from "@/store/modules"
import ticks from "@/utility/ticks"

export default {
  name: "FieldImageryView",
  props: ["boundaries", "fields"],
  components: {
    DatepickerImagery,
    Tooltip,
  },

  data() {
    return {
      datePickerDates: null,
      selectedDate: null,
      ndviDates: [],
      rgbDates: [],
      map: null,
      lastMapAction: null,
      layerType: "ndvi",
      MAX_DATE_LAYERS: 1,
      orders: [],
      initialResponseComplete: false,
    }
  },

  computed: {
    ...mapState({
      organization: state => state.Organization.organization,
    }),

    selectedDateIndex: {
      get() {
        return this.sliderDates.indexOf(this.selectedDate)
      },
      set(val) {
        if (!_.isNil(val)) {
          this.selectedDate = this.sliderDates[val]
        }
      },
    },

    corporationUUID() {
      return (
        this.organization &&
        this.organization.corporation &&
        this.organization.corporation.uuid
      )
    },

    bounds() {
      const geometry = {
        type: "MultiPolygon",
        coordinates: [],
      }
      let eligibleFields = this.orders.map(obj => {
        return obj.field_id
      })
      for (const field of this.fields) {
        if (eligibleFields.includes(field.properties.field.id)) {
          geometry.coordinates = geometry.coordinates.concat(
            field.geometry.coordinates
          )
        }
      }
      return geometry
    },

    allDates() {
      const allDates = new Set()
      this.ndviDates.forEach(date => allDates.add(date))
      this.rgbDates.forEach(date => allDates.add(date))

      const allDatesArray = Array.from(allDates).sort()
      return allDatesArray
    },

    sliderDates() {
      if (!this.datePickerDates) {
        return this.allDates
      } else {
        const startDate = moment(this.datePickerDates.start).format(
          "YYYY-MM-DD"
        )
        const endDate = moment(this.datePickerDates.end).format("YYYY-MM-DD")
        const startIndex = this.allDates.indexOf(startDate)
        const endIndex = this.allDates.indexOf(endDate)
        return this.allDates.slice(startIndex, endIndex + 1)
      }
    },

    selectedNdviDates() {
      const filteredDates = this.ndviDates.filter(
        date => date <= this.selectedDate
      )
      return filteredDates.slice(filteredDates.length - this.MAX_DATE_LAYERS)
    },

    selectedRgbDates() {
      const filteredDates = this.rgbDates.filter(
        date => date <= this.selectedDate
      )
      return filteredDates.slice(filteredDates.length - this.MAX_DATE_LAYERS)
    },


    marksMapper() {
      const datesLength = this.sliderDates.length
      const tickNums = ticks(0, datesLength - 1, 5)

      const marks = this.sliderDates.map((sliderDate, i) => {
        return tickNums.includes(i)
          ? `${sliderDate}`
          : "" 
      })
      return marks;
    }
  },

  methods: {
    ...mapMutations({
      setHoverData: Map.Mutations.setHoverData,
      toggleField: Filter.Mutations.toggleItem,
    }),

    fillFunction(d) {
      if (this.fields.find(field => field.geometry === d.geometry)) {
        return true
      }
      return false
    },

    boundsStyle(feature) {
      return {
        color: "#FFCC00",
        weight: 1,
        opacity: 0.85,
        fill: this.fillFunction(feature),
      }
    },

    getMapLayers() {
      const osmLayer = L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png", {
        attribution:
          '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
        minZoom: 5,
        maxZoom: 16,
      })

      const ndviLayers = []
      const rgbLayers = []
      const boundsLayer = []

      boundsLayer.push(
        new L.GeoJSON(this.boundaries, {
          style: this.boundsStyle,
          onEachFeature: (feature, layer) => (layer.myTag = "boundsGeoJSON"),
        })
      )

      this.selectedNdviDates.forEach(date => {
        ndviLayers.push(
          L.TileLayer.boundaryCanvas(
            `https://storage.googleapis.com/pl-arva-pub-testing/tilelayers/${this.corporationUUID}/${date}/ndvi/{z}/{x}/{y}.png`,
            {
              tms: true,
              opacity: 1,
              attribution: "",
              minZoom: 11,
              maxZoom: 16,
              pane: "labels",
              boundary: this.bounds,
            }
          )
        )
      })

      this.selectedRgbDates.forEach(date => {
        rgbLayers.push(
          L.TileLayer.boundaryCanvas(
            `https://storage.googleapis.com/pl-arva-pub-testing/tilelayers/${this.corporationUUID}/${date}/rgb/{z}/{x}/{y}.png`,
            {
              tms: true,
              opacity: 1,
              attribution: "",
              minZoom: 11,
              maxZoom: 16,
              pane: "labels",
              boundary: this.bounds,
            }
          )
        )
      })

      return {
        osmLayer,
        ndviLayers,
        rgbLayers,
        boundsLayer,
      }
    },

    updateMap() {
      if (this.bounds.coordinates.length === 0) {
        if (this.map) {
          this.map.eachLayer(layer => {
            if (layer.options.boundary) this.map.removeLayer(layer)
          })
        }
        return
      }

      const { osmLayer, ndviLayers, boundsLayer } = this.getMapLayers()
      const layers = [osmLayer].concat(boundsLayer).concat(ndviLayers)

      if (this.map) {
        this.map.eachLayer(layer => {
          if (layer.myTag && layer.myTag === "boundsGeoJSON")
            this.map.removeLayer(layer)
          if (layer.options.boundary) this.map.removeLayer(layer)
        })
        this.updateLayers()
      } else {
        this.map = L.map("map", {
          layers,
        })

        this.map.createPane("labels")
        this.map.getPane("labels").style.zIndex = 650

        this.map.on("mousemove", this.handleMapHover)
        this.map.on("click", this.handleMapClick)
      }
    },

    updateLayers() {
      const { ndviLayers, rgbLayers, boundsLayer } = this.getMapLayers()
      this.map.eachLayer(layer => {
        if (layer.myTag && layer.myTag === "boundsGeoJSON")
          this.map.removeLayer(layer)
        if (layer.options.boundary) this.map.removeLayer(layer)
      })

      boundsLayer.forEach(layer => this.map.addLayer(layer))
      if (this.layerType === "ndvi") {
        ndviLayers.forEach(layer => this.map.addLayer(layer))
      } else {
        rgbLayers.forEach(layer => this.map.addLayer(layer))
      }

      this.map.createPane("labels")
      this.map.getPane("labels").style.zIndex = 650
    },

    zoomToBounds() {
      if (this.bounds.coordinates.length === 0 || !this.map) return
      const geoJSON = L.geoJson(this.bounds)
      this.map.fitBounds(geoJSON.getBounds())
    },

    findFieldFromLatLng(latlng) {
      const { lat, lng } = latlng
      const pt = point([lng, lat])
      return this.boundaries.find(field => {
        const fieldMPoly = multiPolygon(field.geometry.coordinates)
        return booleanPointInPolygon(pt, fieldMPoly)
      })
    },

    findSelectedFieldFromLatLng(latlng) {
      const { lat, lng } = latlng
      const pt = point([lng, lat])
      return this.fields.find(field => {
        const fieldMPoly = multiPolygon(field.geometry.coordinates)
        return booleanPointInPolygon(pt, fieldMPoly)
      })
    },

    handleMapHover(e) {
      const { latlng } = e
      const hoveredField = this.findSelectedFieldFromLatLng(latlng)
      if (hoveredField) {
        const { field } = hoveredField.properties
        const { x, y } = e.containerPoint
        this.setHoverData({
          x,
          y,
          fieldName: field.name,
          farmName: field.farm.name,
          acreage: field.acreage,
        })
      } else {
        this.setHoverData()
      }
    },

    handleMapClick(e) {
      const { latlng } = e
      const clickedField = this.findFieldFromLatLng(latlng)
      if (clickedField) {
        const { field } = clickedField.properties
        this.toggleField({
          id: field.id,
          dropdownType: DROPDOWN.Field,
          preventAutozoom: true,
        })
        this.lastMapAction = "click"
      }
    },
    fetchOrders() {
      PlanetOrderAPI.get().then(resp => {
        this.orders = resp.data
        this.initialResponseComplete = true
      })
    },
  },

  mounted() {
    this.fetchOrders()
  },
  watch: {
    bounds() {
      this.updateMap()

      // if user deselects field, map won't snap to bounds
      if (this.lastMapAction === "click") {
        this.lastMapAction = null
        return
      }
      this.zoomToBounds()
    },

    datePickerDates() {
      const endDate = moment(this.datePickerDates.end).format("YYYY-MM-DD")
      this.selectedDate = endDate
      this.updateMap()
    },

    fields() {
      const fieldIds = this.fields.map(f => f.properties.field.id)
      if (fieldIds.length > 0) {
        FieldImageryAPI.post({
          field_ids: JSON.stringify(fieldIds),
        }).then(response => {
          const { data } = response
          this.ndviDates = data.ndvi_dates
          this.rgbDates = data.rgb_dates
          const allDates = this.allDates
          if (allDates.length > 0)
            this.selectedDate = this.allDates[this.allDates.length - 1]
        })
      }
    },

    layerType() {
      this.updateMap()
    },

    selectedDate() {
      this.updateMap()
    },
  },
}
</script>

<style scoped>
body {
  margin: 0;
  padding: 0;
}

.page {
  position: relative;
}

#map {
  height: calc(100vh - 65px);
  position: relative;
  background: #1b1b1d;
  margin: -17px -29px -17px -29px;
}

.planet-logo-wrapper {
  position: absolute;
  top: 15px;
  right: -5px;
  z-index: 500;
}

.planet-logo {
  width: 122px;
  height: 50px;
  background-image: url("/assets/images/logos/planet_logo.svg");
  background-size: 67%;
  background-color: rgba(0, 0, 0, 0.7);
  background-position: center;
  border-radius: 7px;
}

.control-wrapper {
  position: absolute;
  top: 80px;
  right: -5px;
  height: 100px;
  width: 90px;
  z-index: 500;
  background: white;
  border: 1pt solid #eee;
  box-shadow: 0px 0px 4px #ccc;
  padding: 13px;
  border-radius: 7px;
}

.dot {
  height: 12px;
  width: 12px;
  background-color: #26c6f8;
  border-radius: 50%;
  display: inline-block;
  margin-top: 4px;
}

.dot-text {
  margin-left: 8px;
}

.ndvi-legend {
  position: absolute;
  top: 13px;
  left: 35px;
  height: 32px;
  width: 385px;
  z-index: 1000;
  background: white;
  border: 1pt solid #eee;
  box-shadow: 0px 0px 4px #ccc;
  padding: 0px 11px 0px 15px;
  border-radius: 7px;
  z-index: 400;
}

.ndvi-legend > div {
  background-image: url("/assets/images/legends/ndvi_legend.png");
  background-size: contain;
  height: 100%;
  width: 100%;
}

.slider-wrapper-wrapper {
  position: absolute;
  bottom: 25px;
  z-index: 1000;
  width: 92%;
  box-shadow: 0px 0px 4px #ccc;
  border-radius: 7px;
  z-index: 400;
}

.slider-wrapper {
  position: relative;
  height: 90px;
  background: white;
  border: 1pt solid #eee;
  border-radius: 7px;
}

.date-padding {
  padding-left: 12px;
}

.slider-padding {
  padding: 7px 40px 0px 40px;
}

.zero-state {
  text-align: center;
  padding: 60px;
}

.zero-state > div {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
}
</style>
