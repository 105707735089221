<template>
  <div class="row reverse-margin">
    <div class="col scroll-col">
      <div class="card">
        <FieldViewSummary :farm="farm" :field="field" />
      </div>
      <div class="card h-auto">
        <FieldViewSettings
          :carbonSettings="carbonFieldSettingsForYear"
          :field="field"
          :year="year"
        />
      </div>
    </div>
    <div class="col map-col">
      <div class="map-wrapper" oncontextmenu="return false">
        <VueMapBox
          :accessToken="mapBoxToken"
          :settings="mapBoxSettings"
          @created="mapCreated"
          :class="['fill-wrapper']"
          ref="map"
        />

        <VueDeckGL
          :settings="deckSettings"
          :layers="deckLayers"
          @created="deckCreated"
          :class="['fill-wrapper']"
          :map="map"
          ref="deck"
        />

        <Tooltip v-if="Boolean(this.map) && Boolean(this.deck)" />

        <LayerController />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from "vuex"
import FieldViewSettings from "@/components/fields/FieldViewSettings"
import FieldViewSummary from "@/components/fields/FieldViewSummary"
import LayerController from "@/components/map/LayerController"
import Tooltip from "@/components/map/Tooltip"
import VueDeckGL from "@/components/map/VueDeckGL"
import VueMapBox from "@/components/map/VueMapBox"
import {
  DECKGL_SETTINGS,
  MAPBOX_SETTINGS,
  MAP_STYLES,
  MAPBOX_TOKEN,
} from "@/constants/map"
import { Defaults, Filter, Map } from "@/store/modules"

export default {
  name: "FieldView",
  props: ["fieldId"],
  components: {
    VueDeckGL,
    VueMapBox,
    Tooltip,
    LayerController,
    FieldViewSettings,
    FieldViewSummary,
  },

  data() {
    return {
      mapBoxToken: MAPBOX_TOKEN,
      mapBoxSettings: MAPBOX_SETTINGS,
      deckSettings: DECKGL_SETTINGS,
      map: {},
      deck: false,
      hovered: false,
      activeStyle: "satellite",
    }
  },

  computed: {
    ...mapGetters({
      selectedFields: Filter.Getters.getSelectedFields,
      carbonFieldSettings: Defaults.Getters.getNewFieldSettings,
    }),

    ...mapState({
      hoverData: state => state.Map.hoverData,
      deckLayers: state => state.Map.layers,
      isFetching: state => state.Map.isFetching,
      pitchMode: state => state.Map.pitchMode,
      fields: state => state.Fields.fields,
      mapFieldData: state => state.Map.fieldData,
      year: state => state.Organization.year,
      // carbon settings
      // fieldSettings: state => state.Defaults.fieldSettings,
    }),

    carbonFieldSettingsForYear() {
      if (this.carbonFieldSettings && this.carbonFieldSettings.length) {
        const settings = this.carbonFieldSettings[0][1].year_data
        const find = settings.find(d => d.year === parseInt(this.year))
        return find
      }
      return {}
    },

    field() {
      return this.fields[this.fieldId] ? this.fields[this.fieldId] : {}
    },

    farm() {
      return this.field && this.field.farm ? this.field.farm : {}
    },
  },

  methods: {
    ...mapActions({
      fetchCarbonSettingsByField: Defaults.Actions.fetchCarbonSettingsByField,
    }),

    ...mapMutations({
      autoZoom: Map.Mutations.autoZoom,
      disableCropIcons: Map.Mutations.disableCropIcons,
      setDeck: Map.Mutations.setDeck,
      togglePitchMode: Map.Mutations.togglePitchMode,
      updateSingleFieldExtentBounds:
        Map.Mutations.updateSingleFieldExtentBounds,
    }),

    mapCreated(map) {
      this.map = map
    },

    deckCreated(deck) {
      this.deck = deck
      setTimeout(() => {
        this.updateSingleFieldExtentBounds({ fieldId: parseInt(this.fieldId) })
        this.autoZoom()
      })
    },

    toggleStyle() {
      this.activeStyle = this.activeStyle === "dark" ? "satellite" : "dark"
      this.map.setStyle(MAP_STYLES[this.activeStyle])
    },

    togglePitch() {
      if (!this.pitchMode) this.disableCropIcons()
      this.togglePitchMode()
    },
  },

  created() {
    setTimeout(() => this.setDeck(this.$refs.deck))
  },

  async mounted() {
    this.updateSingleFieldExtentBounds({ fieldId: parseInt(this.fieldId) })
    await this.fetchCarbonSettingsByField({
      fieldIds: [parseInt(this.fieldId)],
    })
  },

  beforeDestroy() {
    this.updateSingleFieldExtentBounds({ fieldId: null })
  },

  watch: {
    // this is for smooth zooming when refreshing on the field view page
    mapFieldData() {
      this.updateSingleFieldExtentBounds({ fieldId: parseInt(this.fieldId) })
    },
  },
}
</script>

<style scoped>
h2 {
  font-weight: bold;
  border-bottom: 1pt solid #bbb;
  padding-bottom: 9px;
  margin-bottom: 20px;
}

.map-wrapper {
  height: calc(100vh - 65px);
  width: 105%;
  margin-top: -30px;
  margin-bottom: -17px;
  position: relative;
  background: #1b1b1d;
}

.fill-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.scroll-col {
  max-height: 85vh;
  overflow: scroll;
}

.reverse-margin {
  margin-bottom: -60px;
}
</style>
