<template>
  <b-sidebar id="evidencing-group-due-date-sidebar" width="534px" :title="getTitle" v-model="show" right shadow
    backdrop>
    <div class="sidebar-body">
      <div class="date-picker-wrapper">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="date"
            label="Select due date"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu=false">Cancel</v-btn>
          <v-btn text color="primary" @click="$refs.menu.save(date)">OK</v-btn>
        </v-date-picker>
      </v-menu>
      </div>

      <p class="table-explainer">Select at least one evidencing type to apply a due date to</p>

      <div class="table-wrapper">
        <v-simple-table>
          <thead>
            <tr>
              <th class="checkbox-head">
                <v-checkbox @click="handleAllClick" v-model="allAreSelected" />
              </th>
              <th>Evidencing Type</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="evidencingType, idx in getEvidencingTypes" :key="evidencingType + '--' + idx">
              <td><v-checkbox v-model="selectedEvidencingTypes" :value="evidencingType" /></td>
              <td>{{ getEvidencingName(evidencingType) }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </div>
    </div>

    <div class="sidebar-footer">
      <v-btn @click="cancelDueDateApplication" outlined height="48px">Cancel</v-btn>
      <v-btn @click="applyDueDate" :disabled="!isSaveable" outlined height="48px">Save</v-btn>
    </div>
  </b-sidebar>
</template>

<script>
import { EVIDENCING_TYPE_CHOICES } from "@/constants/defaults";

export default {
  name: "EvidencingGroupDueDateSidebar",
  props: {
    visible: { required: true },
    detailSpec: { required: true }
  },
  emits: ["closeSidebar"],
  data() {
    return {
      menu: null,
      date: '',
      show: false,
      cancelled: true,
      allAreSelected: false,
      selectedEvidencingTypes: [],
      EVIDENCING_TYPE_CHOICES
    }
  },
  methods: {
    handleAllClick() {
      if (this.selectedEvidencingTypes.length == this.detailSpec['evidencing'].length) {
        this.selectedEvidencingTypes = [];
      }
      else {
        this.selectedEvidencingTypes = this.detailSpec['evidencing'];
      }
    },
    removeSidebar() {
      this.$emit('closeSidebar', this.cancelled, this.date, this.selectedEvidencingTypes);
      this.selectedEvidencingTypes = [];
      this.allAreSelected = false;
      this.cancelled = true;
      this.date = '';
    },
    getEvidencingName(evidencingType) {
      const foundType = EVIDENCING_TYPE_CHOICES.find(({ value }) => value == evidencingType);
      if (foundType != null) {
        return foundType['name'];
      }

      return 'Not Specified';
    },
    cancelDueDateApplication() {
      this.cancelled = true;
      this.show = false;
    },
    applyDueDate() {
      this.cancelled = false;
      this.show = false;
    }
  },
  computed: {
    getTitle() {
      const { name } = this.detailSpec;
      return `Assign ${name} Evidencing Due Dates`;
    },
    getEvidencingTypes() {
      return this.detailSpec['evidencing']
    },
    isValidDate() {
      if (this.date != null && this.date.length > 4) return true
      return false
    },
    isSaveable() {
      if (this.isValidDate && this.selectedEvidencingTypes.length > 0) return true
      return false
    }
  },
  watch: {
    visible(curr) {
      this.show = curr;
    },
    show(curr) {
      if (!curr) this.removeSidebar(true);
    },
    detailSpec: {
      handler(curr) {
        if (curr != null && curr['evidencing'] != null && curr['evidencing'].length > 0) {
          this.selectedEvidencingTypes = curr['evidencing'];
        }
      },
      deep: true
    },
    selectedEvidencingTypes: {
      handler(curr) {
        if (curr != null && this.detailSpec != null && this.detailSpec['evidencing'] != null) {
          if (this.detailSpec['evidencing'].length == curr.length) {
            this.allAreSelected = true;
          }
          else {
            this.allAreSelected = false;
          }
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="css" scoped>
::v-deep(#evidencing-group-due-date-sidebar) {
  background: #FFFFFF !important;
}

p {
  color: #000000;
}

::v-deep #evidencing-group-due-date-sidebar header {
  padding: 24px 36px;
}

::v-deep #evidencing-group-due-date-sidebar header .close {
  margin-right: 16px !important;
}

::v-deep #evidencing-group-due-date-sidebar header strong {
  margin-right: auto;
  text-transform: capitalize;
}

.sidebar-body {
  padding: 0 36px 36px;
  position: relative;
}
.table-explainer {
  color: #000000;
  font-weight: bold;
  margin: 0 0 8px 0;
  padding: 0;
}
.date-picker-wrapper {
  margin: 0 0 24px 0;
}
.table-wrapper {
  padding: 0;
  margin: 0;
  background-color: #F7F8FA;
}
.table-wrapper .v-data-table {
  background-color: #F7F8FA;
  height: 500px;
  overflow-y: scroll;
}
.table-wrapper .v-data-table th {
  position: sticky;
  top: 0;
  background: #F7F8FA;
  z-index: 9;
}
.table-wrapper .v-data-table td {
  border: none !important;
}
.table-wrapper .v-data-table td .v-input,
.table-wrapper .v-data-table td ::v-deep(.v-input__slot) {
  padding: 0;
  margin: 0;
}
.table-wrapper .v-data-table td ::v-deep(.v-messages) {
  display: none;
}
.checkbox-head {
  width: 75px;
}
.sidebar-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 72px;
  background: #FFFFFF;
  border-top: 1px solid rgba(0, 0, 0, 0.25);
  padding: 0 36px;
  display: flex;
  align-items: center;
}
.sidebar-footer > button {
  float: left;
  margin-right: 16px;
}
</style>