<template>
  <b-sidebar
    width="80vw"
    id="sidebar-right"
    title="Manage Group & Contacts"
    right
    shadow
    backdrop
    bg-variant="white"
    v-model="show"
  >
    <v-progress-circular
      v-if="loading"
      indeterminate
      :size="64"
      color="#79c61c"
    />

    <div
      :class="`px-8 sidebar-body-inner-padding-bottom ${
        loading ? 'loading' : ''
      }`"
    >
      <v-row class="pt-4 my-0 sticky-top">
        <v-col>
          <div>
            <v-btn-toggle v-model="contractInputGroup" tile mandatory group color="#000000" class="d-flex flex-wrap contracts-button-toggle">
              <v-btn :ripple="false" height="42px" value="groupDetails" class="ml-0">Group Details</v-btn>
              <v-btn disabled :ripple="false" height="42px" value="fields">Fields</v-btn>
              <v-btn disabled :ripple="false" height="42px" value="newPractices">New Practices</v-btn>
              <v-btn disabled :ripple="false" height="42px" value="contract">Contract</v-btn>
            </v-btn-toggle>
          </div>
        </v-col>
      </v-row>

      <div
        class="contract-input-group pb-16"
        v-if="contractInputGroup == 'groupDetails'"
      >
        <v-row class="my-0">
          <v-col cols="8">
            <p class="ma-0 confirm-contract-text">
              Confirm contract selections & legal parties
            </p>
          </v-col>
        </v-row>

        <v-row class="mb-4">
          <v-col cols="4">
            <p class="mt-0 mb-4 font-weight-bold confirm-contract-text">
              Legal Information
            </p>

            <v-text-field
              hide-details
              height="40"
              class="contracttext-field"
              label="Entity Name"
              placeholder="Entity Name"
              underlined
              v-model="entityName"
            />
          </v-col>
        </v-row>

        <v-row class="mt-0 mb-2">
          <v-col>
            <p class="ma-0 font-weight-bold confirm-contract-text">
              Channel Partner and Contract Participant Contact Information
            </p>
          </v-col>
        </v-row>

        <v-expansion-panels
          multiple
          accordion
          v-model="cpProfilePanelIndices"
          v-if="
            makingNewCPProfile ||
            (entity != null && entity['cpProfiles'].length > 0)
          "
          class="mb-4"
        >
          <ClientProfileEditor
            v-for="(profile, idx) in entity['cpProfiles']"
            :key="profile + idx"
            :selected="profile"
            :clientProfiles="allClientProfiles"
            :isCPProfile="true"
            @modificationStatusChange="handleProfileStatusChange"
            @addProfile="addProfile"
            @swapProfile="swapProfile"
            @removeProfile="removeProfile"
            @createProfile="createGroupProfile"
            @updateProfile="updateGroupProfile"
            @cancelCreation="cancelCreation"
          />

          <ClientProfileEditor
            v-if="makingNewCPProfile"
            :selected="null"
            :clientProfiles="allClientProfiles"
            :isCPProfile="true"
            @modificationStatusChange="handleProfileStatusChange"
            @addProfile="addProfile"
            @swapProfile="swapProfile"
            @removeProfile="removeProfile"
            @createProfile="createGroupProfile"
            @updateProfile="updateGroupProfile"
            @cancelCreation="cancelCreation"
          />
        </v-expansion-panels>

        <v-row class="mt-0 mb-8">
          <v-col cols="4" class="d-flex flex-wrap py-0">
            <v-btn
              @click="makingNewCPProfile = true"
              :disabled="makingNewCPProfile || makingNewGrowerProfile"
              :ripple="false"
              outlined
            >
              <v-icon>mdi-plus-circle-outline</v-icon>
              <span class="ml-2">Add Applicants</span>
            </v-btn>
          </v-col>
        </v-row>

        <v-row class="mt-0 mb-2">
          <v-col>
            <p class="ma-0 font-weight-bold confirm-contract-text">
              Grower and Contract Owner Contact Information
            </p>
          </v-col>
        </v-row>

        <v-expansion-panels
          multiple
          accordion
          v-model="growerProfilePanelIndices"
          v-if="
            makingNewGrowerProfile ||
            (entity != null && entity['growerProfiles'].length > 0)
          "
          class="mb-4"
        >
          <ClientProfileEditor
            v-for="(profile, idx) in entity['growerProfiles']"
            :key="profile + idx"
            :selected="profile"
            :clientProfiles="allClientProfiles"
            :isCPProfile="false"
            @modificationStatusChange="handleProfileStatusChange"
            @addProfile="addProfile"
            @swapProfile="swapProfile"
            @removeProfile="removeProfile"
            @createProfile="createGroupProfile"
            @updateProfile="updateGroupProfile"
            @cancelCreation="cancelCreation"
          />

          <ClientProfileEditor
            v-if="makingNewGrowerProfile"
            :selected="null"
            :clientProfiles="allClientProfiles"
            :isCPProfile="false"
            @modificationStatusChange="handleProfileStatusChange"
            @addProfile="addProfile"
            @swapProfile="swapProfile"
            @removeProfile="removeProfile"
            @createProfile="createGroupProfile"
            @updateProfile="updateGroupProfile"
            @cancelCreation="cancelCreation"
          />
        </v-expansion-panels>

        <v-row class="mt-0 mb-0">
          <v-col cols="4" class="d-flex flex-wrap py-0">
            <v-btn
              @click="makingNewGrowerProfile = true"
              :disabled="makingNewCPProfile || makingNewGrowerProfile"
              :ripple="false"
              outlined
            >
              <v-icon>mdi-plus-circle-outline</v-icon>
              <span class="ml-2">Add Applicants</span>
            </v-btn>
          </v-col>
        </v-row>
      </div>

      <div
        class="contract-input-group pb-16"
        v-if="contractInputGroup == 'fields'"
      >
        <v-row class="my-0">
          <v-col>
            <v-data-table
              dense
              show-select
              hide-default-footer
              :items="fieldItems"
              :headers="FIELD_HEADERS"
              @click:row="handleClick"
            >
              <template v-slot:item.field="props">
                <div class="d-flex align-center pa-2">
                  <img class="thumbnail" :src="props.value['thumbnail']" />
                  <p class="mb-0 ml-4">{{ props.value["name"] }}</p>
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </div>

      <div
        class="contract-input-group pb-16"
        v-if="contractInputGroup == 'newPractices'"
      >
        <v-row class="my-0">
          <v-col>
            <v-data-table
              dense
              show-select
              hide-default-footer
              :items="fieldItems"
              :headers="FIELD_HEADERS"
              @click:row="handleClick"
            >
              <template v-slot:item.field="props">
                <div class="d-flex align-center pa-2">
                  <img class="thumbnail" :src="props.value['thumbnail']" />
                  <p class="mb-0 ml-4">{{ props.value["name"] }}</p>
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </div>

      <div
        class="contract-input-group pb-16"
        v-if="contractInputGroup == 'contract'"
      >
        <v-card outlined class="pa-6 mb-6">
          <v-row>
            <v-col>
              <v-data-table
                dense
                show-select
                hide-default-footer
                :items="loiItems"
                :headers="CONTRACT_HEADERS"
                @click:row="handleClick"
                height="200"
              >
                <template v-slot:top="props">
                  <div
                    v-bind="props"
                    class="d-flex justify-space-between align-center mb-4"
                  >
                    <div class="d-flex align-center flex-wrap">
                      <v-card-title
                        class="ma-0 pa-0 w-100 contract-table-header"
                        >LOI Contract</v-card-title
                      >
                      <v-card-subtitle
                        class="ma-0 pa-0 w-100 contract-table-subheader"
                        >Document Management</v-card-subtitle
                      >
                    </div>
                    <div class="d-flex w-50 justify-end">
                      <v-tooltip v-if="!generateLOIEnabled" left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on"
                            >mdi-information</v-icon
                          >
                        </template>
                        <span class="d-flex mb-2"
                          >To generate LOIs, you need:</span
                        >
                        <ul>
                          <li>
                            <v-icon
                              v-if="completeCPProfiles.length > 0"
                              class="mr-1"
                              color="success"
                              >mdi-check-circle-outline</v-icon
                            >
                            <v-icon v-else class="mr-1" color="error"
                              >mdi-close-circle-outline</v-icon
                            >
                            At least one completed CP profile
                          </li>
                          <li>
                            <v-icon
                              v-if="completeGrowerProfiles.length > 0"
                              class="mr-1"
                              color="success"
                              >mdi-check-circle-outline</v-icon
                            >
                            <v-icon v-else class="mr-1" color="error"
                              >mdi-close-circle-outline</v-icon
                            >
                            At least one completed grower profile
                          </li>
                          <li>
                            <v-icon
                              v-if="!saveIsEnabled"
                              class="mr-1"
                              color="success"
                              >mdi-check-circle-outline</v-icon
                            >
                            <v-icon v-else class="mr-1" color="error"
                              >mdi-close-circle-outline</v-icon
                            >
                            Save any other changes before generating
                          </li>
                        </ul>
                      </v-tooltip>

                      <v-btn
                        height="40px"
                        outlined
                        :ripple="false"
                        class="d-flex ml-2 mr-3"
                        :disabled="!generateLOIEnabled"
                        @click="handleOpenLOIModal"
                      >
                        <v-icon>mdi-file-document-plus-outline</v-icon>
                        <span class="ml-2">Generate LOI</span>
                      </v-btn>
                      <v-btn
                        disabled
                        class="mr-3"
                        outlined
                        :ripple="false"
                        height="40px"
                      >
                        Search
                      </v-btn>
                      <v-btn disabled outlined :ripple="false" height="40px">
                        Filter
                      </v-btn>
                    </div>
                  </div>
                </template>

                <template v-slot:item.dateGenerated="props">
                  {{ props.value.toLocaleDateString() }}
                </template>

                <template v-slot:item.due="props">
                  {{ props.value.toLocaleDateString() }}
                </template>

                <template v-slot:item.contractOwners="props">
                  <div class="d-flex flex-wrap">
                    <p class="w-100 mb-0">{{ props.value[0]["name"] }}</p>
                    <p class="w-100 mb-0">{{ props.value[0]["email"] }}</p>
                  </div>
                </template>

                <template v-slot:item.loiLastSent="props">
                  {{ props.value.toLocaleDateString() }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>

        <v-card outlined class="pa-6">
          <v-row>
            <v-col>
              <v-data-table
                dense
                show-select
                hide-default-footer
                :items="finalContractItems"
                :headers="CONTRACT_HEADERS"
                @click:row="handleClick"
                height="200"
              >
                <template v-slot:top="props">
                  <div
                    v-bind="props"
                    class="d-flex justify-space-between align-center mb-4"
                  >
                    <div class="d-flex align-center flex-wrap">
                      <v-card-title
                        class="ma-0 pa-0 w-100 contract-table-header"
                        >Final Contract</v-card-title
                      >
                      <v-card-subtitle
                        class="ma-0 pa-0 w-100 contract-table-subheader"
                        >Document Management</v-card-subtitle
                      >
                    </div>
                    <div class="d-flex w-50 justify-end">
                      <v-tooltip v-if="!generateFinalContractEnabled" left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on"
                            >mdi-information</v-icon
                          >
                        </template>
                        <span class="d-flex mb-2"
                          >To generate Final Contracts, you need:</span
                        >
                        <ul>
                          <li>
                            <v-icon
                              v-if="completeCPProfiles.length > 0"
                              class="mr-1"
                              color="success"
                              >mdi-check-circle-outline</v-icon
                            >
                            <v-icon v-else class="mr-1" color="error"
                              >mdi-close-circle-outline</v-icon
                            >
                            At least one completed CP profile
                          </li>
                          <li>
                            <v-icon
                              v-if="completeGrowerProfiles.length > 0"
                              class="mr-1"
                              color="success"
                              >mdi-check-circle-outline</v-icon
                            >
                            <v-icon v-else class="mr-1" color="error"
                              >mdi-close-circle-outline</v-icon
                            >
                            At least one completed grower profile
                          </li>
                          <li>
                            <v-icon class="mr-1"
                              >mdi-check-circle-outline</v-icon
                            >
                            <!-- <v-icon v-else class="mr-1">mdi-close-circle-outline</v-icon> -->
                            Field stuff, coming soon
                          </li>
                          <li>
                            <v-icon class="mr-1"
                              >mdi-check-circle-outline</v-icon
                            >
                            <!-- <v-icon v-else class="mr-1">mdi-close-circle-outline</v-icon> -->
                            Practices stuff, coming soon
                          </li>
                          <li>
                            <v-icon
                              v-if="!saveIsEnabled"
                              class="mr-1"
                              color="success"
                              >mdi-check-circle-outline</v-icon
                            >
                            <v-icon v-else class="mr-1" color="error"
                              >mdi-close-circle-outline</v-icon
                            >
                            Save any other changes before generating
                          </li>
                        </ul>
                      </v-tooltip>

                      <v-btn
                        height="40px"
                        outlined
                        :ripple="false"
                        class="d-flex ml-2 mr-3"
                        :disabled="!generateFinalContractEnabled"
                        @click="handleOpenFinalContractModal"
                      >
                        <v-icon>mdi-file-document-plus-outline</v-icon>
                        <span class="ml-2">Generate Final Contract</span>
                      </v-btn>
                      <v-btn
                        disabled
                        class="mr-3"
                        outlined
                        :ripple="false"
                        height="40px"
                      >
                        Search
                      </v-btn>
                      <v-btn disabled outlined :ripple="false" height="40px">
                        Filter
                      </v-btn>
                    </div>
                  </div>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </div>

    <div :class="`contract-navigation-handlers ${loading ? 'loading' : ''}`">
      <div>
        <v-btn :ripple="false" outlined height="52" @click="cancel()"
          >Exit</v-btn
        >
        <v-btn
          :ripple="false"
          outlined
          height="52"
          :disabled="!saveIsEnabled"
          @click="apply()"
          >Save</v-btn
        >
      </div>
    </div>
  </b-sidebar>
</template>

<script>
import Vue from "vue"
import ClientProfileEditor from "@/components/contractGroups/ClientProfileEditor.vue"
import { GROWER, CHANNEL_PARTNER } from "@/constants/contractGroups"
import {
  createProfile,
  updateProfile,
  swapGroupProfile,
  addProfileToGroup,
  removeProfileFromGroup,
} from "@/api/ContractGroupAPI"

const CONTRACT_HEADERS = [
  { text: "Date generated", value: "dateGenerated" },
  { text: "Due", value: "due" },
  { text: "Contract Owners", value: "contractOwners" },
  { text: "Status", value: "status" },
  { text: "LOI File", value: "loiFile" },
  { text: "LOI Last Sent", value: "loiLastSent" },
  { text: "Actions", value: "actions" },
]

const FIELD_HEADERS = [
  { text: "Field", value: "field" },
  { text: "Crop", value: "crop" },
  { text: "Farm", value: "farm" },
  { text: "Field ID", value: "fieldId" },
  { text: "Acres", value: "acres" },
]

export default {
  name: "ManageGroupSidebar",
  props: {
    entity: { type: Object },
    fields: { type: Array },
    allFieldSupplySummaries: { type: Array },
    allClientProfiles: { type: Array },
  },
  emits: ["closeSidebar", "openLOIModal", "renderGroupData"],
  components: { ClientProfileEditor },
  data() {
    const clientProfileStatuses = {}
    clientProfileStatuses[CHANNEL_PARTNER] = {}
    clientProfileStatuses[GROWER] = {}

    return {
      loading: false,
      error: false,
      show: false,
      cancelled: true,
      clientProfileStatuses,
      contractInputGroup: "groupDetails",
      dataForLOIModal: {},
      entityName: "",
      makingNewCPProfile: false,
      makingNewGrowerProfile: false,
      growerProfilePanelIndices: [],
      cpProfilePanelIndices: [],
      cpEmailsPreopen: [],
      growerEmailsPreopen: [],
      CONTRACT_HEADERS,
      FIELD_HEADERS,
      CHANNEL_PARTNER,
      GROWER,
    }
  },
  computed: {
    completeGrowerProfiles() {
      if (this.entity == null) return []
      return this.entity["growerProfiles"].filter(g => {
        if (g["first_name"] == null) return false
        if (g["last_name"] == null) return false
        if (g["phone_number"] == null) return false
        if (g["poc_email"] == null) return false
        if (g["street_address_1"] == null) return false
        if (g["city"] == null) return false
        if (g["state"] == null) return false
        if (g["zip_code"] == null) return false
        if (g["country"] == null) return false
        return true
      })
    },
    completeCPProfiles() {
      if (this.entity == null) return []
      return this.entity["cpProfiles"].filter(c => {
        if (c["first_name"] == null) return false
        if (c["last_name"] == null) return false
        if (c["phone_number"] == null) return false
        if (c["poc_email"] == null) return false
        if (c["street_address_1"] == null) return false
        if (c["city"] == null) return false
        if (c["state"] == null) return false
        if (c["zip_code"] == null) return false
        if (c["country"] == null) return false
        return true
      })
    },
    generateLOIEnabled() {
      if (this.saveIsEnabled) return false // if any modification hasn't been saved
      if (
        this.completeCPProfiles.length == 0 ||
        this.completeGrowerProfiles == 0
      )
        return false
      // we can allow LOI generation if there aren't any fields or practices listed
      return true
    },
    generateFinalContractEnabled() {
      if (!this.generateLOIEnabled) return false
      // TODO if no fields, disable
      // TODO if not practices (?) disable
      return true
    },
    saveIsEnabled() {
      if (this.entity != null) {
        if (this.entityName != this.entity["name"]) return true

        if (this.entity["cpProfiles"].length > 0) {
          for (const key in this.clientProfileStatuses[CHANNEL_PARTNER]) {
            if (this.clientProfileStatuses[CHANNEL_PARTNER][key] == true)
              return true
          }

          for (const key in this.clientProfileStatuses[GROWER]) {
            if (this.clientProfileStatuses[GROWER][key] == true) return true
          }
        }
      }

      return false
    },
    fieldItems() {
      if (this.fields == null || this.fields.length == 0) return []

      return this.fields.map(f => ({
        crop: f["crop"],
        acres: f["acreage"],
        fieldId: null,
        farm: null,
        field: {
          name: f["field"],
          thumbnail: f["thumbnail"],
        },
      }))
    },
    loiItems() {
      console.log("Entity: ", this.entity)
      return []
    },
    finalContractItems() {
      return []
    },
  },
  methods: {
    cancelCreation({ as_cp_profile }) {
      if (as_cp_profile) this.makingNewCPProfile = false
      else this.makingNewGrowerProfile = false
    },
    addProfile({ as_cp_profile, to_id }) {
      this.loading = true
      this.error = false

      addProfileToGroup({
        org_node_id: this.entity["org"],
        group_id: this.entity["id"],
        year: Number(this.entity["year"]),
        client_profile_id: to_id,
        as_cp_profile,
      })
        .then(({ data }) => {
          this.loading = false
          this.$emit("renderGroupData", data)
          this.maintainExpansionStateAfterModification({ as_cp_profile, data })
        })
        .catch(e => {
          this.loading = false
          this.error = e
        })
    },
    removeProfile({ as_cp_profile, remove_id }) {
      this.loading = true
      this.error = false

      removeProfileFromGroup({
        year: Number(this.entity["year"]),
        org_node_id: this.entity["org"],
        group_id: this.entity["id"],
        as_cp_profile,
        remove_id,
      })
        .then(({ data }) => {
          this.loading = false
          this.$emit("renderGroupData", data)
          this.maintainExpansionStateAfterModification({ as_cp_profile, data })
        })
        .catch(e => {
          this.loading = false
          this.error = e
        })
    },
    swapProfile({ as_cp_profile, from_id, to_id }) {
      this.loading = true
      this.error = false

      swapGroupProfile({
        year: Number(this.entity["year"]),
        org_node_id: this.entity["org"],
        group_id: this.entity["id"],
        as_cp_profile,
        from_id,
        to_id,
      })
        .then(({ data }) => {
          this.loading = false
          this.$emit("renderGroupData", data)
          this.maintainExpansionStateAfterModification({ as_cp_profile, data })
        })
        .catch(e => {
          this.loading = false
          this.error = e
        })
    },
    updateGroupProfile({ as_cp_profile, profile_dataset, profile_id }) {
      this.loading = true
      this.error = false

      updateProfile({
        year: Number(this.entity["year"]),
        org_node_id: this.entity["org"],
        group_id: this.entity["id"],
        profile_dataset,
        as_cp_profile,
        profile_id,
      })
        .then(({ data }) => {
          this.loading = false
          this.$emit("renderGroupData", data)
        })
        .catch(e => {
          this.loading = false
          this.error = e
        })
    },
    createGroupProfile({ as_cp_profile, profile_dataset }) {
      this.loading = true
      this.error = false

      createProfile({
        year: Number(this.entity["year"]),
        org_node_id: this.entity["org"],
        group_id: this.entity["id"],
        profile_dataset,
        as_cp_profile,
      })
        .then(({ data }) => {
          this.loading = false
          this.$emit("renderGroupData", data)
        })
        .catch(e => {
          this.loading = false
          this.error = e
        })
    },
    setLocalTrackers() {
      if (this.entity != null) {
        this.entityName = this.entity["name"]

        for (const profile of this.entity["cpProfiles"]) {
          this.clientProfileStatuses[CHANNEL_PARTNER][profile["id"]] = false
        }

        for (const profile of this.entity["growerProfiles"]) {
          this.clientProfileStatuses[GROWER][profile["id"]] = false
        }
      }
    },
    handleProfileStatusChange({ as_cp_profile, profileId, status }) {
      if (as_cp_profile)
        Vue.set(this.clientProfileStatuses[CHANNEL_PARTNER], profileId, status)
      else Vue.set(this.clientProfileStatuses[GROWER], profileId, status)
    },
    removeSidebar() {
      this.$emit("closeSidebar", this.cancelled)
      this.entityName = ""
      this.cancelled = true
      this.clientProfileStatuses[CHANNEL_PARTNER] = {}
      this.clientProfileStatuses[GROWER] = {}
    },
    cancel() {
      this.cancelled = true
      this.show = false
    },
    apply() {
      this.cancelled = false
      this.show = false
    },
    handleOpenLOIModal() {
      this.$emit("openLOIModal", {
        entityName: this.entityName,
        entity: this.entity,
      })
    },
    handleOpenFinalContractModal() {
      this.$emit("openFinalContractModal", {}) // TODO
    },
    handleClick(row) {
      console.log("row click!", row)
    },
    handlePrevNav() {
      // for (const key in this.formTabOrdering) {
      //   if (this.formTabOrdering[key] == this.contractInputGroup) {
      //     this.contractInputGroup = key;
      //     break;
      //   }
      // }
    },
    handleNextNav() {
      // this.contractInputGroup = this.formTabOrdering[this.contractInputGroup];
    },
    maintainExpansionStateAfterModification({ as_cp_profile, data }) {
      const targetIndices = as_cp_profile
        ? "cpProfilePanelIndices"
        : "growerProfilePanelIndices"
      const targetMakeKey = as_cp_profile
        ? "makingNewCPProfile"
        : "makingNewGrowerProfile"
      const [dKey, eKey] = as_cp_profile
        ? ["cp_profiles", "cpProfiles"]
        : ["grower_profiles", "growerProfiles"]

      const newAddition = data[dKey].find(({ id }) =>
        this.entity[eKey].every(c => c["id"] != id)
      )
      const newRemoval = this.entity[eKey].find(({ id }) =>
        data[dKey].every(c => c["id"] != id)
      )

      const indices = this[targetIndices].slice()
      const idsByIndices = this.entity[eKey]
        .map(({ id }, idx) => (indices.includes(idx) ? id : null))
        .filter(i => i)

      const newIndices = data[dKey]
        .map(({ id }, idx) => {
          if (newRemoval && newRemoval["id"] == id) return null
          if (newAddition && newAddition["id"] == id) return idx
          if (idsByIndices.includes(id)) return idx
          return null
        })
        .filter(d => d != null)

      if (this[targetMakeKey]) this[targetMakeKey] = false
      setTimeout(() => Vue.set(this, targetIndices, newIndices))
    },
  },
  watch: {
    visible(curr) {
      this.show = curr
    },
    show(curr) {
      if (!curr) this.removeSidebar()
      else {
        this.setLocalTrackers()
      }
    },
    makingNewCPProfile(curr) {
      if (curr) {
        Vue.set(
          this,
          "cpProfilePanelIndices",
          this.entity["cpProfiles"].length == 0
            ? [0]
            : [...this.cpProfilePanelIndices, this.entity["cpProfiles"].length]
        )
      }
    },
    makingNewGrowerProfile(curr) {
      if (curr) {
        Vue.set(
          this,
          "growerProfilePanelIndices",
          this.entity["growerProfiles"].length == 0
            ? [0]
            : [
                ...this.growerProfilePanelIndices,
                this.entity["growerProfiles"].length,
              ]
        )
      }
    },
  },
}
</script>

<style scoped>
#sidebar-right {
  background: #ffffff !important;
}

p {
  color: #000000;
}

.sticky-top {
  position: sticky;
  top: 0;
  background-color: #ffffff;
  z-index: 1;
}

.contracts-button-toggle > button {
  text-transform: none;
  padding: 12px 24px !important;
  letter-spacing: normal;
  border-radius: 0 !important;
  border: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25) !important;
  color: #374151;
}

.contracts-button-toggle
  > button.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background: none !important;
}

.v-btn {
  text-transform: none;
  letter-spacing: normal;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04),
    0px 1px 2px 0px rgba(16, 24, 40, 0.04);
}

.v-btn .v-btn__content > i {
  font-size: 20px;
}

.contracts-button-toggle > button.v-item--active {
  color: #61af02;
  border-bottom: 2px solid #61b100 !important;
  background: #f1fddf !important;
}

.contracts-button-toggle > button.v-item--active::before {
  display: none;
}

.contract-input-group p {
  color: #212121;
}

.contract-navigation-handlers button {
  border-color: rgba(0, 0, 0, 0.5);
}

.contract-navigation-handlers > div > button:not(:last-child) {
  margin-right: 12px;
}

p.disabled-label {
  opacity: 0.5;
}

::v-deep .fips-option-not-supported + ::before {
  background-color: red !important;
}

::v-deep .b-sidebar-outer {
  z-index: 9999999999;
}

::v-deep #sidebar-right {
  overflow: hidden;
}

.sidebar-body-inner-padding-bottom {
  padding-bottom: 96px;
}

::v-deep .contract-navigation-handlers {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px 32px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  background: #ffffff;
}
::v-deep header.b-sidebar-header {
  padding: 32px 32px 0;
  position: relative;
}
::v-deep header.b-sidebar-header > button.close {
  font-size: 32px;
}
::v-deep header.b-sidebar-header > strong {
  width: 100%;
  text-align: left;
  font-size: 32px;
  color: #000000;
  margin-left: 12px;
}
.confirm-contract-text {
  font-size: 18px;
  line-height: 28px;
}
:deep(.v-label) {
  color: #000000;
}
:deep(.v-data-table-header) {
  z-index: 1;
}
.thumbnail {
  width: 36px;
  height: 36px;
  object-fit: cover;
}
.contract-table-header {
  font-size: 24px;
  line-height: 32px;
}
.contract-table-subheader {
  font-size: 14px;
  line-height: 20px;
  color: #6b7280;
}
:deep(.v-data-table__empty-wrapper > td) {
  padding-top: 16px !important;
}
:deep(.v-list-item__title) {
  text-transform: lowercase;
}
.v-expansion-panels {
  z-index: 0;
}
.loading {
  pointer-events: none;
  opacity: 0.5;
}
.v-progress-circular {
  position: absolute;
  left: calc(50% - 36px);
  top: calc(50% - 36px);
}
.v-tooltip__content {
  background: rgba(255, 255, 255, 0.9);
  color: #000000;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}
.v-tooltip__content :deep(ul) {
  padding: 0;
}
.v-tooltip__content :deep(ul li) {
  list-style: none;
}
.v-tooltip__content :deep(i) {
  color: #000000;
}
</style>
