<template>
  <ArvaModal :name="name" title="Add Seed Plan" @close-modal="handleClose">
    <div class="px-2 py-2">
      <div class="row mx-0">
        <div class="col-5 text-bold">
          Crop
        </div>
        <div class="col-7">
          <div class="input-group">
            <v-select-old
              class="select-style w-100"
              label="display_name"
              :options="cropChoices"
              :reduce="o => o.value"
              v-model="crop"
            />
          </div>
        </div>
      </div>
      <div class="row mx-0">
        <div class="col-5 text-bold">
          Seeding Rate
        </div>
        <div class="col-7">
          <div class="input-group">
            <input type="number" class="form-control" v-model="seedingRate" />
          </div>
        </div>
      </div>
      <div class="row mx-0">
        <div class="col-5 text-bold">
          Seed Rate Units
        </div>
        <div class="col-7">
          <div class="input-group">
            <v-select-old
              class="select-style w-100"
              label="display_name"
              :options="seedRateUnitChoices"
              :reduce="o => o.value"
              v-model="seedingRateUnits"
            />
          </div>
        </div>
      </div>
      <div class="row mx-0">
        <div class="col-5 text-bold">
          Seed Manufacturer
        </div>
        <div class="col-7">
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              v-model="seedManufacturer"
            />
          </div>
        </div>
      </div>
      <div class="row mx-0">
        <div class="col-5 text-bold">
          Planting Date
        </div>
        <div class="col-7">
          <div class="input-group">
            <datepicker v-model="plantingDate" />
          </div>
        </div>
      </div>
      <div class="row mx-0">
        <div class="col-5 text-bold">
          Seed Variety
        </div>
        <div class="col-7">
          <div class="input-group">
            <input type="text" class="form-control" v-model="seedVariety" />
          </div>
        </div>
      </div>
      <div class="row mx-0">
        <div class="col-5 text-bold">
          Seed Cost Per Bag
        </div>
        <div class="col-7">
          <div class="input-group">
            <input
              type="number"
              class="form-control"
              v-model="seedCostPerBag"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div
          type="button"
          class="btn btn-primary btn-sm ml-auto mr-4"
          :class="{ disabled: !canSubmit }"
          @click="handleSubmit"
        >
          Ok
        </div>
      </div>
    </div>
  </ArvaModal>
</template>

<script>
import ArvaModal from "../ArvaModal"
import { CROP_DROPDOWN_OPTIONS } from "@/constants"
import {
  MONITOR_CHOICES,
  SEEDING_RATE_UNIT_CHOICES,
} from "@/constants/defaults"

export default {
  name: "SeedPlanModal",

  components: {
    ArvaModal,
  },

  data() {
    return {
      name: "seedPlanModal",
      cropChoices: CROP_DROPDOWN_OPTIONS,
      monitorChoices: MONITOR_CHOICES,
      seedRateUnitChoices: SEEDING_RATE_UNIT_CHOICES,
      crop: null,
      // monitorType: null,
      // biologicalName: null,
      plantingDate: null,
      seedCostPerBag: null,
      seedingRate: null,
      seedingRateUnits: null,
      seedManufacturer: null,
      // seedsPerBag: null,
      seedVariety: null,
    }
  },

  computed: {
    canSubmit() {
      const checkInputs = [
        this.crop,
        // this.monitorType,
        // this.biologicalName,
        this.plantingDate,
        this.seedCostPerBag,
        this.seedingRate,
        this.seedingRateUnits,
        this.seedManufacturer,
        // this.seedsPerBag,
        this.seedVariety,
      ]
      const anyNull = val => val === null
      return !checkInputs.some(anyNull)
    },
    seedPlan() {
      const plan = {
        cost_per_bag: { source: "User Input", value: this.seedCostPerBag },
        crop_id: { source: "User Input", value: this.crop },
        date: { source: "User Input", value: this.plantingDate },
        manufacturer: { source: "User Input", value: this.seedManufacturer },
        rate: { source: "User Input", value: this.seedingRate },
        unit: { source: "User Input", value: this.seedingRateUnits },
        variety: { source: "User Input", value: this.seedVariety },
      }
      return plan
    },
  },

  methods: {
    handleClose() {
      this.$emit("close-template-modal")
    },
    handleSubmit() {
      this.$emit("submit-seed-plan", this.seedPlan)
    },
  },
}
</script>
<style scoped>
.crop-icon {
  width: 30px;
  height: 30px;
}

.group-width {
  width: 30%;
}

.append-text-width {
  width: 100%;
}
</style>
