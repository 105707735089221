<template>
  <ArvaModal
    name="deleteSoilPlanModal"
    :title="titleText"
    @close-modal="handleCloseModal"
  >
    <div class="error-message">Do you want to delete this plan, {{ planName }}?</div>

    <div class="row footer-row">
      <div class="col-12 btn-row d-flex justify-content-end">
        <v-btn class="ma-1" color="error" @click="handleConfirmModal"
          >Confirm</v-btn
        >
        <v-btn class="ma-1" @click="handleCloseModal">Cancel</v-btn>
      </div>
    </div>
  </ArvaModal>
</template>

<script>
import ArvaModal from "./ArvaModal"

export default {
  name: "DeleteSoilPlanModal",
  props: {
    planName: { type: String },
    titleText: { type: String },
  },
  components: { ArvaModal },

  methods: {
    handleCloseModal() {
      this.$emit("close-modal")
    },
    handleConfirmModal() {
      this.$emit("confirm-delete")
    },
  },
}
</script>
<style scoped>
.error-message {
  font-size: 15px;
  font-weight: 500;
  padding: 30px 30px;
  color: red;
}

.footer-row {
  border-top: 1px solid #e9ecef;
  margin: 0px;
}

.btn-row {
  padding: 10px 40px;
}

.btn-secondary {
  color: #fff;
}
</style>
