import { Enrollment } from "@/store/modules"

const state = {
  previousEnrollment: null,
}

const mutations = {
  [Enrollment.Mutations.setPreviousEnrollment](state, enrollment) {
    console.log("set prev enrollment vuex:", enrollment)
    state.previousEnrollment = enrollment
  },
  [Enrollment.Mutations.clearPreviousEnrollment](state) {
    state.previousEnrollment = null
  },
}

export default {
  state,
  mutations,
}
