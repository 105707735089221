<template>
  <ArvaModal
    name="fieldImageryOrderModal"
    title="Order Field Imagery"
    @close-modal="handleCloseModal"
  >
    <div class="row">
      <div class="col">
        <div class="d-flex justify-content-center">
          <div class="planet-logo" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="d-flex justify-content-between order-row">
          <div>Number of Fields:</div>
          <div>{{ fieldIds.length }}</div>
        </div>
        <div class="d-flex justify-content-between order-row">
          <div>Number of Acres:</div>
          <div>{{ totalAcres }}</div>
        </div>
        <div class="d-flex justify-content-between order-row">
          <div>Imagery:</div>
          <div>NDVI, RGB</div>
        </div>
        <div class="d-flex justify-content-between order-row">
          <div>Contract Dates:</div>
          <div>
            <a @click="openStartDate">{{ contractStartDate | date }}</a> -
            {{ contractEndDate | date }}
            <vc-date-picker
              v-if="selectStartDate"
              v-model="contractStartDate"
              :min-date="minDate"
              :max-date="maxDate"
            />
          </div>
        </div>
      </div>
    </div>
    <ArvaModalSaveFooter
      confirmText="Place Order"
      cancelText="Cancel"
      @on-cancel="handleCloseModal"
      @on-confirm="handlePlaceOrder"
    />
  </ArvaModal>
</template>

<script>
import _ from "lodash"
import moment from "moment"

import PlanetOrderAPI from "@/api/PlanetOrderAPI"
import ArvaModal from "@/components/modals/ArvaModal"
import ArvaModalSaveFooter from "@/components/modals/ArvaModalSaveFooter"

export default {
  name: "FieldImageryOrderModal",
  props: ["fieldIds", "contractStartDate", "contractEndDate", "totalAcres"],
  components: {
    ArvaModal,
    ArvaModalSaveFooter,
  },

  data() {
    return {
      selectStartDate: false,
      minDate: new Date(2016, 6, 1),
      maxDate: null,
    }
  },

  methods: {
    handleCloseModal() {
      this.$emit("close-modal")
    },
    openStartDate() {
      this.selectStartDate = true
      this.maxDate = new Date(this.contractStartDate)
    },
    closeStartDate() {
      this.selectStartDate = false
    },
    handlePlaceOrder() {
      PlanetOrderAPI.post({
        contract_start_date: moment(this.contractStartDate).format(
          "YYYY-MM-DD"
        ),
        contract_end_date: moment(this.contractEndDate).format("YYYY-MM-DD"),
        field_ids: this.fieldIds,
      }).then(this.handleCloseModal)
    },
  },

  created() {
    this.handlePlaceOrder = _.throttle(this.handlePlaceOrder, 4000)
  },
}
</script>

<style scoped>
.planet-logo {
  width: 122px;
  height: 50px;
  background-image: url("/assets/images/logos/planet_logo.svg");
  background-size: 67%;
  background-color: rgba(0, 0, 0, 0.7);
  background-position: center;
  border-radius: 7px;
}

.order-row {
  padding: 0px 140px;
  font-weight: 500;
}

.order-row > div {
  margin: 5px 0px;
}
</style>
