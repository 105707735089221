<template>
  <div class="agt d-flex" :style="{ backgroundColor: colorStyle }">
    {{ agt }}
  </div>
</template>

<script>
import { getAgtColor } from "@/utility"

export default {
  name: "AGT",
  props: ["agt"],
  computed: {
    colorStyle() {
      return getAgtColor(this.agt)
    },
  },
}
</script>

<style scoped>
.agt {
  align-items: center;
  justify-content: center;
  padding-top: 1px;
  /* padding-left: 1px; */
  height: 21px;
  width: 65px;
  border-radius: 21px;
  color: white;
  text-align: center;
  font-weight: 500;
  font-size: 11px;
}
</style>
