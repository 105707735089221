<template>
  <b-sidebar id="add-evidencing-for-upload-sidebar" width="850px" title="Add Evidencing To Upload" v-model="show" right shadow backdrop>
    <div class="sidebar-body">
      <p>Add evidencing from existing group requests:</p>

      <div class="add-evidencing-table-wrapper">
        <v-simple-table>
          <thead>
            <tr>
              <th>Add?</th>
              <th>Evidencing ID</th>
              <th>Evidencing Type</th>
              <th>Field Ids</th>
              <th>Field Names</th>
              <th>Crops</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="evidencing, idx in possibleEvidencingChoices" :key="evidencing['name'] + '-' + idx">
              <td><v-checkbox v-model="selectedEvidencingChoices" :value="evidencing['id']" /></td>
              <td>{{ evidencing['id'] }}</td>
              <td>{{ getFormattedEvidencingType(evidencing['confirmation_type']) }}</td>
              <td>{{ evidencing['fields'].map(({ id, name }) => id).join(', ') }}</td>
              <td>{{ evidencing['fields'].map(({ id, name }) => name).join(', ') }}</td>
              <td>{{ evidencing['crops'].map(({ id, name }) => name).join(', ') }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </div>
    </div>

    <div class="sidebar-footer">
      <v-btn @click="cancel" outlined height="48px">Cancel</v-btn>
      <v-btn @click="send" outlined height="48px" :disabled="!isSaveable">Send</v-btn>
    </div>
  </b-sidebar>
</template>

<script>
import { mapState } from "vuex";
import { EVIDENCING_TYPE_CHOICES } from "@/constants/defaults";
export default {
  name: "AddEvidencingForUploadSidebar",
  emits: ["closeSidebar"],
  props: {
    visible: { type: Boolean, required: true },
    existingFulfills: { required: true },
    uploadId: { required: true }
  },
  data() {
    return {
      cancelled: true,
      show: false,
      possibleEvidencingChoices: [],
      selectedEvidencingChoices: [],
    }
  },
  computed: {
    ...mapState({
      user: state => state.User.user,
      activeEvidencingGroup: state => state.Evidencing.activeEvidencingGroup
    }),
    isSuperUser() {
      if (this.user != null) {
        return this.user['is_superuser'];
      }

      return false;
    },
    isSaveable() {
      return this.selectedEvidencingChoices.length > 0
    }
  },
  methods: {
    getFormattedEvidencingType(evidencingType) {
      const found = EVIDENCING_TYPE_CHOICES.find(({ value }) => value == evidencingType);
      if (found == null) return 'Not Specified';
      return found['name'];
    },
    cancel() {
      this.cancelled = true;
      this.show = false;
    },
    send() {
      this.cancelled = false;
      this.show = false;
    },
    removeSidebar() {
      this.$emit('closeSidebar', this.cancelled, this.uploadId, this.selectedEvidencingChoices);
      this.cancelled = true;
      this.possibleEvidencingChoices = [];
      this.selectedEvidencingChoices = [];
    },
  },
  watch: {
    visible(curr) {
      this.show = curr;
    },
    show(curr) {
      if (!curr) this.removeSidebar(true);
      else {
        if (this.activeEvidencingGroup != null && this.existingFulfills != null) {
          this.possibleEvidencingChoices = this.activeEvidencingGroup['confirmations'].filter(({ id }) => !(id in this.existingFulfills));
          this.possibleEvidencingChoices.sort((a, b) => a['confirmation_type'].localeCompare(b['confirmation_type']))
        }
      }
    }
  }
}
</script>

<style scoped>
::v-deep(#add-evidencing-for-upload-sidebar) {
  background: #FFFFFF !important;
  z-index: 99;
}

p {
  color: #000000;
  line-height: 1.5;
  font-size: 16px;
  margin: 0 0 16px;
}

::v-deep #add-evidencing-for-upload-sidebar header {
  padding: 24px 36px;
}

::v-deep #add-evidencing-for-upload-sidebar header .close {
  margin-right: 16px !important;
}

::v-deep #add-evidencing-for-upload-sidebar header strong {
  margin-right: auto;
  text-transform: capitalize;
}

.v-input {
  margin-top: 24px;
}

.sidebar-body {
  padding: 24px 36px 96px;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  align-content: flex-start;
}

.messages-container {
  background: #F7F8FA;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  height: 450px;
  border-radius: 8px;
  padding: 24px;
  width: 100%;
  overflow-y: scroll;
}

.messaging-inputs {
  width: 100%;
  height: 200px;
}

.sidebar-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 72px;
  background: #FFFFFF;
  border-top: 1px solid rgba(0, 0, 0, 0.25);
  padding: 0 36px;
  display: flex;
  align-items: center;
  z-index: 9;
}

.sidebar-footer>button {
  float: left;
  margin-right: 16px;
}
.add-evidencing-table-wrapper .v-data-table td .v-input,
.add-evidencing-table-wrapper .v-data-table td ::v-deep(.v-input__slot),
.add-evidencing-table-wrapper ::v-deep(.v-input--selection-controls__input) {
  padding: 0;
  margin: 0;
}
.add-evidencing-table-wrapper ::v-deep(.v-messages),
.add-evidencing-table-wrapper ::v-deep(.v-input--selection-controls__ripple) {
  display: none;
}
</style>