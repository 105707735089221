import API from "./API"

const get = () => API.get("/planet-order-fields/")

const post = payload => API.post("/planet-order/", payload)

const getZones = payload =>
  API.get("/imagery-field-zones/", { params: payload })

const updateZones = payload =>
  API.put(`/imagery-field-zones/${payload.id}/`, { payload })

const deleteZone = payload => API.delete(`imagery-field-zones/${payload.id}/`)

export default { get, post, deleteZone, getZones, updateZones }
