import SeedSelectorAPI from "@/api/SeedSelectorAPI"
import { Placements } from "@/store/modules"

const state = {
  cropsWithTrials: [],
  seedPlacementPlans: {},
}

const getters = {
  [Placements.Getters.getPlacementsList]: state =>
    Object.values(state.seedPlacementPlans).sort(
      (a, b) => b.field_list.length - a.field_list.length
    ),
}

const mutations = {
  [Placements.Mutations.setCropsWithTrials](state, crops) {
    const cropsWithTrialsArr = []
    crops.forEach(crop => cropsWithTrialsArr.push(crop))
    state.cropsWithTrials = cropsWithTrialsArr
  },

  [Placements.Mutations.setPlacements](state, placements) {
    const placementsDict = {}
    placements.forEach(item => (placementsDict[item.id] = item))
    state.seedPlacementPlans = placementsDict
  },
}

const actions = {
  [Placements.Actions.fetchCropsWithTrials]({ commit }) {
    return new Promise(resolve => {
      SeedSelectorAPI.fetchCropsWithTrials().then(response => {
        commit(Placements.Mutations.setCropsWithTrials, response.data)
        resolve()
      })
    })
  },

  [Placements.Actions.fetchSeedPlacementPlans]({ commit }) {
    return new Promise(resolve => {
      SeedSelectorAPI.fetchSeedPlans().then(response => {
        commit(Placements.Mutations.setPlacements, response.data)
        resolve()
      })
    })
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
