<template>
  <div class="card-body">
    <div>
      <b-alert v-model="hasError" variant="danger" dismissible>
        <span>Failed to switch organization:</span>
        <div v-if="errorMessage.length > 0">
          <div
            class="error-message ml-5 p-1"
            v-for="currentError in errorMessage"
            :key="currentError"
          >
            <li>{{ currentError }}</li>
          </div>
        </div>
      </b-alert>

      <div class="d-flex justify-center" v-if="loading">
        <div>
          <div class="load-spacer">
            <v-progress-circular
              :size="60"
              :width="8"
              color="green"
              indeterminate
            ></v-progress-circular>
          </div>
          <div class="mt-3">
            <h4 class="text-center">Loading Data...</h4>
          </div>
        </div>
      </div>

      <div v-else>
        <div class="row ml-3">
          <div class="col-lg-11 col-md-4 col-sm-6 col-xs-12">
            <span class="default-input-title">Corporation</span>
            <div class="input-group">
              <v-select
                v-model="corporation"
                :items="corporationOptions"
                item-text="name"
                return-object
                solo
                label="Select Organization"
              ></v-select>
            </div>
          </div>
        </div>

        <div class="row ml-3">
          <div class="col-lg-11 col-md-4 col-sm-6 col-xs-12">
            <span class="default-input-title">Organization</span>
            <div class="input-group">
              <v-select
                v-model="organization"
                :items="organizationOptions"
                item-text="name"
                return-object
                solo
                label="Select Organization"
              >
                <template #item="{ item, attrs, on }">
                  <div
                    :style="locationItemStyle(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ item.name }}
                  </div>
                </template>
              </v-select>
            </div>
          </div>
        </div>

        <div v-if="showSwitchBtn" class="row ml-3">
          <v-btn class="ml-auto mr-3 float-right" @click="switchOrganization">
            Switch
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex"
import UserAPI from "@/api/UserAPI"
import { User, Map } from "@/store/modules"
import router from "@/router"

export default {
  name: "SwitchBetweenSharedOrganizationsView",
  props: ["selectedCorp", "selectedOrg", "loading"],
  data() {
    return {
      corporation: this.selectedCorp ? this.selectedCorp : null,
      organization: this.selectedOrg ? this.selectedOrg : null,
      hasError: false,
      errorMessage: [],
    }
  },
  components: {},

  computed: {
    ...mapState({
      user: state => state.User.user,
      sharedOrgByUser: state => state.User.userSharedOrg,
    }),

    corporationOptions() {
      const corporationOptions = []
      if (this.sharedOrgByUser.length > 0) {
        this.sharedOrgByUser.forEach(row => {
          const option = {
            id: row.id,
            name: row.name,
          }

          corporationOptions.push(option)
        })
      }
      return corporationOptions
    },

    showSwitchBtn() {
      if (
        this.organization &&
        this.corporation &&
        this.organizationOptions &&
        this.organizationOptions.filter(
          record => this.organization.id === record.id
        ).length === 1 &&
        !this.organizationOptions
          .filter(record => this.organization.id === record.id)[0]
          .name.includes(this.selectedOrg.name)
      ) {
        return true
      }
      return false
    },

    organizationOptions() {
      const organizationOptions = []
      if (this.sharedOrgByUser.length > 0) {
        const sharedOrgByCorp = this.sharedOrgByUser.filter(
          row => this.corporation && row.name === this.corporation.name
        )

        if (sharedOrgByCorp.length === 1) {
          sharedOrgByCorp[0].shared_organization.forEach(row => {
            const option = {
              id: row.id,
              name: row.name,
              depth: row.depth,
            }
            organizationOptions.push(option)
          })
        }
      }
      return organizationOptions
    },
  },

  methods: {
    ...mapActions({
      fetchUser: User.Actions.fetchUser,
    }),
    ...mapMutations({
      setToken: User.Mutations.setToken,
      setLoading: User.Mutations.setLoading,
      resetMapState: Map.Mutations.resetState,
    }),

    resetError() {
      this.hasError = false
      this.errorMessage = []
    },

    locationItemStyle(item) {
      if (!item.depth) return null

      return item.depth > 1
        ? { "padding-left": item.depth + 1 + ".75em" }
        : null
    },

    async switchOrganization() {
      this.resetError()
      const payload = {
        corporation_id: this.corporation.id,
        organization_id: this.organization.id,
        user_id: this.user.id,
      }
      this.setLoading(true)

      await UserAPI.switchOrg(payload)
        .then(response => {
          this.setToken(response.data.token)
          this.clearCache() // Remove old cache
          this.resetMapState()
          localStorage.removeItem("arvaYear")
          this.fetchUser()
          setTimeout(this.loadingData, 30000)
        })
        .catch(error => {
          this.hasError = true
          this.errorMessage = error.response.data
          this.setLoading(false)
        })
    },

    async clearCache() {
      caches.open("mapbox-tiles").then(cache => {
        this.removeOldCache(cache)
      })
    },

    async removeOldCache(cache) {
      await cache.keys().then(cacheKeys => {
        cacheKeys.forEach(request => {
          const url = request.url
          cache.delete(url)
        })
      })
    },

    loadingData() {
      this.setLoading(false)
      router.push("/")
    },
  },
}
</script>

<style scoped></style>
