<template>
  <b-sidebar v-model="show" id="sidebar-task-detail-view" width="576px" :title="safeTask['title']" right
    shadow backdrop>
    <div v-if="loading['state'] == true">
      <div class="sidebar-loading-wrapper" :class="loading['action'] == LOADER_ERROR ? 'error-sizing' : ''">
        <v-progress-circular v-if="loading['action'] == LOADER_PROCESSING" :size="48" color="#79c61c"
          class="sidebar-progress" indeterminate />
        <v-icon v-else-if="loading['action'] == LOADER_SUCCESS" :size="48" color="#79c61c" dark>mdi-check-bold</v-icon>
        <v-icon v-else-if="loading['action'] == LOADER_ERROR" :size="48"
          color="#ff3c7e">mdi-alert-circle-outline</v-icon>

        <div class="sidebar-error-message" v-if="loading['action'] == LOADER_ERROR">
          <p class="text-danger">Something went wrong...</p>
          <p>The request could not be processed ({{ loadingErrorMsg }}).</p>
          <v-btn @click="resetLoadingState()">close</v-btn>
        </div>
      </div>
      <div class="progress-overlay" />
    </div>

    <div v-if="safeTask['type'] == 'practiceconfirmation'" class="notifications-sidebar-body">
      <div>
        <p>
          You are receiving this because you have been assigned to provide
          evidencing for
          {{ safeTask["data"]["model_related"].length }} practices.
        </p>

        <p>
          To provide evidencing, please go to the evidencing dashboard under sustainability,
          or use the Arva Evidencing mobile application.
        </p>

        <div class="notification-btn-links">
          <p>Go to...</p>
          <v-btn disabled height="44px" outlined>Arva Evidencing (Mobile)</v-btn>
          <v-btn href="/evidencing" height="44px" outlined>Evidencing Dashboard</v-btn>
        </div>
      </div>
    </div>
  </b-sidebar>
</template>

<script>
import {
  BLANK_TASK_SIDEBAR,
  LOADER_PROCESSING,
  LOADER_SUCCESS,
  LOADER_ERROR,
} from "@/constants/arvaNotifications"
import { EVIDENCING_TYPE_CHOICES } from "@/constants/defaults"

export default {
  name: "NotificationTaskDetailSidebar",
  props: {
    visible: { required: true },
    task: { required: true }
  },
  emits: ["sidebarClosed"],
  data() {
    return {
      loading: {
        state: false,
        action: LOADER_PROCESSING,
      },
      show: false,
      loadingErrorMsg: "",
      LOADER_ERROR,
      LOADER_PROCESSING,
      LOADER_SUCCESS,
      EVIDENCING_TYPE_CHOICES,
    }
  },
  computed: {
    safeTask() {
      if (this.task == null) return BLANK_TASK_SIDEBAR
      return this.task
    },
  },
  methods: {
    removeSidebar(refresh=false) {
      this.$emit("sidebarClosed", refresh)
      this.resetLoadingState()
    },
    resetLoadingState() {
      this.loading["state"] = false
      this.loading["action"] = LOADER_PROCESSING
      this.loadingErrorMsg = ""
    }
  },
  watch: {
    visible(curr) {
      this.show = curr;
    },
    show(curr) {
      if (!curr) this.removeSidebar()
    }
  },
}
</script>

<style scoped>
p {
  color: #000000;
}

.sidebar-loading-wrapper {
  position: absolute;
  top: calc(50% - 48px);
  left: calc(50% - 48px);
  width: 96px;
  background-color: white;
  padding: 24px;
  z-index: 10;
  border-radius: 8px;
}

.sidebar-loading-wrapper.error-sizing {
  width: 250px;
  text-align: center;
  left: calc(50% - 125px);
}

.progress-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.15);
  z-index: 9;
}

.notifications-sidebar-body {
  padding: 24px 36px;
}

.whitespace-pre-instructions {
  white-space: pre;
}

button:deep(.v-ripple__container) {
  display: none !important;
}

.notification-btn-links {
  margin-top: 24px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 24px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  background: #ffffff;
}

.notification-btn-links p {
  width: 100%;
}

.notification-btn-links>a:not(:last-child),
.notification-btn-links>button:not(:last-child) {
  margin: 0 0 12px 0;
}
</style>