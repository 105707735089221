<template>
  <div class="px-3">
    <div v-if="view === 'list' && yieldZonationPlans.length > 0">
      <div class="font-weight-bold">
        List of {{ isYield ? "Yield" : "AGT" }} Zonation Plans
      </div>
      <table class="table">
        <thead>
          <tr>
            <th>Plan</th>
            <th>Date</th>
            <th>Fields</th>
            <th></th>
          </tr>
          <tr v-for="plan in yieldZonationPlans" :key="plan.id">
            <td class="hover" @click="handleZonePlanSelect(plan)">
              {{ plan.name }}
            </td>
            <td class="hover" @click="handleZonePlanSelect(plan)">
              {{ plan.updated_at | date }}
            </td>
            <td class="hover" @click="handleZonePlanSelect(plan)">
              {{ plan.fields.length }}
            </td>
            <td v-if="!isReadOnly">
              <i
                class="fa fa-times-circle"
                @click="handleDeletePlan(plan.id)"
              />
            </td>
          </tr>
        </thead>
      </table>
      <!--
      <button
        type="button"
        class="btn btn-primary update-btn py-2"
        @click="viewCreatePlan"
      >
        Create New Plan
      </button>-->
    </div>
    <div
      v-else-if="
        view === 'list' && yieldZonationPlans.length < 1 && !finishedLoading
      "
      class="my-3 d-flex justify-content-center"
      justify="center"
    >
      <div class="text-bold mb-0 mt-1">Fetching Data</div>
      <div class="spinner-border ml-4" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div
      v-else-if="
        view === 'list' && yieldZonationPlans.length < 1 && finishedLoading
      "
      class="my-3 d-flex justify-content-center"
      justify="center"
    >
      <div class="text-bold mb-0 mt-1">No Plans Found</div>
    </div>
    <div v-if="view === 'create'">
      <div>
        <!-- create view -->
        <div
          class="font-weight-bold text-primary hover"
          @click="viewList(true)"
        >
          <i class="fa fa-arrow-circle-left" />
          Return to List
        </div>
        <div class="font-weight-bold">Create New Zone Plan</div>
        <div>
          <div>Name</div>
          <input
            class="w-100 form-control"
            type="text"
            v-model="createPlanName"
          />
        </div>
        <div class="my-3">
          <div>Select Crop</div>
          <v-select-old
            :appendToBody="true"
            label="display_name"
            :options="cropOptions"
            :reduce="o => o.value"
            v-model="cropToAdd"
            v-on:input="cropChanged"
          />
        </div>
        <div class="my-3" v-if="isYield">
          <div>Select Years</div>
          <v-select-old
            :appendToBody="true"
            multiple
            v-if="seasonOptions.length > 0"
            :options="seasonOptions"
            v-model="yearsSelected"
          />
        </div>
        <div v-if="isYield">
          <div>Number of Zones</div>
          <div class="mt-2 mb-5 px-2">
            <v-slider
              v-model="numZones"
              :min="2"
              :max="9"
              :step="1"
              :tick-labels="[2, 3, 4, 5, 6, 7, 8, 9]"
              :tick-size="2"
            />
          </div>
        </div>
        <div v-if="isYield">
          <div>Minimum Acreage</div>
          <div class="mt-2 mb-5 px-2">
            <v-slider
              v-model="minAcreage"
              :min="0.5"
              :max="10"
              :step="0.5"
              :tick-labels="[
                0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8,
                8.5, 9, 9.5, 10,
              ]"
              :ticks="always"
              :tick-size="2"
            />
          </div>
        </div>
        <div class="d-flex">
          <button
            type="button"
            class="btn btn-primary update-btn py-2"
            @click="handleYieldZoneSubmit"
          >
            Submit Plan
            <div
              v-if="isPlanLoading"
              class="spinner-border spinner-border-sm text-light ml-1"
              role="status"
            ></div>
          </button>
        </div>
        <div v-if="createPlanError">
          <div class="text-danger mb-3">
            An error occurred when creating the zone plan.
          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->
    <div v-if="view === 'plan'">
      <div class="font-weight-bold text-primary hover" @click="viewList(true)">
        <i class="fa fa-arrow-circle-left" />
        Return to List
      </div>
      <div v-if="!isReadOnly" class="mb-3">
        <div class="font-weight-bold">Edit Zone Prescription Name</div>
        <div>
          <input
            class="form-control"
            type="text"
            :value="selectedZone.name"
            @input="handleZoneNameUpdate"
          />
        </div>
      </div>
      <div v-if="!isReadOnly">
        <div class="font-weight-bold">Select/Add Product to Zones</div>
        <div class="d-flex flex-row pb-2">
          <div class="w-100">
            <v-select-old
              class="select-style"
              taggable
              push-tags
              v-model="productToAdd"
              label="display_name"
              :reduce="o => o.value"
              :options="allFertChoices"
            />
          </div>
        </div>
        <div class="d-flex flex-row">
          <div class="w-50 pr-2">
            <span class="default-input-title">Amount</span>
            <input class="form-control" type="number" v-model="amountToAdd" />
          </div>
          <div class="w-50">
            <span class="default-input-title">Units</span>
            <v-select-old
              class="select-style"
              v-model="unitsToAdd"
              :options="zoneUnitsOptions"
            />
          </div>
        </div>
        <div class="d-flex flex-row pt-2 pb-4">
          <div class="w-50 pr-2">
            <span class="default-input-title">Select Zones</span>
            <v-select-old
              class="select-style"
              multiple
              v-model="zonesToAdd"
              :reduce="zToAdd => zToAdd.cluster"
              :options="zonesAddOptions"
            />
          </div>
          <div class="w-50">
            <button
              type="button"
              class="btn btn-primary wide-btn"
              :class="{ disabled: !canAddProduct }"
              @click="handleAddProduct"
            >
              <i class="fa fa-plus" />Add Product
            </button>
          </div>
        </div>
      </div>
      <div>
        <table class="table table-striped">
          <thead>
            <tr>
              <th class="w-35">Zone</th>
              <th class="w-65">Products</th>
            </tr>
          </thead>
          <tbody>
            <ZonesControllerRow
              v-for="zone in selectedZoneRows"
              :key="zone.cluster"
              :agt="!isYield"
              :rx="true"
              :zone="zone"
              :zoneIndex="selectedZoneRows.indexOf(zone)"
              :totalZones="selectedZoneRows.length"
              @delete-product="handleDeleteProduct"
            />
          </tbody>
        </table>
        <div class="mb-3">
          <div class="font-weight-bold">Product Total</div>
          <table class="table">
            <tbody>
              <tr
                v-for="product in Object.keys(zonesTotalProduct)"
                :key="product"
              >
                <td>{{ product }}</td>
                <td class="text-right">
                  <div class="amt">
                    {{ zonesTotalProduct[product]["amount"] | prettyInteger }}
                    {{ zonesTotalProduct[product]["units"].slice(0, -3) }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="!isReadOnly">
          <div>
            <button
              type="button"
              class="btn btn-primary update-btn py-2 mr-0 float-right"
              @click="handleExport"
            >
              Export Shapefile
              <div
                v-if="isZipExportLoading"
                class="spinner-border spinner-border-sm text-light ml-1"
                role="status"
              ></div>
            </button>
          </div>
          <!-- <button
            type="button"
            class="btn btn-primary update-btn py-2 mr-0 float-right"
            @click="handleExport"
          >
            Export Shapefile
            <div
              v-if="isZipExportLoading"
              class="spinner-border spinner-border-sm text-light ml-1"
              role="status"
            ></div>
          </button> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash"
import { mapState } from "vuex"

import ZoneRxAPI from "@/api/ZoneRxAPI"
import ZonesControllerRow from "@/components/fieldImageryMap/ZonesControllerRow"
import { ZONES_PRODUCT_UNITS } from "@/constants/map.js"
import { CROP_DISPLAY_NAME } from "@/constants"
import { ALL_FERT_CHOICES } from "@/constants/defaults"

export default {
  name: "ZonesControllerYield",
  props: [
    "activeFields",
    "isYield",
    "tab",
    "yieldZonationPlans",
    "zoneControllerView",
    "years",
    "finishedLoading",
  ],
  components: {
    ZonesControllerRow,
  },
  data() {
    return {
      allFertChoices: ALL_FERT_CHOICES,
      amountToAdd: null,
      clusterData: [],
      createPlanError: false,
      createPlanName: null,
      cropToAdd: null,
      datesSelected: [],
      isPlanLoading: false,
      isUpdatePlanLoading: false,
      isZipExportLoading: false,
      minAcreage: 5.5,
      numZones: 2,
      productToAdd: null,
      selectedZone: null,
      unitsToAdd: null,
      yearsSelected: [],
      zonesToAdd: null,
      zoneUnitsOptions: ZONES_PRODUCT_UNITS,
    }
  },
  computed: {
    ...mapState({
      isReadOnly: state => state.User.user.permission === "Read-Only",
      orgAgtCrops: state => state.Map.orgAgtCrops,
      //years: state => state.Organization.organization.years,
    }),

    canAddProduct() {
      const { amountToAdd, productToAdd, unitsToAdd, zonesToAdd } = this
      return !!amountToAdd && !!productToAdd && !!unitsToAdd && zonesToAdd
    },

    cropOptions() {
      const options = this.orgAgtCrops
        .map(cropId => ({
          value: cropId,
          display_name: CROP_DISPLAY_NAME[cropId],
        }))
        .filter(e => {
          return e.display_name != "Generic"
        })
      return options
    },

    seasonOptions() {
      //  returns same years as sidenav
      //   if (!this.years || typeof this.years === "undefined") return []
      //   return this.years
      // TODO: change this to become dynamic
      return this.years
    },

    selectedZoneRows() {
      if (!this.selectedZone) return []
      return _.sortBy(this.selectedZone.zones, ["cluster"])
    },

    view() {
      return this.zoneControllerView
    },

    zonesAddOptions() {
      if (this.selectedZone) {
        const options = this.selectedZone.zones.map(z => ({
          label: "Zone " + z.cluster,
          cluster: z.cluster,
        }))
        return options
      }
      return []
    },

    zonesTotalProduct() {
      if (this.selectedZone) {
        let productsTotal = {}
        this.selectedZone.zones.forEach(z => {
          const zoneAcreage = z.area
          if (z.products) {
            z.products.forEach(prod => {
              if (!productsTotal[prod.product]) {
                productsTotal[prod.product] = {
                  amount: parseInt(prod.amount) * zoneAcreage,
                  units: prod.units,
                }
              } else {
                productsTotal[prod.product].amount +=
                  parseInt(prod.amount) * zoneAcreage
              }
            })
          }
        })
        return productsTotal
      }
      return {}
    },
  },

  methods: {
    cropChanged(d) {
      this.$emit("update-map-params", d)
    },
    viewCreatePlan() {
      this.$emit("set-view-list", "create")
    },
    viewList(reset) {
      if (reset) {
        this.selectedZone = null
        this.$emit("reset-zones")
      }
      this.createPlanError = false
      this.$emit("set-view-list", "list")
    },
    viewPlan() {
      this.$emit("set-view-list", "plan")
    },
    handleAddProduct() {
      const amount = this.amountToAdd
      const product = this.productToAdd
      const units = this.unitsToAdd
      const zones = this.zonesToAdd

      zones.forEach(zone => {
        const matchingZone = this.selectedZone.zones.findIndex(
          z => z.cluster === zone
        )
        const zoneToAdd = this.selectedZone.zones[matchingZone]
        if (!zoneToAdd.products) {
          zoneToAdd.products = []
        }
        zoneToAdd.products.push({
          amount,
          product,
          units,
        })
      })

      // need to use Object assign to have Vue become reactive
      const selectedZoneCopy = _.cloneDeep(this.selectedZone.zones)
      this.selectedZone = Object.assign({}, this.selectedZone, {
        zones: selectedZoneCopy,
      })

      this.amountToAdd = null
      this.zonesToAdd = null

      this.handleUpdate()
    },
    async handleDeletePlan(planId) {
      const payload = {
        id: planId,
      }
      await ZoneRxAPI.deleteYieldZone(payload)
      this.$emit("update-list")
    },
    handleDeleteProduct(product, zone) {
      const matchingZone = this.selectedZone.zones.findIndex(
        z => z.cluster === zone.cluster
      )
      const zoneToFilter = this.selectedZone.zones[matchingZone]
      zoneToFilter.products = _.reject(zoneToFilter.products, product)
      const selectedZoneCopy = _.clone(this.selectedZone)
      this.selectedZone = selectedZoneCopy
      this.handleUpdate()
    },
    async handleUpdate() {
      await ZoneRxAPI.updateYieldZones(this.selectedZone)
      this.$emit("update-list")
    },
    handleZoneNameUpdate(e) {
      const { target } = e
      const inputValue = target.value
      this.selectedZone.name = inputValue
    },
    handleZonePlanSelect(selectedZone) {
      this.selectedZone = selectedZone

      this.viewPlan()
      // this.view = "plan"
      const parsedGeoJSON = JSON.parse(selectedZone.geometry)

      this.$emit(
        "select-fields",
        selectedZone.fields.map(f => f.id)
      )
      this.$emit("update-zones", parsedGeoJSON)
      this.selectedZoneName = this.selectedZone.name
    },
    async handleYieldZoneSubmit() {
      this.isPlanLoading = true
      const payload = this.isYield
        ? {
            name: this.createPlanName,
            field_ids: this.activeFields.map(field => field.id),
            years: this.yearsSelected.map(year => parseInt(year)),
            crop_id: this.cropToAdd,
            n_cluster: this.numZones,
            min_ac: this.minAcreage,
            use_agt: false,
          }
        : {
            name: this.createPlanName,
            field_ids: this.activeFields.map(field => field.id),
            years: [2017, 2018, 2019, 2020, 2021],
            crop_id: this.cropToAdd,
            n_cluster: 0,
            min_ac: 0,
            use_agt: true,
          }

      await ZoneRxAPI.fetchYieldZones(payload)
        .then(response => {
          const zoneObject = response.data
          this.selectedZone = zoneObject
          this.selectedZoneName = zoneObject.name
          const parsedGeoJSON = JSON.parse(zoneObject.geometry)
          this.$emit("update-zones", parsedGeoJSON)
          this.viewPlan()

          // removes error view upon successful creation
          this.createPlanError = false
        })
        .catch(err => {
          console.log(err)
          this.createPlanError = true
        })
      this.isPlanLoading = false
      this.createPlanName = null
      this.$emit("update-list")
    },
    async handleExport() {
      const payload = {
        zone_plan_id: this.selectedZone.id,
        name: this.selectedZone.name,
        zones: this.selectedZone.zones,
      }
      await ZoneRxAPI.fetchYieldZonesExport(payload).then(response => {
        const link = document.createElement("a")
        link.href = response.data.signed_url
        link.setAttribute("download", "file.zip")
        link.click()
        link.remove()
      })

      this.isZipExportLoading = false
    },
  },
}
</script>
<style scoped>
.default-input-title {
  font-size: 14px;
}

.fa-times-circle {
  cursor: pointer;
  color: #e60000;
}

.hover {
  cursor: pointer;
}

.info-accent {
  color: #757575;
  font-size: 12px;
  font-weight: bold;
}

.select-style .vs__dropdown-toggle {
  height: 37px;
}

.wide-btn {
  width: 100%;
  margin: 0;
  margin-top: 20px;
  padding-bottom: 8px;
}

.w-35 {
  width: 35%;
}

.w-65 {
  width: 65%;
}

.amt {
  font-size: 16px;
  font-weight: bold;
}

.units {
  font-size: 10px;
}
</style>
