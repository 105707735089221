<template>
  <ArvaModal
    name="executeInsetOrderModal"
    title="Execute Inset Supply Program"
    :wide="true"
    :width="1100"
    @close-modal="handleCloseModal"
  >
    <v-card class="pa-5">
      <form @submit.prevent="handleSubmit">
        <div>
          <h4>Requested Program Filters:</h4>
          <v-card class="pa-1 ma-2">
            <v-data-table
              v-if="previewDataFrame"
              :headers="headers"
              :items="previewDataFrame"
            ></v-data-table>
          </v-card>

          <div v-if="showError" class="pw-error pa-2">{{ errorText }}</div>
          <div class="mt-3">
            <v-select
              v-model="selectedOrderRollup"
              label="Parent Program"
              :items="orderRollupOptions"
            ></v-select>
          </div>

          <ArvaModalSaveFooter
            confirmText="Execute"
            cancelText="Cancel"
            @on-cancel="handleCloseModal"
            @on-confirm="handleSubmit"
          />
        </div>
      </form>
    </v-card>
  </ArvaModal>
</template>

<script>
import ArvaModal from "@/components/modals/ArvaModal"
import ArvaModalSaveFooter from "@/components/modals/ArvaModalSaveFooter"
import CarbonReadyAPI from "@/api/CarbonReadyAPI"
import { faAd } from "@fortawesome/free-solid-svg-icons"

export default {
  name: "ExecuteInsetOrderModal",
  props: [
    "filterParams",
    "headers",
    "items",
    "currentOrderName",
    "currentOrderID",
  ],
  components: { ArvaModal, ArvaModalSaveFooter },

  data() {
    return {
      previewDataFrame: null,
      orderName: null,
      showError: false,
      errorText: "",
      orderPrice: 15,
      orderGeoids: [],
      supplyTarget: 10000,
      orderRollupOptions: [],
      selectedOrderRollup: null,
    }
  },

  methods: {
    handleCloseModal() {
      this.$emit("close-modal")
    },
    handleSubmit() {
      if (this.currentOrderID != null) {
        if (this.selectedOrderRollup == null) {
          this.errorText = "Please Select a Parent Program"
          this.showError = true
          return
        }
        this.showError = false
        let payload = {
          order_id: this.currentOrderID,
          status: "executed",
          selectedParentProgram: this.selectedOrderRollup,
        }
        CarbonReadyAPI.updateInsetOrder(payload).then(response => {
          this.$emit("save-from-modal")
        })
      }
    },
  },
  mounted() {
    this.previewDataFrame = []
    for (const [key, value] of Object.entries(
      this.filterParams["filter_params"]
    )) {
      if (typeof value === "object" && value !== null) {
        this.previewDataFrame.push({
          category: key,
          value: JSON.stringify(value, null, 1),
        })
      } else {
        this.previewDataFrame.push({ category: key, value: value })
      }
    }
    CarbonReadyAPI.getInsetOrderRollups().then(response => {
      response.data.forEach(e => {
        this.orderRollupOptions.push({ value: e.id, text: e.name })
      })
    })
  },
}
</script>

<style scoped>
.pw-error {
  color: red;
  font-weight: 500;
}
</style>
