<template>
  <ArvaModal
    name="wizardConfirmModal"
    title="Confirm Field Info"
    :wide="true"
    :width="1000"
    @close-modal="handleClose"
  >
    <v-card-text v-if="!loadState">
      <div class="ml-2 mb-3 text-h5">
        Do you want to confirm the following settings for
        {{ confirmationField.fieldName }} in {{ year }}?
      </div>
      <div class="ml-2 mt-4 text-h7">
        <v-simple-table class="mx-4">
          <thead>
            <tr>
              <th v-for="keyName in wantedKeys" :key="keyName.idx">
                {{ keyName == "crop_id" ? "Crop" : keyName | cleanSnake }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="data in confirmationData" :key="data.idx">
              <td v-for="key in wantedKeys" :key="key.idx">
                <span v-if="key == 'crop_id'">{{ cropDisplayName[data[key].value] }}</span>
                <span v-else>{{ data[key].value ? data[key].value : "None" }}</span>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </div>
    </v-card-text>
    <v-card-text v-if="loadState == 'loading'">
      <div class="text-center py-3">
        <v-progress-circular
          :size="40"
          :width="8"
          color="green"
          indeterminate
        ></v-progress-circular>
        <div>
          <h3>Confirming your data...</h3>
        </div>
      </div>
    </v-card-text>
    <v-card-text v-if="loadState == 'confirmed'">
      <div class="text-center py-3">
        <h3>Your data has been confirmed!</h3>
      </div>
    </v-card-text>
    <ArvaModalSaveFooter
      v-if="!loadState"
      confirmText="Confirm"
      cancelText="Cancel"
      @on-cancel="handleClose"
      @on-confirm="handleConfirm"
    />
  </ArvaModal>
</template>
<script>
import ArvaModal from "./ArvaModal"
import ArvaModalSaveFooter from "@/components/modals/ArvaModalSaveFooter"
import { CROP_DISPLAY_NAME } from "@/constants"

export default {
  name: "WizardConfirmModal",
  props: [
    "confirmationField",
    "confirmationData",
    "wantedKeys",
    "year",
    "loadState",
  ],
  components: {
    ArvaModal,
    ArvaModalSaveFooter,
  },
  data() {
    return {
      cropDisplayName: CROP_DISPLAY_NAME,
    }
  },
  methods: {
    handleClose() {
      this.$emit("close-template-modal")
    },
    handleConfirm() {
      this.$emit("confirm-data")
    },
  },
}
</script>
<style scoped>
.modal-body {
  max-height: 440px;
}
</style>
