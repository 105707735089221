<template>
  <div v-if="items.length > 0" class="leaflet-topright">
    <div
      v-if="!sidePanelOpen"
      class="layer-controller-toggle"
      @mouseover="sidePanelOpen = true"
    >
      <a @click.prevent="sidePanelOpen = true" href="javascript:void(0);">
        <div class="hamburger">
          <div />
          <div />
          <div />
        </div>
      </a>
    </div>
    <div
      v-else
      @mouseleave="debounceMouseLeave"
      @mouseover="mouseOverSidePanel = true"
      @mouseout="mouseOverSidePanel = false"
      class="layer-controller"
    >
      <div class="px-5 py-3">
        <div class="crop-flex-container vlistgroup">
          <div class="full-width vlistgroup">
            <h5 class="dropdown_desc" v-if="selectionDesc != null">
              <b>{{ selectionDesc.toUpperCase() }}</b>
            </h5>

            <v-list class="vlistgroup">
              <v-list-group
                v-for="item in items"
                :key="item.title"
                v-model="item.active"
                :prepend-icon="item.action"
                no-action
                class="vlistgroup"
              >
                <template v-slot:activator>
                  <v-list-item class="vlistgroup">
                    <v-list-item-content class="vlistgroup">
                      <v-list-item-title class="vlistgroup">{{
                        item.title
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>

                <v-list-item
                  v-for="subItem in item.items"
                  :key="subItem.title"
                  @click="getGeojson(subItem)"
                  class="vlistgroup"
                >
                  <v-list-item-content class="vlistgroup">
                    <v-list-item-subtitle class="vlistgroup">{{
                      subItem.title
                    }}</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action class="vlistgroup">
                    <v-icon class="vlistgroup">{{ subItem.action }}</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </v-list-group>
            </v-list>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MapAPI from "@/api/MapAPI"
import _ from "lodash"

export default {
  name: "LeafletLayerControl",
  props: ["fields", "mapParams"],

  data() {
    return {
      items: [],
      selectionDesc: null,
      mouseOverSidePanel: false,
    }
  },

  computed: {
    sidePanelOpen: {
      get() {
        return this.$store.state.Theme.sidePanelOpen
      },
      set(val) {
        this.$store.commit("Theme/setSidePanelOpen", val)
      },
    },
  },

  methods: {
    async getLayerList() {
      const payload = this.mapParams
      let field_ids = this.fields.map(x => {
        return x.properties.field.id
      })

      payload["field_ids"] = field_ids
      await MapAPI.fetchEligibleLeafletLayers(payload).then(response => {
        this.items = response.data.items

        if (payload.category == "synthetic_yield") {
          this.$emit(
            "update-synthetic-year-list",
            this.items[0].items.map(x => {
              return x.year
            })
          )
        }
      })
    },

    async getGeojson(d) {
      const payload = d
      let field_ids = this.fields.map(x => {
        return x.properties.field.id
      })
      payload["field_ids"] = field_ids
      payload["datatype"] = this.mapParams.category

      await MapAPI.fetchLeafletLayersGeoJSON(payload).then(response => {
        this.$emit("update-zone", response.data)
        this.$emit("update-map-layer-payload", payload)
        this.selectionDesc = payload.category + ": " + payload.title
        //also return latest payload so can recall it from mapview when field
      })
    },

    //methods here
    //based on a prop that describe the page type, we do api call to format a list of items
    //based on user selection from that list, we emit a returend geojson back to the map
    debounceMouseLeave: _.debounce(function() {
      this.sidePanelOpen = this.mouseOverSidePanel
    }, 100),
  },
  async mounted() {
    this.getLayerList()
  },
}
</script>

<style scoped>
.fa-align-right {
  color: #888 !important;
}

.hamburger {
  margin-top: 10px;
}

.hamburger > div {
  margin: 5px 8px;
  width: 34px;
  height: 4px;
  background: lightgray; /*#999*/
  border-radius: 20px;
}

.layer-controller-toggle {
  top: 0;
  height: 100%;
  width: 100px;
  background: rgba(24, 24, 24, 0.9);
  color: rgb(200, 186, 175);
  position: absolute;
  right: -20px;
}

.full-width {
  width: 100%;
}

.leaflet-topright {
  border-radius: 20px;
  position: absolute;
  width: 300px;
  top: 5%;
  right: -5%;
  z-index: 1000;
  background: rgba(24, 24, 24, 0.8);
}

.dropdown_desc {
  padding: 0px;
  color: rgb(200, 186, 175, 1) !important;
}

.vlistgroup {
  background: rgba(24, 24, 24, 0);
  color: rgb(200, 186, 175, 1) !important;
}
</style>
