<template>
  <div v-if="responseData" class="d-flex label-padding pdf-wrapper">
    <div>
      <div v-for="response in responseData.slice(0, 5)" :key="String(response.id) + String(response.sample_tests)">
        <QRCodeLabel :data="response" />
      </div>
    </div>
    <div>
      <div v-for="response in responseData.slice(5, 10)" :key="response.id">
        <QRCodeLabel :data="response" />
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash"
import SamplingAPI from "@/api/SamplingAPI"
import QRCodeLabel from "../components/sampling/QRCodeLabel.vue"

export default {
  components: {
    QRCodeLabel,
  },
  name: "PDFSoilLabelView",
  data() {
    return {
      pageToView: null,
      responseData: null,
      responseLength: null,
    }
  },

  async mounted() {
    const { name } = this.$route
    const { reportHash } = this.$route.params
    const sampleIds = this.$route.query.samples

    // const reportHash = 3
    // const sampleIds = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

    this.pageToView = name

    if (this.pageToView === "soil-label-generator") {
      this.envNum = this.$route.params.pageNum
    }
    let sampleSplit = sampleIds.split(",")
    let payload = {
      report_hash: reportHash,
      sample_ids: sampleSplit,
    }

    await SamplingAPI.fetchQRCodeLabelData(payload)
      .then(response => {
        if (Array.isArray(response.data)) {
          this.responseLength = response.data.length
        }
        this.responseData = _.orderBy(response.data, ["id"], ["asc"])
      })
      .catch(err => {
        console.log(err)
      })
  },

  beforeCreate() {
    document.body.className = "pdf-body"
  },
}
</script>

<style>
@page {
  size: 8.5in 11in !important;
  margin: 0 !important;
}
</style>

<style scoped>
body {
  background-color: white !important;
}

.label-padding {
  padding-top: 60px;
}

.pdf-wrapper {
  width: 1000px;
  height: 1283px;
  position: relative;
}
</style>
