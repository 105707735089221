<template>
  <div>
    <Permissions package="inset-enrollment" :superUserOnly="false" />

    <!--
    <ManageGroupSidebar
      :visible="showMangementSidebar"
      :entity="entityData"
      :allFieldSupplySummaries="allFieldSupplySummaries"
      :allClientProfiles="allClientProfiles"
      @closeSidebar="handleManagementSidebarClose"
      @openLOIModal="handleLOIModalOpen"
      @renderGroupData="handleUpdateGroupData"
    />

    <LetterOfIntentModal
      v-if="openLOIModal"
      :data="dataForLOIModal"
      @close-modal="handleLOIModalClose"
      @generate-loi="generateLOI"
    />
    -->
    <div class="pb-4 position-relative contract-group-main-content">
      <div
        v-if="showBottomActionBar"
        class="position-absolute pl-8 py-4 d-flex align-center justify-space-between bottom-action-bar"
      >
        <div class="d-flex">
          <p :class="`mb-0 pr-4 mr-4 ${pending['acreage'] < 0 ? 'pending-acreage-decrease' : 'pending-acreage-increase'}`">
            {{ pending['acreage'] < 0 ? '' : '+' }} {{ numberToLocale(Math.floor(pending['acreage'] * 100) / 100) }}
          </p>

          <p class="mb-0">{{ pending['numFields'] }} Fields</p>
        </div>

        <div>
          <v-btn
            height="44"
            class="mr-4"
            outlined
            v-if="groupData['snapshot'] != null"
            :disabled="cancelDisabled"
            @click="cancelSupplyChanges()"
          >
            Cancel
          </v-btn>

          <v-btn
            height="44"
            outlined
            :disabled="submissionsDisabled"
            @click="submitSupplyChanges({ groupId })"
          >
            Send field selections for review
          </v-btn>
        </div>
      </div>

      <div class="d-flex justify-space-between align-center mb-4">
        <div class="d-flex align-center">
          <router-link to="/enrollment-groups">
            <v-icon class="back-icon">mdi-chevron-left</v-icon>
          </router-link>
          <h1 class="mb-0 font-weight-bold">
            {{ enrollmentTableLoading['loading'] ? 'Loading group data...' : groupData['name'] }}
          </h1>
        </div>

        <div>
          <v-btn disabled class="mr-3" :ripple="false" outlined height="44">
            <v-icon class="custom-icon">mdi-play-outline</v-icon>
            5 Min Training
          </v-btn>

          <v-btn disabled v-if="isSuperuser" @click="setSpoofingCP(!spoofingCP)" class="mr-3" :ripple="false" outlined height="44">
            <v-icon v-if="spoofingCP">mdi-close</v-icon>
            <v-icon v-else>mdi-account-multiple</v-icon>
            <span class="ml-1">{{ spoofingCP ? 'Close CP View' : 'Open CP View' }}</span>
          </v-btn>
  
          <v-btn disabled v-b-toggle.sidebar-right :ripple="false" outlined height="44">
            <v-icon class="mr-2">mdi-file-document-outline</v-icon>
            Manage Group & Contracts
          </v-btn>
        </div>
      </div>
  
      <RollupTable
        :selectedRegions="selectedRegions"
        :selectedCrops="selectedCrops"
        @updateSelected="handleUpdateSelected"
        @updateRollupTableHeight="updateRollupTableHeight"
      />

      <v-card outlined class="px-6 py-4">
        <v-progress-circular v-if="enrollmentTableLoading['loading']" indeterminate :size="48" color="#79c61c" />

        <div v-if="enrollmentTableLoading['error'] != null">
          <p>Error: {{ enrollmentTableLoading['error'] }}</p>
        </div>

        <v-data-table
          v-else
          show-select
          hide-default-footer
          disable-pagination
          :class="`enrollment-table ${enrollmentTableLoading['loading'] ? 'loading' : ''}`"
          :style="`--table-height-modifier: ${allOtherHeights}px`"
          :headers="HEADERS"
          :items="items"
          :search="search"
          :custom-filter="handleDataTableFiltering"
          :modelValue="selectedItems"
          @input="(items) => selectedItems = items.map(({ id }) => id)"
        >
          <template v-slot:top="props">
            <div v-bind="props" class="d-flex justify-space-between align-center mb-4">
              <div class="d-flex align-center flex-wrap">
                <v-card-title class="pa-0 ma-0 w-100 order-1">
                  {{ props.items.length }} Field{{ props.items.length == 1 ? '' : 's' }}
                </v-card-title>
                <v-card-subtitle class="pa-0 ma-0 mb-2 w-100 order-0">Enrollment Progress</v-card-subtitle>
              </div>
              <div class="d-flex justify-end">
                <v-select
                  :class="`mr-2 ${selectedRegions.length == 0 ? 'all-selected' : ''}`"
                  solo
                  multiple
                  clearable
                  hide-details
                  :items="allUniqueRegions"
                  v-model="selectedRegions"
                  placeholder="All Regions"
                >
                  <template v-slot:selection="{ item, index }">
                    <div v-if="index == 0">
                      <p class="ma-0 text-subtitle-2 font-weight-regular">
                        Regions <span>{{ selectedRegions.length }}</span>
                      </p>
                    </div>
                  </template>

                  <template v-slot:item="{ on, attrs, item }">
                    <v-list-item v-on="on" v-bind="attrs">
                      <template v-slot:default="{ active }">
                        <v-list-item-action>
                          <v-checkbox :input-value="active" />
                        </v-list-item-action>

                        <v-list-item-content>
                          <v-list-item-title>
                            {{ regionAbbrevToFull(item) }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                  </template>
                </v-select>

                <v-select
                  :class="`mr-2 ${selectedCrops.length == 0 ? 'all-selected' : ''}`"
                  solo
                  multiple
                  clearable
                  hide-details
                  :items="allUniqueCrops"
                  v-model="selectedCrops"
                  placeholder="All Crops"
                >
                  <template v-slot:selection="{ item, index }">
                    <div v-if="index == 0">
                      <p class="ma-0 text-subtitle-2 font-weight-regular">
                        Crops <span>{{ selectedCrops.length }}</span>
                      </p>
                    </div>
                  </template>

                  <template v-slot:item="{ on, attrs, item }">
                    <v-list-item v-on="on" v-bind="attrs">
                      <template v-slot:default="{ active }">
                        <v-list-item-action>
                          <v-checkbox :input-value="active"></v-checkbox>
                        </v-list-item-action>

                        <v-list-item-content>
                          <v-list-item-title>
                            {{ CROP_DISPLAY_NAME[item] }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                  </template>
                </v-select>

                <v-select
                  :class="`mr-2 ${selectedFarms.length == 0 ? 'all-selected' : ''}`"
                  solo
                  multiple
                  clearable
                  hide-details
                  :items="allUniqueFarms"
                  v-model="selectedFarms"
                  placeholder="All Farms"
                >
                  <template v-slot:selection="{ item, index }">
                    <div v-if="index == 0">
                      <p class="ma-0 text-subtitle-2 font-weight-regular">
                        Farms <span>{{ selectedFarms.length }}</span>
                      </p>
                    </div>
                  </template>
                </v-select>

                <v-select
                  :class="`mr-2 ${selectedStatuses.length == 0 ? 'all-selected' : ''}`"
                  solo
                  multiple
                  clearable
                  hide-details
                  :items="allStatuses"
                  v-model="selectedStatuses"
                  placeholder="All Statuses"
                >
                  <template v-slot:selection="{ item, index }">
                    <div v-if="index == 0">
                      <p class="ma-0 text-subtitle-2 font-weight-regular">
                        Statuses <span>{{ selectedStatuses.length }}</span>
                      </p>
                    </div>
                  </template>

                  <template v-slot:item="{ on, attrs, item }">
                    <v-list-item v-on="on" v-bind="attrs">
                      <template v-slot:default="{ active }">
                        <v-list-item-action>
                          <v-checkbox :input-value="active"></v-checkbox>
                        </v-list-item-action>

                        <v-list-item-content>
                          <v-list-item-title>
                            {{ STATUS_MAP[item]['text'] }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                  </template>
                </v-select>

                <v-text-field
                  :ripple="false"
                  class="mr-3 search-input"
                  outlined
                  v-model="search"
                  hide-details
                  placeholder="Search"
                >
                  <template v-slot:prepend-inner>
                    <v-icon>mdi-magnify</v-icon>
                  </template>
                  <span class="ml-1">Search</span>
                </v-text-field>
              </div>
            </div>
          </template>

          <template v-slot:header.requestEnrollment="props">
            <span>{{ props.header['text'] }}</span>
            <v-tooltip content-class="tooltip-no-transition" top max-width="300">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
              </template>
              <span>This will request this field is added to your enrollment for this region and crop.</span>
            </v-tooltip>
          </template>

          <template v-slot:header.practicesDetail="props">
            <span>{{ props.header['text'] }}</span>
            <v-tooltip content-class="tooltip-no-transition" top max-width="300">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
              </template>
              <span>These are the sustainable practices we have data for in our system for this field and crop. If you see something missing, it can be updated in the management practices or management practices worksheet found under the List View of Fields.</span>
            </v-tooltip>
          </template>

          <template v-slot:item.field="props">
            <div class="d-flex align-center py-3">
              <img class="thumbnail" :src="props.value['thumbnail']" @error="(e) => e.target.src = fallback">
              <p class="mb-0 ml-4 field-name">{{ props.value['name'] }}</p>
            </div>
          </template>

          <template v-slot:item.region="props">
            <div v-bind="props">
              <p class="mb-0 capitalize">{{ props.value['value'] }}</p>
            </div>
          </template>

          <template v-slot:item.crops="props">
            <div v-bind="props">
              <p class="mb-0 capitalize">{{ props.value['value'] }}</p>
            </div>
          </template>
          
          <template v-slot:item.status="{ value }">
            <v-chip :color="STATUS_MAP[value['value']]['color']">
              {{ STATUS_MAP[value['value']]['text'] }}
            </v-chip>
          </template>

          <template v-slot:item.requestEnrollment="{ item }">
            <div
              :class="`d-flex custom-toggler`"
            >
              <!-- <v-progress-circular v-if="fssLoading[item['id']]['loading']" indeterminate :size="24" color="#79c61c" /> -->

              <a
                @click="handleSelectSupplyState(item['id'], YES)"
                :class="`px-4 py-3 d-flex align-center justify-center w-100 ${getTogglerClass(item['id'], YES)}`"
              >
                Yes
              </a>
              <a
                @click="handleSelectSupplyState(item['id'], NO)"
                :class="`px-4 py-3 d-flex align-center justify-center w-100 ${getTogglerClass(item['id'], NO)}`"
              >
                No
              </a>
            </div>
          </template>

          <template v-slot:item.practicesDetail="props">
            <p class="ma-0 text-capitalize">{{ props.value.join(', ') }}</p>
          </template>
        </v-data-table>
      </v-card>
    </div>
  </div>
</template>

<script>
import fallback from "@/assets/images/fallback.png"
import Permissions from "@/components/permissions/Permissions"
import RollupTable from "@/components/contractGroups/RollupTable.vue"
import ManageGroupSidebar from "@/components/contractGroups/ManageGroupSidebar.vue"
import LetterOfIntentModal from "@/components/modals/LetterOfIntentModal.vue"
import FieldsAPI from "@/api/FieldsAPI"
import { numberToLocale, regionAbbrevToFull, getMinutesCopySinceDate } from "@/utility"
import { EnrollmentGroups } from "@/store/modules"
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import { CROP_DISPLAY_NAME, US_STATES } from "@/constants"
import { EVIDENCING_TYPE_CHOICES } from "@/constants/defaults"
import {
  NO,
  YES,
  OPEN,
  LOCKED,
  AVAILABLE,
  REQUESTED,
  STATUS_MAP,
  ENROLLMENT_TABLE,
  REQUESTED_PENDING,
} from "@/constants/contractGroups"

// temp constants
const HEADERS = [
  { text: "Field", value: "field", filter: v => v['selectedFarms'].length == 0 || v['selectedFarms'].includes(v['farm']) },
  { text: "Region", value: "region", filter: v => v['selectedRegions'].length == 0 || v['selectedRegions'].includes(v['value']) },
  { text: "Crop", value: "crops", filter: v => v['selectedCrops'].length == 0 || v['selectedCrops'].includes(v['cropId']) },
  { text: "Acres", value: "acres" },
  { text: "Request Enrollment", value: "requestEnrollment", sortable: false },
  { text: "Status", value: "status", filter: v => v['selectedStatuses'].length == 0 || v['selectedStatuses'].includes(v['value']) },
  { text: "Practices", value: "practices" },
  { text: "Practices", value: "practicesDetail" },
]

export default {
  name: "ContractGroupDetail",
  components: {
    Permissions,
    RollupTable,
    ManageGroupSidebar,
    LetterOfIntentModal,
  },
  mounted() {
    if (this.groupData == null) {
      this.listOverview()
      .then(() => {
        this.fetchFssMetadata({ groupId: this.groupId })
      })
    }
    else {
      this.fetchFssMetadata({ groupId: this.groupId })
    }
  },
  destroyed() {
    clearInterval(this.minutesInterval)
  },
  data() {
    return {
      minutesInterval: null,
      minutesSince: '',
      search: '',
      selectedItems: [],
      selectedCrops: [],
      selectedFarms: [],
      selectedRegions: [],
      selectedStatuses: [],
      showMangementSidebar: false,
      rollupTableHeight: 58,
      openLOIModal: false,
      dataForLOIModal: null,
      dataForProfileModificationModal: null,
      allFieldSupplySummaries: [],
      allClientProfiles: [],
      HEADERS,
      OPEN,
      AVAILABLE,
      REQUESTED,
      REQUESTED_PENDING,
      LOCKED,
      fallback,
      STATUS_MAP,
      allStatuses: [AVAILABLE, REQUESTED, REQUESTED_PENDING, LOCKED],
      YES,
      NO,
      CROP_DISPLAY_NAME,
      numberToLocale
    }
  },
  computed: {
    ...mapState({
      isSuperuser: state => state.User.user != null && state.User.user['is_superuser'],
      org: state => state.Organization.organization,
      year: state => state.Organization.year,
      spoofingCP: state => state.EnrollmentGroups.spoofingCP,
      enrollmentTableLoading: state => state.EnrollmentGroups[ENROLLMENT_TABLE],
      groupData: function(state) {
        return state.EnrollmentGroups.groupData[this.groupId]
      }
    }),
    groupId() {
      return this.$router.currentRoute.params['id']
    },
    submissionsDisabled() {
      return this.groupData['supplyChanges'][YES].length == 0 && this.groupData['supplyChanges'][NO].length == 0
    },
    cancelDisabled() {
      if (this.submissionsDisabled) return true
      if (this.snapshottedChangedFSS.length == 0) return true
      return false
    },
    snapshottedChangedFSS() {
      // check if at least one fss is in the snapshot and in the changes obj
      // cancel should not apply to fss that is a 'change' but not in the snapshot
      const { fieldsupply, supplyChanges, snapshot } = this.groupData
      return fieldsupply.filter(([_, id]) => {
        const isChange = supplyChanges[YES].includes(id) || supplyChanges[NO].includes(id)
        const inSnapshot = snapshot['yes_fieldsupply'].includes(id) || snapshot['no_fieldsupply'].includes(id)
        return isChange && inSnapshot
      })
    },
    showBottomActionBar() {
      return this.groupData != null && !this.submissionsDisabled
    },
    pending() {
      const result = { acreage: 0, numFields: 0 }

      if (this.groupData == null) return result

      const { acreageValues } = this.groupData
      
      for (const cropId in acreageValues[REQUESTED_PENDING]) {
        for (const region in acreageValues[REQUESTED_PENDING][cropId]) {
          result['acreage'] += acreageValues[REQUESTED_PENDING][cropId][region]['acreage']
          result['numFields'] += acreageValues[REQUESTED_PENDING][cropId][region]['numFields']
        }
      }

      return result
    },
    allOtherHeights() {
      // navbar + page container margin top + h1 + h1 margin + table + table result + table margin
      const external = 60 + 20 + 48 + 16 + this.rollupTableHeight + 65 + 24

      // table borders, table y padding + "top" slot + "top" slot margin
      const internal = 2 + 32 + 60 + 12

      const barOffset = this.showBottomActionBar ? 76 : 0

      // 24px of artificial padding at bottom
      const extra = 24

      return external + internal + barOffset + extra
    },
    allUniqueRegions() {
      if (this.groupData == null) return []

      const { fieldsupply } = this.groupData
      const regs = []

      for (const [_, __, ___, stateName] of fieldsupply) {
        const found = US_STATES.find(({ text }) => text == stateName)
        if (found == null && !regs.includes('Canada')) regs.push('Canada')
        if (found != null && !regs.includes(found['value'])) regs.push(found['value'])
      }

      regs.sort()

      return regs
    },
    allUniqueFarms() {
      if (this.groupData == null) return []

      const { fieldsupply } = this.groupData
      const allFarms = []

      for (const fss of fieldsupply) {
        const metadata = fss.slice(-1)[0]
        if (!allFarms.includes(metadata['farmName']))
          allFarms.push(metadata['farmName'])
      }

      return allFarms.sort()
    },
    allUniqueCrops() {
      if (this.groupData == null) return []

      const { fieldsupply } = this.groupData
      const allCrops = []

      for (const [_, __, ___, ____, cropId] of fieldsupply) {
        if (!allCrops.includes(cropId))
          allCrops.push(cropId)
      }

      return allCrops.sort()
    },
    entityData() {
      if (this.groupData == null || this.org == null) return null

      return {
        id: this.groupData["id"],
        org: this.org["id"],
        year: this.year,
        name: this.groupData["name"],
        notes: this.groupData["notes"],
        cpProfiles: this.groupData["cp_profiles"],
        growerProfiles: this.groupData["grower_profiles"],
        generatedContracts: this.groupData["generatedContracts"],
      }
    },
    items() {
      if (this.groupData == null) return []

      const { fieldsupply } = this.groupData

      return fieldsupply.map(([_, fssId, status, stateName, cropId, fieldName, acreage, metadata]) => {
        const foundRegion = US_STATES.find(({ text }) => text == stateName)

        return {
          id: fssId,
          field: {
            name: fieldName,
            thumbnail: metadata['thumbnail'],
            farm: metadata['farmName'],
            selectedFarms: this.selectedFarms
          },
          acres: acreage,
          crops: {
            cropId: cropId,
            value: CROP_DISPLAY_NAME[cropId],
            selectedCrops: this.selectedCrops
          },
          region: {
            value: foundRegion != null ? foundRegion['value'] : stateName,
            selectedRegions: this.selectedRegions
          },
          status: {
            value: status,
            selectedStatuses: this.selectedStatuses
          },
          practices: metadata['practices'].length,
          practicesDetail: metadata['practices']
            .map(p => p.includes(': ') ? p.split(': ')[1] : p)
            .map(p => EVIDENCING_TYPE_CHOICES.find(({ value }) => p == value || null))
            .filter(p => p)
            .map(p => p['name']),
          submitDate: null,
          deadline: null
        }
      })
    }
  },
  methods: {
    getMinutesCopySinceDate,
    regionAbbrevToFull,
    ...mapActions({
      listOverview: EnrollmentGroups.Actions.listOverview,
      updatePendingFssState: EnrollmentGroups.Actions.updatePendingFssState,
      submitSupplyChanges: EnrollmentGroups.Actions.submitSupplyChanges,
      fetchFssMetadata: EnrollmentGroups.Actions.fetchFssMetadata,
    }),
    ...mapMutations({
      setSpoofingCP: EnrollmentGroups.Mutations.setSpoofingCP
    }),
    cancelSupplyChanges() {
      const supplyChanges = this.snapshottedChangedFSS.reduce((accum, [supplyState, fssId]) => {
        if (!accum.hasOwnProperty(YES)) accum[YES] = []
        if (!accum.hasOwnProperty(NO)) accum[NO] = []

        const otherState = supplyState == YES ? NO : YES
        accum[otherState].push(fssId)

        return accum
      }, {})

      for (const supplyState in supplyChanges) {
        this.updatePendingFssState({ fssIds: supplyChanges[supplyState], groupId: this.groupId, supplyState})
      }
    },
    handleSelectSupplyState(fssId, supplyState) {
      const fssIds = [fssId]

      if (this.selectedItems.length > 0) 
        fssIds.push(...this.selectedItems.filter(id => id != fssId))

      this.updatePendingFssState({ fssIds, supplyState, groupId: this.groupId })
    },
    getTogglerClass(fssId, supplyState) {
      const otherState = supplyState == YES ? NO : YES

      // check if is a change
      // if not a change, it's confirmed
      if (this.groupData['supplyChanges'][supplyState].includes(fssId)) return 'selected-pending'
      if (this.groupData['supplyChanges'][otherState].includes(fssId)) return 'not-selected'
      if (this.groupData['fieldsupply'].find(([state, id]) => id == fssId && state == otherState)) return 'not-selected'

      return `selected-confirmed-${supplyState}`
    },
    handleUpdateSelected({ crops, region }) {
      this.selectedCrops = crops
      this.selectedRegions = region
    },
    updateRollupTableHeight(height) {
      this.rollupTableHeight = height
    },
    handleDataTableFiltering(value, search, item) {
      if (search == null || search.length == 0) return true

      const fieldName = item['field']['name'].toLowerCase()
      if (fieldName.includes(search)) return true
      return false
    },
    handleLOIModalOpen(data) {
      this.dataForLOIModal = {
        ...data,
        org: this.org,
      }
      this.openLOIModal = true
    },
    handleLOIModalClose() {
      this.dataForLOIModal = null
      this.openLOIModal = false
    },
    handleManagementSidebarClose(cancelled) {
      if (!cancelled) {
      }

      this.showMangementSidebar = false
    },
    async generateLOI(payload) {
      //
      // TEMP
      //
      return
      const appendedPayload = {
        ...payload,
        fss_ids: this.groupData["fieldsupply"].map(({ id }) => id),
        year: this.groupData["year"],
      }

      this.openLOIModal = false
      this.showLoading = true
      FieldsAPI.generateLOI(appendedPayload).then(r => {
        alert("LOI Created!")
        // this.fetchClientProfile()
        // this.showLoading = false
        // this.message = `Letter of Intent for ${this.selectedClient.client.name} (${this.selectedClient.client.id}) has been generated successfully.`
        // this.showSuccessAlert = true
        // setTimeout(() => {
        //   this.showSuccessAlert = false
        // }, 10000)
      })
    }
  },
  watch: {
    org() {
      this.listOverview(this.groupId)
      .then(() => {
        this.fetchFssMetadata({ groupId: this.groupId })
      })
    },
    year(curr, prev) {
      if (curr != null && prev != null) {
        this.listOverview(this.groupId)
        .then(() => {
          this.fetchFssMetadata({ groupId: this.groupId })
        })
      }
    }
  }
}
</script>

<style>
.tooltip-no-transition {
  transition: none !important;
}
</style>

<style scoped>
:deep(th i) {
  margin-left: 4px;
  font-size: 16px !important;
}
.contract-group-main-content {
  min-height: calc(100vh - 80px);
}
.loading {
  opacity: 0.5;
  pointer-events: none;
}
.v-progress-circular {
  position: absolute;
  left: calc(50% - 32px);
  top: calc(50% - 32px);
  z-index: 1;
}
.capitalize {
  text-transform: capitalize;
}
p {
  color: #000000;
}
h1 {
  color: #000;
  font-size: 32px;
  line-height: 48px;
  font-weight: 700;
}
.v-data-table :deep(tr) {
  cursor: pointer;
}

.enrollment-table > :deep(.v-data-table__wrapper) {
  transition: height 0.25s ease-in-out;
  height: calc(100vh - var(--table-height-modifier, 0));
  overflow-y: scroll;
}
:deep(.v-data-table-header) {
  background: #ffffff;
  position: sticky;
  top: 0;
  z-index: 1;
}
.thumbnail {
  width: 36px;
  height: 36px;
  object-fit: fill;
}
.v-btn {
  text-transform: none;
  letter-spacing: normal;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04),
    0px 1px 2px 0px rgba(16, 24, 40, 0.04);
}
.v-card__title {
  color: #20292f;
  font-size: 24px;
}
.v-card__subtitle {
  color: #6b7280;
  line-height: 20px;
}
:deep(.v-simple-checkbox) {
  width: 24px;
  margin: 0 auto !important;
}
:deep(.v-simple-checkbox .v-input--selection-controls__input) {
  margin-right: 0 !important;
}
:deep(.v-simple-checkbox i) {
  margin-left: 0 !important;
}
:deep(.v-simple-checkbox i::before) {
  font-size: 24px;
}
:deep(.v-simple-checkbox .v-input--selection-controls__ripple) {
  display: none;
}
.v-data-table:not(.rollup-table) :deep(tr > th:nth-of-type(1)),
.v-data-table:not(.rollup-table) :deep(tr > td:nth-of-type(1)),
.v-data-table:not(.rollup-table) :deep(tr > td:nth-of-type(2)) {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.v-chip.v-size--default {
  height: 20px !important;
}
.search-input :deep(.v-input__slot) {
  min-height: unset !important;
  height: 40px;
}
.search-input :deep(.v-input__prepend-inner) {
  margin: 2px 0 0;
  align-self: center;
}
.practices-list {
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: capitalize;
}
.field-name {
  width: 132px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: none;
}
.enrollment-table :deep(table) {
  table-layout: fixed;
}
.enrollment-table :deep(th:nth-of-type(1)) {
  width: 64px !important;
}
.enrollment-table :deep(th:nth-of-type(2)) {
  width: 192px;
}
.enrollment-table :deep(th:nth-of-type(3)) {
  width: 91px;
}
.enrollment-table :deep(th:nth-of-type(4)) {
  width: 118px;
}
.enrollment-table :deep(th:nth-of-type(5)) {
  width: 90px;
}
.enrollment-table :deep(th:nth-of-type(6)) {
  width: 205px;
}
.enrollment-table :deep(th:nth-of-type(7)) {
  width: 138px;
}
.enrollment-table :deep(th:nth-of-type(8)) {
  width: 105px;
}
.enrollment-table :deep(th:nth-of-type(9)) {
  width: 500px;
}
.enrollment-table :deep(tr td:nth-of-type(9)) {
  padding: 0 4px;
}
.enrollment-table :deep(tr td:nth-of-type(8) > p) {
  color: #4B5563;
  font-size: 14px;
  line-height: 16px;
}
.v-chip :deep(span) {
  font-size: 12px;
}
.fss-loading {
  opacity: 0.5;
}
.rollup-loader {
  position: absolute;
  left: calc(50% - 24px);
  top: calc(50% - 24px);
  z-index: 1;
}

.custom-toggler {
  position: relative;
  border-radius: 8px;
  border: 1px solid #E5E7EB;
  background: #FFF;
  width: 148px;
  overflow: hidden;
}
.custom-toggler.disabled a {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}
.custom-toggler a {
  background-color: #FFFFFF;
  color: #374151;
  transition: background-color 0.25s color 0.25s;
}
.custom-toggler a:first-of-type {
  border-right: 1px solid #E5E7EB;
}
.custom-toggler a:hover {
  opacity: 0.75;
}
.custom-toggler a[class*=selected-] {
  color: #FFFFFF;
}
.custom-toggler a.selected-confirmed-yes {
  background-color: #61AF02;
}
.custom-toggler a.selected-confirmed-no {
  background-color: #64748B;
}
.custom-toggler a.selected-pending {
  background-color: #0E7490;
}
.custom-toggler .v-progress-circular {
  position: absolute;
  left: calc(50% - 12px);
  top: calc(50% - 12px);
  z-index: 0;
}

/* v-select overrides */
.v-input.v-select {
  width: 144px;
  height: 40px;
  max-width: unset;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04), 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
  border-radius: 8px;
}
.v-input.v-select.v-input--is-label-active:not(.all-selected) :deep(div[role="button"]) {
  background: #6B7280;
}
.v-input.v-select.v-input--is-label-active:not(.all-selected) :deep(.v-input__append-inner:nth-last-of-type(2) > div) {
  opacity: 1;
}
.v-input.v-select.v-input--is-label-active:not(.all-selected) :deep(.v-input__append-inner:last-of-type) {
  display: none;
}
.v-input.v-select.v-input--is-label-active:not(.all-selected) :deep(div[role="button"] p),
.v-input.v-select.v-input--is-label-active:not(.all-selected) :deep(div[role="button"] i),
.v-input.v-select.v-input--is-label-active:not(.all-selected) :deep(div[role="button"] button.mdi-close) {
  color: white;
}
.v-input.v-select.v-input--is-label-active:not(.all-selected) :deep(div[role="button"] p span) {
  padding: 1px 2px;
  color: black;
  background: white;
  border-radius: 2px;
}
.v-input.v-select :deep(div[role="button"]) {
  min-height: 40px;
}
.v-input.v-select :deep(.v-select__slot label) {
  top: 7px;
}
.v-input.v-select :deep(.v-input__append-inner) {
  margin-top: 0px !important;
}
.v-chip.gray {
  background: #F3F4F6 !important;
}
.v-chip.gray :deep(span) {
  color: #374151;
}
.v-chip.green {
  background: #F1FDDF !important;
}
.v-chip.green :deep(span) {
  color: #61B100;
}
.v-chip.red {
  background: #FEF2F2 !important;
}
.v-chip.red :deep(span) {
  color: #EF4444;
  font-weight: bold;
}
.custom-icon {
  font-size: 28px;
}
.back-icon {
  font-size: 32px;
}
.all-selected :deep(::placeholder) {
  color: #374151 !important;
  opacity: 1;
}
.bottom-action-bar {
  z-index: 1;
  left: -30px;
  bottom: -2px;
  background: #3F3F46;
  width: calc(100% + 60px);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding-right: 84px;
}
.bottom-action-bar button:first-of-type {
  color: #61B100 !important;
  border-color: #61B100;
  background-color: #3F3F46;
}
.bottom-action-bar button:last-of-type {
  color: #FFFFFF !important;
  border-color: #61B100;
  background-color: #61B100;
}
.bottom-action-bar p {
  color: #E5E7EB;
  font-size: 24px;
  line-height: 32px;
}
.bottom-action-bar .pending-acreage-increase {
  color: #C4E197;
}
.bottom-action-bar .pending-acreage-decrease {
  color: #FECACA;
}
.bottom-action-bar p:first-of-type {
  border-right: 2px solid rgba(255, 255, 255, 0.05);
}
::v-deep(.v-input__control input) {
  cursor: pointer;
}
.v-btn.no-click-events {
  pointer-events: none;
}
.v-btn p {
  margin: 0;
  line-height: 1.125;
  text-align: left;
  font-weight: normal;
}
.progress-min-width {
  width: 80px;
}
</style>
