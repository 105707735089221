<template>
  <a
    @click="handleClick(item)"
    :class="cssClass"
    :style="locationItemStyle"
    href="javascript:void(0)"
    >{{ item.name }}</a
  >
</template>

<script>
export default {
  name: "DropDownItem",
  props: {
    item: {
      type: Object,
    },
    active: {
      type: Boolean,
    },
  },

  computed: {
    cssClass() {
      return `dropdown-item ${this.active ? "active" : ""}`
    },
    locationItemStyle() {
      // if item is location, indent location children
      if (!this.item.depth) return null

      return this.item.depth > 1
        ? { "padding-left": this.item.depth + 1 + ".75em" }
        : null
    },
  },
  methods: {
    handleClick(item) {
      let newItem = this.item

      newItem.active = !item.active
      this.$emit("handle-item-click", newItem)
    },
  },
}
</script>

<style scoped>
a {
  text-overflow: ellipsis;
  overflow-x: hidden;
}
</style>
