<template>
  <ArvaModal
    name="reviewFSSDetails"
    :title="title"
    :wide="true"
    :width="width"
    @close-modal="handleCloseModal"
  >
    <v-card class="pa-8">
      <table class="table table-lg table-hover">
        <thead>
          <tr>
            <th v-if="!isReadOnly">
              <input
                @click="selectAllCheckBox"
                type="checkbox"
                name="selected"
                ref="fieldSelectAllCheckbox"
              />
            </th>
            <th class="hover header-width"></th>
            <th />
            <th class="hover header-width">Field</th>
            <th class="hover header-width"></th>
            <th class="hover header-width">Acreage</th>
            <th class="hover header-width">Farm</th>
            <th class="hover header-width">Client</th>
            <th class="mp-header-width">Management Practices</th>
            <th class="data-completion-header-width"></th>

            <th class="data-completion-header-width">
              <div class="d-flex justify-content-between">
                <div>Data Completion</div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <FieldListRow
            :key="field.id"
            :field="field"
            :organization="organization"
            completionIncludeDefaults="true"
            showSustainability="true"
          />
        </tbody>
      </table>
    </v-card>
  </ArvaModal>
</template>

<script>
import ArvaModal from "@/components/modals/ArvaModal"
import ArvaModalSaveFooter from "@/components/modals/ArvaModalSaveFooter"
import FieldListRow from "@/components/fields/FieldListRow"

import { mapGetters, mapState } from "vuex"
import { Filter } from "@/store/modules"

export default {
  name: "FieldDetailsCompletion",
  props: ["fieldID", "year"],
  components: { FieldListRow, ArvaModal, ArvaModalSaveFooter },

  data() {
    return {
      isReadOnly: true,
      width: 1300,
    }
  },
  computed: {
    ...mapGetters({
      selectedFields: Filter.Getters.getSelectedFields,
    }),
    ...mapState({
      organization: state => state.Organization.organization,
    }),
    field() {
      return this.selectedFields.filter(e => e.id == this.fieldID)[0]
    },
    title() {
      return "Field Completion Details for " + this.year
    },
  },
  methods: {
    handleCloseModal() {
      this.$emit("close-modal")
    },
  },
}
</script>

<style scoped>
h2 {
  font-size: 21px;
  font-weight: 500;
}

.toggle-checkbox {
  font-size: 12px;
}

.toggle-checkbox > span {
  margin-right: 10px;
  font-weight: 500;
}

.header-width {
  width: 8%;
}

.mp-header-width {
  width: 16%;
}

.data-completion-header-width {
  width: 32%;
}

.hover {
  cursor: pointer;
}
</style>
