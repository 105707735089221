<template>
  <div class="card-body">
    <b-alert v-model="showSuccessAlert" variant="success" dismissible>
      {{ message }}
    </b-alert>

    <b-alert v-model="showErrorAlert" variant="danger" dismissible>
      <div v-if="errorMessage.length > 0">
        <div
          class="error-message ml-5 p-1"
          v-for="currentError in errorMessage"
          :key="currentError"
        >
          <li>{{ currentError }}</li>
        </div>
      </div>

      <div v-else>
        {{ message }}
      </div>
    </b-alert>

    <div class="row mb-2 ml-2">
      <div class="col-4">
        <div class="row mb-2">
          <SearchBar :searchString="searchString" @searching="handleSearch" />
        </div>
        <div class="row">
          <div class="requested-ball" />
          <div class="table-label mr-4">Requested</div>
          <div class="approved-ball" />
          <div class="table-label mr-4">Approved</div>
          <div class="expired-ball" />
          <div class="table-label mr-4">Expired</div>
        </div>
      </div>

      <div class="col-8">
        <v-btn
          v-if="checkedCount > 0"
          class="ml-auto mr-3 float-right"
          color="red lighten-1"
          @click="confirmCloseTransactionModalOpen = true"
        >
          Close Transaction(s) ({{ checkedCount }})
        </v-btn>

        <v-btn
          class="ml-auto mr-3 float-right"
          :loading="loadingCreateNewTransaction"
          @click="openCreateNewTransactionModal"
        >
          Create Sharing Transaction
        </v-btn>
      </div>
    </div>

    <ConfirmModal
      v-if="confirmCloseTransactionModalOpen"
      titleText="Confirm Closing Transaction(s)"
      confirmText="Are you sure you want to close the selected transaction(s)? You will not be able to undo this action."
      @confirm="closeSharingTransaction"
      @close-modal="confirmCloseTransactionModalOpen = false"
    />

    <div class="row">
      <div class="col-md-12">
        <v-simple-table
          class="selector-table"
          fixed-header
          height="calc(100vh - 350px)"
        >
          <thead>
            <tr>
              <th>
                <v-checkbox
                  v-model="sharingTransactionAllCheckbox"
                  @click="selectAllCheckBox"
                  name="selected"
                  ref="sharingTransactionAllCheckbox"
                />
              </th>
              <th class="hover">
                Transaction ID
              </th>
              <th class="hover">
                Requested By
              </th>
              <th class="hover">
                From Org
              </th>
              <th class="hover">
                To Org
              </th>
              <th class="hover">
                Field To Share
              </th>
              <th class="hover">
                Status
              </th>
              <th />
            </tr>
          </thead>

          <tbody>
            <DataSharingTransactionListRow
              v-for="transaction in searchFilteredItems"
              :key="transaction.id"
              :transaction="transaction"
              :loadingFieldToShare="loadingFieldToShare"
              :isCustomerAdmin="isCustomerAdmin"
              @checked="checked"
              @unchecked="unchecked"
              @open-field-to-share-modal="openFieldToShareModal"
              @open-edit-transaction-modal="openEditTransactionModal"
              @approve-sharing-transaction="processApproveSharingTransaction"
              ref="sharingTransactionListRows"
            />
          </tbody>
        </v-simple-table>
      </div>

      <ConfirmModal
        v-if="confirmApproveModalOpen"
        titleText="Confirm Approving Transaction(s)"
        confirmText="Are you sure you want to approve this data sharing transaction?"
        @confirm="approveSharingTransaction"
        @close-modal="closeConfirmApproveModal"
      />

      <CreateNewTransactionModal
        v-if="createNewTransactionOpen"
        :userId="adminUser.id"
        :corporation="corporation"
        :organizationOptions="organizationOptions"
        :toCorpOptions="toCorpOptions"
        :toOrgOptions="toOrgOptions"
        :yearOptions="seasonOptions"
        @close-modal="closeCreateNewTransactionModal"
        @save-changes="processCreateNewTransaction"
      />

      <EditTransactionModal
        v-if="editTransactionModalOpen"
        :transaction="transactionToEdit"
        :corporation="corporation"
        :yearOptions="seasonOptions"
        @close-modal="closeEditTransactionModal"
        @save-changes="processEditTransaction"
      />

      <FieldToShareModal
        v-if="fieldToShareModalOpen"
        :transaction="transaction"
        :fieldToShare="fieldToShare"
        :isEditable="isEditable"
        @close-modal="closeFieldToShareModal"
        @save-changes="processSaveFieldToShare"
      />

      <DataShareAgreementModal
        v-if="dataShareAgreementModalOpen"
        :source="source"
        @agree="submitChanges"
        @close-modal="closeDataShareAgreementModal"
      />

      <AlertErrorModal
        v-if="alertErrorModalOpen"
        :titleText="modalTitleText"
        :errorMessage="errorMessageForModal"
        @close-modal="alertErrorModalOpen = false"
      />
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex"
import SearchBar from "@/components/misc/SearchBar"
import DataSharingTransactionListRow from "@/components/customerAdmin/dataSharing/DataSharingTransactionListRow"
import CreateNewTransactionModal from "@/components/modals/customerAdmin/dataSharing/CreateNewTransactionModal"
import EditTransactionModal from "@/components/modals/customerAdmin/dataSharing/EditTransactionModal"
import FieldToShareModal from "@/components/modals/customerAdmin/dataSharing/FieldToShareModal"
import DataShareAgreementModal from "@/components/modals/DataShareAgreementModal"
import ConfirmModal from "@/components/modals/ConfirmModal"
import { User } from "@/store/modules"
import UserAPI from "@/api/UserAPI"
import AlertErrorModal from "@/components/modals/AlertErrorModal"
// import _ from "lodash"

export default {
  name: "UserManagementView",
  components: {
    SearchBar,
    DataSharingTransactionListRow,
    CreateNewTransactionModal,
    EditTransactionModal,
    FieldToShareModal,
    DataShareAgreementModal,
    ConfirmModal,
    AlertErrorModal,
  },

  data() {
    return {
      loadingCreateNewTransaction: false,
      loadingFieldToShare: false,
      createNewTransactionOpen: false,
      editTransactionModalOpen: false,
      showSpinner: false,
      showSuccessAlert: false,
      showErrorAlert: false,
      message: null,
      errorMessage: [],
      searchString: "",
      sharingTransactionAllCheckbox: false,
      sharingTransactionToClose: [],
      confirmCloseTransactionModalOpen: false,
      dataShareAgreementModalOpen: false,
      userLiabilityAgreement: false,
      confirmApproveModalOpen: false,
      sortByRole: null,
      alertErrorModalOpen: false,
      errorMessageForModal: null,
      organizationOptions: [],
      toCorpOptions: [],
      toOrgOptions: [],
      fieldToShare: [],
      fieldToShareModalOpen: false,
      transaction: null,
      payload: null,
      APPROVE_REQUESTED_TRANSACTION: "APPROVE_REQUESTED_TRANSACTION",
      EDIT_APPROVED_TRANSACTION: "EDIT_APPROVED_TRANSACTION",
      CREATE_NEW_TRANSACTION_BY_ADMIN: "CREATE_NEW_TRANSACTION_BY_ADMIN",
      UPDATE_APPROVED_FIELD_TO_SHARE: "UPDATE_APPROVED_FIELD_TO_SHARE",
    }
  },

  computed: {
    ...mapState({
      orgSharingTransactions: state => state.User.orgSharingTransactions,
      years: state => state.Organization.organization.years,
      adminUser: state => state.User.user,
      corporation: state => state.Organization.organization.corporation,
      userRole: state => state.User.userRole,
    }),

    isCustomerAdmin() {
      if (
        this.userRole === "Corporation Admin" ||
        this.userRole === "Organization Admin"
      )
        return true
      return false
    },

    searchFilteredItems() {
      if (!this.searchString) return this.orgSharingTransactions
      const searchString = this.searchString.toLocaleLowerCase()
      return this.orgSharingTransactions.filter(function(transaction) {
        return (
          transaction.id.toString().includes(searchString) ||
          transaction.shared_org_name.toLowerCase().includes(searchString) ||
          transaction.received_org_name.toLowerCase().includes(searchString) ||
          transaction.sharing_status.toLowerCase().includes(searchString)
        )
      })
    },

    checkedCount() {
      return this.sharingTransactionToClose.length
    },

    seasonOptions() {
      if (!this.years || typeof this.years === "undefined") return []
      return this.years
    },
  },

  methods: {
    ...mapMutations({
      setOrgSharingTransactions: User.Mutations.setOrgSharingTransactions,
    }),

    handleSearch(searchString) {
      this.searchString = searchString
    },

    sortIcon(sortValue) {
      if (sortValue === "asc") return "fa-sort-up"
      else if (sortValue === "desc") return "fa-sort-down"
      return "fa-sort"
    },

    handleSortRole() {
      if (!this.sortByRole) this.sortByRole = "asc"
      else if (this.sortByRole === "asc") this.sortByRole = "desc"
      else if (this.sortByRole === "desc") this.sortByRole = null
    },

    selectAllCheckBox() {
      this.$refs.sharingTransactionListRows.forEach(transactionListRow =>
        transactionListRow.setCheckbox(this.sharingTransactionAllCheckbox)
      )
      if (!this.sharingTransactionAllCheckbox)
        this.sharingTransactionToClose = []
    },

    deselectAllCheckbox() {
      this.$refs.sharingTransactionListRows.forEach(transactionListRow =>
        transactionListRow.setCheckbox(false)
      )
    },

    checked(id) {
      this.sharingTransactionToClose.push(id)
    },

    unchecked(id) {
      const index = this.sharingTransactionToClose.indexOf(id)
      if (index > -1) this.sharingTransactionToClose.splice(index, 1)
    },

    processApproveSharingTransaction(transaction) {
      this.transaction = transaction
      this.source = this.APPROVE_REQUESTED_TRANSACTION
      this.dataShareAgreementModalOpen = true
    },

    closeDataShareAgreementModal() {
      this.transaction = null
      this.source = null
      this.dataShareAgreementModalOpen = false
    },

    submitChanges(source) {
      this.userLiabilityAgreement = true
      if (source === this.APPROVE_REQUESTED_TRANSACTION) {
        this.confirmApproveModalOpen = true
      } else if (source === this.EDIT_APPROVED_TRANSACTION) {
        this.editSharingTransaction(this.payload)
      } else if (source === this.CREATE_NEW_TRANSACTION_BY_ADMIN) {
        this.createNewTransactionRequest(this.payload)
      } else if (source === this.UPDATE_APPROVED_FIELD_TO_SHARE) {
        this.saveFieldToShare(this.payload)
      }
      this.dataShareAgreementModalOpen = false
      this.source = null
    },

    closeConfirmApproveModal() {
      this.transaction = null
      this.confirmApproveModalOpen = false
    },

    async openCreateNewTransactionModal() {
      this.loadingCreateNewTransaction = true
      await this.getOrgOptions()
      await this.getToSectionOptions()
      this.createNewTransactionOpen = true
      this.loadingCreateNewTransaction = false
    },

    closeCreateNewTransactionModal() {
      this.createNewTransactionOpen = false
    },

    openEditTransactionModal(transaction) {
      this.transactionToEdit = transaction
      this.editTransactionModalOpen = true
    },

    closeEditTransactionModal() {
      this.editTransactionModalOpen = false
    },

    async openFieldToShareModal(transaction, isEditable) {
      this.transaction = transaction
      this.isEditable = isEditable
      this.loadingFieldToShare = true
      const payload = {
        transaction_id: transaction.id,
      }
      await UserAPI.getFieldToShare(payload)
        .then(({ data }) => {
          if (data.length > 0) this.fieldToShare = data
          this.fieldToShareModalOpen = true
        })
        .catch(error => {
          this.modalTitleText = "Failed To Get Field To Share"
          this.errorMessageForModal = error.response.data.message
          this.alertErrorModalOpen = true
        })
      this.loadingFieldToShare = false
    },

    closeFieldToShareModal() {
      this.transaction = null
      this.fieldToShareModalOpen = false
    },

    async createNewTransactionRequest(payload) {
      payload["user_liability_agreement"] = this.userLiabilityAgreement
      await UserAPI.createNewDataSharingRequest(payload)
        .then(() => {
          this.showSuccessAlert = true
          this.message = "Successfully Created A New Data Sharing Transaction "
          this.refreshDataSharingRequest()
          this.closeCreateNewTransactionModal()
        })
        .catch(error => {
          this.modalTitleText =
            "Failed To Created A New Data Sharing Transaction"
          this.errorMessageForModal = error.response.data.message
          this.alertErrorModalOpen = true
        })
    },

    processCreateNewTransaction(payload) {
      this.payload = payload
      if (this.isCustomerAdmin) {
        this.source = this.CREATE_NEW_TRANSACTION_BY_ADMIN
        this.dataShareAgreementModalOpen = true
      } else {
        this.createNewTransactionRequest(payload)
      }
    },

    processEditTransaction(payload, transactionStatus) {
      this.payload = payload
      if (transactionStatus === "Approved") {
        this.source = this.EDIT_APPROVED_TRANSACTION
        this.dataShareAgreementModalOpen = true
      } else {
        this.editSharingTransaction(payload)
      }
    },

    processSaveFieldToShare(payload) {
      this.payload = payload
      if (this.isCustomerAdmin) {
        this.source = this.UPDATE_APPROVED_FIELD_TO_SHARE
        this.dataShareAgreementModalOpen = true
      } else {
        this.saveFieldToShare(payload)
      }
    },

    async editSharingTransaction(payload) {
      payload["user_liability_agreement"] = this.userLiabilityAgreement
      await UserAPI.editSharingTransaction(payload)
        .then(() => {
          this.showSuccessAlert = true
          this.message = "Successfully Modified Data Sharing Transaction "
          this.refreshDataSharingRequest()
        })
        .catch(error => {
          this.modalTitleText = "Failed To Modify Data Sharing Transaction"
          this.errorMessageForModal = error.response.data.message
          this.alertErrorModalOpen = true
        })

      this.closeEditTransactionModal()
    },

    async approveSharingTransaction() {
      const payload = {
        transaction_id: this.transaction.id,
        user_id: this.adminUser.id,
        user_liability_agreement: this.userLiabilityAgreement,
      }
      await UserAPI.approveSharingTransaction(payload)
        .then(() => {
          this.showSuccessAlert = true
          this.message = "Successfully Approved Data Sharing Transaction "
          this.refreshDataSharingRequest()
        })
        .catch(error => {
          this.modalTitleText = "Failed To Approve Data Sharing Transaction"
          this.errorMessageForModal = error.response.data.message
          this.alertErrorModalOpen = true
        })
      this.closeConfirmApproveModal()
    },

    async closeSharingTransaction() {
      this.confirmCloseTransactionModalOpen = false
      const payload = {
        transaction_ids: this.sharingTransactionToClose,
        user_id: this.adminUser.id,
      }
      await UserAPI.closeSharingTransaction(payload)
        .then(() => {
          this.showSuccessAlert = true
          this.sharingTransactionToClose = []
          this.message = "Successfully Closed Data Sharing Transaction(s) "
          this.refreshDataSharingRequest()
        })
        .catch(error => {
          this.modalTitleText = "Failed To Close Data Sharing Transaction(s)"
          this.errorMessageForModal = error.response.data.message
          this.alertErrorModalOpen = true
        })
    },

    async saveFieldToShare(fieldIds) {
      const payload = {
        transaction_id: this.transaction.id,
        field_ids: fieldIds,
        user_liability_agreement: this.userLiabilityAgreement,
      }
      await UserAPI.updateFieldToShare(payload)
        .then(() => {
          this.showSuccessAlert = true
          this.message = "Successfully Modified Field To Share "
        })
        .catch(error => {
          this.modalTitleText = "Failed To Modify Field To Share"
          this.errorMessageForModal = error.response.data.message
          this.alertErrorModalOpen = true
        })
      this.closeFieldToShareModal()
    },

    displayError(error) {
      this.modalTitleText = "Failed To Modify Position"
      this.errorMessageForModal = error.response.data.message
      this.alertErrorModalOpen = true
    },

    // Fetch organization drop down options
    async getOrgOptions() {
      const payload = {
        admin_user_id: this.adminUser.id,
      }
      await UserAPI.getOrgOptions(payload).then(({ data }) => {
        this.organizationOptions = data.org_options
      })
    },

    // Fetch to section drop down options
    async getToSectionOptions() {
      const payload = {
        from_corporation_id: this.corporation.id,
      }
      await UserAPI.getToSectionOptions(payload).then(({ data }) => {
        this.toCorpOptions = data.to_section_options.corporation_options
        this.toOrgOptions = data.to_section_options.organization_options
      })
    },

    async refreshDataSharingRequest() {
      await UserAPI.getDataSharingRequest().then(({ data }) => {
        this.setOrgSharingTransactions(data)
      })
    },
  },
}
</script>

<style scoped>
.requested-ball {
  background: #a2ac1a;
  width: 12px;
  height: 12px;
  border-radius: 12px;
  margin-top: 3px;
  margin-right: 1px;
}
.expired-ball {
  background: #e42121;
  width: 12px;
  height: 12px;
  border-radius: 12px;
  margin-top: 3px;
  margin-right: 1px;
}

.approved-ball {
  background: #14cd3f;
  width: 12px;
  height: 12px;
  border-radius: 12px;
  margin-top: 3px;
  margin-right: 1px;
}
</style>
