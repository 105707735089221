<template>
  <ArvaModal
    name="UploadDetailModal"
    :title="upload.name_display"
    :wide="true"
    :width="700"
    @close-modal="handleClose"
  >
    <div class="row">
      <div class="col-12 details">
        <div class="detail-row">
          <div>Uploaded at:</div>
          <div>{{ upload.created_at | shortDatetime }}</div>
        </div>
        <div class="detail-row">
          <div>Uploaded by:</div>
          <div>{{ uploadedBy }}</div>
        </div>
        <div class="detail-row">
          <div>Dataset Type:</div>
          <div>{{ upload.dataset_type_display }}</div>
        </div>
        <div class="detail-row">
          <div>UUID:</div>
          <div>{{ upload.uuid }}</div>
        </div>
        <div class="detail-row">
          <div>Upload #ID:</div>
          <div>{{ upload.id }}</div>
        </div>
        <div v-if="!isReadOnly" class="download">
          <a :href="upload.raw_data">
            <i class="fa fa-download" />Download dataset
          </a>
        </div>
        <div class="parsed">
          <div>Parsed files:</div>
          <div>
            <div v-for="file in parsedFiles" :key="file.filename">
              <i
                class="fa"
                :class="{ 'fa-check': !file.failed, 'fa-times': file.failed }"
              />
              {{ file.filename }}
              <div v-if="failedParseDetails[file.filename]">
                <div class="table-container">
                  <v-data-table
                    :headers="failedParseDetails[file.filename].headers"
                    :items="failedParseDetails[file.filename].items"
                    hide-actions
                    :hide-default-footer="
                      failedParseDetails[file.filename].items.length < 10
                    "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ArvaModal>
</template>

<script>
import { mapState } from "vuex"
import ArvaModal from "./ArvaModal"

export default {
  name: "UploadMappingModal",

  components: { ArvaModal },

  data() {
    return {
      upload: {},
    }
  },

  computed: {
    ...mapState({
      isReadOnly: state => state.User.user.permission === "Read-Only",
      uploadID: state => state.Uploads.detailsModalUploadID,
      uploads: state => state.Uploads.uploads,
    }),

    uploadedBy() {
      const { user } = this.upload
      if (!user) return ""
      if (user.first_name && user.last_name)
        return `${user.first_name} ${user.last_name}`
      return user.email
    },

    parsedFiles() {
      const { successful_parses = [], failed_parses = [] } = this.upload

      let failedParses = []
      if (failed_parses instanceof Array) {
        failedParses = failed_parses.map(filename => ({
          filename,
          failed: true,
        }))
      } else {
        failedParses = Object.keys(failed_parses).map(filename => ({
          filename,
          failed: true,
        }))
      }
      const successfulParses = successful_parses.map(filename => ({
        filename,
        failed: false,
      }))
      return failedParses.concat(successfulParses)
    },

    failedParseDetails() {
      const { failed_parses = [] } = this.upload
      if (failed_parses instanceof Object) {
        let failedParseDetails = {}

        for (let [filename, items] of Object.entries(failed_parses)) {
          let headers = []
          if (items && items.length > 0) {
            headers = Object.keys(items[0]).map(key => ({
              text: key.replace("_", " "),
              value: key,
            }))
          }

          failedParseDetails[filename] = {
            items: items,
            headers: headers,
          }
        }

        return failedParseDetails
      } else {
        return {}
      }
    },
  },

  methods: {
    handleClose() {
      this.$emit("close-modal")
    },
  },

  async mounted() {
    const upload = this.uploads.find(upload => upload.id === this.uploadID)
    if (upload) {
      this.upload = upload
    }
  },
}
</script>

<style scoped>
.details {
  padding: 20px 60px;
}

.detail-row {
  display: flex;
}

.detail-row > div:first-of-type {
  font-weight: bold;
  min-width: 110px;
}

.download {
  margin-top: 8px;
  font-weight: bold;
}

.download i {
  margin-right: 5px;
}

.parsed {
  margin-top: 20px;
}

.parsed > div:first-of-type {
  font-weight: bold;
}

.parsed > div:last-of-type {
  font-size: 13px;
  padding-left: 10px;
}

.parsed > div:last-of-type i {
  width: 10px;
  margin-right: 10px;
}

.fa-check {
  color: #00a200;
}

.fa-times {
  color: #e00000;
}

.table-container {
  max-height: 300px;
  overflow-y: auto;
}
</style>
