<template>
  <div class="toggle-checkbox toggle-sm toggle-success mr-2 d-flex">
    <input
      type="checkbox"
      v-model="activeLayerIds"
      :value="id"
      :name="id"
      :id="id"
      class="checked mx-3"
    />
    <label :for="id" class="form-check-label mt-1" />
    <div
      v-if="
        [yieldByElevationLayerId, harvestLayerId].includes(id) &&
          showHarvestOptions === true
      "
      class="selector ml-4"
    >
      <v-select-old
        class="white-bg"
        :options="activeHarvestsSelect"
        label="display_name"
        v-model="showHarvest"
        :reduce="o => o.value"
        :clearable="false"
      />
      <ElevationScaleSlider
        v-if="id === yieldByElevationLayerId"
        class="pt-1"
        :id="id"
      />
      <OpacitySlider v-if="id === harvestLayerId" class="pt-1" :id="id" />
    </div>
    <div
      v-if="id == fertLayerId && showFertOptions === true"
      class="selector ml-4"
    >
      <v-select-old
        class="white-bg"
        :options="activeFerts"
        v-model="showFert"
        :clearable="false"
      >
        <template #selected-option-container="{ option }">
          <div class="vs__selected" v-if="option.label.length < 14">
            {{ option.label }}
          </div>
          <div class="vs__selected" v-if="option.label.length >= 14">
            {{ option.label.slice(0, -(option.label.length - 14)) }}...
          </div>
        </template>
      </v-select-old>
      <OpacitySlider class="pt-1" :id="id" />
    </div>
    <div
      v-if="id == plantingLayerId && showSeedOptions === true"
      class="selector ml-4"
    >
      <v-select-old
        class="white-bg"
        :options="activeSeedsSelect"
        label="display_name"
        v-model="showSeed"
        :reduce="o => o.value"
        :clearable="false"
      />
      <OpacitySlider class="pt-1" :id="id" />
    </div>
    <OpacitySlider v-if="showSlider" class="slider" :id="id" />
    <ElevationScaleSlider
      v-if="
        [agtByElevationLayerId, elevationLayerId, profitLayerId].includes(id)
      "
      class="slider"
      :id="id"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { CROP_DISPLAY_NAME, MAP_LAYER } from "@/constants"
import { Map } from "@/store/modules"
import ElevationScaleSlider from "./ElevationScaleSlider"
import OpacitySlider from "./OpacitySlider"

export default {
  name: "ActiveLayerToggle",
  props: ["id"],
  components: { ElevationScaleSlider, OpacitySlider },

  data() {
    return {
      yieldByElevationLayerId: MAP_LAYER.YieldByElevation,
      agtByElevationLayerId: MAP_LAYER.AGTByElevation,
      elevationLayerId: MAP_LAYER.Elevation,
      profitLayerId: MAP_LAYER.Profit,
      boundaryLayerId: MAP_LAYER.Boundary,
      fieldBorderLayerId: MAP_LAYER.FieldBorder,
      // seasonality layers
      harvestLayerId: MAP_LAYER.Harvest,
      fertLayerId: MAP_LAYER.Fertilizer,
      plantingLayerId: MAP_LAYER.Planting,
    }
  },

  computed: {
    ...mapGetters({
      activeFerts: Map.Getters.getActiveFerts,
      showFertOptions: Map.Getters.getShowNewFertOptions,
      activeSeeds: Map.Getters.getActiveSeeds,
      showSeedOptions: Map.Getters.getShowNewSeedOptions,
      activeHarvests: Map.Getters.getActiveHarvests,
      showHarvestOptions: Map.Getters.getShowNewHarvestOptions,
    }),

    activeLayerIds: {
      get() {
        return this.$store.state.Map.activeLayerIds
      },
      set(val) {
        this.$store.commit(Map.Mutations.setActiveLayerIds, val)
      },
    },

    activeSeedsSelect() {
      const mapForSelect = this.activeSeeds.map(val => {
        if (CROP_DISPLAY_NAME[val])
          return { value: val, display_name: CROP_DISPLAY_NAME[val] }
        else return { value: val, display_name: val }
      })

      return mapForSelect
    },

    activeHarvestsSelect() {
      const mapForSelect = this.activeHarvests.map(val => {
        if (CROP_DISPLAY_NAME[val])
          return { value: val, display_name: CROP_DISPLAY_NAME[val] }
        else return { value: val, display_name: val }
      })
      return mapForSelect
    },

    showFert: {
      get() {
        return this.$store.state.Map.showFert
      },
      set(val) {
        this.$store.commit(Map.Mutations.setShowFert, val)
      },
    },

    showSeed: {
      get() {
        return this.$store.state.Map.showSeed
      },
      set(val) {
        this.$store.commit(Map.Mutations.setShowSeed, val)
      },
    },

    showHarvest: {
      get() {
        return this.$store.state.Map.showHarvestCrop
      },
      set(val) {
        this.$store.commit(Map.Mutations.setShowHarvest, val)
      },
    },

    showSlider() {
      const id = this.id
      if (
        [
          this.yieldByElevationLayerId,
          this.fertLayerId,
          this.harvestLayerId,
          this.plantingLayerId,
        ].includes(id)
      ) {
        if (
          id == this.yieldByElevationLayerId &&
          this.showHarvestOptions === true
        )
          return false
        if (id == this.fertLayerId && this.showFertOptions === true)
          return false
        if (id == this.harvestLayerId && this.showHarvestOptions === true)
          return false
        if (id == this.plantingLayerId && this.showSeedOptions === true)
          return false
      }
      return true
    },
  },
}
</script>

<style scoped>
.slider {
  flex: 1;
  margin-left: 20px;
}

.selector {
  width: 80%;
}

.white-bg {
  background-color: white !important;
  font-weight: bold;
  border-radius: 6px;
}
</style>
