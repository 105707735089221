<template>
  <li class="nav-item">
    <a
      @click="$emit('navlink-signal')"
      :style="{ color }"
      href="javascript:void(0)"
      id="link"
      >{{ label }}
    </a>
  </li>
</template>

<script>
export default {
  name: "NavLink",
  props: {
    label: {
      type: String,
    },
    color: {
      type: String,
    },
  },
}
</script>
