<template>
  <div>
    <DashboardTitle
      title="Switch Between Organizations"
      imgSrc="/assets/images/side-nav/sitemap-solid.svg"
    />

    <div class="col-12 scroll-col">
      <v-card>
        <v-toolbar flat>
          <v-tabs grow v-model="tab">
            <v-tabs-slider color="blue"></v-tabs-slider>
            <v-tab v-for="item in tabTitles" :key="item.index">
              {{ item.name }}
            </v-tab>
          </v-tabs>
        </v-toolbar>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in tabTitles" :key="item.index">
            <v-card flat>
              <div
                v-if="item.name === 'Assigned Organizations'"
                class="pt-3 card"
              >
                <AssignedOrganization
                  :selectedCorp="selectedCorp"
                  :selectedOrg="selectedOrg"
                  :tab="item.name"
                  :loading="!dataReady"
                />
              </div>
              <div v-if="item.name === 'Shared Organizations'" class="pt-3">
                <SharedOrganization
                  :selectedCorp="selectedCorp"
                  :selectedOrg="selectedOrg"
                  :tab="item.name"
                  :loading="!dataReady"
                />
              </div>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex"
import DashboardTitle from "@/components/dashboard/DashboardTitle"
import { User } from "@/store/modules"
import AssignedOrganization from "@/components/switchOrganization/AssignedOrganization"
import SharedOrganization from "@/components/switchOrganization/SharedOrganization"
import UserAPI from "@/api/UserAPI"

export default {
  name: "SwitchOrganizationView",
  components: {
    DashboardTitle,
    AssignedOrganization,
    SharedOrganization,
  },

  data() {
    return {
      tab: 0,
      tabTitles: [
        { index: 0, name: "Assigned Organizations" },
        { index: 1, name: "Shared Organizations" },
      ],
      dataReady: true,
    }
  },

  computed: {
    ...mapState({
      user: state => state.User.user,
      organization: state => state.Organization.organization,
    }),

    selectedCorp() {
      if (this.organization.corporation.name === "") return null
      return this.organization.corporation
    },

    selectedOrg() {
      if (this.organization.corporation.name === "") return null
      return this.organization
    },
  },

  methods: {
    ...mapActions({
      fetchUser: User.Actions.fetchUser,
    }),
    ...mapMutations({
      setUserAssignedOrg: User.Mutations.setUserAssignedOrg,
      setUserSharedOrg: User.Mutations.setUserSharedOrg,
    }),

    async getAssignedOrgByUser() {
      const payload = {
        user_id: this.user.id,
      }
      this.dataReady = false
      await UserAPI.getAssignedOrgByUser(payload)
        .then(({ data }) => {
          const jsonResult = JSON.parse(data)
          this.setUserAssignedOrg(jsonResult)
        })
        .catch(error => {
          console.log(error.response.data)
        })
      this.dataReady = true
    },

    async getSharedOrgByUser() {
      const payload = {
        user_id: this.user.id,
      }
      this.dataReady = false
      await UserAPI.getSharedOrgByUser(payload)
        .then(({ data }) => {
          const jsonResult = JSON.parse(data)
          this.setUserSharedOrg(jsonResult)
        })
        .catch(error => {
          console.log(error.response.data)
        })
      this.dataReady = true
    },
  },

  async beforeMount() {
    await this.fetchUser()
    this.getAssignedOrgByUser()
    this.getSharedOrgByUser()
  },

  mounted() {},
}
</script>

<style scoped></style>
