<template>
  <div class="row padding-fit">
    <div class="card w-100">
      <div class="card-body">
        <div class="card-title pb-0 d-flex">
          AGT
          <div
            class="agt-num ml-1 mr-1"
            :style="{ backgroundColor: colorStyle }"
          >
            {{ this.agt.agt }}
          </div>
          {{ this.agt.acreage }} ac.
        </div>
        <div class="card-body pt-0">
          <div class="row">
            <div class="col-6">
              <div class="card-title mb-0">Macros</div>
              <apexchart
                type="bar"
                height="200"
                :options="macrosOptions"
                :series="macrosSeries"
              />
            </div>
            <div class="col-6">
              <table class="table mt-4">
                <thead>
                  <tr>
                    <th scope="col">CEC</th>
                    <th scope="col">Organic Matter</th>
                    <th scope="col">pH</th>
                    <th scope="col">pH Buffer</th>
                    <!--<th scope="col">Predicted Avg. Yield</th>-->
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ this.agt.cec_median | floatHundredth }}</td>
                    <td>{{ this.agt.om_median | floatHundredth }}%</td>
                    <td>{{ this.agt.ph_median | floatHundredth }}</td>
                    <td>{{ this.agt.ph_median > 6.5 ? "Low" : "High" }}</td>
                    <!--<td>
                      {{ (agtYield / this.agt.acreage) | prettyInteger }}
                      {{ this.agt.crop_id | yieldUnits }}
                    </td>-->
                  </tr>
                </tbody>
              </table>
              <div class="row">
                <div class="col-4 text-right pt-1 pb-0">
                  <span class="bullet span-green">&#8226;</span>
                  <span class="font-weight-bold span-green">
                    {{ carbonTons | prettyInteger }} T</span
                  >
                  Carbon In Soil
                </div>
                <div class="col-4">
                  <div class="progress">
                    <div
                      class="progress-bar bg-success"
                      :style="progressBarStyle"
                      role="progressbar"
                      :aria-valuenow="progressBarValue"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
                <div class="col-4 pt-1 pb-0">
                  <span class="bullet span-black">&#8226;</span>
                  <span class="font-weight-bold span-black">
                    {{ carbonPotentialTons | prettyInteger }}
                    T</span
                  >
                  Carbon Potential
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-6">
              <h5>Micros</h5>
              <apexchart
                type="bar"
                height="200"
                :options="microsOptions"
                :series="microsSeries"
              />
            </div>
            <div class="col-6">
              <div class="d-flex flex-row">
                <h2 class="mt-4">
                  Alerts
                </h2>
                <i
                  class="fa fa-info-circle info-tooltip"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Alerts are determined by AI analysis when certain macro or micro nutrient conditions may limit crop performance."
                />
              </div>
              <div v-for="alert in alertText" :key="alert">
                {{ alert }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { CROP_DISPLAY_NAME } from "@/constants"
import { getAgtColor } from "@/utility"

export default {
  name: "AgtCard",
  props: ["agt", "agtYield"],
  computed: {
    agtCrop() {
      return CROP_DISPLAY_NAME[this.agt.crop_id]
    },

    carbonTons() {
      const OM_PCT = this.agt.om_median / 100
      const acreage = this.agt.acreage
      const TOP_SOIL_LBS_AC = 2000000
      const ORGANIC_CARBON_PCT = 0.58
      const LBS_PER_TON = 2000
      // For example - OM 1.39% * 20,000 lbs/acre of top soil * acres 2401 * 58% Organic Carbon / 2000 = tons of organic carbon
      return (
        (OM_PCT * TOP_SOIL_LBS_AC * acreage * ORGANIC_CARBON_PCT) / LBS_PER_TON
      )
    },

    carbonPotentialTons() {
      const OM_PCT = 0.005
      const acreage = this.agt.acreage
      const TOP_SOIL_LBS_AC = 2000000
      const ORGANIC_CARBON_PCT = 0.58
      const LBS_PER_TON = 2000
      const additionalPotential =
        (OM_PCT * TOP_SOIL_LBS_AC * acreage * ORGANIC_CARBON_PCT) / LBS_PER_TON
      // constant value of 0.005 organic matter is our current estimate for additional carbon capture
      return this.carbonTons + additionalPotential
    },

    alertText() {
      const thisAgt = this.agt
      let alerts = []
      if (thisAgt.ph_median < 6.5) {
        alerts.push(
          "In this AGT the pH may be limiting yield in some regions. Liming is recommended."
        )
      } else if (thisAgt.ph_median >= 6.5 && thisAgt.ph_median < 7.5) {
        alerts.push(
          "You are managing soil pH well. This is important for maximizing nutrient availability and minimizing plant toxicity."
        )
      } else if (thisAgt.ph_median >= 7.5) {
        alerts.push(
          "The soil pH in the AGT is reaching alkaline levels, which limits the availability of Iron, Boron, Manganese, Copper and Zinc."
        )
      }

      if (thisAgt.p_ppm_median < 36) {
        alerts.push(
          "You may be able to improve soil quality in this AGT with additional Phosphorus applications."
        )
      }

      if (thisAgt.k_ppm_median < 120) {
        alerts.push(
          "You may be able to improve soil quality in this AGT with additional Potassium applications."
        )
      }

      if (thisAgt.s_ppm_median < 8) {
        alerts.push(
          "This AGT may benefit from Sulfur applications, particularly if a difficiency has occurred before. Plant tissue samples may prove helpful here."
        )
      }

      if (thisAgt.mg_ppm_median < 53) {
        alerts.push(
          "This AGT may benefit from Magnesium applications. If liming is recommended, Magnesium can be provided in the form of dolomitic limestone."
        )
      }

      if (thisAgt.fe_ppm_median < 2) {
        alerts.push("This AGT may benefit from foliar Iron applications.")
      }

      if (thisAgt.mn_ppm_median < 5) {
        alerts.push("Manganese levels are low in this AGT.")
      }

      if (thisAgt.b_ppm_median < 1) {
        alerts.push(
          "This AGT would benefit from Boron applications at a rate of 1 lb/acre."
        )
      } else if (thisAgt.b_ppm_median < 0.5) {
        alerts.push(
          "This AGT would benefit from Boron applications at a rate of 2 lbs/acre."
        )
      }

      if (thisAgt.zn_ppm_median < 1) {
        alerts.push("This AGT is low in Zinc which may be limiting yields.")
      }

      return alerts
    },

    colorStyle() {
      return getAgtColor(this.agt.agt)
    },

    macrosSeries() {
      const macroData = [
        (parseInt(this.agt.p_ppm_median) * 100) / 36,
        (parseInt(this.agt.k_ppm_median) * 100) / 120,
        (parseInt(this.agt.s_ppm_median) * 100) / 25,
        (parseInt(this.agt.ca_ppm_median) * 100) / 401,
        (parseInt(this.agt.mg_ppm_median) * 100) / 107,
      ]
      return [
        {
          name: "Percentage of Optimum",
          data: macroData,
        },
      ]
    },
    macrosOptions() {
      const macroCategories = [
        `P ${parseInt(this.agt.p_ppm_median)}`,
        `K ${parseInt(this.agt.k_ppm_median)}`,
        `S ${parseInt(this.agt.s_ppm_median)}`,
        `Ca ${parseInt(this.agt.ca_ppm_median)}`,
        `Mg ${parseInt(this.agt.mg_ppm_median)}`,
      ]
      const macroColors = [
        (parseInt(this.agt.p_ppm) * 100) / 36,
        (parseInt(this.agt.k_ppm) * 100) / 120,
        (parseInt(this.agt.s_ppm) * 100) / 25,
        (parseInt(this.agt.ca_ppm) * 100) / 401,
        (parseInt(this.agt.mg_ppm) * 100) / 107,
      ].map(x => {
        if (x < 50) return "#F05400"
        if (x < 100) return "#fae655"
        return "#79C61C"
      })
      return {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
        },
        colors: macroColors,
        plotOptions: {
          bar: {
            distributed: true,
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories: macroCategories,
          title: {
            text: "Soil Nutrient Grade",
            offsetX: 0,
            offsetY: 0,
            style: {
              color: "black",
              fontSize: "12px",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 600,
              cssClass: "apexcharts-xaxis-title",
            },
          },
          labels: {
            formatter: function(value) {
              if (value == 0) return ""
              else if (value <= 40) return "Low"
              else if (value <= 80) return "Medium"
              else if (value <= 140) return "Optimum"
              else if (value <= 180) return "High"
              else if (value <= 200) return ""
            },
          },
          tickAmount: 5,
          min: 0,
          max: 200,
        },
        yaxis: {
          min: 0,
          max: 200,
          title: {
            text: "ppm",
            rotate: -90,
            offsetX: 10,
            offsetY: 0,
            style: {
              color: "black",
              fontSize: "12px",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 600,
              cssClass: "apexcharts-yaxis-title",
            },
          },
        },
        tooltip: {
          followCursor: true,
          y: {
            formatter: function(value) {
              return parseFloat(value).toFixed(0) + "%"
            },
          },
          x: {
            formatter: function(value) {
              return value.replace(" ", " - ") + " ppm"
            },
          },
        },
        annotations: {
          xaxis: [
            {
              x: 50,
              borderColor: "black",
            },
            {
              x: 100,
              borderColor: "black",
            },
            {
              x: 150,
              borderColor: "black",
            },
          ],
        },
      }
    },
    microsSeries() {
      const microData = [
        (parseFloat(this.agt.b_ppm_median) * 100) / 1.2,
        (parseInt(this.agt.fe_ppm_median) * 100) / 15,
        (parseInt(this.agt.mn_ppm_median) * 100) / 16,
        (parseInt(this.agt.na_ppm_median) * 100) / 53,
        (parseFloat(this.agt.zn_ppm_median) * 100) / 4,
      ]
      return [
        {
          name: "Percentage of Optimum",
          data: microData,
        },
      ]
    },
    microsOptions() {
      const microCategories = [
        `B ${this.agt.b_ppm_median.toFixed(1)}`,
        `Fe ${parseInt(this.agt.fe_ppm_median)}`,
        `Mn ${parseInt(this.agt.mn_ppm_median)}`,
        `Na ${parseInt(this.agt.na_ppm_median)}`,
        `Zn ${parseInt(this.agt.zn_ppm_median)}`,
      ]
      const microColors = [
        (parseFloat(this.agt.b_ppm_median) * 100) / 1.2,
        (parseInt(this.agt.fe_ppm_median) * 100) / 15,
        (parseInt(this.agt.mn_ppm_median) * 100) / 16,
        (parseInt(this.agt.na_ppm_median) * 100) / 53,
        (parseFloat(this.agt.zn_ppm_median) * 100) / 4,
      ].map(x => {
        if (x < 50) return "#F05400"
        if (x < 100) return "#FAE655"
        return "#79C61C"
      })
      return {
        chart: {
          type: "bar",
          height: "100%",
          stacked: true,
        },
        colors: microColors,
        plotOptions: {
          bar: {
            distributed: true,
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories: microCategories,
          title: {
            text: "Soil Nutrient Grade",
            offsetX: 0,
            offsetY: 0,
            style: {
              color: "black",
              fontSize: "12px",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 600,
              cssClass: "apexcharts-xaxis-title",
            },
          },
          labels: {
            formatter: function(value) {
              if (value == 0) return ""
              else if (value <= 40) return "Low"
              else if (value <= 80) return "Medium"
              else if (value <= 140) return "Optimum"
              else if (value <= 180) return "High"
              else if (value <= 200) return ""
            },
          },
          tickAmount: 5,
          min: 0,
          max: 200,
        },
        yaxis: {
          min: 0,
          max: 200,
          title: {
            text: "ppm",
            rotate: -90,
            offsetX: 10,
            offsetY: 0,
            style: {
              color: "black",
              fontSize: "12px",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 600,
              cssClass: "apexcharts-yaxis-title",
            },
          },
        },
        tooltip: {
          followCursor: true,
          y: {
            formatter: function(value) {
              return parseFloat(value).toFixed(0) + "%"
            },
          },
          x: {
            formatter: function(value) {
              return value.replace(" ", " - ") + " ppm"
            },
          },
        },
        annotations: {
          xaxis: [
            {
              x: 50,
              borderColor: "black",
            },
            {
              x: 100,
              borderColor: "black",
            },
            {
              x: 150,
              borderColor: "black",
            },
          ],
        },
      }
    },

    progressBarStyle() {
      const barValue = (this.carbonTons / this.carbonPotentialTons) * 100
      return { width: `${parseFloat(barValue)}%` }
    },

    progressBarValue() {
      const barValue = (this.carbonTons / this.carbonPotentialTons) * 100
      return parseFloat(barValue)
    },
  },
}
</script>
<style scoped>
.agt-num {
  border-radius: 24px;
  color: white;
  text-align: center;
  font-size: 12px;
  height: 24px;
  width: 65px;
  padding-top: 3px;
}

.bullet {
  font-weight: 800;
}

.card-title {
  font-size: 16px;
  font-weight: 500;
}

.padding-fit {
  padding-left: 15px;
  padding-right: 15px;
}

.span-black {
  color: black;
}

.span-green {
  color: #0b7d3f;
}

.info-tooltip {
  font-size: 14px;
  margin-left: 4px;
  margin-top: 24px;
}
</style>
// const nutrientDict = {'p':42, 'k': 89, 'ca': 401, 'mg': 15.1, 's': 29, //
'zn': 3.5, 'mn':23, 'fe':11, 'cu':1.6, 'b': 1.3}
