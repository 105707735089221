<template>
  <div>
    <div v-if="selectedFields.length > MAX_DEFAULT_FIELDS">
      <v-card elevation="0">
        <v-card-text class="text-center">
          <span class="text-h4"
            >Please select fewer than {{ MAX_DEFAULT_FIELDS }} fields using the
            top navigation tools.</span
          >
        </v-card-text>
      </v-card>
    </div>
    <div v-else-if="selectedFields.length > 0">
      <div id="input-card-container">
        <v-card class="my-auto mx-auto" v-if="showPracticesView || showSaveView || showSummaryView">
          <v-card-text class="min-step-height" v-if="showPracticesView">
            <v-row class="mx-1 mt-3">
              <div class="text-h5">Conservation program enrollment for {{ year }}</div>
              <v-btn class="ml-auto" @click="handleCancelClick()">
                <v-icon>mdi-close</v-icon> Cancel
              </v-btn>
              <v-btn
                class="ml-4"
                color="green lighten-4"
                :disabled="programsComplete(newConservationPrograms) === false"
                @click="handlePreSave()"
                >Save and Continue</v-btn
              >
            </v-row>

            <v-row class="mx-1 mt-7">
              <v-btn-toggle class="ml-5" v-model="stackPractices" divided dense active-class="light-blue lighten-4" mandatory>
                <v-btn :value="true"  class="px-3">Combine with Enrolled Programs</v-btn>
                <v-btn :value="false" class="px-3">Replace Enrolled Programs</v-btn>
              </v-btn-toggle>
            </v-row>

            <v-row v-if="newConservationPrograms">
              <v-col md="12">
                <v-card
                  class="mt-3"
                  elevation="6"
                >
                  <v-card-title
                    class="cursor blue-grey lighten-5"
                  >
                    <v-tooltip top
                      ><template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          class="ml-auto"
                          :color="
                            programsComplete(newConservationPrograms)
                              ? 'green accent-4'
                              : 'red darken-1'
                          "
                        >
                          {{
                            programsComplete(newConservationPrograms)
                              ? "mdi-check-circle-outline"
                              : "mdi-alert-circle-outline"
                          }}
                        </v-icon> </template
                      ><span>{{
                        programsComplete(newConservationPrograms)
                          ? "Program documentation is complete."
                          : "Please fill out information for all programs."
                      }}</span></v-tooltip
                    >
                  </v-card-title>
                  <v-card-text>
                    <v-row class="my-4 mx-2">
                      <!-- Row 1 -->
                      <v-col cols="6" sm="3" md="2">
                        <b class="text-h7">RCPP (Regional Conservation Partnership Program)</b>
                        <span v-if="!stackPractices" class="red--text">*</span>
                      </v-col>
                      <v-col cols="6" sm="3" md="2">
                        <v-btn-toggle v-model="newConservationPrograms.rcpp">
                          <v-btn :value="true">Yes</v-btn>
                          <v-btn :value="false">No</v-btn>
                        </v-btn-toggle>
                      </v-col>
                      <v-col cols="6" sm="3" md="2">
                        <b class="text-h7">CSCG (Climate Smart Commodities Grant)</b>
                        <span v-if="!stackPractices" class="red--text">*</span>
                      </v-col>
                      <v-col cols="6" sm="3" md="2">
                        <v-btn-toggle v-model="newConservationPrograms.cscg">
                          <v-btn :value="true">Yes</v-btn>
                          <v-btn :value="false">No</v-btn>
                        </v-btn-toggle>
                      </v-col>
                      <v-col cols="6" sm="3" md="2">
                        <b class="text-h7">EQIP (Environmental Quality Incentives Program)</b>
                        <span v-if="!stackPractices" class="red--text">*</span>
                      </v-col>
                      <v-col cols="6" sm="3" md="2">
                        <v-btn-toggle v-model="newConservationPrograms.eqip">
                          <v-btn :value="true">Yes</v-btn>
                          <v-btn :value="false">No</v-btn>
                        </v-btn-toggle>
                      </v-col>

                      <!-- Row 2 -->
                      <v-col cols="6" sm="3" md="2">
                        <b class="text-h7">CSP (Conservation Stewardship Program)</b>
                        <span v-if="!stackPractices" class="red--text">*</span>
                      </v-col>
                      <v-col cols="6" sm="3" md="2">
                        <v-btn-toggle v-model="newConservationPrograms.csp">
                          <v-btn :value="true">Yes</v-btn>
                          <v-btn :value="false">No</v-btn>
                        </v-btn-toggle>
                      </v-col>
                      <v-col cols="6" sm="3" md="2">
                        <b class="text-h7">Non-deforestation</b>
                        <span v-if="!stackPractices" class="red--text">*</span>
                      </v-col>
                      <v-col cols="6" sm="3" md="2">
                        <v-btn-toggle v-model="newConservationPrograms.non_deforestation">
                          <v-btn :value="true">Yes</v-btn>
                          <v-btn :value="false">No</v-btn>
                        </v-btn-toggle>
                      </v-col>
                      <v-col cols="6" sm="3" md="2">
                        <b class="text-h7">Pay for Practice</b>
                        <span v-if="!stackPractices" class="red--text">*</span>
                      </v-col>
                      <v-col cols="6" sm="3" md="2">
                        <v-btn-toggle v-model="newConservationPrograms.pay_for_practice">
                          <v-btn :value="true">Yes</v-btn>
                          <v-btn :value="false">No</v-btn>
                        </v-btn-toggle>
                      </v-col>

                      <!-- Row 3 -->
                      <v-col cols="6" sm="3" md="2">
                        <b class="text-h7">Carbon Offset</b>
                        <span v-if="!stackPractices" class="red--text">*</span>
                      </v-col>
                      <v-col cols="6" sm="3" md="2">
                        <v-btn-toggle v-model="newConservationPrograms.carbon_offset">
                          <v-btn :value="true">Yes</v-btn>
                          <v-btn :value="false">No</v-btn>
                        </v-btn-toggle>
                      </v-col>
                      <v-col cols="6" sm="3" md="2">
                        <b class="text-h7">Other Scope 3</b>
                        <span v-if="!stackPractices" class="red--text">*</span>
                      </v-col>
                      <v-col cols="6" sm="3" md="2">
                        <v-btn-toggle v-model="newConservationPrograms.other_scope_3">
                          <v-btn :value="true">Yes</v-btn>
                          <v-btn :value="false">No</v-btn>
                        </v-btn-toggle>
                      </v-col>
                      <v-col cols="6" sm="3" md="2" class="mx-auto">
                        <v-btn  @click="selectNoPrograms()">
                          None
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>

              </v-col>
            </v-row>
          </v-card-text>
          <!-- Save Spinner View -->
          <v-card-text class="min-step-height text-center" v-if="showSaveView">
            <v-progress-circular
              class="mt-4"
              :size="100"
              :width="8"
              color="green"
              indeterminate
            >
            </v-progress-circular>
            <div>
              <h3>Saving your conservation program information</h3>
            </div>
          </v-card-text>
          <!-- Summary View -->
          <v-card-text class="min-step-height" v-if="showSummaryView">
            <div class="d-flex">
              <h3>Fields Updated</h3>
              <v-btn class="ml-auto" @click="handleAddMore">
                Record More Conservation Programs
              </v-btn>
            </div>
            <div class="d-flex">
              <v-chip
                class="mx-2"
                v-for="fieldName in summaryFields"
                :key="fieldName.idx"
                >{{ fieldName }}</v-chip
              >
            </div>
            <br />
            <h3>Conservation Program Data Submitted</h3>
            <div>
              <v-simple-table class="mx-4 mb-4">
                <thead>
                  <tr>
                    <th v-for="key in summaryKeys" :key="key.idx">
                      {{ key == "crop_id" ? "Crop" : key | cleanSnake }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td v-for="key in summaryKeys" :key="key.idx">
                      <span v-if="key == 'crop_id'">{{
                        cropDisplay[summaryData[key].value]
                      }}</span>
                      <span v-else>
                        {{
                          summaryData[key] !== null
                            ? boolDisplay(summaryData[key])
                            : ""
                        }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>
          </v-card-text>
        </v-card>
      </div>

      <!-- Fields, Data Comp, and Add Practices -->
      <v-row class="mt-4 mb-2 row-margin-correct">
        <div v-if="!showPracticesView && !showSaveView">
          <span class="text-h5 ml-1"
            >{{ opsSettingsByField.length }} fields</span
          >
          <span class="text-h5 ml-2"
            >({{ dataCompletionForYear }}% Complete)</span
          >
        </div>
        <div class="ml-auto d-flex">
          <div>
            <v-btn
              v-for="y in yearListModified"
              :key="y.idx"
              :color="parseInt(y) === parseInt(year) ? 'blue-grey lighten-4' : 'white'"
              elevation="0"
              tile
              dense
              class="mx-1 py-1"
              @click="handleSeasonSelection(y)"
            >
              {{ y }}
            </v-btn>
          </div>
          <div v-if="!showPracticesView && !showSaveView" class="text-container ml-2">
            <v-text-field
              outlined
              dense
              hide-details
              v-model="tableFilterText"
              label="Filter Fields"
            ></v-text-field>
          </div>
          <div v-if="!showPracticesView && !showSaveView" class="ml-2">
            <v-btn v-if="showPracticesView === false && showSaveView === false && showSummaryView === false"
              class="white--text"
              color="green"
              x-large
              :disabled="fieldsInSelection.length === 0"
              @click="handleAddPracticesClick"
              >Add Programs</v-btn
            >
          </div>
        </div>
      </v-row>


      <!-- Quick Filtering Buttons -->
      <v-row class="mt-4 row-margin-correct">
        <v-btn
          dense
          tile
          :color="cropSelect == null ? 'blue-grey lighten-4' : 'white'"
          @click="handleSelectAll(true, true)"
          class="px-2 mx-2 py-1 text-none letter-spacing-0"
          elevation="0"
        >
          All ({{ opsSettingsByField.length }})
        </v-btn>
        <v-btn
          v-for="sharedCropPlan in historicalPlantings"
          :key="sharedCropPlan.idx"
          dense
          tile
          :color="JSON.stringify(cropSelect) == JSON.stringify(sharedCropPlan.crops.split(',')) ? 'blue-grey lighten-4' : 'white'"
          @click="handleCropFilterButton(sharedCropPlan)"
          class="px-2 mx-2 py-1 text-none"
          elevation="0"
        >
          {{ mapCropNames(sharedCropPlan.crops) }} ({{ sharedCropPlan.fieldIds.length }})
        </v-btn>
      </v-row>
      <v-row class="mt-4 mb-2 row-margin-correct" v-if="showPracticesView">
        <div>
          <span class="text-button ml-1">Receiving fields</span>
        </div>
      </v-row>
      <!-- Field Filtering Table -->
      <v-row class="pt-3">
        <v-col>
          <v-card>
            <v-card-text v-if="showSelectedFields">
              <v-simple-table>
                <thead>
                  <tr>
                    <th class="three">
                      <div class="d-flex">
                        <v-simple-checkbox
                          color="blue"
                          v-model="selectAllBox"
                          @input="handleSelectAll($event)"
                        ></v-simple-checkbox>
                      </div>
                    </th>
                    <th class="ten">Name</th>
                    <th class="three">Farm</th>
                    <th class="three">Locked</th>
                    <th class="three">Crops</th>
                    <th>
                      <v-tooltip top max-width="300">
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">RCPP</span>
                        </template>
                        <span>Regional Conservation Partnership Program</span>
                      </v-tooltip>
                    </th>
                    <th>
                      <v-tooltip top max-width="300">
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">CSCG</span>
                        </template>
                        <span>Climate Smart Commodities Grant</span>
                      </v-tooltip>
                    </th>
                    <th>
                      <v-tooltip top max-width="300">
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">EQIP</span>
                        </template>
                        <span>Environmental Quality Incentives Program</span>
                      </v-tooltip>
                    </th>
                    <th>
                      <v-tooltip top max-width="300">
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">CSP</span>
                        </template>
                        <span>Conservation Stewardship Program</span>
                      </v-tooltip>
                    </th>
                    <th>Non-Deforestation</th>
                    <th>Pay for Practice</th>
                    <th>Carbon Offset</th>
                    <th>Other Scope 3</th>
                    <th>Completion</th>
                    <th class="three">Copy</th>
                    <!-- <th>Status</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="field in opsSettingsByFieldFiltered"
                    :key="field.idx"
                  >
                    <td>
                      <v-simple-checkbox
                        color="blue"
                        :value="
                          fieldsInSelection.includes(parseInt(field.fieldId))
                        "
                        :disabled="
                          disableCheckbox(field.noYearData) || field.fieldLocked
                        "
                        @input="fieldChecked(field, $event)"
                      ></v-simple-checkbox>
                    </td>
                    <td>
                      <img :src="field.fieldImg" style="width:50px" />&nbsp;
                      {{ field.fieldName }}
                    </td>
                    <td class="overflow-hidden">{{ field.farmName }}</td>
                    <td>
                      <v-icon
                        size="20"
                        v-if="field.fieldLocked"
                        :color="field.fieldLocked ? 'red' : 'green'"
                      >
                        {{ field.fieldLocked ? "mdi-lock" : "mdi-lock-open" }}
                      </v-icon>
                    </td>
                    <td v-if="'cropNames' in field">
                      <div v-for="crop in field.cropNames" :key="crop">
                        {{ crop }}
                      </div>
                    </td>
                    <td v-else>
                      <div>No Crop</div>
                    </td>
                    <td class="py-2" v-if="!field.noYearData">
                       {{ boolDisplay(getConservationByField(field.fieldId).rcpp) }}
                    </td>
                    <td class="py-2" v-if="!field.noYearData">
                       {{ boolDisplay(getConservationByField(field.fieldId).cscg) }}
                    </td>
                    <td class="py-2" v-if="!field.noYearData">
                       {{ boolDisplay(getConservationByField(field.fieldId).eqip) }}
                    </td>
                    <td class="py-2" v-if="!field.noYearData">
                       {{ boolDisplay(getConservationByField(field.fieldId).csp) }}
                    </td>
                    <td class="py-2" v-if="!field.noYearData">
                       {{ boolDisplay(getConservationByField(field.fieldId).non_deforestation) }}
                    </td>
                    <td class="py-2" v-if="!field.noYearData">
                       {{ boolDisplay(getConservationByField(field.fieldId).pay_for_practice) }}
                    </td>
                    <td class="py-2" v-if="!field.noYearData">
                       {{ boolDisplay(getConservationByField(field.fieldId).carbon_offset) }}
                    </td>
                    <td class="py-2" v-if="!field.noYearData">
                       {{ boolDisplay(getConservationByField(field.fieldId).other_scope_3) }}
                    </td>
                    <td class="py-2">
                      <v-col md="3">
                        <!-- Data Complete -->
                        <div
                          class="d-flex"
                          v-if="programsComplete(getConservationByField(field.fieldId))"
                        >
                        <v-icon
                          color="success"
                          >mdi-check-circle</v-icon
                        >
                          <span class="ml-2">Data Complete</span>
                        </div>
                        <!-- Missing Data -->
                        <div
                          v-else class="d-flex"
                        >
                          <v-tooltip top
                            ><template v-slot:activator="{ on, attrs }"
                              ><v-icon v-bind="attrs" v-on="on" color="red"
                                >mdi-alert-circle</v-icon
                              ></template
                            ><span
                              >This field is missing data. Please fill in
                              its data using the wizard.</span
                            ></v-tooltip
                          >
                          <span class="ml-2">Missing Data</span>
                        </div>
                      </v-col>
                    </td>
                    <td class="cursor" @click="handleCopy(field)">
                      <span class="blue--text lighten-1 text-bold">Copy</span>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- No Fields Selected Alert -->
      <AlertErrorModal
        v-if="alertErrorModalOpen"
        titleText="No Fields Selected"
        :errorMessage="[
          'You currently have no fields selected to save management practices for. Please select a field to continue.',
        ]"
        @close-modal="alertErrorModalOpen = false"
      />


      <ConfirmModal
        v-if="showSaveConfirmModal"
        titleText="Confirm Practice Replacement"
        :confirmText="saveConfirmText"
        @confirm="handleSave"
        @close-modal="showSaveConfirmModal = false"
      />

    </div>
    <div v-else>
      <v-card elevation="0">
        <v-card-text class="text-center">
          <span class="text-h4"
            >No Fields Selected. Please select a field to apply management
            practices.</span
          >
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import _ from "lodash"
import { mapActions, mapGetters, mapMutations, mapState } from "vuex"
import { Defaults, Organization } from "@/store/modules"
import {
  CROP_KEY,
  CROP_DISPLAY_NAME,
  CROP_ID_KEY,
} from "@/constants"
import { MAX_DEFAULT_FIELDS } from "@/constants/defaults"
import AlertErrorModal from "@/components/modals/AlertErrorModal"
import ConfirmModal from "@/components/modals/ConfirmModal"
import DefaultsAPI from "@/api/DefaultsAPI"

/**
 * Represents a conservation program entry.
 * @typedef {Object} ConservationProgram
 * @property {?boolean} rcpp - Indicates if the Regional Conservation Partnership Program is applicable.
 * @property {?boolean} cscg - Indicates if the Climate Smart Commodities Grant is applicable.
 * @property {?boolean} eqip - Indicates if the Environmental Quality Incentives Program is applicable.
 * @property {?boolean} csp - Indicates if the Conservation Stewardship Program is applicable.
 * @property {?boolean} non_deforestation - Indicates if non-deforestation practices are applicable.
 * @property {?boolean} pay_for_practice - Indicates if pay for practice is applicable.
 * @property {?boolean} carbon_offset - Indicates if carbon offset practices are applicable.
 * @property {?boolean} other_scope_3 - Indicates if other Scope 3 environmental initiatives are applicable.
 */

export default {
  name: "ConservationProgramWizard",
  props: {
    selectedFields: { type: Array },
    year: { type: String },
    yearList: { type: Array },
  },
  components: {
    AlertErrorModal,
    ConfirmModal,
  },
  data() {
    return {
      MAX_DEFAULT_FIELDS: MAX_DEFAULT_FIELDS,

      /** @type {ConservationProgram|null} */
      newConservationPrograms: null,
      /** @type {Object.<number, ConservationProgram>|null} */
      conservationByField: {},
      stackPractices: false,
      // table related
      showSelectedFields: true,
      fieldsInSelection: [],
      filterCrops: [],
      filterCropsReflection: [],
      tableFilterText: "",
      selectAllBox: true,
      alertErrorModalOpen: false,
      showSaveConfirmModal: false,
      loadState: null,
      //   new UI
      showPracticesView: false,
      showSaveView: false,
      showSummaryView: false,
      summaryFields: null,
      summaryKeys: null,
      summaryData: null,
      cropSelect: null,
      cropDisplay: CROP_DISPLAY_NAME,
    }
  },
  computed: {
    ...mapState({
      opsSettings: state => state.Defaults.newFieldSettings,
    }),
    ...mapGetters({
      recentWizardData: Defaults.Getters.getRecentWizardData,
      practiceChoices: Defaults.Getters.getPracticeChoices,
    }),
    dataCompletionForYear() {
      if (!this.year || this.selectedFields.length == 0 || this.conservationByField == {}) {
        return 0
      }

      let completeFieldsCount = 0;
      for (const field of this.selectedFields) {
        if (this.programsComplete(this.getConservationByField(field.id))) {
          completeFieldsCount += 1;
        }
      }
      return Math.ceil((completeFieldsCount / this.selectedFields.length) * 100);
    },
    fieldLocks() {
      let fieldLocks = {}
      for (const field of this.opsSettingsByField) {
        fieldLocks[field.fieldId] = field.fieldLocked
      }
      return fieldLocks
    },
    saveConfirmText() {
      return `
        Are you sure you want to replace conservation program documentation on the ${this.fieldsInSelection.length} selected fields for ${this.year}?
      `
    },
    opsSettingsByField() {
      let opsDict = {}
      let yearInConsideration = this.year
      Object.entries(this.opsSettings).forEach(opsDefault => {
        const fieldKey = opsDefault[0]

        const selField = this.selectedFields.find(field => field.id == fieldKey)
        if (!selField) {
          return
        }
        opsDict[fieldKey] = {}
        const fieldSettingObj = opsDefault[1]
        const idSettings = {
          fieldId: fieldKey,
          fieldName: selField.name,
          farmName: selField.farm.name,
          fieldImg: selField.boundary_image,
        }

        const hasYearData = fieldSettingObj.year_data.some(
          y => y.year == yearInConsideration
        )

        fieldSettingObj.year_data.forEach(yearData => {
          if (!hasYearData) {
            opsDict[fieldKey] = idSettings
            opsDict[fieldKey]["crops"] = ["No Data"]
            opsDict[fieldKey]["cropNames"] = ["No Data"]
            opsDict[fieldKey]["noYearData"] = true
          } else if (yearData.year == yearInConsideration) {
            opsDict[fieldKey] = idSettings
            opsDict[fieldKey]["crops"] = []
            opsDict[fieldKey]["cropNames"] = []
            opsDict[fieldKey]["cropSearch"] = ""
            opsDict[fieldKey]["plantings"] = []

            opsDict[fieldKey]["isSelected"] = this.fieldsInSelection.includes(
              parseInt(fieldKey)
            )
              ? true
              : false

            const cultivations = yearData.cultivations.sort(
              (a, b) => a.arva_crop_id - b.arva_crop_id
            )
            cultivations.forEach(cultivation => {
              opsDict[fieldKey]["crops"].push(cultivation.arva_crop_id)
              opsDict[fieldKey]["cropNames"].push(
                CROP_DISPLAY_NAME[cultivation.arva_crop_id]
              )
              opsDict[fieldKey]["cropSearch"] =
                opsDict[fieldKey]["cropSearch"] +
                CROP_DISPLAY_NAME[cultivation.arva_crop_id]
              opsDict[fieldKey]["plantings"] = opsDict[fieldKey][
                "plantings"
              ].concat(cultivation.plantings)

              opsDict[fieldKey]["crops"].sort()
              opsDict[fieldKey]["cropNames"].sort()
              opsDict[fieldKey]["fieldLocked"] = cultivation.year_data_locked
              opsDict[fieldKey]["noYearData"] = false
            })
          }
        })
      })
      return Object.values(opsDict)
    },
    opsSettingsByFieldFiltered() {
      // returns sorted list for table, only reduces list when filtering by name
      let arrayToReturn = _.cloneDeep(this.opsSettingsByField)

      if (this.tableFilterText) {
        const searchString = this.tableFilterText.toLocaleLowerCase()
        arrayToReturn = arrayToReturn.filter(field =>
          field.fieldName.toLowerCase().includes(searchString)
        )
      }

      if (this.cropSelect) {
        arrayToReturn = arrayToReturn.filter(field => {
          const found = this.cropSelect.every(
            c => field.crops.indexOf(parseInt(c)) >= 0
          )
          return found
        })
      }

      const sortColumns = ["fieldName"]
      const sortDirections = ["desc", "asc"]
      return _.orderBy(arrayToReturn, sortColumns, sortDirections)
    },
    historicalPlantings() {
      const something = _(this.opsSettingsByField)
        .groupBy("crops")
        .map((items, crops) => ({
          crops: crops,
          fieldIds: _.map(items, "fieldId"),
          fieldNames: _.map(items, "fieldName"),
        }))
        .value()
      return something
    },
    yearListModified() {
      return this.yearList.filter(o => !isNaN(o))
    },
  },
  methods: {
    ...mapActions({
      updateYear: Organization.Actions.updateYear,
      // this stuff doesn't exist in state now, but it would if we want to show it in the map
      updateConservationPrograms: Defaults.Actions.updateConservationPrograms,
    }),
    ...mapMutations({
      setRecentWizardData: Defaults.Mutations.setRecentWizardData,
    }),
    boolDisplay(val) {
      if (val === true) {
        return "Yes"
      } else if (val === false) {
        return "No"
      } else {
        return ""
      }
    },
    selectNoPrograms() {
      for (const key in this.newConservationPrograms) {
        this.newConservationPrograms[key] = false
      }
    },
    getConservationByField(fieldId) {

      if (!(this.year in this.conservationByField)) {
        this.$set(this.conservationByField, this.year, {})
      }

      if (fieldId in this.conservationByField[this.year]) {
        return this.conservationByField[this.year][fieldId]
      } else {
        const blankConservationProgramData = this.blankConservationProgramData()
        this.$set(this.conservationByField[this.year], fieldId, blankConservationProgramData)
        return this.conservationByField[this.year][fieldId]
      }
    },
    disableCheckbox(noYearData) {
      if (noYearData) {
        return true
      } else {
        return false
      }
    },
    fieldChecked(field, e) {
      if (
        this.fieldsInSelection.includes(parseInt(field.fieldId)) &&
        e == false
      ) {
        this.fieldsInSelection = this.fieldsInSelection.filter(
          f => f != parseInt(field.fieldId)
        )
      }
      if (e == true) {
        this.fieldsInSelection.push(parseInt(field.fieldId))
      }
    },
    /*
      * @param {ConservationProgram} programs
      * @returns {boolean}
    */
    programsComplete(programs) {
      if (!this.stackPractices) {
        return (
          programs.rcpp != null &&
          programs.cscg != null &&
          programs.eqip != null &&
          programs.csp != null &&
          programs.non_deforestation != null &&
          programs.pay_for_practice != null &&
          programs.carbon_offset != null &&
          programs.other_scope_3 != null
        )
      } else {
        return (
          programs.rcpp != null ||
          programs.cscg != null ||
          programs.eqip != null ||
          programs.csp != null ||
          programs.non_deforestation != null ||
          programs.pay_for_practice != null ||
          programs.carbon_offset != null ||
          programs.other_scope_3 != null
        )
      }

    },
    handleAddPracticesClick() {
      if (this.showPracticesView == false) {
        this.showSaveView = false
        this.showSummaryView = false
        this.showPracticesView = true

        this.newConservationPrograms = this.blankConservationProgramData()
      }
    },
    handleCancelClick() {
      this.showSaveView = false
      this.showSummaryView = false
      this.showPracticesView = false
      this.newConservationPrograms = this.blankConservationProgramData()
    },

    blankConservationProgramData() {
      return {
        rcpp: null,
        cscg: null,
        eqip: null,
        csp: null,
        non_deforestation: null,
        pay_for_practice: null,
        carbon_offset: null,
        other_scope_3: null,
      }
    },
    handleCropFilterButton(sharedCropPlan) {
      if (
        JSON.stringify(this.cropSelect) ==
        JSON.stringify(sharedCropPlan.crops.split(","))
      ) {
        this.cropSelect = null
        this.filterCrops = []
      } else {
        this.cropSelect = sharedCropPlan.crops.split(",")
        this.filterCrops = [this.mapCropNames(sharedCropPlan.crops)]
      }
    },
    mergeFieldPayload(field, programPayload) {
      if (!this.stackPractices) {
        const merged =  {...programPayload, field: Number(field.fieldId), year: Number(this.year)};
        return merged;
      }

      let mergedPayload = this.getConservationByField(field.fieldId)

      for (const key in programPayload) {
        if (programPayload[key] != null) {
          mergedPayload[key] = programPayload[key]
        }
      }

      return {...mergedPayload, field: Number(field.fieldId), year: Number(this.year)};
    },
    handleCopy(field) {
      this.cropSelect = null

      this.newConservationPrograms = _.cloneDeep(this.getConservationByField(field.fieldId))
      this.showSummaryView = false
      this.showPracticesView = true

      const myElement = document.getElementById("input-card-container")
      myElement.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      })
    },
    handlePreSave() {
      if (this.stackPractices) {
        this.handleSave()
      } else {
        this.showSaveConfirmModal = true
      }
    },
    async handleSave() {
      this.showSaveConfirmModal = false
      if (this.fieldsInSelection.length == 0) {
        this.alertErrorModalOpen = true
        return
      }

      let programPayload = _.cloneDeep(this.newConservationPrograms)

      this.showPracticesView = false
      this.showSaveView = true

      const updatePrograms = this.opsSettingsByFieldFiltered.map(
        async field => {
          if (this.fieldsInSelection.includes(parseInt(field.fieldId))) {

            if (!field.noYearData && !field.fieldLocked) {
              return DefaultsAPI.updateConservationProgramData(
                this.mergeFieldPayload(field, programPayload),
              )
            } else {
              console.log("no year data for ", field)
            }
          }
        }
      )
      await Promise.all(updatePrograms)

      const fieldNames = this.opsSettingsByFieldFiltered
        .filter(f => this.fieldsInSelection.includes(parseInt(f.fieldId)))
        .map(f => f.fieldName)
      const programKeys = ["rcpp", "cscg", "eqip", "csp", "non_deforestation", "pay_for_practice", "carbon_offset", "other_scope_3"]

      this.summaryData = programPayload
      this.summaryKeys = programKeys
      this.summaryFields = fieldNames
      this.showSaveView = false
      this.showSummaryView = true

      this.newConservationPrograms = this.blankConservationProgramData()
      this.stackPractices = false

      this.fetchConservationProgramData()
      this.$emit("update-data-complete")
    },
    handleSeasonSelection(newYear) {
      this.updateYear(newYear)
    },
    handleSelectAll(e, resetCropSelect = false) {
      if (e == false) this.fieldsInSelection = []
      if (e == true) {
        this.fieldsInSelection = this.selectedFields
          .map(f => f.id)
          .filter(f => !this.fieldLocks[f])
      }
      if (resetCropSelect) {
        this.cropSelect = null
      }
    },
    mapCropNames(cropIdString) {
      if (cropIdString == "No Data") {
        return cropIdString
      } else if (cropIdString.includes(",")) {
        const cropIdVals = cropIdString.split(",")
        return cropIdVals.map(v => this.cropDisplay[v]).join(", ")
      } else {
        return this.cropDisplay[cropIdString]
      }
    },
    handleAddMore() {
      this.showSummaryView = false
      this.showPracticesView = true
    },
    async fetchConservationProgramData() {
      const response = await DefaultsAPI.listConservationProgramData()
      for (const program of response.data) {
        if (!(program.year in this.conservationByField)) {
          this.$set(this.conservationByField, program.year, {})
        }
        this.$set(this.conservationByField[program.year], program.field, program)
      }
    },
  },

  mounted() {
    this.fieldsInSelection = this.selectedFields
      .map(f => f.id)
      .filter(f => !this.fieldLocks[f]);
    this.fetchConservationProgramData();
  },
  watch: {
    filterCrops: {
      deep: true,
      handler(myArray) {
        this.filterCropsReflection = _.cloneDeep(myArray)

        let arrayToFilter = _.cloneDeep(this.opsSettingsByField)

        if (this.filterCropsReflection.length > 0) {
          let cropsToFilter = _.flatten(
            this.filterCropsReflection.map(i => {
              return i.split(", ")
            })
          )
          arrayToFilter = arrayToFilter.filter(field =>
            cropsToFilter.every(c => field.cropNames.indexOf(c) >= 0)
          )
        }

        this.fieldsInSelection = arrayToFilter
          .map(f => parseInt(f.fieldId))
          .filter(f => !this.fieldLocks[f])
      },
    },
  },
}
</script>
<style scoped>
.min-step-height {
  min-height: 400px;
}

.row-margin-correct {
  margin-left: 0px;
  margin-right: 0px;
}

.text-container {
  width: 200px;
}

.three {
  width: 3%;
}

.ten {
  width: 10%;
}

.cursor {
  cursor: pointer;
}
</style>
