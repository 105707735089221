<template>
  <div>
    <div class="d-flex ml-auto mr-2">
      <v-btn class="mx-2" @click="handleFSAClick()"><v-icon>mdi-tag-outline</v-icon>Set FSA Data</v-btn>
      <v-btn class="mx-2" @click="handleExportClick()"><v-icon>mdi-export</v-icon>Export CSV</v-btn>
      <v-btn class="mx-2" @click="handleImportClick()"><v-icon>mdi-import</v-icon>Import From CSV</v-btn>
    </div>
    <ArvaModal v-if="showConfirmExportModal" name="confirmModal" title="Confirm CSV Export"
      @close-modal="showConfirmExportModal = false">
      <div v-html="confirmMessage" class="confirm-text"></div>
      <v-switch class="mx-8" v-model="filterYears" label="Filter years"></v-switch>
      <v-select class="mx-8" v-if="filterYears" v-model="selectedYears" :items="availableYears" dense multiple
        label="Years">
        <template v-slot:prepend-item>
          <v-list-item ripple @mousedown.prevent @click="toggleSelectAll">
            <v-list-item-action>
              <v-icon :color="selectedYears.length > 0 ? 'indigo darken-4' : ''">
                {{ selectAllIcon }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Select All
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mt-2"></v-divider>
        </template>
      </v-select>
      <ArvaModalSaveFooter confirmText="Export" cancelText="Cancel" @on-cancel="showConfirmExportModal = false"
        @on-confirm="handleSubmitExport" />
    </ArvaModal>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import { Filter } from "@/store/modules"
import ArvaModal from "@/components/modals/ArvaModal"
import ArvaModalSaveFooter from "@/components/modals/ArvaModalSaveFooter"
import DefaultsAPI from "@/api/DefaultsAPI"

export default {
  name: "WizardToolBar",
  components: {
    ArvaModal,
    ArvaModalSaveFooter
  },
  methods: {
    handleImportClick() {
      this.$router.push(`/uploads`)
    },
    handleFSAClick() {
      this.$router.push(`/fsa-data-table`)
    },
    handleExportClick() {
      this.showConfirmExportModal = true;
    },
    toggleSelectAll() {
      this.$nextTick(() => {
        if (this.selectedYears.length == this.availableYears.length) {
          this.selectedYears = []
        } else {
          this.selectedYears = this.availableYears.slice()
        }
      })
    },
    async handleSubmitExport() {
      const payload = { "field_ids": this.selectedFields.map(f => f.id) }
      if (this.filterYears) {
        payload["selected_years"] = this.selectedYears;
      }
      await DefaultsAPI.exportPracticesCSV(payload).catch(() => {
        alert('There was a permissioning error exporting the CSV.');
      });
      this.showConfirmExportModal = false;
    }
  },
  data: () => ({
    showConfirmExportModal: false,
    filterYears: false,
    selectedYears: []
  }),
  computed: {
    ...mapGetters({
      selectedFields: Filter.Getters.getSelectedFields,
    }),

    ...mapState({
      userEmail: (state) => state.User.user.email,
      selectedFieldIds: (state) => state.Organization.organization.years,
      availableYears: state => state.Organization.organization.years,
    }),

    confirmMessage() {
      return `Confirm to export <strong>${this.selectedFields.length}</strong> selected fields to your email at <strong>${this.userEmail}</strong>. Please ensure this email address is secure. Arva Intelligence is not liable for data privacy when using this feature.`;
    },

    selectAllIcon() {
      if (this.selectedYears.length == this.availableYears.length)
        return 'mdi-close-box'
      if (this.selectedYears.length > 0)
        return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    }
  },
  mounted() {
    this.selectedYears = this.availableYears;
  }
};
</script>
<style scoped>
.confirm-text {
  font-size: 15px;
  padding: 30px 30px 0 30px;
}
</style>
