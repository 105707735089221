<template>
  <ArvaModal
    name="createNewTransactionModal"
    title="Create New Data Sharing Request"
    :wide="true"
    :width="1000"
    @close-modal="handleCloseModal"
  >
    <!-- From Section -->
    <div class="highlighted-section">
      <div class="row ml-3">
        <div class="col">
          <span class="section-title">From</span>
        </div>
      </div>
      <div class="row ml-3">
        <div class="col">
          <span
            class="default-input-title"
            :class="{ 'invalid-title': !isFromCorporationValid }"
            >Corporation</span
          >
          <v-select
            v-model="selectedFromCorporation"
            :items="fromCorpOptions"
            item-text="name"
            item-value="id"
            :clearable="true"
            required
          ></v-select>
        </div>
        <div class="col">
          <span
            class="default-input-title"
            :class="{ 'invalid-title': !isFromOrganizationValid }"
            >Organization</span
          >
          <v-select
            v-model="selectedFromOrg"
            :items="organizationOptions"
            item-text="name"
            item-value="id"
            :clearable="true"
            required
          >
            <template #item="{ item, attrs, on }">
              <div :style="locationItemStyle(item)" v-bind="attrs" v-on="on">
                {{ item.name }}
              </div>
            </template>
          </v-select>
        </div>
      </div>
    </div>

    <!-- To Section -->
    <div class="highlighted-section">
      <div class="row ml-3">
        <div class="col">
          <span class="section-title">To</span>
        </div>
      </div>
      <div class="row ml-3">
        <div class="col">
          <span
            class="default-input-title"
            :class="{ 'invalid-title': !isToCorporationValid }"
            >Corporation</span
          >
          <div class="input-group">
            <v-select
              v-model="selectedToCorporation"
              :items="toCorpOptions"
              item-text="name"
              item-value="id"
              :clearable="true"
              required
            ></v-select>
          </div>
        </div>
        <div class="col">
          <span
            class="default-input-title"
            :class="{ 'invalid-title': !isToOrganizationValid }"
          >
            Organization</span
          >
          <v-select
            v-model="selectedToOrg"
            :items="orgOptionsByCorp"
            item-text="name"
            item-value="id"
            :clearable="true"
            required
          >
            <template #item="{ item, attrs, on }">
              <div :style="locationItemStyle(item)" v-bind="attrs" v-on="on">
                {{ item.name }}
              </div>
            </template>
          </v-select>
        </div>
      </div>
    </div>

    <!-- Selected Package(s) and Year(s) Section -->
    <div class="row justify-content-between ml-5 mt-8">
      <div class="col-6">
        <span
          class="default-input-title"
          :class="{ 'invalid-title': !isPackageValid }"
          >Select Package(s) To Share</span
        >
        <v-select
          v-model="selectedPackages"
          :items="
            corporation.package.length > 0 ? corporation.package : ['Base']
          "
          multiple
        ></v-select>
      </div>
      <div class="col-6">
        <span
          class="default-input-title"
          :class="{ 'invalid-title': !isYearValid }"
          >Select Year(s) To Share</span
        >
        <v-select
          v-model="selectedYears"
          :items="yearOptions"
          multiple
        ></v-select>
      </div>
    </div>

    <!-- Expiration Date and Permission Section -->
    <div class="row justify-content-between ml-5 mt-8">
      <div class="col-3">
        <span
          class="default-input-title"
          :class="{ 'invalid-title': !isExpirationDateValid }"
          >Expiration Date</span
        >
        <v-menu offset-y min-width="auto">
          <template #activator="{ on }">
            <v-text-field
              v-model="selectedExpirationDate"
              readonly
              v-on="on"
              :clearable="true"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="selectedExpirationDate"
            :min="minDate"
            @input="showDate = false"
            scrollable
          ></v-date-picker>
        </v-menu>
      </div>
      <div class="col-6">
        <span
          class="default-input-title"
          :class="{ 'invalid-title': !isPermissionValid }"
          >Select Permission</span
        >
        <v-select
          v-model.trim="selectedPermission"
          :items="permissionOptions"
          solo
          :clearable="true"
        ></v-select>
      </div>
    </div>

    <!-- Save Footer Section -->
    <div class="row ml-3">
      <ArvaModalSaveFooter
        confirmText="Create"
        cancelText="Cancel"
        @on-cancel="handleCloseModal"
        @on-confirm="confirmChanges"
      />
    </div>
  </ArvaModal>
</template>

<style scoped>
.highlighted-section {
  background-color: #939695;
  padding: 10px;
  margin-bottom: 20px;
}
.section-title {
  font-weight: bold;
}
.default-input-title {
  font-weight: bold;
  margin-top: 8px;
  display: block;
}

.invalid-title {
  color: red;
}
</style>

<script>
import ArvaModal from "@/components/modals/ArvaModal"
import ArvaModalSaveFooter from "@/components/modals/ArvaModalSaveFooter"

export default {
  name: "CreateNewTransactionModal",
  props: [
    "userId",
    "yearOptions",
    "corporation",
    "organizationOptions",
    "toCorpOptions",
    "toOrgOptions",
  ],
  components: {
    ArvaModal,
    ArvaModalSaveFooter,
  },

  data() {
    return {
      panel: [0, 1],
      disabled: false,
      permissionOptions: ["Read-Only", "Read/Write"],
      selectedFromCorporation: null,
      selectedToCorporation: null,
      selectedFromOrg: null,
      selectedToOrg: null,
      selectedPermission: null,
      selectedPackages: [],
      selectedExpirationDate: null,
      showDate: false,
      minDate: new Date().toISOString().substr(0, 10), // Format: YYYY-MM-DD
      selectedYears: [],

      isFromCorporationValid: true,
      isFromOrganizationValid: true,
      isToCorporationValid: true,
      isToOrganizationValid: true,
      isPackageValid: true,
      isYearValid: true,
      isExpirationDateValid: true,
      isPermissionValid: true,
    }
  },

  computed: {
    fromCorpOptions() {
      return [this.corporation]
    },
    orgOptionsByCorp() {
      if (this.selectedToCorporation) {
        return this.toOrgOptions[this.selectedToCorporation]
      }
      return []
    },
  },

  watch: {
    selectedFromOrg() {
      this.isFromOrganizationValid = !!this.selectedFromOrg
    },
    selectedToOrg() {
      this.isToOrganizationValid = !!this.selectedToOrg
    },
    selectedFromCorporation() {
      this.isFromCorporationValid = !!this.selectedFromCorporation
    },
    selectedToCorporation() {
      this.isToCorporationValid = !!this.selectedToCorporation
    },
    selectedPackages() {
      this.isPackageValid = this.selectedPackages.length > 0
    },
    selectedYears() {
      this.isYearValid = this.selectedYears.length > 0
    },
    selectedExpirationDate() {
      this.isExpirationDateValid = !!this.selectedExpirationDate
    },
    selectedPermission() {
      this.isPermissionValid = !!this.selectedPermission
    },
  },

  methods: {
    locationItemStyle(item) {
      if (!item.depth) return null

      return item.depth > 1
        ? { "padding-left": item.depth + 1 + ".75em" }
        : null
    },

    handleCloseModal() {
      this.$emit("close-modal")
    },

    validateInputs() {
      this.isFromCorporationValid = !!this.selectedFromCorporation
      this.isFromOrganizationValid = !!this.selectedFromOrg
      this.isToCorporationValid = !!this.selectedToCorporation
      this.isToOrganizationValid = !!this.selectedToOrg
      this.isPackageValid = this.selectedPackages.length > 0
      this.isYearValid = this.selectedYears.length > 0
      this.isExpirationDateValid = !!this.selectedExpirationDate
      this.isPermissionValid = !!this.selectedPermission

      return (
        !this.isFromOrganizationValid ||
        !this.isToCorporationValid ||
        !this.isToOrganizationValid ||
        !this.isPackageValid ||
        !this.isYearValid ||
        !this.isExpirationDateValid ||
        !this.isPermissionValid
      )
    },

    confirmChanges() {
      const hasError = this.validateInputs()
      if (!hasError) {
        const payload = {
          requested_by: this.userId,
          from_org: this.selectedFromOrg,
          to_org: this.selectedToOrg,
          to_corp: this.selectedToCorporation,
          packages: this.selectedPackages,
          years: this.selectedYears,
          expiration_date: this.selectedExpirationDate,
          permission: this.selectedPermission,
        }
        this.$emit("save-changes", payload)
      }
    },
  },
}
</script>
