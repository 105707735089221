<template>
  <BoundingBox v-if="selecting" @select-boundaries="selectBoundaries" />
</template>

<script>
import BoundingBox from "./BoundingBox"

export default {
  name: "BoundingBoxSelector",
  components: { BoundingBox },
  data() {
    return {
      selecting: false,
      isDeselectMode: false,
    }
  },
  methods: {
    addSKeyEventListeners() {
      const S_KEY_CODE = 83
      const D_KEY_CODE = 68
      document.addEventListener("keydown", e => {
        if (e.keyCode === S_KEY_CODE) {
          if (!this.selecting) this.selecting = true
          this.isDeselectMode = false
        } else if (e.keyCode === D_KEY_CODE) {
          if (!this.selecting) this.selecting = true
          this.isDeselectMode = true
        }
      })
      document.addEventListener("keyup", e => {
        if (e.keyCode === S_KEY_CODE || e.keyCode === D_KEY_CODE) {
          if (this.selecting) this.selecting = false
        }
      })
    },

    selectBoundaries(boundingBox) {
      this.$emit("selectBoundaries", boundingBox, this.isDeselectMode)
    },
  },
  mounted() {
    this.addSKeyEventListeners()
  },
}
</script>

<style scoped></style>
