import API from "./API"

const fetchYieldZones = payload => API.post("yield-zones/", payload)

const getYieldZones = payload =>
  API.get("/yield-zones-data/", {
    params: payload,
    // paramsSerializer: function(params) {
    //   return qs.stringify(params, { arrayFormat: "repeat" })
    // },
  })

const postYieldZones = payload => API.post("/yield-zones-data/", payload)

const updateYieldZones = payload =>
  API.put(`/yield-zones-data/${payload.id}/`, { payload })

const deleteYieldZone = payload => API.delete(`yield-zones-data/${payload.id}/`)

const fetchYieldZonesExport = payload =>
  API.post("/yield-zones-export/", payload)

export default {
  fetchYieldZones,
  getYieldZones,
  postYieldZones,
  updateYieldZones,
  deleteYieldZone,
  fetchYieldZonesExport,
}
