<template>
  <div class="placement-row">
    <div class="d-flex align-items-center">
      <div class="fields-count text-center">
        <div class="clickable">
          {{ planInfo.field_list.length }}
        </div>
        <div>Fields</div>
      </div>
      <div class="divider" />
      <div>
        <div class="placement-name">{{ planInfo.name }}</div>
        <div class="placement-date">
          Created on: {{ planInfo.created_at | date }}
        </div>
      </div>
      <div class="actions">
        <div>
          <router-link
            :to="{
              name: 'seed-placement-plan',
              params: { placementPlanId: planInfo.id },
            }"
          >
            {{ actionLabel }} <i class="fa fa-arrow-circle-right"
          /></router-link>
        </div>
        <div v-if="!isReadOnly">
          <i class="pull-right fa fa-times-circle" @click="deletePlan" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex"

import { Placements } from "@/store/modules"
import SeedSelectorAPI from "@/api/SeedSelectorAPI"

export default {
  name: "PlacementRow",
  props: {
    planInfo: { type: Object },
  },

  computed: {
    ...mapState({
      isReadOnly: state => state.User.user.permission === "Read-Only",
    }),

    actionLabel() {
      console.log("isReadOnly", this.isReadOnly)
      return this.isReadOnly ? "View" : "View/Edit"
    },
  },

  methods: {
    ...mapActions({
      fetchSeedPlacementPlans: Placements.Actions.fetchSeedPlacementPlans,
    }),

    async deletePlan() {
      await SeedSelectorAPI.deleteSeedPlan(this.planInfo.id)
      await this.fetchSeedPlacementPlans()
    },
  },
}
</script>
<style scoped>
.clickable {
  cursor: pointer;
}

.placement-row {
  padding: 18px;
  border-top: 1pt solid #d5d5d5;
}

.placement-row:hover {
  background: #fafafa;
}

.fields-count {
  padding: 0px 15px;
}

.fields-count > div:first-of-type {
  font-weight: bold;
  font-size: 18px;
}

.divider {
  height: 40px;
  width: 1px;
  border-left: 1pt solid #e0e0e0;
  display: block;
  margin: auto 18px;
}

.placement-name {
  color: #333;
  font-size: 15px;
  font-weight: 500;
}

.placement-date {
  font-size: 13px;
}

.placement-user {
  font-size: 13px;
}

.actions {
  flex: 1;
  text-align: right;
  color: #0095ff;
  font-weight: 500;
  cursor: pointer;
}

.actions > div:nth-child(2) {
  color: #e60000;
}

.actions:hover {
  color: #888;
}
</style>
