<template>
  <ArvaModal
    :name="name"
    title="Set Seeding Rates by Crop"
    @close-modal="handleClose"
  >
    <div class="px-2 py-2">
      <div
        v-for="crop in Object.keys(seedingRates)"
        class="row mx-0"
        :key="crop"
      >
        <div class="col-5 text-bold">
          <span class="mr-1">
            <img class="crop-icon" :src="cropIconUrls[crop]" />
          </span>
          {{ crop | capitalize }}
        </div>

        <div class="col-7">
          <div class="input-group">
            <input
              type="number"
              class="form-control"
              :value="seedingRates[crop]"
              @blur="handleInputBlur($event, crop)"
            />
            <div class="input-group-append group-width">
              <span class="input-group-text append-text-width">{{
                crop | cropKeySeedUnits
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          type="button"
          class="btn btn-primary btn-sm ml-auto mr-4"
          @click="handleClose"
        >
          Ok
        </div>
      </div>
    </div>
  </ArvaModal>
</template>

<script>
import { mapActions, mapState } from "vuex"

import ArvaModal from "./ArvaModal"
import { CROP_KEY, CROP_KEY_TO_ICON_URL } from "@/constants"
import { Dashboard } from "@/store/modules"

export default {
  name: "SeedingRatesModal",

  components: {
    ArvaModal,
  },

  data() {
    return {
      name: "seedingRatesModal",
      cropIconUrls: CROP_KEY_TO_ICON_URL,
      cropKeys: CROP_KEY,
    }
  },

  computed: {
    ...mapState({
      seedingRates: state => state.Dashboard.seedingRates,
    }),
  },

  methods: {
    ...mapActions({ updateSeedingRate: Dashboard.Actions.updateSeedingRate }),

    handleClose() {
      this.$emit("close-template-modal")
    },

    handleInputBlur(e, crop) {
      this.updateSeedingRate({
        seedingRate: e.target.valueAsNumber,
        crop: crop,
      })
    },
  },
}
</script>
<style scoped>
.crop-icon {
  width: 30px;
  height: 30px;
}

.group-width {
  width: 30%;
}

.append-text-width {
  width: 100%;
}
</style>
