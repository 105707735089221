<template>
  <div>
    <Permissions package="seed-selector" />
    <div v-if="showSeedSelector">
      <div class="d-flex justify-content-between">
        <DashboardTitle title="Seed Selector" faClass="fa-envira" />
      </div>

      <div class="d-flex mt-1">
        <div class="d-flex">
          <div class="mx-3">
            <span class="top-input"
              >Relative Maturity
              <i
                class="fa fa-info-circle"
                data-toggle="tooltip"
                data-placement="top"
                title="Adjust the sliders to filter results by their relative maturity."
            /></span>
            <v-range-slider
              class="slider"
              v-model="relativeMaturity"
              strict
              :min="70"
              :max="130"
              :width="175"
              :tick-labels="[70, ...Array(130 - 70 - 2).fill(''), 130]"
              :thumb-label="true"
            />
          </div>
          <div class="ml-4">
            <div>
              Company Focus
              <i
                class="fa fa-info-circle"
                data-toggle="tooltip"
                data-placement="top"
                title="Select a company to add their seed varieties to the results, even if the results are not in the top 3 hybrids for that field."
              />
            </div>
            <v-select-old
              class="select-style"
              label="name"
              multiple
              :style="{ width: '360px' }"
              :options="seedCompanyOptions"
              :reduce="o => o.id"
              v-model="companyFocus"
            />
          </div>
          <div class="ml-4 filter-results">
            <div>
              Filter Results
              <i
                class="fa fa-info-circle"
                data-toggle="tooltip"
                data-placement="top"
                title="Turn this toggle on to see results from a single company."
              />
            </div>
            <div
              class="toggle-checkbox toggle-sm toggle-success mr-2 pt-1 d-flex"
            >
              <input
                type="checkbox"
                v-model="filterResults"
                id="filterResults"
                name="filterResults"
                class="checked mx-3"
              />
              <label for="filterResults" class="form-check-label" />
            </div>
          </div>
        </div>
        <div class="d-flex ml-auto mt-auto">
          <div class="ml-4">
            <button
              class="btn btn-default btn-sm download-text"
              @click="seedRateModalOpen = true"
            >
              <span>Seeding Rates</span>
            </button>

            <SeedingRatesModal
              v-if="seedRateModalOpen"
              :seedingRates="seedingRates"
              @close-template-modal="closeSeedRateModal"
            />
          </div>
          <div class="ml-2">
            <div
              v-if="!isReadOnly"
              type="button"
              class="btn btn-primary btn-sm download-text"
              :class="{ disabled: isPdfEmpty }"
              @click="handlePdfDownload"
            >
              Download Seed Plan <i class="fa fa-arrow-circle-o-down" />
            </div>
          </div>
        </div>
      </div>

      <v-simple-table
        class="selector-table"
        fixed-header
        height="calc(100vh - 250px)"
      >
        <thead>
          <tr>
            <th></th>
            <th>Field</th>
            <th class="text-right">Acreage</th>
            <!--<th class="hover" @click="handleStateSort">
            State
            <i
              class="fa"
              :class="sortIcon(stateSort)"
              @click="handleStateSort"
            />
          </th>-->
            <th>Crop</th>
            <th>Irrigation Type</th>
            <th>
              Risk Tolerance
              <i
                class="fa fa-info-circle"
                data-toggle="tooltip"
                data-placement="top"
                title="Selecting workhorse preferences seed that performed relatively well when trial conditions were poor. Selecting racehorse seeds preferences seeds that performed relatively well when trial conditions were good."
              />
            </th>
            <th>
              <div class="nowrap">Recommended Hybrids</div>
              <div class="selected-hybrid nowrap d-flex align-items-center">
                <div />
                Selected Hybrid
              </div>
            </th>
            <th>
              Est. Yield Imp.
              <i
                class="fa fa-info-circle"
                data-toggle="tooltip"
                data-placement="top"
                title="Yield improvement In Selected Risk Conditions"
              />
            </th>

            <th>
              Est Yield Imp. Range
              <i
                class="fa fa-info-circle"
                data-toggle="tooltip"
                data-placement="top"
                title="Relative Yield Improvement In Poor Environmental Conditions (Left) vs Relative Improvement in Ideal Environmental Conditions (Right)"
              />
            </th>
            <th># Observations Used</th>
            <th>Avg. Trial Distance (km)</th>
            <th>Total Bags</th>
          </tr>
        </thead>
        <tbody>
          <SeedSelectorRow
            v-for="field in fieldRows"
            :key="field.id"
            :field="field"
            :seedingRate="seedingRate"
            :seedingRates="seedingRates"
            :companyFocus="companyFocus"
            :filterResults="filterResults"
            :relativeMaturity="relativeMaturity"
            @crop-cleared="handleCropClear"
            @update-pdf-post="handleRowSelect"
          />
        </tbody>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import _ from "lodash"

import DashboardTitle from "@/components/dashboard/DashboardTitle"
import SeedSelectorRow from "@/components/seedSelector/SeedSelectorRow"
import SeedingRatesModal from "@/components/modals/SeedingRatesModal"
import { Filter } from "@/store/modules"
import { fetchSeedSelectorHash } from "@/api/SeedSelectorReportAPI"
import Permissions from "@/components/permissions/Permissions"

export default {
  name: "SeedSelector",

  components: {
    DashboardTitle,
    SeedingRatesModal,
    SeedSelectorRow,
    Permissions,
  },

  data() {
    return {
      stateSort: null,
      seedingRate: null,
      seedRateModalOpen: false,
      companyFocus: null,
      filterResults: false,
      pdfData: {},
      relativeMaturity: [70, 130],
    }
  },

  computed: {
    ...mapGetters({
      activeFields: Filter.Getters.getSelectedFields,
    }),

    ...mapState({
      isReadOnly: state => state.User.user.permission === "Read-Only",
      organization: state => state.Organization.organization,
      seedCompanyOptions: state => state.Dashboard.seedCompanies,
      seedingRates: state => state.Dashboard.seedingRates,
      showSeedSelector: state =>
        state.Organization.organization.showSeedSelector,
    }),

    fieldRows() {
      const activeFields = [...this.activeFields]
      return _.orderBy(activeFields, ["region_name"], [this.stateSort])
    },

    isPdfEmpty() {
      return _.isEmpty(this.pdfData)
    },
  },

  methods: {
    closeSeedRateModal() {
      this.seedRateModalOpen = false
    },

    handleCropClear(field) {
      this.pdfData = _.omit(this.pdfData, [field.name])
    },

    handlePdfDownload() {
      if (this.isPdfEmpty) return
      const pdfPost = {
        pdf_data: this.pdfData,
        organization: this.organization.corporation.name,
      }
      fetchSeedSelectorHash(pdfPost).then(response => {
        const url = response.data
        window.open(url, "_blank")
      })
    },

    handleStateSort() {
      if (!this.stateSort) this.stateSort = "asc"
      else if (this.stateSort === "asc") this.stateSort = "desc"
      else if (this.stateSort === "desc") this.stateSort = null
    },

    handleRowSelect(
      field,
      hybrid,
      hybridCompany,
      bags,
      nextCrop,
      riskTolerance,
      client
    ) {
      const fieldSeedObj = {
        acreage: field.acreage,
        bags: bags,
        client: client,
        farm: field.farm.name,
        hybrid: hybrid,
        hybrid_company: hybridCompany,
        id: field.id,
        next_crop: nextCrop,
        risk_tolerance: riskTolerance,
      }
      this.pdfData = Object.assign({}, this.pdfData, {
        [field.name]: fieldSeedObj,
      })
    },

    sortIcon(sortValue) {
      if (sortValue === "asc") return "fa-sort-up"
      else if (sortValue === "desc") return "fa-sort-down"
      return "fa-sort"
    },
  },

  mounted() {
    const { $ } = window
    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
    })
  },
}
</script>

<style>
.select-style .vs__dropdown-toggle {
  background: white !important;
  height: 37px !important;
}

.slider {
  margin-top: 7px;
}
</style>

<style scoped>
.download-text {
  font-size: 11px;
}

.form-control {
  height: 37px !important;
}

.input-group-append {
  font-size: 12px;
  height: 37px !important;
}

.input-group-text {
  font-size: 12px;
}

.top-input {
  font-size: 12px;
}

.selector-table {
  margin-top: 25px;
}

th {
  z-index: 100 !important;
  white-space: nowrap;
}

.hover {
  cursor: pointer;
}

.sort {
  margin-left: 5px;
}

.nowrap {
  white-space: nowrap;
}

.selected-hybrid {
  font-size: 10px;
}

.selected-hybrid > div {
  background: #7574e0;
  height: 7px;
  width: 7px;
  border-radius: 7px;
  margin-right: 5px;
}
</style>
