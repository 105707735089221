<template>
  <div class="row settings-row">
    <div class="col">
      <slot name="title"></slot>
    </div>
    <div class="col">
      <div v-if="!editRow">
        <slot name="display-value"></slot>
        <i
          class="fa fa-pencil-square-o"
          @click="
            editRow = true
            $emit('focus-input')
          "
        />
      </div>
      <div v-else class="d-flex">
        <slot name="input" :handleEnter="submitOnEnter"></slot>
        <div class="edit-icons">
          <div>
            <i
              class="fa fa-check"
              @click="
                editRow = false
                $emit('submit-row')
              "
            />
          </div>
          <div>
            <i
              class="fa fa-times"
              @click="
                $emit('clear-input')
                editRow = false
              "
            />
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="col">
      <slot name="default-type">General Defaults</slot>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "SettingsRow",
  data() {
    return {
      editRow: false,
    }
  },
  methods: {
    submitOnEnter: function(e) {
      if (e.keyCode === 13) {
        this.$emit("submit-row")
        this.editRow = false
      }
    },
  },
}
</script>
