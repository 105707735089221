<template>
  <div class="row">
    <form @submit.prevent="handleSubmit" novalidate>
      <div class="card">
        <h2
          class="card-heading default-title"
          @click="showInputs = !showInputs"
        >
          Soil Sample Data
          <i class="fa fa-chevron-down" v-if="!showInputs" />
          <i class="fa fa-chevron-up" v-else />
        </h2>
        <div class="card-body d-flex" v-if="showInputs">
          <div class="left-side">
            <div class="row">
              <div class="col">
                <span class="default-input-title">Lab</span>
                <v-select-old
                  class="select-style"
                  :options="labChoices"
                  :reduce="o => o.value"
                  v-model="soilSampleData.lab"
                  label="display_name"
                />
              </div>

              <div class="col">
                <span class="default-input-title">Sample Date</span>
                <datepicker v-model="soilSampleData.sample_date" />
              </div>

              <div class="col">
                <span class="default-input-title">Sample Density</span>
                <v-select-old
                  class="select-style"
                  :options="sampleDensityChoices"
                  :reduce="o => o.value"
                  v-model="soilSampleData.sample_density"
                  label="display_name"
                />
              </div>
            </div>

            <div class="row">
              <div class="col">
                <span class="default-input-title">CEC</span>
                <div class="input-group">
                  <input
                    type="number"
                    class="form-control"
                    v-model="soilSampleData.cec"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">meq</span>
                  </div>
                </div>
              </div>
              <div class="col">
                <span class="default-input-title">pH</span>
                <div class="input-group">
                  <input
                    type="number"
                    class="form-control"
                    v-model="soilSampleData.ph"
                  />
                </div>
              </div>
              <div class="col">
                <span class="default-input-title">Organic Matter (OM)</span>
                <div class="input-group">
                  <input
                    type="number"
                    class="form-control"
                    v-model="soilSampleData.organic_matter"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">%</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <table class="table" v-if="showSoilTable()">
                  <thead>
                    <tr>
                      <th>Nutrient</th>
                      <th>Amount</th>
                      <th>Units</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="key in nutrientKeys" :key="key">
                      <td>{{ key }}</td>
                      <td>{{ soilSampleData.nutrients[key].amount }}</td>
                      <td>{{ soilSampleData.nutrients[key].units }}</td>
                      <td>
                        <i
                          class="fa fa-times-circle"
                          @click="deleteNutrient(key)"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="right-side">
            <div class="row">
              <div class="col">
                <span class="default-input-title">Nutrient</span>
                <v-select-old
                  class="select-style"
                  :options="sampleNutrientChoices"
                  :reduce="o => o.value"
                  v-model="nutrient"
                  label="display_name"
                />
              </div>
              <div class="col">
                <span class="default-input-title">Amount</span>
                <div class="input-group">
                  <input
                    type="number"
                    class="form-control"
                    v-model="nutrientAmount"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col" />
              <div class="col">
                <span class="default-input-title">Units</span>
                <v-select-old
                  class="select-style"
                  :options="sampleUnitsChoices"
                  :reduce="o => o.value"
                  v-model="nutrientUnits"
                  label="display_name"
                />
              </div>
            </div>

            <div class="row">
              <div class="col">
                <button
                  type="button"
                  class="btn btn-primary update-btn"
                  :class="{ disabled: !canAddNutrient }"
                  @click="handleAddNutrient(true)"
                >
                  <i class="fa fa-plus" />Add Nutrient
                </button>
              </div>
              <div class="col">
                <button
                  type="submit"
                  class="btn btn-primary update-btn"
                  :class="{ disabled: !canUpdate }"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import _ from "lodash"
import { mapActions } from "vuex"
import DefaultsAPI from "@/api/DefaultsAPI"
import {
  LAB_CHOICES,
  SAMPLE_DENSITY_CHOICES,
  SAMPLE_NUTRIENT_CHOICES,
  SAMPLE_UNITS_CHOICES,
} from "@/constants/defaults"
import { Fields } from "@/store/modules"
import { successMessage } from "@/utility"

export default {
  name: "SoilSampleDataDefaults",
  props: {
    soilSampleData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      showInputs: true,
      labChoices: LAB_CHOICES,
      sampleDensityChoices: SAMPLE_DENSITY_CHOICES,
      sampleNutrientChoices: SAMPLE_NUTRIENT_CHOICES,
      sampleUnitsChoices: SAMPLE_UNITS_CHOICES,
      nutrient: null,
      nutrientAmount: null,
      nutrientUnits: null,
      // update button highlighting
      initialLab: null,
      initialSampleDate: null,
      initialSampleDensity: null,
      initialCec: null,
      initialPh: null,
      initialOrganicMatter: null,
    }
  },

  computed: {
    canUpdate() {
      const {
        lab,
        sample_date,
        sample_density,
        cec,
        ph,
        organic_matter,
      } = this.soilSampleData
      const {
        initialLab,
        initialSampleDate,
        initialSampleDensity,
        initialCec,
        initialPh,
        initialOrganicMatter,
      } = this
      return (
        lab !== initialLab ||
        sample_date !== initialSampleDate ||
        sample_density !== initialSampleDensity ||
        cec !== initialCec ||
        ph !== initialPh ||
        organic_matter !== initialOrganicMatter
      )
    },

    canAddNutrient() {
      const { nutrient, nutrientAmount, nutrientUnits } = this
      return !!nutrient && !!nutrientAmount && !!nutrientUnits
    },

    nutrientKeys() {
      return Object.keys(this.soilSampleData.nutrients)
    },
  },

  methods: {
    ...mapActions({
      fetchFields: Fields.Actions.fetchFields,
    }),

    deleteNutrient(key) {
      this.soilSampleData.nutrients = _.omit(this.soilSampleData.nutrients, key)
      this.handleSubmit()
    },

    handleAddNutrient(submit = false) {
      if (this.canAddNutrient) {
        let updatedNutrients = _.clone(this.soilSampleData.nutrients)
        updatedNutrients[this.nutrient] = {
          amount: this.nutrientAmount,
          units: this.nutrientUnits,
        }
        this.soilSampleData.nutrients = updatedNutrients

        if (submit) this.handleSubmit()

        this.nutrient = null
        this.nutrientAmount = null
        this.nutrientUnits = null
      }
    },

    async handleSubmit() {
      this.handleAddNutrient()

      await DefaultsAPI.update(
        "soil-sample-defaults",
        this.soilSampleData.id,
        this.soilSampleData
      )
      successMessage(this, "Soil Sample Defaults Updated", "top-right")
      this.fetchFields()
    },

    showSoilTable() {
      return (
        !_.isEmpty(this.soilSampleData) &&
        !_.isEmpty(this.soilSampleData.nutrients)
      )
    },
  },

  mounted() {
    const {
      lab,
      sample_date,
      sample_density,
      cec,
      ph,
      organic_matter,
    } = this.soilSampleData
    this.initialLab = lab
    this.initialSampleDate = sample_date
    this.initialSampleDensity = sample_density
    this.initialCec = cec
    this.initialPh = ph
    this.initialOrganicMatter = organic_matter
  },
}
</script>

<style scoped>
.defaults-container {
  background-color: white;
}

.default-input-title {
  font-size: 14px;
}

.default-title {
  font-size: 16px;
  font-weight: bold;
  color: #515365;
  margin: 0;
  padding-bottom: 0;
  cursor: pointer;
}

.default-title i {
  margin-left: 5px;
}

form {
  width: 100%;
}

.update-btn {
  width: 100%;
  margin: 0;
  margin-top: 17px;
}

.left-side {
  flex: 3;
  padding-right: 25px;
  border-right: 1pt solid #ccc;
}

.right-side {
  flex: 2;
  padding-left: 25px;
}

.fa-plus {
  margin-right: 5px;
}
</style>
