<template>
  <table class="table table-font-inc">
    <tbody>
      <tr v-for="(entry, val) in Object.entries(dataTableObject)" :key="val">
        <td class="text-nowrap text-left pl-4">{{ entry[0] }}</td>
        <td
          v-if="Array.isArray(entry[1]) && entry[1].length < 2"
          class="font-weight-bold dk-grey text-right"
        >
          {{ entry[1][0] }}
        </td>
        <td
          v-else-if="Array.isArray(entry[1]) && entry[1].length < 3"
          class="font-weight-bold dk-grey text-right"
        >
          <span
            class="small-text pl-3"
            v-for="(item, idx) in entry[1]"
            :key="idx"
            >{{ item }}</span
          >
        </td>
        <td
          v-else-if="typeof entry[1] == 'number' && (decimalCount(entry[1]) > 2 || decimalCount(entry[1]) === 0)"
          class="text-nowrap font-weight-bold dk-grey text-right"
        >
          {{ entry[1] | prettyInteger }}
        </td>
        <td v-else class="text-nowrap font-weight-bold dk-grey text-right">
          {{ entry[1] }}
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  name: "ReportInfoTable",
  props: ["dataTableObject"],

  methods: {
    decimalCount(num) {
      // Convert to String
      const numStr = String(num)
      // String Contains Decimal
      if (numStr.includes(".")) {
        return numStr.split(".")[1].length
      }
      // String Does Not Contain Decimal
      return 0
    },
  },
}
</script>
<style scoped>
.dk-grey {
  color: #444;
}

table {
  table-layout: fixed;
  word-wrap: break-word;
}

td {
  font-family: Montserrat, sans-serif;
}

td:first-of-type {
  text-align: right;
  overflow-wrap: break-word;
}

.small-text {
  font-size: 12px;
}

.table-font-inc {
  font-size: 15px;
}
</style>
