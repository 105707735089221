<template>
  <ArvaModal
    name="sampleLocationTestModal"
    title="Sample Location Test(s)"
    @close-modal="handleClose"
  >
    <v-card-text>
      <div>
        <h4>
          Change soil test request for current location
        </h4>
      </div>
      <form @submit.prevent="submit">
        <v-select-old
          class="mx-2 tillage-select"
          chips
          v-model="selection"
          :options="soilTests"
          multiple
        ></v-select-old>
        <br />
        <v-btn class="mr-4" type="submit">
          Update Test Selection
        </v-btn>
        <v-btn class="mr-4" @click="cancel">
          Cancel
        </v-btn>
      </form>
    </v-card-text>
  </ArvaModal>
</template>

<script>
import ArvaModal from "./ArvaModal"
import SamplingAPI from "@/api/SamplingAPI"

export default {
  name: "SamplingLocationTestModal",
  props: ["location", "selectedPlan"],
  components: {
    ArvaModal,
  },
  data() {
    return {
      selection: [],
      selectedTests: null,
      soilTests: [
        {
          label: "Bulk Density",
          id: 1,
        },
        {
          label: "SOC",
          id: 2,
        },
        {
          label: "Haney Test",
          id: 3,
        },
      ],
    }
  },

  mounted() {
    this.selection = []

    this.selectedTests = this.location.sample_tests.map(test => test.id)
    for (let i in this.soilTests) {
      if (this.selectedTests.includes(this.soilTests[i].id)) {
        this.selection.push(this.soilTests[i])
      }
    }
  },
  methods: {
    handleClose() {
      this.$emit("close-template-modal")
    },

    async submit() {
      let payload = {
        sample_location_id: this.location.id,
        sample_tests: this.selection.map(x => x.id),
      }
      await SamplingAPI.postUpdateLocationTest(payload).then(() => {
        this.location.sample_tests = this.selection.map(x => x)
        this.selectedPlan.label_pdf = null
      })

      //create a post payload to run daycent from here
      this.$emit("close-template-modal")
    },
    cancel() {
      this.$emit("close-template-modal")
    },
  },
}
</script>

<style scoped>
.confirm-text {
  font-size: 15px;
  font-weight: 500;
  padding: 30px 30px;
}

.tillage-select {
  max-width: 225px;
}
</style>
