<template>
  <tr :class="showColorText()">
    <td>
      <v-checkbox
        v-if="(isCustomerAdmin && !isExpired) || canClose"
        v-model="sharingTransactionSelectedCheckbox"
        @click="sendCheckEvents"
        name="selected"
        ref="sharingTransactionSelectedCheckbox"
      />
    </td>
    <td>{{ transaction.id }}</td>
    <td>{{ transaction.requested_by.email }}</td>
    <td>{{ transaction.shared_org_name }}</td>
    <td>{{ transaction.received_org_name }}</td>
    <td>
      <v-btn
        class="ml-auto"
        small
        @click="openFieldToShareModal"
        :loading="loadingFieldToShare"
      >
        {{ fieldToShareLabel }}
      </v-btn>
    </td>
    <td>{{ transaction.sharing_status }}</td>
    <td>
      <v-btn
        v-if="isRequested && isCustomerAdmin"
        class="ml-auto mr-3"
        small
        color="green"
        @click="approveSharingTransaction"
      >
        Approve
      </v-btn>
      <v-btn
        v-if="isCustomerAdmin || (!isCustomerAdmin && isRequested)"
        class="ml-auto"
        small
        @click="openEditTransactionModal"
      >
        Edit
      </v-btn>
    </td>
  </tr>
</template>

<script>
export default {
  name: "SharingTransactionRow",

  props: ["transaction", "loadingFieldToShare", "isCustomerAdmin"],

  data() {
    return {
      sharingTransactionSelectedCheckbox: false,
    }
  },

  computed: {
    fieldToShareLabel() {
      return this.transaction.sharing_status === "Requested" ||
        this.isCustomerAdmin
        ? "Edit Field(s)"
        : "Show Field(s)"
    },

    isFieldToShareEditable() {
      return this.fieldToShareLabel === "Edit Field(s)"
    },

    isApproved() {
      return this.transaction.sharing_status === "Approved"
    },

    isRequested() {
      return this.transaction.sharing_status === "Requested"
    },

    isExpired() {
      return this.transaction.sharing_status === "Expired"
    },

    canClose() {
      return this.isRequested
    },
  },

  methods: {
    sendCheckEvents() {
      if (this.sharingTransactionSelectedCheckbox)
        this.$emit("checked", this.transaction.id)
      else this.$emit("unchecked", this.transaction.id)
    },

    setCheckbox(checked) {
      if (this.transaction.sharing_status !== "Expired") {
        this.sharingTransactionSelectedCheckbox = checked
        this.sendCheckEvents()
      }
    },

    openFieldToShareModal() {
      this.$emit(
        "open-field-to-share-modal",
        this.transaction,
        this.isFieldToShareEditable
      )
    },

    openEditTransactionModal() {
      this.$emit("open-edit-transaction-modal", this.transaction)
    },

    approveSharingTransaction() {
      this.$emit("approve-sharing-transaction", this.transaction)
    },

    showColorText() {
      if (this.isRequested) return "showRequestedText"
      else if (this.isApproved) return "showApprovedText"
      else return "showExpiredText"
    },
  },
}
</script>

<style scoped>
.showRequestedText {
  color: #a2ac1a;
}
.showExpiredText {
  color: #e42121;
}

.showApprovedText {
  color: #14cd3f;
}
</style>
