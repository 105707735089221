<template>
  <div>
    <b-button v-if="ciScore == 0 && !loading" v-b-toggle.sidebar-right class="ci-sidebar-toggle total-span mb-0">Calculate</b-button>
    
    <v-progress-circular v-else-if="loading" indeterminate :size="48" color="#79c61c" />

    <div v-else class="total-span mb-0 ci-score-result">
      {{ ciScore }}
      <p>{{ units }}</p>
    </div>

    <b-sidebar width="576px" id="sidebar-right" title="CI Score Potential Calculations" right shadow backdrop>
      <div class="px-8 pt-4 sidebar-body-inner-padding-bottom">
        <div>
          <v-btn-toggle v-model="ciInputGroup" tile color="#000000" class="d-flex flex-wrap ci-buttons-toggle" group>
            <v-btn height="32px" value="yield">Yield</v-btn>
            <v-btn height="32px" value="fuel">Fuel</v-btn>
            <v-btn height="32px" value="nitrogen">Nitrogen</v-btn>
            <v-btn height="32px" value="otherFerts">Other fertilizers</v-btn>
            <v-btn height="32px" value="pestControl">Pest control</v-btn>
            <v-btn :disabled="formInputs.crop == 'Rice'" height="32px" value="manure">Manure</v-btn>
            <v-btn :disabled="formInputs.crop == 'Rice'" height="32px" value="coverCrop">Cover Crop</v-btn>
            <v-btn :disabled="formInputs.crop == 'Rice'" height="32px" value="tillage">Tillage</v-btn>
            <v-btn height="32px" value="other">Other</v-btn>
          </v-btn-toggle>
        </div>

        <v-divider class="mt-2 mb-8" />

        <div class="ci-input-group" v-if="ciInputGroup == 'yield'">
          <v-row>
            <v-col class="py-0" cols="6">
              <p class="mb-0">Crop</p>
              <v-select v-model="formInputs.crop" :items="cropItems" outlined />
            </v-col>

            <v-col class="py-0" cols="6">
              <p class="mb-0">Crop Yield {{formInputs.crop == 'Rice' ? '(cwt/acre)' : '(bu/acre)'}}</p>
              <v-text-field :rules="[numberValidator]" v-model.number="formInputs.cropYield" outlined />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="py-0" cols="6">
              <p class="mb-0">State</p>
              <v-select v-model="selectedState" :items="stateItems" outlined />
            </v-col>

            <v-col class="py-0" cols="6">
              <p class="mb-0" :class="disabledStateSelected ? 'disabled-label' : ''">County</p>
              <v-select :disabled="disabledStateSelected" v-model="selectedCounty" :items="countyItems" outlined />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="py-0" cols="12">
              <p>FIPS Code: {{ formInputs.fips }}</p>
              <p v-if="disabledStateSelected || disabledCountySelected"><sup>*</sup>
                This state or county is not supported for SOC calculations.
                The CI Potential value will only be feedstock CI without SOC, which typically reduces the overall result.
              </p>
            </v-col>
          </v-row>
        </div>

        <div class="ci-input-group" v-if="ciInputGroup == 'fuel'">
          <v-row>
            <v-col class="py-0" cols="6">
              <p class="mb-0">Diesel (gal/acre)</p>
              <v-text-field :rules="[numberValidator]" v-model.number="formInputs.diesel" outlined />
            </v-col>

            <v-col class="py-0" cols="6">
              <p class="mb-0">Gasoline (gal/acre)</p>
              <v-text-field :rules="[numberValidator]" v-model.number="formInputs.gasoline" outlined />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="py-0" cols="6">
              <p class="mb-0">Natural Gas (ft3/acre)</p>
              <v-text-field :rules="[numberValidator]" v-model.number="formInputs.naturalGas" outlined />
            </v-col>

            <v-col class="py-0" cols="6">
              <p class="mb-0">Liquefied Petroleum Gas (gal/acre)</p>
              <v-text-field :rules="[numberValidator]" v-model.number="formInputs.liqPetrGas" outlined />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="py-0" cols="6">
              <p class="mb-0">Electricity (kWh/acre)</p>
              <v-text-field :rules="[numberValidator]" v-model.number="formInputs.electricity" outlined />
            </v-col>
          </v-row>
        </div>

        <div class="ci-input-group" v-if="ciInputGroup == 'nitrogen'">
          <v-row>
            <v-col class="py-0" cols="6">
              <p class="mb-0">Ammonia (lbs N/acre)</p>
              <v-text-field :rules="[numberValidator]" v-model.number="formInputs.ammonia" outlined />
            </v-col>

            <v-col class="py-0" cols="6">
              <p class="mb-0">Ammonium Sulfate (lbs N/acre)</p>
              <v-text-field :rules="[numberValidator]" v-model.number="formInputs.ammoniumSulfate" outlined />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="py-0" cols="6">
              <p class="mb-0">Urea (lbs N/acre)</p>
              <v-text-field :rules="[numberValidator]" v-model.number="formInputs.urea" outlined />
            </v-col>

            <v-col class="py-0" cols="6">
              <p class="mb-0">Urea-ammonium Nitrat Sol (gal/acre)</p>
              <v-text-field :rules="[numberValidator]" v-model.number="formInputs.ureaAmmoniumNitrate" outlined />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="py-0" cols="6">
              <p class="mb-0">Ammonium Nitrate (lbs N/acre)</p>
              <v-text-field :rules="[numberValidator]" v-model.number="formInputs.ammoniumNitrate" outlined />
            </v-col>

            <v-col class="py-0" cols="6">
              <p class="mb-0">Monoammonium Phos (lbs N/acre)</p>
              <v-text-field :rules="[numberValidator]" v-model.number="formInputs.nitrogenMap" outlined />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="py-0" cols="6">
              <p class="mb-0">Diammonium Phos (lbs N/acre)</p>
              <v-text-field :rules="[numberValidator]" v-model.number="formInputs.nitrogenDap" outlined />
            </v-col>
          </v-row>
        </div>

        <div class="ci-input-group" v-if="ciInputGroup == 'otherFerts'">
          <v-row>
            <v-col class="py-0" cols="6">
              <p class="mb-0">Monoammonium Phos (lbs P205/acre)</p>
              <v-text-field :rules="[numberValidator]" v-model.number="formInputs.phosMap" outlined />
            </v-col>

            <v-col class="py-0" cols="6">
              <p class="mb-0">CaC03 (lbs/acre)</p>
              <v-text-field :rules="[numberValidator]" v-model.number="formInputs.lime" outlined />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="py-0" cols="6">
              <p class="mb-0">Diammonium Phos (lbs P205/acre)</p>
              <v-text-field :rules="[numberValidator]" v-model.number="formInputs.phosDap" outlined />
            </v-col>

            <v-col class="py-0" cols="6">
              <p class="mb-0">K20 (lbs K20/acre)</p>
              <v-text-field :rules="[numberValidator]" v-model.number="formInputs.potash" outlined />
            </v-col>
          </v-row>
        </div>

        <div class="ci-input-group" v-if="ciInputGroup == 'manure'">
          <v-row>
            <v-col class="py-0" cols="6">
              <p class="mb-0">Did you use manure?</p>
              <v-select v-model="formInputs.manure" :items="manureItems" outlined />
            </v-col>

            <v-col class="py-0" cols="6">
              <p 
                :class="disableManureSelects ? 'disabled-label' : ''"
                class="mb-0">Manure transp. distance (miles)
              </p>
              <v-text-field
                :rules="[numberValidator]"
                :disabled="disableManureSelects"
                v-model.number="formInputs.manureTranspDistance"
                outlined
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="py-0" cols="6">
              <p
                :class="disableManureSelects ? 'disabled-label' : ''" 
                class="mb-0">Tons of swine manure/acre
              </p>
              <v-text-field
                :rules="[numberValidator]"
                :disabled="disableManureSelects"
                v-model.number="formInputs.swineManure"
                outlined
              />
            </v-col>

            <v-col class="py-0" cols="6">
              <p
                :class="disableManureSelects ? 'disabled-label' : ''"
                class="mb-0">Manure transp. energy used<br />(Btu/ton manure/mile)
              </p>
              <v-text-field
                :rules="[numberValidator]"
                :disabled="disableManureSelects"
                v-model.number="formInputs.manureTranspEnergy"
                outlined
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="py-0" cols="6">
              <p
                :class="disableManureSelects ? 'disabled-label' : ''"
                class="mb-0">Tons of dairy cow manure/acre</p>
              <v-text-field
                :rules="[numberValidator]"
                :disabled="disableManureSelects"
                v-model.number="formInputs.cowManure"
                outlined
              />
            </v-col>

            <v-col class="py-0" cols="6">
              <p
                :class="disableManureSelects ? 'disabled-label' : ''"
                class="mb-0">Tons of beef cattle manure/acre</p>
              <v-text-field
                :rules="[numberValidator]"
                :disabled="disableManureSelects"
                v-model.number="formInputs.beefManure"
                outlined
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="py-0" cols="6">
              <p
                :class="disableManureSelects ? 'disabled-label' : ''"
                class="mb-0">Tons of chicken manure/acre
              </p>
              <v-text-field
                :rules="[numberValidator]"
                :disabled="disableManureSelects"
                v-model.number="formInputs.chickenManure"
                outlined
              />
            </v-col>

            <v-col class="py-0" cols="6">
              <p
                :class="disableManureSelects ? 'disabled-label' : ''"
                class="mb-0">Manure application energy (Btu/acre)
              </p>
              <v-text-field
                :rules="[numberValidator]"
                :disabled="disableManureSelects"
                v-model.number="formInputs.manureApplicationEnergy"
                outlined
              />
            </v-col>
          </v-row>
        </div>

        <div class="ci-input-group" v-if="ciInputGroup == 'coverCrop'">
          <v-row>
            <v-col class="py-0" cols="6">
              <p class="mb-0">Did you use cover crops?</p>
              <v-select v-model="formInputs.coverCrop" :items="coverCropItems" outlined />
            </v-col>

            <v-col class="py-0" cols="6">
              <p
                :class="disableCoverCropSelects ? 'disabled-label' : ''"
                class="mb-0">Farming Energy (Btu/acre)
              </p>
              <v-text-field
                :rules="[numberValidator]"
                :disabled="disableCoverCropSelects"
                v-model.number="formInputs.coverCropFarmEnergy"
                outlined
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="py-0" cols="6">
              <p
                :class="disableCoverCropSelects ? 'disabled-label' : ''"
                class="mb-0">Herbicide Application Rate (g/acre)
              </p>
              <v-text-field
                :rules="[numberValidator]"
                :disabled="disableCoverCropSelects"
                v-model.number="formInputs.coverCropHerbicideRate"
                outlined
              />
            </v-col>

            <v-col class="py-0" cols="6">
              <p
                :class="disableCoverCropSelects ? 'disabled-label' : ''"
                class="mb-0">Crop Yield (dry ton/acre)
              </p>
              <v-text-field
                :rules="[numberValidator]"
                :disabled="disableCoverCropSelects"
                v-model.number="formInputs.coverCropYield"
                outlined
              />
            </v-col>
          </v-row>
        </div>

        <div class="ci-input-group" v-if="ciInputGroup == 'tillage'">
          <v-row>
            <v-col class="py-0" cols="6">
              <p class="mb-0">Tillage Practice</p>
              <v-select v-model="formInputs.tillage" :items="tillageItems" outlined />
            </v-col>
          </v-row>
        </div>

        <div class="ci-input-group" v-if="ciInputGroup == 'pestControl'">
          <v-row>
            <v-col class="py-0" cols="6">
              <p class="mb-0">Herbicide (g/acre)</p>
              <v-text-field :rules="[numberValidator]" v-model.number="formInputs.herbicide" outlined />
            </v-col>

            <v-col class="py-0" cols="6">
              <p class="mb-0">Insecticide (g/acre)</p>
              <v-text-field :rules="[numberValidator]" v-model.number="formInputs.insecticide" outlined />
            </v-col>
          </v-row>
        </div>

        <div class="ci-input-group" v-if="ciInputGroup == 'other'">
          <v-row>
            <v-col class="py-0" cols="6">
              <p
                :class="formInputs.crop != 'Corn' ? 'disabled-label' : ''"
                class="mb-0">Climate Zone
              </p>
              <v-select
                :disabled="formInputs.crop != 'Corn'"
                :items="climateZoneItems"
                v-model="formInputs.climateZone"
                outlined
              />
            </v-col>

            <v-col class="py-0" cols="6">
              <p
                :class="formInputs.crop != 'Corn' ? 'disabled-label' : ''"
                class="mb-0">Tech. for Nitrogen Management</p>
              <v-select
                :disabled="formInputs.crop != 'Corn'"
                :items="nitrogenManagementItems"
                v-model="formInputs.nitrogenManagement"
                outlined
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="py-0" cols="6">
              <p class="mb-0">Source of ammonia for N fertilizer production</p>
              <v-select :items="ammoniaSourceItems" v-model="formInputs.ammoniaSource" outlined />
            </v-col>
          </v-row>

          <p class="mt-6">(Rice Only)</p>
          <v-divider />

          <v-row>
            <v-col class="py-0" cols="6">
              <p class="mb-0">Water regime during the cultivation period</p>
              <v-select
                :disabled="formInputs.crop != 'Rice'"
                :items="waterRegimeDuringItems"
                v-model="formInputs.waterRegimeDuring"
                outlined
              />
            </v-col>

            <v-col class="py-0" cols="6">
              <p class="mb-0">Water regime in the pre-season before cultivation period</p>
              <v-select
                :disabled="formInputs.crop != 'Rice'"
                :items="waterRegimePreSeasonItems"
                v-model="formInputs.waterRegimePreSeason"
                outlined
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="py-0" cols="6">
              <p class="mb-0">Time for straw incorporation</p>
              <v-select
                :disabled="formInputs.crop != 'Rice'"
                :items="strawIncorporationTimeItems"
                v-model="formInputs.strawIncorporationTime"
                outlined
              />
            </v-col>
          </v-row>
        </div>
      </div>

      <div class="ci-navigation-handlers">
        <v-btn outlined height="52" @click="setCropDefaults()">Set GREET Defaults</v-btn>

        <div>
          <v-btn outlined height="52" :disabled="ciInputGroup == 'yield'" @click="handlePrevNav()">Prev</v-btn>
          <v-btn outlined height="52" :disabled="ciInputGroup == 'other'" @click="handleNextNav()">Next</v-btn>
          <v-btn outlined height="52" @click="handleCICalculation()">Calculate</v-btn>
        </div>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import { getCIScoreEstimate } from "@/api/CIScore";
import { mapState, mapMutations } from "vuex";
import { Dashboard } from "@/store/modules"
import { numberValidator } from '@/utility'
import {
  GREET_US_STATES_COUNTIES,
  GREET_DEFAULTS,
  SUPPORTED_FIPS_2022
} from "@/constants/greet";
import { GREET_US_STATES_ABBREV } from "../../constants/greet";

export default {
  name: "CIForm",
  data() {
    return {
      loading: false,
      numberValidator,
      disabledStateSelected: false,
      disabledCountySelected: false,

      units: '',
      lastComputedCrop: '',

      selectedState: null,
      selectedCounty: null,
      supportedRegions: [],
      
      disableManureSelects: false,
      disableCoverCropSelects: false,

      ciInputGroup: 'yield',
      manureItems: ["Manure", "No manure"],
      cropItems: ['Corn', 'Soybeans', 'Rice'],
      coverCropItems: ["Cover crop", "No cover crop"],
      tillageItems: ['Conventional tillage', 'Reduced tillage', 'No till'],
      climateZoneItems: ['No consideration', 'Wet or Moist'],
      ammoniaSourceItems: ['Conventional', 'Green'],
      nitrogenManagementItems: [
        'Business as usual',
        '4R (Right time, Right place, Right form, and Right rate)',
        'Enhanced Efficiency Fertilizer'
      ],
      waterRegimeDuringItems: [
        'Continuously flooded',
        'Single drainage period',
        'Multiple drainage periods',
        'Regular rainfed',
        'Drought prone',
        'Deep water'
      ],
      waterRegimePreSeasonItems: [
        'Non flooded pre-season <180 d',
        'Non flooded pre-season >180 d',
        'Flooded pre-season (>30 d)',
        'Non-flooded pre-season >365 d'
      ],
      strawIncorporationTimeItems: [
        'Straw incorporated shortly (<30 days) before cultivation',
        'Straw incorporated long (>30 days) before cultivation'
      ],
      formTabOrdering: {
        'yield': 'fuel',
        'fuel': 'nitrogen',
        'nitrogen': 'otherFerts',
        'otherFerts': 'pestControl',
        'pestControl': 'manure',
        'manure': 'coverCrop',
        'coverCrop': 'tillage',
        'tillage': 'other'
      },

      formInputs: {
        // crop/yield
        crop: 'Corn',
        cropYield: 178.4,
        fips: 2013,
  
        // energy
        diesel: 7.2,
        gasoline: 1.3,
        naturalGas: 87.0,
        liqPetrGas: 2.2,
        electricity: 69.3,
  
        // nitrogen
        ammonia: 49.0,
        urea: 36.3,
        ammoniumNitrate: 3.2,
        ammoniumSulfate: 3.2,
        ureaAmmoniumNitrate: 50.5,
        nitrogenMap: 6.3,
        nitrogenDap: 9.5,
  
        // other ferts
        phosMap: 29.6,
        phosDap: 29.6,
        lime: 573.0,
        potash: 59.9,
  
        // pest control
        herbicide: 1044.2,
        insecticide: 2.2,

        // cover crops
        coverCrop: 'Cover crop',
        coverCropFarmEnergy: 62060,
        coverCropHerbicideRate: 612,
        coverCropYield: 1.214,

        // manure
        manure: 'Manure',
        manureApplicationEnergy: 221366,
        manureTranspDistance: 0.367,
        manureTranspEnergy: 10416,
        chickenManure: 0.9,
        swineManure: 1.9,
        cowManure: 3.3,
        beefManure: 1.7,

        // tillage
        tillage: 'Conventional tillage',

        // other inputs
        climateZone: 'No consideration',
        nitrogenManagement: 'Business as usual',
        ammoniaSource: 'Conventional',
        waterRegimeDuring: 'Continuously flooded',
        waterRegimePreSeason: 'Non flooded pre-season <180 d',
        strawIncorporationTime: 'Straw incorporated shortly (<30 days) before cultivation'
      }
    }
  },
  methods: {
    ...mapMutations({
      setCIScore: Dashboard.Mutations.setCIScore,
    }),
    setCropDefaults() {
      const defaults = GREET_DEFAULTS[this.formInputs.crop.toLowerCase()];

      for (const key in defaults) {
        this.formInputs[key] = defaults[key];
      }
    },
    handlePrevNav() {
      for (const key in this.formTabOrdering) {
        if (this.formTabOrdering[key] == this.ciInputGroup) {
          this.ciInputGroup = key;
          break;
        }
      }
    },
    handleNextNav() {
      this.ciInputGroup = this.formTabOrdering[this.ciInputGroup];
    },
    async handleCICalculation() {
      try {
        this.loading = true;
        
        // get the formatted data and hit the api for estimates
        const formData = this.getAPIFormattedData;
        const crop = formData['crop'];
        const { data } = await getCIScoreEstimate(formData);

        const score = data.score;

        let ciScore = 0;

        if (crop == 'rice') {
          ciScore = (score / 1000).toFixed(1) + "k";
          this.units = 'g GHG/cwt';
        }

        if (crop == 'corn') {
          ciScore = score.toFixed(1);
          this.units = 'g GHG/MJ';
        }
    
        if (crop == 'soybeans') {
          ciScore = (score / 1000).toFixed(1) + "k";
          this.units = 'g GHG/bu';
        }

        this.setCIScore(ciScore);
      }
      catch (e) {
        console.log('error calculating estimate: ', e);
        this.ciError = 'sum\'n';
      }

      this.loading = false;
    }
  },
  computed: {
    ...mapState({
      ciScore: state => state.Dashboard.ciScore
    }),
    stateItems() {
      return GREET_US_STATES_ABBREV.map(state => {
        const isEnabled = state in this.supportedRegions;
  
        return {
          text: `${state}${isEnabled ? '' : ' *'}`,
          value: state,
          fipsDisabled: !isEnabled
        }
      })
    },
    countyItems() {
      if (this.selectedState == null) return null
      if (this.selectedState.includes('disabled')) {
        this.disabledStateSelected = true;
        this.selectedCounty = null;
        return null
      }

      return GREET_US_STATES_COUNTIES[this.selectedState].map(([county, fips]) => {
        const countyIsEnabled = this.selectedState in this.supportedRegions && this.supportedRegions[this.selectedState].some(f => f[1] == fips);
  
        return {
          text: `${county}${countyIsEnabled ? '' : ' *'}`,
          value: fips,
          fipsDisabled: !countyIsEnabled
        }
      });
    },
    selectedCrop() {
      return this.formInputs['crop']
    },
    getAPIFormattedData() {
      return {
        // crop
        'crop': this.formInputs.crop.toLowerCase(),
        'ci_year': '2023',
        'crop_yield': this.formInputs.cropYield,
        'fips': this.formInputs.fips,

        // energy
        'diesel': this.formInputs.diesel,
        'gasoline': this.formInputs.gasoline,
        'natural_gas': this.formInputs.naturalGas,
        'liq_petr_gas': this.formInputs.liqPetrGas,
        'electricity': this.formInputs.electricity,

        // nitrogen ferts
        'ammonia': this.formInputs.ammonia,
        'urea': this.formInputs.urea,
        'ammonium_nitrate': this.formInputs.ammoniumNitrate,
        'ammonium_sulfate': this.formInputs.ammoniumSulfate,
        'urea_ammonium_nitrate': this.formInputs.ureaAmmoniumNitrate,
        'nitrogen_map': this.formInputs.nitrogenMap,
        'nitrogen_dap': this.formInputs.nitrogenDap,

        // other ferts
        'phosphorus_map': this.formInputs.phosMap,
        'phosphorus_dap': this.formInputs.phosDap,
        'potash': this.formInputs.potash,
        'lime': this.formInputs.lime,

        // pest control
        'herbicide': this.formInputs.herbicide,
        'insecticide': this.formInputs.insecticide,

        // cover crops
        'cover_crop_rotation': this.formInputs.coverCrop,
        'cover_crop_energy': this.formInputs.coverCropFarmEnergy,
        'cover_crop_herbicide_ar': this.formInputs.coverCropHerbicideRate,
        'cover_crop_yield': this.formInputs.coverCropYield,

        // manure
        'manure': this.formInputs.manure,
        'manure_swine': this.formInputs.swineManure,
        'manure_dairycow': this.formInputs.cowManure,
        'manure_beefcattle': this.formInputs.beefManure,
        'manure_chicken': this.formInputs.chickenManure,
        'manure_energy': this.formInputs.manureApplicationEnergy,
        'manure_transport_distance': this.formInputs.manureTranspDistance,
        'manure_transport_energy': this.formInputs.manureTranspEnergy,

        // etc.
        'tillage': this.formInputs.tillage,
        'climate_zone': this.formInputs.climateZone,
        'nitrogen_management': this.formInputs.nitrogenManagement,
        'ammonia_source': this.formInputs.ammoniaSource,

        // rice only
        'water_regime_during_cult': this.formInputs.waterRegimeDuring,
        'water_regime_preseason_cult': this.formInputs.waterRegimePreSeason,
        'straw_incorporation_time': this.formInputs.strawIncorporationTime
      }
    },
  },
  watch: {
    formInputs() {
      // cover crop
      this.disableCoverCropSelects = this.formInputs.coverCrop != 'Cover crop'
        || this.formInputs.crop != 'Corn';

      // manure
      this.disableManureSelects = this.formInputs.manure != 'Manure'
        || this.formInputs.crop != 'Corn';
    },
    selectedCrop(curr) {
      // parse our greet constants to configure which counties are enabled/disabled
      const supportedRegions = {};

      for (const key in GREET_US_STATES_COUNTIES) {
        const supportedCounties = GREET_US_STATES_COUNTIES[key].filter(([, fips]) => SUPPORTED_FIPS_2022[curr.toLowerCase()].includes(fips));
        if (supportedCounties.length) {
          supportedRegions[key] = supportedCounties;
        }
      }

      this.supportedRegions = supportedRegions;
    },
    supportedRegions() {
      if (this.selectedState == null) {
        this.selectedState = this.stateItems.find(({ fipsDisabled }) => !fipsDisabled).value;
        return
      }

      if (this.selectedCounty == null) {
        this.selectedCounty = this.countyItems.find(({ fipsDisabled }) => !fipsDisabled).value;
      }

      const stateObj = this.stateItems.find(s => s['value'] == this.selectedState);
      if (stateObj == null || stateObj['fipsDisabled']) {
        this.disabledStateSelected = true;
        this.selectedCounty = null;
      }
      else {
        this.disabledStateSelected = false
      }

      const countyInSupportedCounties = this.countyItems.find(({ value }) => value == this.selectedCounty);
      if (countyInSupportedCounties == null || countyInSupportedCounties['fipsDisabled']) {
        this.disabledCountySelected = true;
        this.formInputs.fips = null;
      }
      else {
        this.disabledCountySelected = false
      }
    },
    selectedState(curr) {
      const stateObj = this.stateItems.find(s => s['value'] == curr);
      if (stateObj == null || stateObj['fipsDisabled']) {
        this.disabledStateSelected = true;
        this.selectedCounty = null;
        return
      }

      // we changed the state selection, update the county items and set the current county to first enabled
      // county will either be a fips code or the string name of the county
      // this.countyItems = GREET_US_STATES_COUNTIES[curr].map((county) => this.formatCountyItems(curr, county));
      this.selectedCounty = this.countyItems.find(({ fipsDisabled }) => !fipsDisabled).value;
      this.disabledStateSelected = false;
    },
    selectedCounty(curr) {
      if (curr == null) {
        this.disabledCountySelected = true;
        this.formInputs.fips = null;
        return
      }

      const countyInSupportedCounties = this.countyItems.find(({ value }) => value == curr);
      if (countyInSupportedCounties == null || countyInSupportedCounties['fipsDisabled']) {
        this.disabledCountySelected = true;
        this.formInputs.fips = null;
        return
      }

      this.formInputs.fips = curr;
      this.disabledCountySelected = false;
    }
  },
  mounted() {
    // parse our greet constants to configure which counties are enabled/disabled
    const supportedRegions = {};

    for (const key in GREET_US_STATES_COUNTIES) {
      const supportedCounties = GREET_US_STATES_COUNTIES[key].filter(([, fips]) => SUPPORTED_FIPS_2022[this.formInputs.crop.toLowerCase()].includes(fips));

      if (supportedCounties.length) {
        supportedRegions[key] = supportedCounties;
      }
    }

    // set our initial states and lookups
    this.supportedRegions = supportedRegions;
  },
}
</script>

<style scoped>
/* .total-span from DashboardSummary.vue */
.total-span {
  float: right;
  color: #79c61c;
  font-size: 20px;
  font-weight: 500;
  min-width: 40px;
  white-space: nowrap;
}

.ci-sidebar-toggle {
  margin-bottom: 0;
  background: none !important;
  box-shadow: none !important;
  border: none !important;
  color: #79c61c !important;
  padding-right: 0;
  margin-right: 0;
}

.ci-sidebar-toggle:hover {
  opacity: 0.5;
}

.ci-buttons-toggle > button {
  text-transform: none;
  padding: 6px 16px;
  letter-spacing: normal;
  border-radius: 4px !important;
}

.ci-buttons-toggle > button.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background: none !important;
}

.ci-input-group p {
  color: #212121;
}

.ci-default-handler {
  position: absolute;
  bottom: 72px;
  left: 32px;
}

.ci-navigation-handlers button {
  border-color: rgba(0, 0, 0, 0.5);
}

.ci-navigation-handlers > div > button:not(:last-child) {
  margin-right: 12px;
}

p.disabled-label {
  opacity: 0.5;
}

.ci-score-result {
  text-align: left;
}

.ci-score-result > p {
  margin: 0;
  font-size: 12px;
  line-height: 1;
  color: #79c61c;
}

::v-deep .fips-option-not-supported + ::before {
  background-color: red !important;
}

::v-deep .b-sidebar-outer {
  z-index: 9999999999;
}

::v-deep #sidebar-right {
  overflow: hidden;
}

.sidebar-body-inner-padding-bottom {
  padding-bottom: 96px;
}

::v-deep .ci-navigation-handlers {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px 32px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  background: #f8f9fa;
}

::v-deep header.b-sidebar-header {
  padding: 56px 32px 0;
  position: relative;
}

::v-deep header.b-sidebar-header > button.close {
  position: absolute;
  top: 24px;
  left: 24px;
}

::v-deep header.b-sidebar-header > strong {
  width: 100%;
  text-align: left;
}

</style>