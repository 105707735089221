<template>
  <div>
    <Permissions package="inset-enrollment" :superUserOnly="false" />
    <div>
      <div class="d-flex justify-space-between align-center mb-4">
        <div class="d-flex align-center">
          <h1 class="mb-0 font-weight-bold">Enrollment Groups</h1>
        </div>

        <div>
          <v-btn disabled class="mr-3" :ripple="false" outlined height="44">
            <v-icon>mdi-play-outline</v-icon>
            5 Min Training
          </v-btn>

          <v-btn disabled v-if="isSuperuser" @click="setSpoofingCP(!spoofingCP)" class="mr-3" :ripple="false" outlined height="44">
            <v-icon v-if="spoofingCP">mdi-close</v-icon>
            <v-icon v-else>mdi-account-multiple</v-icon>
            <span class="ml-1">{{ spoofingCP ? 'Close CP View' : 'Open CP View' }}</span>
          </v-btn>
        </div>
      </div>
  
      <RollupTable
        :selectedRegions="selectedRegions"
        :selectedCrops="selectedCrops"
        @updateSelected="handleUpdateSelected"
        @updateRollupTableHeight="updateRollupTableHeight"
      />

      <v-card outlined class="px-6 py-3 position-relative">
        <v-progress-circular v-if="enrollmentTableLoading['loading']" indeterminate :size="48" color="#79c61c" />

        <div v-if="enrollmentTableLoading['error'] != null">
          <p>Error: {{ enrollmentTableLoading['error'] }}</p>
        </div>

        <v-data-table
          v-else
          show-select
          hide-default-footer
          disable-pagination
          item-key="cropIdKey"
          :class="`enrollment-group-table ${enrollmentTableLoading['loading'] ? 'loading' : ''}`"
          :style="`--table-height-modifier: ${allOtherHeights}px`"
          :search="search"
          :custom-filter="handleDataTableFiltering"
          :headers="spoofingCP ? CP_HEADERS : CSM_HEADERS"
          :items="items"
          :items-per-page="10"
          v-model="selectedItems"
          @current-items="(items) => filteredItems = items"
          @click:row="handleClick"
        >
          <template v-slot:top="props">
            <div
              v-bind="props"
              class="d-flex align-center justify-space-between mb-4"
            >
              <div class="d-flex align-center flex-wrap">
                <v-card-title class="pa-0 ma-0 w-100 order-1">
                  {{ 
                    enrollmentTableLoading['loading']
                      ? 'Loading group data...'
                      : `${allUniqueRenderedGroupIds.length} Enrollment Group${allUniqueRenderedGroupIds.length == 1 ? '' : 's'}`
                  }}
                </v-card-title>
                <v-card-subtitle class="pa-0 ma-0 mb-2 w-100 order-0">Enrollment Progress</v-card-subtitle>
              </div>

              <div class="d-flex justify-end">
                <v-select
                  :class="`mr-2 ${selectedRegions.length == 0 ? 'all-selected' : ''}`"
                  solo
                  multiple
                  clearable
                  hide-details
                  :items="allUniqueRegions"
                  v-model="selectedRegions"
                  placeholder="All Regions"
                >
                  <template v-slot:selection="{ item, index }">
                    <div v-if="index == 0">
                      <p class="ma-0 text-subtitle-2 font-weight-regular">
                        Regions <span>{{ selectedRegions.length }}</span>
                      </p>
                    </div>
                  </template>

                  <template v-slot:item="{ on, attrs, item }">
                    <v-list-item v-on="on" v-bind="attrs">
                      <template v-slot:default="{ active }">
                        <v-list-item-action>
                          <v-checkbox :input-value="active" />
                        </v-list-item-action>

                        <v-list-item-content>
                          <v-list-item-title>
                            {{ regionAbbrevToFull(item) }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                  </template>
                </v-select>

                <v-select
                  :class="`mr-2 ${selectedCrops.length == 0 ? 'all-selected' : ''}`"
                  solo
                  multiple
                  clearable
                  hide-details
                  :items="allUniqueCrops"
                  v-model="selectedCrops"
                  placeholder="All Crops"
                  :menu-props="{ bottom: true, offsetY: true }"
                >
                  <template v-slot:selection="{ item, index }">
                    <div v-if="index == 0">
                      <p class="ma-0 text-subtitle-2 font-weight-regular">
                        Crops <span>{{ selectedCrops.length }}</span>
                      </p>
                    </div>
                  </template>

                  <template v-slot:item="{ on, attrs, item }">
                    <v-list-item v-on="on" v-bind="attrs">
                      <template v-slot:default="{ active }">
                        <v-list-item-action>
                          <v-checkbox :input-value="active" />
                        </v-list-item-action>

                        <v-list-item-content>
                          <v-list-item-title>
                            {{ CROP_DISPLAY_NAME[item] }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                  </template>
                </v-select>

                <v-text-field
                  :ripple="false"
                  class="mr-3 search-input"
                  outlined
                  v-model="search"
                  hide-details
                  placeholder="Search"
                >
                  <template v-slot:prepend-inner>
                    <v-icon>mdi-magnify</v-icon>
                  </template>
                  <span class="ml-1">Search</span>
                </v-text-field>
              </div>
            </div>
          </template>

          <template v-slot:item.name="{ item, index }">
            <span v-if="shouldCellBeVisible(index, item['groupId'])">{{ item['name'] }}</span>
          </template>
          
          <template v-slot:item.crop="{ value, item }">
            <div class="d-flex position-relative">
              <v-progress-linear v-if="value == null" indeterminate color="#E2E8F0" />
              <span v-else>{{ CROP_DISPLAY_NAME[value['id']] }}</span>
            </div>
          </template>

          <template v-slot:item.regions="{ value, item }">
            <div class="d-flex position-relative">
              <v-progress-linear v-if="value == null" indeterminate color="#E2E8F0" />
              <span v-else>
                {{
                  value['value'].map(stateName => {
                    const found = US_STATES.find(({ text }) => stateName == text);
                    if (found != null) return found['value'];
                    return stateName;
                  }).join(', ')
                }}
              </span>
            </div>
          </template>

          <template v-slot:item.confirmed="{ value, item }">
            <div class="d-flex position-relative">
              <v-progress-linear v-if="value == null" indeterminate color="#E2E8F0" />
              <span v-if="value != null && value != 0">{{ value.toLocaleString() }}</span>
            </div>
          </template>

          <template v-slot:item.requested_pending="{ value, item }">
            <div class="d-flex position-relative">
              <v-progress-linear v-if="value == null" indeterminate color="#E2E8F0" />
              <v-chip v-if="value != null && value != 0" :class="`${value < 0 ? 'red' : 'green'}`">
                {{ value < 0 ? '' : '+' }} {{ value.toLocaleString() }}
              </v-chip>
            </div>
          </template>

          <template v-slot:item.requested="{ value, item }">
            <div class="d-flex position-relative">
              <v-progress-linear v-if="value == null" indeterminate color="#E2E8F0" />
              <span v-if="value != null && value != 0">{{ value.toLocaleString() }}</span>
            </div>
          </template>

          <template v-slot:item.confirmedPending="{ item }">
            <div class="d-flex position-relative">
              <v-progress-linear v-if="value == null" indeterminate color="#E2E8F0" />
              <v-chip v-else-if="item[REQUESTED] != null && item[REQUESTED] != 0" :class="`${item[REQUESTED] < 0 ? 'red' : 'green'}`">
                {{ item[REQUESTED] < 0 ? '' : '+' }} {{ item[REQUESTED].toLocaleString() }}
              </v-chip>
            </div>
          </template>

          <template v-slot:item.confirmAction="{ item }">
            <div class="py-2">
              <v-btn
                class="rounded-lg"
                :disabled="item[REQUESTED] == 0"
                outlined
                @click.stop.prevent="null"
              >
                Confirm
              </v-btn>
            </div>
          </template>

          <template v-slot:item.submit="{ item, value }">
            <div class="py-2">
              <v-btn
                class="rounded-lg"
                :disabled="item[REQUESTED_PENDING] == 0"
                outlined
                @click.stop.prevent="submitSupplyChanges({ groupIdCropKeys: [item['cropIdKey']] })"
              >
                Send for review
              </v-btn>
            </div>
          </template>

          <template v-slot:item.statusUpdate="{ value, item, index }">
            <div>
              <div class="d-flex flex-wrap" v-if="value != null">
                <p class="w-100 mb-0">{{ value }}</p>
                <p class="w-100 mb-0">Submitted</p>
              </div>
              <div class="d-flex" v-else>
                <p class="w-100 mb-0">Unsubmitted</p>
              </div>
            </div>
          </template>

          <template v-slot:item.ellipsisMenu="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="rounded-lg" outlined v-bind="attrs" v-on="on">
                  Demo Menu
                </v-btn>
              </template>
              <v-list>
                <v-list-item>
                  <v-list-item-title>Revert confirmation</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>Cool action 2</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>Cool action 3</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card>
    </div>
  </div>
</template>

<script>
import chevronUp from "@/assets/images/chevron-up.svg"
import Permissions from "@/components/permissions/Permissions"
import RollupTable from "@/components/contractGroups/RollupTable.vue"
import { regionAbbrevToFull } from "@/utility"
import { CROP_DISPLAY_NAME, US_STATES } from "@/constants"
import { EnrollmentGroups } from "@/store/modules"
import { mapState, mapActions, mapGetters, mapMutations } from "vuex"
import { REQUESTED, CONFIRMED, REQUESTED_PENDING, ENROLLMENT_TABLE } from "@/constants/contractGroups"

const CP_HEADERS = [
  { text: "Enrollment Group Name", value: "name" },
  { text: "Regions", value: "regions", filter: v => v['selectedRegions'].length == 0 || v['selectedRegions'].some(r => v['value'].includes(regionAbbrevToFull(r))) },
  { text: "Crops", value: "crop", filter: v => v['selectedCrops'].length == 0 || v['selectedCrops'].includes(Number(v['id'])) },
  { text: "Confirmed", value: CONFIRMED },
  { text: "Pending Request", value: REQUESTED_PENDING },
  { text: "Requested AC", value: REQUESTED },
  { text: "Submit", value: "submit" },
  { text: "Status update", value: "statusUpdate" }
]

const CSM_HEADERS = [
  { text: "Enrollment Group Name", value: "name" },
  { text: "Regions", value: "regions", filter: v => v['selectedRegions'].length == 0 || v['selectedRegions'].some(r => v['value'].includes(regionAbbrevToFull(r))) },
  { text: "Crops", value: "crop", filter: v => v['selectedCrops'].length == 0 || v['selectedCrops'].includes(v['id']) },
  { text: "Confirmed", value: CONFIRMED },
  { text: "Submitted AC Pending", value: "confirmedPending" },
  { text: "Confirm Fields", value: "confirmAction" },
  { text: "", value: "ellipsisMenu" }
]

export default {
  name: "ContractGroups",
  components: { Permissions, RollupTable },
  data() {
    return {
      search: '',
      rollupTableHeight: 179,
      filteredItems: [],
      selectedItems: [],
      selectedCrops: [],
      selectedRegions: [],
      CP_HEADERS,
      CSM_HEADERS,
      REQUESTED,
      CROP_DISPLAY_NAME,
      REQUESTED_PENDING,
      US_STATES,
      chevronUp,
    }
  },
  computed: {
    ...mapState({
      isSuperuser: state => state.User.user != null && state.User.user['is_superuser'],
      org: state => state.Organization.organization,
      enrollmentTableLoading: state => state.EnrollmentGroups[ENROLLMENT_TABLE],
      spoofingCP: state => state.EnrollmentGroups.spoofingCP,
      allGroupData: state => state.EnrollmentGroups.groupData
    }),
    ...mapGetters({
      allUniqueCrops: EnrollmentGroups.Getters.allUniqueCrops,
      allUniqueRegions: EnrollmentGroups.Getters.allUniqueRegions
    }),
    allUniqueRenderedGroupIds() {
      return this.filteredItems.reduce((accum, { groupId }) => {
        if (!accum.includes(groupId)) accum.push(groupId)
        return accum
      }, [])
    },
    allOtherHeights() {
      // navbar + page container margin top + h1 + h1 margin + table + table result + table margin
      const external = 60 + 20 + 48 + 16 + this.rollupTableHeight + 65 + 24

      // table borders, table y padding + "top" slot + "top" slot margin
      const internal = 2 + 24 + 60 + 12

      // 24px of artificial padding at bottom
      const extra = 24

      return external + internal + extra
    },
    items() {
      const rows = []

      for (const id in this.allGroupData) {
        const { name, timestamps, cropRegionMap, acreageValues } = this.allGroupData[id]
        
        const groupRows = []
      
        for (const cropId in cropRegionMap) {
          const result = {
            name,
            groupId: id,
            cropIdKey: `${id}-${cropId}`,
            statusUpdate: timestamps.hasOwnProperty(cropId) ? new Date(timestamps[cropId]).toLocaleString() : null,
            crop: {
              id: cropId,
              selectedCrops: this.selectedCrops
            },
            regions: {
              value: cropRegionMap[cropId].sort((a, b) => a.localeCompare(b)),
              selectedRegions: this.selectedRegions
            }
          }

          for (const s of [CONFIRMED, REQUESTED, REQUESTED_PENDING]) {
            for (const region of result['regions']['value']) {
              if (cropId in acreageValues[s] && region in acreageValues[s][cropId]) {
                result[s] = acreageValues[s][cropId][region]['acreage']
              }
            }
          }

          groupRows.push(result)
        }
  
        groupRows.sort((a, b) => 
          CROP_DISPLAY_NAME[a['crop']['id']].localeCompare(CROP_DISPLAY_NAME[b['crop']['id']])
        )
    
        rows.push(...groupRows)
      }

      return rows.sort((a, b) => a['name'].localeCompare(b['name']))
    }
  },
  methods: {
    regionAbbrevToFull,
    ...mapActions({
      listOverview: EnrollmentGroups.Actions.listOverview,
      submitSupplyChanges: EnrollmentGroups.Actions.submitSupplyChanges
    }),
    ...mapMutations({
      setSpoofingCP: EnrollmentGroups.Mutations.setSpoofingCP
    }),
    shouldCellBeVisible(index, id) {
      return this.filteredItems.findIndex(({ groupId }) => groupId == id) == index
    },
    handleUpdateSelected({ crops, region }) {
      this.selectedCrops = crops
      this.selectedRegions = region
    },
    updateRollupTableHeight(height) {
      this.rollupTableHeight = height
    },
    handleDataTableFiltering(value, search, item) {
      if (search == null || search.length == 0) return true
      
      const groupName = item['name'].toLowerCase()
      if (groupName.includes(search)) return true
      return false
    },
    handleClick({ groupId }) {
      this.$router.push(`/enrollment-groups/${groupId}`)
    }
  },
  mounted() {
    if (Object.keys(this.allGroupData).length == 0) this.listOverview()
  },
  watch: {
    org() {
      this.listOverview()
    },
    year(prev, curr) {
      if (prev != null && curr != null) {
        this.listOverview()
      }
    },
    selectedItems(curr) {
      console.log('curr', curr)
    }
  }
}
</script>

<style scoped>
p {
  color: #000000;
}
h1 {
  color: #000;
  font-size: 32px;
  line-height: 48px;
  font-weight: 700;
}
.v-data-table :deep(tr) {
  cursor: pointer;
}
.enrollment-group-table > :deep(.v-data-table__wrapper) {
  transition: height 0.25s ease-in-out;
  height: calc(100vh - var(--table-height-modifier, 0));
  overflow-y: scroll;
}
:deep(.v-data-table-header) {
  background: #ffffff;
  position: sticky;
  top: 0;
  z-index: 2;
}
.thumbnail {
  width: 36px;
  height: 36px;
  object-fit: fill;
}
.v-card__title {
  color: #20292f;
  font-size: 24px;
}
.v-card__subtitle {
  color: #6b7280;
  line-height: 20px;
}
.v-chip.v-size--default {
  height: 20px !important;
}
.enrollment-group-table :deep(table) {
  table-layout: fixed;
}
.enrollment-group-table :deep(th:nth-of-type(1)) {
  width: 52px !important;
}
.enrollment-group-table :deep(th:nth-of-type(2)) {
  width: 200px;
}
.enrollment-group-table :deep(th:nth-of-type(3)) {
  width: 98px;
}
.enrollment-group-table :deep(th:nth-of-type(4)) {
  width: 120px;
}
.enrollment-group-table :deep(th:nth-of-type(5)),
.enrollment-group-table :deep(th:nth-of-type(7)) {
  width: 130px;
}
.enrollment-group-table :deep(th:nth-of-type(6)) {
  width: 145px;
}
.enrollment-group-table :deep(th:nth-of-type(8)) {
  width: 152px;
}
.enrollment-group-table :deep(th:nth-of-type(9)) {
  width: 200px;
}
.enrollment-group-table :deep(.v-data-table__selected td),
.enrollment-group-table :deep(tr:hover td) {
  background: #f5f5f5 !important;
}
.enrollment-group-table :deep(td:nth-of-type(1)),
.enrollment-group-table :deep(td:nth-of-type(2)),
.enrollment-group-table :deep(td:nth-of-type(9)),
.enrollment-group-table :deep(th:nth-of-type(1)),
.enrollment-group-table :deep(th:nth-of-type(2)) {
  position: sticky;
  z-index: 1;
}
.enrollment-group-table :deep(td:nth-of-type(1)),
.enrollment-group-table :deep(td:nth-of-type(2)),
.enrollment-group-table :deep(th:nth-of-type(1)),
.enrollment-group-table :deep(th:nth-of-type(2)) {
  background: #FFFFFF;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.enrollment-group-table :deep(td:nth-of-type(1)),
.enrollment-group-table :deep(th:nth-of-type(1)) {
  left: 0;
}
.enrollment-group-table :deep(td:nth-of-type(2)),
.enrollment-group-table :deep(th:nth-of-type(2)) {
  left: 52px;
}
.enrollment-group-table :deep(.v-btn) {
  color: #487F06;
  letter-spacing: normal;
  text-transform: none;
  border-color: #c7c9ce;
}
.enrollment-group-table :deep(tr td:nth-of-type(9) p) {
  line-height: 1.5 !important;
}
/* v-select overrides */
.v-input.v-select {
  width: 144px;
  height: 40px;
  max-width: unset;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04), 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
  border-radius: 8px;
}
.v-input.v-select.v-input--is-label-active:not(.all-selected) :deep(div[role="button"]) {
  background: #6B7280;
}
.v-input.v-select.v-input--is-label-active:not(.all-selected) :deep(.v-input__append-inner:nth-last-of-type(2) > div) {
  opacity: 1;
}
.v-input.v-select.v-input--is-label-active:not(.all-selected) :deep(.v-input__append-inner:last-of-type) {
  display: none;
}
.v-input.v-select.v-input--is-label-active:not(.all-selected) :deep(div[role="button"] p),
.v-input.v-select.v-input--is-label-active:not(.all-selected) :deep(div[role="button"] i),
.v-input.v-select.v-input--is-label-active:not(.all-selected) :deep(div[role="button"] button.mdi-close) {
  color: white;
}
.v-input.v-select.v-input--is-label-active:not(.all-selected) :deep(div[role="button"] p span) {
  padding: 1px 2px;
  color: black;
  background: white;
  border-radius: 2px;
}
.v-input.v-select :deep(div[role="button"]) {
  min-height: 40px;
}
.v-chip.green {
  background: #F1FDDF !important;
}
.v-chip.green :deep(span) {
  color: #61B100;
}
.v-chip.red {
  background: #FEF2F2 !important;
}
.v-chip.red :deep(span) {
  color: #EF4444;
}
.search-input :deep(.v-input__slot) {
  min-height: unset !important;
  height: 40px;
}
.search-input :deep(.v-input__prepend-inner) {
  margin: 2px 0 0;
  align-self: center;
}
.all-selected :deep(::placeholder) {
  color: #374151 !important;
  opacity: 1;
}
::v-deep(.v-input__control input) {
  cursor: pointer;
}
.v-progress-circular {
  position: absolute;
  z-index: 1;
  left: calc(50% - 24px);
  top: calc(50% - 24px);
}
.loading {
  opacity: 0.5;
  pointer-events: none;
}
.v-btn {
  text-transform: none;
  letter-spacing: normal;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04),
    0px 1px 2px 0px rgba(16, 24, 40, 0.04);
}
.v-btn.no-click-events {
  pointer-events: none;
}
.v-btn p {
  margin: 0;
  line-height: 1.125;
  text-align: left;
  font-weight: normal;
}
.v-btn p:last-of-type {
  font-size: 12px;
}
.progress-min-width {
  width: 80px;
}
</style>
