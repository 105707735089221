<template>
  <!-- THIS COMES FROM V-CALENDAR NOT VUETIFY -->
  <vc-date-picker
    v-model="date"
    class="flex-fill"
    :popover="{
      placement: $screens({ default: 'auto' }),
    }"
    @submit.prevent
  >
    <template v-slot="{ inputValue, inputEvents }">
      <div class="btn w-100 p-0 m-0 text-left date-pick">
        <div class="py-2 pl-2 pr-1 ws-nr">
          <i class="cal-cal fa fa-calendar" />
          <input
            class="input w-70"
            :value="inputValue"
            v-on="inputEvents"
            :placeholder="formatDate(value)"
          />
          <i
            v-if="date"
            class="pull-right pt-1 cal-delete fa fa-times-circle"
            @click="date = null"
          />
        </div>
      </div>
    </template>
  </vc-date-picker>
</template>

<script>
import moment from "moment"
export default {
  name: "Datepicker",
  props: ["value"],
  data() {
    return {
      date: null,
    }
  },
  watch: {
    value() {
      this.date = this.value
    },
    date() {
      this.$emit("input", this.date)
    },
  },
  methods: {
    formatDate(val) {
      if (!val) return ""
      let momentDate = moment(val, "MM/DD/YYYY")
      if (momentDate.isValid()) return momentDate.format("MM/DD/YYYY")
      return ""
    },
  },
}
</script>

<style scoped>
.date-pick {
  border: 1pt solid #ccc;
  height: 38px;
}

.input {
  border: none;
}

.cal-cal {
  margin-right: 8px;
}

.w-70 {
  width: 70%;
}

.ws-nr {
  white-space: nowrap;
}
</style>
