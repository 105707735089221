import API from "./API"

const fetchSeedSelection = (
  fieldId,
  cropId,
  irrigationType,
  riskTolerance,
  seedCompanyId,
  filterResults,
  relativeMaturity
) =>
  API.post(`/seed-selection/`, {
    field_id: fieldId,
    crop_id: cropId,
    irrigation_type: irrigationType,
    risk_tolerance: riskTolerance,
    seed_company_id: seedCompanyId,
    filter_results: filterResults,
    relative_maturity: relativeMaturity,
  })

const fetchSeedCompanies = () => API.get("/seed-companies/")

const fetchCompaniesByCrop = crop =>
  API.post("/seed-companies-crop/", { crop_id: crop })

const fetchSeedsByCompany = (company, crop) =>
  API.post("/seeds-for-company/", { seed_company: company, crop_id: crop })

const fetchSeedPlacement = inputs =>
  API.post("/seed-placement/", { inputs: inputs })

const placementPlansEndpoint = "/seed-placement-plan/"

const fetchSeedPlans = () => API.get(placementPlansEndpoint)

const createSeedPlan = payload => API.post(placementPlansEndpoint, payload)

const updateSeedPlan = (placementPlanId, payload) =>
  API.put(`${placementPlansEndpoint}${placementPlanId}/`, payload)

const deleteSeedPlan = planId =>
  API.delete(`${placementPlansEndpoint}${planId}/`)

const fetchSeedPlanPdf = seedPlanJson =>
  API.post("/seed-plan-pdf-data/", { seed_selector_data: seedPlanJson })

const fetchCropsWithTrials = () => API.post("/crops-with-trials/")

export default {
  fetchSeedSelection,
  fetchSeedCompanies,
  fetchCompaniesByCrop,
  fetchSeedsByCompany,
  fetchSeedPlacement,
  fetchSeedPlans,
  fetchSeedPlanPdf,
  createSeedPlan,
  deleteSeedPlan,
  updateSeedPlan,
  fetchCropsWithTrials,
}
