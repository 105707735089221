<template>
  <tr class="grey lighten-3">
    <th class="pl-2" v-if="showIcon">
      <v-icon right>mdi-filter</v-icon>
    </th>
    <th v-for="header in headers" :key="header.text">
      <div v-if="filters.hasOwnProperty(header.value)">
        <v-autocomplete
          flat
          hide-details
          multiple
          attach
          chips
          dense
          clearable
          :items="columnValueList(header.value)"
          v-model="filters[header.value]"
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < 5">
              <span>
                {{ item }}
              </span>
            </v-chip>
            <span v-if="index === 5" class="grey--text caption">
              (+{{ filters[header.value].length - 5 }} others)
            </span>
          </template>
        </v-autocomplete>
      </div>
    </th>
  </tr>
</template>

<script>
export default {
  name: "VDataTablesMultiSelectHeader",
  props: ["items", "headers", "filters", "showIcon"],
  computed: {
    filteredValues() {
      return this.items.filter(d => {
        return Object.keys(this.filters).every(f => {
          return this.filters[f].length < 1 || this.filters[f].includes(d[f])
        })
      })
    },
  },

  watch: {
    filteredValues() {
      this.$emit("filtered-data", this.filteredValues)
    },
  },

  methods: {
    columnValueList(val) {
      return this.items.map(d => {
        return d[val]
      })
    },
  },
}
</script>
