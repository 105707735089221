<template>
  <ArvaModal
    name="editFieldAssignmentModal"
    title="Edit Field Assignment"
    :wide="true"
    :width="1100"
    @close-modal="handleCloseModal"
  >
    <v-card class="pa-5">
      <form @submit.prevent="handleSubmit">
        <div>
          <v-card class="pa-4">
            <v-chip color="green lighten-1 mb-2" v-if="updateOrderID">
              Subprogram: {{ updateOrderName }} - ({{ updateOrderID }})
            </v-chip>
            <br />
            <v-chip
              class="py-6"
              v-if="insetOrderRollupName"
              color="orange lighten-1 mb-2"
            >
              Program Rollup: {{ insetOrderRollupName }} (Avg Co2e:
              {{ insetOrderRollupCO2e }}) <br />
              Acres Allocated vs Alloted: {{ insetOrderRollupAcreSummary }}
            </v-chip>
            <v-expansion-panels focusable v-model="panels">
              <v-expansion-panel v-if="fieldSupply.length > 0">
                <v-expansion-panel-header
                  >Fields Currently Allocated In this Program ({{
                    totalAcreageInProgram.toFixed(2)
                  }}
                  ac)</v-expansion-panel-header
                >
                <!--<h4>Requested Order Filters:</h4>-->
                <v-expansion-panel-content>
                  <span></span>
                  <v-text-field
                    v-model="includedSearch"
                    label="Search"
                    prepend-inner-icon="mdi-magnify"
                    variant="outlined"
                    hide-details
                    single-line
                  ></v-text-field>
                  <v-data-table
                    v-model="selectedToRemove"
                    :headers="supplyHeaders"
                    :items="fieldSupply"
                    :search="includedSearch"
                    :single-select="false"
                    show-select
                    item-key="fss_id"
                  ></v-data-table>
                  <v-btn
                    color="primary"
                    v-if="selectedToRemove.length > 0"
                    @click="removeFields"
                    >Remove Selected {{ selectedToRemove.length }} Field(s) ({{
                      toRemoveAcreage.toFixed(1)
                    }}
                    ac) From Program</v-btn
                  >
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel v-if="fieldSupplyEligible.length > 0">
                <v-expansion-panel-header
                  >Additional Fields Available to add to this Program ({{
                    totalAcreageEligible.toFixed(2)
                  }}
                  ac)</v-expansion-panel-header
                >
                <!--<h4>Requested Order Filters:</h4>-->
                <v-expansion-panel-content>
                  <v-text-field
                    v-model="eligibleSearch"
                    label="Search"
                    prepend-inner-icon="mdi-magnify"
                    variant="outlined"
                    hide-details
                    single-line
                  ></v-text-field>
                  <v-data-table
                    v-model="selectedToInclude"
                    :headers="supplyHeaders"
                    :items="fieldSupplyEligible"
                    :search="eligibleSearch"
                    show-select
                    item-key="fss_id"
                  ></v-data-table>
                  <v-btn
                    color="primary"
                    v-if="selectedToInclude.length > 0"
                    @click="addFields"
                    >Add Selected {{ selectedToInclude.length }} Field(s) ({{
                      toAddAcreage.toFixed(1)
                    }}
                    ac) to Program
                  </v-btn>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <div
              v-if="showLoading"
              class="mt-3 ml-6 d-flex justify-content-center"
            >
              <div class="text-bold mb-0">Fetching Data</div>
              <div class="spinner-border ml-4" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div v-else>
              <h5
                v-if="fieldSupplyEligible.length < 1 && fieldSupply.length < 1"
              >
                No Fields Currently Available to Add or Remove
              </h5>
            </div>
          </v-card>

          <ArvaModalSaveFooter
            confirmText="Return"
            cancelText="Cancel"
            @on-cancel="handleCloseModal"
            @on-confirm="handleSubmit"
          />
        </div>
      </form>
    </v-card>
  </ArvaModal>
</template>

<script>
import ArvaModal from "@/components/modals/ArvaModal"
import ArvaModalSaveFooter from "@/components/modals/ArvaModalSaveFooter"
import CarbonReadyAPI from "@/api/CarbonReadyAPI"

export default {
  name: "EditFieldAssignmentModal",
  props: ["updateOrderID", "updateOrderName"],
  components: { ArvaModal, ArvaModalSaveFooter },

  data() {
    return {
      panels: 0,
      previewDataFrame: null,
      orderName: null,
      showError: false,
      orderPrice: 15,
      orderGeoids: [],
      supplyTarget: 10000,
      errorText: "Please Provide an Unique Order Name",
      aggregationMetric: "acreage",
      aggregationMetrics: ["acreage", "harvest_amount", "scope3_emission"],
      fieldSupplyEligible: [],
      fieldSupply: [],
      selectedToRemove: [],
      selectedToInclude: [],
      includedSearch: "",
      eligibleSearch: "",
      totalAcreageInProgram: 0,
      totalAcreageEligible: 0,
      showLoading: false,
      insetOrderRollupCO2e: null,
      insetOrderRollupName: null,
      insetOrderRollupAcreSummary: "",
      supplyHeaders: [
        { text: "Field ID", value: "field_id" },
        { text: "Field", value: "field" },
        { text: "Client", value: "client" },
        { text: "Channel Partner", value: "channel_partner" },
        { text: "Acreage", value: "acreage" },
        { text: "CO2e/ac", value: "average_co2e" },
        { text: "Crop", value: "crop" },
        //{ text: "Enroll ID", value: "enrollment_id" },
      ],
    }
  },

  computed: {
    toAddAcreage() {
      return this.selectedToInclude
        .map(e => e.acreage)
        .reduce((a, b) => a + b, 0)
    },
    toRemoveAcreage() {
      return this.selectedToRemove
        .map(e => e.acreage)
        .reduce((a, b) => a + b, 0)
    },
  },

  methods: {
    handleCloseModal() {
      this.$emit("close-modal")
    },
    handleSubmit() {
      this.$emit("close-modal")
    },

    removeFields() {
      let payload = {
        fieldSupplyIds: this.selectedToRemove.map(e => e.fss_id),
        order_id: this.updateOrderID,
        job_type: "remove",
      }

      CarbonReadyAPI.postEAOrderFSS(payload)
        .then(response => {
          if (response.status == 200) {
            this.refreshFSSList()
            this.selectedToRemove = []
            //this.$emit("save-from-modal")
          } else {
            this.errorText = response.data
            this.showError = true
          }
        })
        .catch(err => {
          this.errorText = err.response.data
          this.showError = true
        })
    },
    addFields() {
      this.showError = false
      let payload = {
        fieldSupplyIds: this.selectedToInclude.map(e => e.fss_id),
        order_id: this.updateOrderID,
        job_type: "add",
      }

      CarbonReadyAPI.postEAOrderFSS(payload)
        .then(response => {
          if (response.status == 200) {
            this.refreshFSSList()
            this.selectedToInclude = []
            //this.$emit("save-from-modal")
          } else {
            this.errorText = response.data
            this.showError = true
          }
        })
        .catch(err => {
          this.errorText = err.response.data
          this.showError = true
        })
    },

    refreshFSSList() {
      this.showLoading = true
      this.fieldSupplyEligible = []
      this.fieldSupply = []
      this.totalAcreageEligible = 0
      this.totalAcreageInProgram = 0
      CarbonReadyAPI.getEAOrderFSS({
        order_id: this.updateOrderID,
        restrict_corporation: 0,
      }).then(response => {
        this.getInsetRollup()

        //console.log("here?")
        this.fieldSupplyList = response.data
        //console.log(response.data)
        this.fieldSupplyList.forEach(fss => {
          //let loi_name = enroll["loi_entity_name"]
          //enroll["fieldsupply"].forEach(fss => {
          let fss_dict = {
            field: fss["fieldcrop"]["field_name"],
            field_id: fss["fieldcrop"]["field_id"],
            client: fss["client"],
            crop: fss["fieldcrop"]["crop_name"],
            year: fss["fieldcrop"]["year"],
            fss_id: fss["id"],
            channel_partner: fss["channel_partner_name"],
            acreage: fss["fieldcrop"]["acreage"],
            average_co2e: fss["scope3_emission"],
            //loi_entity: loi_name,
            //enrollment_id: enroll["id"],
          }
          if (fss["matched_program"] == null) {
            this.fieldSupplyEligible.push(fss_dict)
            this.totalAcreageEligible += fss["fieldcrop"]["acreage"]
          } else if (fss["matched_program"] == this.updateOrderID) {
            this.fieldSupply.push(fss_dict)
            this.totalAcreageInProgram += fss["fieldcrop"]["acreage"]
          }
        })
        this.showLoading = false
      })
    },

    getInsetRollup() {
      if (this.updateOrderID != null) {
        let params = { order_id: this.updateOrderID }

        CarbonReadyAPI.getInsetOrderRollup(params).then(response => {
          this.insetOrderRollupName = response.data.name
          this.insetOrderRollupCO2e = response.data.average_co2e
          this.insetOrderRollupAcreSummary =
            response.data.acres_allocated + " / " + response.data.acres_allotted
        })
      }
    },
  },

  mounted() {
    if (this.updateOrderID != null) {
      this.refreshFSSList()
      this.getInsetRollup()
    }
  },
}
</script>

<style scoped>
.pw-error {
  color: red;
  font-weight: 500;
}
</style>
