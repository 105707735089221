<template>
  <ArvaModal
    name="dataImportModal"
    :title="modalTitle"
    @close-modal="handleCloseModal"
  >
    <!-- Initial View -->
    <div v-if="state === 'initial'" class="row modal-pad">
      <div class="col-12">
        <div class="row">
          <div class="col-12">
            <div class="info-text">
              You can import files by either connecting an online account (John
              Deere, Climate, etc.), or uploading files directly from your
              computer
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-around my-4">
          <div class="selection-box" @click="openJD">
            <div class="logo logo-john-deere mx-auto" />
            <div class="selection-text">Connect to John Deere</div>
          </div>
          <div
            id="select-data-type"
            class="selection-box"
            @click="state = 'select-data-type'"
          >
            <div>
              <i class="fa fa-laptop" />
            </div>
            <div class="selection-text">Import from your computer</div>
          </div>
        </div>
        <div class="d-flex justify-content-around my-4">
          <div class="selection-box" @click="state = 'climate'">
            <div class="logo logo-climate mx-auto" />
            <div class="selection-text">Connect to Climate</div>
          </div>
        </div>
      </div>
    </div>

    <!-- Select Data Type View -->
    <div v-if="state === 'select-data-type'" class="row modal-pad">
      <div class="col-12">
        <SelectOrg :orgNodes="orgNodes" @change-org-id="changeOrgId" />

        <div class="info-text">
          Select the data type you're about to import. Please ensure that you
          only upload files of the selected type.
        </div>

        <div class="form-group">
          <label for="datatype">Data Type</label>
          <v-select-old
            :options="datasetTypes"
            :reduce="type => type.key"
            label="name"
            v-model="datasetType"
          />

          <button
            class="btn btn-primary"
            :class="{ disabled: !datasetType }"
            :disabled="!datasetType"
            @click="handleNextState()"
          >
            Next
          </button>
        </div>
      </div>
    </div>

    <!-- Client/Farm/Field Confirmations View -->
    <div v-if="state === 'confirmation-client-spec'" class="row modal-pad confirmation-modal pb-5">
      <div class="col-8 offset-2 text-center">
        <label class="text-left w-100 mb-0">Confirmation Type<span class="asterisk-super">*</span></label>
        <v-select
          class="mt-0 pt-0"
          role="button"
          v-model="confirmationType"
          placeholder="Type"
          :items="confirmationTypeChoices"
          item-text="name"
          item-value="value"
          clearable
        />

        <div class="confirmation-client-spec-modal">
          <label class="text-left w-100 mb-0">Choose Year<span class="asterisk-super">*</span></label>
          <v-select
            class="mt-0 pt-0"
            role="button"
            v-model="confirmationYear"
            placeholder="Year"
            :items="getYearOptions(2019)"
            clearable
          />

          <div>
            <label class="text-left w-100 mb-0">Choose Client<span class="asterisk-super">*</span></label>
            <v-select
              class="mt-0 pt-0"
              role="button"
              v-model="confirmationClient"
              placeholder="Client"
              item-text="name"
              item-value="id"
              :items="clients"
              clearable
            />
          </div>

          <div>
            <label class="text-left w-100 mb-0">Choose Farms</label>
            <v-select
              class="mt-0 pt-0"
              role="button"
              v-model="confirmationFarms"
              placeholder="Farm"
              item-text="name"
              item-value="id"
              :items="farms"
              clearable
              multiple
              chips
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index < 2">
                  <span>{{ item.name }}</span>
                </v-chip>

                <span v-if="index === 2" class="text-grey text-caption align-self-center">
                  (+{{ confirmationFarms.length - 2 }} other{{ confirmationFarms.length == 3 ? '' : 's' }})
                </span>
              </template>
            </v-select>
          </div>

          <label class="text-left w-100 mb-0">Choose Crop</label>
          <v-select
            class="mt-0 pt-0"
            role="button"
            v-model="confirmationCrops"
            placeholder="Crop"
            item-text="name"
            item-value="id"
            :items="crops"
            clearable
            multiple
            chips
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index < 2">
                <span>{{ item.name }}</span>
              </v-chip>

              <span v-if="index === 2" class="text-grey text-caption align-self-center">
                (+{{ confirmationCrops.length - 2 }} other{{ confirmationCrops.length == 3 ? '' : 's' }})
              </span>
            </template>
          </v-select>

          <div v-if="loadingFieldResponse" class="spinner-border spinner-border-sm spinner" role="status" />
          <div v-else >
            <label class="text-left w-100 mb-0">Choose Fields</label>
            <v-select
              class="mt-0 pt-0"
              role="button"
              v-model="confirmationFields"
              placeholder="Field"
              item-text="name"
              item-value="id"
              :items="fields"
              clearable
              multiple
              chips
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index < 2">
                  <span>{{ item.name }}</span>
                </v-chip>
  
                <span v-if="index === 2" class="text-grey text-caption align-self-center">
                  (+{{ confirmationFields.length - 2 }} other{{ confirmationFields.length == 3 ? '' : 's' }})
                </span>
              </template>
            </v-select>
          </div>

          <button
            :class="`btn btn-primary confirmation-btn ${!confirmationValidation ? 'disabled' : ''}`"
            :disabled="!confirmationValidation"
            @click="handleNextState()"
          >
            Next
          </button>
        </div>
      </div>
    </div>

    <!-- Upload View -->
    <div v-if="state === 'upload'" class="row modal-pad">
      <div class="col-12 text-center">
        <div
          class="upload-box"
          :class="{ dropping: dropActive }"
          @click="handleUploadClick"
        >
          <i class="fa fa-upload" :class="{ green: dropActive }" />
          <h2>Drop files here to upload</h2>
          <div v-if="datasetType == 'boundary'">
            <i class="fa fa-file" />.zip .shp .dbf .prj .shx .kml .kmz .geojson
          </div>
          <div v-else-if="datasetType == 'practice-confirmations'">
            <i class="fa fa-file" />
            .zip, .csv, .jpg, .jpeg, .png, .pdf
          </div>
          <div v-else-if="datasetType == 'sustainability-quantification-report'">
            <i class="fa fa-file" />.csv
          </div>
          <div v-else><i class="fa fa-file" />.zip .shp .dbf .prj .shx</div>
          <div>These files may include information for multiple fields</div>
        </div>
      </div>
    </div>

    <!-- Climate Choice View -->
    <div v-if="state === 'climate'" class="row modal-pad">
      <div class="col-12">
        <div class="d-flex justify-content-around my-4">
          <div class="selection-box" @click="openLeaf">
            <div class="selection-text">Import from Your Account</div>
          </div>
          <div class="selection-box" @click="state = 'climate-choice'">
            <div class="selection-text">Import from Client Account</div>
          </div>
        </div>
      </div>
    </div>

    <!-- Climate Client View -->
    <div v-if="state === 'climate-choice'" class="row modal-pad">
      <div class="col-12">
        <div class="text-center">
          <span class="climate-text">Select which client to import from:</span>
        </div>
        <div class="d-flex justify-content-around my-2">
          <div class="select-container">
            <v-select-old
              :options="orgNodes"
              :reduce="node => node.id"
              label="name"
              v-model="orgNodeForClimate"
            />
          </div>
        </div>
        <div class="text-center pt-4">
          <span class="climate-text">Enter grower email:</span>
        </div>
        <div class="d-flex justify-content-around my-2">
          <div class="select-container">
            <v-text-field
              dense
              outlined
              label="Grower Email"
              v-model="growerEmail"
              type="text"
            ></v-text-field>
          </div>
        </div>
        <div class="d-flex justify-content-around">
          <v-btn
            @click="handleSendGrowerEmail"
            :disabled="!orgNodeForClimate || !growerEmail"
            >Import</v-btn
          >
        </div>
      </div>
    </div>

    <!-- Climate API Result View -->
    <div
      v-if="state === 'client-result'"
      class="row modal-pad complete-pad text-center"
    >
      <div class="col-12 complete">
        <div>
          <i class="fa fa-check-circle complete-check" />
        </div>
        <h2>
          An email regarding your client's climate import has been sent
          successfully!
        </h2>
        <button class="btn btn-primary" @click="handleCloseModal">Close</button>
      </div>
    </div>

    <!-- Uploading Progress View -->
    <div v-if="state === 'uploading'" class="row modal-pad file-upload-pad">
      <div class="col-12">
        <h5 class="upload-title">
          <i class="fa fa-cloud-upload" />Upload Progress
        </h5>
        <small class="form-text text-muted">
          Please don't close your browser window, or your upload will be
          interrupted.
        </small>

        <div class="progress" style="height: 20px;">
          <div
            class="progress-bar bg-success"
            role="progressbar"
            :style="uploadWidth"
            :aria-valuenow="uploadPercentage"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            {{ uploadPercentage }}%
          </div>
        </div>
      </div>
    </div>

    <!-- Complete View -->
    <div
      v-if="state === 'complete'"
      class="row modal-pad complete-pad text-center"
    >
      <div class="col-12 complete">
        <div>
          <i class="fa fa-check-circle complete-check" />
        </div>
        <h2>Upload Complete!</h2>
        <button class="btn btn-primary" @click="handleCloseModal">Close</button>
      </div>
    </div>
  </ArvaModal>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex"

import ArvaModal from "./ArvaModal"
import { Filter } from "@/store/modules"
import JohnDeereAPI from "@/api/JohnDeereAPI"
import LeafAPI from "@/api/LeafAPI"
import ClimateAPI from "@/api/ClimateAPI"
import SelectOrg from "@/components/uploads/SelectOrg"
import { EVIDENCING_TYPE_CHOICES } from "@/constants/defaults";
import { getYearsToNow } from "@/utility";

export default {
  name: "DataImportModal",
  props: {
    dropActive: { type: Boolean },
    isUploading: { type: Boolean },
    datasetTypes: { type: Array },
    orgNodes: { type: Array },
    initialDatasetType: { type: String },
    initialState: { type: String }
  },
  components: { ArvaModal, SelectOrg },
  data() {
    return {
      selection: null,
      johnDeereURL: null,
      leafURL: null,
      state: "initial",
      datasetType: null,
      orgNodeForClimate: null,
      growerEmail: null,
      confirmationType: '',
      confirmationClient: '',
      confirmationYear: '',
      confirmationFarms: [],
      confirmationFields: [],
      confirmationCrops: [],
      loadingFieldResponse: false
    }
  },

  computed: {
    ...mapState({
      organization: state => state.Organization.organization,
      uploadPercentage: state => state.Uploads.uploadPercentage,
    }),

    ...mapGetters({
      crops: Filter.Getters.getCrops,
      farms: Filter.Getters.getFarms,
      fields: Filter.Getters.getFields,
      clients: Filter.Getters.getClients,
      activeFields: Filter.Getters.getSelectedFields
    }),

    confirmationTypeChoices() {
      return EVIDENCING_TYPE_CHOICES
    },

    confirmationValidation() {
      return this.confirmationType
        && this.confirmationYear
        && this.confirmationClient
    },

    modalTitle() {
      let title = "Import Files"
      const datasetType = this.datasetTypes.find(
        item => item.key === this.datasetType
      )
      const additional = datasetType ? datasetType.name : ""

      return additional ? `${title} - ${additional}` : title
    },

    uploadWidth() {
      return `width: ${this.uploadPercentage}%;`
    }
  },

  methods: {
    ...mapActions({
      createDataset: "Dataset/createDataset",
      listDatasets: "Dataset/listDatasets",
    }),

    getYearOptions(beginningYear) {
      return [new Date().getFullYear()+1 , ...getYearsToNow({ beginningYear }).sort((a, b) => b-a)];
    },

    handleCloseModal() {
      this.$emit("close-modal")
    },

    handleNextState() {
      let newState = 'upload';

      if (this.datasetType == 'practice-confirmations') {
        this.$emit("change-confirmation-client-specs", {
          type: this.confirmationType,
          year: this.confirmationYear,
          farms: this.confirmationFarms,
          fields: this.confirmationFields,
          crops: this.confirmationCrops,
          client: this.confirmationClient
        });

        if (this.state != 'confirmation-client-spec') {
          newState = 'confirmation-client-spec'
        }
      }

      this.state = newState;
    },

    openJD() {
      if (this.johnDeereURL) {
        window.open(this.johnDeereURL)
        this.handleCloseModal()
      }
    },

    openLeaf() {
      if (this.leafURL) {
        window.open(this.leafURL)
        this.handleCloseModal()
      }
    },

    handleSendGrowerEmail() {
      const payload = {
        org_node_id: this.orgNodeForClimate,
        email: this.growerEmail,
      }
      ClimateAPI.postClimateOrgTransfer(payload)
        .then(response => {
          console.log(response)
          this.state = "client-result"
        })
        .catch(error => {
          console.log(error)
        })
    },

    changeOrgId(org_id) {
      this.$emit("change-org-id", org_id)
    },

    handleUploadClick() {
      this.$emit("open-upload")
    },
  },

  watch: {
    datasetType() {
      this.$emit("change-dataset-type", this.datasetType)
    },

    isUploading() {
      if (this.isUploading) this.state = "uploading"
    },

    uploadPercentage() {
      if (this.uploadPercentage === 100) this.state = "complete"
    },
  },

  async mounted() {
    if (this.initialDatasetType) {
      this.datasetType = this.initialDatasetType;

      if (this.initialState) {
        this.state = this.initialState
      }
      else {
        this.state = 'upload';
      }
    }

    const { data: jdUrl } = await JohnDeereAPI.fetchJohnDeereUrls()
    this.johnDeereURL = jdUrl.sso

    const { data: leaf_url } = await LeafAPI.fetchLeafUrl(this.organization.id)
    this.leafURL = leaf_url.leaf_url.url
  },
}
</script>

<style scoped>
.modal-pad {
  padding-bottom: 35px;
}

.info-text {
  text-align: center;
  max-width: 430px;
  margin: 20px auto;
  color: #777;
}

.selection-box {
  padding: 20px;
  border: 1pt solid #e5e5e5;
  min-width: 200px;
  text-align: center;
  cursor: pointer;
}

.selection-box:hover {
  background: #f8f8f8;
  border: 1pt solid #d5d5d5;
}

.selection-box i {
  font-size: 60px;
}

.select-container {
  width: 320px;
}

.selection-text {
  font-size: 13px;
  font-weight: 500;
  color: #666;
}

.form-group {
  margin: 20px auto 90px auto;
  padding: 0px 100px;
}

.form-group > button {
  margin-top: 20px;
  margin-right: 0px;
  float: right;
}

.upload-box {
  width: 450px;
  background: #f0f0f0;
  border: 1pt dotted black;
  margin: 20px auto;
  padding: 30px;
  cursor: pointer;
}

.upload-box:hover {
  background: #eaeaea;
}

.dropping {
  background: rgba(89, 255, 140, 0.35);
}

.upload-box .fa-upload {
  font-size: 30px;
}

.upload-box .green {
  color: green;
}

.upload-box h2 {
  font-size: 20px;
  margin-top: 10px;
  font-weight: 500;
}

.upload-box .fa-file {
  margin-right: 5px;
}

.file-upload-pad {
  padding: 40px;
  text-align: center;
}

.upload-title {
  font-size: 20px;
  font-weight: 500;
}

.upload-title i {
  font-size: 20px;
  margin-right: 5px;
}

.text-muted {
  margin-bottom: 10px;
}

.complete-pad {
  margin-top: 40px;
}

.complete h2 {
  margin-top: 10px;
  margin-bottom: 30px;
}

.complete i {
  font-size: 55px;
  color: green;
}

.logo-john-deere {
  width: 60px;
  height: 60px;
  background-image: url("/assets/images/logos/john-deere-logo.png");
  background-size: contain;
  filter: grayscale(100%);
  margin-left: auto;
  margin-right: auto;
}

.logo-climate {
  width: 60px;
  height: 60px;
  background-image: url("/assets/images/logos/climate-logo.jpg");
  background-size: contain;
  filter: grayscale(100%);
  margin-left: auto;
  margin-right: auto;
}

.climate-text {
  font-weight: 400;
  font-size: 18px;
}

.confirmation-client-spec-modal {
  min-height: 535.5px;
  position: relative;
} 

.confirmation-client-spec-modal label {
  font-size: 13px;
  line-height: 32px;
}

.confirmation-client-spec-modal /deep/ .confirmation-number input {
  text-transform: uppercase;
}

.confirmation-client-spec-modal /deep/ .confirmation-number .v-input__append-inner {
  margin-top: 8px;
}

.confirmation-client-spec-modal /deep/ .v-input .v-input__slot {
  min-height: 41px;
}

.disabled-label {
  opacity: 0.5;
}

.confirmation-modal {
  min-height: 550px;
}

.confirmation-btn {
  position: absolute;
  bottom: 0;
  right: 0;
}

.confirmation-client-farm-wrapper {
  display: flex;
  justify-content: space-between;
}

.confirmation-client-farm-wrapper > div {
  width: 48%;
}

.asterisk-super {
  vertical-align: super;
}
</style>
