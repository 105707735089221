<template>
  <ArvaModal
    name="CropForceWelcomeModal"
    title="Welcome To CropForce"
    :wide="true"
    :width="700"
    @close-modal="handleClose"
  >
    <div class="row">
      <div class="col-12 details">
        <div class="detail-row">
          <div class="info-text">
              {{user.first_name}} {{user.last_name}}, welcome to the Arva CropForce platform!<br>
              <strong>Have questions?</strong> <br>
              Please select the Support Chat Box in the lower right corner of this display. <br><br>
          </div>
        </div>
        <div class="detail-row info-text pb-2">
          <input
            @click="checkboxClicked"
            type="checkbox"
            name="selected"
            ref="fieldSelectedCheckbox"
          /> Don't show me this again
        </div>
      </div>
    </div>

    <ArvaModalSaveFooter
      confirmText="Save"
      cancelText="Cancel"
      @on-cancel="handleClose"
      @on-confirm="handleConfirm"
    />
  </ArvaModal>
</template>

<script>
import ArvaModal from "./ArvaModal"
import ArvaModalSaveFooter from "./ArvaModalSaveFooter"
import UserAPI from "@/api/UserAPI"

export default {
  name: "CropForceWelcomeModal",
  props: ["user"],

  components: { ArvaModal, ArvaModalSaveFooter },

  data() {
    return {
        showWelcomeModal: true,
    }
  },

  methods: {
    async handleConfirm() {
      await UserAPI.displayWelcomeModal(this.user.id, this.showWelcomeModal)
      this.handleClose()
    },
    handleClose() {
        localStorage.removeItem("initialLoading")
        localStorage.setItem("initialLoading", "no");
        this.$emit("close-modal")
    },
    checkboxClicked() {
        const checkbox = this.$refs.fieldSelectedCheckbox
        this.showWelcomeModal = !checkbox.checked
    },
  },
}
</script>

<style scoped>
.info-text {
  margin: 0px 20px;
  font-size: 15px;
  text-align: left;
}

.checkbox {
  margin: 0px 20px;
}
</style>