<template>
  <b-tr>
    <!-- date -->
    <b-td
      class="cell-table medium-column"
      :class="{
        'date-column': !additionView,
        'date-column-add': additionView,
        ...getClassForCell(soilSample.date)
      }"
    >
      <v-menu
        v-model="showDate"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <div class="d-flex">
            <div class="date-holder">
              <v-text-field
                v-model="date"
                dense
                readonly
                hide-details="true"
                v-bind="attrs"
                v-on="on"
                :append-icon="getAppendIconForCell(soilSample.date)"
                @click:append="handleClickSubmit('date', date)"
              ></v-text-field>
            </div>
          </div>
        </template>
        <v-date-picker
          v-model="date"
          :min="yearPriorThree + '-01-01'"
          :max="year + '-12-31'"
          @input="showDate = false"
          scrollable
        ></v-date-picker>
      </v-menu>
    </b-td>
    <!-- type -->
    <b-td
      class="cell-table medium-column"
      :class="getClassForCell(soilSample.type)"
    >
      <v-select
        clearable
        dense
        hide-details="true"
        :items="soilSampleTypeChoices"
        item-text="display_name"
        item-value="value"
        v-model="ssType"
        :append-icon="getAppendIconForCell(soilSample.type)"
        @click:append="handleClickSubmit('type', ssType)"
      />
    </b-td>
    <b-td class="cell-table white">
      <v-btn icon x-small color="black" @click="handleAdd">
        <v-icon>mdi-plus-circle-outline</v-icon>
      </v-btn>
      <v-btn icon x-small color="black" @click="handleDelete">
        <v-icon>mdi-close-circle-outline</v-icon>
      </v-btn>
    </b-td>
  </b-tr>
</template>
<script>
import { mapMutations } from "vuex"
import { SOIL_SAMPLE_TYPE_CHOICES } from "@/constants/defaults"
import { Defaults } from "@/store/modules"
import { getAppendIconForCell, getClassForCell } from "../utils"

export default {
  name: "SoilSampleRow",
  props: {
    additionView: { type: Boolean, default: false },
    cropId: { type: Number },
    field: { type: String },
    soilSample: { type: Object },
    rowIndex: { type: Number },
    year: { type: String },
  },
  data() {
    return {
      soilSampleTypeChoices: SOIL_SAMPLE_TYPE_CHOICES,
      showDate: false,
      getClassForCell,
      getAppendIconForCell
    }
  },
  computed: {
    yearPriorThree() {
      return String(Number(this.year) - 3)
    },
    date: {
      get() {
        return this.soilSample.date.value
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "soil_sampling",
          dataSetting: "date",
          value: val,
          rowIndex: this.rowIndex,
        }
        if (!this.additionView) {
          this.spreadsheetMutate(updatePayload)
        } else {
          this.soilSample.date.value = val
        }
      },
    },
    ssType: {
      get() {
        return this.soilSample.type.value
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "soil_sampling",
          dataSetting: "type",
          value: val,
          rowIndex: this.rowIndex,
        }
        if (!this.additionView) {
          this.spreadsheetMutate(updatePayload)
        } else {
          this.soilSample.type.value = val
        }
      },
    },
  },
  methods: {
    ...mapMutations({
      addFieldSettingRow: Defaults.Mutations.addFieldSettingRow,
      deleteNewFieldSetting: Defaults.Mutations.deleteNewFieldSetting,
      updateNewFieldSetting: Defaults.Mutations.updateNewFieldSetting,
      spreadsheetMutate: Defaults.Mutations.spreadsheetMutate,
    }),
    handleClickSubmit(setting, val) {
      this[setting] = val
    },
    handleCopy() {
      this.$emit("handle-copy", this.soilSample)
    },
    handleAdd() {
      const payloadObj = {
        crop_id: { source: "User Input", value: this.cropId },
        date: { source: "User Input", value: null },
        type: { source: "User Input", value: null },
      }
      this.addFieldSettingRow({
        fieldId: this.field,
        cropId: this.cropId,
        dataCategory: "soil_sampling",
        dataObj: payloadObj,
        year: this.year,
      })
    },
    handleDelete() {
      var defaultObj = {}
      for (const [key, value] of Object.entries(this.soilSample)) {
        if (key === "crop_id") defaultObj[key] = value
        else defaultObj[key] = { source: "User Input", value: null }
      }

      this.deleteNewFieldSetting({
        fieldId: this.field,
        cropId: this.cropId,
        dataCategory: "soil_sampling",
        defaultObj: defaultObj,
        rowIndex: this.rowIndex,
        year: this.year,
      })
    },
  },
}
</script>
<style scoped>
.arva-alert {
  background-color: #f7c0be;
}

.arva-generated {
  background-color: #fedb80;
}

.pre-gen {
  background-color: #fedb80;
}

.bg-white {
  color: white;
}

.cell-table {
  padding: 2px 6px;
}

.cell-text {
  font-weight: 600;
  color: white;
}

.machine {
  background-color: #dbe6d6;
}

.complete {
  background-color: #c7e3c1;
}

.copy-icon {
  cursor: pointer;
  color: #0f9aee;
}

.input-check {
  cursor: pointer;
}

.select-check {
  margin-top: 14px;
  cursor: pointer;
}

.submit-icon {
  color: #c7e3c1;
}

.delete {
  color: #cc0101;
  cursor: pointer;
}

.copy-col {
  min-width: 44px;
}

.del-col {
  min-width: 40px;
}

.medium-column {
  width: 140px;
}

.large-column {
  width: 240px;
}

.marge-column {
  width: 180px;
}

.v-input {
  font-size: 18px;
}
</style>
