import API from "./API"

const fetchJohnDeereUrls = () => API.get("jdurl")

const fetchJohnDeereStage = () => API.get("jd-stage")

const fetchJohnDeereOrgs = () => API.get("/jd-org")

const importJohnDeereData = (data_type, payload) =>
  API.post(`/jd-org/import_data/?data_type=${data_type}`, payload)

const configureJohnDeereOrg = payload => API.post(`/jd-org/set_org/`, payload)

const updateJohnDeereClients = (pk, payload) => API.post(`/jd-org/${pk}/set_clients/`, payload)

export default {
  fetchJohnDeereUrls, fetchJohnDeereStage, fetchJohnDeereOrgs,
  importJohnDeereData, configureJohnDeereOrg, updateJohnDeereClients
}
