<template>
    <div v-if="enabled" class="audio-recorder">
      <div v-if="showFailureIcon" class="fade-out">
        <v-icon color="red">mdi-alert-circle-outline</v-icon>
      </div>
      <div v-else-if="showSuccessIcon" class="fade-out">
        <v-icon color="green">mdi-check</v-icon>
      </div>
      <button v-else-if="!isRecording && !isProcessing" @click.stop="startRecording" class="icon-button">
        <v-tooltip right >
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on">mdi-microphone</v-icon>
          </template>
          <span>
            Click to describe this practice by voice.
            <br />
            Always review practice data before saving.
          </span>
       </v-tooltip>
      </button>
      <button v-else-if="isProcessing" class="icon-button">
        <v-progress-circular indeterminate color="grey" size="20"></v-progress-circular>
      </button>
      <button v-else-if="isRecording" @click.stop="stopRecording" class="icon-button pulsing">
        <v-icon>mdi-stop-circle-outline</v-icon>
      </button>

    </div>
</template>

<script setup>

import { ref, defineComponent } from 'vue';
import { Fields } from "@/store/modules"
import store from "@/store"

import DefaultsAPI from "@/api/DefaultsAPI";

const props = defineProps({
    practiceIndex: {
        type: Number,
    },
    model: {
        type: String,
    },
    year: {
        type: Number,
    },
});

const emit = defineEmits(['practice-response']);

const enabled = store.getters[Fields.Getters.getFeatureFlags]['speech_to_practice'] === true;

const isRecording = ref(false);
const isProcessing = ref(false);
const showFailureIcon = ref(false);
const showSuccessIcon = ref(false);

let mediaRecorder = null;

let audioChunks = [];

let startTime = 0;
const startRecording = async () => {
    startTime = new Date().getTime();

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            mediaRecorder = new MediaRecorder(stream);
            audioChunks = [];
            mediaRecorder.ondataavailable = event => {
                audioChunks.push(event.data);
            };

          mediaRecorder.onstop = async () => {
              const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
              // is audio less than 1s or longer than 30s? ignore
              const duration = new Date().getTime() - startTime;
              if (duration < 1000 || duration > 30000) {
                  isRecording.value = false;
                  return;
              }
              isProcessing.value = true;
              isRecording.value = false;
              const formData = new FormData();
              formData.append('audio', audioBlob);
              formData.append('model', props.model);
              formData.append('year', props.year);
              try {
                  const practiceReponse = await DefaultsAPI.speechToPractices(formData);
                  const response = {
                      index: props.practiceIndex,
                      response: practiceReponse.data,
                  };
                  emit('practice-response', response);

                  showSuccessIcon.value = true;
                  setTimeout(() => {
                    showSuccessIcon.value = false;
                  }, 1100);
              } catch (error) {
                  console.error('Error sending audio to server', error);
                  showFailureIcon.value = true;
                  setTimeout(() => {
                    showFailureIcon.value = false;
                  }, 1100);
              }
              isProcessing.value = false;
          };

          mediaRecorder.start();
          isRecording.value = true;
        } catch (error) {
          console.error('Error accessing the microphone', error);
        }
    } else {
        console.error('getUserMedia not supported on your browser!');
    }
};

const stopRecording = () => {
    if (mediaRecorder) {
        mediaRecorder.stop();
        mediaRecorder.stream.getTracks().forEach(track => track.stop());
    }
};

</script>

<style>
.icon-button {
  background: none;
  border: none;
  cursor: pointer;
}

.fade-out {
    opacity: 1;
    animation: fadeOutEffect 1s 0.1s forwards;
}

@keyframes fadeOutEffect {
    from { opacity: 1; }
    to { opacity: 0; }
}
</style>
