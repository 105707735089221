<template>
  <div class="defaults-row">
    <div class="d-flex align-items-center">
      <div class="fields-count text-center">
        <div class="clickable" @click="handleFieldsClick">
          {{ defaults.fields_count }}
        </div>
        <div>Fields</div>
      </div>
      <div class="divider" />
      <div>
        <div class="defaults-name">{{ defaults.name }}</div>
        <div class="defaults-user">
          {{ addedBy }}
        </div>
      </div>
      <div class="actions">
        <div>
          <router-link
            :to="{
              name: 'defaults-settings',
              params: { defaultsTemplateId: defaults.id },
            }"
            >View/Edit <i class="fa fa-arrow-circle-right"
          /></router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex"
import { Fields, Filter } from "@/store/modules"
export default {
  name: "DefaultsRow",
  props: {
    defaults: { type: Object },
  },

  computed: {
    ...mapGetters({
      fields: Fields.Getters.getFields,
    }),

    ...mapState({
      selectedFields: state => state.Filter.selectedFields,
    }),

    addedBy() {
      if (!this.defaults.added_by_user) return ""
      const { first_name, last_name, email } = this.defaults.added_by_user
      const name =
        !first_name || !last_name ? email : `${first_name} ${last_name}`
      return `Added by: ${name}`
    },
  },

  methods: {
    ...mapMutations({
      setSelectedFields: Filter.Mutations.setSelectedFields,
    }),

    handleFieldsClick() {
      const selFields = this.selectedFields
      const defaultId = this.defaults.id
      const defaultFieldsIds = this.fields
        .filter(field => field.defaults.id === defaultId)
        .map(field => field.id)

      for (const fieldId in selFields) {
        if (defaultFieldsIds.includes(Number(fieldId))) {
          selFields[fieldId] = true
        } else {
          selFields[fieldId] = false
        }
      }

      this.setSelectedFields(selFields)
      this.$router.push(`/fields`)
    },
  },
}
</script>

<style scoped>
.clickable {
  cursor: pointer;
}

.defaults-row {
  padding: 18px;
  border-top: 1pt solid #d5d5d5;
}

.defaults-row:hover {
  background: #fafafa;
}

.fields-count {
  padding: 0px 15px;
}

.fields-count > div:first-of-type {
  font-weight: bold;
  font-size: 18px;
}

.divider {
  height: 40px;
  width: 1px;
  border-left: 1pt solid #e0e0e0;
  display: block;
  margin: auto 18px;
}

.defaults-name {
  color: #333;
  font-size: 15px;
  font-weight: 500;
}

.defaults-user {
  font-size: 13px;
}

.actions {
  flex: 1;
  text-align: right;
  color: #0095ff;
  font-weight: 500;
  cursor: pointer;
}

.actions:hover {
  color: #888;
}
</style>
