import DefaultsAPI from "@/api/DefaultsAPI"
import { Defaults } from "@/store/modules"
import Vue from "vue"
import _ from "lodash"

import { MAX_DEFAULT_FIELDS } from "@/constants/defaults"

const state = {
  defaults: {},
  fieldSettings: {},
  newFieldSettings: {},
  opsSettings: {},
  fertSettings: {},
  practiceChoices: {},
  spreadsheetNeedsSave: false,
  recentWizardData: { wizard: null, data: null },
  wizardDataCompletionSummary: {},
}

const getters = {
  [Defaults.Getters.getDefaultsList]: state => {
    return Object.values(state.defaults).sort(
      (a, b) => b.fields_count - a.fields_count
    )
  },
  [Defaults.Getters.getOneFieldSettings]: state => fieldId => {
    return [[fieldId, state.newFieldSettings[fieldId]]]
  },
  [Defaults.Getters.getNewFieldSettings]: state => {
    return Object.entries(state.newFieldSettings)
  },
  [Defaults.Getters.getRecentWizardData]: state => {
    return state.recentWizardData
  },
  [Defaults.Getters.getPracticeChoices]: state => {
    return state.practiceChoices
  },
  [Defaults.Getters.getHarvestTypeChoices]: state => {
    const choices = state.practiceChoices["harvest_type"]
    if (!choices) {
      return []
    }

    const harvestTypeRank = harvestType => {
      if (harvestType.display_name.startsWith("Cotton")) {
        return 1
      }
      if (harvestType.display_name.startsWith("Other")) {
        return 2
      }
      return 0
    }
    const sortedChoices = choices.toSorted((a, b) => {
      return harvestTypeRank(a) - harvestTypeRank(b)
    })
    return sortedChoices
  },
  [Defaults.Getters.getWizardDataCompletionSummary]: state => {
    return state.wizardDataCompletionSummary
  },
}

const mutations = {
  [Defaults.Mutations.setDefaults](state, defaults) {
    const defaultsDict = {}
    defaults.forEach(item => (defaultsDict[item.id] = item))
    state.defaults = defaultsDict
  },
  [Defaults.Mutations.setFieldSettings](state, fieldSettings) {
    const fieldSettingsDict = {}
    fieldSettings.forEach(item => (fieldSettingsDict[item.id] = item))
    state.fieldSettings = fieldSettingsDict
  },
  // new code for arva pre-generated values
  [Defaults.Mutations.setNewFieldSettings](state, newFieldSettings) {
    state.newFieldSettings = JSON.parse(newFieldSettings)
  },
  [Defaults.Mutations.addToFieldSettings](state, fieldSettingsToAdd) {
    const addObj = JSON.parse(fieldSettingsToAdd)
    Object.keys(addObj).forEach(fieldId => {
      Vue.set(state.newFieldSettings, fieldId, addObj[fieldId])
    })
  },
  [Defaults.Mutations.cleanFieldSettings](state, fieldsToClean) {
    const cleanedNewFieldSettings = Object.keys(state.newFieldSettings)
      .filter(fieldId => !fieldsToClean.includes(fieldId))
      .reduce((result, fieldId) => {
        result[fieldId] = state.newFieldSettings[fieldId]
        return result
      }, {})
    state.newFieldSettings = cleanedNewFieldSettings
  },
  [Defaults.Mutations.setOpsSettings](state, opsSettings) {
    state.opsSettings = JSON.parse(opsSettings)
  },
  [Defaults.Mutations.setFertSettings](state, fertSettings) {
    state.fertSettings = JSON.parse(fertSettings)
  },
  [Defaults.Mutations.setPracticeChoices](state, practiceChoices) {
    state.practiceChoices = Object.freeze(practiceChoices)
  },
  [Defaults.Mutations.addFieldSettingRow](state, payload) {
    const fieldId = payload.fieldId
    const dataCategory = payload.dataCategory
    const fieldSettingYear = payload.year
    const payloadData = payload.dataObj

    let dataForYear = state.newFieldSettings[fieldId].year_data.find(
      dataByYear => dataByYear.year === parseInt(fieldSettingYear)
    )
    let cultivationInFocus = dataForYear.cultivations.find(
      cultiv => cultiv.arva_crop_id === payload.cropId
    )
      ? dataForYear.cultivations.find(
          cultiv => cultiv.arva_crop_id === payload.cropId
        )
      : dataForYear.cultivations[0]

    if (
      ["cover_crop", "grazings", "limings", "tillages", "irrigations"].includes(
        dataCategory
      )
    ) {
      cultivationInFocus["operations"][dataCategory].push(payloadData)
    } else {
      cultivationInFocus[dataCategory].push(payloadData)
    }
  },
  // new code for arva-pre-generated values
  [Defaults.Mutations.deleteNewFieldSetting](state, payload) {
    // payload values
    // fieldId, cropId, dataCategory, rowIndex, year
    const fieldId = payload.fieldId
    const dataCategory = payload.dataCategory
    const fieldSettingYear = payload.year

    let dataForYear = state.newFieldSettings[fieldId].year_data.find(
      dataByYear => dataByYear.year === parseInt(fieldSettingYear)
    )
    let cultivationInFocus = dataForYear.cultivations.find(
      cultiv => cultiv.arva_crop_id === payload.cropId
    )
      ? dataForYear.cultivations.find(
          cultiv => cultiv.arva_crop_id === payload.cropId
        )
      : dataForYear.cultivations[0]

    if (
      ["cover_crop", "grazings", "limings", "tillages", "irrigations"].includes(
        dataCategory
      )
    ) {
      cultivationInFocus["operations"][dataCategory].splice(payload.rowIndex, 1)
      if (payload.rowIndex === 0)
        cultivationInFocus["operations"][dataCategory].push(payload.defaultObj)
    } else {
      cultivationInFocus[dataCategory].splice(payload.rowIndex, 1)
      if (payload.rowIndex === 0)
        cultivationInFocus[dataCategory].push(payload.defaultObj)
    }
  },
  // called when updating individual rows
  // deprecated in favor of saving all data in one call via a save button
  [Defaults.Mutations.updateNewFieldSetting](state, payload) {
    // TODO CONSIDER BETTER MULTIPLE CULTIVATIONS PER YEAR
    // payload values
    // fieldId, cropId, dataCategory, dataSetting, rowIndex, value, year
    const fieldId = payload.fieldId
    const dataCategory = payload.dataCategory
    const fieldSettingYear = payload.year

    let dataForYear = state.newFieldSettings[fieldId].year_data.find(
      dataByYear => dataByYear.year === parseInt(fieldSettingYear)
    )
    let cultivationInFocus = dataForYear.cultivations.find(
      cultiv => cultiv.arva_crop_id === payload.cropId
    )

    // cultivation crop id based on first value in harvests
    // alter all at once to reflect cultivation change
    if (["arva_crop_id"].includes(dataCategory)) {
      cultivationInFocus["harvests"].forEach(harv => {
        harv["crop_id"]["value"] = payload.value
        harv["crop_id"]["source"] = "User Input"
      })
      const harvestPayload = state.newFieldSettings[fieldId].year_data.map(
        year => {
          let dataByYear = state.newFieldSettings[fieldId].year_data.find(
            dataByYear => dataByYear.year === parseInt(year["year"])
          )
          const harvsForYear = dataByYear.cultivations
            .map(cultiv => cultiv["harvests"])
            .flat()
          return {
            year: year["year"],
            harvests: harvsForYear,
          }
        }
      )
      DefaultsAPI.updateFieldSetting(
        "harvest-defaults",
        cultivationInFocus["harvestId"],
        { data: harvestPayload }
      )
      // cultivationInFocus["arva_crop_id"] = payload.value
    } else if (
      ["cover_crop", "grazings", "limings", "tillages", "irrigations"].includes(
        dataCategory
      )
    ) {
      if (payload.rowIndex !== null) {
        // grazing, liming, tillage changes
        cultivationInFocus["operations"][dataCategory][payload.rowIndex][
          payload.dataSetting
        ].value = payload.value
        cultivationInFocus["operations"][dataCategory][payload.rowIndex][
          payload.dataSetting
        ].source = "User Input"
      } else {
        // change for cover crop
        cultivationInFocus["operations"][dataCategory][
          payload.dataSetting
        ].value = payload.value
        cultivationInFocus["operations"][dataCategory][
          payload.dataSetting
        ].source = "User Input"
      }

      const apiPayload = state.newFieldSettings[fieldId].year_data.map(year => {
        let dataByYear = state.newFieldSettings[fieldId].year_data.find(
          dataByYear => dataByYear.year === parseInt(year["year"])
        )

        let opsForYear = []
        dataByYear.cultivations.forEach(cultiv =>
          opsForYear.push(cultiv.operations)
        )
        return {
          year: year["year"],
          operations: opsForYear,
        }
      })
      DefaultsAPI.updateFieldSetting(
        "operation-defaults",
        cultivationInFocus["operationId"],
        { data: apiPayload }
      )
    } else if (payload.rowIndex !== null) {
      cultivationInFocus[dataCategory][payload.rowIndex][
        payload.dataSetting
      ].value = payload.value
      cultivationInFocus[dataCategory][payload.rowIndex][
        payload.dataSetting
      ].source = "User Input"

      if (dataCategory === "harvests") {
        const harvestPayload = state.newFieldSettings[fieldId].year_data.map(
          year => {
            let dataByYear = state.newFieldSettings[fieldId].year_data.find(
              dataByYear => dataByYear.year === parseInt(year["year"])
            )
            const harvsForYear = dataByYear.cultivations
              .map(cultiv => cultiv[dataCategory])
              .flat()
            return {
              year: year["year"],
              harvests: harvsForYear,
            }
          }
        )
        DefaultsAPI.updateFieldSetting(
          "harvest-defaults",
          cultivationInFocus["harvestId"],
          { data: harvestPayload }
        )
      } else if (dataCategory === "plantings") {
        const plantingPayload = state.newFieldSettings[fieldId].year_data.map(
          year => {
            let dataByYear = state.newFieldSettings[fieldId].year_data.find(
              dataByYear => dataByYear.year === parseInt(year["year"])
            )
            const plantsForYear = dataByYear.cultivations
              .map(cultiv => cultiv[dataCategory])
              .flat()
            return {
              year: year["year"],
              plantings: plantsForYear,
            }
          }
        )
        DefaultsAPI.updateFieldSetting(
          "planting-defaults",
          cultivationInFocus["plantingId"],
          { data: plantingPayload }
        )
      } else if (dataCategory === "fert_applications") {
        const fertPayload = state.newFieldSettings[fieldId].year_data.map(
          year => {
            let dataByYear = state.newFieldSettings[fieldId].year_data.find(
              dataByYear => dataByYear.year === parseInt(year["year"])
            )
            const fertsForYear = dataByYear.cultivations
              .map(cultiv => cultiv[dataCategory])
              .flat()
            return {
              year: year["year"],
              fert_applications: fertsForYear,
            }
          }
        )
        DefaultsAPI.updateFieldSetting(
          "fertilizer-defaults",
          cultivationInFocus["fertilizerId"],
          { data: fertPayload }
        )
      }
    }
  },
  // save button function
  [Defaults.Mutations.saveDataSpreadsheet](state, payload) {
    const fieldId = payload.fieldId

    const dataForYear = state.newFieldSettings[fieldId].year_data[0]
    const cultivationInFocus = dataForYear.cultivations[0]

    // get planting payload for save
    const plantingPayload = state.newFieldSettings[fieldId].year_data.map(
      year => {
        let dataByYear = state.newFieldSettings[fieldId].year_data.find(
          dataByYear => dataByYear.year === parseInt(year["year"])
        )
        const plantsForYear = dataByYear.cultivations
          .map(cultiv => cultiv["plantings"])
          .flat()
        return {
          year: year["year"],
          plantings: plantsForYear,
        }
      }
    )
    DefaultsAPI.updateFieldSetting(
      "planting-defaults",
      cultivationInFocus["plantingId"],
      { data: plantingPayload }
    )

    // fertilizer payload for save
    const fertPayload = state.newFieldSettings[fieldId].year_data.map(year => {
      let dataByYear = state.newFieldSettings[fieldId].year_data.find(
        dataByYear => dataByYear.year === parseInt(year["year"])
      )
      const fertsForYear = dataByYear.cultivations
        .map(cultiv => cultiv["fert_applications"])
        .flat()
      return {
        year: year["year"],
        fert_applications: fertsForYear,
      }
    })

    DefaultsAPI.updateFieldSetting(
      "fertilizer-defaults",
      cultivationInFocus["fertilizerId"],
      { data: fertPayload }
    )

    // harvest payload for save
    const harvestPayload = state.newFieldSettings[fieldId].year_data.map(
      year => {
        let dataByYear = state.newFieldSettings[fieldId].year_data.find(
          dataByYear => dataByYear.year === parseInt(year["year"])
        )
        const harvsForYear = dataByYear.cultivations
          .map(cultiv => cultiv["harvests"])
          .flat()
        return {
          year: year["year"],
          harvests: harvsForYear,
        }
      }
    )
    DefaultsAPI.updateFieldSetting(
      "harvest-defaults",
      cultivationInFocus["harvestId"],
      { data: harvestPayload }
    )

    // operation payload for save
    const apiPayload = state.newFieldSettings[fieldId].year_data.map(year => {
      let dataByYear = state.newFieldSettings[fieldId].year_data.find(
        dataByYear => dataByYear.year === parseInt(year["year"])
      )

      let opsForYear = []
      dataByYear.cultivations.forEach(cultiv =>
        opsForYear.push(cultiv.operations)
      )
      return {
        year: year["year"],
        operations: opsForYear,
      }
    })

    DefaultsAPI.updateFieldSetting(
      "operation-defaults",
      cultivationInFocus["operationId"],
      { data: apiPayload }
    )

    // crop protection payload for save
    const protectionPayload = state.newFieldSettings[fieldId].year_data.map(
      year => {
        let dataByYear = state.newFieldSettings[fieldId].year_data.find(
          dataByYear => dataByYear.year === parseInt(year["year"])
        )
        const protectionsForYear = dataByYear.cultivations
          .map(cultiv => cultiv["crop_protection"])
          .flat()
        return {
          year: year["year"],
          crop_protection: protectionsForYear,
        }
      }
    )

    DefaultsAPI.updateFieldSetting(
      "crop-protection-defaults",
      cultivationInFocus["cropProtectionId"],
      { data: protectionPayload }
    )

    // soil sampling payload for save
    const samplePayload = state.newFieldSettings[fieldId].year_data.map(
      year => {
        let dataByYear = state.newFieldSettings[fieldId].year_data.find(
          dataByYear => dataByYear.year === parseInt(year["year"])
        )
        const samplesForYear = dataByYear.cultivations
          .map(cultiv => cultiv["soil_sampling"])
          .flat()
        return {
          year: year["year"],
          soil_sampling: samplesForYear,
        }
      }
    )

    DefaultsAPI.updateFieldSetting(
      "soil-sample-defaults",
      cultivationInFocus["samplingId"],
      { data: samplePayload }
    )

    DefaultsAPI.createDataVersion({
      fieldId: fieldId,
    })

    return new Promise(resolve => {
      resolve()
    })
  },
  [Defaults.Mutations.updateMultipleNewFieldSetting](state, payload) {
    // payload values
    // cropId, dataCategory, fieldId, rowData (data being added), years
    const fieldId = payload.fieldId
    const dataCategory = payload.dataCategory

    payload.years.forEach(year => {
      const yearInFocus = year
      let dataForYear = state.newFieldSettings[fieldId].year_data.find(
        dataByYear => dataByYear.year === parseInt(yearInFocus)
      )
      let cultivationInFocus = dataForYear.cultivations.find(
        cultiv => cultiv.arva_crop_id === payload.cropId
      )
        ? dataForYear.cultivations.find(
            cultiv => cultiv.arva_crop_id === payload.cropId
          )
        : dataForYear.cultivations[0]
      if (
        ["grazings", "limings", "tillages", "irrigations"].includes(
          dataCategory
        )
      ) {
        cultivationInFocus["operations"][dataCategory].push(payload.rowData)
      } else {
        cultivationInFocus[dataCategory].push(payload.rowData)
      }

      if (
        ["grazings", "limings", "tillages", "irrigations"].includes(
          dataCategory
        )
      ) {
        const apiPayload = state.newFieldSettings[fieldId].year_data.map(
          pYear => {
            let dataByYear = state.newFieldSettings[fieldId].year_data.find(
              dataByYear => dataByYear.year === parseInt(pYear["year"])
            )
            // TODO CONSIDER BETTER MULTIPLE CULTIVATIONS PER YEAR
            const opsForYear = {}
            opsForYear["limings"] = dataByYear.cultivations
              .map(cultiv => cultiv["operations"]["limings"])
              .flat()
            opsForYear["grazings"] = dataByYear.cultivations
              .map(cultiv => cultiv["operations"]["grazings"])
              .flat()
            opsForYear["tillages"] = dataByYear.cultivations
              .map(cultiv => cultiv["operations"]["tillages"])
              .flat()
            opsForYear["irrigations"] = dataByYear.cultivations
              .map(cultiv => cultiv["operations"]["irrigations"])
              .flat()
            opsForYear["drainage"] =
              dataByYear["cultivations"][0]["operations"]["drainage"]
            return {
              year: pYear["year"],
              operations: opsForYear,
            }
          }
        )
        DefaultsAPI.updateFieldSetting(
          "operation-defaults",
          cultivationInFocus["operationId"],
          { data: apiPayload }
        )
      } else {
        if (dataCategory === "harvests") {
          const harvestPayload = state.newFieldSettings[fieldId].year_data.map(
            pYear => {
              let dataByYear = state.newFieldSettings[fieldId].year_data.find(
                dataByYear => dataByYear.year === parseInt(pYear["year"])
              )
              const harvsForYear = dataByYear.cultivations
                .map(cultiv => cultiv[dataCategory])
                .flat()
              return {
                year: pYear["year"],
                harvests: harvsForYear,
              }
            }
          )
          DefaultsAPI.updateFieldSetting(
            "harvest-defaults",
            cultivationInFocus["harvestId"],
            { data: harvestPayload }
          )
        } else if (dataCategory === "plantings") {
          const plantingPayload = state.newFieldSettings[fieldId].year_data.map(
            pYear => {
              let dataByYear = state.newFieldSettings[fieldId].year_data.find(
                dataByYear => dataByYear.year === parseInt(pYear["year"])
              )
              const plantsForYear = dataByYear.cultivations
                .map(cultiv => cultiv[dataCategory])
                .flat()
              return {
                year: pYear["year"],
                plantings: plantsForYear,
              }
            }
          )
          DefaultsAPI.updateFieldSetting(
            "planting-defaults",
            cultivationInFocus["plantingId"],
            { data: plantingPayload }
          )
        } else if (dataCategory === "fert_applications") {
          const fertPayload = state.newFieldSettings[fieldId].year_data.map(
            pYear => {
              let dataByYear = state.newFieldSettings[fieldId].year_data.find(
                dataByYear => dataByYear.year === parseInt(pYear["year"])
              )
              const fertsForYear = dataByYear.cultivations
                .map(cultiv => cultiv[dataCategory])
                .flat()
              return {
                year: pYear["year"],
                fert_applications: fertsForYear,
              }
            }
          )
          DefaultsAPI.updateFieldSetting(
            "fertilizer-defaults",
            cultivationInFocus["fertilizerId"],
            { data: fertPayload }
          )
        }
      }
    })
  },
  [Defaults.Mutations.spreadsheetMutate](state, payload) {
    const fieldId = payload.fieldId
    const year = payload.year
    const crop = payload.crop
    const dataCategory = payload.dataCategory
    const dataSetting = payload.dataSetting
    const rowIndex = payload.rowIndex
    const payloadVal = payload.value

    state.spreadsheetNeedsSave = true

    let fieldToMutate = state.newFieldSettings[fieldId]
    if (!fieldToMutate) {
      console.error("No Field Found")
    }

    let yearData = fieldToMutate.year_data
    let yearToMutate = yearData.find(y => y.year == year)
    let cultivation = yearToMutate.cultivations.find(
      c => c.arva_crop_id == crop
    )

    if (
      ["tillages", "limings", "grazings", "cover_crop", "irrigations"].includes(
        dataCategory
      )
    ) {
      cultivation["operations"][dataCategory][rowIndex][dataSetting] = {
        source: "User Input",
        value: payloadVal,
      }
    } else {
      cultivation[dataCategory][rowIndex][dataSetting] = {
        source: "User Input",
        value: payloadVal,
      }
    }
  },
  [Defaults.Mutations.resetNeedsSave](state) {
    state.spreadsheetNeedsSave = false
  },
  [Defaults.Mutations.setRecentWizardData](state, payload) {
    state.recentWizardData = payload
  },
  [Defaults.Mutations.resetRecentWizardData](state) {
    state.recentWizardData = { wizard: null, data: null }
  },
  [Defaults.Mutations.setWizardDataCompletionSummary](state, payload) {
    state.wizardDataCompletionSummary = Object.freeze(payload)
  },
}

const actions = {
  [Defaults.Actions.fetchDefaults]({ commit }) {
    return new Promise(resolve => {
      DefaultsAPI.list().then(response => {
        commit(Defaults.Mutations.setDefaults, response.data)
        resolve()
      })
    })
  },
  [Defaults.Actions.fetchFieldSettings]({ commit }) {
    return new Promise(resolve => {
      DefaultsAPI.listFieldSettings().then(response => {
        commit(Defaults.Mutations.setFieldSettings, response.data)
        resolve()
      })
    })
  },
  // new fetch
  [Defaults.Actions.fetchNewFieldSettings]({ commit }) {
    return new Promise(resolve => {
      DefaultsAPI.getNewFieldSettings().then(response => {
        commit(Defaults.Mutations.setNewFieldSettings, response.data)
        resolve()
      })
    })
  },
  [Defaults.Actions.fetchOpsSettings]({ commit }, payload) {
    return new Promise(resolve => {
      DefaultsAPI.getCarbonOpsByField(payload)
        .catch(error => {
          console.error(error)
          resolve(error)
        })
        .then(response => {
          commit(Defaults.Mutations.setOpsSettings, response.data)
          resolve()
        })
    })
  },
  [Defaults.Actions.fetchFertSettings]({ commit }, payload) {
    return new Promise(resolve => {
      DefaultsAPI.getCarbonFertByField(payload)
        .catch(error => {
          console.error(error)
          resolve(error)
        })
        .then(response => {
          commit(Defaults.Mutations.setFertSettings, response.data)
          resolve()
        })
    })
  },
  [Defaults.Actions.fetchPracticeChoices]({ commit }) {
    return new Promise(resolve => {
      DefaultsAPI.getPracticeChoices()
        .catch(error => {
          console.error(error)
          resolve(error)
        })
        .then(response => {
          commit(Defaults.Mutations.setPracticeChoices, response.data)
          resolve()
        })
    })
  },
  async [Defaults.Actions.updateWizardDataCompletionSummary](
    { commit },
    payload
  ) {
    const response = await DefaultsAPI.getCarbonDataCompletion(payload)
    commit(
      Defaults.Mutations.setWizardDataCompletionSummary,
      JSON.parse(response.data)
    )
  },
  [Defaults.Actions.fetchCarbonSettingsByField]({ commit, state }, payload) {
    const checkExistingState =
      payload.checkExistingState !== undefined
        ? payload.checkExistingState
        : true

    let fieldsForFetch = payload.fieldIds

    if (checkExistingState) {
      // check for field ids already in state, clear any unwanted fields from local data
      let fieldsInState = Object.keys(state.newFieldSettings).map(v =>
        Number(v)
      )
      fieldsForFetch = payload.fieldIds.filter(f => !fieldsInState.includes(f))
      let fieldsToClean = fieldsInState.filter(
        f => !payload.fieldIds.includes(f)
      )
      if (fieldsToClean.length > 0) {
        commit(Defaults.Mutations.cleanFieldSettings, fieldsToClean)
      }
    }

    if (fieldsForFetch.length > 0) {
      if (fieldsForFetch.length < MAX_DEFAULT_FIELDS) {
        payload.fieldIds = fieldsForFetch
        return new Promise(resolve => {
          DefaultsAPI.getCarbonSettingsByField(payload)
            .catch(error => {
              console.error(error)
              resolve(error)
            })
            .then(response => {
              commit(Defaults.Mutations.addToFieldSettings, response.data)
              resolve()
            })
        })
      } else {
        return Promise.resolve()
      }
    } else {
      return Promise.resolve()
    }
  },
  // Adds/copies submitted rowData to multiple fields
  [Defaults.Actions.updateMultipleFieldSettings]({ commit }, payload) {
    payload.fieldIds.forEach(fieldId => {
      commit(Defaults.Mutations.updateMultipleNewFieldSetting, {
        cropId: payload.cropId,
        dataCategory: payload.dataCategory,
        fieldId: fieldId,
        rowData: payload.rowData,
        years: payload.years,
      })
    })
  },
  [Defaults.Actions.saveDataSpreadsheet]({ commit }, payload) {
    return new Promise(resolve => {
      commit(Defaults.Mutations.saveDataSpreadsheet, payload)
      resolve()
    })
  },
  async [Defaults.Actions.updateIrrigation]({ state }, payload) {
    const fieldId = payload.fieldId
    const year = payload.year
    let fieldState = _.cloneDeep(state.newFieldSettings[fieldId])
    let operationId = fieldState.operation_id

    let dataForYear = fieldState.year_data.find(
      dataByYear => dataByYear.year === parseInt(year)
    )

    dataForYear.cultivations.forEach(cultiv => {
      const cropFilteredPayload = payload.irrigation.filter(
        o => o.crop_id.value == cultiv.arva_crop_id
      )
      if (cropFilteredPayload.length > 0) {
        cultiv["operations"]["irrigations"] = cropFilteredPayload
      }
      cultiv["operations"]["drainage"] = payload.drainage
    })

    const apiPayload = fieldState.year_data.map(year => {
      let dataByYear = fieldState.year_data.find(
        dataForYear => dataForYear.year === parseInt(year["year"])
      )
      let opsForYear = []
      dataByYear.cultivations.forEach(cultiv =>
        opsForYear.push(cultiv.operations)
      )
      return {
        year: year["year"],
        operations: opsForYear,
      }
    })

    const queryParams = { field_id: fieldId, year_modified: year }
    await DefaultsAPI.updateFieldSetting(
      "operation-defaults",
      operationId,
      {
        data: apiPayload,
      },
      queryParams
    )

    state.newFieldSettings[fieldId] = fieldState
  },
  async [Defaults.Actions.updateTillage]({ state }, payload) {
    const fieldId = payload.fieldId
    const year = payload.year
    let fieldState = _.cloneDeep(state.newFieldSettings[fieldId])
    let operationId = fieldState.operation_id

    let dataForYear = fieldState.year_data.find(
      dataByYear => dataByYear.year === parseInt(year)
    )

    // sets tillage for each cultivation in field
    dataForYear.cultivations.forEach(cultiv => {
      const cropFilteredPayload = payload.tillages.filter(
        o => o.crop_id.value == cultiv.arva_crop_id
      )
      if (cropFilteredPayload.length > 0) {
        cultiv["operations"]["tillages"] = cropFilteredPayload
      }
      // cultiv["operations"]["tillages"] = payload.tillages
    })

    const apiPayload = fieldState.year_data.map(year => {
      let dataByYear = fieldState.year_data.find(
        dataForYear => dataForYear.year === parseInt(year["year"])
      )
      let opsForYear = []
      dataByYear.cultivations.forEach(cultiv =>
        opsForYear.push(cultiv.operations)
      )
      return {
        year: year["year"],
        operations: opsForYear,
      }
    })

    const queryParams = { field_id: fieldId, year_modified: year }
    await DefaultsAPI.updateFieldSetting(
      "operation-defaults",
      operationId,
      {
        data: apiPayload,
      },
      queryParams
    )

    state.newFieldSettings[fieldId] = fieldState
  },
  async [Defaults.Actions.updateFertilizer]({ state }, payload) {
    const fieldId = payload.fieldId
    const year = payload.year
    let fieldState = _.cloneDeep(state.newFieldSettings[fieldId])
    let fertilizerId = fieldState.fertilizer_id

    let dataForYear = fieldState.year_data.find(
      dataByYear => dataByYear.year === parseInt(year)
    )

    dataForYear.cultivations.forEach(cultiv => {
      const cropFilteredPayload = payload.fertApplications.filter(
        o => o.crop_id.value == cultiv.arva_crop_id
      )
      if (cropFilteredPayload.length > 0) {
        cultiv["fert_applications"] = cropFilteredPayload
      }
    })

    const fertPayload = fieldState.year_data.map(year => {
      let dataByYear = fieldState.year_data.find(
        dataByYear => dataByYear.year === parseInt(year["year"])
      )
      const fertsForYear = dataByYear.cultivations
        .map(cultiv => cultiv["fert_applications"])
        .flat()
      return {
        year: year["year"],
        fert_applications: fertsForYear,
      }
    })

    const queryParams = { field_id: fieldId, year_modified: year }
    await DefaultsAPI.updateFieldSetting(
      "fertilizer-defaults",
      fertilizerId,
      {
        data: fertPayload,
      },
      queryParams
    )

    state.newFieldSettings[fieldId] = fieldState
  },
  async [Defaults.Actions.updateProtection]({ state }, payload) {
    const fieldId = payload.fieldId
    const year = payload.year
    let fieldState = _.cloneDeep(state.newFieldSettings[fieldId])
    let protectionId = fieldState.crop_protection_id

    let dataForYear = fieldState.year_data.find(
      dataByYear => dataByYear.year === parseInt(year)
    )

    dataForYear.cultivations.forEach(cultiv => {
      const cropFilteredPayload = payload.protectionPayload.filter(
        o => o.crop_id.value == cultiv.arva_crop_id
      )
      if (cropFilteredPayload.length > 0) {
        cultiv["crop_protection"] = cropFilteredPayload
      }
    })

    const protectionDatabasePayload = fieldState.year_data.map(year => {
      let dataByYear = fieldState.year_data.find(
        dataByYear => dataByYear.year === parseInt(year["year"])
      )
      const protectionForYear = dataByYear.cultivations
        .map(cultiv => cultiv["crop_protection"])
        .flat()
      return {
        year: year["year"],
        crop_protection: protectionForYear,
      }
    })

    const queryParams = { field_id: fieldId, year_modified: year }
    await DefaultsAPI.updateFieldSetting(
      "crop-protection-defaults",
      protectionId,
      {
        data: protectionDatabasePayload,
      },
      queryParams
    )

    state.newFieldSettings[fieldId] = fieldState
  },
  async [Defaults.Actions.updateCoverCropping]({ state }, payload) {
    const fieldId = payload.fieldId
    const year = payload.year

    let fieldState = _.cloneDeep(state.newFieldSettings[fieldId])
    let operationId = fieldState.operation_id

    let dataForYear = fieldState.year_data.find(
      dataByYear => dataByYear.year === parseInt(year)
    )

    dataForYear.cultivations.forEach(cultiv => {
      const cropFilteredPayload = payload.coverCropping.filter(
        o => o.crop_id.value == cultiv.arva_crop_id
      )
      if (cropFilteredPayload.length > 0) {
        cultiv["operations"]["cover_crop"] = cropFilteredPayload
      }
    })

    const apiPayload = fieldState.year_data.map(year => {
      let dataByYear = fieldState.year_data.find(
        dataForYear => dataForYear.year === parseInt(year["year"])
      )
      let opsForYear = []
      dataByYear.cultivations.forEach(cultiv =>
        opsForYear.push(cultiv.operations)
      )
      return {
        year: year["year"],
        operations: opsForYear,
      }
    })

    const queryParams = { field_id: fieldId, year_modified: year }
    await DefaultsAPI.updateFieldSetting(
      "operation-defaults",
      operationId,
      {
        data: apiPayload,
      },
      queryParams
    )

    state.newFieldSettings[fieldId] = fieldState
  },
  async [Defaults.Actions.updateHarvest]({ state }, payload) {
    const fieldId = payload.fieldId
    const year = payload.year
    let fieldState = _.cloneDeep(state.newFieldSettings[fieldId])
    let harvestId = fieldState.harvest_id

    let dataForYear = fieldState.year_data.find(
      dataByYear => dataByYear.year === parseInt(year)
    )

    dataForYear.cultivations.forEach(cultiv => {
      const cropFilteredPayload = payload.harvests.filter(
        o => o.crop_id.value == cultiv.arva_crop_id
      )
      if (cropFilteredPayload.length > 0) {
        cultiv["harvests"] = cropFilteredPayload
      }
    })

    const harvestPayload = fieldState.year_data.map(year => {
      let dataByYear = fieldState.year_data.find(
        dataByYear => dataByYear.year === parseInt(year["year"])
      )
      const harvestsForYear = dataByYear.cultivations
        .map(cultiv => cultiv["harvests"])
        .flat()
      return {
        year: year["year"],
        harvests: harvestsForYear,
      }
    })

    const queryParams = { field_id: fieldId, year_modified: year }
    await DefaultsAPI.updateFieldSetting(
      "harvest-defaults",
      harvestId,
      {
        data: harvestPayload,
      },
      queryParams
    )

    state.newFieldSettings[fieldId] = fieldState
  },
  async [Defaults.Mutations.updatePlanting]({ state }, payload) {
    const fieldId = payload.fieldId
    const year = payload.year
    const plantingId = state.newFieldSettings[fieldId].planting_id

    const existingYears = []
    const apiPayload = state.newFieldSettings[fieldId].year_data.map(
      existingYearData => {
        existingYears.push(existingYearData.year)
        if (existingYearData.year === parseInt(year)) {
          return {
            year: existingYearData.year,
            plantings: payload.plantingPayload,
          }
        } else {
          const plantingsForYear = existingYearData.cultivations
            .map(cultiv => cultiv["plantings"])
            .flat()

          return {
            year: existingYearData.year,
            plantings: plantingsForYear,
          }
        }
      }
    )

    if (!existingYears.includes(parseInt(year))) {
      const newYearData = {
        year: parseInt(year),
        plantings: payload.plantingPayload,
      }
      apiPayload.push(newYearData)
    }

    const queryParams = { field_id: fieldId, year_modified: year }
    await DefaultsAPI.updateFieldSetting(
      "planting-defaults",
      plantingId,
      {
        data: apiPayload,
      },
      queryParams
    )
  },
  async [Defaults.Actions.updateSoilSampling]({ state }, payload) {
    const fieldId = payload.fieldId
    let fieldState = _.cloneDeep(state.newFieldSettings[fieldId])

    const samplingId = fieldState.soil_sampling_id

    const apiPayload = fieldState.year_data.map(year => {
      let dataByYear = fieldState.year_data.find(
        dataByYear => dataByYear.year === parseInt(year["year"])
      )
      if (parseInt(payload.year) === parseInt(year["year"])) {
        const yearCrops = new Set(
          dataByYear.cultivations.map(cultiv => cultiv.arva_crop_id)
        )
        const allSamplings = []
        for (const yearCrop of yearCrops) {
          const cropSamplings = []
          for (const newSampling of payload.soilSamplingPayload) {
            const cropSampling = _.cloneDeep(newSampling)
            cropSampling.crop_id = {}
            cropSampling.crop_id.value = yearCrop
            cropSampling.crop_id.source = "User Input"
            cropSamplings.push(cropSampling)
          }

          // this updates the state and therefore opssettings after the save
          dataByYear.cultivations.find(c => c.arva_crop_id === yearCrop)[
            "soil_sampling"
          ] = cropSamplings

          allSamplings.push(...cropSamplings)
        }

        return {
          year: year["year"],
          soil_sampling: allSamplings,
        }
      }

      const soilSamplingForYear = dataByYear.cultivations
        .map(cultiv => cultiv["soil_sampling"])
        .flat()
      return {
        year: year["year"],
        soil_sampling: soilSamplingForYear,
      }
    })

    const queryParams = { field_id: fieldId, year_modified: payload.year }

    await DefaultsAPI.updateFieldSetting(
      "soil-sample-defaults",
      samplingId,
      {
        data: apiPayload,
      },
      queryParams
    )

    state.newFieldSettings[fieldId] = fieldState
  },
  async [Defaults.Actions.updateLiming]({ state }, payload) {
    const fieldId = payload.fieldId
    const year = payload.year
    let fieldState = _.cloneDeep(state.newFieldSettings[fieldId])
    let operationId = fieldState.operation_id

    let dataForYear = fieldState.year_data.find(
      dataByYear => dataByYear.year === parseInt(year)
    )

    const apiPayload = fieldState.year_data.map(year => {
      let dataByYear = fieldState.year_data.find(
        dataForYear => dataForYear.year === parseInt(year["year"])
      )
      let opsForYear = []
      dataByYear.cultivations.forEach(cultiv =>
        opsForYear.push(cultiv.operations)
      )
      return {
        year: year["year"],
        operations: opsForYear,
      }
    })

    // sets liming for each cultivation in field
    dataForYear.cultivations.forEach(cultiv => {
      const cropFilteredPayload = payload.limingPayload.filter(
        o => o.crop_id.value == cultiv.arva_crop_id
      )
      if (cropFilteredPayload.length > 0) {
        cultiv["operations"]["limings"] = cropFilteredPayload
      }
    })

    const queryParams = { field_id: fieldId, year_modified: year }
    await DefaultsAPI.updateFieldSetting(
      "operation-defaults",
      operationId,
      {
        data: apiPayload,
      },
      queryParams
    )

    state.newFieldSettings[fieldId] = fieldState
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
