<template>
  <ArvaModal
    name="alertErrorModal"
    :title="titleText"
    @close-modal="handleCloseModal"
  >
    <v-card-text>
      <div
        class="error-message ml-5 p-1"
        v-for="message in errorMessage"
        :key="message"
      >
        <li>{{ message }}</li>
      </div>
    </v-card-text>
  </ArvaModal>
</template>

<script>
import ArvaModal from "./ArvaModal"

export default {
  name: "AlertErrorModal",
  props: {
    errorMessage: { type: Array },
    titleText: { type: String },
  },
  components: { ArvaModal },

  methods: {
    handleCloseModal() {
      this.$emit("close-modal")
    },
  },
}
</script>

<style scoped>
.error-message {
  font-size: 15px;
  font-weight: 500;
  color: red;
}
</style>
