import { Theme } from "@/store/modules"

const getDefaultState = () => {
  return {
    sidePanelOpen: false,
    sideNavOpen: false,
    initialDataFetch: false,
  }
}

const state = getDefaultState()

const getters = {}

const mutations = {
  [Theme.Mutations.resetState](state) {
    Object.assign(state, getDefaultState())
  },
  [Theme.Mutations.setSidePanelOpen](state, val) {
    state.sidePanelOpen = val
  },
  [Theme.Mutations.setSideNavOpen](state, val) {
    state.sideNavOpen = val
  },
  [Theme.Mutations.setInitialDataFetch](state, val) {
    state.initialDataFetch = val
  },
}

const actions = {
  [Theme.Actions.resetThemeState]({ commit }) {
    commit("resetState")
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
