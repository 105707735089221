<template>
  <v-expansion-panel @click="onExpansionPanelClick" eager>
    <v-expansion-panel-header>
      {{ title }}
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <div>
        <div>
          <div v-if="!loading">
            <h4>
              {{ graphTitle }} {{ this.filterParams["aggregation_metric"] }}
            </h4>
            <apexchart
              v-if="chartSeries != null"
              :type="chartType"
              height="350"
              :options="chartOptions"
              :series="chartSeries"
            />
          </div>
          <!--<div v-else class="mt-3 d-flex justify-content-center">
            <div class="text-bold mb-0">Fetching Data</div>
            <div class="spinner-border ml-4" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>-->

          <div v-if="loading" class="mt-3 d-flex justify-content-center">
            <div class="text-bold mb-0">Updating Data</div>
            <div class="spinner-border ml-4" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <div v-if="items.length > 0 && !loading">
            <v-container>
              <v-row align="center" justify="center">
                <v-col v-if="selectionType == 'select'" cols="7">
                  <v-autocomplete
                    v-model="inputSelection"
                    ref="autocompleteSearch"
                    clearable
                    label="Select"
                    :items="items"
                    multiple
                    @change="madeSelection = true"
                  ></v-autocomplete>
                </v-col>
                <v-col v-if="selectionType == 'slider'" cols=" 12">
                  <v-slider
                    v-model="inputSelection"
                    :min="minSlider"
                    :max="maxSlider"
                    :step="stepSize"
                    @click="updateFilterResults"
                    hide-details
                  >
                    <template v-slot:append>
                      <v-text-field
                        v-model="inputSelection"
                        density="compact"
                        style="width: 80px"
                        type="number"
                        variant="outlined"
                        hide-details
                      ></v-text-field>
                    </template>
                  </v-slider>
                </v-col>
                <v-col v-if="selectionType == 'select'" cols="1"></v-col>
                <v-col cols="4" v-if="selectionType == 'select'">
                  <v-btn
                    v-if="madeSelection"
                    @click="updateFilterResults"
                    density="compact"
                    small
                  >
                    Preview Map Change
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </div>
          <div v-if="items.length >= 0">
            <v-container>
              <!-- v-if="this.previewParam in this.filterParams['filter_params']"-->
              <v-row class="justify-end">
                <v-col cols="auto" class="text-left">
                  <v-switch
                    v-model="filterChoices"
                    :label="`Filter Graph Choices: ${filterChoices.toString()}`"
                    hide-details
                    @click="resetFilter"
                    inset
                  ></v-switch>
                </v-col>

                <v-col></v-col>
                <v-col
                  v-if="previewParam == 'geoids'"
                  cols="auto"
                  class="text-right"
                >
                  <v-btn @click="selectAll"> Select All </v-btn>
                </v-col>
                <v-col cols="auto" class="text-right">
                  <v-btn @click="saveFilter" color="green">Save</v-btn>
                </v-col>

                <v-col cols="auto" class="text-right">
                  <v-btn @click="cancelFilter">Cancel</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </div>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import CarbonReadyAPI from "@/api/CarbonReadyAPI"
import _ from "lodash"

export default {
  name: "InsetFilter",
  props: [
    "filterParams",
    "title",
    "graphTitle",
    "previewParam",
    "selectionType",
  ],
  data() {
    return {
      aggregationMetric: "fieldcrop__field__acreage",
      apexChartData: null,
      chartSeries: null,
      chartOptions: null,
      chartType: null,
      filterPreview: null,
      items: [],
      inputSelection:
        this.$props.filterParams["filter_params"][this.previewParam],
      loading: false,
      minSlider: 0,
      maxSlider: 100,
      stepSize: 1,
      madeSelection: false,
      filterChoices: false,
      matchSummary: null,
      autoSelect: false,
    }
  },

  /*computed: {
        #might switch to this later...
        inputSelection() {

            return this.$props.filterParams['filter_params'][this.previewParam]
        }

    },*/

  methods: {
    updateFilterResults() {
      this.loading = true

      //this.filterParams['filter_params'][this.previewParam] = this.inputSelection
      let payload = $.extend(true, {}, this.filterParams)
      payload["filter_params"][this.previewParam] = this.inputSelection
      payload["use_available_only"] = this.filterChoices

      CarbonReadyAPI.postInsetFilter(payload).then(response => {
        let choroplethData = response.data["chloropleth_data"]
        this.matchSummary = response.data["match_summary"]

        this.$emit("matchSummary", this.matchSummary)
        this.$emit("choroplethData", choroplethData)
        this.loading = false
        this.madeSelection = false
      })
    },
    saveFilter() {
      this.filterParams["filter_params"][this.previewParam] =
        this.inputSelection

      this.updateFilterResults()
      this.$emit("save")
      this.$emit("filterParams", this.filterParams)
      this.$emit("matchSummary", this.matchSummary)

      this.$emit("close", true)

      //emit final filter settings back to main buyerinset view, close panel
    },
    resetFilter() {
      if (this.filterChoices) {
        this.onExpansionPanelClick()
      } else {
        this.onExpansionPanelClick(this.previewParam)
      }
    },

    cancelFilter() {
      this.$emit("close", true)
      //close panel
    },

    selectAll() {
      let internalSearch = this.$refs.autocompleteSearch.internalSearch
      let thisSelection = []
      if (internalSearch != "" && internalSearch != undefined) {
        internalSearch = internalSearch.toLowerCase()

        thisSelection = this.items
          .filter(item => item.text.toLowerCase().includes(internalSearch))
          .map(item => item.value)
      } else {
        // = ''

        thisSelection = this.items.map(item => item.value)
      }
      if (this.inputSelection) {
        if (this.inputSelection.length > 0) {
          thisSelection.forEach(value => {
            this.inputSelection.push(value)
          })
        } else {
          this.inputSelection = thisSelection
        }
      } else {
        this.inputSelection = thisSelection
      }

      this.madeSelection = true
    },

    onExpansionPanelClick(removeParam = null) {
      this.filterParams["preview_param"] = this.previewParam
      this.filterParams["aggregation_metric"] = this.aggregationMetric //change this in above view later

      //deep clone
      let payload = JSON.parse(JSON.stringify(this.filterParams))
      if (removeParam != null) {
        delete payload["filter_params"][removeParam]
      }

      payload["use_available_only"] = this.filterChoices
      this.loading = true
      CarbonReadyAPI.postInsetFilter(payload)
        .then(response => {
          if ("geojson" in this.filterParams) {
            delete this.filterParams.geojson
          }

          this.apexChartData = response.data["apex_chart_options"]
          if (this.apexChartData.length > 0) {
            this.chartSeries = this.apexChartData[0]["chart_info"]["series"]
            this.chartOptions = {
              plotOptions: this.apexChartData[0]["chart_info"]["plotOptions"],
              dataLabels: this.apexChartData[0]["chart_info"]["dataLabels"],
              xaxis: this.apexChartData[0]["chart_info"]["xaxis"],
            }
            this.chartType =
              this.apexChartData[0]["chart_info"]["chart"]["type"]
          } else {
            this.apexChartData = null
            this.chartSeries = null
            this.chartOptions = null
          }
          this.items = []
          if (response.data["filter_preview"][this.previewParam] != undefined) {
            response.data["filter_preview"][this.previewParam].forEach(x => {
              if (x.length > 2) {
                if (
                  this.previewParam != "practices" &&
                  this.previewParam != "org_nodes"
                ) {
                  this.items.push({
                    text: x[1] + " (" + x[0] + ")",
                    value: x[0],
                  })
                } else {
                  this.items.push({
                    text: x[1],
                    value: x[0],
                  })
                }
              } else {
                this.items.push(x[0])
              }
            })
          }
          let choroplethData = response.data["chloropleth_data"]
          this.matchSummary = response.data["match_summary"]

          this.$emit("matchSummary", this.matchSummary)
          this.$emit("choroplethData", choroplethData)

          if (this.selectionType == "slider") {
            this.minSlider = parseFloat(_.min(this.items))
            this.maxSlider = parseFloat(_.max(this.items))
            this.stepSize = (this.maxSlider - this.minSlider) / 100
          }
          this.loading = false
        })
        .then(() => {
          if (this.autoSelect) {
            this.selectAll()
            this.autoSelect = false
          }
        })
    },
  },

  mounted() {
    if (this.previewParam == "geoids" && "geojson" in this.filterParams) {
      this.autoSelect = true
      this.onExpansionPanelClick()
    }
  },
  created() {
    if (this.previewParam == "crop_id") {
      this.onExpansionPanelClick()
    }
  },
}
</script>
