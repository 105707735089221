<template>
  <ArvaModal
    name="UploadFieldDetailModal"
    :title="upload.name_display"
    :wide="true"
    :width="700"
    @close-modal="handleClose"
  >
    <AlertErrorModal
      v-if="alertErrorModalOpen"
      titleText="Failed To Download Dataset"
      :errorMessage="errorMessage"
      @close-modal="alertErrorModalOpen = false"
    />

    <div class="row">
      <div class="col-12 details">
        <div class="detail-row">
          <div>Year:</div>
          <div>{{ year }}</div>
        </div>
        <div class="detail-row">
          <div>Field GeoPackage #ID:</div>
          <div>{{ fieldGpkgId }}</div>
        </div>
        <div class="detail-row">
          <div>Number Of Observations:</div>
          <div>{{ numOfObservations }}</div>
        </div>
        <div class="detail-row">
          <div>Uploaded at:</div>
          <div>{{ upload.created_at | shortDatetime }}</div>
        </div>
        <div v-if="uploadedBy" class="detail-row">
          <div>Uploaded by:</div>
          <div>{{ uploadedBy }}</div>
        </div>
        <div class="detail-row">
          <div>Dataset Type:</div>
          <div>{{ upload.dataset_type_display }}</div>
        </div>
        <div class="detail-row">
          <div>UUID:</div>
          <div>{{ upload.uuid }}</div>
        </div>
        <div class="detail-row">
          <div>Upload #ID:</div>
          <div>{{ upload.id }}</div>
        </div>
        <div v-if="!isReadOnly" class="download">
          <a @click="getSignedUrl()">
            <i class="fa fa-download" />Download dataset
          </a>
        </div>
      </div>
    </div>
  </ArvaModal>
</template>

<script>
import { mapState } from "vuex"
import ArvaModal from "./ArvaModal"
import UploadsAPI from "@/api/UploadsAPI"
import AlertErrorModal from "@/components/modals/AlertErrorModal"

export default {
  name: "UploadFieldDetailModal",
  props: {
    uploadByFieldId: { type: Number },
  },
  components: { ArvaModal, AlertErrorModal },

  data() {
    return {
      fieldGpkgId: null,
      numOfObservations: null,
      upload: {},
      year: null,
      alertErrorModalOpen: false,
      errorMessage: [],
    }
  },

  computed: {
    ...mapState({
      isReadOnly: state => state.User.user.permission === "Read-Only",
      uploadID: state => state.Uploads.detailsModalUploadFieldID,
      uploads: state => state.Uploads.uploads,
    }),

    uploadedBy() {
      const { user } = this.upload
      if (!user) return ""
      if (user.first_name && user.last_name)
        return `${user.first_name} ${user.last_name}`
      return user.email
    },
  },

  methods: {
    handleClose() {
      this.$emit("close-modal")
    },

    getSignedUrl() {
      this.downloadDataSet()
    },

    async downloadDataSet() {
      await UploadsAPI.downloadDataSet(this.uploadByFieldId)
        .then(response => {
          const downloadUrl = response.data
          window.open(downloadUrl, "_blank")
          this.handleClose()
        })
        .catch(error => {
          this.errorMessage = [
            "Unable to download the dataset due to an unexpected error.",
          ]
          this.alertErrorModalOpen = true
          console.log(error)
        })
    },
  },

  async mounted() {
    const uploadByField = this.uploads.find(
      record => record.id === this.uploadByFieldId
    )
    if (uploadByField) {
      this.fieldGpkgId = uploadByField.id
      this.numOfObservations = uploadByField.num_obs
      this.upload = uploadByField.gpkg.upload
      this.year = uploadByField.year
    }
  },
}
</script>

<style scoped>
.details {
  padding: 20px 60px;
}

.detail-row {
  display: flex;
}

.detail-row > div:first-of-type {
  font-weight: bold;
  min-width: 180px;
}

.download {
  margin-top: 8px;
  font-weight: bold;
  color: #1976d2;
}

.download i {
  margin-right: 5px;
}

.parsed {
  margin-top: 20px;
}

.parsed > div:first-of-type {
  font-weight: bold;
}

.parsed > div:last-of-type {
  font-size: 13px;
  padding-left: 10px;
}

.parsed > div:last-of-type i {
  width: 10px;
  margin-right: 10px;
}

.fa-check {
  color: #00a200;
}

.fa-times {
  color: #e00000;
}
</style>
