import API from "./API"

const fetchImagingDates = payload =>
  API.get("/raster_fieldlevelanalytics/", { params: payload })

// payload data format: { field_id: fieldId, dates: [date] or [date1, date2] }
const fetchGeotiffByFieldDate = payload =>
  API.post("/raster-single-geotiff/", payload)

const fetchFieldsWithRaster = payload =>
  API.post("/fields-with-raster/", payload)

const fetchGeotiffExport = payload => API.post("/geotiff-export/", payload)

const fetchImageryZones = payload => API.post("/imagery-zones/", payload)

const fetchZonesExport = payload => API.post("/imagery-zones-export/", payload)

export default {
  fetchFieldsWithRaster,
  fetchGeotiffByFieldDate,
  fetchGeotiffExport,
  fetchImagingDates,
  fetchImageryZones,
  fetchZonesExport,
}
