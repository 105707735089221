<template>
  <div>
    <div class="row">
      <div class="col">
        <div
          class="settings-section-title"
          @click="showFertData = !showFertData"
        >
          Fertilizer Applications
          <i class="fa fa-chevron-down" v-if="!showFertData" />
          <i class="fa fa-chevron-up" v-else />
        </div>
      </div>
    </div>
    <div v-if="showFertData" class="settings-table">
      <!-- <SettingsRow
        v-on:clear-input="
          fertilizerData.yield_goal_bu_per_acre = null
          handleSubmit()
        "
        v-on:submit-row="handleSubmit"
        v-on:focus-input="focusInput('yield_goal_bu_per_acre')"
      >
        <template v-slot:title>
          Yield Goal
        </template>
        <template v-slot:display-value>
          {{ displaySetting("yield_goal_bu_per_acre") }}
        </template>
        <template v-slot:input="{ handleEnter }">
          <input
            v-model="fertilizerData.yield_goal_bu_per_acre"
            type="number"
            :placeholder="displaySetting('yield_goal_bu_per_acre')"
            ref="yield_goal_bu_per_acre"
            @keyup="handleEnter"
          />
        </template>
        <template v-slot:default-type>
          <div
            v-bind:class="{ 'default-name': isLink('yield_goal_bu_per_acre') }"
            @click="handleLinkClick('yield_goal_bu_per_acre')"
          >
            {{ defaultType("yield_goal_bu_per_acre") }}
            <i
              v-if="isLink('yield_goal_bu_per_acre')"
              class="fa fa-arrow-circle-right"
            />
          </div>
        </template>
      </SettingsRow> -->

      <!-- <SettingsRow
        v-on:clear-input="
          fertilizerData.n_units_per_acre = null
          handleSubmit()
        "
        v-on:submit-row="handleSubmit"
        v-on:focus-input="focusInput('n_units_per_acre')"
      >
        <template v-slot:title>
          N Lbs / Acre
        </template>
        <template v-slot:display-value>
          {{ displaySetting("n_units_per_acre") }}
        </template>
        <template v-slot:input="{ handleEnter }">
          <input
            v-model="fertilizerData.n_units_per_acre"
            type="number"
            :placeholder="displaySetting('n_units_per_acre')"
            ref="n_units_per_acre"
            @keyup="handleEnter"
          />
        </template>
        <template v-slot:default-type>
          <div
            v-bind:class="{ 'default-name': isLink('n_units_per_acre') }"
            @click="handleLinkClick('n_units_per_acre')"
          >
            {{ defaultType("n_units_per_acre") }}
            <i
              v-if="isLink('n_units_per_acre')"
              class="fa fa-arrow-circle-right"
            />
          </div>
        </template>
      </SettingsRow> -->

      <!-- <SettingsRow
        v-on:clear-input="
          fertilizerData.p_units_per_acre = null
          handleSubmit()
        "
        v-on:submit-row="handleSubmit"
        v-on:focus-input="focusInput('p_units_per_acre')"
      >
        <template v-slot:title>
          P Lbs / Acre
        </template>
        <template v-slot:display-value>
          {{ displaySetting("p_units_per_acre") }}
        </template>
        <template v-slot:input="{ handleEnter }">
          <input
            v-model="fertilizerData.p_units_per_acre"
            type="number"
            :placeholder="displaySetting('p_units_per_acre')"
            ref="p_units_per_acre"
            @keyup="handleEnter"
          />
        </template>
        <template v-slot:default-type>
          <div
            v-bind:class="{ 'default-name': isLink('p_units_per_acre') }"
            @click="handleLinkClick('p_units_per_acre')"
          >
            {{ defaultType("p_units_per_acre") }}
            <i
              v-if="isLink('p_units_per_acre')"
              class="fa fa-arrow-circle-right"
            />
          </div>
        </template>
      </SettingsRow> -->

      <!-- <SettingsRow
        v-on:clear-input="
          fertilizerData.k_units_per_acre = null
          handleSubmit()
        "
        v-on:submit-row="handleSubmit"
        v-on:focus-input="focusInput('k_units_per_acre')"
      >
        <template v-slot:title>
          K Lbs / Acre
        </template>
        <template v-slot:display-value>
          {{ displaySetting("k_units_per_acre") }}
        </template>
        <template v-slot:input="{ handleEnter }">
          <input
            v-model="fertilizerData.k_units_per_acre"
            type="number"
            :placeholder="displaySetting('k_units_per_acre')"
            ref="k_units_per_acre"
            @keyup="handleEnter"
          />
        </template>
        <template v-slot:default-type>
          <div
            v-bind:class="{ 'default-name': isLink('k_units_per_acre') }"
            @click="handleLinkClick('k_units_per_acre')"
          >
            {{ defaultType("k_units_per_acre") }}
            <i
              v-if="isLink('k_units_per_acre')"
              class="fa fa-arrow-circle-right"
            />
          </div>
        </template>
      </SettingsRow> -->

      <!-- <SettingsRow
        v-on:clear-input="
          fertilizerData.s_units_per_acre = null
          handleSubmit()
        "
        v-on:submit-row="handleSubmit"
        v-on:focus-input="focusInput('s_units_per_acre')"
      >
        <template v-slot:title>
          S Lbs / Acre
        </template>
        <template v-slot:display-value>
          {{ displaySetting("s_units_per_acre") }}
        </template>
        <template v-slot:input="{ handleEnter }">
          <input
            v-model="fertilizerData.s_units_per_acre"
            type="number"
            :placeholder="displaySetting('s_units_per_acre')"
            ref="s_units_per_acre"
            @keyup="handleEnter"
          />
        </template>
        <template v-slot:default-type>
          <div
            v-bind:class="{ 'default-name': isLink('s_units_per_acre') }"
            @click="handleLinkClick('s_units_per_acre')"
          >
            {{ defaultType("s_units_per_acre") }}
            <i
              v-if="isLink('s_units_per_acre')"
              class="fa fa-arrow-circle-right"
            />
          </div>
        </template>
      </SettingsRow> -->

      <!-- <SettingsRow
        v-on:clear-input="
          fertilizerData.z_units_per_acre = null
          handleSubmit()
        "
        v-on:submit-row="handleSubmit"
        v-on:focus-input="focusInput('z_units_per_acre')"
      >
        <template v-slot:title>
          Z Lbs / Acre
        </template>
        <template v-slot:display-value>
          {{ displaySetting("z_units_per_acre") }}
        </template>
        <template v-slot:input="{ handleEnter }">
          <input
            v-model="fertilizerData.z_units_per_acre"
            type="number"
            :placeholder="displaySetting('z_units_per_acre')"
            ref="z_units_per_acre"
            @keyup="handleEnter"
          />
        </template>
        <template v-slot:default-type>
          <div
            v-bind:class="{ 'default-name': isLink('z_units_per_acre') }"
            @click="handleLinkClick('z_units_per_acre')"
          >
            {{ defaultType("z_units_per_acre") }}
            <i
              v-if="isLink('z_units_per_acre')"
              class="fa fa-arrow-circle-right"
            />
          </div>
        </template>
      </SettingsRow> -->

      <div class="row px-2 ml-2 mt-2 mb-4">
        <div class="col py-0">
          <div class="row">
            <div
              class="col-8 pb-0 mt-1 text-bold click-me"
              @click="showFertPlans = !showFertPlans"
            >
              Applications
              <i class="fa fa-chevron-down" v-if="!showFertPlans" />
              <i class="fa fa-chevron-up" v-else />
            </div>
          </div>
          <div class="row pl-2 pr-4">
            <table class="table" v-if="showFertPlans">
              <thead>
                <tr>
                  <th class="w-15">Method</th>
                  <th class="w-18">Type</th>
                  <th class="w-15">Fert. Product</th>
                  <th>N</th>
                  <th>P</th>
                  <th>K</th>
                  <th>S</th>
                  <th class="w-18">App. Date</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="fert in carbonFerts" :key="fert.idx">
                  <td>
                    {{ fert.method.value }}
                  </td>
                  <td>{{ fert.method_type ? fert.method_type.value : "" }}</td>
                  <td>
                    {{ fert.product.value }}
                  </td>
                  <td>{{ fert.N.value }}</td>
                  <td>{{ fert.P.value }}</td>
                  <td>{{ fert.K.value }}</td>
                  <td>{{ fert.S.value }}</td>
                  <td>{{ fert.date.value | date }}</td>
                  <!-- <td>
                    {{ fert.fert_rate.value }}
                    {{ fert.unit.value | fertUnits }}
                  </td> -->
                  <!-- <td>
                    {{ fert.cost.value | currency }} /
                    {{ fert.unit.value | fertCostUnits }}
                  </td>
                  <td class="px-0">
                    <div
                      class="default-name"
                      v-if="fertData.location !== 'fertilizerData'"
                    >
                      {{ fertDataLocationMapper[fertData.location] }}
                      <i class="fa fa-arrow-circle-right" />
                    </div>
                    <i
                      v-else
                      class="fa fa-times-circle"
                      @click="handleDeleteFert(fertData.location, fert)"
                    />
                  </td> -->
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <FertilizerPlanModal
        v-if="fertPlanModalOpen"
        @close-template-modal="fertPlanModalOpen = false"
        @submit-fert-plan="handleAddFert($event)"
      />
    </div>
  </div>
</template>
<script>
import _ from "lodash"
import { mapActions } from "vuex"
import DefaultsAPI from "@/api/DefaultsAPI"
import {
  APPLICATION_METHOD_CHOICES,
  WET_METHOD_TYPE_CHOICES,
  DRY_METHOD_TYPE_CHOCIES,
  DRY_FERT_PRODUCT_CHOICES,
  WET_FERT_PRODUCT_CHOICES,
  MANURE_PRODUCT_CHOICES,
  RATE_UNIT_CHOICES,
} from "@/constants/defaults"
import { Fields } from "@/store/modules"
// import SettingsRow from "./SettingsRow"
import FertilizerPlanModal from "@/components/modals/fieldSettings/FertilizerPlanModal"

export default {
  name: "FertilizerFieldSettings",
  components: {
    // SettingsRow,
    FertilizerPlanModal,
  },
  props: {
    carbonSettings: Array,
    fertilizerData: { type: Object, required: true },
    fertilizerDefaults: { type: Object, required: true },
    generalDefaults: { type: Object, required: true },
    defaultsName: String,
    year: String,
  },
  data() {
    return {
      // view toggle
      showFertData: false,
      // initial values
      appMethodValue: null,
      fertRateValue: 0,
      fertRateUnitsValue: null,
      methodTypeValue: null,
      fertProductValue: null,
      applicationDateValue: null,
      // dropdown choices
      applicationMethodChoices: APPLICATION_METHOD_CHOICES,
      wetMethodTypeChoices: WET_METHOD_TYPE_CHOICES,
      dryMethodTypeChoices: DRY_METHOD_TYPE_CHOCIES,
      dryFertProductChoices: DRY_FERT_PRODUCT_CHOICES,
      wetFertProductChoices: WET_FERT_PRODUCT_CHOICES,
      manureProductChoices: MANURE_PRODUCT_CHOICES,
      rateUnitChoices: RATE_UNIT_CHOICES,
      // fert plan modal
      showFertPlans: false,
      fertPlanModalOpen: false,
    }
  },
  computed: {
    carbonFerts() {
      return this.carbonSettings
    },
    fertDataForYear() {
      const data = this.fertilizerData.data.find(
        d => d.year === parseInt(this.year)
      )
      if (!data) {
        this.addDataForYear()
        return this.fertilizerData.data.find(
          d => d.year === parseInt(this.year)
        )
      }

      return data
    },
    fertData() {
      if (this.fertDataForYear !== null) {
        return {
          data: _.orderBy(
            this.fertDataForYear.fert_applications,
            ["application_date"],
            ["asc"]
          ),
          location: "fertilizerData",
        }
      }
      if (
        this.fertilizerDefaults.fert_applications !== null &&
        this.fertilizerDefaults.fert_applications.length > 0
      ) {
        return {
          data: _.orderBy(
            this.fertilizerDefaults.fert_applications,
            ["application_date"],
            ["asc"]
          ),
          location: "fertilizerDefaults",
        }
      }
      return {
        data: _.orderBy(
          this.generalDefaults.fert_applications,
          ["application_date"],
          ["asc"]
        ),
        location: "generalDefaults",
      }
    },
    methodTypeChoices() {
      if (this.appMethodValue === "wet") return this.wetMethodTypeChoices
      if (this.appMethodValue === "dry") return this.dryMethodTypeChoices
      if (
        this.fertilizerDefaults.fert_applications[0].application_method ===
        "wet"
      )
        return this.wetMethodTypeChoices
      if (
        this.fertilizerDefaults.fert_applications[0].application_method ===
        "dry"
      )
        return this.dryMethodTypeChoices
      return []
    },
    productChoices() {
      if (this.appMethodValue === "wet") return this.wetFertProductChoices
      if (this.appMethodValue === "dry") return this.dryFertProductChoices
      if (this.appMethodValue === "manure") return this.manureProductChoices
      if (
        this.fertilizerDefaults.fert_applications[0].application_method ===
        "wet"
      )
        return this.wetFertProductChoices
      if (
        this.fertilizerDefaults.fert_applications[0].application_method ===
        "dry"
      )
        return this.dryFertProductChoices
      if (
        this.fertilizerDefaults.fert_applications[0].application_method ===
        "manure"
      )
        return this.manureProductChoices
      return []
    },
    fertDataLocationMapper() {
      const mapper = {
        fertilizerData: "Field Specific",
        fertilizerDefaults: this.defaultsName,
        generalDefaults: "Field Level Information",
      }
      return mapper
    },
  },

  filters: {
    fertUnits: value => {
      if (value === "lb") return "lbs/ac"
      if (value === "gal") return "gal/ac"
      return "units/ac"
    },

    fertCostUnits: value => {
      if (value === "lb") return "ton"
      if (value === "gal") return "gal"
      return "unit"
    },
  },

  methods: {
    ...mapActions({
      fetchFields: Fields.Actions.fetchFields,
    }),
    addDataForYear() {
      this.fertilizerData.data.push({
        fert_applications: [],
        year: parseInt(this.year),
      })
    },
    async handleSubmit() {
      await DefaultsAPI.update(
        "fertilizer-defaults",
        this.fertilizerData.id,
        this.fertilizerData
      )
      this.fetchFields()
    },
    defaultType(defaultName) {
      if (this.fertilizerData[defaultName]) return "Field Setting"
      if (this.fertilizerDefaults[defaultName]) return this.defaultsName
      return "General Defaults"
    },
    displaySetting(settingName) {
      if (this.fertilizerData[settingName])
        return this.fertilizerData[settingName]
      if (this.fertilizerDefaults[settingName])
        return this.fertilizerDefaults[settingName]
      if (this.generalDefaults.fertilizer_data[settingName])
        return this.generalDefaults.fertilizer_data[settingName]
      return "--"
    },
    isLink(defaultName) {
      if (this.fertilizerData[defaultName]) return false
      if (this.fertilizerDefaults[defaultName]) return true
      return true
    },
    handleLinkClick(defaultName) {
      if (
        !this.fertilizerData[defaultName] &&
        this.fertilizerDefaults[defaultName]
      ) {
        this.$router.push(
          `/settings/defaults/${this.fertilizerDefaults.defaults_template}`
        )
      }
      if (
        !this.fertilizerData[defaultName] &&
        !this.fertilizerDefaults[defaultName]
      ) {
        this.$router.push(`/settings/defaults/${this.generalDefaults.id}`)
      }
    },
    focusInput(input) {
      this.$nextTick(() => {
        this.$refs[input].focus()
      })
    },
    handleEnter() {},
    handleAddFert(val) {
      this.fertPlanModalOpen = false
      if (!this.fertDataForYear.fert_applications)
        this.fertDataForYear.fert_applications = []
      this.fertDataForYear.fert_applications.push(val)
      this.handleSubmit()
    },
    handleDeleteFert(location, fert) {
      if (location === "fertilizerData") {
        const newFerts = _.without(this.fertDataForYear.fert_applications, fert)
        this.fertDataForYear.fert_applications = newFerts
        this.handleSubmit()
      }
      if (location === "fertilizerDefaults") {
        this.$router.push(
          `/settings/defaults/${this.fertilizerDefaults.defaults_template}`
        )
      }
      if (location === "generalDefaults") {
        this.$router.push(`/settings/defaults/${this.generalDefaults.id}`)
      }
    },
  },
}
</script>
<style scoped>
.click-me {
  cursor: pointer;
}
.fa-times-circle {
  cursor: pointer;
  color: #e60000;
}
</style>
