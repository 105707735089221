<template>
  <div class="card-body">
    <b-alert v-model="showSuccessAlert" variant="success" dismissible>
      {{ message }}
    </b-alert>

    <b-alert v-model="showErrorAlert" variant="danger" dismissible>
      <div v-if="errorMessage.length > 0">
        <div
          class="error-message ml-5 p-1"
          v-for="currentError in errorMessage"
          :key="currentError"
        >
          <li>{{ currentError }}</li>
        </div>
      </div>

      <div v-else>
        {{ message }}
      </div>
    </b-alert>

    <div class="row mb-2 ml-2">
      <div class="col-4">
        <div class="row mb-2">
          <SearchBar :searchString="searchString" @searching="handleSearch" />
        </div>
        <div class="row">
          <div class="inactive-ball" />
          <div class="table-label mr-4">Inactive</div>
          <div class="active-ball" />
          <div class="table-label mr-4">Active</div>
        </div>
      </div>

      <div class="col-8">
        <v-btn
          v-if="checkedCount > 0"
          class="ml-auto mr-3 float-right"
          color="red lighten-1"
          @click="confirmSuspendUserModalOpen = true"
        >
          Suspend User(s) ({{ checkedCount }})
        </v-btn>

        <v-btn
          class="ml-auto mr-3 float-right"
          :loading="loadingAddNewUser"
          @click="openAddNewUserModal"
        >
          Add New User
        </v-btn>

        <v-btn
          class="ml-auto mr-3 float-right"
          :loading="loadingManageOrg"
          @click="openManageOrgModal"
        >
          Manage Organization
        </v-btn>
      </div>
    </div>

    <ConfirmModal
      v-if="confirmSuspendUserModalOpen"
      titleText="Confirm User Suspension"
      :confirmText="userSuspensionConfirmText"
      @confirm="suspendUsers"
      @close-modal="confirmSuspendUserModalOpen = false"
    />

    <div class="row">
      <div class="col-md-12">
        <v-simple-table
          class="selector-table"
          fixed-header
          height="calc(100vh - 350px)"
        >
          <thead>
            <tr>
              <th>
                <v-checkbox
                  v-model="userSelectAllCheckbox"
                  @click="selectAllCheckBox"
                  name="selected"
                  ref="userSelectAllCheckbox"
                />
              </th>
              <th class="hover">
                First Name
              </th>
              <th class="hover">
                Last Name
              </th>
              <th class="hover">
                Email
              </th>
              <th class="hover">Status</th>
              <th width="150" class="text-center hover">Total Assigned Organizations</th>
              <th />
            </tr>
          </thead>

          <tbody>
            <UserListRow
              v-for="assignedOrg in searchFilteredItems"
              :key="assignedOrg.id"
              :assignedOrg="assignedOrg"
              @checked="checked"
              @unchecked="unchecked"
              @openEditUserModal="openEditUserModal"
              ref="userListRows"
            />
          </tbody>
        </v-simple-table>
      </div>

      <ManageOrgModal
        v-if="openManageOrgModalOpen"
        :adminUserId="adminUser.id"
        :corporation="corporation"
        :organizationOptions="organizationOptions"
        @close-modal="closeManageOrgModal"
        @add-new-organization="addNewOrganization"
        @remove-organization="removeOrganization"
        @edit-organization="editOrganization"
        @modify-position="modifyPosition"
        @error="displayError"
      />

      <AddNewUserModal
        v-if="addNewUserModalOpen"
        :adminUserId="adminUser.id"
        :corporation="corporation"
        :organizationOptions="organizationOptions"
        @close-modal="closeAddNewUserModal"
        @save-changes="addNewUser"
      />

      <EditUserModal
        v-if="editUserModalOpen"
        :adminUserId="adminUser.id"
        :assignedOrg="selectedUser"
        :assignedOrgList="assignedOrgBySelectedUser"
        :organizationOptions="organizationOptions"
        @close-modal="closeEditUserModal"
        @save-changes="editUser"
        @remove-assigned-org="removeAssignedOrg"
        @open-assigned-org-modal="openAssignedOrgModalToCreate"
        @edit-assigned-org-modal="openAssignedOrgModalToEdit"
      />

      <AssignedOrgModal
        v-if="openAssignedOrgModal"
        :selectedUserId="userId"
        :selectedAssignedOrg="selectedAssignedOrg"
        :corporation="corporation"
        :organizationOptions="organizationOptions"
        @add-new-assigned-org="addNewAssignedOrg"
        @edit-existing-assigned-org="editExistingAssignedOrg"
        @close-modal="openAssignedOrgModal=false"
      />

      <AlertErrorModal
        v-if="alertErrorModalOpen"
        :titleText="modalTitleText"
        :errorMessage="errorMessageForModal"
        @close-modal="alertErrorModalOpen = false"
      />
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex"
import SearchBar from "@/components/misc/SearchBar"
import UserListRow from "@/components/customerAdmin/userManagement/UserListRow"
import ConfirmModal from "@/components/modals/ConfirmModal"
import EditUserModal from "@/components/modals/customerAdmin/userManagement/EditUserModal"
import ManageOrgModal from "@/components/modals/customerAdmin/userManagement/ManageOrgModal"
import AddNewUserModal from "@/components/modals/customerAdmin/userManagement/AddNewUserModal"
import AssignedOrgModal from "@/components/modals/customerAdmin/userManagement/AssignedOrgModal"
import { User, Filter } from "@/store/modules"
import UserAPI from "@/api/UserAPI"
import AlertErrorModal from "@/components/modals/AlertErrorModal"
import OrganizationAPI from "@/api/OrganizationAPI"
import _ from "lodash"

export default {
  name: "UserManagementView",
  components: {
    SearchBar,
    UserListRow,
    ConfirmModal,
    ManageOrgModal,
    EditUserModal,
    AddNewUserModal,
    AssignedOrgModal,
    AlertErrorModal,
  },

  data() {
    return {
      showSpinner: false,
      showSuccessAlert: false,
      showErrorAlert: false,
      message: null,
      errorMessage: [],
      searchString: "",
      userSelectAllCheckbox: false,
      userToSuspend: [],
      openManageOrgModalOpen: false,
      addNewUserModalOpen: false,
      editUserModalOpen: false,
      confirmSuspendUserModalOpen: false,
      sortByRole: null,
      selectedUser: {},
      assignedOrgBySelectedUser: [],
      openAssignedOrgModal: false,
      userId: null,
      alertErrorModalOpen: false,
      errorMessageForModal: null,
      organizationOptions: [],
      loadingManageOrg: false,
      loadingAddNewUser: false,
    }
  },

  computed: {
    ...mapState({
      userManagement: state => state.User.userManagement,
      adminUser: state => state.User.user,
      corporation: state => state.Organization.organization.corporation,
    }),

    orderedUserManagement() {
      return _.orderBy(this.userManagement, 'user.is_active', 'desc')
    },

    searchFilteredItems() {
      if (!this.searchString) return this.orderedUserManagement
      const searchString = this.searchString.toLocaleLowerCase()
      return this.orderedUserManagement.filter(function (assignedOrg) {
        return (
          assignedOrg.user.first_name.toLowerCase().includes(searchString) ||
          assignedOrg.user.last_name.toLowerCase().includes(searchString) ||
          assignedOrg.user.email.toLowerCase().includes(searchString) ||
          assignedOrg.total_assigned_orgs == searchString
        )
      })
    },

    checkedCount() {
      return this.userToSuspend.length
    },

    userSuspensionConfirmText() {
      return this.checkedCount === 1
        ? "Are you sure you want to inactivate this user from the system?"
        : `Are you sure you want to inactivate ${this.checkedCount} users from the system?`
    },
  },

  methods: {
    ...mapMutations({
      setUserManagement: User.Mutations.setUserManagement,
      removeSelectedAssignedOrg: User.Mutations.removeSelectedAssignedOrg,
      addSelectedAssignedOrg: User.Mutations.addSelectedAssignedOrg,
      updateSelectedAssignedOrg: User.Mutations.updateSelectedAssignedOrg,
      setOrgNodes: Filter.Mutations.setOrgNodes,
    }),

    handleSearch(searchString) {
      this.searchString = searchString
    },

    sortIcon(sortValue) {
      if (sortValue === "asc") return "fa-sort-up"
      else if (sortValue === "desc") return "fa-sort-down"
      return "fa-sort"
    },

    handleSortRole() {
      if (!this.sortByRole) this.sortByRole = "asc"
      else if (this.sortByRole === "asc") this.sortByRole = "desc"
      else if (this.sortByRole === "desc") this.sortByRole = null
    },

    selectAllCheckBox() {
      this.$refs.userListRows.forEach(userListRow =>
        userListRow.setCheckbox(this.userSelectAllCheckbox)
      )
      if (!this.userSelectAllCheckbox) this.userToSuspend = []
    },

    deselectAllCheckbox() {
      this.$refs.userListRows.forEach(userListRow =>
        userListRow.setCheckbox(false)
      )
    },

    checked(id) {
      this.userToSuspend.push(id)
    },

    unchecked(id) {
      const index = this.userToSuspend.indexOf(id)
      if (index > -1) this.userToSuspend.splice(index, 1)
    },

    async openManageOrgModal() {
      this.loadingManageOrg = true
      await this.getOrgOptions()
      this.loadingManageOrg = false
      this.openManageOrgModalOpen = true
    },

    async openAddNewUserModal() {
      this.loadingAddNewUser = true
      await this.getOrgOptions()
      this.loadingAddNewUser = false
      this.addNewUserModalOpen = true
    },

    openEditUserModal(selectedUser) {
      this.selectedUser = selectedUser
      this.editUserModalOpen = true
    },

    closeManageOrgModal() {
      this.openManageOrgModalOpen = false
    },

    closeEditUserModal() {
      this.selectedUser = {}
      this.editUserModalOpen = false
    },

    closeAddNewUserModal() {
      this.addNewUserModalOpen = false
    },

    async openAssignedOrgModalToCreate(userId) {
      this.userId = userId
      this.selectedAssignedOrg = {}
      await this.getOrgOptions()
      this.openAssignedOrgModal = true
    },

    async openAssignedOrgModalToEdit(payload) {
      this.userId = payload.selected_user_id
      this.selectedAssignedOrg = payload.assigned_org
      await this.getOrgOptions()
      this.openAssignedOrgModal = true
    },

    async addNewAssignedOrg(payload) {
      let new_assigned_org = {}
      await UserAPI.addAssignedOrg(payload).then(({ data }) => {
        new_assigned_org = {
          corporation: {
            id: payload.corporation_id,
            name: data.corporation
          },
          id: data.id,
          organization: {
            id: payload.selected_org.id,
            name: payload.selected_org.name
          },
          role: data.role
        }

        this.addSelectedAssignedOrg({
          userId: payload.selected_user_id,
          assignedOrg: new_assigned_org
        })
        this.openAssignedOrgModal = false
      })
      .catch(error => {
        this.modalTitleText = "Failed To Assign A New Organization"
        this.errorMessageForModal = [error.response.data.message]
        this.alertErrorModalOpen = true
      })
    },

    async editExistingAssignedOrg(payload) {
      let updated_assigned_org = {}
      await UserAPI.updateAssignedOrg(payload).then(({ data }) => {
        updated_assigned_org = {
          corporation: {
            id: payload.corporation_id,
            name: data.corporation
          },
          id: data.id,
          organization: {
            id: payload.selected_org.id,
            name: payload.selected_org.name
          },
          role: data.role
        }

        this.updateSelectedAssignedOrg({
          userId: payload.selected_user_id,
          assignedOrg: updated_assigned_org
        })
        this.openAssignedOrgModal = false
      })
      .catch(error => {
        this.modalTitleText = "Failed To Edit A Selected Assigned Organization"
        this.errorMessageForModal = [error.response.data.message]
        this.alertErrorModalOpen = true
      })
    },

    async suspendUsers() {
      let user_management = {}

      const payload = {
        user_ids: this.userToSuspend,
        admin_user_id: this.adminUser.id,
      }
      await UserAPI.batchSuspendUsers(payload).then(({ data }) => {
        this.showErrorAlert = true
        this.message = "Successfully Suspended " + this.checkedCount + " User(s)"
        user_management = JSON.parse(data.user_management)
      })

      if (user_management) {
        this.setUserManagement(user_management)
      }

      this.confirmSuspendUserModalOpen = false
      this.deselectAllCheckbox()
      this.userToSuspend = []
    },

    async removeAssignedOrg(payload) {
      await UserAPI.removeAssignedOrg(payload).then(() => {
        this.removeSelectedAssignedOrg(payload)
      })
      .catch(error => {
        this.modalTitleText = "Failed To Remove A Selected Assigned Organization"
        this.errorMessageForModal = [error.response.data.message]
        this.alertErrorModalOpen = true
      })
    },

    async editUser(payload) {
      let user_management = {}

      await UserAPI.editUser(payload).then(({ data }) => {
        this.showSuccessAlert = true
        this.message = "Successfully Edited User <" + payload.selected_user.email + ">"
        
        user_management = JSON.parse(data.user_management)
        if (user_management) this.setUserManagement(user_management)
        this.closeEditUserModal()
      }).catch(error => {
        this.modalTitleText = "Failed To Edit An Existing User"
        this.errorMessageForModal = error.response.data.message
        this.alertErrorModalOpen = true
      })
    },

    async addNewUser(payload) {
      let user_management = {}

      await UserAPI.addNewUser(payload).then(({ data }) => {
        this.showSuccessAlert = true
        this.message = "Successfully Added New User <" + payload.user_email + ">"

        user_management = JSON.parse(data.user_management)
        if (user_management) this.setUserManagement(user_management)
        this.closeAddNewUserModal()
      }).catch(error => {
        this.modalTitleText = "Failed To Add New User"
        this.errorMessageForModal = error.response.data.message
        this.alertErrorModalOpen = true
      })
    },

    async addNewOrganization(payload) {
      await OrganizationAPI.addNewOrganization(payload).then(({ data }) => {
        this.showSuccessAlert = true
        this.message = "Successfully added a new organization."
        this.organizationOptions = data.org_options
        if (data.org_tree.length > 0) this.setOrgNodes(data.org_tree)
        this.closeManageOrgModal()
      })
      .catch(error => {
        this.modalTitleText = "Failed To Add New Organization"
        this.errorMessageForModal = error.response.data.message
        this.alertErrorModalOpen = true
      })
    },

    async editOrganization(payload) {
      await OrganizationAPI.editOrganization(payload.selected_org_id, payload).then(({ data }) => {
        this.showSuccessAlert = true
        this.message = "Successfully edited a selected organization."
        this.organizationOptions = data.org_options
        if (data.org_tree.length > 0) this.setOrgNodes(data.org_tree)
        this.closeManageOrgModal()
      })
      .catch(error => {
        this.modalTitleText = "Failed To Remove Organization"
        this.errorMessageForModal = error.response.data.message
        this.alertErrorModalOpen = true
      })
    },

    async removeOrganization(org_node_id) {
      await OrganizationAPI.removeOrganization(org_node_id).then(({ data }) => {
        this.showSuccessAlert = true
        this.message = "Successfully deleted a selected organization."
        this.organizationOptions = data.org_options
        if (data.org_tree.length > 0) this.setOrgNodes(data.org_tree)
        this.closeManageOrgModal()
      })
      .catch(error => {
        this.modalTitleText = "Failed To Remove Organization"
        this.errorMessageForModal = error.response.data.message
        this.alertErrorModalOpen = true
      })
    },

    async modifyPosition(payload) {
      await OrganizationAPI.modifyPosition(payload).then(({ data }) => {
        this.showSuccessAlert = true
        this.message = "Successfully modified new position for the selected organization."
        this.organizationOptions = data.org_options
        if (data.org_tree.length > 0) this.setOrgNodes(data.org_tree)
        this.closeManageOrgModal()
      })
      .catch(error => {
        this.modalTitleText = "Failed To Modify Position"
        this.errorMessageForModal = error.response.data.message
        this.alertErrorModalOpen = true
      })
    },

    displayError(error) {
      this.modalTitleText = "Failed To Modify Position"
      this.errorMessageForModal = error.response.data.message
      this.alertErrorModalOpen = true
    },

    // Fetch organization drop down options
    async getOrgOptions() {
      const payload = {
        admin_user_id: this.adminUser.id,
      }
      await UserAPI.getOrgOptions(payload).then(({ data }) => {
        this.organizationOptions = data.org_options
      })
    },
  },
}
</script>

<style scoped>
.inactive-ball {
  background: #850000;
  width: 12px;
  height: 12px;
  border-radius: 12px;
  margin-top: 3px;
  margin-right: 1px;
}

.active-ball {
  background: #324a75;
  width: 12px;
  height: 12px;
  border-radius: 12px;
  margin-top: 3px;
  margin-right: 1px;
}
</style>
