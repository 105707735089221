<template>
  <div class="row">
    <form @submit.prevent="handleSubmit(true)" novalidate>
      <div class="card">
        <h2
          class="card-heading default-title"
          @click="showInputs = !showInputs"
        >
          Season Planting Plan
          <i class="fa fa-chevron-down" v-if="!showInputs" />
          <i class="fa fa-chevron-up" v-else />
        </h2>
        <div class="card-body d-flex" v-if="showInputs">
          <div class="left-side">
            <div class="row">
              <div class="col">
                <span class="default-input-title">Previous Year Crop Type</span>
                <v-select-old
                  class="select-style"
                  label="display_name"
                  :options="cropChoices"
                  :reduce="o => o.value"
                  v-model="plantingData.previous_year_crop"
                />
              </div>
              <div class="col">
                <span class="default-input-title">Current Year Crop Type</span>
                <v-select-old
                  class="select-style"
                  label="display_name"
                  :options="cropChoices"
                  :reduce="o => o.value"
                  v-model="plantingData.current_year_crop"
                />
              </div>
              <div class="col">
                <span class="default-input-title">Monitor Type</span>
                <v-select-old
                  class="select-style"
                  label="display_name"
                  :options="monitorChoices"
                  :reduce="o => o.value"
                  v-model="plantingData.monitor_type"
                />
              </div>
            </div>

            <div class="row">
              <div class="col">
                <span class="default-input-title">Starter Fertilizer</span>
                <v-select-old
                  class="select-style"
                  label="display_name"
                  :options="starterFertilizerChoices"
                  :reduce="o => o.value"
                  v-model="plantingData.starter_fertilizer"
                />
              </div>

              <div class="col">
                <span class="default-input-title">Starter Fertilizer Rate</span>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">#</span>
                  </div>
                  <input
                    type="number"
                    class="form-control"
                    v-model="plantingData.starter_fertilizer_rate"
                  />
                </div>
              </div>

              <div class="col">
                <span class="default-input-title">Biological Name</span>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    maxlength="30"
                    v-model="plantingData.biological_name"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <table class="table" v-if="showSeedsTable">
                  <thead>
                    <tr>
                      <th>Seed</th>
                      <th>Seeding Rate</th>
                      <th>Cost / Bag</th>
                      <th>Seeds / Bag</th>
                      <th>Planting Date</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="seed in plantingData.seeds" :key="seed.idx">
                      <td>
                        {{ seed.seed_manufacturer }} {{ seed.seed_variety }}
                      </td>
                      <td>
                        {{ seed.seeding_rate }} {{ seed.seeding_rate_units }}
                      </td>
                      <td>{{ seed.seed_cost_per_bag | currency }}</td>
                      <td>{{ seed.seeds_per_bag }}</td>
                      <td>{{ seed.planting_date | date }}</td>
                      <td>
                        <i
                          class="fa fa-times-circle"
                          @click="deleteSeed(seed.idx)"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="right-side">
            <div class="row">
              <div class="col">
                <span class="default-input-title">Seeding Rate</span>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">#</span>
                  </div>
                  <input
                    type="number"
                    class="form-control"
                    v-model="seedingRate"
                  />
                </div>
              </div>
              <div class="col">
                <span class="default-input-title">Seed Rate Units</span>
                <v-select-old
                  class="select-style"
                  label="display_name"
                  :options="seedRateUnitChoices"
                  :reduce="o => o.value"
                  v-model="seedingRateUnits"
                />
              </div>
            </div>
            <div class="row">
              <div class="col">
                <span class="default-input-title">Seed Manufacturer</span>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    v-model="seedManufacturer"
                  />
                </div>
              </div>
              <div class="col">
                <span class="default-input-title">Planting Date</span>
                <datepicker v-model="plantingDate" />
              </div>
            </div>
            <div class="row">
              <div class="col">
                <span class="default-input-title">Seed Variety</span>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    v-model="seedVariety"
                  />
                </div>
              </div>
              <div class="col">
                <span class="default-input-title">Seed Cost Per Bag</span>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">$</span>
                  </div>
                  <input
                    type="number"
                    class="form-control"
                    v-model="seedCostPerBag"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <span class="default-input-title">Seeds Per Bag</span>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">#</span>
                  </div>
                  <input
                    type="number"
                    class="form-control"
                    v-model="seedsPerBag"
                  />
                </div>
              </div>
              <div class="col" />
            </div>
            <div class="row">
              <div class="col">
                <button
                  type="button"
                  class="btn btn-primary update-btn"
                  :class="{ disabled: !canAddProduct }"
                  @click="handleAddProduct(true)"
                >
                  <i class="fa fa-plus" />Add Product
                </button>
              </div>
              <div class="col">
                <button
                  type="submit"
                  class="btn btn-primary update-btn"
                  :class="{ disabled: !canUpdate }"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import _ from "lodash"
import { mapActions } from "vuex"
import DefaultsAPI from "@/api/DefaultsAPI"
import { CROP_DROPDOWN_OPTIONS } from "@/constants"
import {
  MONITOR_CHOICES,
  STARTER_FERTILIZER_CHOICES,
  SEEDING_RATE_UNIT_CHOICES,
} from "@/constants/defaults"
import { Fields } from "@/store/modules"
import { successMessage } from "@/utility"

export default {
  name: "PlantingDataDefaults",
  props: {
    plantingData: { type: Object, required: true },
  },

  data() {
    return {
      showInputs: true,
      cropChoices: CROP_DROPDOWN_OPTIONS,
      monitorChoices: MONITOR_CHOICES,
      starterFertilizerChoices: STARTER_FERTILIZER_CHOICES,
      seedRateUnitChoices: SEEDING_RATE_UNIT_CHOICES,
      // for adding new seeds/planting dates
      seedingRate: null,
      seedingRateUnits: null,
      plantingDate: null,
      seedManufacturer: null,
      seedVariety: null,
      seedCostPerBag: null,
      seedsPerBag: null,
      // update button highlighting
      initialPreviousCrop: null,
      initialCurrentCrop: null,
      initialMonitorType: null,
      initialStarterFert: null,
      initialStarterFertRate: null,
      initialBiologicalName: null,
    }
  },

  computed: {
    canUpdate() {
      const {
        previous_year_crop,
        current_year_crop,
        monitor_type,
        starter_fertilizer,
        starter_fertilizer_rate,
        biological_name,
      } = this.plantingData
      const {
        initialPreviousCrop,
        initialCurrentCrop,
        initialMonitorType,
        initialStarterFert,
        initialStarterFertRate,
        initialBiologicalName,
      } = this
      return (
        previous_year_crop !== initialPreviousCrop ||
        current_year_crop !== initialCurrentCrop ||
        monitor_type !== initialMonitorType ||
        starter_fertilizer !== initialStarterFert ||
        starter_fertilizer_rate !== initialStarterFertRate ||
        biological_name !== initialBiologicalName
      )
    },

    canAddProduct() {
      const {
        seedingRate,
        seedingRateUnits,
        plantingDate,
        seedManufacturer,
        seedVariety,
        seedCostPerBag,
        seedsPerBag,
      } = this
      return (
        !!seedingRate &&
        !!seedingRateUnits &&
        !!plantingDate &&
        !!seedManufacturer &&
        !!seedVariety &&
        !!seedCostPerBag &&
        !!seedsPerBag
      )
    },

    showSeedsTable() {
      return !_.isEmpty(this.plantingData) && this.plantingData.seeds.length > 0
    },
  },

  methods: {
    ...mapActions({
      fetchFields: Fields.Actions.fetchFields,
    }),

    deleteSeed(idx) {
      this.plantingData.seeds = this.plantingData.seeds.filter(
        seed => seed.idx !== idx
      )
      this.handleSubmit()
    },

    handleAddProduct(submit = false) {
      if (this.canAddProduct) {
        this.plantingData.seeds.push({
          seeding_rate: this.seedingRate,
          seeding_rate_units: this.seedingRateUnits,
          planting_date: this.plantingDate,
          seed_manufacturer: this.seedManufacturer,
          seed_variety: this.seedVariety,
          seed_cost_per_bag: this.seedCostPerBag,
          seeds_per_bag: this.seedsPerBag,
        })

        this.plantingData.seeds = this.plantingData.seeds.map((row, idx) => ({
          ...row,
          idx,
        }))

        if (submit) this.handleSubmit()

        this.seedingRate = null
        this.seedingRateUnits = null
        this.plantingDate = null
        this.seedManufacturer = null
        this.seedVariety = null
        this.seedCostPerBag = null
      }
    },

    async handleSubmit(addProduct = false) {
      if (addProduct) this.handleAddProduct()

      await DefaultsAPI.update(
        "planting-defaults",
        this.plantingData.id,
        this.plantingData
      )
      successMessage(this, "Planting Defaults Updated", "top-right")
      this.fetchFields()
    },
  },

  mounted() {
    const {
      previous_year_crop,
      current_year_crop,
      monitor_type,
      starter_fertilizer,
      starter_fertilizer_rate,
      biological_name,
    } = this.plantingData
    this.initialPreviousCrop = previous_year_crop
    this.initialCurrentCrop = current_year_crop
    this.initialMonitorType = monitor_type
    this.initialStarterFert = starter_fertilizer
    this.initialStarterFertRate = starter_fertilizer_rate
    this.initialBiologicalName = biological_name
  },
}
</script>

<style scoped>
.defaults-container {
  background-color: white;
}

.default-title {
  font-size: 16px;
  font-weight: bold;
  color: #515365;
  margin-bottom: 0;
  cursor: pointer;
}

.default-title i {
  margin-left: 5px;
}

.default-input-title {
  font-size: 14px;
}

form {
  width: 100%;
}

.left-side {
  flex: 3;
  padding-right: 25px;
  border-right: 1pt solid #ccc;
}

.right-side {
  flex: 2;
  padding-left: 25px;
}

.update-btn {
  width: 100%;
  margin: 0;
  margin-top: 17px;
}

.fa-plus {
  margin-right: 5px;
}

.fa-times-circle {
  cursor: pointer;
  color: #e60000;
}
</style>
