<template>
  <div v-if="isAssigned" class="row">
    <div class="col">
      <div class="d-flex mb-3">
        <DashboardTitle :title="dashBoardTitle" faClass="fa-globe" />
        <v-btn
          v-if="currentWizard"
          class="ml-auto"
          text
          @click="handleWizardReturn"
        >
          <v-icon>mdi-chevron-left</v-icon>
          Return To Practices</v-btn
        >
        <div v-else-if="type !== 'inset'" class="ml-auto">
          <WizardToolBar />
        </div>
      </div>

      <!-- hide when the wizard is engaged -->
      <div v-if="currentWizard == null && type !== 'inset'">
        <v-stepper v-model="step" non-linear>
          <v-stepper-header>
            <v-btn
              v-if="step != '1'"
              class="m-auto"
              color="blue lighten-1 ml-3"
              @click="moveBackward"
            >
              <v-icon>mdi-arrow-left-circle</v-icon>
              Previous
            </v-btn>
            <v-stepper-step
              step="1"
              editable
              @click="changeRoute('/sustainability')"
            >
              <span>
                Field Selection
                <i
                  class="fa fa-info-circle"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Select Fields  to Enroll and Indicate New Regenerative Practices to Implement"
                />
              </span>
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              step="2"
              editable
              @click="changeRoute('/historical-practices')"
            >
              <span>
                Update Management Practices
                <i
                  class="fa fa-info-circle"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Confirm and Update Recent Management Practice History on Selected Fields"
                />
              </span>
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              step="3"
              editable
              @click="changeRoute('/enroll-fields')"
            >
              <span>
                Enrollment
                <i
                  class="fa fa-info-circle"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Tag, Request, Approve and Confirm
                  Specific Carbon Aggregation Enrollments"
                />
              </span>
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              step="4"
              editable
              @click="changeRoute('/enroll-manager')"
            >
              <span>
                Manage Enrollments
                <i
                  class="fa fa-info-circle"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Review, Access and Manage Previously Created Enrollment Groups"
                />
              </span>
            </v-stepper-step>
            <v-btn
              v-if="step != '4'"
              class="m-auto"
              color="blue lighten-1 mr-3"
              @click="moveForward"
            >
              Next
              <v-icon>mdi-arrow-right-circle</v-icon>
            </v-btn>
          </v-stepper-header>
        </v-stepper>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardTitle from "@/components/dashboard/DashboardTitle"
import WizardToolBar from "@/components/wizard/WizardToolBar"
import { mapState } from "vuex"

export default {
  props: ["step", "currentWizard", "yearSelected", "type"],

  components: {
    DashboardTitle,
    WizardToolBar,
  },
  data() {
    return {
      step_lut: {
        1: {
          forward: "/historical-practices",
          back: null,
          name: "Field and Practice Change Selection",
        },
        2: {
          forward: "/enroll-fields",
          back: "/sustainability",
          name: "Update and Confirm Management Practices",
        },
        3: {
          forward: "/enroll-manager",
          back: "/historical-practices",
          name: "Request New Carbon Enrollment",
        },
        4: {
          forward: null,
          back: "/enroll-fields",
          name: "Manage Existing Enrollments",
        },
      },
    }
  },

  computed: {
    ...mapState({
      isAssigned: state => state.User.user.is_in_assigned_org,
    }),
    dashBoardTitle() {
      if (this.currentWizard && this.yearSelected) {
        return this.currentWizard + " for " + this.yearSelected
      }
      return "Carbon Ready Program: " + this.step_lut[this.step]["name"]
    },
  },
  methods: {
    changeRoute(url) {
      this.$router.push(url)
    },
    handleWizardReturn() {
      this.$emit("handle-wizard-return")
    },
    moveForward() {
      let url = this.step_lut[this.step]["forward"]
      if (url != null) {
        this.$router.push(url)
      }
    },
    moveBackward() {
      let url = this.step_lut[this.step]["back"]
      if (url != null) {
        this.$router.push(url)
      }
    },
  },
}
</script>
<style scoped></style>
