<template>
  <div>
    <DashboardTitle
      title="Account Settings"
      imgSrc="/assets/images/side-nav/settings.svg"
    />

    <form class="settings-form" @submit.prevent="handleSubmit" novalidate>
      <div class="row">
        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
          <span class="default-input-title">First Name</span>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              v-model.trim="user.first_name"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
          <span class="default-input-title">Last Name</span>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              v-model.trim="user.last_name"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
          <span class="default-input-title">Email</span>
          <div class="input-group">
            <input type="text" class="form-control" v-model.trim="user.email" />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
          <span class="default-input-title">Phone</span>
          <div class="input-group">
            <input
              type="number"
              class="form-control"
              v-model.trim="user.mobile_phone_number"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
          <span class="default-input-title">Title</span>
          <div class="input-group">
            <input type="text" class="form-control" v-model="user.job_title" />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 d-flex">
          <div>Arva Data Agreement:</div>
          <div class="data-agreement-status">
            <div v-if="isAgreementSigned"><i class="fa fa-check" /> Signed</div>
            <div v-else>
              <div class="sign" @click="dataAgreementModalOpen = true">
                View/Sign <i class="fa fa-arrow-circle-right" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 d-flex">
          <button
            type="submit"
            class="btn btn-primary"
            :class="{ disabled: !saveButtonEnabled }"
          >
            Save Changes
          </button>
          <button
            type="button"
            class="btn btn-outline-secondary"
            @click="showChangePasswordModal = true"
          >
            Change Password
          </button>
        </div>
      </div>
    </form>

    <DataAgreementModal
      v-if="dataAgreementModalOpen"
      @close-modal="dataAgreementModalOpen = false"
      @accepted-agreement="dataAgreementModalOpen = false"
    />

    <ChangePasswordModal
      v-if="showChangePasswordModal"
      :userId="user.id"
      @close-modal="showChangePasswordModal = false"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex"
import UserAPI from "@/api/UserAPI"
import DashboardTitle from "@/components/dashboard/DashboardTitle"
import ChangePasswordModal from "@/components/modals/ChangePasswordModal"
import DataAgreementModal from "@/components/modals/DataAgreementModal"
import { User } from "@/store/modules"
import { successMessage } from "@/utility"

export default {
  name: "AccountView",
  components: {
    ChangePasswordModal,
    DashboardTitle,
    DataAgreementModal,
  },

  data() {
    return {
      dataAgreementModalOpen: false,
      showChangePasswordModal: false,
      initialFirstName: null,
      initialLastName: null,
      initialEmail: null,
      initialPhone: null,
      initialTitle: null,
    }
  },

  computed: {
    ...mapState({
      user: state => state.User.user,
      isAgreementSigned: state =>
        !!state.User.user.arva_data_agreement_accepted_at,
    }),

    saveButtonEnabled() {
      const {
        first_name,
        last_name,
        email,
        mobile_phone_number,
        job_title,
      } = this.user
      const {
        initialFirstName,
        initialLastName,
        initialEmail,
        initialPhone,
        initialTitle,
      } = this
      return (
        first_name !== initialFirstName ||
        last_name !== initialLastName ||
        email !== initialEmail ||
        mobile_phone_number !== initialPhone ||
        job_title !== initialTitle
      )
    },
  },

  methods: {
    ...mapActions({
      fetchUser: User.Actions.fetchUser,
    }),

    handleSubmit() {
      if (this.saveButtonEnabled) {
        UserAPI.updateUser(this.user)
        successMessage(this, "Account info updated", "top-right")
      }
    },
  },

  mounted() {
    const {
      first_name,
      last_name,
      email,
      mobile_phone_number,
      job_title,
    } = this.user
    this.initialFirstName = first_name
    this.initialLastName = last_name
    this.initialEmail = email
    this.initialPhone = mobile_phone_number
    this.initialTitle = job_title
  },
}
</script>

<style scoped>
.settings-form {
  margin-top: 20px;
}

.data-agreement-status {
  margin-left: 15px;
}

.fa-check {
  color: #28a745;
}

.sign {
  color: #1979f1;
  font-weight: bold;
}

.sign:hover {
  color: #333;
  cursor: pointer;
}
</style>
