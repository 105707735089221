<template>
  <b-sidebar id="evidencing-messaging-sidebar" width="700px" title="Chat" v-model="show" right shadow backdrop>
    <div class="sidebar-body">
      <div ref="containerRef" class="messages-container">
        <MessagingBubble
          v-for="message, idx in getMessages"
          :key="message['date'] + '-' + idx"
          :message="message"
          :isSuperuser="isSuperUser"
        />
      </div>

      <div class="messaging-inputs">
        <v-textarea outlined v-model="newMessage" placeholder="Write your message" label="Message" />
      </div>
    </div>

    <div class="sidebar-footer">
      <v-btn @click="cancel" outlined height="48px">Cancel</v-btn>
      <v-btn @click="send" outlined height="48px" :disabled="!isSaveable">Send</v-btn>
    </div>
  </b-sidebar>
</template>

<script>
import { mapState } from "vuex";
import MessagingBubble from "@/components/evidencing/MessagingBubble";
import {
  REVIEW_MESSAGE,
  REVIEW_ACCEPTED,
  REVIEW_REJECTED
} from "@/constants/evidencing";
export default {
  name: "EvidencingMessagingSidebar",
  emits: ["closeSidebar"],
  components: { MessagingBubble },
  props: {
    uploadDetail: { required: true },
    visible: { type: Boolean, required: true },
  },
  data() {
    return {
      newMessage: '',
      cancelled: true,
      show: false,
      setInitialScrollToBottom: false,
      scrollInterval: null
    }
  },
  computed: {
    ...mapState({
      user: state => state.User.user
    }),
    isSuperUser() {
      if (this.user != null) {
        return this.user['is_superuser'];
      }
      
      return false;
    },
    isSaveable() {
      if (this.newMessage.length > 0) return true
      return false
    },
    getMessages() {
      if (this.uploadDetail == null) return [];
      const chronology = [];

      for (const review of this.uploadDetail['reviews']) {
        let content = '';

        if (review['acceptance'] == REVIEW_ACCEPTED) {
          content = `This upload was approved.`;
        }

        if (review['acceptance'] == REVIEW_REJECTED) {
          content = `This upload was rejected.`;
        }

        chronology.push({
            type: review['acceptance'],
            date: review['created_at'],
            user: review['sender'],
            content
          }
        )
      }

      for (const message of this.uploadDetail['messages']) {
        chronology.push({
          type: REVIEW_MESSAGE,
          date: message['created_at'],
          user: message['sender'],
          content: message['message']
        })
      }

      return chronology.toSorted((a, b) => new Date(a['date']) - new Date(b['date']));
    }
  },
  methods: {
    cancel() {
      this.cancelled = true;
      this.show = false;
    },
    send() {
      this.cancelled = false;
      this.show = false;
    },
    removeSidebar() {
      this.$emit('closeSidebar', this.cancelled, this.uploadDetail['uploadId'], this.newMessage);
      this.newMessage = '';
      this.cancelled = true;
      if (this.scrollInterval != null) {
        clearInterval(this.scrollInterval);
        this.scrollInterval = null;
      }
    },
  },
  watch: {
    visible(curr) {
      this.show = curr;
    },
    show(curr) {
      if (!curr) this.removeSidebar(true);
      else {
        if (this.scrollInterval == null) {
          this.scrollInterval = setInterval(() => {
            if ('containerRef' in this.$refs && this.$refs['containerRef'] != null) {
              if (this.$refs['containerRef'].scrollHeight > 0) {
                this.$refs['containerRef'].scrollTop = this.$refs['containerRef'].scrollHeight;
                this.setInitialScrollToBottom = true;
              }
            }

            if (this.setInitialScrollToBottom) {
              clearInterval(this.scrollInterval);
              this.scrollInterval = null;
            }
          }, 50)
        }
      }
    }
  }
}
</script>

<style scoped>
::v-deep(#evidencing-messaging-sidebar) {
  background: #FFFFFF !important;
  z-index: 99;
}

p {
  color: #000000;
  line-height: 1.5;
  font-size: 16px;
  margin: 24px 0 0;
}

::v-deep #evidencing-messaging-sidebar header {
  padding: 24px 36px;
}

::v-deep #evidencing-messaging-sidebar header .close {
  margin-right: 16px !important;
}

::v-deep #evidencing-messaging-sidebar header strong {
  margin-right: auto;
  text-transform: capitalize;
}

.v-input {
  margin-top: 24px;
}

.sidebar-body {
  padding: 0 36px 36px;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
}

.messages-container {
  background: #F7F8FA;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  height: 450px;
  border-radius: 8px;
  padding: 24px;
  width: 100%;
  overflow-y: scroll;
}
.messaging-inputs {
  width: 100%;
  height: 200px;
}
.sidebar-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 72px;
  background: #FFFFFF;
  border-top: 1px solid rgba(0, 0, 0, 0.25);
  padding: 0 36px;
  display: flex;
  align-items: center;
  z-index: 9;
}

.sidebar-footer > button {
  float: left;
  margin-right: 16px;
}
</style>