<template>
  <div class="col-12">
    <div class="row">
      <div class="col-9">
        <h2>API Management Center</h2>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="row">
          <button
            v-for="item in tabs"
            id="item.id"
            :key="item.id"
            class="col-sm btn tab"
            :class="{ active: currentTab === item.id }"
            @click="gotoTab(item.id)"
          >
            <div class="logo" />
            {{ item.title }}
          </button>
        </div>

        <div style="margin-top: 20px">
          <component
            :is="item.component"
            v-for="item in tabs"
            v-show="currentTab === item.id"
            :key="item.id"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JohnDeereIntegrationTab from "@/views/JohnDeereIntegrationTab";
import ClimateIntegrationTab from "@/views/ClimateIntegrationTab";

const tabs = [
  {
    title: "John Deere",
    id: "john-deere-api",
    component: "JohnDeereIntegrationTab",
  },
  { title: "Climate", id: "climate-api", component: "ClimateIntegrationTab" },
];

export default {
  components: {
    JohnDeereIntegrationTab,
    ClimateIntegrationTab,
  },

  data() {
    return {
      currentTab: tabs[0].id,
    };
  },

  computed: {
    tabs() {
      return tabs;
    },
  },

  watch: {},

  methods: {
    gotoTab(tab) {
      this.currentTab = tab;
    },
  },
};
</script>

<style scoped>
.tab.active {
  background-color: #7774e7;
}

.tab:not(.active) {
  background-color: #ffffff;
}
</style>
