<template>
  <div id="evidencing-map"></div>
</template>

<script>
import L from "leaflet";
import { MAPBOX_TOKEN } from "@/constants/map";
import { Filter } from "@/store/modules";
import { multiPolygon, point } from "@turf/helpers";
import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import { mapGetters, mapState } from "vuex";
import {
  UPLOAD_DATA_ACTION,
  ASSIGN_USERS_ACTION,
  CLIENT_SPECIFICITY,
  FARM_SPECIFICITY,
  FIELD_SPECIFICITY
} from "@/constants";
import { DROPDOWN } from "@/constants";
import editIcon from "@/assets/images/edit-icon.svg";
import validateIcon from "@/assets/images/validate-icon.svg";
import fileIcon from "@/assets/images/file-icon.svg";
import mapIcon from "@/assets/images/map-icon.svg";
import tableIcon from "@/assets/images/table-icon.svg";
import uploadIcon from "@/assets/images/upload-icon.svg";
import router from "@/router";

export default {
  name: "EvidencingMap",
  components: {},
  props: {
    evidencing: { required: true }
  },
  data() {
    return {
      loading: true,
      fieldMarkerMap: {},
      markerList: [],
      map: null,
      zoomControl: null,
      mapIcon,
      fileIcon,
      tableIcon,
      uploadIcon,
      editIcon,
      validateIcon,
      UPLOAD_DATA_ACTION,
      ASSIGN_USERS_ACTION,
      CLIENT_SPECIFICITY,
      FARM_SPECIFICITY,
      FIELD_SPECIFICITY,
      router
    }
  },
  computed: {
    ...mapGetters({
      activeFields: Filter.Getters.getSelectedFields
    }),
    ...mapState({
      fieldBoundaries: state => state.Map.fieldBoundaries
    }),
    iconSvg() {
      return `
        <svg viewBox="0 0 384 512">
          <path d="M384 192c0 87.4-117 243-168.3 307.2c-12.3 15.3-35.1 15.3-47.4 0C117 435 0 279.4 0 192C0 86 86 0 192 0S384 86 384 192z"/>
        </svg>
      `
    }
  },
  methods: {
    highlightTable({ fieldId = null }) {
      // this.showEvidencingTableView = true
      // this.highlightedField = fieldId
    },
    fitMapToBounds() {
      if (this.map && this.fieldBoundaries.length) {
        this.map.fitBounds(L.geoJSON(this.fieldBoundaries).getBounds())
      }
    },
    buildPopupContent({ field }) {
      let evidencingTemplate = ``

      const complete = { detail: "Completed Evidencing", evidencing: [] }
      const incomplete = {
        detail: "Incomplete Evidencing",
        evidencing: [],
      }

      this.fieldMarkerMap[field["id"]]["evidencing"].forEach(
        evidencing => {
          if (evidencing["hasUploads"])
            complete["evidencing"].push(evidencing)
          else incomplete["evidencing"].push(evidencing)
        }
      )
      ;[complete, incomplete].forEach(({ detail, evidencing }) => {
        if (evidencing.length == 0) return

        // we're making a complete marker popup
        evidencingTemplate += `
        <b>${evidencing.length} ${detail}${
          evidencing.length == 1 ? "" : "s"
        }</b><br>
        `

        const evidencingMap = {}
        evidencing.forEach(({ confirmation_type }) => {
          if (confirmation_type in evidencingMap) {
            evidencingMap[confirmation_type] += 1
          } else {
            evidencingMap[confirmation_type] = 1
          }
        })

        evidencingTemplate += "<ul>"
        for (const confType in evidencingMap) {
          if (evidencingMap[confType] > 1) {
            evidencingTemplate += `<li>${confType} (${evidencingMap[confType]})</li>`
          } else {
            evidencingTemplate += `<li>${confType}</li>`
          }
        }
        evidencingTemplate += "</ul>"
      })

      return `
        <b>Field ${field["name"]}</b>
        <br><ul>
          <li>${field["farm"]["name"]}
          <li>${field["acreage"]} AC
        </ul

        <br>${evidencingTemplate}
      `
    },
    calculateFieldMarkers() {
      // reset our field marker map and recalculate what fields should have markers
      this.fieldMarkerMap = {}

      this.evidencing.forEach(e => {
        const fieldsByFarm = []
        const fieldsByClient = []

        if (e["fields"].length == 0) {
          if (e["farm"] != undefined) {
            // we don't have the field ids, get our farm id and crops and infer which field ids should be represented with markers
            // filter the active fields by farm id, then those fields' crops by evidencing-specific year and crop id
            if (this.activeFields) {
              this.activeFields.forEach(({ farm, id, field_crops }) => {
                if (farm["id"] == e["farm"]["id"]) {
                  const relevantCropsByYear = field_crops.filter(
                    ({ year }) => year == e["year"]
                  )
                  if (
                    e["crops"].every(crop =>
                      relevantCropsByYear.find(
                        ({ crop_id }) => crop["id"] == crop_id
                      )
                    )
                  ) {
                    // we found all of the specified crops in this fields field_crop list
                    fieldsByFarm.push({ id })
                  }
                }
              })
            }
          } else if (e["client"] != undefined) {
            // no farm is specified, no fields, but we have a client, so this is a client-wide evidencing
            // set markers to all fields in all farms related to the client
            if (this.activeFarms && this.activeFields) {
              const relevantFarms = this.activeFarms
              .filter(f => f["clientId"] == e["client"]["id"])
              .map(f => f["id"])
              const relevantFields = this.activeFields.filter(f =>
              relevantFarms.includes(f["farm"]["id"])
            )
              fieldsByClient.push(...relevantFields)
            }
          }
        }

        // if we had fields tied to the evidencing, fieldsByFarm will be null
        // thus this array will only be the field-specific selections or the entirety of a farm's fields
        const fieldsNeedingMarkers = [
          ...e["fields"],
          ...fieldsByFarm,
          ...fieldsByClient,
        ]

        for (const { id } of fieldsNeedingMarkers) {
          if (id in this.fieldMarkerMap) {
            this.fieldMarkerMap[id]["completed"] += e["hasUploads"]
              ? 1
              : 0
            this.fieldMarkerMap[id]["incomplete"] += e["hasUploads"]
              ? 0
              : 1
            this.fieldMarkerMap[id]["evidencing"].push(e)
          } else {
            this.fieldMarkerMap[id] = {
              completed: e["hasUploads"] ? 1 : 0,
              incomplete: e["hasUploads"] ? 0 : 1,
              evidencing: [e],
            }
          }
        }
      })
    },
    findFieldFromLatLng(latlng) {
      const { lat, lng } = latlng
      const pt = point([lng, lat])
      return this.fieldBoundaries.find(field => {
        const fieldMPoly = multiPolygon(field.geometry.coordinates)
        return booleanPointInPolygon(pt, fieldMPoly)
      })
    },
    handleMapClick(e) {
      const { latlng } = e
      const clickedField = this.findFieldFromLatLng(latlng)
      if (clickedField) {
        const { field } = clickedField.properties
        this.toggleField({
          id: field.id,
          dropdownType: DROPDOWN.Field,
          preventAutozoom: true,
        })
        this.lastMapAction = "click"
      }
    },
    createMap() {
      if (this.map) return

      // create our base map layer
      const osmLayer = L.tileLayer(
        `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=${MAPBOX_TOKEN}`,
        {
          attribution:
            '© <a href="https://apps.mapbox.com/feedback/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
          minZoom: 0,
          maxZoom: 16,
        }
      )

      // create our map
      this.map = L.map(document.querySelector("#evidencing-map"), {
        layers: [osmLayer],
        zoomControl: false,
        attributionControl: false,
      }).on("click", this.handleMapClick)

      // move zoom controls to bottom left, fit our map to our field bounds and zoom in
      this.zoomControl = L.control.zoom({ position: "bottomleft" })
      this.zoomControl.addTo(this.map)
      this.fitMapToBounds()

      // our map was just created, update it with appropriate markers + field bounds
      this.updateMap()
    },
    updateMap() {
      if (!this.map) return

      this.calculateFieldMarkers()

      // clear our existing markers so we can repopulate the map with new ones
      this.markerList.forEach(marker => this.map.removeLayer(marker))
      this.markerList = []

      // clear our existing bounds layers
      this.map.eachLayer(layer => {
        if (layer.myTag && layer.myTag === "boundsGeoJSON")
          this.map.removeLayer(layer)
      })

      // create our layers from our field geojson
      if (this.fieldBoundaries) {
        // add our bounds layers
        this.fieldBoundaries.forEach(boundary => {
          const {
            centroid: { coordinates },
            properties: { field },
          } = boundary
          const boundsAreActive = this.activeFields.find(
            ({ id }) => field["id"] == id
          )

          const layer = new L.GeoJSON(boundary, {
            style: {
              color: "#FFCC00",
              weight: 1,
              opacity: 0.85,
              fill: boundsAreActive,
            },
            onEachFeature: (_feature, layer) => (layer.myTag = "boundsGeoJSON"),
          })

          this.map.addLayer(layer)

          // add our markers
          const fieldId =
            field["id"] && field["id"] in this.fieldMarkerMap
              ? field["id"]
              : null
          if (!fieldId || !boundsAreActive) return

          const completeCount = this.fieldMarkerMap[field["id"]]["completed"]
          const incompleteCount = this.fieldMarkerMap[field["id"]]["incomplete"]
          const markerHtml = `
            <span>${incompleteCount ? "+" : completeCount}</span>${this.iconSvg}
          `

          const newMark = L.marker([coordinates[1], coordinates[0]], {
            icon: L.divIcon({
              html: markerHtml,
              iconSize: [27, 42],
              className: `evidencing-div-icon ${
                completeCount ? "complete-icon" : ""
              } ${incompleteCount ? "incomplete-icon" : ""}`,
              popupAnchor: [184, 128],
            }),
          })

          newMark
            .bindPopup(this.buildPopupContent({ field }))
            .on("click", () => this.highlightTable({ fieldId }))
            .on("mouseover", function () {
              this.openPopup()
            }) // 'this' is the marker instance
            .on("mouseout", function () {
              this.closePopup()
            })
            .addTo(this.map)

          this.markerList.push(newMark)
        })
      }
    }
  },
  mounted() {
    if (this.evidencing.length > 0 && this.fieldBoundaries.length > 0) {
      if (!this.map) {
        this.createMap()
        return
      }

      this.updateMap()
    }
  },
  watch: {
    fieldBoundaries() {
      // we have boundaries to work with, create our map instance
      if (!this.map) {
        this.createMap()
        return
      }

      // if the map is already created, just update it
      this.updateMap()
    },
    activeFields() {
      this.updateMap()
    },
    evidencing() {
      this.updateMap()
    }
  }
}
</script>

<style scoped>

#evidencing-map {
  height: calc(100vh - 65px);
  position: relative;
  background: #1b1b1d;
  margin: -17px 0;
  z-index: 3;
}
#evidencing-map ::v-deep(.leaflet-popup-tip-container),
#evidencing-map ::v-deep(.leaflet-popup-close-button) {
  display: none;
}
#evidencing-map /deep/ .leaflet-popup .leaflet-popup-content-wrapper {
  background: rgba(4, 20, 35, 0.89);
  border-radius: 0;
  pointer-events: none;
  width: 300px;
}

#evidencing-map
  /deep/
  .leaflet-popup
  .leaflet-popup-content-wrapper
  .leaflet-popup-content {
  color: white;
  font-family: "Roboto";
  font-size: 13px;
  display: flex;
  flex-wrap: wrap;
}

#evidencing-map
  /deep/
  .leaflet-popup
  .leaflet-popup-content-wrapper
  .leaflet-popup-content
  b {
  font-size: 16px;
  margin-bottom: 6px;
  width: 100%;
}

#evidencing-map
  /deep/
  .leaflet-popup
  .leaflet-popup-content-wrapper
  .leaflet-popup-content
  ul {
  margin-bottom: 24px;
}
</style>

<style>
.evidencing-div-icon {
  position: absolute;
}
.evidencing-div-icon > span {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
  font-size: 16px;
  color: white;
  position: relative;
  z-index: 4;
}
.evidencing-div-icon > svg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
}
.evidencing-div-icon:hover > svg {
  fill: #1976d2 !important;
}
.evidencing-div-icon.complete-icon > svg {
  fill: #67ac5b;
}
.evidencing-div-icon.complete-icon.incomplete-icon > svg {
  fill: #357f8f;
}
.evidencing-div-icon.incomplete-icon > svg {
  fill: #054789;
}
</style>