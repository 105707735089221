<template>
  <tr>
    <td><img :src="field.boundary_image" style="width:50px" /></td>
    <td class="nowrap">{{ field.name }}</td>
    <td class="text-right">{{ field.acreage | prettyInteger }}</td>
    <td>{{ crop | crop }}</td>
    <td>{{ variety }}</td>
    <td class="text-right">
      {{ (fieldYield / field.acreage) | prettyInteger }}
    </td>
    <td class="text-right">{{ revenue | intCurrency }}</td>
    <td v-if="hybridPlacementOptimalVariety" class="text-right nowrap">
      {{ hybridPlacementOptimalVariety }}
    </td>
    <td v-if="hybridPlacementOptimalYieldPerAcre" class="text-right">
      {{ hybridPlacementOptimalYieldPerAcre | prettyInteger }}
    </td>
    <td v-if="hybridPlacementRevenueImprovement" class="text-right currency">
      <span v-if="hybridPlacementRevenueImprovement"
        >+{{ hybridPlacementRevenueImprovement | intCurrency }}</span
      >
    </td>
  </tr>
</template>

<script>
export default {
  name: "HybridPlacementRow",
  props: ["field", "year"],

  computed: {
    fieldData() {
      return this.field.historicalROI[this.year]
    },

    crop() {
      return this.fieldData && this.fieldData.crop
    },

    variety() {
      return this.fieldData && this.fieldData.seed_varieties
    },

    fieldYield() {
      return this.fieldData && this.fieldData.field_yield
    },

    revenue() {
      return this.fieldData && this.fieldData.revenue
    },

    hybridPlacementOptimalVariety() {
      return this.fieldData && this.fieldData.hybrid_placement_top_hybrid
    },

    hybridPlacementOptimalYieldPerAcre() {
      return this.fieldData && this.fieldData.hybrid_placement_yield_per_acre
    },

    hybridPlacementRevenueImprovement() {
      return (
        this.fieldData && this.fieldData.hybrid_placement_revenue_improvement
      )
    },
  },
}
</script>

<style scoped>
.nowrap {
  white-space: nowrap;
}

.currency {
  color: #61b100;
  font-weight: bold;
}
</style>
