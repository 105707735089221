<template>
<div>
  <ReportTitlePage v-if="responseData && pageToView === 'trial-title-page'" :responseData="responseData" />
  <TreatmentAppliedReport v-if="responseData && pageToView === 'trial-report-generator'" :responseData="responseData" />
  <EnvironmentAppliedReport v-if="responseData && pageToView === 'trial-env-generator'" :responseData="responseData" :envNum="envNum" />
  <MicroclimateReport v-if="responseData && pageToView === 'trial-microclimate'" :responseData="responseData" />
  <TrialReportRollup v-if="responseData && pageToView === 'trial-rollup-generator'" :responseData="responseData" />
</div>
</template>

<script>
import {
  fetchTrialReportData
} from "@/api/ReportsAPI"
import ReportTitlePage from "../components/reports/ReportTitlePage.vue"
import MicroclimateReport from "../components/reports/MicroclimateReport.vue"
import TrialReportRollup from "../components/reports/TrialReportRollup.vue"
import TreatmentAppliedReport from "../components/reports/TreatmentAppliedReport.vue"
import EnvironmentAppliedReport from '../components/reports/EnvironmentAppliedReport.vue'

export default {
  components: {
    ReportTitlePage,
    TreatmentAppliedReport,
    EnvironmentAppliedReport,
    TrialReportRollup,
    MicroclimateReport
  },
  name: "PDFTrialReportView",
  data() {
    return {
      envNum: null,
      pageToView: null,
      responseData: null,
    }
  },

  async mounted() {
    const {
      name
    } = this.$route
    const {
      reportHash
    } = this.$route.params

    this.pageToView = name

    if (this.pageToView === "trial-env-generator") {
      this.envNum = this.$route.params.pageNum
    }
    await fetchTrialReportData(reportHash).then(response => {
      this.responseData = response.data
    })
  },

  beforeCreate() {
    document.body.className = "pdf-body"
  },
}
</script>
<style scoped>
body {
  background-color: white !important;
}
</style>
