<template>
  <ArvaModal
    name="FieldDataTypeDeletionModal"
    :title="title"
    :wide="true"
    :width="700"
    @close-modal="handleClose"
  >
  <div v-if="!isGeoPkgsEmpty">
    <div class="modal-header">
      <span>Select Layer to change aggregation eligibility:
        <i
          class="fa fa-info-circle"
          data-toggle="tooltip"
          data-placement="top"
          title="Each geopackage file (listed below) is the most raw form of geospatial operational data that has been received by Arva. The data in these individual files are aggregated into ~1/4 acre grid cells, which are then used for further analysis. 
          The green checks indicate that the file will be used in this aggregation. The red x indicates the file will be ignored for aggregation.
          Selecting a green checked file will cause that file to be ignored during the next aggregation. And vice-versa."
        />
      </span>

      <div class="select-btn">
        <button v-if="checkedGpkgsCount > 0" class="btn btn-primary" @click="selectedGpkgsList">Save</button>
      </div>
    </div>

    <div class="modal-container">
      <div v-for="(geo_packages, org_node) in geoPkgsByDataType" :key="org_node">
        <div class="row">
          <div class="col-12 details">
            <div class="dataTypeRow">
              <input
                type="checkbox"
                @click="clicked($event)"
                :id="org_node"
                :value="geo_packages"
                class="checkboxText"
                ref="org_nodes"
              />
              <span class="org_node_text_color"> {{org_node}} </span>
              <div v-for="(geo_package, index) in geo_packages" :key="index">
                <div class="dataTypeRow_2">
                  <div v-if="geo_package.skipped">
                    <input
                      type="checkbox"
                      @click="geoPackageChecked($event)"
                      :value="geo_package.s3_key"
                      :id="org_node"
                      class="checkboxText"
                      ref="geo_packages"
                    />
                    <i
                      :class="[
                        'check',
                        'fa',
                        'fa-times',
                        'confident-check-red',
                      ]"
                    />
                    <span> {{geo_package.name}} </span>
                  </div>
                  <div v-else>
                    <input
                      type="checkbox"
                      @click="geoPackageChecked($event)"
                      :value="geo_package.s3_key"
                      :id="org_node"
                      class="checkboxText"
                      ref="geo_packages"
                    />
                    <i
                        :class="[
                          'check',
                          'fa',
                          'fa-check',
                          'confident-check-green',
                        ]"
                      />
                    <span> {{geo_package.name}} </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-else>
    <span> Found 0 Files for this Data Layer. </span>
  </div>
  </ArvaModal>
</template>

<script>
import _ from "lodash"
import ArvaModal from "./ArvaModal"

export default {
  name: "FieldDataTypeDeletionModal",

  props: {
    geoPkgsByDataType: { type: Object },
    selectFieldId: { type: Number },
    selectedDataType: { type: String },
  },

  components: { ArvaModal },

  data() {
    return {
      title: "Activate/Deactivate Data Layer Files",
      selectedGeoPackages: [],
    }
  },

  computed: {
    checkedGpkgsCount() {
      return this.selectedGeoPackages.length
    },

    isGeoPkgsEmpty() {
      return _.isEmpty(this.geoPkgsByDataType)
    },
  },

  methods: {
    handleClose() {
      this.$emit("close-modal")
    },

    clicked: function(e) {
      const num_checkboxes = this.$refs.geo_packages.length
      for (var index = 0; index < num_checkboxes; index++) {
        const checkbox = this.$refs.geo_packages[index]
        if (checkbox.id === e.target.id) {
          checkbox.checked = e.target.checked

          if (checkbox.checked) {
            this.checked(checkbox.value)
          } else {
            this.unchecked(checkbox.value)
          }
        }
      }
    },

    geoPackageChecked: function(e) {
      if (e.target.checked) {
        this.checked(e.target.value)
      } else {
        this.unchecked(e.target.value)
      }

      // Check/Uncheck the Org Node checkbox
      var numOfSelectedGpkgsByOrgNode = this.numOfSelectedGpkgsByOrgNode(e.target.id)
      if (this.geoPkgsByDataType[e.target.id].length === numOfSelectedGpkgsByOrgNode) {
        this.clickOrgNode(e.target.id, true)
      }
      else {
        this.clickOrgNode(e.target.id, false)
      }
    },

    selectedGpkgsList() {
      this.$emit("selectedGpkgsFromModal", this.selectedGeoPackages)
      this.uncheckAll()
    },

    checked(geoPackage) {
      if (!this.selectedGeoPackages.includes(geoPackage)) this.selectedGeoPackages.push(geoPackage)
    },

    unchecked(geoPackage) {
      const index = this.selectedGeoPackages.indexOf(geoPackage)
      if (index > -1) this.selectedGeoPackages.splice(index, 1)
    },

    clickOrgNode(orgNode, checked) {
      const num_checkboxes = this.$refs.org_nodes.length
      for (var index = 0; index < num_checkboxes; index++) {
        const checkbox = this.$refs.org_nodes[index]
        if (checkbox.id === orgNode) {
          checkbox.checked = checked
          return;
        }
      }
    },

    uncheckAll() {
      // Uncheck all the checked org_nodes
      const num_org_node_checkboxes = this.$refs.org_nodes.length
      for (var org_node_index = 0; org_node_index < num_org_node_checkboxes; org_node_index++) {
        const checkbox = this.$refs.org_nodes[org_node_index]
        if (checkbox.checked) {
          checkbox.checked = false
        }
      }

      // Uncheck all the checked geo_packages
      const num_gpkg_checkboxes = this.$refs.geo_packages.length
      for (var gpkg_index = 0; gpkg_index < num_gpkg_checkboxes; gpkg_index++) {
        const checkbox = this.$refs.geo_packages[gpkg_index]
        if (checkbox.checked) {
          checkbox.checked = false
        }
      }

      this.selectedGeoPackages = []
    },

    numOfSelectedGpkgsByOrgNode(orgNode) {
      var numOfSelectedGpkgsByOrgNode = 0;

      for (var index = 0; index < this.geoPkgsByDataType[orgNode].length; index++) {
        if (this.selectedGeoPackages.includes(this.geoPkgsByDataType[orgNode][index].s3_key)) {
          numOfSelectedGpkgsByOrgNode++
        }
      }

      return numOfSelectedGpkgsByOrgNode
    },
  },

  mounted() {
    if (this.tooltip) {
      const { $ } = window
      $(function() {
        $('[data-toggle="tooltip"]').tooltip()
      })
    }
  },
}
</script>

<style scoped>
.dataTypeRow {
  padding-left: 5%;
  font-weight: bold;
}

.org_node_text_color {
  color:green;
}

.dataTypeRow_2 {
  padding-left: 7%;
  font-weight: bold;
}

.checkboxText {
  width: 5%;
}

.select-btn {
  margin-left: 5%;
}

.modal-container {
  overflow-x: auto;
  overflow-y: auto;
  max-height: 500px;
}

.confident-check-green {
  color: green;
}

.confident-check-red {
  color: red;
}

.fa-info-circle {
  margin-left: 5px;
  font-size: 16px;
}

</style>
