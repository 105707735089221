<template>
  <v-stepper v-model="el" non-linear elevation="0">
    <v-stepper-items>
      <v-stepper-content step="1">
        <v-card class="my-auto mx-auto">
          <v-card-text class="text-center">
            <b class="text-h5">Are you employing a nitrogen reduction plan?</b>
            <div class="pt-2">
              <v-btn class="mr-4" outlined rounded text color="red accent-4">
                No <v-icon class="pl-1">mdi-close-circle-outline</v-icon>
              </v-btn>
              <v-btn
                class="ml-4"
                outlined
                rounded
                text
                color="green accent-4"
                @click="el = 2"
              >
                Yes <v-icon>mdi-check-circle</v-icon>
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-stepper-content>

      <v-stepper-content step="2">
        <v-card class="my-auto mx-auto">
          <v-card-text class="text-center">
            <!-- <b class="text-h5"
              >What kind of alternative to synthetic nitrogen are you using?</b
            >
            <v-row class="text-center">
              <v-col class="d-flex justify-space-around" md="6" offset-md="3">
                <v-radio-group v-model="nitrogenAltType" row>
                  <v-radio
                    class="radio-text"
                    label="Manure"
                    value="Manure"
                  ></v-radio>
                  <v-radio
                    class="radio-text"
                    label="Other Alternatives"
                    value="OtherAlt"
                  ></v-radio>
                  <v-radio
                    class="radio-text"
                    label="Inhibitors"
                    value="Inhibitor"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row> -->
            <b class="text-h5"
              >How many nitrogen alternative applications did you have this
              year?</b
            >
            <v-row class="pt-3">
              <v-col class="d-flex justify-space-between" md="3" offset-md="4">
                <v-btn
                  rounded
                  outlined
                  text
                  @click="handleNumApplications(1)"
                  :input-value="numApplications == 1"
                  >One</v-btn
                >
                <v-btn
                  rounded
                  outlined
                  text
                  @click="handleNumApplications(2)"
                  :input-value="numApplications == 2"
                  >Two</v-btn
                >
                <v-btn
                  rounded
                  outlined
                  text
                  @click="handleNumApplications(3)"
                  :input-value="numApplications == 3"
                  >Three</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-stepper-content>

      <v-stepper-content step="3">
        <v-card class="my-auto mx-auto">
          <v-card-text>
            <v-row>
              <v-btn @click="handleSaveReduction" class="ml-auto">Save and Continue</v-btn>
            </v-row>
            <div class="text-center">
              <b class="text-h5"
                >Describe these nitrogen alternative practices:</b
              >
            </div>
            <v-row>
              <v-col md="8" offset-md="2">
                <v-card
                  v-for="app in applicationData"
                  :key="app.idx"
                  class="mt-3"
                  elevation="6"
                >
                  <v-card-title>
                    Application #{{ applicationData.indexOf(app) + 1 }}
                    <v-icon
                      v-if="!app.showAllInfo"
                      @click="app.showAllInfo = true"
                      >mdi-chevron-right</v-icon
                    >
                    <v-icon v-else @click="app.showAllInfo = false"
                      >mdi-chevron-down</v-icon
                    >
                    <v-icon
                      class="ml-auto"
                      :color="
                        isAppComplete(app) ? 'green accent-4' : 'red darken-1'
                      "
                    >
                      {{
                        isAppComplete(app)
                          ? "mdi-check-circle-outline"
                          : "mdi-alert-circle-outline"
                      }}
                    </v-icon>
                  </v-card-title>
                  <v-card-text v-if="app.showAllInfo">
                    <v-row>
                      <v-col md="9">
                        <b>On which crop was this applied?</b>
                        <div class="d-flex">
                          <div class="input-holder">
                            <v-select
                              dense
                              outlined
                              label="Crop"
                              :items="cropChoices"
                              v-model="app.crop_id"
                            >
                            </v-select>
                          </div>
                          <div class="input-holder">
                            <v-menu
                              v-model="app.showCal"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  outlined
                                  dense
                                  v-model="app.date"
                                  label="Date"
                                  prepend-inner-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="app.date"
                                @input="app.showCal = false"
                              ></v-date-picker>
                            </v-menu>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col md="9">
                        <b>What was your application type?</b>
                        <div class="d-flex justify-space-between pt-2">
                          <v-btn
                            rounded
                            outlined
                            text
                            @click="app.type = 'manure'"
                            :input-value="app.type == 'manure'"
                            >Manure <v-icon>mdi-information</v-icon></v-btn
                          >
                          <v-btn
                            rounded
                            outlined
                            text
                            @click="app.type = 'inhibitor'"
                            :input-value="app.type == 'inhibitor'"
                            >Nitrogen Inhibitor
                            <v-icon>mdi-information</v-icon></v-btn
                          >
                          <v-btn
                            rounded
                            outlined
                            text
                            @click="app.type = 'other'"
                            :input-value="app.type == 'other'"
                            >Other Alternative
                            <v-icon>mdi-information</v-icon></v-btn
                          >
                        </div>
                      </v-col>
                      <!-- <v-col md="4"
                        ><v-menu
                          v-model="app.showCal"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              outlined
                              dense
                              v-model="app.date"
                              label="Date"
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="app.date"
                            @input="app.showCal = false"
                          ></v-date-picker> </v-menu
                      ></v-col> -->
                    </v-row>
                    <v-row>
                      <v-col md="6">
                        <b class="text-h7">
                          Describe your application method and product:
                        </b></v-col
                      >
                    </v-row>
                    <v-row>
                      <v-col md="4">
                        <v-select
                          dense
                          outlined
                          label="Method"
                          :items="methodChoices"
                          v-model="app.method"
                        >
                        </v-select>
                      </v-col>
                      <v-col md="4">
                        <v-text-field
                          dense
                          outlined
                          label="Product"
                          v-model="app.product"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col md="6">
                        <b class="text-h7">
                          What was your application rate?
                        </b></v-col
                      >
                    </v-row>
                    <v-row>
                      <v-col md="4">
                        <v-text-field
                          dense
                          outlined
                          label="Rate"
                          v-model="app.amount"
                          type="number"
                        ></v-text-field>
                      </v-col>
                      <v-col md="4">
                        <v-select
                          dense
                          outlined
                          label="Rate Units"
                          :items="rateChoices"
                          v-model="app.unit"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>
<script>
export default {
  name: "NitrogenReductionWizard",
  props: {
    carbonSettingsByField: { type: Array },
    year: { type: String },
  },
  data() {
    return {
      el: 1,
      // data values
      nitrogenAltType: null,
      numApplications: null,
      applicationData: [],
      // temp crop choices
      cropChoices: ["corn", "soybeans"],
      methodChoices: [
        "broadcast",
        "incorporated",
        "injected",
        "sprayed",
        "manure",
      ],
      rateChoices: ["lbs / acre", "gal / acre"],
    }
  },
  methods: {
    handleNumApplications(num) {
      this.numApplications = num
      for (let i = 0; i < this.numApplications; i++) {
        this.applicationData.push({
          ammonium_pct: null,
          crop_id: null,
          type: null,
          method: null,
          product: null,
          amount: null,
          unit: null,
          date: null,
          showCal: false,
          showAllInfo: i == 0 ? true : false,
        })
      }

      this.el = 3
    },
    isAppComplete(app) {
      return (
        app.crop_id &&
        app.type &&
        app.method &&
        app.product &&
        app.amount &&
        app.unit &&
        app.date
      )
    },
    handleSaveReduction() {
      this.$emit("submit-reduction", this.applicationData)
    }
  },
}
</script>
<style scoped>
/* .radio-text {
  font-size: 32px !important;
} */
.input-holder {
  width: 270px;
}

.v-label {
  font-size: 32px !important;
}
</style>
