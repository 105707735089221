<template>
  <div>
    <Permissions package="inset-enrollment" :superUserOnly="false" />
    <div v-if="showInsetEnrollment">
      <CarbonFlowSteppers :step="3" :type="'inset'" />
      <v-row class="mt-4 mb-2 row-margin-correct">
        <div>
          <v-btn
            class="mx-1"
            text
            v-for="y in yearList"
            :key="y.idx"
            @click="handleSeasonSelection(y)"
            :color="yearInFocus == y ? 'green' : ''"
            >{{ y }}</v-btn
          >
        </div>
      </v-row>

      <v-row class="mt-4 row-margin-correct">
        <div>
          <v-btn
            class="mr-3"
            text
            v-for="crop in cropList"
            :key="crop.crop_id"
            :color="
              JSON.stringify(cropSelect) == JSON.stringify(crop.crop_id)
                ? 'green'
                : ''
            "
            @click="handleCropFilterButton(crop.crop_id)"
          >
            <span>{{ crop.crop_name }}</span>
            <span>({{ crop.crop_count }})</span>
          </v-btn>
        </div>
        <div class="ml-auto">
          <v-btn
            v-if="fieldSupplySummarySelected.length > 0 && !isAllSelected"
            class="grey white--text mr-5"
            medium
            @click="selectAll"
          >
            Select All
          </v-btn>

          <v-btn
            v-if="fieldSupplySummarySelected.length > 0"
            class="red white--text mr-5"
            medium
            @click="resetCheckboxes"
          >
            Clear All
          </v-btn>

          <v-btn
            class="white--text mr-5"
            color="green"
            medium
            :disabled="fieldSupplySummarySelected.length === 0"
            @click="handleAcceptClick"
            >Enroll</v-btn
          >
        </div>
      </v-row>

      <v-row class="pt-3">
        <v-col cols="12">
          <v-card>
            <v-card-text>
              <div v-if="showLoading" class="text-center py-3">
                <v-progress-circular
                  :size="70"
                  :width="8"
                  color="green"
                  indeterminate
                ></v-progress-circular>
                <div>
                  <h3>{{ loadingText }}</h3>
                  <h4>
                    Please be patient when loading many fields - this may take a
                    couple minutes.
                  </h4>
                </div>
              </div>

              <div
                v-else-if="
                  filterFieldSupplySummary &&
                  filterFieldSupplySummary.length > 0
                "
              >
                <v-simple-table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Field Name</th>
                      <th>Client Name</th>
                      <th>Crop</th>
                      <th>Practices</th>
                      <th>DAS</th>
                      <th># Matches</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="record in filterFieldSupplySummary"
                      :key="record.id"
                    >
                      <td>
                        <v-simple-checkbox
                          v-model="record.selected"
                          :disabled="
                            record.disabled || record.status === 'available'
                          "
                          @click="selectCheckbox(record)"
                          name="selected"
                          ref="fssSelectCheckbox"
                        ></v-simple-checkbox>
                      </td>
                      <td>{{ record.fieldcrop.field_name }}</td>
                      <td>{{ record.client }}</td>
                      <td>{{ record.fieldcrop.crop_name }}</td>
                      <td>
                        <ul v-for="practice in record.practices">
                          <li>{{ practice.split(":")[1] }}</li>
                        </ul>
                      </td>
                      <td>{{ record.das }}</td>
                      <td>
                        <v-btn
                          v-if="Object.keys(record.recommendation).length > 0"
                          color="red"
                          small
                          @click="handleFSSDetailsModal(record)"
                          >{{ record.num_matches }}</v-btn
                        ><span v-else>{{ record.num_matches }}</span>
                      </td>

                      <td>{{ record.status }}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </div>

              <div v-else class="text-center py-3">
                <h1>No Data Found For {{ yearInFocus }}</h1>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <InsetEnrollmentModal
        v-if="openInsetEnrollmentModal"
        :selectedClient="selectedClient"
        :user="user"
        :org="org"
        @close-modal="openInsetEnrollmentModal = false"
        @accept="handleAcceptClick"
      />

      <EnrollmentThankYouModal
        v-if="downloadURL"
        :downloadUrl="downloadURL"
        @close-modal="downloadURL = null"
      />

      <ReviewFSSDetails
        v-if="showFSSDetailsModal"
        :items="items"
        title="Field Supply Details"
        @close-modal="showFSSDetailsModal = false"
      />
    </div>
  </div>
</template>

<script>
import _ from "lodash"
import { mapActions, mapState, mapGetters } from "vuex"
import { Organization, Filter } from "@/store/modules"
import CarbonFlowSteppers from "@/views/CarbonFlowSteppers"
import Permissions from "@/components/permissions/Permissions"
import CarbonReadyAPI from "@/api/CarbonReadyAPI"
import InsetEnrollmentModal from "@/components/modals/InsetEnrollmentModal.vue"
import EnrollmentThankYouModal from "@/components/modals/EnrollmentThankYouModal.vue"
import ReviewFSSDetails from "@/components/insets/modals/ReviewFSSDetails"

export default {
  name: "EnrollFieldsView",
  components: {
    CarbonFlowSteppers,
    Permissions,
    InsetEnrollmentModal,
    EnrollmentThankYouModal,
    ReviewFSSDetails,
  },

  data() {
    return {
      fieldSupplySummary: [],
      filterFieldSupplySummary: [],
      cropList: [],
      cropSelect: null,
      showLoading: false,
      loadingText: "Getting Field Supply Summary",
      fieldSupplySummarySelected: [],
      openInsetEnrollmentModal: false,
      selectedClient: null,
      downloadURL: null,
      showFSSDetailsModal: false,
      items: [],
      statusOrder: {
        open: 1,
        available: 2,
        locked: 3,
        matched: 4,
      },
    }
  },

  computed: {
    ...mapState({
      years: state => state.Organization.organization.years,
      yearInFocus: state => state.Organization.year,
      showSustainability: state =>
        state.Organization.organization.showSustainability,
      showInsetEnrollment: state =>
        state.Organization.organization.showInsetEnrollment,
      user: state => state.User.user,
      org: state => state.Organization.organization,
    }),

    ...mapGetters({
      clients: Filter.Getters.getClients,
    }),

    yearList() {
      return this.years.filter(o => !isNaN(o))
    },

    isAllSelected() {
      return (
        this.fieldSupplySummarySelected.length ===
        this.filterFieldSupplySummary.filter(
          o => o.status === "open" && this.selectedClient === o.client
        ).length
      )
    },
  },

  methods: {
    ...mapActions({
      updateYear: Organization.Actions.updateYear,
    }),

    async fetchFieldSupplySummary() {
      const payload = {
        year: this.yearInFocus,
      }
      this.showLoading = true
      await CarbonReadyAPI.getFieldSupplySummary(payload).then(resp => {
        this.fieldSupplySummary = resp.data.results.sort((a, b) => {
          // Sort by status first, then by das
          if (this.statusOrder[a.status] !== this.statusOrder[b.status]) {
            return this.statusOrder[a.status] - this.statusOrder[b.status]
          }
          return b.das - a.das
        })

        this.filterFieldSupplySummary = this.fieldSupplySummary
        this.cropList = resp.data.crop_counts

        if (this.clients.length > 0) {
          this.updateFSSByClients()
        }
        this.showLoading = false
      })
    },

    updateCropListByClients(client_ids) {
      const filteredCrops = this.fieldSupplySummary
        .filter(o => client_ids.includes(o.client_id))
        .map(field => ({
          crop_id: field.fieldcrop.crop_id,
          crop_name: field.fieldcrop.crop_name,
        }))

      const cropCounts = _.countBy(filteredCrops, "crop_id")
      this.cropList = _.uniqBy(filteredCrops, "crop_id").map(crop => ({
        ...crop,
        crop_count: cropCounts[crop.crop_id],
      }))
      const isCropSelectedInList = this.cropList.some(
        crop => crop.crop_id === this.cropSelect
      )
      if (!this.cropSelect || !isCropSelectedInList) {
        this.cropSelect =
          this.cropList.length > 0 ? this.cropList[0].crop_id : null
      } else {
        this.handleCropFilterButton(this.cropSelect)
      }
    },

    updateFSSByClients() {
      const client_ids = this.clients.filter(o => o.selected).map(o => o.id)
      this.updateCropListByClients(client_ids)
      this.filterFieldSupplySummary = this.fieldSupplySummary.filter(
        o =>
          client_ids.includes(o.client_id) &&
          (!this.cropSelect || o.fieldcrop.crop_id === this.cropSelect)
      )
    },

    async handleSeasonSelection(newYear) {
      await this.updateYear(newYear)
    },

    handleCropFilterButton(crop) {
      const client_ids = this.clients.filter(o => o.selected).map(o => o.id)
      this.cropSelect = crop
      this.filterFieldSupplySummary = this.fieldSupplySummary.filter(
        o => o.fieldcrop.crop_id == crop && client_ids.includes(o.client_id)
      )
      this.resetCheckboxes()
    },

    selectAll() {
      this.fieldSupplySummarySelected = []
      this.filterFieldSupplySummary
        .filter(o => o.status === "open" && this.selectedClient === o.client)
        .forEach(o => {
          o.selected = true
          this.fieldSupplySummarySelected.push(o)
        })
    },

    selectCheckbox(record) {
      if (record.disabled) {
        return
      }

      // Get selected fss
      this.fieldSupplySummarySelected = this.filterFieldSupplySummary.filter(
        o => o.selected
      )

      // Update selected client
      if (record.selected) {
        if (!this.selectedClient) {
          this.selectedClient = record.client
        }
      } else if (this.fieldSupplySummarySelected.length === 0) {
        this.selectedClient = null
      }

      // Update disabled flag for each fss
      this.filterFieldSupplySummary.forEach(field => {
        var isOpen = field.status === "open"

        if (!this.selectedClient) {
          field.disabled = !isOpen
        } else {
          var hasSameClient = field.client === this.selectedClient
          field.disabled = !(hasSameClient && isOpen)
        }
      })
    },

    resetCheckboxes() {
      this.fieldSupplySummarySelected = []
      this.filterFieldSupplySummary.forEach(o => {
        o.selected = false
        o.disabled = o.status !== "open"
      })
      this.selectedClient = null
    },

    resetState() {
      this.cropSelect = null
      this.fieldSupplySummary = []
      this.filterFieldSupplySummary = []
      this.cropList = []
      this.resetCheckboxes()
    },

    async handleAcceptClick() {
      const payload = {
        selected_client: this.selectedClient,
        "fss-selected": this.fieldSupplySummarySelected,
        crop: this.cropSelect,
        year: this.yearInFocus,
      }

      await CarbonReadyAPI.enrollFieldsInset(payload).then(() => {
        this.resetCheckboxes()
        this.fetchFieldSupplySummary()
      })

      this.openInsetEnrollmentModal = false
    },
    handleFSSDetailsModal(fss) {
      this.items = []

      CarbonReadyAPI.getFSSRecommendations({
        fss_id: fss.id,
      }).then(response => {
        let dataObj = response.data[0]
        for (let key in dataObj) {
          let tempAction = dataObj[key]
          if (Array.isArray(tempAction)) {
            tempAction = tempAction.join(", ")
          }
          this.items.push({
            category: key,
            action: tempAction,
          })
        }

        this.showFSSDetailsModal = true
      })
    },
  },

  async mounted() {
    await this.fetchFieldSupplySummary()
  },

  watch: {
    yearInFocus() {
      this.resetState()
      this.fetchFieldSupplySummary()
    },
    clients() {
      this.updateFSSByClients()
    },
  },
}
</script>

<style scoped></style>
