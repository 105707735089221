import API from "./API"

const fetchBoundaries = year => API.get(`/map-bounds/`, { params: { year } })
const fetchVoxels = (year, orgNodeId) =>
  API.get(`/map-voxel/`, { params: { year, orgNodeId } })
const fetchMVTURLs = (year, orgNodeId) =>
  API.get(`/map-mvt-url/`, { params: { year, orgNodeId } })
const fetchMicroclimates = () => API.get(`microclimates`)

const fetchEligibleLeafletLayers = payload =>
  API.post(`/leaflet_layer_list/`, payload)
const fetchLeafletLayersGeoJSON = payload =>
  API.post(`leaflet_layer_geojson/`, payload) //this should be opst

export default {
  fetchBoundaries,
  fetchVoxels,
  fetchMicroclimates,
  fetchEligibleLeafletLayers,
  fetchLeafletLayersGeoJSON,
  fetchMVTURLs,
}
