<template>
  <ArvaModal
    name="editTransactionModal"
    title="Edit Data Sharing Request"
    :wide="true"
    :width="1000"
    @close-modal="handleCloseModal"
  >
    <!-- Transaction Details Section -->
    <div
      class="highlighted-section"
      :class="{
        'requested-color': isRequested,
        'approved-color': isApproved,
        'expired-color': isExpired,
      }"
    >
      <div class="row ml-3">
        <div class="col">
          <span class="section-title">Sharing Transaction Details</span>
        </div>
      </div>
      <div class="row ml-3">
        <div class="col">
          <span class="default-input-title">Transaction ID:</span>
          <span class="default-input-value">{{ transaction.id }}</span>
        </div>
        <div class="col">
          <span class="default-input-title">Status: </span>
          <span class="default-input-value">{{
            transaction.sharing_status
          }}</span>
        </div>
      </div>
      <div class="row ml-3">
        <div class="col">
          <span class="default-input-title">Corporation To Share:</span>
          <span class="default-input-value">{{
            transaction.received_corp_name
          }}</span>
        </div>
        <div class="col">
          <span class="default-input-title">Organization To Share:</span>
          <span class="default-input-value">{{
            transaction.received_org_name
          }}</span>
        </div>
      </div>
    </div>

    <!-- Selected Package(s) and Year(s) Section -->
    <div class="row justify-content-between ml-5 mt-8">
      <div class="col-6">
        <span
          class="default-input-title"
          :class="{ 'invalid-title': !isPackageValid }"
          >Select Package(s) To Share</span
        >
        <v-select
          v-model="selectedPackages"
          :items="
            corporation.package.length > 0 ? corporation.package : ['Base']
          "
          multiple
        ></v-select>
      </div>
      <div class="col-6">
        <span
          class="default-input-title"
          :class="{ 'invalid-title': !isYearValid }"
          >Select Year(s) To Share</span
        >
        <v-select
          v-model="selectedYears"
          :items="convertyearOptionsToNumber"
          multiple
        ></v-select>
      </div>
    </div>

    <!-- Expiration Date and Permission Section -->
    <div class="row justify-content-between ml-5 mt-8">
      <div class="col-3">
        <span
          class="default-input-title"
          :class="{ 'invalid-title': !isExpirationDateValid }"
          >Expiration Date</span
        >
        <v-menu offset-y min-width="auto">
          <template #activator="{ on }">
            <v-text-field
              v-model="selectedExpirationDate"
              readonly
              v-on="on"
              :clearable="true"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="selectedExpirationDate"
            :min="minDate"
            @input="showDate = false"
            scrollable
          ></v-date-picker>
        </v-menu>
      </div>
      <div class="col-6">
        <span
          class="default-input-title"
          :class="{ 'invalid-title': !isPermissionValid }"
          >Select Permission</span
        >
        <v-select
          v-model.trim="selectedPermission"
          :items="permissionOptions"
          solo
          :clearable="true"
        ></v-select>
      </div>
    </div>

    <!-- Save Footer Section -->
    <div class="row ml-3">
      <ArvaModalSaveFooter
        confirmText="Save"
        cancelText="Cancel"
        @on-cancel="handleCloseModal"
        @on-confirm="confirmChanges"
      />
    </div>
  </ArvaModal>
</template>

<script>
import ArvaModal from "@/components/modals/ArvaModal"
import ArvaModalSaveFooter from "@/components/modals/ArvaModalSaveFooter"

export default {
  name: "EditTransactionModal",
  props: ["transaction", "corporation", "yearOptions"],
  components: {
    ArvaModal,
    ArvaModalSaveFooter,
  },

  data() {
    return {
      panel: [0, 1],
      disabled: false,
      permissionOptions: ["Read-Only", "Read/Write"],
      selectedPermission: this.transaction.sharing_permission,
      selectedPackages: this.transaction.sharing_attributes.attributes,
      selectedExpirationDate: this.transaction.expiration_date,
      selectedYears: this.transaction.sharing_attributes.years,
      showDate: false,
      minDate: new Date().toISOString().substr(0, 10), // Format: YYYY-MM-DD

      isPackageValid: true,
      isYearValid: true,
      isExpirationDateValid: true,
      isPermissionValid: true,
    }
  },

  computed: {
    isRequested() {
      return this.transaction.sharing_status === "Requested"
    },
    isApproved() {
      return this.transaction.sharing_status === "Approved"
    },
    isExpired() {
      return this.transaction.sharing_status === "Expired"
    },
    convertyearOptionsToNumber() {
      return this.yearOptions.map(year => parseInt(year))
    },
  },

  watch: {
    selectedPackages() {
      this.isPackageValid = this.selectedPackages.length > 0
    },
    selectedYears() {
      this.isYearValid = this.selectedYears.length > 0
    },
    selectedExpirationDate() {
      this.isExpirationDateValid = !!this.selectedExpirationDate
    },
    selectedPermission() {
      this.isPermissionValid = !!this.selectedPermission
    },
  },

  methods: {
    handleCloseModal() {
      this.$emit("close-modal")
    },

    validateInputs() {
      this.isPackageValid = this.selectedPackages.length > 0
      this.isYearValid = this.selectedYears.length > 0
      this.isExpirationDateValid = !!this.selectedExpirationDate
      this.isPermissionValid = !!this.selectedPermission

      return (
        !this.isPackageValid ||
        !this.isYearValid ||
        !this.isExpirationDateValid ||
        !this.isPermissionValid
      )
    },

    confirmChanges() {
      const hasError = this.validateInputs()
      if (!hasError) {
        const payload = {
          transaction_id: this.transaction.id,
          packages: this.selectedPackages,
          years: this.selectedYears,
          expiration_date: this.selectedExpirationDate,
          permission: this.selectedPermission,
        }
        this.$emit("save-changes", payload, this.transaction.sharing_status)
      }
    },
  },
}
</script>

<style scoped>
.highlighted-section {
  background-color: #83c48d;
  padding: 10px;
  margin-bottom: 20px;
}

.requested-color {
  background-color: #a2ac1a;
}

.approved-color {
  background-color: #14cd3f;
}

.expired-color {
  background-color: #e42121;
}
.section-title {
  font-weight: bold;
}
.default-input-title {
  font-weight: bold;
  margin-top: 8px;
  display: block;
}

.invalid-title {
  color: red;
}
</style>
