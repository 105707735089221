<template>
  <div>
    <Permissions package="crop-planner" />
    <div v-if="showCropPlanner">
      <DashboardTitle
        title="Arva Crop Designer"
        faClass="fa-dot-circle-o"
        :beta="true"
        tooltip="Arva's AI Crop Designer points out where to plant Hybrids (by AGT zone) for best crop performance based on your previous years of uploaded training data and from local environment (weather, growing days, slope, Soil Fertility etc.). Use this page for planning your next year's crops for all fields selected in view."
      />

      <div class="row">
        <div class="col">
          <h3>Summary by Crop</h3>
        </div>
        <div class="col">
          <div
            v-if="!isReadOnly"
            type="button"
            class="btn btn-primary btn-sm float-right"
            @click="handlePdfDownload('summary')"
          >
            Download <i class="fa fa-arrow-circle-o-down" />
          </div>
          <div
            type="button"
            class="btn btn-default btn-sm float-right"
            @click="seedRateModalOpen = true"
          >
            Seeding Rates
            <i
              class="fa fa-info-circle"
              data-toggle="tooltip"
              data-placement="top"
              title="Suggested per-crop seeding rates are used to estimate next-year seed products and costs. Select here to edit default Seeding Rates."
            />
          </div>
          <SeedingRatesModal
            v-if="seedRateModalOpen"
            :seedingRates="seedingRates"
            @close-template-modal="closeSeedRateModal"
          />
        </div>
      </div>

      <v-simple-table>
        <thead>
          <tr>
            <th />
            <th>Crop</th>
            <th class="text-right">Total Acres</th>
            <th class="text-right">Total Estimated Yield</th>
            <th class="text-right">Total Target Yield</th>
            <th class="hybrid">
              Selected Hybrids
              <i
                class="fa fa-info-circle"
                data-toggle="tooltip"
                data-placement="top"
                title="Arva AI Analytics estimates the best per crop hybrid seed summary based on soil and environmental specifics for local growing areas. Details of this estimation per field may be viewed 'By Field' below."
              />
            </th>
            <th class="text-right">Acres/Seed Type</th>
            <th class="text-right">Total Bags</th>
            <th class="nRec text-right">N Tons</th>
            <th class="nRec text-right">N Lbs/Acre</th>
            <th class="pRec text-right">P2O5 Tons</th>
            <th class="pRec text-right">P2O5 Lbs/Acre</th>
            <th class="kRec text-right">K2O5 Tons</th>
            <th class="kRec text-right">K2O5 Lbs/Acre</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in cropDesignerSummary" :key="row.crop">
            <td><img class="crop-icon" :src="cropIconUrls[row.crop]" /></td>
            <td>{{ row.crop | crop }}</td>
            <td class="text-right">{{ row.acreage | prettyInteger }}</td>
            <td class="text-right">{{ row.predictedYield | prettyInteger }}</td>
            <td class="text-right">{{ row.targetYield | prettyInteger }}</td>
            <td class="hybrid">
              <div
                v-for="hybrid in row.cropDesignerHybridsSummary"
                :key="hybrid.name"
              >
                <div class="nowrap hybrid-name">{{ hybrid.name }}</div>
              </div>
            </td>
            <td class="text-right">
              <div
                v-for="hybrid in row.cropDesignerHybridsSummary"
                :key="hybrid.name"
              >
                <div class="nowrap hybrid-name">
                  {{ hybrid.acreage | prettyInteger }}
                  ac
                </div>
              </div>
            </td>
            <td class="text-right">
              <div
                v-for="hybrid in row.cropDesignerHybridsSummary"
                :key="hybrid.name"
              >
                <div class="hybrid-name">
                  {{ hybrid.bags | prettyInteger }}
                </div>
              </div>
            </td>
            <td class="nRec text-right font-med">
              {{ row.nTons | prettyInteger }}
            </td>
            <td class="nRec text-right font-med">
              {{ row.nPerAcre | prettyInteger }}
            </td>
            <td class="pRec text-right font-med">
              {{ row.pTons | prettyInteger }}
            </td>
            <td class="pRec text-right font-med">
              {{ row.pPerAcre | prettyInteger }}
            </td>
            <td class="kRec text-right font-med">
              {{ row.kTons | prettyInteger }}
            </td>
            <td class="kRec text-right font-med">
              {{ row.kPerAcre | prettyInteger }}
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <div class="row">
        <div class="col">
          <h3>By Field</h3>
        </div>
        <div class="col">
          <div
            v-if="!isReadOnly"
            type="button"
            class="btn btn-primary btn-sm float-right mt-2"
            @click="handlePdfDownload('fields')"
          >
            Download <i class="fa fa-arrow-circle-o-down" />
          </div>
        </div>
      </div>

      <v-simple-table fixed-header height="calc(100vh - 470px)">
        <thead>
          <tr>
            <th></th>
            <th>Field</th>
            <th class="text-right">Acreage</th>
            <th>Last Year Crop</th>
            <th>Next Year Crop</th>
            <th class="text-right">Estimated Yield / Ac.</th>
            <th>
              Confidence
              <i
                class="fa fa-info-circle"
                data-toggle="tooltip"
                data-placement="top"
                title="Confidence of Arva's estimated yield for this field. Fields with no measured yield data will have low confidence predictions. Fields with measured yield data will have higher confidence predictions."
              />
            </th>
            <th>
              Target Yield
              <i
                class="fa fa-info-circle"
                data-toggle="tooltip"
                data-placement="top"
                title="Adjust target yield up or down to update fertility recommendations."
              />
            </th>
            <th class="hybrid">
              <div>
                Your Top Hybrids
                <i
                  class="fa fa-info-circle"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Select preferred seed products for PDF download report."
                />
              </div>
              <div class="selected-hybrid nowrap d-flex align-items-center">
                <div />
                Selected Hybrid
              </div>
            </th>

            <th class="nRec text-right">N Lbs</th>
            <th class="nRec text-right">N Lbs/Acre</th>
            <th class="pRec text-right">P2O5 Lbs</th>
            <th class="pRec text-right">P2O5 Lbs/Acre</th>
            <th class="kRec text-right">K2O5 Lbs</th>
            <th class="kRec text-right">K2O5 Lbs/Acre</th>
            <th>
              Calculation
              <i
                class="fa fa-info-circle"
                data-toggle="tooltip"
                data-placement="top"
                title="Formula used for calculating fertilizer recommendations. The 'Soil/CEC' formula is used when there is sufficient soil data. If not, the 'Crop Removal' formula is used based on the field's last crop type. Contact Arva support if you'd like to use a custom fertilizer formula when using the Arva Crop Designer."
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <CropDesignerRow
            v-for="field in fieldRows"
            :key="field.fieldId"
            :field="field"
            :nextCropSelection="nextCropSelections[field.id]"
            :targetYields="targetYields[field.id]"
          />
        </tbody>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex"

import { fetchCropDesignerHash } from "@/api/CropDesignerReportAPI"
import CropDesignerRow from "@/components/cropDesigner/CropDesignerRow"
import DashboardTitle from "@/components/dashboard/DashboardTitle"
import SeedingRatesModal from "@/components/modals/SeedingRatesModal"
import { CROP_DISPLAY_NAME, CROP_ID_TO_ICON_URL } from "@/constants"
import { Dashboard, Filter } from "@/store/modules"
import { fertScaleFactor } from "@/utility"
import Permissions from "@/components/permissions/Permissions"

export default {
  name: "CropDesigner",

  components: {
    CropDesignerRow,
    DashboardTitle,
    SeedingRatesModal,
    Permissions,
  },

  data() {
    return {
      cropIconUrls: CROP_ID_TO_ICON_URL,
      seedRateModalOpen: false,
    }
  },

  computed: {
    ...mapGetters({
      activeFields: Filter.Getters.getSelectedFields,
      cropDesignerSummary: Dashboard.Getters.getCropDesignerSummary,
    }),

    ...mapState({
      showCropPlanner: state => state.Organization.organization.showCropPlanner,
      isReadOnly: state => state.User.user.permission === "Read-Only",
      agtPerformance: state => state.Dashboard.agtPerformance,
      nextCropSelections: state => state.Dashboard.nextCropSelections,
      cropDesignerHybrids: state => state.Dashboard.cropDesignerHybrids,
      organization: state => state.Organization.organization,
      seedingRates: state => state.Dashboard.seedingRates,
      targetYields: state => state.Dashboard.targetYields,
    }),

    fieldRows() {
      return this.activeFields.filter(field => field.crop_designer)
    },
  },

  methods: {
    closeSeedRateModal() {
      this.seedRateModalOpen = false
    },

    pdfData(pdfType) {
      const completeRows = this.fieldRows.filter(
        field => this.nextCropSelections[field.id] != null
      )

      const data = completeRows.reduce((result, field) => {
        const nextCropObj =
          field.crop_designer.crops[this.nextCropSelections[field.id]]
        if (nextCropObj !== undefined) {
          const hybrids = this.hybrids(
            this.nextCropSelections[field.id],
            nextCropObj.agts
          )

          const nextCropId = parseInt(this.nextCropSelections[field.id])
          nextCropObj.next_crop = CROP_DISPLAY_NAME[nextCropId]
          const selectedHybrid = this.cropDesignerHybrids[field.id]
          const fieldInfo = {
            id: field.id,
            name: field.name,
            acreage: field.acreage,
            hybrids: hybrids,
            selectedHybrid: selectedHybrid,
          }
          const returnObj = Object.assign(nextCropObj, fieldInfo)

          const predictedYieldPerAcre =
            nextCropObj.predicted_yield / field.acreage

          let targetYield = this.targetYields[field.id][nextCropId]
          targetYield =
            typeof targetYield !== "undefined"
              ? targetYield
              : predictedYieldPerAcre

          const fertRecScaleFactor = fertScaleFactor(
            nextCropId,
            predictedYieldPerAcre,
            targetYield
          )

          let obj = { ...returnObj }
          obj.n_acre *= fertRecScaleFactor
          obj.n_rec = obj.n_rec * fertRecScaleFactor
          obj.p_acre *= fertRecScaleFactor
          obj.p_rec *= fertRecScaleFactor
          obj.k_acre *= fertRecScaleFactor
          obj.k_rec *= fertRecScaleFactor
          obj.targetYield = targetYield

          result.push(obj)
        }
        return result
      }, [])

      const numFarms = [...new Set(completeRows.map(field => field.farm.name))]
        .length

      const summaryClone = this.cropDesignerSummary.map(i => ({ ...i }))
      const summary = summaryClone.map(cropSummary => {
        cropSummary.crop = CROP_DISPLAY_NAME[parseInt(cropSummary.crop)]
        return cropSummary
      })

      const pdfPost = {
        pdf_data: data,
        num_farms: numFarms,
        organization: this.organization.corporation.name,
        summary: summary,
        pdf_type: pdfType,
      }

      return pdfPost
    },

    handlePdfDownload(pdfType) {
      const pdfPost = this.pdfData(pdfType)
      fetchCropDesignerHash(pdfPost).then(response => {
        const url = response.data
        window.open(url, "_blank")
      })
    },

    hybrids(nextCrop, fieldAgts) {
      const hybrids = []
      const agts = fieldAgts || []
      for (const agt of agts) {
        const agtPerformance = this.agtPerformance || []
        const topPerformingHybrid = agtPerformance.find(
          row => row.agt === agt.agt && row.crop_id === parseInt(nextCrop)
        )
        if (topPerformingHybrid) {
          const { variety, yieldPerAcre } = topPerformingHybrid
          hybrids.push({ agt: agt.agt, variety, yieldPerAcre })
        }
      }
      return hybrids
    },
  },

  mounted() {
    const { $ } = window
    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
    })
  },
}
</script>

<style scoped>
h3 {
  font-size: 16px;
  font-weight: 500;
  margin-top: 15px;
}

.nRec {
  background: rgba(91, 137, 165, 1) !important;
  color: white !important;
}

.pRec {
  background: rgba(107, 153, 206, 1) !important;
  color: white !important;
}

.kRec {
  background: rgba(227, 126, 87, 1) !important;
  color: white !important;
}

.crop-icon {
  width: 30px;
  height: 30px;
}

.hybrids {
  margin: 4px 0px;
}

.hybrid-name {
  font-weight: 500;
  font-size: 12px;
}

thead > tr > th {
  white-space: nowrap;
}

.nowrap {
  white-space: nowrap;
}

.font-med {
  font-weight: 500;
}

.selected-hybrid {
  font-size: 10px;
}

.selected-hybrid > div {
  background: #7574e0;
  height: 7px;
  width: 7px;
  border-radius: 7px;
  margin-right: 5px;
}
</style>
