<template>
  <div>
    <Permissions v-if="!showImagery && !showRxZones && !canAccess" />
    <div v-else-if="showImagery || showRxZones || canAccess" class="col-12">
      <div class="card">
        <div class="card-body">
          <div
            class="zero-state"
            v-if="
              fieldsWithRaster.length == 0 &&
              !zonesView &&
              intialResponseComplete
            "
          >
            <div>
              You have not ordered any satellite imagery. To order, visit
              <router-link to="/order-imagery">Order imagery</router-link>
            </div>
          </div>

          <!-- SOIL SAMPLING VIEW -->
          <v-simple-table
            height="480px"
            fixed-header
            v-else-if="zonesView && tabView && sortFields.length > 0"
          >
            <thead>
              <tr>
                <th v-if="zonesView && tabView">
                  <input
                    type="checkbox"
                    @click="handleSelectAllCheck"
                    v-model="selectAllCheck"
                  />
                </th>
                <th v-if="!tabView" />
                <th class="hover" @click="handleSortFieldName">
                  Field
                  <i
                    class="fa hover mr-1"
                    :class="sortIcon(sortByFieldName)"
                    @click="handleSortFieldName"
                  />
                  <i
                    class="fa fa-info-circle"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Field names are displayed with Hyperlink to open a field level information editor allowing specific field settings for this field."
                  />
                </th>
                <th v-if="soilSamplingView" @click="handleSortCrop">
                  {{ year }} Crop
                  <i
                    class="fa hover"
                    :class="sortIcon(sortByCrop)"
                    @click="handleSortCrop"
                  />
                </th>
                <th class="hover" @click="handleSortAcreage">
                  {{ tabView ? "Acres" : "Acreage" }}
                  <i
                    class="fa hover"
                    :class="sortIcon(sortByAcreage)"
                    @click="handleSortAcreage"
                  />
                </th>
                <th class="hover" @click="handleSortFarm">
                  Farm
                  <i
                    class="fa hover"
                    :class="sortIcon(sortByFarm)"
                    @click="handleSortFarm"
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              <FieldImageryListRow
                v-for="field in sortFields"
                :key="field.id"
                :field="field"
                :soilSamplingView="soilSamplingView"
                :tabView="tabView"
                :zonesView="zonesView"
                :year="year"
                @field-clicked="handleListRowClick"
              />
            </tbody>
          </v-simple-table>
          <div
            v-else-if="fieldsWithRaster.length < 1 && !intialResponseComplete"
            class="d-flex justify-content-center top"
          >
            <div class="text-bold mb-0">Fetching Field Data</div>
            <div class="spinner-border ml-4" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>

          <table class="table table-lg table-hover" v-else>
            <thead>
              <tr>
                <th v-if="zonesView && tabView">
                  <input
                    type="checkbox"
                    @click="handleSelectAllCheck"
                    v-model="selectAllCheck"
                  />
                </th>
                <th v-if="!tabView" />
                <th class="hover" @click="handleSortFieldName">
                  Field
                  <i
                    class="fa hover mr-1"
                    :class="sortIcon(sortByFieldName)"
                    @click="handleSortFieldName"
                  />
                  <i
                    class="fa fa-info-circle"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Field names are displayed with Hyperlink to open a field level information editor allowing specific field settings for this field."
                  />
                </th>
                <th class="hover" @click="handleSortAcreage">
                  {{ tabView ? "Acres" : "Acreage" }}
                  <i
                    class="fa hover"
                    :class="sortIcon(sortByAcreage)"
                    @click="handleSortAcreage"
                  />
                </th>
                <th class="hover" @click="handleSortFarm">
                  Farm
                  <i
                    class="fa hover"
                    :class="sortIcon(sortByFarm)"
                    @click="handleSortFarm"
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              <FieldImageryListRow
                v-for="field in sortFields"
                :key="field.id"
                :field="field"
                :soilSamplingView="soilSamplingView"
                :tabView="tabView"
                :zonesView="zonesView"
              />
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex"
import _ from "lodash"

import FieldImageryListRow from "@/components/fieldImageryMap/FieldImageryListRow"
import SatelliteAPI from "@/api/SatelliteAPI"
import { Defaults, Fields, Filter, Map } from "@/store/modules"
import Permissions from "@/components/permissions/Permissions"

export default {
  name: "FieldImageryListView",
  props: {
    canAccess: { type: Boolean, default: false },
    soilSamplingView: { type: Boolean, default: false },
    tabView: { type: Boolean, default: false },
    zonesView: { type: Boolean, default: false },
  },
  components: {
    FieldImageryListRow,
    Permissions,
  },

  data() {
    return {
      fieldsWithRaster: [],
      selectAllCheck: false,
      sortByAcreage: null,
      sortByCrop: null,
      sortByFarm: null,
      sortByFieldName: null,
      sortBySettings: null,
      intialResponseComplete: false,
      fieldSettingsTitle:
        "Field Level information template that is applied to each field. Clicking on template name opens the template for display of the field level information. Note: Templates may be edited and saved by selecting <em>Field Level Information</em> from the left menu then <em>+ add New Settings</em> Template, then in this page <em>Apply Field Settings selection</em> to apply template to one or more fields.",
    }
  },

  computed: {
    ...mapGetters({
      allFields: Fields.Getters.getFields,
      fieldsWithSelectedBool: Filter.Getters.getFields,
      selectedFields: Filter.Getters.getSelectedFields,
      defaultsList: Defaults.Getters.getDefaultsList,
      includeFieldLevelInfo: Map.Getters.getIncludeDefaults,
    }),

    ...mapState({
      organization: state => state.Organization.organization,
      defaultsTemplates: state => state.DefaultsTemplates.list,
      years: state => state.Organization.organization.years,
      year: state => state.Organization.year,
      showImagery: state => state.Organization.organization.showImagery,
      showRxZones: state => state.Organization.organization.showRxZones,
    }),

    sortFields() {
      let sortValues = {
        acreage: this.sortByAcreage,
        "farm.name": this.sortByFarm,
        "defaults.name": this.sortBySettings,
        name: this.sortByFieldName,
      }
      let sortColumns = []
      let sortDirections = []
      for (const column in sortValues) {
        if (sortValues[column] !== null) {
          sortColumns.push(column)
          sortDirections.push(sortValues[column])
        }
      }

      // Ticket 836: Locus asked to hide non-selected fields and add sort by crop for selected year
      if (this.soilSamplingView) {
        let sortValues = {
          acreage: this.sortByAcreage,
          "farm.name": this.sortByFarm,
          "defaults.name": this.sortBySettings,
          name: this.sortByFieldName,
          cropForYear: this.sortByCrop,
        }
        let sortColumns = []
        let sortDirections = []
        for (const column in sortValues) {
          if (sortValues[column] !== null) {
            sortColumns.push(column)
            sortDirections.push(sortValues[column])
          }
        }

        this.selectedFields.forEach(sf => {
          let cropForYear = sf.field_crops.find(fc => fc.year == this.year)

          if (typeof cropForYear !== "undefined") {
            sf["cropForYear"] = cropForYear.crop_id
          } else {
            sf["cropForYear"] = 0
          }
        })
        const res = _.orderBy(this.selectedFields, sortColumns, sortDirections)
        return res
      }

      // Imagery Analytics Views (filtered by fields with available imagery)
      if (!this.zonesView) {
        const fieldsFiltered = this.selectedFields.filter(f =>
          this.fieldsWithRaster.includes(f.id)
        )
        const res = _.orderBy(fieldsFiltered, sortColumns, sortDirections)
        return res
      }

      // AGT and Yield Zones view
      // Ticket 434: sort the fields so that the selected fields are at the top
      if (this.zonesView && this.tabView) {
        var selectedFieldArray = this.getSelectedFieldsInArray()

        var resSortedBySelectedFields = _.orderBy(
          this.selectedFields,
          sortColumns,
          sortDirections
        )
        var allFieldsSorted = _.orderBy(
          this.allFields,
          sortColumns,
          sortDirections
        )

        for (const field of allFieldsSorted) {
          if (!selectedFieldArray.includes(field.id)) {
            resSortedBySelectedFields.push(field)
          }
        }

        return resSortedBySelectedFields
      }

      return _.orderBy(this.selectedFields, sortColumns, sortDirections)
    },
  },

  methods: {
    ...mapMutations({
      clearAll: Filter.Mutations.clearAll,
      selectAll: Filter.Mutations.selectAll,
    }),

    handleSelectAllCheck() {
      if (!this.selectAllCheck) this.selectAll()
      else this.clearAll()
    },

    handleSortAcreage() {
      if (!this.sortByAcreage) this.sortByAcreage = "asc"
      else if (this.sortByAcreage === "asc") this.sortByAcreage = "desc"
      else if (this.sortByAcreage === "desc") this.sortByAcreage = null
    },

    handleSortCrop() {
      if (!this.sortByCrop) this.sortByCrop = "asc"
      else if (this.sortByCrop === "asc") this.sortByCrop = "desc"
      else if (this.sortByCrop === "desc") this.sortByCrop = null
    },

    handleSortFarm() {
      if (!this.sortByFarm) this.sortByFarm = "asc"
      else if (this.sortByFarm === "asc") this.sortByFarm = "desc"
      else if (this.sortByFarm === "desc") this.sortByFarm = null
    },

    handleSortFieldName() {
      if (!this.sortByFieldName) this.sortByFieldName = "asc"
      else if (this.sortByFieldName === "asc") this.sortByFieldName = "desc"
      else if (this.sortByFieldName === "desc") this.sortByFieldName = null
    },

    sortIcon(sortValue) {
      if (sortValue === "asc") return "fa-sort-up"
      else if (sortValue === "desc") return "fa-sort-down"
      return "fa-sort"
    },

    getSelectedFieldsInArray() {
      var res = []
      for (const field of this.selectedFields) {
        res.push(field.id)
      }
      return res
    },

    handleListRowClick(fieldId) {
      this.$emit("row-clicked", fieldId)
    },
  },

  watch: {
    organization() {
      SatelliteAPI.fetchFieldsWithRaster({
        org_node_id: this.organization.id,
      }).then(response => {
        this.fieldsWithRaster = response.data.fields_list
      })
    },
  },

  async mounted() {
    const { $ } = window
    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
    })

    if (!this.zonesView) {
      await SatelliteAPI.fetchFieldsWithRaster({
        org_node_id: this.organization.id,
      }).then(response => {
        this.fieldsWithRaster = response.data.fields_list
        this.intialResponseComplete = true
      })
    }
  },
}
</script>

<style>
#field-select-controls {
  width: 100%;
}
</style>

<style scoped>
.toggle-checkbox {
  font-size: 12px;
}

.toggle-checkbox > span {
  margin-right: 10px;
  font-weight: 500;
}

.hover {
  cursor: pointer;
}

.zero-state {
  text-align: center;
  padding: 60px;
}

.zero-state > div {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
}

.zonelist {
  height: 400px;
  /* height: calc(100vh - 640px); */
}
</style>
