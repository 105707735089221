<template>
  <div class="form-inline">
    <div class="form-group">
      <label class="mr-1" for="fieldSearch"
        ><span class="ei ei-search form-control-feedback"></span
      ></label>
      <input
        @input="handleInput"
        :value="searchString"
        placeholder="Search by Name"
        id="fieldSearch"
        type="text"
        class="form-control input-sm"
        autocomplete="off"
        ref="searchInput"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchBar",
  props: ["searchString"],

  methods: {
    handleInput() {
      this.$emit("searching", this.$refs.searchInput.value)
    },
  },

  mounted() {
    this.$refs.searchInput.focus()
  },
}
</script>
