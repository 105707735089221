<template>
  <ArvaModal
    name="summarizeProgramAllocations"
    :title="title"
    :wide="true"
    @close-modal="handleCloseModal"
  >
    <v-card class="pa-5">
      <div v-if="items.length > 0">
        <v-data-table :items="items" :headers="headers"></v-data-table>
      </div>
      <div v-else>
        <p>No programs found</p>
      </div>
      <!-- also have a input for order name-->
    </v-card>
  </ArvaModal>
</template>

<script>
import ArvaModal from "@/components/modals/ArvaModal"
import ArvaModalSaveFooter from "@/components/modals/ArvaModalSaveFooter"

export default {
  name: "ReviewAllocationByProgram",
  props: ["items", "title"],
  components: { ArvaModal, ArvaModalSaveFooter },

  data() {
    return {
      headers: [
        { text: "Program ID", value: "program_id" },
        { text: "Program Name", value: "program_name" },
        { text: "Acreage", value: "acreage" },
        { text: "Priority Score", value: "program_priority" },
      ],
    }
  },

  methods: {
    handleCloseModal() {
      this.$emit("close-modal")
    },
  },
}
</script>
