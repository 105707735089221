import API from "./API"

const resource = "/upload-mapping/"

const get = () => API.get(resource)

const put = (uploadMappingId, payload) =>
  API.put(`${resource}${uploadMappingId}/`, payload)

export default { get, put }
