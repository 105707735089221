<template>
  <div class="evidencing-multi-image-viewer">
    <v-icon v-if="images.length > 1" @click="previousImgClick" class="interact-btn btn-left">mdi-chevron-left</v-icon>
    <v-icon v-if="images.length > 1" @click="nextImgClick" class="interact-btn btn-right">mdi-chevron-right</v-icon>
    <v-icon @click="emitDownload" class="download-btn">mdi-tray-arrow-down</v-icon>
    <div v-if="getImg != null">
      <img v-if="getImg['isImage'] && getImg['fileExt'] != 'pdf'" :src="getImg['url']" />
      
      <embed v-else-if="getImg['isImage'] && getImg['fileExt'] == 'pdf'" :src="getImg['url']" />
      
      <div class="fallback-img-viewer" v-else-if="getImg['fileExt'] in FALLBACK_MIME_MAP">
        {{ getFallbackMimeType(getImg['fileExt']) }}
      </div>

      <div class="fallback-img-viewer" v-else>ETC</div>
    </div>
  </div>
</template>

<script>
const FALLBACK_MIME_MAP = {
  csv: 'csv',
  xlsx: 'excel',
  xlsm: 'excel',
  xlsb: 'excel',
  xltx: 'excel',
  xltm: 'excel',
  xls: 'excel',
  xlt: 'excel',
  xlam: 'excel',
  xlw: 'excel',
  xlr: 'excel',
  ods: 'excel',
  xml: 'xml',
  txt: 'txt',
  rtf: 'txt',
  html: 'html',
  gif: 'gif',
  bmp: 'bmp',
  shp: 'shp',
  dbf: 'dbf',
  shx: 'shx',
  prj: 'prj',
  zip: 'zip',
}

export default {
  name: "EvidencingMultiImageViewer",
  emits: ['download'],
  props: { 
    uploadId: { required: true },
    images: { required: true }
  },
  data() {
    return {
      curImgIdx: 0,
      FALLBACK_MIME_MAP
    }
  },
  computed: {
    getImg() {
      if (this.images.length > 0) return this.images[this.curImgIdx]
      return null
    }
  },
  methods: {
    getFallbackMimeType(type) {
      if (type in FALLBACK_MIME_MAP) return FALLBACK_MIME_MAP[type];
      else return 'other';
    },
    emitDownload() {
      this.$emit('download')
    },
    previousImgClick() {
      if (this.curImgIdx == 0) this.curImgIdx = this.images.length-1;
      else this.curImgIdx -= 1;
    },
    nextImgClick() {
      if (this.curImgIdx == this.images.length-1) this.curImgIdx = 0;
      else this.curImgIdx += 1;
    }
  }
}
</script>

<style scoped>
.evidencing-multi-image-viewer {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.25);
}
.evidencing-multi-image-viewer .download-btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000000;
  color: #FFFFFF;
  opacity: 0;
  cursor: pointer;
}
.evidencing-multi-image-viewer .interact-btn {
  position: absolute;
  top: calc(50% - 12px);
  font-size: 24px;
  color: #000000;
  border-radius: 50%;
  border: 1px solid #000000;
  background: rgba(255,255,255,0.25);
  transition: background 0.25s;
  cursor: pointer;
}
.evidencing-multi-image-viewer:hover .download-btn {
  opacity: 1;
}
.evidencing-multi-image-viewer:hover .interact-btn {
  background: rgba(255,255,255,1.0);
}
.evidencing-multi-image-viewer .btn-left {
  left: 12px;
}
.evidencing-multi-image-viewer .btn-right {
  right: 12px;
}
.evidencing-multi-image-viewer img,
.evidencing-multi-image-viewer embed {
  width: 100%;
  height: 250px;
  object-fit: cover;
  pointer-events: none;
}
.evidencing-multi-image-viewer .fallback-img-viewer {
  width: 100%;
  height: 250px;
  display: flex;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  color: #000000;
  font-size: 72px;
  line-height: 1;
  text-transform: uppercase;
  border: 1px solid rgba(0, 0, 0, 0.25);
}
</style>
