<template>
  <div>
    <Permissions package="base" />
    <div v-if="showBase" class="map-wrapper" oncontextmenu="return false">
      <VueMapBox
        :accessToken="mapBoxToken"
        :settings="mapBoxSettings"
        @created="mapCreated"
        :class="['fill-wrapper']"
        ref="map"
      />

      <VueDeckGL
        :settings="deckSettings"
        :layers="deckLayers"
        @created="deckCreated"
        :map="map"
        ref="deck"
      />

      <Tooltip v-if="Boolean(this.map) && Boolean(this.deck)" />

      <div class="debug px-3 py-2">
        <a href="javascript:void(0)" @click="toggleStyle">
          <i
            :class="[{ 'fa fa-globe': darkMode, 'fa fa-map': !darkMode }]"
            aria-hidden="true"
          />
        </a>
        <a
          class="three-d-view"
          :class="{ 'three-d-active': pitchMode }"
          @click="togglePitch"
          >3D</a
        >
        <hr />
        <div v-if="isFetching" class="pull-left mt-1 mr-4">
          <h5 class="text-bold mb-0">Fetching Data</h5>
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>

      <div class="voxel-debug">{{ hoveredVoxelId }}</div>

      <BoundingBoxSelector
        @selectBoundaries="selectBoundaries"
        :class="['fill-wrapper']"
      />

      <LayerController />

      <CropForceWelcomeModal
        v-if="
          displayWelcomeModalFlag && openCropForceWelcomeModal && initialLoading
        "
        :user="user"
        @close-modal="openCropForceWelcomeModal = false"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex"

import BoundingBoxSelector from "@/components/map/BoundingBoxSelector"
import LayerController from "@/components/map/LayerController"
import { BoundaryLayer } from "@/components/map/layers"
import Tooltip from "@/components/map/Tooltip"
import VueDeckGL from "@/components/map/VueDeckGL"
import VueMapBox from "@/components/map/VueMapBox"
import CropForceWelcomeModal from "@/components/modals/CropForceWelcomeModal"
import Permissions from "@/components/permissions/Permissions"

import { DROPDOWN } from "@/constants"
import {
  DECKGL_SETTINGS,
  MAP_STYLES,
  MAPBOX_TOKEN,
  MAPBOX_SETTINGS,
} from "@/constants/map"
import { Map, Filter } from "@/store/modules"

export default {
  name: "MapView",
  components: {
    VueDeckGL,
    VueMapBox,
    Tooltip,
    BoundingBoxSelector,
    LayerController,
    CropForceWelcomeModal,
    Permissions,
  },

  data() {
    return {
      mapBoxToken: MAPBOX_TOKEN,
      mapBoxSettings: MAPBOX_SETTINGS,
      deckSettings: DECKGL_SETTINGS,
      map: {},
      deck: false,
      hovered: false,
      activeStyle: "satellite",
      openCropForceWelcomeModal: true,
      showControl: false,
    }
  },

  computed: {
    ...mapGetters({
      selectedFields: Filter.Getters.getSelectedFields,
    }),

    ...mapState({
      showBase: state => state.Organization.organization.showBase,
      hoverData: state => state.Map.hoverData,
      deckLayers: state => state.Map.layers,
      isFetching: state => state.Map.isFetching,
      pitchMode: state => state.Map.pitchMode,
      user: state => state.User.user,
      displayWelcomeModalFlag: state =>
        !!state.User.user.display_cropforce_welcome_modal,
    }),

    initialLoading() {
      return localStorage.getItem("initialLoading") === "yes"
    },

    darkMode() {
      return this.activeStyle === "dark"
    },

    hoveredVoxelId() {
      return this.hoverData ? this.hoverData.voxelId : ""
    },
  },

  methods: {
    ...mapMutations({
      toggleFilterItem: Filter.Mutations.toggleItem,
      autoZoom: Map.Mutations.autoZoom,
      updateMap: Map.Mutations.updateMap,
      disableCropIcons: Map.Mutations.disableCropIcons,
      setDeck: Map.Mutations.setDeck,
      togglePitchMode: Map.Mutations.togglePitchMode,
    }),

    mapCreated(map) {
      this.map = map
      this.showControl = true
    },

    deckCreated(deck) {
      this.deck = deck
      setTimeout(() => {
        this.updateMap()
        this.autoZoom()
      })
    },

    toggleStyle() {
      this.activeStyle = this.activeStyle === "dark" ? "satellite" : "dark"
      this.map.setStyle(MAP_STYLES[this.activeStyle])
    },

    togglePitch() {
      if (!this.pitchMode) this.disableCropIcons()
      this.togglePitchMode()
    },

    selectBoundaries(boundingBox, isDeselectMode) {
      const filterToBoundaryLayers = index =>
        index.layer instanceof BoundaryLayer

      const allSelectedFields = this.deck.pickObjects({
        x: boundingBox.x,
        y: boundingBox.y,
        width: boundingBox.width,
        height: boundingBox.height,
      })

      const selectedBoundaryFields = allSelectedFields.filter(
        filterToBoundaryLayers
      )

      const newBoundaries = []
      selectedBoundaryFields.forEach(index => {
        if (selectedBoundaryFields.length !== 0) {
          newBoundaries.push(index.object.properties.field.id)
        }
      })

      const selectedFieldIds = this.selectedFields.map(({ id }) => id)
      newBoundaries.forEach(fieldId => {
        const isFieldOn = selectedFieldIds.includes(fieldId)
        if ((isDeselectMode && isFieldOn) || (!isDeselectMode && !isFieldOn)) {
          this.toggleFilterItem({
            id: fieldId,
            dropdownType: DROPDOWN.Field,
            preventAutozoom: true,
          })
        }
      })
    },
  },

  created() {
    setTimeout(() => this.setDeck(this.$refs.deck))
  },
  updated() {
    setTimeout(() => this.setDeck(this.$refs.deck))
  },
}
</script>

<style>
#deckgl-overlay {
  width: 100% !important;
  height: 100% !important;
}
</style>

<style scoped>
.map-wrapper {
  height: calc(100vh - 65px);
  position: relative;
  background: #1b1b1d;
  margin: -17px -32px -17px -29px;
}

.fill-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.debug {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(24, 24, 24, 0.5);
  color: rgb(200, 186, 175);
  border-radius: 2px;
  width: 15em;
}

.debug h5 {
  color: rgb(200, 186, 175);
}

.voxel-debug {
  position: absolute;
  top: 92px;
  left: 10px;
  font-size: 10px;
  color: white;
}

.fa-globe {
  font-size: 2em;
  color: rgb(200, 186, 175);
}

.fa-map {
  font-size: 2em;
  color: rgb(200, 186, 175);
}

.three-d-view {
  font-size: 24px;
  margin-left: 16px;
  font-weight: bold;
  opacity: 0.6;
  cursor: pointer;
}

.three-d-view:hover {
  opacity: 1;
}

.three-d-active {
  opacity: 1;
}
</style>
