<template>
  <div class="row">
    <form @submit.prevent="handleSubmit(true)" novalidate>
      <div class="card">
        <h2
          class="card-heading default-title"
          @click="showInputs = !showInputs"
        >
          Season Fertilizer Application Plan
          <i
            class="fa fa-info-circle info-margin"
            data-toggle="tooltip"
            data-placement="top"
            title="N, P, K, S, Z values are the total amounts for the season"
          />
          <i class="fa fa-chevron-down" v-if="!showInputs" />
          <i class="fa fa-chevron-up" v-else />
        </h2>

        <div class="card-body d-flex" v-if="showInputs">
          <div class="left-side">
            <div class="row">
              <div class="col">
                <span class="default-input-title">Yield Goal</span>
                <div class="input-group">
                  <input
                    type="number"
                    class="form-control"
                    v-model="fertilizerData.yield_goal_bu_per_acre"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">bu/ac</span>
                  </div>
                </div>
              </div>

              <div class="col">
                <span class="default-input-title">S Lbs / Acre</span>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">#</span>
                  </div>
                  <input
                    type="number"
                    class="form-control"
                    v-model="fertilizerData.s_units_per_acre"
                  />
                </div>
              </div>

              <div class="col">
                <span class="default-input-title">Z Lbs / Acre</span>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">#</span>
                  </div>
                  <input
                    type="number"
                    class="form-control"
                    v-model="fertilizerData.z_units_per_acre"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <span class="default-input-title">N Lbs / Acre</span>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">#</span>
                  </div>
                  <input
                    type="number"
                    class="form-control"
                    v-model="fertilizerData.n_units_per_acre"
                  />
                </div>
              </div>

              <div class="col">
                <span class="default-input-title">P Lbs / Acre</span>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">#</span>
                  </div>
                  <input
                    type="number"
                    class="form-control"
                    v-model="fertilizerData.p_units_per_acre"
                  />
                </div>
              </div>

              <div class="col">
                <span class="default-input-title">K Lbs / Acre</span>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">#</span>
                  </div>
                  <input
                    type="number"
                    class="form-control"
                    v-model="fertilizerData.k_units_per_acre"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <table class="table" v-if="showFertTable">
                  <thead>
                    <tr>
                      <th>Application Method</th>
                      <th>Method Type</th>
                      <th>Fert. Product</th>
                      <th>Application Date</th>
                      <th>Rate</th>
                      <th>Cost</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="fertApplication in fertilizerData.fert_applications"
                      :key="fertApplication.idx"
                    >
                      <td>{{ fertApplication.application_method }}</td>
                      <td>{{ fertApplication.method_type }}</td>
                      <td>{{ fertApplication.fert_product }}</td>
                      <td>{{ fertApplication.application_date | date }}</td>
                      <td>
                        {{ fertApplication.fert_rate }}
                        {{ fertApplication.fert_rate_units | fertUnits }}
                      </td>
                      <td>
                        {{ fertApplication.fert_cost | currency }} /
                        {{ fertApplication.fert_rate_units | fertCostUnits }}
                      </td>

                      <td>
                        <i
                          class="fa fa-times-circle"
                          @click="deleteFertApplication(fertApplication.idx)"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="right-side">
            <div class="row">
              <div class="col">
                <span class="default-input-title">Application Method</span>
                <v-select-old
                  class="select-style"
                  label="display_name"
                  :options="applicationMethodChoices"
                  :reduce="o => o.value"
                  v-model="applicationMethod"
                />
              </div>
              <div class="col">
                <div v-if="applicationMethod !== 'manure'">
                  <span class="default-input-title">Method Type</span>
                  <v-select-old
                    class="select-style"
                    label="display_name"
                    :options="methodTypeChoices"
                    :reduce="o => o.value"
                    v-model="methodType"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <span class="default-input-title">Fertilizer Product</span>
                <v-select-old
                  class="select-style"
                  label="display_name"
                  :options="productChoices"
                  :reduce="o => o.value"
                  v-model="fertProduct"
                />
              </div>
              <div class="col">
                <span class="default-input-title">Application Date</span>
                <datepicker v-model="applicationDate" />
              </div>
            </div>

            <div class="row">
              <div class="col">
                <span class="default-input-title">Rate</span>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">#</span>
                  </div>
                  <input
                    type="number"
                    class="form-control"
                    v-model="fertRate"
                  />
                </div>
              </div>

              <div class="col">
                <span class="default-input-title">Rate Units</span>
                <v-select-old
                  class="select-style"
                  label="display_name"
                  :options="rateUnitChoices"
                  :reduce="o => o.value"
                  v-model="fertRateUnits"
                />
              </div>
            </div>

            <div class="row">
              <div class="col">
                <span class="default-input-title">Cost Per {{ unitText }}</span>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">$</span>
                  </div>
                  <input
                    type="number"
                    class="form-control"
                    v-model="fertCost"
                  />
                </div>
              </div>
              <div class="col" />
            </div>

            <div class="row">
              <div class="col">
                <button
                  type="button"
                  class="btn btn-primary update-btn"
                  :class="{ disabled: !canAddProduct }"
                  @click="handleAddProduct(true)"
                >
                  <i class="fa fa-plus" />Add Product
                </button>
              </div>
              <div class="col">
                <button
                  type="submit"
                  class="btn btn-primary update-btn"
                  :class="{ disabled: !canUpdate }"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import _ from "lodash"
import { mapActions } from "vuex"
import DefaultsAPI from "@/api/DefaultsAPI"
import {
  APPLICATION_METHOD_CHOICES,
  WET_METHOD_TYPE_CHOICES,
  DRY_METHOD_TYPE_CHOCIES,
  DRY_FERT_PRODUCT_CHOICES,
  WET_FERT_PRODUCT_CHOICES,
  MANURE_PRODUCT_CHOICES,
  RATE_UNIT_CHOICES,
} from "@/constants/defaults"
import { Fields } from "@/store/modules"
import { successMessage } from "@/utility"

export default {
  name: "FertilizerDataDefaults",
  props: {
    fertilizerData: { type: Object, required: true },
  },

  data() {
    return {
      showInputs: true,
      applicationMethod: null,
      methodType: null,
      fertProduct: null,
      applicationDate: null,
      fertRate: null,
      fertRateUnits: null,
      fertCost: null,
      applicationMethodChoices: APPLICATION_METHOD_CHOICES,
      wetMethodTypeChoices: WET_METHOD_TYPE_CHOICES,
      dryMethodTypeChoices: DRY_METHOD_TYPE_CHOCIES,
      dryFertProductChoices: DRY_FERT_PRODUCT_CHOICES,
      wetFertProductChoices: WET_FERT_PRODUCT_CHOICES,
      manureProductChoices: MANURE_PRODUCT_CHOICES,
      rateUnitChoices: RATE_UNIT_CHOICES,
      // update button highlighting
      initialYieldGoal: null,
      initialNUnits: null,
      initialPUnits: null,
      initialKUnits: null,
      initialSUnits: null,
      initialZUnits: null,
    }
  },

  computed: {
    unitText() {
      if (this.fertRateUnits === "lb") return "Ton"
      if (this.fertRateUnits === "gal") return "Gallon"
      return "Unit"
    },

    canUpdate() {
      const {
        yield_goal_bu_per_acre,
        n_units_per_acre,
        p_units_per_acre,
        k_units_per_acre,
        s_units_per_acre,
        z_units_per_acre,
      } = this.fertilizerData
      const {
        initialYieldGoal,
        initialNUnits,
        initialPUnits,
        initialKUnits,
        initialSUnits,
        initialZUnits,
      } = this
      return (
        yield_goal_bu_per_acre !== initialYieldGoal ||
        n_units_per_acre !== initialNUnits ||
        p_units_per_acre !== initialPUnits ||
        k_units_per_acre !== initialKUnits ||
        s_units_per_acre !== initialSUnits ||
        z_units_per_acre !== initialZUnits
      )
    },

    canAddProduct() {
      const {
        applicationMethod,
        methodType,
        applicationDate,
        fertProduct,
        fertRate,
        fertRateUnits,
        fertCost,
      } = this
      const methodTypeCondition = applicationMethod === "manure" || !!methodType
      return (
        !!applicationMethod &&
        methodTypeCondition &&
        !!applicationDate &&
        !!fertProduct &&
        !!fertRate &&
        !!fertRateUnits &&
        !!fertCost
      )
    },

    methodTypeChoices() {
      if (!this.applicationMethod) return []
      return this.applicationMethod === "wet"
        ? this.wetMethodTypeChoices
        : this.dryMethodTypeChoices
    },

    productChoices() {
      if (this.applicationMethod === "wet") return this.wetFertProductChoices
      if (this.applicationMethod === "dry") return this.dryFertProductChoices
      if (this.applicationMethod === "manure") return this.manureProductChoices
      return []
    },

    showFertTable() {
      return (
        !_.isEmpty(this.fertilizerData) &&
        this.fertilizerData.fert_applications.length > 0
      )
    },
  },

  filters: {
    fertUnits: value => {
      if (value === "lb") return "lb/ac"
      if (value === "gal") return "gal/ac"
      return "units/ac"
    },

    fertCostUnits: value => {
      if (value === "lb") return "ton"
      if (value === "gal") return "gal"
      return "unit"
    },
  },

  methods: {
    ...mapActions({
      fetchFields: Fields.Actions.fetchFields,
    }),

    deleteFertApplication(idx) {
      this.fertilizerData.fert_applications = this.fertilizerData.fert_applications.filter(
        fert => fert.idx !== idx
      )
      this.handleSubmit()
    },

    handleAddProduct(submit = false) {
      if (this.canAddProduct) {
        this.fertilizerData.fert_applications.push({
          application_method: this.applicationMethod,
          method_type: this.methodType,
          fert_product: this.fertProduct,
          application_date: this.applicationDate,
          fert_rate: this.fertRate,
          fert_rate_units: this.fertRateUnits,
          fert_cost: this.fertCost,
        })

        this.fertilizerData.fert_applications = this.fertilizerData.fert_applications.map(
          (row, idx) => ({
            ...row,
            idx,
          })
        )

        if (submit) this.handleSubmit()

        this.applicationMethod = null
        this.methodType = null
        this.applicationDate = null
        this.fertProduct = null
        this.fertRate = null
        this.fertRateUnits = null
        this.fertCost = null
      }
    },

    async handleSubmit(addProduct = false) {
      if (addProduct) this.handleAddProduct()

      await DefaultsAPI.update(
        "fertilizer-defaults",
        this.fertilizerData.id,
        this.fertilizerData
      )
      successMessage(this, "Fertilizer Defaults Updated", "top-right")
      this.fetchFields()
    },
  },

  mount() {
    const {
      yield_goal_bu_per_acre,
      n_units_per_acre,
      p_units_per_acre,
      k_units_per_acre,
      s_units_per_acre,
      z_units_per_acre,
    } = this.fertilizerData
    this.initialYieldGoal = yield_goal_bu_per_acre
    this.initialNUnits = n_units_per_acre
    this.initialPUnits = p_units_per_acre
    this.initialKUnits = k_units_per_acre
    this.initialSUnits = s_units_per_acre
    this.initialZUnits = z_units_per_acre
  },
}
</script>

<style scoped>
.defaults-container {
  background-color: white;
}

.default-title {
  font-size: 16px;
  font-weight: bold;
  color: #515365;
  margin-bottom: 0;
  cursor: pointer;
}

.default-title i {
  margin-left: 5px;
}

.default-input-title {
  font-size: 14px;
}

form {
  width: 100%;
}

.info-margin {
  margin-left: 0 !important;
  margin-right: 5px;
}

.left-side {
  flex: 3;
  padding-right: 25px;
  border-right: 1pt solid #ccc;
}

.right-side {
  flex: 2;
  padding-left: 25px;
}

.update-btn {
  width: 100%;
  margin: 0;
  margin-top: 17px;
}

.fa-plus {
  margin-right: 5px;
}

.fa-times-circle {
  cursor: pointer;
  color: #e60000;
}
</style>
