<template>
  <ArvaModal
    :name="name"
    title="Add Fertilizer Plan"
    @close-modal="handleClose"
  >
    <div class="px-2 py-2">
      <div class="row mx-0">
        <div class="col-5 text-bold">
          Crop
        </div>
        <div class="col-7">
          <div class="input-group">
            <v-select-old
              class="select-style w-100"
              label="display_name"
              :options="cropChoices"
              :reduce="o => o.value"
              v-model="crop"
            />
          </div>
        </div>
      </div>

      <div class="row mx-0">
        <div class="col-5 text-bold">
          Application Method
        </div>
        <div class="col-7">
          <div class="input-group">
            <v-select-old
              class="select-style w-100"
              label="display_name"
              :options="applicationMethodChoices"
              :reduce="o => o.value"
              v-model="applicationMethod"
            />
          </div>
        </div>
      </div>

      <div class="row mx-0">
        <div class="col-5 text-bold">
          Method Type
        </div>
        <div class="col-7">
          <div class="input-group">
            <v-select-old
              class="select-style w-100"
              label="display_name"
              :options="methodTypeChoices"
              :reduce="o => o.value"
              v-model="methodType"
            />
          </div>
        </div>
      </div>

      <div class="row mx-0">
        <div class="col-5 text-bold">
          Fertilizer Product
        </div>
        <div class="col-7">
          <div class="input-group">
            <v-select-old
              class="select-style w-100"
              label="display_name"
              taggable
              push-tags
              :options="productChoices"
              :create-option="o => ({ value: o, display_name: o })"
              :reduce="o => o.value"
              v-model="fertProduct"
            />
          </div>
        </div>
      </div>

      <div class="row mx-0">
        <div class="col-5 text-bold">
          Application Date
        </div>
        <div class="col-7">
          <div class="input-group">
            <datepicker v-model="applicationDate" />
          </div>
        </div>
      </div>

      <div class="row mx-0">
        <div class="col-5 text-bold">
          Rate
        </div>
        <div class="col-7">
          <div class="input-group">
            <input type="number" class="form-control" v-model="fertRate" />
          </div>
        </div>
      </div>

      <div class="row mx-0">
        <div class="col-5 text-bold">
          Rate Units
        </div>
        <div class="col-7">
          <div class="input-group">
            <v-select-old
              class="select-style w-100"
              label="display_name"
              :options="rateUnitChoices"
              :reduce="o => o.value"
              v-model="fertRateUnits"
            />
          </div>
        </div>
      </div>

      <div class="row mx-0">
        <div class="col-5 text-bold">Cost Per {{ unitText }}</div>
        <div class="col-7">
          <div class="input-group">
            <input type="number" class="form-control" v-model="fertCost" />
          </div>
        </div>
      </div>

      <div class="row mx-0">
        <div class="col-5 text-bold">N Lbs / Acre</div>
        <div class="col-7">
          <div class="input-group">
            <input type="number" class="form-control" v-model="nAmount" />
          </div>
        </div>
      </div>

      <div class="row mx-0">
        <div class="col-5 text-bold">P Lbs / Acre</div>
        <div class="col-7">
          <div class="input-group">
            <input type="number" class="form-control" v-model="pAmount" />
          </div>
        </div>
      </div>

      <div class="row mx-0">
        <div class="col-5 text-bold">K Lbs / Acre</div>
        <div class="col-7">
          <div class="input-group">
            <input type="number" class="form-control" v-model="kAmount" />
          </div>
        </div>
      </div>

      <div class="row mx-0">
        <div class="col-5 text-bold">S Lbs / Acre</div>
        <div class="col-7">
          <div class="input-group">
            <input type="number" class="form-control" v-model="sAmount" />
          </div>
        </div>
      </div>

      <div class="row mx-0">
        <div class="col-5 text-bold">Ammonium Percentage</div>
        <div class="col-7">
          <div class="input-group">
            <input
              type="number"
              class="form-control"
              v-model="ammoniumPercentage"
            />
            <div class="input-group-append">
              <span class="input-group-text">%</span>
            </div>
          </div>
        </div>
      </div>

      <div class="row mx-0">
        <div class="col-5 text-bold">
          Slow Release
        </div>
        <div class="col-7">
          <div class="input-group">
            <div class="toggle-checkbox toggle-sm toggle-success mr-2 d-flex">
              <input
                type="checkbox"
                id="slowReleaseToggle"
                name="slowReleaseToggle"
                class="checked mx-3"
                v-model="slowRelease"
              />
              <label for="slowReleaseToggle" class="form-check-label" />
            </div>
          </div>
        </div>
      </div>

      <div class="row mx-0">
        <div class="col-5 text-bold">
          Inhibitor
        </div>
        <div class="col-7">
          <div class="input-group">
            <div class="toggle-checkbox toggle-sm toggle-success mr-2 d-flex">
              <input
                type="checkbox"
                id="inhibitorToggle"
                name="inhibitorToggle"
                class="checked mx-3"
                v-model="inhibitor"
              />
              <label for="inhibitorToggle" class="form-check-label" />
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div
          type="button"
          class="btn btn-primary btn-sm ml-auto mr-4"
          :class="{ disabled: !canSubmit }"
          @click="handleSubmit"
        >
          Ok
        </div>
      </div>
    </div>
  </ArvaModal>
</template>

<script>
import ArvaModal from "../ArvaModal"
import { CROP_DROPDOWN_OPTIONS } from "@/constants"
import {
  APPLICATION_METHOD_CHOICES,
  WET_METHOD_TYPE_CHOICES,
  DRY_METHOD_TYPE_CHOCIES,
  DRY_FERT_PRODUCT_CHOICES,
  WET_FERT_PRODUCT_CHOICES,
  MANURE_PRODUCT_CHOICES,
  RATE_UNIT_CHOICES,
} from "@/constants/defaults"

export default {
  name: "FertilizerPlanModal",

  components: {
    ArvaModal,
  },

  data() {
    return {
      name: "FertilizerPlanModal",
      // dropdown choices
      applicationMethodChoices: APPLICATION_METHOD_CHOICES,
      cropChoices: CROP_DROPDOWN_OPTIONS,
      wetMethodTypeChoices: WET_METHOD_TYPE_CHOICES,
      dryMethodTypeChoices: DRY_METHOD_TYPE_CHOCIES,
      dryFertProductChoices: DRY_FERT_PRODUCT_CHOICES,
      wetFertProductChoices: WET_FERT_PRODUCT_CHOICES,
      manureProductChoices: MANURE_PRODUCT_CHOICES,
      rateUnitChoices: RATE_UNIT_CHOICES,
      // inputs
      ammoniumPercentage: null,
      amount: null,
      applicationDate: null,
      applicationMethod: null,
      crop: null,
      fertProduct: null,
      fertRate: null,
      fertRateUnits: null,
      fertCost: null,
      inhibitor: false,
      methodType: null,
      product: null,
      slowRelease: false,
      unit: null,
      nAmount: null,
      pAmount: null,
      kAmount: null,
      sAmount: null,
      zAmount: null,
    }
  },

  computed: {
    canSubmit() {
      const checkInputs = [
        this.applicationMethod,
        this.methodType,
        this.fertProduct,
        this.applicationDate,
        this.fertRate,
        this.fertRateUnits,
        this.fertCost,
        this.nAmount,
        this.pAmount,
        this.kAmount,
        this.sAmount,
      ]
      const anyNull = val => val === null
      return !checkInputs.some(anyNull)
    },
    methodTypeChoices() {
      if (!this.applicationMethod) return []
      return this.applicationMethod === "wet"
        ? this.wetMethodTypeChoices
        : this.dryMethodTypeChoices
    },
    productChoices() {
      if (this.applicationMethod === "wet") return this.wetFertProductChoices
      if (this.applicationMethod === "dry") return this.dryFertProductChoices
      if (this.applicationMethod === "manure") return this.manureProductChoices
      return []
    },
    fertPlan() {
      const plan = {
        N: { source: "User Input", value: this.nAmount },
        P: { source: "User Input", value: this.pAmount },
        K: { source: "User Input", value: this.kAmount },
        S: { source: "User Input", value: this.sAmount },
        ammonium_pct: { source: "User Input", value: this.ammoniumPercentage },
        // amount === fertRate
        amount: { source: "User Input", value: this.fertRate },
        cost: { source: "User Input", value: this.fertCost },
        crop_id: { source: "User Input", value: this.crop },
        date: { source: "User Input", value: this.applicationDate },
        // fert_product: { source: "User Input", value: this.fertProduct },
        fert_rate: { source: "User Input", value: this.fertRate },
        // fert_rate_units: { source: "User Input", value: this.fertRateUnits },
        inhibitor: { source: "User Input", value: this.inhibitor },
        method: { source: "User Input", value: this.applicationMethod },
        method_type: { source: "User Input", value: this.methodType },
        product: { source: "User Input", value: this.fertProduct },
        slow_release: { source: "User Input", value: this.slowRelease },
        unit: { source: "User Input", value: this.fertRateUnits },
      }
      return plan
    },
    unitText() {
      if (this.fertRateUnits === "lb") return "Ton"
      if (this.fertRateUnits === "gal") return "Gallon"
      return "Unit"
    },
  },

  methods: {
    handleClose() {
      this.$emit("close-template-modal")
    },
    handleSubmit() {
      this.$emit("submit-fert-plan", this.fertPlan)
    },
  },
}
</script>
<style scoped>
.crop-icon {
  width: 30px;
  height: 30px;
}

.group-width {
  width: 30%;
}

.append-text-width {
  width: 100%;
}
</style>
