<template>
  <div>
    <div class="row">
      <div class="col">
        <div
          class="settings-section-title"
          @click="showFinancialData = !showFinancialData"
        >
          Financials/ROI
          <i class="fa fa-chevron-down" v-if="!showFinancialData" />
          <i class="fa fa-chevron-up" v-else />
        </div>
      </div>
    </div>
    <div v-if="showFinancialData" class="settings-table">
      <SettingsRow
        v-on:clear-input="
          financialData.operational_cost = null
          handleSubmit()
        "
        v-on:submit-row="handleSubmit"
        v-on:focus-input="focusInput('operational_cost')"
      >
        <template v-slot:title>
          Operational Cost
        </template>
        <template v-slot:display-value>
          {{ displaySetting("operational_cost") }}
        </template>
        <template v-slot:input="{ handleEnter }">
          <input
            v-model="financialData.operational_cost"
            type="number"
            :placeholder="displaySetting('operational_cost')"
            ref="operational_cost"
            @keyup="handleEnter"
          />
        </template>
        <!-- <template v-slot:default-type>
          <div
            v-bind:class="{ 'default-name': isLink('operational_cost') }"
            @click="handleLinkClick('operational_cost')"
          >
            {{ defaultType("operational_cost") }}
            <i
              v-if="isLink('operational_cost')"
              class="fa fa-arrow-circle-right"
            />
          </div>
        </template> -->
      </SettingsRow>

      <SettingsRow
        v-on:clear-input="
          financialData.equipment_and_irrigation_cost = null
          handleSubmit()
        "
        v-on:submit-row="handleSubmit"
        v-on:focus-input="focusInput('equipment_and_irrigation_cost')"
      >
        <template v-slot:title>
          Irrigation Cost per Season $USD/acre
        </template>
        <template v-slot:display-value>
          {{ displaySetting("equipment_and_irrigation_cost") }}
        </template>
        <template v-slot:input="{ handleEnter }">
          <input
            v-model="financialData.equipment_and_irrigation_cost"
            type="number"
            :placeholder="displaySetting('equipment_and_irrigation_cost')"
            ref="equipment_and_irrigation_cost"
            @keyup="handleEnter"
          />
        </template>
        <!-- <template v-slot:default-type>
          <div
            v-bind:class="{
              'default-name': isLink('equipment_and_irrigation_cost'),
            }"
            @click="handleLinkClick('equipment_and_irrigation_cost')"
          >
            {{ defaultType("equipment_and_irrigation_cost") }}
            <i
              v-if="isLink('equipment_and_irrigation_cost')"
              class="fa fa-arrow-circle-right"
            />
          </div>
        </template> -->
      </SettingsRow>

      <SettingsRow
        v-on:clear-input="
          financialData.lease_payment_type = null
          handleSubmit()
        "
        v-on:submit-row="handleSubmit"
      >
        <template v-slot:title>
          Lease / Rental Payment Type
        </template>
        <template v-slot:display-value>
          {{ displaySetting("lease_payment_type") }}
        </template>
        <template v-slot:input>
          <v-select-old
            class="select-style flex-fill"
            :options="leaseTypeChoices"
            :reduce="o => o.value"
            v-model="financialData.lease_payment_type"
            label="display_name"
            :placeholder="displaySetting('lease_payment_type')"
          />
        </template>
        <!-- <template v-slot:default-type>
          <div
            v-bind:class="{ 'default-name': isLink('lease_payment_type') }"
            @click="handleLinkClick('lease_payment_type')"
          >
            {{ defaultType("lease_payment_type") }}
            <i
              v-if="isLink('lease_payment_type')"
              class="fa fa-arrow-circle-right"
            />
          </div>
        </template> -->
      </SettingsRow>

      <SettingsRow
        v-on:clear-input="
          financialData.lease_cost_per_acre = null
          handleSubmit()
        "
        v-on:submit-row="handleSubmit"
        v-on:focus-input="focusInput('lease_cost_per_acre')"
      >
        <template v-slot:title>
          Lease Cost $USD/acre
        </template>
        <template v-slot:display-value>
          {{ displaySetting("lease_cost_per_acre") }}
        </template>
        <template v-slot:input="{ handleEnter }">
          <input
            v-model="financialData.lease_cost_per_acre"
            type="number"
            :placeholder="displaySetting('lease_cost_per_acre')"
            ref="lease_cost_per_acre"
            @keyup="handleEnter"
          />
        </template>
        <!-- <template v-slot:default-type>
          <div
            v-bind:class="{ 'default-name': isLink('lease_cost_per_acre') }"
            @click="handleLinkClick('lease_cost_per_acre')"
          >
            {{ defaultType("lease_cost_per_acre") }}
            <i
              v-if="isLink('lease_cost_per_acre')"
              class="fa fa-arrow-circle-right"
            />
          </div>
        </template> -->
      </SettingsRow>

      <SettingsRow
        v-on:clear-input="
          financialData.lease_crop_share_percentage = null
          handleSubmit()
        "
        v-on:submit-row="handleSubmit"
        v-on:focus-input="focusInput('lease_crop_share_percentage')"
      >
        <template v-slot:title>
          Land Lease Crop Share %
        </template>
        <template v-slot:display-value>
          {{ displaySetting("lease_crop_share_percentage") }}
        </template>
        <template v-slot:input="{ handleEnter }">
          <input
            v-model="financialData.lease_crop_share_percentage"
            type="number"
            :placeholder="displaySetting('lease_crop_share_percentage')"
            ref="lease_crop_share_percentage"
            @keyup="handleEnter"
          />
        </template>
        <!-- <template v-slot:default-type>
          <div
            v-bind:class="{
              'default-name': isLink('lease_crop_share_percentage'),
            }"
            @click="handleLinkClick('lease_crop_share_percentage')"
          >
            {{ defaultType("lease_crop_share_percentage") }}
            <i
              v-if="isLink('lease_crop_share_percentage')"
              class="fa fa-arrow-circle-right"
            />
          </div>
        </template> -->
      </SettingsRow>

      <SettingsRow
        v-on:clear-input="
          financialData.fert_cost_per_acre = null
          handleSubmit()
        "
        v-on:submit-row="handleSubmit"
        v-on:focus-input="focusInput('fert_cost_per_acre')"
      >
        <template v-slot:title>
          Fertilizer Cost Per Acre
        </template>
        <template v-slot:display-value>
          {{ displaySetting("fert_cost_per_acre") }}
        </template>
        <template v-slot:input="{ handleEnter }">
          <input
            v-model="financialData.fert_cost_per_acre"
            type="number"
            :placeholder="displaySetting('fert_cost_per_acre')"
            ref="fert_cost_per_acre"
            @keyup="handleEnter"
          />
        </template>
        <!-- <template v-slot:default-type>
          <div
            v-bind:class="{ 'default-name': isLink('fert_cost_per_acre') }"
            @click="handleLinkClick('fert_cost_per_acre')"
          >
            {{ defaultType("fert_cost_per_acre") }}
            <i
              v-if="isLink('fert_cost_per_acre')"
              class="fa fa-arrow-circle-right"
            />
          </div>
        </template> -->
      </SettingsRow>

      <SettingsRow
        v-on:clear-input="
          financialData.seed_cost_per_acre = null
          handleSubmit()
        "
        v-on:submit-row="handleSubmit"
        v-on:focus-input="focusInput('seed_cost_per_acre')"
      >
        <template v-slot:title>
          Seed Cost Per Acre
        </template>
        <template v-slot:display-value>
          {{ displaySetting("seed_cost_per_acre") }}
        </template>
        <template v-slot:input="{ handleEnter }">
          <input
            v-model="financialData.seed_cost_per_acre"
            type="number"
            :placeholder="displaySetting('seed_cost_per_acre')"
            ref="seed_cost_per_acre"
            @keyup="handleEnter"
          />
        </template>
        <!-- <template v-slot:default-type>
          <div
            v-bind:class="{ 'default-name': isLink('seed_cost_per_acre') }"
            @click="handleLinkClick('seed_cost_per_acre')"
          >
            {{ defaultType("seed_cost_per_acre") }}
            <i
              v-if="isLink('seed_cost_per_acre')"
              class="fa fa-arrow-circle-right"
            />
          </div>
        </template> -->
      </SettingsRow>

      <SettingsRow
        v-on:clear-input="
          financialData.estimated_price_per_bushel = null
          handleSubmit()
        "
        v-on:submit-row="handleSubmit"
        v-on:focus-input="focusInput('estimated_price_per_bushel')"
      >
        <template v-slot:title>
          Est. Market $ Per Bushel
        </template>
        <template v-slot:display-value>
          {{ displaySetting("estimated_price_per_bushel") }}
        </template>
        <template v-slot:input="{ handleEnter }">
          <input
            v-model="financialData.estimated_price_per_bushel"
            type="number"
            :placeholder="displaySetting('estimated_price_per_bushel')"
            ref="estimated_price_per_bushel"
            @keyup="handleEnter"
          />
        </template>
        <!-- <template v-slot:default-type>
          <div
            v-bind:class="{
              'default-name': isLink('estimated_price_per_bushel'),
            }"
            @click="handleLinkClick('estimated_price_per_bushel')"
          >
            {{ defaultType("estimated_price_per_bushel") }}
            <i
              v-if="isLink('estimated_price_per_bushel')"
              class="fa fa-arrow-circle-right"
            />
          </div>
        </template> -->
      </SettingsRow>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex"
import DefaultsAPI from "@/api/DefaultsAPI"
import { LEASE_TYPE_CHOICES } from "@/constants/defaults"
import { Fields } from "@/store/modules"
import SettingsRow from "./SettingsRow"

export default {
  name: "FinancialFieldSettings",
  components: {
    SettingsRow,
  },
  props: {
    financialData: { type: Object, required: true },
    financialDefaults: { type: Object, required: true },
    generalDefaults: { type: Object, required: true },
    defaultsName: String,
  },
  data() {
    return {
      // view toggle
      showFinancialData: false,
      // dropdown choices
      leaseTypeChoices: LEASE_TYPE_CHOICES,
    }
  },
  methods: {
    ...mapActions({
      fetchFields: Fields.Actions.fetchFields,
    }),

    async handleSubmit() {
      await DefaultsAPI.update(
        "operation-costs-defaults",
        this.financialData.id,
        this.financialData
      )
      this.fetchFields()
    },

    defaultType(defaultName) {
      if (this.financialData[defaultName]) return "Field Setting"
      if (this.financialDefaults[defaultName]) return this.defaultsName
      return "General Defaults"
    },

    displaySetting(settingName) {
      if (this.financialData[settingName])
        return this.financialData[settingName]
      if (this.financialDefaults[settingName])
        return this.financialDefaults[settingName]
      if (this.generalDefaults.operation_costs[settingName])
        return this.generalDefaults.operation_costs[settingName]
      return "--"
    },

    isLink(defaultName) {
      if (this.financialData[defaultName]) return false
      if (this.financialDefaults[defaultName]) return true
      return true
    },

    handleLinkClick(defaultName) {
      if (
        !this.financialData[defaultName] &&
        this.financialDefaults[defaultName]
      ) {
        this.$router.push(
          `/settings/defaults/${this.financialDefaults.defaults_template}`
        )
      }
      if (
        !this.financialData[defaultName] &&
        !this.financialDefaults[defaultName]
      ) {
        this.$router.push(`/settings/defaults/${this.generalDefaults.id}`)
      }
    },

    focusInput(input) {
      this.$nextTick(() => {
        this.$refs[input].focus()
      })
    },

    handleEnter() {},
  },
}
</script>
