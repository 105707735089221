<template>
  <div class="form-group">
    <label for="org-select">Choose target org</label>
    <v-select-old
      :options="orgNodes"
      :reduce="org => org.id"
      label="name"
      v-model="orgInfo"
    />
  </div>
</template>

<script>
export default {
  name: "SelectOrg",
  props: {
    orgNodes: {type: Array},
  },
  data() {
    return {
      orgInfo: undefined,
    }
  },

  watch: {
    orgInfo() {
      this.$emit("change-org-id", this.orgInfo)
    }
  },

  async mounted() {
    this.orgInfo = this.orgNodes[0].id
  }
}
</script>

<style scoped>
.form-group {
  margin: 20px auto 50px auto;
  padding: 0px 100px;
}
</style>
