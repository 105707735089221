<template>
  <ArvaModal
    name="alertErrorModal"
    title="Set Land Owner Name"
    tooltip="Prior to the tenant being approved for 
    carbon offset payments that span multiple years, 
    the land owner must sign a waiver to approve the 
    O2 Sequestration practice on their land. 
    This is a requirement to generate carbon offsets 
    prior to sale on one or more carbon exchanges."
    @close-modal="handleCloseModal"
  >
    <b-alert v-model="showErrorAlert" variant="danger" dismissible>
      {{errorMessage}}
    </b-alert>

    <div class="row setLandOwnerNameModal">
      <div class="col fullNameCheckbox">
        <span class="default-input-title">Enter the first and last name of the primary land owner for this field.</span>
        <div v-for="landOwnerName in landOwnerNameList"
          :key="landOwnerName">
          <input
            class="ml-2"
            type="checkbox"
            name="landOwnerName"
            ref="landOwnerNameCheckbox"
            :value="landOwnerName"
            :disabled="landOwnerNameToAdd"
            @change="check($event, landOwnerName)"
          />
          <span class="checkboxText">{{landOwnerName}}</span>
        </div>
      </div>

      <v-divider
        vertical
      ></v-divider>

      <div class="col">
        <span class="default-input-title">Full Name</span>
        <div class="input-group">
          <input type="text" class="fullNameInput" v-model.trim="landOwnerNameToAdd" :disabled="landOwnerNameToAddByCheckbox" @keyup.delete="handleDelete()"/>
        </div>
      </div>
    </div>

    <slot />

    <ArvaModalSaveFooter
      confirmText="Set"
      cancelText="Cancel"
      @on-cancel="handleCloseModal"
      @on-confirm="setLandOwnerName"
    />

  </ArvaModal>
</template>

<script>
import ArvaModal from "./ArvaModal"
import ArvaModalSaveFooter from "@/components/modals/ArvaModalSaveFooter"

export default {
  name: "SetLandOwnerModal",

  props: ["landOwnerNameList", "fieldId"],
  components: { ArvaModal, ArvaModalSaveFooter },

  data() {
    return {
      showErrorAlert: false,
      errorMessage: null,
      landOwnerNameToAdd: null,
      landOwnerNameToAddByCheckbox: null,
    }
  },

  methods: {
    handleCloseModal() {
      this.$emit("close-modal", this.fieldId)
    },
    setLandOwnerName() {
      if (this.landOwnerNameToAdd) this.$emit("set-landowner-name", this.fieldId, this.landOwnerNameToAdd)
      else if (this.landOwnerNameToAddByCheckbox) this.$emit("set-landowner-name", this.fieldId, this.landOwnerNameToAddByCheckbox)
      else this.setErrorAlert("Land Owner Name must be entered or selected from the checkbox(es).")
    },
    check(e, fullName) {
      const checkboxes = this.$refs.landOwnerNameCheckbox
      checkboxes.forEach(checkbox => {
        if (checkbox.value !== fullName) {
          checkbox.checked = false
        }
      })

      if (e.target.checked) this.landOwnerNameToAddByCheckbox = fullName
      else this.landOwnerNameToAddByCheckbox = null
    },
    handleDelete() {
      if (this.landOwnerNameToAdd === '') this.landOwnerNameToAdd = null
    },
    resetErrorAlert() {
      this.errorMessage = null
      this.showErrorAlert = false
    },

    setErrorAlert(message) {
        this.errorMessage = message
        this.showErrorAlert = true
    },
  },
}
</script>

<style scoped>
.setLandOwnerNameModal {
  margin-left: 20px;
  margin-bottom: 10px;
}

.fullNameInput {
  border: 1px solid #e6ecf5;
  border-radius: 2px;
  box-shadow: none;
  height: 42px;
  font-size: 14px;
  color: #888da8;
  transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
}

.fullNameCheckbox {
  margin-left: 20px;
}

.checkboxText {
  margin-left: 5px;
}
</style>
