<template>
  <canvas id="deck-canvas" />
</template>

<script>
import { Deck } from "@deck.gl/core"
import _ from "lodash"
import { mapMutations, mapState } from "vuex"
import { Map } from "@/store/modules"

export default {
  name: "VueDeckGL",
  data() {
    return {
      deck: {},
    }
  },

  props: {
    settings: {
      type: Object,
      required: true,
    },
    map: {
      type: Object,
      required: false,
    },
    layers: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapState({
      extentBounds: state => state.Map.extentBounds,
      singleFieldExtentBounds: state => state.Map.singleFieldExtentBounds,
    }),
  },

  methods: {
    ...mapMutations({
      updateBearingPitch: Map.Mutations.updateBearingPitch,
      updateZoom: Map.Mutations.updateZoom,
    }),

    moveMap({ viewState }) {
      this.deck.setProps({ viewState })
      this.map.jumpTo({
        center: [viewState.longitude, viewState.latitude],
        zoom: viewState.zoom,
        bearing: viewState.bearing,
        pitch: viewState.pitch,
      })
      this.updateZoom(viewState.zoom)
      this.updateBearingPitch(viewState)
    },

    autoZoom(resetView = true) {
      this.map.on("move", () => {
        const viewState = {
          latitude: this.map.getCenter().lat,
          longitude: this.map.getCenter().lng,
          zoom: this.map.getZoom(),
          bearing: this.map.getBearing(),
          pitch: this.map.getPitch(),
        }

        this.deck.setProps({ viewState })
        this.updateZoom(viewState.zoom)
      })

      if (!_.isEmpty(this.singleFieldExtentBounds)) {
        const {
          minLongitude,
          maxLongitude,
          minLatitude,
          maxLatitude,
        } = this.singleFieldExtentBounds

        this.map.fitBounds([
          [minLongitude, minLatitude],
          [maxLongitude, maxLatitude],
        ])
      } else if (!_.isEmpty(this.extentBounds) && resetView) {
        const {
          minLongitude,
          maxLongitude,
          minLatitude,
          maxLatitude,
        } = this.extentBounds

        this.map.fitBounds([
          [minLongitude, minLatitude],
          [maxLongitude, maxLatitude],
        ])
      }
    },

    setFullPitch() {
      const viewState = {
        latitude: this.map.getCenter().lat,
        longitude: this.map.getCenter().lng,
        zoom: this.map.getZoom(),
        bearing: this.map.getBearing(),
        pitch: 60,
      }
      this.map.jumpTo({ pitch: 60 })
      this.deck.setProps({ viewState })
    },

    setOverheadView() {
      const viewState = {
        latitude: this.map.getCenter().lat,
        longitude: this.map.getCenter().lng,
        zoom: this.map.getZoom(),
        bearing: 0,
        pitch: 0,
      }
      this.map.jumpTo({ bearing: 0, pitch: 0 })
      this.deck.setProps({ viewState })
    },
  },

  watch: {
    layers() {
      const layers = _.flatMap(this.layers)
      this.deck.setProps({ layers })
    },
  },

  mounted() {
    this.deck = new Deck({
      ...this.settings,
      onViewStateChange: this.moveMap,
    })
    this.$emit("created", this.deck)
  },
}
</script>
