<template>
  <ArvaModal
    :name="name"
    :title="titleText"
    @close-modal="handleCloseModal"
  >
    <div class="modal-body">
      <p class="confirm-text" v-if="confirmText" v-html="confirmText"></p>
      <v-checkbox
        class="py-1"
        :input-value="dontAskAgain"
        @change="checkboxChanged"
        name="selected"
        dense
        label="Don't ask again"
      />
    </div>
    <ArvaModalSaveFooter
      confirmText="CONFIRM"
      cancelText="CANCEL"
      @on-cancel="handleCloseModal"
      @on-confirm="handleConfirm"
    />
  </ArvaModal>
</template>

<script>
import ArvaModal from "./ArvaModal"
import ArvaModalSaveFooter from "./ArvaModalSaveFooter"

export default {
  name: "ConfirmModalWithCheckbox",
  components: { ArvaModal, ArvaModalSaveFooter },
  props: {
    name: { type: String, default: 'confirmModalWithCheckbox' },
    confirmText: { type: String, required: true },
    titleText: { type: String, required: true },
  },
  data() {
    return {
      dontAskAgain: false,
      checkboxId: `dont-ask-again-${this._uid}`,
    }
  },
  methods: {
    handleCloseModal() {
      this.$emit("close-modal", this.dontAskAgain)
    },
    handleConfirm() {
      this.$emit("confirm", this.dontAskAgain)
    },
    checkboxChanged(checked) {
      this.dontAskAgain = checked
      this.$emit("dontask-changed", this.dontAskAgain)
    },
  },
}
</script>

<style scoped>
.modal-body {
  padding: 10px 30px;
}

.confirm-text {
  font-size: 15px;
  font-weight: 500;
  padding: 10px 10px;
}

</style>