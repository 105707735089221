<template>
    <tr>
        <td>
            {{logEntry}}
        </td>
        <td>
            <div v-for="message in messages"
                :key="message">
                <li>{{message}}</li>
            </div>
        </td>
        <td>
            {{timeStamp}}
        </td>
        <td>
            {{modifiedBy}}
        </td>
    </tr>
</template>

<script>

export default {
    name: "EnrollmentHistoryRow",
    props: ["historyRecord"],

    data() {
        return {

        }
    },

    computed: {
        logEntry() {
            return this.historyRecord.log_entry
        },
        messages() {
            var listOfChanges = []
            if (this.historyRecord.message) {
                listOfChanges = this.historyRecord.message.split("|")
            }
            return listOfChanges
        },
        timeStamp() {
            return this.convertDate(this.historyRecord.updated_at)
        },
        modifiedBy() {
            return this.historyRecord.modified_by.email
        },
    },

    methods: {
        convertDate(date) {
            var dateFormatted = date
            if (date) {
                if (date.length >= 10) {
                dateFormatted = date.substring(0, 10)
                }
            }
            return dateFormatted
        },
    },
}
</script>
