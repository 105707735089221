<template>
  <div>
    <Permissions package="imagery" />
    <div v-if="showImagery">
      <FieldImageryMap
        :boundaries="fieldBoundaries"
        :fields="fields"
        :order="orders"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import MapAPI from "@/api/MapAPI"
import FieldImageryMap from "@/components/fieldImageryMap/FieldImageryMap"
import Permissions from "@/components/permissions/Permissions"
import { Filter } from "@/store/modules"

export default {
  name: "FieldImageryView",
  components: {
    FieldImageryMap,
    Permissions,
  },

  data() {
    return {
      fieldBoundaries: [],
      orders: [],
    }
  },

  computed: {
    ...mapGetters({
      activeFields: Filter.Getters.getSelectedFields,
    }),
    ...mapState({
      showImagery: state => state.Organization.organization.showImagery,
    }),

    fields() {
      const activeFieldIds = this.activeFields.map(field => field.id)
      return this.fieldBoundaries.filter(row =>
        activeFieldIds.includes(row.properties.field.id)
      )
    },
  },

  mounted() {
    MapAPI.fetchBoundaries().then(response => {
      this.fieldBoundaries = response.data.features
    })
  },
}
</script>
