<template>
  <div>
    <FieldPerformanceReport
      v-if="reportType === 'field-performance'"
      :reportData="reportData"
      :voxelGeom="voxelGeom"
    />
  </div>
</template>

<script>
import { fetchReportData } from "@/api/ReportsAPI"
import FieldPerformanceReport from "@/components/reports/FieldPerformanceReport"

export default {
  name: "PDFGeneratorView",
  components: { FieldPerformanceReport },

  data() {
    return {
      boundaryData: null,
      reportType: null,
      reportData: null,
      voxelGeom: null,
    }
  },

  async mounted() {
    const { reportHash } = this.$route.params

    await fetchReportData(reportHash).then(resp => {
      const { report_type, report_data, voxel_geom } = resp.data
      console.log("report data: ", report_data, voxel_geom)
      this.reportType = report_type
      this.reportData = report_data
      this.voxelGeom = voxel_geom
    })
  },

  beforeCreate() {
    document.body.className = "pdf-body"
  },
}
</script>
<style scoped>
body {
  background-color: white !important;
}
</style>
