import API from "./API"

const resource = "/user-organizations/"

const acceptDataAgreement = orgNodeId =>
  API.post(`${resource}${orgNodeId}/accept_data_agreement/`)

const addNewOrganization = payload =>
API.post(`${resource}`, payload)

const editOrganization = (orgNodeId, payload) =>
API.put(`${resource}${orgNodeId}/`, payload)

const canModifyPosition = (orgNodeId, payload) =>
API.post(`${resource}${orgNodeId}/can_modify_position/`, payload)

const modifyPosition = (payload) =>
API.post(`${resource}modify_position/`, payload)

const removeOrganization = orgNodeId =>
API.delete(`${resource}${orgNodeId}/`)

export default {
  acceptDataAgreement,
  addNewOrganization,
  editOrganization,
  canModifyPosition,
  modifyPosition,
  removeOrganization
}
