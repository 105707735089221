<template>
  <b-td
    :id="'fertilizer-table-' + rowNum"
    class="cell-table"
    :class="{
      'gray-bg': rowNum % 2 !== 0,
    }"
  >
    <div>
      <b-table-simple class="mb-0 fertilizer-table" width="100%">
        <b-tbody>
          <FertilizerRow
            v-for="fertilizer in fertilizerData"
            :addition-view="false"
            :cropId="cropId"
            :field="fieldId"
            :fertilizer="fertilizer"
            :key="fertilizer.idx"
            :rowIndex="fertilizerData.indexOf(fertilizer)"
            :year="year"
            @handle-copy="handleCopyFertilizer($event)"
            @bad-input="handleBadInput"
          />
        </b-tbody>
      </b-table-simple>
      <!-- addition button -->
      <!-- <div
        v-if="!showAddition"
        class="btn btn-primary add-icon icon-column mr-0 pull-right"
        @click="showAddition = true"
      >
        <i class="fa fa-plus" />
      </div> -->
      <div v-if="showAddition" class="border">
        <div class="float-right mr-1 mt-1">
          <i class="fa fa-times-circle fa-2x" @click="resetFertilizerToAdd" />
        </div>
        <div class="addition-container">
          <b-table-simple class="mt-4" width="100%">
            <b-tbody>
              <FertilizerRow
                :addition-view="true"
                :fertilizer="fertilizerToAdd"
                :year="year"
                @bad-input="handleBadInput"
              />
            </b-tbody>
          </b-table-simple>
          <div class="d-flex flex-row justify-content-between my-3">
            <div class="add-text">
              {{ showCopy ? "Copy" : "Add" }} to
              <!-- Re-enable for multifield add/copy -->
              <!-- {{ fieldYearToCopy.length }} -->
              additional years:
            </div>
            <div>
              <v-select-old
                multiple
                class="year-select"
                :options="years"
                v-model="yearSelected"
              />
            </div>
          </div>
          <!-- Re-enable for multifield add/copy -->
          <!-- <div class="d-flex flex-row justify-content-between mt-1">
            <div class="mb-3">
              <div class="d-flex flex-row">
                <span>Years: </span
                ><v-select-old
                  class="ml-2 year-select"
                  multiple
                  :options="years"
                  v-model="yearSelected"
                />
              </div>
            </div>
            <div>
              <v-select-old
                multiple
                class="field-select"
                label="name"
                :options="allFields"
                :reduce="o => o.id"
                v-model="fieldYearToCopy"
              />
            </div>
            <div>
              <button
                type="button"
                class="btn btn-primary py-2"
                @click="handleAddFieldsInFocus"
              >
                Select
                <span class="badge badge-pill badge-success">{{
                  selectedFields.length
                }}</span>
                Fields
              </button>
            </div>
          </div> -->
        </div>
      </div>
      <button
        v-if="showAddition"
        type="button"
        class="btn btn-success btn-lg btn-block"
        @click="handleSubmitFertilizer"
      >
        {{ showCopy ? "Copy" : "Submit" }} Fertilizer <i class="fa fa-plus" />
      </button>
    </div>
  </b-td>
</template>
<script>
import { cloneDeep } from "lodash"
import { mapActions, mapGetters } from "vuex"

import FertilizerRow from "@/components/spreadsheet/rows/FertilizerRow"
import { Defaults, Filter } from "@/store/modules"

export default {
  name: "FertilizerTable",
  components: {
    FertilizerRow,
  },
  props: {
    cropId: { type: Number },
    fieldId: { type: String },
    fertilizerData: { type: Array },
    rowNum: { type: Number },
    year: { type: String },
    years: { type: Array },
  },
  data() {
    return {
      showAddition: false,
      showCopy: false,
      fertilizerToAdd: {
        N: { source: "User Input", value: null },
        P: { source: "User Input", value: null },
        K: { source: "User Input", value: null },
        S: { source: "User Input", value: null },
        ammonium_pct: { source: "User Input", value: null },
        // amount === fertRate
        amount: { source: "User Input", value: null },
        cost: { source: "User Input", value: null },
        crop_id: { source: "User Input", value: null },
        date: { source: "User Input", value: null },
        fert_rate: { source: "User Input", value: null },
        inhibitor: { source: "User Input", value: null },
        method: { source: "User Input", value: null },
        // method_type: { source: "User Input", value: null },
        product: { source: "User Input", value: null },
        slow_release: { source: "User Input", value: null },
        unit: { source: "User Input", value: null },
        vrt: { source: "User Input", value: null },
        management_plan: { source: "User Input", value: null },
      },
      fieldYearToCopy: [],
      yearSelected: [],
    }
  },
  computed: {
    ...mapGetters({
      allFields: Filter.Getters.getFields,
      selectedFields: Filter.Getters.getSelectedFields,
    }),
  },
  methods: {
    ...mapActions({
      updateMultipleFieldSettings: Defaults.Actions.updateMultipleFieldSettings,
    }),
    handleAddFieldsInFocus() {
      this.selectedFields.forEach(field => {
        if (
          !this.fieldYearToCopy.includes(this.fieldId) &&
          field.id !== this.fieldId
        ) {
          this.fieldYearToCopy.push(field.id)
        }
      })
    },
    handleCopyFertilizer(fertilizerToCopy) {
      this.showAddition = true
      this.showCopy = true

      this.fertilizerToAdd = cloneDeep(fertilizerToCopy)
    },
    handleSubmitFertilizer() {
      // TODO Re-enable for multi-field add/copy
      // if (this.showAddition && !this.showCopy) {
      //   this.fieldYearToCopy.push(this.fieldId)
      // }
      this.fieldYearToCopy.push(this.fieldId)
      this.fertilizerToAdd["crop_id"] = {
        source: "User Input",
        value: this.cropId,
      }
      this.updateMultipleFieldSettings({
        cropId: this.cropId,
        dataCategory: "fert_applications",
        fieldIds: this.fieldYearToCopy,
        rowData: this.fertilizerToAdd,
        years: this.yearSelected,
      })
      this.resetFertilizerToAdd()
    },
    resetFertilizerToAdd() {
      this.fertilizerToAdd = {
        N: { source: "User Input", value: null },
        P: { source: "User Input", value: null },
        K: { source: "User Input", value: null },
        S: { source: "User Input", value: null },
        ammonium_pct: { source: "User Input", value: null },
        // amount === fertRate
        amount: { source: "User Input", value: null },
        cost: { source: "User Input", value: null },
        crop_id: { source: "User Input", value: null },
        date: { source: "User Input", value: null },
        fert_rate: { source: "User Input", value: null },
        inhibitor: { source: "User Input", value: null },
        // method: { source: "User Input", value: this.applicationMethod },
        method: { source: "User Input", value: null },
        product: { source: "User Input", value: null },
        slow_release: { source: "User Input", value: null },
        unit: { source: "User Input", value: null },
      }
      this.showAddition = false
      this.showCopy = false
      this.fieldYearToCopy = []
      this.yearSelected = [this.year]
    },
    handleBadInput(errorText) {
      this.$emit("bad-input", errorText)
    },
  },
  mounted() {
    if (this.year !== null) this.yearSelected.push(this.year)
  },
}
</script>
<style scoped>
.fert-bg {
  background-color: #dae6e7;
}

.fert-bg-dark {
  background-color: #9bbdbf;
}

.gray-bg {
  background-color: #f6f7fb !important;
}

.addition-container {
  padding-left: 40px;
  padding-right: 84px;
}

.add-icon {
  background-color: #7774e7;
  color: white;
  cursor: pointer;
  padding-left: 14px;
}

.add-icon:hover,
.add-icon:focus {
  color: #ffffff;
  background-color: #8c8aeb;
}

.add-text {
  font-weight: 500;
  font-size: 14px;
}

.cell-table {
  padding: 0;
}

.complete {
  background-color: #00a200;
}

.arva-generated {
  background-color: #fedb80;
}

.delete {
  color: #cc0101;
}

/* v-select-old widths */
.field-select {
  width: 480px;
}

.year-select {
  background-color: white !important;
  width: 180px;
  font-size: 13px;
}

/* column sizing */
.crop-column {
  min-width: 160px;
  max-width: 160px;
}

.date-column {
  min-width: 172px;
  max-width: 172px;
}

.method-column {
  min-width: 160px;
  max-width: 160px;
}

.fert-column {
  min-width: 240px;
  max-width: 240px;
}

.rate-column {
  min-width: 90px;
  max-width: 90px;
}

.unit-column {
  min-width: 130px;
  max-width: 130px;
}

.cost-column {
  min-width: 120px;
  max-width: 120px;
}

.npks-column {
  min-width: 100px;
  max-width: 100px;
}

.ammon-column {
  min-width: 120px;
  max-width: 120px;
}

.slow-column {
  min-width: 120px;
  max-width: 120px;
}

.inhib-column {
  min-width: 90px;
  max-width: 90px;
}

.icon-column {
  min-width: 40px;
  max-width: 40px;
}

.icon-copy-column {
  min-width: 44px;
  max-width: 44px;
}

/* table line-height styling */
/* .fertilizer-table .tr {
  line-height: 20px;
} */
</style>
