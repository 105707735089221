<template>
  <div>
    <Permissions package="sustainability" />
    <div v-if="showSustainability">
      <div class="row">
        <div class="col">
          <div class="d-flex mb-3">
            <DashboardTitle
              :title="`Soil Sampling in ${year}`"
              faClass="fa-columns"
            />
            <v-btn v-once class="ml-auto" text @click="goBack">
              <v-icon>mdi-chevron-left</v-icon>
              Return To Practices
            </v-btn>
          </div>
        </div>
      </div>
      <v-card class="my-4" elevation="0" v-if="showLoadingView">
        <v-progress-circular
          class="mt-4"
          :size="100"
          :width="8"
          color="green"
          indeterminate
        >
        </v-progress-circular>
        <div>
          <h3>Loading soil sampling information...</h3>
        </div>
      </v-card>
      <div v-if="selectedFields.length > MAX_DEFAULT_FIELDS">
        <v-card elevation="0">
          <v-card-text class="text-center">
            <span class="text-h4">
              Please select fewer than {{ MAX_DEFAULT_FIELDS }} fields using the
              top navigation tools.
            </span>
          </v-card-text>
        </v-card>
      </div>
      <div v-else-if="selectedFields.length > 0">
        <v-card class="my-4" elevation="0" v-if="showSaveView">
          <v-progress-circular
            class="mt-4"
            :size="100"
            :width="8"
            color="green"
            indeterminate
          >
          </v-progress-circular>
          <div>
            <h3>Saving your soil sampling information...</h3>
          </div>
        </v-card>
        <v-card class="my-4 px-5 py-5" color="grey lighten-4" elevation="0">
          <h3
            v-if="
              editedFieldIds[year]?.size == 0 &&
              summaryFields[year]?.length == 0 &&
              failedFields[year]?.length == 0
            "
          >
            {{
              `Add your soil sampling information in ${year}
            below.`
            }}
          </h3>
          <h3 v-if="editedFieldIds[year]?.size > 0">
            Unsaved fields in year {{ year }}
          </h3>
          <div v-if="editedFieldIds[year]?.size > 0">
            <v-chip
              v-for="fieldId in editedFieldIds[year]"
              :key="fieldId"
              class="mx-1"
              color="gray lighten-1"
            >
              {{
                soilSamplingSettingsByField[fieldIdToSetting[fieldId]].fieldName
              }}
            </v-chip>
          </div>
          <div v-if="summaryFields[year]?.length > 0">
            <h3>Successfully saved fields for year {{ year }}</h3>
            <v-chip
              v-for="fieldName in summaryFields[year]"
              :key="fieldName"
              class="mx-1"
              color="green"
              text-color="white"
            >
              {{ fieldName }}
            </v-chip>
          </div>
          <div v-if="failedFields[year]?.length > 0">
            <h3>Failed to save fields for year {{ year }}</h3>
            <v-chip
              v-for="fieldName in failedFields[year]"
              :key="fieldName"
              class="mx-2"
              color="red lighten-3"
              text-color="white"
            >
              {{ fieldName }}
            </v-chip>
          </div>
        </v-card>
        <v-row class="mt-4 mb-2 row-margin-correct">
          <div>
            <span class="text-h5 ml-1">
              {{ soilSamplingSettingsByField.length }} fields
            </span>
            <!-- <span class="text-h5 ml-2">
              ({{ dataCompletionForYear }}% Complete)
            </span> -->
          </div>
          <div class="ml-auto d-flex">
            <div class="select-year" v-if="yearBannerVisible">
              <span class="close-icon" @click="yearBannerVisible = false"
                >×</span
              >
              <span>Select year to add records from other date ranges</span>
            </div>
            <div>
              <v-select
                :items="yearListModified"
                :value="year"
                @change="handleSeasonSelection"
                outlined
                dense
                hide-details
                class="mr-3"
                style="width: 110px"
              ></v-select>
            </div>
            <div class="text-container ml-2">
              <v-text-field
                outlined
                dense
                hide-details
                v-model="tableFilterText"
                label="Filter Fields"
              ></v-text-field>
            </div>
          </div>
        </v-row>

        <v-row class="pt-3 overflow-x-auto">
          <v-col>
            <v-simple-table class="mx-4 mb-16">
              <thead>
                <tr>
                  <th>
                    <div class="d-flex">
                      <v-simple-checkbox
                        color="blue"
                        v-model="selectAllBox"
                        @input="handleSelectAll"
                      ></v-simple-checkbox>
                    </div>
                  </th>
                  <th />
                  <th class="text-overline">Name</th>
                  <th class="text-overline">Farm</th>
                  <th class="text-overline">Locked</th>
                  <th class="text-overline">
                    Soil Sample Date<span class="red--text">*</span>
                  </th>
                  <th class="text-overline">
                    Soil Sample Type<span class="red--text">*</span>
                  </th>
                  <th class="text-overline">Actions</th>
                  <th></th>
                  <th class="text-overline">Data Requirement</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(
                    field, fieldIndex
                  ) in soilSamplingSettingsByFieldFiltered"
                  :key="field.fieldId"
                  :class="{
                    'light-blue lighten-5': fieldsInSelection.includes(
                      parseInt(field.fieldId)
                    ),
                  }"
                >
                  <td>
                    <v-simple-checkbox
                      color="blue"
                      :value="
                        fieldsInSelection.includes(parseInt(field.fieldId))
                      "
                      :disabled="
                        disableCheckbox(field.noYearData) || field.fieldLocked
                      "
                      @input="fieldChecked(field, $event)"
                    ></v-simple-checkbox>
                  </td>
                  <td>
                    <img :src="field.fieldImg" style="width: 50px" />&nbsp;
                  </td>
                  <td class="low-space">{{ field.fieldName }}</td>
                  <td class="overflow-hidden">{{ field.farmName }}</td>
                  <td>
                    <v-icon
                      size="20"
                      v-if="field.fieldLocked"
                      :color="field.fieldLocked ? 'red' : 'green'"
                    >
                      {{ field.fieldLocked ? "mdi-lock" : "mdi-lock-open" }}
                    </v-icon>
                  </td>
                  <td class="py-1 medium-column" v-if="!field.noYearData">
                    <div class="d-flex flex-column">
                      <div
                        class="mx-0 d-flex flex-row"
                        dense
                        v-for="(soilSample, index) in field.soilSampling"
                        :key="index"
                      >
                        <v-menu
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          :ref="`menu${fieldIndex}-${index}`"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              :value="
                                getItemValue(field.fieldId, index, 'date')
                              "
                              @input="
                                setItemValue(
                                  field.fieldId,
                                  index,
                                  'date',
                                  $event
                                )
                              "
                              readonly
                              clearable
                              :hide-details="true"
                              v-bind="attrs"
                              v-on="on"
                              append-icon="mdi-calendar-outline"
                              class="soil-sample-date"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            :min="year + '-01-01'"
                            :max="year + '-12-31'"
                            :value="
                              getItemValue(field.fieldId, index, 'date') ||
                              `${year}-01-01`
                            "
                            @input="
                              setItemValue(
                                field.fieldId,
                                index,
                                'date',
                                $event,
                                true
                              )
                              $refs[`menu${fieldIndex}-${index}`][0].save()
                            "
                            scrollable
                          ></v-date-picker>
                        </v-menu>
                      </div>
                    </div>
                  </td>
                  <td class="py-1 medium-column" v-if="!field.noYearData">
                    <div class="d-flex flex-column">
                      <div
                        class="mx-0 d-flex flex-row py-1"
                        v-for="(soilSample, index) in field.soilSampling"
                        :key="index"
                      >
                        <v-select
                          :items="SOIL_SAMPLE_TYPE_CHOICES"
                          item-text="display_name"
                          item-value="value"
                          clearable
                          :value="getItemValue(field.fieldId, index, 'type')"
                          @input="
                            setItemValue(
                              field.fieldId,
                              index,
                              'type',
                              $event,
                              true
                            )
                          "
                          hide-details="true"
                          dense
                          outlined
                        ></v-select>
                      </div>
                    </div>
                  </td>
                  <td class="py-1 small-column" v-if="!field.noYearData">
                    <div class="d-flex flex-column">
                      <div
                        class="mx-0 d-flex flex-row py-1"
                        v-for="(soilSample, index) in field.soilSampling"
                        :key="index"
                      >
                        <v-btn
                          icon
                          small
                          color="red"
                          class="py-5"
                          @click="removeSoilSample(field.fieldId, index)"
                          v-if="field.soilSampling.length > 1"
                        >
                          <v-icon>mdi-trash-can-outline</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </td>
                  <td class="py-1 small-column" v-if="!field.noYearData">
                    <v-btn
                      icon
                      small
                      color="green"
                      @click="addSoilSample(field.fieldId)"
                      :disabled="field.soilSampling.length >= 4"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </td>
                  <td class="small-column">
                    <v-chip
                      label
                      v-if="qualifiedYearsForFields[field.fieldId].length > 0"
                      color="green lighten-3"
                      class="font-weight-bold"
                    >
                      {{
                        qualifiedYearsForFields[field.fieldId].length == 1
                          ? `Sampled in ${
                              qualifiedYearsForFields[field.fieldId][0]
                            }`
                          : `Sampled in ${
                              qualifiedYearsForFields[field.fieldId].length
                            } years`
                      }}
                    </v-chip>
                    <v-chip
                      v-else
                      label
                      class="font-weight-bold"
                      color="yellow lighten-3"
                      >Incomplete</v-chip
                    >
                  </td>
                  <td class="py-2" colspan="100%" v-if="field.noYearData">
                    <div @click="handleShowRedirect(field)">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" color="red">
                            mdi-alert-circle
                          </v-icon>
                        </template>
                        <span>
                          This field doesn't have data for this year. Add
                          another year in the data spreadsheet or upload data
                          from this year.
                        </span>
                      </v-tooltip>
                      <span class="ml-2 cursor red--text text-bold">
                        No Data for Year
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-row>

        <!-- No Fields Selected Alert -->
        <AlertErrorModal
          v-if="alertErrorModalOpen"
          titleText="No Fields Selected"
          :errorMessage="[
            'You currently have no fields selected to save soil sampling data for. Please select a field to continue.',
          ]"
          @close-modal="alertErrorModalOpen = false"
        />

        <!-- Redirect -->
        <WizardRedirectModal
          v-if="showRedirectModal"
          :redirectField="redirectField"
          :year="year"
          @close-template-modal="handleCloseRedirect"
          @redirect-confirm="handleRedirectConfirm"
        />

        <ConfirmModalWithCheckbox
          v-if="showSaveConfirmModal"
          :titleText="distributeConfirmTitle"
          :confirmText="distributeConfirmMessages.join('<br /><br />')"
          @confirm="handleDistribute"
          @close-modal="handleCancelDistribute"
          @dontask-changed="handleDontAskChanged"
        />
        <ArvaModal
          v-if="confirmBeforeLeavingMessages.length"
          name="confirmModal"
          title="Unsaved Changes"
          @close-modal="cancelLeaving"
        >
          <div
            class="confirm-text"
            v-html="confirmBeforeLeavingMessages.join('<br /><br />')"
          ></div>

          <div class="row footer-row">
            <div class="col-12 btn-row d-flex justify-content-end">
              <v-btn class="ma-1" @click="closeWithoutSaving">
                Close without Saving
              </v-btn>

              <v-btn
                class="ma-1"
                color="green lighten-4"
                @click="cancelLeaving"
              >
                Stay on Page
              </v-btn>
            </div>
          </div>
        </ArvaModal>
      </div>
      <div v-else>
        <v-card elevation="0">
          <v-card-text class="text-center">
            <span class="text-h4">
              No Fields Selected. Please select a field to apply soil sampling
              data.
            </span>
          </v-card-text>
        </v-card>
      </div>
      <div class="save-footer py-1" v-if="editedFieldIds[year]?.size > 0">
        <div class="d-flex align-center justify-space-between">
          <h3 class="text-button text-white">Unsaved changes in {{ year }}</h3>
          <div>
            <v-btn class="cancel-btn my-3" outlined @click="cancelChanges"
              >Cancel</v-btn
            >
            <v-btn class="save-btn" outlined @click="handleSave">Save</v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash"
import { mapActions, mapGetters, mapMutations, mapState } from "vuex"
import { Defaults, Organization, Filter } from "@/store/modules"
import { MAX_DEFAULT_FIELDS } from "@/constants/defaults"
import { SOIL_SAMPLE_TYPE_CHOICES } from "@/constants/defaults"
import AlertErrorModal from "@/components/modals/AlertErrorModal"
import WizardRedirectModal from "@/components/modals/WizardRedirectModal"
import ConfirmModalWithCheckbox from "@/components/modals/ConfirmModalWithCheckbox"
import ArvaModal from "@/components/modals/ArvaModal"
import DashboardTitle from "@/components/dashboard/DashboardTitle"
import Permissions from "@/components/permissions/Permissions"

const unloadHandler = e => {
  e.preventDefault()
  e.returnValue = "Unsaved changes"
}

export default {
  name: "SoilSamplingWizardTable",
  components: {
    AlertErrorModal,
    ArvaModal,
    ConfirmModalWithCheckbox,
    DashboardTitle,
    Permissions,
    WizardRedirectModal,
  },
  data() {
    return {
      MAX_DEFAULT_FIELDS,
      SOIL_SAMPLE_TYPE_CHOICES,
      soilSamplingSettingsByField: [],
      editedFieldIds: {},
      fieldsInSelection: [],
      tableFilterText: "",
      selectAllBox: false,
      showRedirectModal: false,
      alertErrorModalOpen: false,
      redirectField: null,
      showSaveView: false,
      showLoadingView: false,
      showSaveConfirmModal: false,
      distributeConfirmTitle: "",
      distributeConfirmMessages: [],
      distributeValue: {},
      dontAskAgain: false,
      pendingNavigation: null,
      yearBannerVisible: true,
      confirmBeforeLeavingMessages: [],
      summaryFields: {},
      failedFields: {},
    }
  },
  computed: {
    ...mapState({
      opsSettings: state => state.Defaults.newFieldSettings,
      years: state => state.Organization.organization.years,
      showSustainability: state =>
        state.Organization.organization.showSustainability,
    }),
    ...mapGetters({
      dataCompletionMaster: Defaults.Getters.getWizardDataCompletionSummary,
      selectedFields: Filter.Getters.getSelectedFields,
      year: Organization.Getters.getYear,
    }),
    selectedFieldIds() {
      return this.selectedFields.map(f => f.id)
    },
    fieldIdToSetting() {
      const settingMap = {}
      this.soilSamplingSettingsByField.forEach((setting, index) => {
        settingMap[setting.fieldId] = index
      })
      return settingMap
    },
    soilSampleCompletionRangeStart() {
      // 3 years from the current date
      return new Date().getFullYear() - 3
    },
    dataCompletionForYear() {
      if (
        !this.year ||
        this.selectedFields.length == 0 ||
        !this.dataCompletionMaster ||
        !("SoilSampling" in this.dataCompletionMaster)
      ) {
        return 0
      }

      let yearDataComps = this.dataCompletionMaster["SoilSampling"].map(o => {
        if ("by_year" in o.data_comp) {
          let temp = o.data_comp.by_year.find(
            yearObj => yearObj.year == this.year
          )
          if (temp) {
            return temp.avg_for_year
          }
        }
        return 0
      })
      let average = array => array.reduce((a, b) => a + b) / array.length
      return yearDataComps.length > 0 ? Math.round(average(yearDataComps)) : 0
    },
    qualifiedYearsForFields() {
      let comps = {}
      const userSampling = s =>
        s.date.value !== null &&
        s.date.source === "User Input" &&
        s.type.value !== null &&
        s.type.source === "User Input"

      const completionForField = fieldSettings => {
        const qualifiedYears = []
        for (const checkYear of this.yearListModified) {
          if (checkYear < this.soilSampleCompletionRangeStart) {
            continue
          }
          const yearData = fieldSettings.year_data.find(
            y => parseInt(y.year) == parseInt(checkYear)
          )
          if (yearData) {
            const allSoilSampling = yearData.cultivations
              .map(c => c.soil_sampling)
              .flat()
              .filter(s => s != null)

            if (allSoilSampling.some(s => userSampling(s))) {
              qualifiedYears.push(checkYear)
            }
          }
        }
        return qualifiedYears
      }

      for (const entry of Object.entries(this.opsSettings)) {
        comps[entry[0]] = completionForField(entry[1])
      }

      return comps
    },
    fieldLocks() {
      let fieldLocks = {}
      for (const field of this.soilSamplingSettingsByField) {
        fieldLocks[field.fieldId] = field[this.year].fieldLocked
      }
      return fieldLocks
    },
    soilSamplingSettingsByFieldFiltered() {
      let arrayToReturn = _.cloneDeep(
        this.soilSamplingSettingsByField
          .map(f => f[this.year])
          .filter(f => this.selectedFieldIds.includes(Number(f.fieldId)))
      )

      if (this.tableFilterText) {
        const searchString = this.tableFilterText.toLocaleLowerCase()
        arrayToReturn = arrayToReturn.filter(field =>
          field.fieldName.toLowerCase().includes(searchString)
        )
      }

      return _.orderBy(arrayToReturn, ["fieldName"], ["desc"])
    },
    yearList() {
      if (!this.years || typeof this.years === "undefined") {
        return ["No Data Found"]
      }

      if (_.isEmpty(this.years)) {
        let currentDate = new Date()
        let currentYear = currentDate.getFullYear()
        return [
          currentYear - 4,
          currentYear - 3,
          currentYear - 2,
          currentYear - 1,
          currentYear,
        ]
      }

      return this.years.map(y => Number(y).toString())
    },
    yearListModified() {
      return this.yearList.filter(o => !isNaN(o))
    },
  },
  methods: {
    ...mapActions({
      updateYear: Organization.Actions.updateYear,
      updateSoilSampling: Defaults.Actions.updateSoilSampling,
      fetchCarbonSettingsByField: Defaults.Actions.fetchCarbonSettingsByField,
      updateWizardDataCompletionSummary:
        Defaults.Actions.updateWizardDataCompletionSummary,
    }),
    goBack() {
      this.$router.push("/historical-practices")
    },
    getItemValue(fieldId, soilSampleIndex, prop) {
      const idx = this.fieldIdToSetting[fieldId]
      const soilSample =
        this.soilSamplingSettingsByField[idx][this.year].soilSampling[
          soilSampleIndex
        ]

      if (
        soilSample[prop].source != "User Input" &&
        soilSample[prop].source != "shapefile"
      ) {
        return null
      }

      return soilSample[prop].value
    },
    setItemValue(fieldId, soilSampleIndex, prop, value, distribute = false) {
      if (value === this.getItemValue(fieldId, soilSampleIndex, prop)) {
        return
      }

      window.addEventListener("beforeunload", unloadHandler)

      this.editedFieldIds[this.year].add(parseInt(fieldId))
      this.summaryFields[this.year] = []
      this.failedFields[this.year] = []

      const idx = this.fieldIdToSetting[fieldId]
      this.soilSamplingSettingsByField[idx][this.year].soilSampling[
        soilSampleIndex
      ][prop].value = value
      this.soilSamplingSettingsByField[idx][this.year].soilSampling[
        soilSampleIndex
      ][prop].source = "User Input"

      if (soilSampleIndex == 0 && distribute) {
        this.confirmDistributeItemValue(prop, value, fieldId)
      }
    },
    confirmDistributeItemValue(prop, value, originFieldId) {
      this.distributeConfirmMessages = []

      if (
        this.fieldsInSelection.length === 0 ||
        (this.fieldsInSelection.length === 1 &&
          this.fieldsInSelection.includes(Number(originFieldId)))
      ) {
        return
      }

      const otherFields = this.fieldsInSelection.filter(
        f => f !== Number(originFieldId)
      )

      if (otherFields.length === 0) {
        return
      }

      this.distributeConfirmTitle = `You're about to update ${
        otherFields.length
      } other field${otherFields.length > 1 ? "s" : ""}, continue?`

      this.distributeValue = { prop, value, otherFields }

      if (!this.dontAskAgain) {
        this.showSaveConfirmModal = true
      } else {
        this.handleDistribute()
      }
    },
    handleDistribute() {
      const { prop, value, otherFields } = this.distributeValue

      for (const fieldId of otherFields) {
        const idx = this.fieldIdToSetting[fieldId]
        const field = this.soilSamplingSettingsByField[idx][this.year]

        if (field.soilSampling.length === 1) {
          field.soilSampling[0][prop].value = value
          field.soilSampling[0][prop].source = "User Input"
          this.editedFieldIds[this.year].add(parseInt(fieldId))
        }
      }

      this.distributeValue = {}
      this.showSaveConfirmModal = false
      this.distributeConfirmMessages = []
      this.distributeConfirmTitle = ""
    },
    handleCancelDistribute() {
      this.distributeValue = {}
      this.showSaveConfirmModal = false
      this.distributeConfirmMessages = []
      this.distributeConfirmTitle = ""
    },
    handleDontAskChanged(dontAskAgain) {
      this.dontAskAgain = dontAskAgain
    },
    disableCheckbox(noYearData) {
      return noYearData
    },
    fieldChecked(field, e) {
      const fieldId = parseInt(field.fieldId)
      if (this.fieldsInSelection.includes(fieldId) && e === false) {
        this.fieldsInSelection = this.fieldsInSelection.filter(
          f => f !== fieldId
        )
      }
      if (e === true) {
        this.fieldsInSelection.push(fieldId)
      }
    },
    async handleSave() {
      this.showSaveView = true

      let updateSoilSamplings = []
      for (const editedFieldId of this.editedFieldIds[this.year]) {
        if (this.selectedFieldIds.includes(parseInt(editedFieldId))) {
          const field =
            this.soilSamplingSettingsByField[
              this.fieldIdToSetting[editedFieldId]
            ]

          if (!field.noYearData && !field.fieldLocked) {
            updateSoilSamplings.push({
              fieldId: field.fieldId,
              promise: this.updateSoilSampling({
                fieldId: field.fieldId,
                year: this.year,
                soilSamplingPayload: field[this.year].soilSampling,
              }),
            })
          }
        }
      }

      const results = await Promise.allSettled(
        updateSoilSamplings.map(u => u.promise)
      )

      const failedFields = updateSoilSamplings
        .filter((item, index) => results[index].status === "rejected")
        .map(item => parseInt(item.fieldId))
      const successFields = updateSoilSamplings
        .filter((item, index) => results[index].status === "fulfilled")
        .map(item => parseInt(item.fieldId))

      for (const successFieldId of successFields) {
        this.editedFieldIds[this.year].delete(successFieldId)
      }

      const successFieldNames = successFields.map(
        id =>
          this.soilSamplingSettingsByField[this.fieldIdToSetting[id]].fieldName
      )
      const failedFieldNames = failedFields.map(
        id =>
          this.soilSamplingSettingsByField[this.fieldIdToSetting[id]].fieldName
      )

      this.summaryFields[this.year] = successFieldNames
      this.failedFields[this.year] = failedFieldNames
      this.showSaveView = false

      if (!Object.values(this.editedFieldIds).some(set => set.size > 0)) {
        window.removeEventListener("beforeunload", unloadHandler)
      }

      this.updateWizardDataCompletionSummary({
        fieldIds: this.selectedFields.map(x => x.id),
      })
    },
    handleSeasonSelection(newYear) {
      this.updateYear(newYear)
    },
    handleSelectAll(e) {
      if (e === false) this.fieldsInSelection = []
      if (e === true) {
        this.fieldsInSelection = this.selectedFields
          .map(f => f.id)
          .filter(f => !this.fieldLocks[f])
      }
    },
    handleShowRedirect(field) {
      this.redirectField = field
      this.showRedirectModal = true
    },
    handleCloseRedirect() {
      this.showRedirectModal = false
      this.redirectField = null
    },
    handleRedirectConfirm() {
      this.$router.push(`/data-spreadsheet/${this.redirectField.fieldId}`)
    },
    assembleSoilSamplingSettingsSingleField(opsDefault, onlyYear = null) {
      const opsDict = {}

      const assembleYears = onlyYear ? [onlyYear] : this.yearListModified

      for (const yearInConsideration of assembleYears) {
        const fieldKey = opsDefault[0]
        const selField = this.selectedFields.find(field => field.id == fieldKey)
        if (!selField) {
          return
        }

        const fieldSettingObj = opsDefault[1]
        const idSettings = {
          fieldId: fieldKey,
          fieldName: selField.name,
          farmName: selField.farm.name,
          fieldImg: selField.boundary_image,
          opsId: fieldSettingObj.operation_id,
        }

        if (!(fieldKey in opsDict)) {
          opsDict[fieldKey] = {}
          opsDict[fieldKey] = idSettings
        }

        const hasYearData = fieldSettingObj.year_data.some(
          y => y.year == yearInConsideration
        )

        fieldSettingObj.year_data.forEach(yearData => {
          if (!hasYearData) {
            opsDict[fieldKey][yearInConsideration] = _.cloneDeep(idSettings)
            opsDict[fieldKey][yearInConsideration]["noYearData"] = true
          } else if (yearData.year == yearInConsideration) {
            opsDict[fieldKey][yearInConsideration] = _.cloneDeep(idSettings)
            opsDict[fieldKey][yearInConsideration]["soilSampling"] = []
            opsDict[fieldKey][yearInConsideration]["noYearData"] = false

            opsDict[fieldKey]["isSelected"] = this.fieldsInSelection.includes(
              parseInt(fieldKey)
            )

            const cultivations = yearData.cultivations
            cultivations.forEach(cultivation => {
              opsDict[fieldKey][yearInConsideration]["fieldLocked"] =
                cultivation.year_data_locked || opsDict[fieldKey["fieldLocked"]]

              cultivation.soil_sampling.forEach(sample => {
                if (
                  sample.date.source != "User Input" &&
                  sample.type.source != "User Input"
                ) {
                  // this prevents two blanks showing up for double-crop fields
                  return
                }
                opsDict[fieldKey][yearInConsideration]["soilSampling"].push(
                  sample
                )
              })
            })

            // Ensure there's at least one record
            if (
              opsDict[fieldKey][yearInConsideration]["soilSampling"].length ===
              0
            ) {
              opsDict[fieldKey][yearInConsideration]["soilSampling"].push({
                date: { value: null, source: "User Input" },
                type: { value: null, source: "User Input" },
              })
            } else {
              // de-duplicate soil samples because they span crops
              const uniqueMap = new Map()
              const deduplicatedSoilSampling = opsDict[fieldKey][
                yearInConsideration
              ]["soilSampling"].filter(item => {
                const key = JSON.stringify({
                  date: item.date,
                  type: item.type,
                })

                if (!uniqueMap.has(key)) {
                  uniqueMap.set(key, item)
                  return true
                }
                return false
              })

              opsDict[fieldKey][yearInConsideration]["soilSampling"] =
                deduplicatedSoilSampling
            }
          }
        })
      }

      return Object.values(opsDict)[0]
    },
    assembleSoilSamplingSettingsByField() {
      Object.entries(_.cloneDeep(this.opsSettings)).forEach(opsDefault => {
        if (!Object.keys(this.fieldIdToSetting).includes(opsDefault[0])) {
          const oneFieldSetting =
            this.assembleSoilSamplingSettingsSingleField(opsDefault)
          if (oneFieldSetting) {
            this.soilSamplingSettingsByField.push(oneFieldSetting)
          }
        }
      })
    },
    cancelChanges() {
      Object.entries(_.cloneDeep(this.opsSettings)).forEach(
        ([fieldId, fieldSettingObj]) => {
          if (fieldId in this.fieldIdToSetting) {
            const index = this.fieldIdToSetting[fieldId]

            const restoredFieldSetting =
              this.assembleSoilSamplingSettingsSingleField(
                [fieldId, fieldSettingObj],
                this.year
              )

            if (restoredFieldSetting && restoredFieldSetting[this.year]) {
              this.soilSamplingSettingsByField[index][this.year] =
                restoredFieldSetting[this.year]
            }
          }
        }
      )
      this.editedFieldIds[this.year] = new Set()
      if (!Object.values(this.editedFieldIds).some(set => set.size > 0)) {
        window.removeEventListener("beforeunload", unloadHandler)
      }
    },
    loadSoilSamplingData() {
      this.assembleSoilSamplingSettingsByField()
    },
    closeWithoutSaving() {
      this.pendingNavigation()
    },
    cancelLeaving() {
      this.confirmBeforeLeavingMessages = []
    },
    addSoilSample(fieldId) {
      const idx = this.fieldIdToSetting[fieldId]
      const field = this.soilSamplingSettingsByField[idx][this.year]

      if (field.soilSampling.length < 4) {
        field.soilSampling.push({
          date: { value: null, source: "User Input" },
          type: { value: null, source: "User Input" },
        })
        this.editedFieldIds[this.year].add(parseInt(fieldId))
      }
    },
    removeSoilSample(fieldId, index) {
      const idx = this.fieldIdToSetting[fieldId]
      const field = this.soilSamplingSettingsByField[idx][this.year]

      if (field.soilSampling.length > 1) {
        field.soilSampling.splice(index, 1)
        this.editedFieldIds[this.year].add(parseInt(fieldId))
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!Object.values(this.editedFieldIds).some(set => set.size > 0)) {
      next()
    } else if (this.confirmBeforeLeavingText) {
      next()
    } else {
      this.pendingNavigation = next
      const unsavedMsgs = Object.entries(this.editedFieldIds)
        .filter(([_, crops]) => crops.size !== 0)
        .map(
          ([year, crops]) =>
            `• ${crops.size} unsaved field${
              crops.size > 1 ? "s" : ""
            } for ${year}`
        )
      this.confirmBeforeLeavingMessages = [
        "You have unsaved changes. Are you sure?",
        ...unsavedMsgs,
      ]
    }
  },
  mounted() {
    this.fieldsInSelection = []

    this.yearList.forEach(year => {
      this.$set(this.editedFieldIds, year, new Set())
      this.$set(this.failedFields, year, [])
      this.$set(this.summaryFields, year, [])
    })

    this.loadSoilSamplingData()
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", unloadHandler)
  },
  watch: {
    opsSettings: {
      handler: async function (newFields, oldFields) {
        this.assembleSoilSamplingSettingsByField()
      },
    },
    yearList: {
      handler: async function (newYears) {
        newYears.forEach(year => {
          if (year in this.editedFieldIds === false) {
            this.$set(this.editedFieldIds, year, new Set())
          }
          if (year in this.summaryFields === false) {
            this.$set(this.summaryFields, year, [])
          }
          if (year in this.failedFields === false) {
            this.$set(this.failedFields, year, [])
          }
        })
      },
    },
    selectedFields: {
      handler: async function (newFields, oldFields) {
        const newFieldIds = newFields.map(f => f.id)
        const oldFieldIds = oldFields.map(f => f.id)

        if (!_.isEqual(newFieldIds, oldFieldIds)) {
          const fieldIds = this.selectedFields.map(f => f.id)
          this.showLoadingView = true
          await this.fetchCarbonSettingsByField({
            fieldIds: fieldIds,
          })
          this.showLoadingView = false
        }
      },
    },
  },
}
</script>

<style scoped>
.min-step-height {
  min-height: 400px;
}

.row-margin-correct {
  margin-left: 0px;
  margin-right: 0px;
}

.text-container {
  width: 200px;
}

.cursor {
  cursor: pointer;
}

.sm-md-column {
  min-width: 120px;
}

.medium-column {
  min-width: 150px;
}

.small-column {
  min-width: 50px;
  width: 50px;
}

.save-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #464b58 !important;
  padding-left: 300px;
}

.save-footer h3 {
  font-size: 1.1rem !important;
}

.save-btn {
  height: 40px !important;
  width: 110px;
  margin-right: 90px;
  background-color: #28a745 !important;
  color: #ffffff !important;
  font-size: 1.1rem !important;
}

.cancel-btn {
  height: 40px !important;
  width: 110px;
  margin-right: 30px;
  color: #c5c9d2 !important;
  font-size: 1.1rem !important;
}

::v-deep .v-data-table__wrapper td,
::v-deep .v-data-table__wrapper th {
  padding: 1px 7px !important;
}

::v-deep .v-data-table__wrapper th {
  line-height: 1.5;
}

::v-deep .v-input--is-focused .v-input__slot {
  background-color: #f4f8fd !important;
}

.footer-row {
  border-top: 1px solid #e9ecef;
  margin: 0px;
}

.btn-row {
  padding: 10px 40px;
}

.confirm-text {
  font-size: 15px;
  font-weight: 500;
  padding: 30px 30px;
}

.low-space {
  line-height: 1.1;
}

.select-year {
  background-color: #e6f3ff;
  border: 1px solid #b8d8ff;
  border-radius: 4px 0 0 4px;
  padding: 8px 25px 8px 12px;
  color: #4a90e2;
  display: flex;
  align-items: center;
  clip-path: polygon(
    0 0,
    calc(100% - 15px) 0,
    100% 50%,
    calc(100% - 15px) 100%,
    0 100%
  );
  margin-right: 10px;
}
.close-icon {
  margin-right: 8px;
  font-weight: bold;
  cursor: pointer;
}
</style>
