<template>
  <div class="row">
    <form @submit.prevent="handleSubmit" novalidate>
      <div class="card">
        <h2
          class="card-heading default-title"
          @click="showInputs = !showInputs"
        >
          Harvest
          <i class="fa fa-chevron-down" v-if="!showInputs" />
          <i class="fa fa-chevron-up" v-else />
        </h2>
        <div class="card-body" v-if="showInputs">
          <div class="row">
            <div class="col">
              <span class="default-input-title">Header Width</span>
              <v-select-old
                class="select-style"
                :options="headerWidthChoices"
                :reduce="o => o.value"
                v-model="harvestData.header_width"
                label="display_name"
              />
            </div>

            <div class="col">
              <span class="default-input-title">Equip. Manufacturer</span>
              <v-select-old
                class="select-style"
                :options="equipmentChoices"
                :reduce="o => o.value"
                label="display_name"
                v-model="harvestData.equipment_manufacturer"
              />
            </div>

            <div class="col">
              <span class="default-input-title">Harvest Date</span>
              <datepicker v-model="harvestData.harvest_date" />
            </div>

            <div class="col">
              <span class="default-input-title">Bushels / Acre</span>
              <div class="input-group">
                <input
                  type="number"
                  class="form-control"
                  v-model="harvestData.bushels_per_acre"
                />
                <div class="input-group-append">
                  <span class="input-group-text">bu/ac</span>
                </div>
              </div>
            </div>

            <div class="col">
              <button
                type="submit"
                class="btn btn-primary update-btn"
                :class="{ disabled: !canUpdate }"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from "vuex"
import DefaultsAPI from "@/api/DefaultsAPI"
import { HEADER_WIDTH_CHOICES, EQUIPMENT_CHOICES } from "@/constants/defaults"
import { successMessage } from "@/utility"
import { Fields } from "@/store/modules"

export default {
  name: "HarvestDataDefaults",
  props: {
    harvestData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showInputs: true,
      headerWidthChoices: HEADER_WIDTH_CHOICES,
      equipmentChoices: EQUIPMENT_CHOICES,
      // update button highlighting
      initialHeaderWidth: null,
      initialEquipmentManufacturer: null,
      initialHarvestDate: null,
      initialBushelsPerAcre: null,
    }
  },

  computed: {
    canUpdate() {
      const {
        header_width,
        equipment_manufacturer,
        harvest_date,
        bushels_per_acre,
      } = this.harvestData
      const {
        initialHeaderWidth,
        initialEquipmentManufacturer,
        initialHarvestDate,
        initialBushelsPerAcre,
      } = this
      return (
        header_width !== initialHeaderWidth ||
        equipment_manufacturer !== initialEquipmentManufacturer ||
        harvest_date !== initialHarvestDate ||
        bushels_per_acre !== initialBushelsPerAcre
      )
    },
  },

  methods: {
    ...mapActions({
      fetchFields: Fields.Actions.fetchFields,
    }),
    async handleSubmit() {
      await DefaultsAPI.update(
        "harvest-defaults",
        this.harvestData.id,
        this.harvestData
      )
      successMessage(this, "Harvest Defaults Updated", "top-right")
      this.fetchFields()
    },
  },

  mounted() {
    const {
      header_width,
      equipment_manufacturer,
      harvest_date,
      bushels_per_acre,
    } = this.harvestData
    this.initialHeaderWidth = header_width
    this.initialEquipmentManufacturer = equipment_manufacturer
    this.initialHarvestDate = harvest_date
    this.initialBushelsPerAcre = bushels_per_acre
  },
}
</script>

<style scoped>
.defaults-container {
  background-color: white;
}

.default-input-title {
  font-size: 14px;
}

.default-title {
  font-size: 16px;
  font-weight: bold;
  color: #515365;
  margin: 0;
  padding-bottom: 0;
  cursor: pointer;
}

.default-title i {
  margin-left: 5px;
}

form {
  width: 100%;
}

.update-btn {
  width: 100%;
  margin: 0;
  margin-top: 17px;
}
</style>
