<template>
  <ArvaModal
    name="fieldToShareModal"
    title="Field To Share"
    :wide="true"
    :width="1000"
    @close-modal="handleCloseModal"
  >
    <!-- Transaction Details Section -->
    <div
      class="highlighted-section"
      :class="{
        'requested-color': isRequested,
        'approved-color': isApproved,
        'expired-color': isExpired,
      }"
    >
      <div class="row ml-3">
        <div class="col">
          <span class="section-title">Sharing Transaction Details</span>
        </div>
      </div>
      <div class="row ml-3">
        <div class="col">
          <span class="default-input-title">Transaction ID:</span>
          <span class="default-input-value">{{ transaction.id }}</span>
        </div>
        <div class="col">
          <span class="default-input-title">Status: </span>
          <span class="default-input-value">{{
            transaction.sharing_status
          }}</span>
        </div>
      </div>
      <div class="row ml-3">
        <div class="col">
          <span class="default-input-title">Corporation To Share:</span>
          <span class="default-input-value">{{
            transaction.received_corp_name
          }}</span>
        </div>
        <div class="col">
          <span class="default-input-title">Organization To Share:</span>
          <span class="default-input-value">{{
            transaction.received_org_name
          }}</span>
        </div>
      </div>
    </div>

    <div class="ml-5">
      <v-simple-table
        class="selector-table"
        fixed-header
        height="380px"
        overflow="auto"
      >
        <thead>
          <tr>
            <th>
              <v-checkbox
                v-model="fieldSelectAllCheckbox"
                @click="selectAllCheckBox"
                :disabled="!isEditable"
                name="selected"
                ref="fieldSelectAllCheckbox"
              />
            </th>
            <th width="300">
              Field
            </th>

            <th width="300">
              Farm
            </th>

            <th width="300">
              Client
            </th>
            <th width="400">
              Organization
            </th>
          </tr>
        </thead>
        <tbody>
          <FieldToShareListRow
            v-for="field in fieldToShare"
            :key="field.id"
            :field="field"
            :isEditable="isEditable"
            @checked="checked"
            ref="fieldToShareListRows"
          />
        </tbody>
      </v-simple-table>
    </div>

    <!-- Save Footer Section -->
    <div v-if="isEditable" class="row ml-3">
      <ArvaModalSaveFooter
        confirmText="Save"
        cancelText="Cancel"
        @on-cancel="handleCloseModal"
        @on-confirm="confirmChanges"
      />
    </div>
  </ArvaModal>
</template>

<script>
import ArvaModal from "@/components/modals/ArvaModal"
import ArvaModalSaveFooter from "@/components/modals/ArvaModalSaveFooter"
import FieldToShareListRow from "@/components/customerAdmin/dataSharing/FieldToShareListRow"

export default {
  name: "FieldToShareModal",
  props: [
    "transaction",
    "corporation",
    "yearOptions",
    "isEditable",
    "fieldToShare",
  ],
  components: {
    ArvaModal,
    ArvaModalSaveFooter,
    FieldToShareListRow,
  },

  data() {
    return {
      fieldIds: [],
      fieldSelectAllCheckbox: false,
    }
  },

  computed: {
    isRequested() {
      return this.transaction.sharing_status === "Requested"
    },
    isApproved() {
      return this.transaction.sharing_status === "Approved"
    },
    isExpired() {
      return this.transaction.sharing_status === "Expired"
    },
  },
  methods: {
    handleCloseModal() {
      this.$emit("close-modal")
    },
    confirmChanges() {
      this.$emit("save-changes", this.fieldIds)
    },
    checked(fieldId) {
      if (this.fieldIds.includes(fieldId)) {
        this.fieldIds = this.fieldIds.filter(id => id !== fieldId)
      } else {
        this.fieldIds.push(fieldId)
      }
    },
    selectAllCheckBox() {
      this.$refs.fieldToShareListRows.forEach(fieldToShareListRow =>
        fieldToShareListRow.setCheckbox(this.fieldSelectAllCheckbox)
      )
    },
  },
}
</script>

<style scoped>
.highlighted-section {
  background-color: #83c48d;
  padding: 10px;
  margin-bottom: 20px;
}

.requested-color {
  background-color: #a2ac1a;
}

.approved-color {
  background-color: #14cd3f;
}

.expired-color {
  background-color: #e42121;
}
.section-title {
  font-weight: bold;
}
.default-input-title {
  font-weight: bold;
  margin-top: 8px;
  display: block;
}
</style>
