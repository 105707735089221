<template>
  <div :class="`messaging-bubble-wrapper ${getBubbleClasses}`">
    <p class="bubble-metadata">{{ getUser }} at {{ new Date(message['date']).toLocaleString() }}</p>

    <div class="messaging-bubble">
      <p>{{ message['content'] }}</p>
    </div>
  </div>
</template>

<script>
import { REVIEW_REJECTED, REVIEW_ACCEPTED, REVIEW_MESSAGE } from "@/constants/evidencing";
import { mapState } from "vuex";
export default {
  name: "MessagingBubble",
  props: {
    message: { required: true },
    isSuperuser: { type: Boolean, required: true }
  },
  data() {
    return {}
  },
  computed: {
    ...mapState({
      user: state => state.User.user,
    }),
    getUser() {
      if (this.user['email'] == this.message['user']['email']) return 'Me';

      if (!this.isSuperuser) {
        if (this.message['user']['is_superuser']) return "Arva Rep";
      }

      return this.message['user']['email']
    },
    getBubbleClasses() {
      const classes = [];

      if (this.message['type'] != REVIEW_MESSAGE) {
        classes.push('acceptance-message');

        if (this.message['type'] == REVIEW_REJECTED) classes.push('rejected');
        if (this.message['type'] == REVIEW_ACCEPTED) classes.push('approved');
      }
      else {
        if (this.message['user']['id'] == this.user['id']) classes.push('from-me');
        else classes.push('from-other');
      }

      return classes.join(' ');
    }
  },
}
</script>

<style scoped>
p {
  color: #000000;
  margin: 0;
  font-size: 16px;
  line-height: 1.5;
}
.messaging-bubble-wrapper {
  display: block;
  width: 100%;
}
.bubble-metadata {
  font-size: 12px;
  line-height: 1;
  margin-bottom: 4px;
}
.messaging-bubble {
  width: 75%;
  padding: 16px;
  background: #FFFFFF;
  margin-bottom: 20px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.25);
}
.from-me .messaging-bubble {
  float: right;
}
.from-me .bubble-metadata {
  text-align: right;
}
.from-other .messaging-bubble {
  float: left;
}
.from-other .bubble-metadata {
  text-align: left;
}
.acceptance-message .messaging-bubble {
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
}
.acceptance-message .messaging-bubble p,
.acceptance-message .bubble-metadata {
  text-align: center;
}
.acceptance-message.rejected .messaging-bubble {
  background: #F4B5B5;
}
.acceptance-message.approved .messaging-bubble {
  background: #D4EDDA;
}
</style>
