<template>
  <ArvaModal
    name="assignedOrgModal"
    title="Assigned Organization Modal"
    @close-modal="handleCloseModal"
  >
    <AlertErrorModal
      v-if="alertErrorModalOpen"
      :titleText="modalTitleText"
      :errorMessage="errorMessage"
      @close-modal="alertErrorModalOpen = false"
    />

    <div class="d-flex" v-if="loading">
      <v-progress-circular indeterminate color="green"></v-progress-circular>
      <span class="ml-2 text-h6">Loading Data...</span>
    </div>

    <div v-else>
      <v-dialog v-model="showModifyPositionModal" width="500">
        <v-card>
          <v-card-title class="text-h6 grey lighten-2">
            Modify Position Modal
          </v-card-title>

          <div class="ml-3 col-lg-11 col-md-4 col-sm-6 col-xs-12">
            <span class="default-input-title">Relative To Organization</span>
            <div class="input-group">
              <v-select
                v-model="selectedRelativeToOrg_"
                :items="orgDropDownOptions"
                item-text="name"
                return-object
                solo
                label="Select Organization"
              >
                <template #item="{ item, attrs, on }">
                  <div
                    :style="locationItemStyle(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ item.name }}
                  </div>
                </template>
              </v-select>
            </div>
          </div>

          <div class="ml-3 col-lg-5 col-md-6 col-sm-6 col-xs-12">
            <span class="mt-3 default-input-title">Position</span>
            <div class="input-group">
              <v-select
                v-model.trim="selectedPosition_"
                :items="positionOptions"
                solo
                dense
                label="Select Position"
              ></v-select>
            </div>
          </div>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="showModifyPositionModal = false"
            >
              Cancel
            </v-btn>
            <v-btn color="primary" text @click="modifyPosition()"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <div class="col-12 scroll-col">
        <v-card>
          <v-toolbar flat>
            <v-tabs grow v-model="tab">
              <v-tabs-slider color="blue"></v-tabs-slider>
              <v-tab v-for="item in tabTitles" :key="item.index">
                {{ item.name }}
              </v-tab>
            </v-tabs>
          </v-toolbar>
          <v-tabs-items v-model="tab">
            <v-tab-item v-for="item in tabTitles" :key="item.index">
              <v-card flat>
                <div v-if="item.name === 'Add New'" class="pt-3 card">
                  <div class="row ml-3 mb-5">
                    <div class="col-lg-11 col-md-4 col-sm-6 col-xs-12">
                      <span class="default-input-title">Corporation</span>
                      <div class="input-group">
                        <input
                          type="text"
                          class="form-control"
                          v-model.trim="selectedCorporation"
                          readonly
                        />
                      </div>
                    </div>
                  </div>

                  <div class="ml-3 col-lg-11 col-md-4 col-sm-6 col-xs-12">
                    <span class="default-input-title"
                      >Relative To Organization</span
                    >
                    <div class="input-group">
                      <v-select
                        v-model="selectedRelativeToOrg"
                        :items="orgDropDownOptions"
                        item-text="name"
                        return-object
                        solo
                        label="Select Organization"
                      >
                        <template #item="{ item, attrs, on }">
                          <div
                            :style="locationItemStyle(item)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{ item.name }}
                          </div>
                        </template>
                      </v-select>
                    </div>
                  </div>

                  <div class="row ml-3 mt-3">
                    <div class="col-lg-5 col-md-6 col-sm-6 col-xs-12">
                      <span class="default-input-title"
                        >New Organization Name</span
                      >
                      <div class="input-group">
                        <input
                          type="text"
                          class="form-control"
                          v-model.trim="newOrgName"
                        />
                      </div>
                    </div>

                    <div class="ml-12 col-lg-5 col-md-6 col-sm-6 col-xs-12">
                      <span class="default-input-title">Position</span>
                      <div class="input-group">
                        <v-select
                          v-model.trim="selectedPosition"
                          :items="positionOptions"
                          solo
                          dense
                          label="Select Position"
                        ></v-select>
                      </div>
                    </div>
                  </div>

                  <div class="row ml-3">
                    <ArvaModalSaveFooter
                      confirmText="Add"
                      cancelText="Cancel"
                      @on-cancel="handleCloseModal"
                      @on-confirm="addNewOrganization"
                    />
                  </div>
                </div>

                <div v-if="item.name === 'Edit Existing'" class="pt-3">
                  <div class="row ml-3 mb-5">
                    <div class="col-lg-11 col-md-4 col-sm-6 col-xs-12">
                      <span class="default-input-title">Corporation</span>
                      <div class="input-group">
                        <input
                          type="text"
                          class="form-control"
                          v-model.trim="selectedCorporation"
                          readonly
                        />
                      </div>
                    </div>
                  </div>

                  <div class="ml-3 col-lg-11 col-md-4 col-sm-6 col-xs-12">
                    <span class="default-input-title">Organization</span>
                    <div class="input-group">
                      <v-select
                        v-model="selectedOrgToEdit"
                        :items="orgDropDownOptions"
                        item-text="name"
                        return-object
                        solo
                        label="Select Organization"
                      >
                        <template #item="{ item, attrs, on }">
                          <div
                            :style="locationItemStyle(item)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{ item.name }}
                          </div>
                        </template>
                      </v-select>
                    </div>
                  </div>

                  <div class="row ml-3 mt-2 mb-3">
                    <div class="col-lg-5 col-md-6 col-sm-6 col-xs-12">
                      <span class="default-input-title">Name</span>
                      <div class="input-group">
                        <input
                          type="text"
                          class="form-control"
                          v-model.trim="orgNameToEdit"
                        />
                      </div>
                    </div>

                    <div
                      class="ml-12 mt-5 col-lg-5 col-md-6 col-sm-6 col-xs-12"
                    >
                      <v-btn
                        class="float-right"
                        @click="openModifyPositionModal()"
                        >Modify Position</v-btn
                      >
                    </div>
                  </div>

                  <ArvaModalSaveFooter
                    confirmText="Save"
                    cancelText="Cancel"
                    @on-cancel="handleCloseModal"
                    @on-confirm="editOrganization"
                  />
                </div>

                <div v-if="item.name === 'Remove Existing'" class="pt-3">
                  <div class="row ml-3 mb-5">
                    <div class="col-lg-11 col-md-4 col-sm-6 col-xs-12">
                      <span class="default-input-title">Corporation</span>
                      <div class="input-group">
                        <input
                          type="text"
                          class="form-control"
                          v-model.trim="selectedCorporation"
                          readonly
                        />
                      </div>
                    </div>
                  </div>

                  <div class="ml-3 mb-3 col-lg-11 col-md-4 col-sm-6 col-xs-12">
                    <span class="default-input-title">Organization</span>
                    <div class="input-group">
                      <v-select
                        v-model="selectedOrgToRemove"
                        :items="orgDropDownOptions"
                        item-text="name"
                        return-object
                        solo
                        label="Select Organization"
                      >
                        <template #item="{ item, attrs, on }">
                          <div
                            :style="locationItemStyle(item)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{ item.name }}
                          </div>
                        </template>
                      </v-select>
                    </div>
                  </div>

                  <ArvaModalSaveFooter
                    confirmText="Remove"
                    cancelText="Cancel"
                    @on-cancel="handleCloseModal"
                    @on-confirm="deleteOrganization"
                  />
                </div>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </div>
    </div>
  </ArvaModal>
</template>

<script>
import { mapState } from "vuex"
import ArvaModal from "@/components/modals/ArvaModal"
import ArvaModalSaveFooter from "@/components/modals/ArvaModalSaveFooter"
import AlertErrorModal from "@/components/modals/AlertErrorModal"
import OrganizationAPI from "@/api/OrganizationAPI"
import UserAPI from "@/api/UserAPI"

export default {
  name: "AssignedOrgModal",
  props: ["adminUserId", "corporation", "organizationOptions"],
  components: {
    ArvaModal,
    ArvaModalSaveFooter,
    AlertErrorModal,
  },

  data() {
    return {
      roleOptions: ["Organization Admin", "Member"],
      statusOptions: ["Active", "Inactive"],
      newOrgName: null,
      showErrorAlert: false,
      errorMessage: [],
      alertErrorModalOpen: false,
      selectedRelativeToOrg: null,
      selectedPosition: "Child Of",
      selectedRelativeToOrg_: null,
      selectedPosition_: "Child Of",
      selectedOrgToEdit: null,
      selectedCorporation: this.corporation.name,
      orgNameToEdit: null,
      selectedOrgToRemove: null,
      modalTitleText: null,
      noDataAvailableText: "No Data Available",
      showModifyPositionModal: false,
      tab: 0,
      tabTitles: [
        { index: 0, name: "Add New" },
        { index: 1, name: "Edit Existing" },
        { index: 2, name: "Remove Existing" },
      ],
      loading: false,
      orgDropDownOptions: this.organizationOptions
        ? this.organizationOptions
        : [],
      orgDropDownOptions_: [],
    }
  },

  computed: {
    ...mapState({
      userRole: state => state.User.userRole,
    }),

    positionOptions() {
      if (this.userRole === "Corporation Admin")
        return ["Child Of", "Sibling Of"]
      return ["Child Of"]
    },
  },

  watch: {
    selectedOrgToEdit() {
      this.orgNameToEdit = this.orgDropDownOptions.filter(
        org => this.selectedOrgToEdit.id === org.id
      )[0].name
    },
    tab() {
      this.refreshOrgOptions()
    },
  },

  methods: {
    locationItemStyle(item) {
      if (!item.depth) return null

      return item.depth > 1
        ? { "padding-left": item.depth + 1 + ".75em" }
        : null
    },

    handleCloseModal() {
      this.$emit("close-modal")
    },

    addNewOrganization() {
      const hasError = this.validateInputs()
      if (!hasError) {
        const payload = {
          admin_user_id: this.adminUserId,
          corporation_id: this.corporation.id,
          selected_relative_to_org: this.selectedRelativeToOrg,
          selected_position: this.selectedPosition,
          new_organization_name: this.newOrgName,
        }
        this.$emit("add-new-organization", payload)
      }
    },

    editOrganization() {
      this.resetError()
      if (!this.orgNameToEdit)
        this.errorMessage.push("Organization Name must be filled out.")

      const payload = {
        admin_user_id: this.adminUserId,
        org_name_to_edit: this.orgNameToEdit,
        selected_org_id: this.selectedOrgToEdit.id,
      }
      this.$emit("edit-organization", payload)
    },

    async openModifyPositionModal() {
      const payload = {
        admin_user_id: this.adminUserId,
        selected_org_id: this.selectedOrgToEdit.id,
      }
      await OrganizationAPI.canModifyPosition(payload.selected_org_id, payload)
        .then(({ data }) => {
          this.orgDropDownOptions_ = data.org_options
          this.showModifyPositionModal = true
        })
        .catch(error => {
          this.$emit("error", error)
        })
    },

    modifyPosition() {
      const hasError = this.validateInputsForModifyPosition()
      if (!hasError) {
        const payload = {
          admin_user_id: this.adminUserId,
          selected_org_id: this.selectedOrgToEdit.id,
          selected_relative_to_org: this.selectedRelativeToOrg_,
          selected_position: this.selectedPosition_,
        }
        this.showModifyPositionModal = false
        this.$emit("modify-position", payload)
      }
    },

    deleteOrganization() {
      this.$emit("remove-organization", this.selectedOrgToRemove.id)
    },

    // Validate all the inputs
    //  Return True if found at least 1 error
    //  Return False if found no error
    validateInputs() {
      this.resetError()
      if (!this.newOrgName)
        this.errorMessage.push("Organization Name must be filled out.")
      if (!this.selectedPosition)
        this.errorMessage.push("Position must be selected.")
      if (!this.selectedRelativeToOrg)
        this.errorMessage.push("Relative To Organization must be selected.")

      if (this.errorMessage.length > 0) {
        this.modalTitleText = "Failed To Add New Organization"
        this.alertErrorModalOpen = true
        return true
      }
      return false
    },

    validateInputsForModifyPosition() {
      this.resetError()
      if (!this.selectedPosition_)
        this.errorMessage.push("Position must be selected.")
      if (!this.selectedRelativeToOrg_)
        this.errorMessage.push("Relative To Organization must be selected.")

      if (this.errorMessage.length > 0) {
        this.modalTitleText = "Failed To Modify New Position Of Organization"
        this.alertErrorModalOpen = true
        return true
      }
      return false
    },

    resetError() {
      this.showErrorAlert = false
      this.errorMessage = []
    },

    async refreshOrgOptions() {
      this.loading = true
      const payload = {
        admin_user_id: this.adminUserId,
      }
      await UserAPI.getOrgOptions(payload).then(({ data }) => {
        this.orgDropDownOptions = data.org_options
      })
      this.loading = false
    },
  },
}
</script>

<style scoped>
.org-options-style {
  max-height: 200px;
  overflow-y: scroll;
}
</style>
