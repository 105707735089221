<template>
  <div>
    <div class="row">
      <div class="col">
        <div
          class="settings-section-title"
          @click="showSoilData = !showSoilData"
        >
          Soil Samples
          <i class="fa fa-chevron-down" v-if="!showSoilData" />
          <i class="fa fa-chevron-up" v-else />
        </div>
      </div>
    </div>
    <div v-if="showSoilData" class="settings-table">
      <SettingsRow
        v-on:clear-input="
          soilSampleData.lab = null
          handleSubmit()
        "
        v-on:submit-row="handleSubmit"
      >
        <template v-slot:title>
          Lab
        </template>
        <template v-slot:display-value>
          {{ displaySetting("lab") }}
        </template>
        <template v-slot:input>
          <v-select-old
            class="select-style flex-fill"
            :options="labChoices"
            :reduce="o => o.value"
            v-model="soilSampleData.lab"
            label="display_name"
            :placeholder="displaySetting('lab')"
          />
        </template>
        <!-- <template v-slot:default-type>
          <div
            v-bind:class="{ 'default-name': isLink('lab') }"
            @click="handleLinkClick('lab')"
          >
            {{ defaultType("lab") }}
            <i v-if="isLink('lab')" class="fa fa-arrow-circle-right" />
          </div>
        </template> -->
      </SettingsRow>

      <SettingsRow
        v-on:clear-input="
          soilSampleData.sample_date = null
          handleSubmit()
        "
        v-on:submit-row="handleSubmit"
      >
        <template v-slot:title>
          Sample Date
        </template>
        <template v-slot:display-value>
          {{ displaySetting("sample_date") | dateSettings }}
        </template>
        <template v-slot:input>
          <datepicker v-model="soilSampleData.sample_date" />
        </template>
        <!-- <template v-slot:default-type>
          <div
            v-bind:class="{ 'default-name': isLink('sample_date') }"
            @click="handleLinkClick('sample_date')"
          >
            {{ defaultType("sample_date") }}
            <i v-if="isLink('sample_date')" class="fa fa-arrow-circle-right" />
          </div>
        </template> -->
      </SettingsRow>

      <SettingsRow
        v-on:clear-input="
          soilSampleData.sample_density = null
          handleSubmit()
        "
        v-on:submit-row="handleSubmit"
      >
        <template v-slot:title>
          Sample Density
        </template>
        <template v-slot:display-value>
          {{ displaySetting("sample_density") }}
        </template>
        <template v-slot:input>
          <v-select-old
            class="select-style flex-fill"
            :options="sampleDensityChoices"
            :reduce="o => o.value"
            v-model="soilSampleData.sample_density"
            label="display_name"
            :placeholder="displaySetting('sample_density')"
          />
        </template>
        <!-- <template v-slot:default-type>
          <div
            v-bind:class="{ 'default-name': isLink('sample_density') }"
            @click="handleLinkClick('sample_density')"
          >
            {{ defaultType("sample_density") }}
            <i
              v-if="isLink('sample_density')"
              class="fa fa-arrow-circle-right"
            />
          </div>
        </template> -->
      </SettingsRow>

      <SettingsRow
        v-on:clear-input="
          soilSampleData.cec = null
          handleSubmit()
        "
        v-on:submit-row="handleSubmit"
        v-on:focus-input="focusInput('cec')"
      >
        <template v-slot:title>
          CEC
        </template>
        <template v-slot:display-value>
          {{ displaySetting("cec") }}
        </template>
        <template v-slot:input="{ handleEnter }">
          <input
            v-model="soilSampleData.cec"
            type="number"
            :placeholder="displaySetting('cec')"
            ref="cec"
            @keyup="handleEnter"
          />
        </template>
        <!-- <template v-slot:default-type>
          <div
            v-bind:class="{ 'default-name': isLink('cec') }"
            @click="handleLinkClick('cec')"
          >
            {{ defaultType("cec") }}
            <i v-if="isLink('cec')" class="fa fa-arrow-circle-right" />
          </div>
        </template> -->
      </SettingsRow>

      <SettingsRow
        v-on:clear-input="
          soilSampleData.pH = null
          handleSubmit()
        "
        v-on:submit-row="handleSubmit"
        v-on:focus-input="focusInput('pH')"
      >
        <template v-slot:title>
          pH
        </template>
        <template v-slot:display-value>
          {{ displaySetting("pH") }}
        </template>
        <template v-slot:input="{ handleEnter }">
          <input
            v-model="soilSampleData.pH"
            type="number"
            :placeholder="displaySetting('pH')"
            ref="pH"
            @keyup="handleEnter"
          />
        </template>
        <!-- <template v-slot:default-type>
          <div
            v-bind:class="{ 'default-name': isLink('pH') }"
            @click="handleLinkClick('pH')"
          >
            {{ defaultType("pH") }}
            <i v-if="isLink('pH')" class="fa fa-arrow-circle-right" />
          </div>
        </template> -->
      </SettingsRow>

      <SettingsRow
        v-on:clear-input="
          soilSampleData.organic_matter = null
          handleSubmit()
        "
        v-on:submit-row="handleSubmit"
        v-on:focus-input="focusInput('organic_matter')"
      >
        <template v-slot:title>
          Organic Matter (%)
        </template>
        <template v-slot:display-value>
          {{ displaySetting("organic_matter") }}
        </template>
        <template v-slot:input="{ handleEnter }">
          <input
            v-model="soilSampleData.organic_matter"
            type="number"
            :placeholder="displaySetting('organic_matter')"
            ref="organic_matter"
            @keyup="handleEnter"
          />
        </template>
        <!-- <template v-slot:default-type>
          <div
            v-bind:class="{ 'default-name': isLink('organic_matter') }"
            @click="handleLinkClick('organic_matter')"
          >
            {{ defaultType("organic_matter") }}
            <i
              v-if="isLink('organic_matter')"
              class="fa fa-arrow-circle-right"
            />
          </div>
        </template> -->
      </SettingsRow>

      <SettingsRow
        v-on:clear-input="
          nitrateAmount = null
          nitrateUnits = null
          clearSetting('nitrate')
        "
        v-on:submit-row="handleSubmit"
        v-on:focus-input="focusInput('nitrate')"
      >
        <template v-slot:title>
          Nitrate
        </template>
        <template v-slot:display-value>
          <span>
            {{ displayNutrientSetting("nitrate", "amount") }}
            {{ displayNutrientSetting("nitrate", "units") }}
          </span>
        </template>
        <template v-slot:input="{ handleEnter }">
          <div class="col">
            <div class="row pb-2">
              <input
                v-model="nitrateAmount"
                type="number"
                :placeholder="displayNutrientSetting('nitrate', 'amount')"
                ref="nitrate"
                @keyup="handleEnter"
              />
            </div>
            <div class="row">
              <v-select-old
                class="select-style flex-fill"
                :options="sampleUnitsChoices"
                :reduce="o => o.value"
                v-model="nitrateUnits"
                label="display_name"
                :placeholder="displayNutrientSetting('nitrate', 'units')"
              />
            </div>
          </div>
        </template>
        <!-- <template v-slot:default-type>
          <div
            v-bind:class="{ 'default-name': isNutrientLink('nitrate') }"
            @click="handleNutrientLinkClick('nitrate')"
          >
            {{ defaultNutrientType("nitrate") }}
            <i
              v-if="isNutrientLink('nitrate')"
              class="fa fa-arrow-circle-right"
            />
          </div>
        </template> -->
      </SettingsRow>

      <SettingsRow
        v-on:clear-input="
          phosphorusAmount = null
          phosphorusUnits = null
          clearSetting('phosphorus')
        "
        v-on:submit-row="handleSubmit"
        v-on:focus-input="focusInput('phosphorus')"
      >
        <template v-slot:title>
          Phosphorus
        </template>
        <template v-slot:display-value>
          <span>
            {{ displayNutrientSetting("phosphorus", "amount") }}
            {{ displayNutrientSetting("phosphorus", "units") }}
          </span>
        </template>
        <template v-slot:input="{ handleEnter }">
          <div class="col">
            <div class="row pb-2">
              <input
                v-model="phosphorusAmount"
                type="number"
                :placeholder="displayNutrientSetting('phosphorus', 'amount')"
                ref="phosphorus"
                @keyup="handleEnter"
              />
            </div>
            <div class="row">
              <v-select-old
                class="select-style flex-fill"
                :options="sampleUnitsChoices"
                :reduce="o => o.value"
                v-model="phosphorusUnits"
                label="display_name"
                :placeholder="displayNutrientSetting('phosphorus', 'units')"
              />
            </div>
          </div>
        </template>
        <!-- <template v-slot:default-type>
          <div
            v-bind:class="{ 'default-name': isNutrientLink('phosphorus') }"
            @click="handleNutrientLinkClick('phosphorus')"
          >
            {{ defaultNutrientType("phosphorus") }}
            <i
              v-if="isNutrientLink('phosphorus')"
              class="fa fa-arrow-circle-right"
            />
          </div>
        </template> -->
      </SettingsRow>

      <SettingsRow
        v-on:clear-input="
          potassiumAmount = null
          potassiumUnits = null
          clearSetting('potassium')
        "
        v-on:submit-row="handleSubmit"
        v-on:focus-input="focusInput('potassium')"
      >
        <template v-slot:title>
          Potassium
        </template>
        <template v-slot:display-value>
          <span>
            {{ displayNutrientSetting("potassium", "amount") }}
            {{ displayNutrientSetting("potassium", "units") }}
          </span>
        </template>
        <template v-slot:input="{ handleEnter }">
          <div class="col">
            <div class="row pb-2">
              <input
                v-model="potassiumAmount"
                type="number"
                :placeholder="displayNutrientSetting('potassium', 'amount')"
                ref="potassium"
                @keyup="handleEnter"
              />
            </div>
            <div class="row">
              <v-select-old
                class="select-style flex-fill"
                :options="sampleUnitsChoices"
                :reduce="o => o.value"
                v-model="potassiumUnits"
                label="display_name"
                :placeholder="displayNutrientSetting('potassium', 'units')"
              />
            </div>
          </div>
        </template>
        <!-- <template v-slot:default-type>
          <div
            v-bind:class="{ 'default-name': isNutrientLink('potassium') }"
            @click="handleNutrientLinkClick('potassium')"
          >
            {{ defaultNutrientType("potassium") }}
            <i
              v-if="isNutrientLink('potassium')"
              class="fa fa-arrow-circle-right"
            />
          </div>
        </template> -->
      </SettingsRow>

      <SettingsRow
        v-on:clear-input="
          chlorideAmount = null
          chlorideUnits = null
          clearSetting('chloride')
        "
        v-on:submit-row="handleSubmit"
        v-on:focus-input="focusInput('chloride')"
      >
        <template v-slot:title>
          Chloride
        </template>
        <template v-slot:display-value>
          <span>
            {{ displayNutrientSetting("chloride", "amount") }}
            {{ displayNutrientSetting("chloride", "units") }}
          </span>
        </template>
        <template v-slot:input="{ handleEnter }">
          <div class="col">
            <div class="row pb-2">
              <input
                v-model="chlorideAmount"
                type="number"
                :placeholder="displayNutrientSetting('chloride', 'amount')"
                ref="chloride"
                @keyup="handleEnter"
              />
            </div>
            <div class="row">
              <v-select-old
                class="select-style flex-fill"
                :options="sampleUnitsChoices"
                :reduce="o => o.value"
                v-model="chlorideUnits"
                label="display_name"
                :placeholder="displayNutrientSetting('chloride', 'units')"
              />
            </div>
          </div>
        </template>
        <!-- <template v-slot:default-type>
          <div
            v-bind:class="{ 'default-name': isNutrientLink('chloride') }"
            @click="handleNutrientLinkClick('chloride')"
          >
            {{ defaultNutrientType("chloride") }}
            <i
              v-if="isNutrientLink('chloride')"
              class="fa fa-arrow-circle-right"
            />
          </div>
        </template> -->
      </SettingsRow>

      <SettingsRow
        v-on:clear-input="
          sulfurAmount = null
          sulfurUnits = null
          clearSetting('sulfur')
        "
        v-on:submit-row="handleSubmit"
        v-on:focus-input="focusInput('sulfur')"
      >
        <template v-slot:title>
          Sulfur
        </template>
        <template v-slot:display-value>
          <span>
            {{ displayNutrientSetting("sulfur", "amount") }}
            {{ displayNutrientSetting("sulfur", "units") }}
          </span>
        </template>
        <template v-slot:input="{ handleEnter }">
          <div class="col">
            <div class="row pb-2">
              <input
                v-model="sulfurAmount"
                type="number"
                :placeholder="displayNutrientSetting('sulfur', 'amount')"
                ref="sulfur"
                @keyup="handleEnter"
              />
            </div>
            <div class="row">
              <v-select-old
                class="select-style flex-fill"
                :options="sampleUnitsChoices"
                :reduce="o => o.value"
                v-model="sulfurUnits"
                label="display_name"
                :placeholder="displayNutrientSetting('sulfur', 'units')"
              />
            </div>
          </div>
        </template>
        <!-- <template v-slot:default-type>
          <div
            v-bind:class="{ 'default-name': isNutrientLink('sulfur') }"
            @click="handleNutrientLinkClick('sulfur')"
          >
            {{ defaultNutrientType("sulfur") }}
            <i
              v-if="isNutrientLink('sulfur')"
              class="fa fa-arrow-circle-right"
            />
          </div>
        </template> -->
      </SettingsRow>

      <SettingsRow
        v-on:clear-input="
          boronAmount = null
          boronUnits = null
          clearSetting('boron')
        "
        v-on:submit-row="handleSubmit"
        v-on:focus-input="focusInput('boron')"
      >
        <template v-slot:title>
          Boron
        </template>
        <template v-slot:display-value>
          <span>
            {{ displayNutrientSetting("boron", "amount") }}
            {{ displayNutrientSetting("boron", "units") }}
          </span>
        </template>
        <template v-slot:input="{ handleEnter }">
          <div class="col">
            <div class="row pb-2">
              <input
                v-model="boronAmount"
                type="number"
                :placeholder="displayNutrientSetting('boron', 'amount')"
                ref="boron"
                @keyup="handleEnter"
              />
            </div>
            <div class="row">
              <v-select-old
                class="select-style flex-fill"
                :options="sampleUnitsChoices"
                :reduce="o => o.value"
                v-model="boronUnits"
                label="display_name"
                :placeholder="displayNutrientSetting('boron', 'units')"
              />
            </div>
          </div>
        </template>
        <!-- <template v-slot:default-type>
          <div
            v-bind:class="{ 'default-name': isNutrientLink('boron') }"
            @click="handleNutrientLinkClick('boron')"
          >
            {{ defaultNutrientType("boron") }}
            <i
              v-if="isNutrientLink('boron')"
              class="fa fa-arrow-circle-right"
            />
          </div>
        </template> -->
      </SettingsRow>

      <SettingsRow
        v-on:clear-input="
          zincAmount = null
          zincUnits = null
          clearSetting('zinc')
        "
        v-on:submit-row="handleSubmit"
        v-on:focus-input="focusInput('zinc')"
      >
        <template v-slot:title>
          Zinc
        </template>
        <template v-slot:display-value>
          <span>
            {{ displayNutrientSetting("zinc", "amount") }}
            {{ displayNutrientSetting("zinc", "units") }}
          </span>
        </template>
        <template v-slot:input="{ handleEnter }">
          <div class="col">
            <div class="row pb-2">
              <input
                v-model="zincAmount"
                type="number"
                :placeholder="displayNutrientSetting('zinc', 'amount')"
                ref="zinc"
                @keyup="handleEnter"
              />
            </div>
            <div class="row">
              <v-select-old
                class="select-style flex-fill"
                :options="sampleUnitsChoices"
                :reduce="o => o.value"
                v-model="zincUnits"
                label="display_name"
                :placeholder="displayNutrientSetting('zinc', 'units')"
              />
            </div>
          </div>
        </template>
        <!-- <template v-slot:default-type>
          <div
            v-bind:class="{ 'default-name': isNutrientLink('zinc') }"
            @click="handleNutrientLinkClick('zinc')"
          >
            {{ defaultNutrientType("zinc") }}
            <i v-if="isNutrientLink('zinc')" class="fa fa-arrow-circle-right" />
          </div>
        </template> -->
      </SettingsRow>

      <SettingsRow
        v-on:clear-input="
          ironAmount = null
          ironUnits = null
          clearSetting('iron')
        "
        v-on:submit-row="handleSubmit"
        v-on:focus-input="focusInput('iron')"
      >
        <template v-slot:title>
          Iron
        </template>
        <template v-slot:display-value>
          <span>
            {{ displayNutrientSetting("iron", "amount") }}
            {{ displayNutrientSetting("iron", "units") }}
          </span>
        </template>
        <template v-slot:input="{ handleEnter }">
          <div class="col">
            <div class="row pb-2">
              <input
                v-model="ironAmount"
                type="number"
                :placeholder="displayNutrientSetting('iron', 'amount')"
                ref="iron"
                @keyup="handleEnter"
              />
            </div>
            <div class="row">
              <v-select-old
                class="select-style flex-fill"
                :options="sampleUnitsChoices"
                :reduce="o => o.value"
                v-model="ironUnits"
                label="display_name"
                :placeholder="displayNutrientSetting('iron', 'units')"
              />
            </div>
          </div>
        </template>
        <!-- <template v-slot:default-type>
          <div
            v-bind:class="{ 'default-name': isNutrientLink('iron') }"
            @click="handleNutrientLinkClick('iron')"
          >
            {{ defaultNutrientType("iron") }}
            <i v-if="isNutrientLink('iron')" class="fa fa-arrow-circle-right" />
          </div>
        </template> -->
      </SettingsRow>
    </div>
  </div>
</template>
<script>
import _ from "lodash"
import { mapActions } from "vuex"
import DefaultsAPI from "@/api/DefaultsAPI"
import {
  LAB_CHOICES,
  SAMPLE_DENSITY_CHOICES,
  SAMPLE_NUTRIENT_CHOICES,
  SAMPLE_UNITS_CHOICES,
} from "@/constants/defaults"
import { Fields } from "@/store/modules"
import SettingsRow from "./SettingsRow"

export default {
  name: "SoilFieldSettings",
  components: {
    SettingsRow,
  },
  props: {
    soilSampleData: { type: Object, required: true },
    soilSampleDefaults: { type: Object, required: true },
    generalDefaults: { type: Object, required: true },
    defaultsName: String,
  },
  data() {
    return {
      // view toggle
      showSoilData: false,
      // initial values
      nitrateAmount: null,
      nitrateUnits: null,
      phosphorusAmount: null,
      phosphorusUnits: null,
      potassiumAmount: null,
      potassiumUnits: null,
      chlorideAmount: null,
      chlorideUnits: null,
      sulfurAmount: null,
      sulfurUnits: null,
      boronAmount: null,
      boronUnits: null,
      zincAmount: null,
      zincUnits: null,
      ironAmount: null,
      ironUnits: null,
      //   dropdown choices
      labChoices: LAB_CHOICES,
      sampleDensityChoices: SAMPLE_DENSITY_CHOICES,
      sampleNutrientChoices: SAMPLE_NUTRIENT_CHOICES,
      sampleUnitsChoices: SAMPLE_UNITS_CHOICES,
    }
  },
  computed: {
    nutrientDict() {
      let dict = {
        nitrate: { amount: this.nitrateAmount, units: this.nitrateUnits },
        phosphorus: {
          amount: this.phosphorusAmount,
          units: this.phosphorusUnits,
        },
        potassium: { amount: this.potassiumAmount, units: this.potassiumUnits },
        chloride: { amount: this.chlorideAmount, units: this.chlorideUnits },
        sulfur: { amount: this.sulfurAmount, units: this.sulfurUnits },
        boron: { amount: this.boronAmount, units: this.boronUnits },
        zinc: { amount: this.zincAmount, units: this.zincUnits },
        iron: { amount: this.ironAmount, units: this.ironUnits },
      }
      return dict
    },
  },
  methods: {
    ...mapActions({
      fetchFields: Fields.Actions.fetchFields,
    }),
    async handleSubmit() {
      let fieldUpdatedNutrients = {}
      for (const idx in this.sampleNutrientChoices) {
        const nutrient = this.sampleNutrientChoices[idx]
        let nutrientSettings = this.nutrientDict[nutrient.value]
        let amount = nutrientSettings.amount
        let units = nutrientSettings.units

        if (amount || units) {
          fieldUpdatedNutrients[nutrient.value] = {
            amount: nutrientSettings.amount,
            units: nutrientSettings.units,
          }
        }
      }

      let nutrientCopy = _.clone(this.soilSampleData.nutrients)
      this.soilSampleData.nutrients = _.assign(
        nutrientCopy,
        fieldUpdatedNutrients
      )

      await DefaultsAPI.update(
        "soil-sample-defaults",
        this.soilSampleData.id,
        this.soilSampleData
      )
      this.fetchFields()
    },
    defaultType(defaultName) {
      if (this.soilSampleData[defaultName]) return "Field Setting"
      if (this.soilSampleDefaults[defaultName]) return this.defaultsName
      return "General Defaults"
    },
    defaultNutrientType(nutrient) {
      if (
        !_.isEmpty(this.soilSampleData.nutrients) &&
        !_.isEmpty(this.soilSampleData.nutrients[nutrient])
      ) {
        return "Field Setting"
      }
      if (
        !_.isEmpty(this.soilSampleDefaults.nutrients) &&
        !_.isEmpty(this.soilSampleDefaults.nutrients[nutrient])
      ) {
        return this.defaultsName
      }
      return "General Defaults"
    },
    displaySetting(settingName) {
      if (this.soilSampleData[settingName])
        return this.soilSampleData[settingName]
      if (this.soilSampleDefaults[settingName])
        return this.soilSampleDefaults[settingName]
      if (this.generalDefaults.soil_sample_data[settingName])
        return this.generalDefaults.soil_sample_data[settingName]
      return "--"
    },
    displayNutrientSetting(nutrient, settingName) {
      if (
        !_.isEmpty(this.soilSampleData.nutrients) &&
        !_.isEmpty(this.soilSampleData.nutrients[nutrient])
      ) {
        return this.soilSampleData.nutrients[nutrient][settingName]
      }
      if (
        !_.isEmpty(this.soilSampleDefaults.nutrients) &&
        !_.isEmpty(this.soilSampleDefaults.nutrients[nutrient])
      ) {
        return this.soilSampleDefaults.nutrients[nutrient][settingName]
      }
      return "--"
    },
    isLink(defaultName) {
      if (this.soilSampleData[defaultName]) return false
      if (this.soilSampleDefaults[defaultName]) return true
      return true
    },
    isNutrientLink(nutrient) {
      if (
        !_.isEmpty(this.soilSampleData.nutrients) &&
        !_.isEmpty(this.soilSampleData.nutrients[nutrient])
      ) {
        return false
      }
      if (
        !_.isEmpty(this.soilSampleDefaults.nutrients) &&
        !_.isEmpty(this.soilSampleDefaults.nutrients[nutrient])
      ) {
        return true
      }
      return true
    },
    handleLinkClick(defaultName) {
      if (
        !this.soilSampleData[defaultName] &&
        this.soilSampleDefaults[defaultName]
      ) {
        this.$router.push(
          `/settings/defaults/${this.soilSampleDefaults.defaults_template}`
        )
      }
      if (
        !this.soilSampleData[defaultName] &&
        !this.soilSampleDefaults[defaultName]
      ) {
        this.$router.push(`/settings/defaults/${this.generalDefaults.id}`)
      }
    },
    handleNutrientLinkClick(nutrient) {
      // check for field level settings
      if (!_.isEmpty(this.soilSampleData.nutrients)) {
        // field level settings is there, no link
        if (!_.isEmpty(this.soilSampleData.nutrients[nutrient])) return
        // field level settings exists & defaults data exists
        if (!_.isEmpty(this.soilSampleDefaults.nutrients)) {
          // defaults data has an input that field level settings do not, link
          if (
            _.isEmpty(this.soilSampleData.nutrients[nutrient]) &&
            !_.isEmpty(this.soilSampleDefaults.nutrients[nutrient])
          ) {
            this.$router.push(
              `/settings/defaults/${this.soilSampleDefaults.defaults_template}`
            )
            return
          }
        }
      }
      // no field level settings, defaults data available
      if (
        !_.isEmpty(this.soilSampleDefaults.nutrients) &&
        !_.isEmpty(this.soilSampleDefaults.nutrients[nutrient])
      ) {
        this.$router.push(
          `/settings/defaults/${this.soilSampleDefaults.defaults_template}`
        )
        return
      }
      // no field level settings or defaults data found
      this.$router.push(`/settings/defaults/${this.generalDefaults.id}`)
    },
    clearSetting(nutrient) {
      this.soilSampleData.nutrients = _.omit(
        this.soilSampleData.nutrients,
        nutrient
      )
      this.handleSubmit()
    },
    focusInput(input, isNutrient = false) {
      if (isNutrient) {
        this.$nextTick(() => {
          this.$refs[input][0].focus()
        })
      } else {
        this.$nextTick(() => {
          this.$refs[input].focus()
        })
      }
    },
    handleEnter() {},
  },
}
</script>
