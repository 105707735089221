<template>
  <ArvaModal
    name="adjustCarbonCreditModal"
    title="Adjust Carbon Credit Modal"
    @close-modal="handleCloseModal"
  >
    <div class="col">
      <span class="default-input-title">Price Per Tonne CO2e</span>
      <div class="input-group">
        <v-text-field
          type="number"
          :rules="numberRule"
          v-model.trim="dollarInput"
        />
      </div>
    </div>

    <ArvaModalSaveFooter
      confirmText="Set"
      cancelText="Cancel"
      @on-cancel="handleCloseModal"
      @on-confirm="setCarbonCreditDollarValue"
    />
  </ArvaModal>
</template>

<script>
import ArvaModal from "./ArvaModal"
import ArvaModalSaveFooter from "@/components/modals/ArvaModalSaveFooter"

export default {
  name: "AdjustCarbonCreditModal",
  props: {
    carbonCreditDollarValue: { type: Number },
  },
  components: { ArvaModal, ArvaModalSaveFooter },
  data: () => ({
    numberRule: [
      v =>
        (!isNaN(parseFloat(v)) && v >= 0) ||
        "Input must be a positive number",
    ],
    dollarInput: null,
  }),

  methods: {
    handleCloseModal() {
      this.$emit("close-template-modal")
    },

    setCarbonCreditDollarValue() {
      this.$emit("update-carbon-credit", this.dollarInput)
    },
  },

  mounted() {
    this.dollarInput = this.carbonCreditDollarValue
  },
}
</script>
