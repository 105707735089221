<template>
  <div>
    <Permissions package="rx-zones" />
    <div v-if="showRxZones" class="row reverse-margin">
      <div class="col-5 scroll-col">
        <v-card>
          <v-toolbar flat>
            <v-toolbar-title
              >{{ isYield ? "Yield" : "AGT" }} Zones</v-toolbar-title
            >
            <template v-slot:extension>
              <v-tabs grow v-model="tab">
                <v-tabs-slider color="blue"></v-tabs-slider>
                <v-tab v-for="item in tabTitles" :key="item.index">
                  {{ item.name }}
                </v-tab>
              </v-tabs>
            </template>
          </v-toolbar>
          <v-tabs-items v-model="tab">
            <v-tab-item v-for="item in tabTitles" :key="item.index">
              <v-card flat>
                <div v-if="item.name === 'Fields'" class="pt-3">
                  <FieldImageryListView
                    :canAccess="true"
                    :tabView="true"
                    :zonesView="true"
                  />
                  <button
                    v-if="!isReadOnly"
                    type="button"
                    class="btn btn-primary update-btn py-2 ml-3"
                    @click="viewCreatePlan"
                  >
                    Create New Plan
                  </button>
                </div>
                <div v-if="item.name === 'Zones'" class="pt-3">
                  <!-- yield zone controller -->
                  <ZonesControllerYield
                    :activeFields="activeFields"
                    :isYield="isYield"
                    :tab="tab"
                    :yieldZonationPlans="yieldZonationPlans"
                    :zoneControllerView="zoneControllerView"
                    :years="years"
                    :finishedLoading="finishedLoading"
                    @select-fields="toggleZoneFields"
                    @set-view-list="setControllerView"
                    @update-list="fetchYieldZonationPlans"
                    @update-zones="updateZoneGeoJSON"
                    @reset-zones="resetZoneGeoJSON"
                    @update-map-params="updateMapParams"
                  />
                </div>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </div>
      <div class="col-7 map-col">
        <div class="map-wrapper" oncontextmenu="return false">
          <LeafletMap
            :boundaries="fieldBoundaries"
            :fields="fields"
            :isYield="isYield"
            :zoneGeoJSON="zoneGeoJSON"
            :mapParams="mapParams"
            @update-synthetic-year-list="updateSyntheticYearList"
          />
          <!--<ZonesMap
          :boundaries="fieldBoundaries"
          :fields="fields"
          :isYield="isYield"
          :zoneGeoJSON="zoneGeoJSON"
        />-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from "vuex"
//import MapAPI from "@/api/MapAPI"
import ZoneRxAPI from "@/api/ZoneRxAPI"

import FieldImageryListView from "@/views/FieldImageryListView"
import ZonesControllerYield from "@/components/zones/ZonesControllerYield"
import LeafletMap from "@/components/map/LeafletMap"
import Permissions from "@/components/permissions/Permissions"
//import ZonesMap from "@/components/zones/ZonesMap"
import { Filter } from "@/store/modules"

export default {
  name: "ZonesYieldView",
  props: ["isYield"],
  components: {
    FieldImageryListView,
    ZonesControllerYield,
    //ZonesMap,
    Permissions,
    LeafletMap,
  },
  data() {
    return {
      tab: 0,
      tabTitles: [
        { index: 0, name: "Fields" },
        { index: 1, name: "Zones" },
      ],
      yieldZonationPlans: [],
      zoneControllerView: "list",
      zoneGeoJSON: null,
      crop_id: null,
      years: [],
      finishedLoading: false,
    }
  },

  computed: {
    ...mapGetters({
      activeFields: Filter.Getters.getSelectedFields,
    }),

    ...mapState({
      isReadOnly: state => state.User.user.permission === "Read-Only",
      selectedFields: state => state.Filter.selectedFields,
      fieldBoundaries: state => state.Map.fieldBoundaries,
      showRxZones: state => state.Organization.organization.showRxZones,
    }),

    mapParams() {
      let tempParams = {}
      tempParams["crop_id"] = this.crop_id
      tempParams["category"] = "synthetic_yield"
      tempParams["field_ids"] = this.activeFields.map(field => field.id)

      if (this.crop_id != null) {
        tempParams["useMap"] = true
      } else {
        tempParams["useMap"] = false
      }
      return tempParams
    },

    fields() {
      const activeFieldIds = this.activeFields.map(field => field.id)
      const filteredBounds =
        activeFieldIds.length > 0
          ? this.fieldBoundaries.filter(row =>
              activeFieldIds.includes(row.properties.field.id)
            )
          : this.fieldBoundaries
      return filteredBounds
    },
  },
  methods: {
    ...mapMutations({
      setSelectedFields: Filter.Mutations.setSelectedFields,
    }),

    fetchYieldZonationPlans() {
      ZoneRxAPI.getYieldZones({
        use_agt: !this.isYield,
      }).then(response => {
        this.yieldZonationPlans = response.data
        this.finishedLoading = true
      })
    },

    resetZoneGeoJSON() {
      this.zoneGeoJSON = null
    },

    updateSyntheticYearList(d) {
      //this.years = [2020, 2021, 2022]
      this.years = d
    },

    updateZoneGeoJSON(d) {
      this.zoneGeoJSON = d
    },
    updateMapParams(d) {
      this.crop_id = d
    },

    setControllerView(view) {
      this.zoneControllerView = view
    },

    viewCreatePlan() {
      this.tab = 1
      this.zoneControllerView = "create"
    },

    toggleZoneFields(fieldIds) {
      const selFields = this.selectedFields
      for (const fieldId in selFields) {
        if (fieldIds.includes(Number(fieldId))) {
          selFields[fieldId] = true
        } else {
          selFields[fieldId] = false
        }
      }

      this.setSelectedFields(fieldIds)
    },
  },
  async mounted() {
    await this.fetchYieldZonationPlans()
  },
  watch: {
    tab(newTab, oldTab) {
      if (oldTab === 1 || newTab !== 1) {
        this.zoneGeoJSON = null
      }
    },
  },
}
</script>

<style scoped>
h2 {
  font-weight: bold;
  border-bottom: 1pt solid #bbb;
  padding-bottom: 9px;
  margin-bottom: 20px;
}

.map-wrapper {
  height: calc(100vh - 65px);
  width: 100%;
  margin-top: -30px;
  margin-bottom: -17px;
  position: relative;
  background: #1b1b1d;
}

.fill-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.scroll-col {
  max-height: 85vh;
  overflow: scroll;
  padding-right: 40px;
}

.reverse-margin {
  margin-bottom: -60px;
}

.single {
  font-weight: bold;
  color: green;
}

.comparison {
  font-weight: bold;
  color: purple;
}

.loading-text {
  font-weight: bold;
  font-size: 16px;
}

.info {
  color: #666666;
}
</style>
