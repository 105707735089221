<template>
  <!-- THIS COMES FROM V-CALENDAR NOT VUETIFY -->
  <vc-date-picker
    v-model="date"
    class="flex-fill"
    :attributes="attributes"
    :min-date="minDate"
    :max-date="maxDate"
    :popover="{
      placement: $screens({ default: 'auto' }),
    }"
    is-range
    @submit.prevent
  >
    <template v-slot="{ inputValue, inputEvents }">
      <div class="d-flex justify-center items-center">
        <input
          :value="inputValue.start"
          v-on="inputEvents.start"
          class="border mx-2 px-2 py-1 w-32 input-height rounded focus:outline-none focus:border-indigo-300"
          :placeholder="formatDate(minDate)"
        />
        to
        <input
          :value="inputValue.end"
          v-on="inputEvents.end"
          class="border ml-2 px-2 py-1 w-32 input-height rounded focus:outline-none focus:border-indigo-300"
          :placeholder="formatDate(maxDate)"
        />
        <button
          type="button"
          class="btn btn-primary update-btn input-height px-2 py-0 mb-0 ml-2"
          @click="handleReset"
        >
          <i class="fa fa-undo" />
        </button>
      </div>
    </template>
  </vc-date-picker>
</template>

<script>
import moment from "moment"
export default {
  name: "Datepicker",
  // imageryDates: ["YYYY-MM-DD", ...]
  // value accepts the v-model
  props: ["imageryDates", "value"],
  data() {
    return {
      date: null,
    }
  },
  computed: {
    attributes() {
      return [
        {
          highlight: "green",
          // ISO formatted dates end up 1 day off due to UTC conversion
          dates: this.imageryDates.map(x => new Date(x.replace(/-/g, "/"))),
        },
      ]
    },
    minDate() {
      if (this.imageryDates.length > 0) {
        return new Date(this.imageryDates[0].replace(/-/g, "/"))
      }
      return null
    },
    maxDate() {
      if (this.imageryDates.length > 0) {
        return new Date(
          this.imageryDates[this.imageryDates.length - 1].replace(/-/g, "/")
        )
      }
      return null
    },
  },
  methods: {
    formatDate(val) {
      if (!val) return ""
      let momentDate = moment(val, "MM/DD/YYYY")
      if (momentDate.isValid()) return momentDate.format("MM/DD/YYYY")
      return ""
    },
    handleReset() {
      this.date = { start: this.minDate, end: this.maxDate }
    },
  },
  watch: {
    date() {
      this.$emit("input", this.date)
    },
    value() {
      this.date = this.value
      this.$emit("input", this.date)
    },
  },
}
</script>

<style scoped>
.date-pick {
  border: 1pt solid #ccc;
  height: 38px;
}

.input-height {
  height: 24px;
}

.cal-cal {
  margin-right: 8px;
}

.w-70 {
  width: 70%;
}

.ws-nr {
  white-space: nowrap;
}
</style>
