import { CROP_DISPLAY_NAME, DROPDOWN } from "@/constants"
import store from "@/store"
// import { map } from "core-js/core/array"
import { Fields, Filter, Map, Organization } from "."

const state = {
  initializedOrgNodeId: null,
  open: {},
  orgNodes: [],
  selectedOrgNodes: {},
  crops: [],
  cropsInView: [],
  selectedCrops: [],
  clients: [],
  clientsInView: {},
  selectedClients: {},
  farms: [],
  farmsInView: {},
  selectedFarms: {},
  fields: [],
  fieldsInView: {},
  selectedFields: {},
}

const getters = {
  [Filter.Getters.getOpen]: state => dropdownType =>
    Boolean(state.open[dropdownType]),

  [Filter.Getters.getOrgNodes]: state =>
    state.orgNodes.map(({ id, name, depth }) => ({
      id,
      name,
      depth,
      selected: state.selectedOrgNodes[id],
    })),

  [Filter.Getters.getSelectedOrgNodes]: (_, getters) =>
    getters[Filter.Getters.getOrgNodes].filter(orgNode => orgNode.selected),

  [Filter.Getters.getSelectedOrgNodeCount]: (_, getters) =>
    getters[Filter.Getters.getSelectedOrgNodes].length,

  [Filter.Getters.getCrops]: state =>
    state.crops
      .filter(crop => state.cropsInView[crop.id])
      .map(({ id, name, nameKey }) => ({
        id,
        name,
        nameKey,
        selected: state.selectedCrops[id],
      })),

  [Filter.Getters.getDashboardCrops]: (_, getters) => {
    const crops = getters[Filter.Getters.getSelectedCrops]
    return crops.length === 0
      ? state.crops
      : getters[Filter.Getters.getSelectedCrops]
  },

  [Filter.Getters.getSelectedCrops]: state =>
    state.crops.filter(crop => state.selectedCrops[crop.id]),

  [Filter.Getters.getSelectedCropsCount]: (_, getters) =>
    getters[Filter.Getters.getCrops].filter(crop => crop.selected).length,

  [Filter.Getters.getClients]: state =>
    state.clients
      .filter(client => state.clientsInView[client.id])
      .map(({ id, name }) => ({
        id,
        name,
        selected: state.selectedClients[id],
      })),

  [Filter.Getters.getSelectedClientsCount]: (_, getters) =>
    getters[Filter.Getters.getClients].filter(client => client.selected).length,

  [Filter.Getters.getFarms]: state =>
    state.farms
      .filter(farm => state.farmsInView[farm.id])
      .map(({ id, name, clientId }) => ({
        id,
        name,
        selected: state.selectedFarms[id],
        clientId,
      })),

  [Filter.Getters.getSelectedFarmsCount]: (_, getters) =>
    getters[Filter.Getters.getFarms].filter(farm => farm.selected).length,

  [Filter.Getters.getFields]: state =>
    state.fields
      .filter(field => state.fieldsInView[field.id])
      .map(({ id, name, acreage, farmId }) => ({
        id,
        name,
        acreage,
        farmId,
        selected: state.selectedFields[id],
      })),

  [Filter.Getters.getFieldForCheck]: state => fieldId => {
    return state.selectedFields[fieldId]
  },

  [Filter.Getters.getSelectedFields]: (state, getters) =>
    getters[Fields.Getters.getFields].filter(
      field => state.selectedFields[field.id]
    ),

  [Filter.Getters.getSelectedFieldsCount]: (_, getters) =>
    getters[Filter.Getters.getFields].filter(field => field.selected).length,

  [Filter.Getters.getSelectedFieldsAcreage]: (_, getters) =>
    getters[Filter.Getters.getFields]
      .filter(field => field.selected)
      .reduce((prev, curr) => prev + curr.acreage, 0),
}

const mutations = {
  [Filter.Mutations.clearAll](state) {
    state.selectedOrgNodes = {}
    state.selectedCrops = {}
    state.selectedClients = {}
    state.selectedFarms = {}
    state.selectedFields = {}

    store.commit(Map.Mutations.updateMap)
    store.commit(Map.Mutations.autoZoom)
    saveState(state)
  },

  [Filter.Mutations.closeDropdowns](state) {
    state.open = {}
  },

  [Filter.Mutations.initialize](state, { organization, force, resetView }) {
    if (organization.id !== state.initializedOrgNodeId || force) {
      const year = this.getters[Organization.Getters.getYear]
      initialize(state, organization, year)
      state.initializedOrgNodeId = organization.id
      store.commit(Map.Mutations.updateMap)
      store.commit(Map.Mutations.autoZoom, resetView)
    }
  },

  [Filter.Mutations.selectAll](state) {
    selectAll(state)
    store.commit(Map.Mutations.updateMap)
    store.commit(Map.Mutations.autoZoom)
    saveState(state)
  },

  [Filter.Mutations.setClosed](state, dropdownType) {
    const open = { ...state.open }
    open[dropdownType] = false
    state.open = open
  },

  [Filter.Mutations.selectAllOfType](state, dropdownType) {
    switch (dropdownType) {
      case DROPDOWN.OrgNode:
        for (const orgNode of state.orgNodes) {
          if (!state.selectedOrgNodes[orgNode.id])
            toggleOrgNode(state, orgNode.id)
        }
        break
      case DROPDOWN.Crop:
        Object.keys(state.cropsInView)
          .filter(cropId => state.cropsInView[cropId])
          .filter(cropId => !state.selectedCrops[cropId])
          .forEach(cropId => toggleCrop(state, cropId))
        break
      case DROPDOWN.Client:
        Object.keys(state.clientsInView)
          .filter(clientId => state.clientsInView[clientId])
          .filter(clientId => !state.selectedClients[clientId])
          .forEach(clientId => toggleClient(state, clientId))
        break
      case DROPDOWN.Farm:
        Object.keys(state.farmsInView)
          .filter(farmId => state.farmsInView[farmId])
          .filter(farmId => !state.selectedFarms[farmId])
          .forEach(farmId => toggleFarm(state, farmId))
        break
      case DROPDOWN.Field:
        Object.keys(state.fieldsInView)
          .filter(fieldId => state.fieldsInView[fieldId])
          .filter(fieldId => !state.selectedFields[fieldId])
          .forEach(fieldId => toggleField(state, fieldId))
        break
    }
    store.commit(Map.Mutations.updateMap)
    store.commit(Map.Mutations.autoZoom)
    saveState(state)
  },

  [Filter.Mutations.clearAllOfType](state, dropdownType) {
    switch (dropdownType) {
      case DROPDOWN.OrgNode:
        state.selectedOrgNodes = {}
        state.selectedClients = {}
        state.selectedFarms = {}
        state.selectedFields = {}

        state.clientsInView = {}
        state.farmsInView = {}
        state.fieldsInView = {}
        break
      case DROPDOWN.Crop:
        state.selectedCrops = {}
        state.selectedClients = {}
        state.selectedFarms = {}
        state.selectedFields = {}
        break
      case DROPDOWN.Client:
        state.selectedClients = {}
        state.selectedFarms = {}
        state.selectedFields = {}

        state.farmsInView = {}
        state.fieldsInView = {}
        break
      case DROPDOWN.Farm:
        state.selectedFarms = {}
        state.selectedFields = {}

        state.fieldsInView = {}
        break
      case DROPDOWN.Field:
        state.selectedFields = {}
        break
    }
    store.commit(Map.Mutations.updateMap)
    store.commit(Map.Mutations.autoZoom)
    saveState(state)
  },

  [Filter.Mutations.setSelectedFields](state, selectedFields) {
    state.selectedOrgNodes = {}
    state.selectedCrops = {}
    state.selectedClients = {}
    state.selectedFarms = {}
    state.selectedFields = {}
    selectedFields.forEach(fieldId => toggleField(state, fieldId))
  },

  [Filter.Mutations.setOrgNodes](state, orgTree) {
    const orgNodes = []

    orgTree.forEach(orgNode => {
      const crops = {}

      state.clients
        .filter(client => client.orgNodeId === orgNode.id)
        .forEach(client =>
          Object.keys(client.crops).forEach(cropId => crops[cropId])
        )

      orgNodes.push({
        id: orgNode.id,
        name: orgNode.name,
        depth: orgNode.depth,
        parentOrgNodeId: orgNode.parent,
        crops,
      })
    })
    state.orgNodes = orgNodes
    selectAll(state)
  },

  [Filter.Mutations.setFields](state, updatedFields) {
    const year = this.getters[Organization.Getters.getYear]
    const orgCrops = new Set()
    const fields = []
    updatedFields.forEach(field => {
      const crops = {}
      field.field_crops
        .filter(({ year: cropYear }) => cropYear === year)
        .forEach(({ crop_id }) => {
          crops[crop_id] = true
          orgCrops.add(crop_id)
        })
      fields.push({
        id: field.id,
        name: field.name,
        farmId: field.farm.id,
        crops,
        acreage: field.acreage,
      })
    })
    state.fields = fields
    const prevFilters = localStorage.getItem(
      `${state.initializedOrgNodeId}-filters`
    )
    selectAll(state, prevFilters)
  },

  [Filter.Mutations.toggleItem](state, { id, dropdownType, preventAutozoom }) {
    switch (dropdownType) {
      case DROPDOWN.OrgNode:
        toggleOrgNode(state, id)
        break
      case DROPDOWN.Crop:
        toggleCrop(state, id)
        break
      case DROPDOWN.Client:
        toggleClient(state, id)
        break
      case DROPDOWN.Farm:
        toggleFarm(state, id)
        break
      case DROPDOWN.Field:
        toggleField(state, id)
        break
    }
    store.commit(Map.Mutations.updateMap)
    if (!preventAutozoom) store.commit(Map.Mutations.autoZoom)

    saveState(state)
  },

  [Filter.Mutations.toggleOpen](state, dropdownType) {
    const open = { [dropdownType]: !state.open[dropdownType] }
    state.open = open
  },
}

function saveState(state) {
  const {
    selectedOrgNodes,
    selectedCrops,
    selectedClients,
    clientsInView,
    selectedFarms,
    farmsInView,
    selectedFields,
    fieldsInView,
  } = state
  localStorage.setItem(
    `${state.initializedOrgNodeId}-filters`,
    JSON.stringify({
      selectedOrgNodes,
      selectedCrops,
      selectedClients,
      clientsInView,
      selectedFarms,
      farmsInView,
      selectedFields,
      fieldsInView,
    })
  )
}

function initialize(state, organization, year) {
  const orgCrops = new Set()
  const fields = []
  organization.fieldsForUser.forEach(field => {
    const crops = {}
    field.field_crops
      .filter(({ year: cropYear }) => cropYear === year)
      .forEach(({ crop_id }) => {
        crops[crop_id] = true
        orgCrops.add(crop_id)
      })
    fields.push({
      id: field.id,
      name: field.name,
      farmId: field.farm_id,
      crops,
      acreage: field.acreage,
    })
  })
  state.fields = fields

  const farms = []
  organization.farmsForUser.forEach(farm => {
    const crops = {}

    state.fields
      .filter(field => field.farmId === farm.id)
      .forEach(field =>
        Object.keys(field.crops).forEach(cropId => (crops[cropId] = true))
      )

    farms.push({
      id: farm.id,
      name: farm.name,
      clientId: farm.client,
      crops,
    })
  })
  state.farms = farms

  const clients = []
  organization.clientsForUser.forEach(client => {
    const crops = {}

    state.farms
      .filter(farm => farm.clientId === client.id)
      .forEach(farm =>
        Object.keys(farm.crops).forEach(cropId => (crops[cropId] = true))
      )

    clients.push({
      id: client.id,
      name: client.name,
      orgNodeId: client.organization_id,
      crops,
    })
  })
  state.clients = clients

  state.crops = Array.from(orgCrops).map(cropId => ({
    id: cropId,
    name: CROP_DISPLAY_NAME[cropId],
    nameKey: CROP_DISPLAY_NAME[cropId],
  }))

  const orgNodes = []
  organization.org_tree.forEach(orgNode => {
    const crops = {}

    state.clients
      .filter(client => client.orgNodeId === orgNode.id)
      .forEach(client =>
        Object.keys(client.crops).forEach(cropId => crops[cropId])
      )

    orgNodes.push({
      id: orgNode.id,
      name: orgNode.name,
      depth: orgNode.depth,
      parentOrgNodeId: orgNode.parent,
      crops,
    })
  })
  state.orgNodes = orgNodes

  const prevFilters = localStorage.getItem(`${organization.id}-filters`)
  selectAll(state, prevFilters)
}

function selectAll(state, prevFilters) {
  let prevSelectedOrgNodes
  let prevSelectedCrops
  let prevSelectedClients
  let prevClientsInView
  let prevSelectedFarms
  let prevFarmsInView
  let prevSelectedFields
  let prevFieldsInView

  if (prevFilters) {
    const prev = JSON.parse(prevFilters)
    prevSelectedOrgNodes = prev.selectedOrgNodes
    prevSelectedCrops = prev.selectedCrops
    prevSelectedClients = prev.selectedClients
    prevClientsInView = prev.clientsInView
    prevSelectedFarms = prev.selectedFarms
    prevFarmsInView = prev.farmsInView
    prevSelectedFields = prev.selectedFields
    prevFieldsInView = prev.fieldsInView
  }

  let selectedOrgNodes = {}
  if (!prevSelectedOrgNodes)
    state.orgNodes.forEach(orgNode => (selectedOrgNodes[orgNode.id] = true))
  else selectedOrgNodes = prevSelectedOrgNodes
  state.selectedOrgNodes = selectedOrgNodes

  let selectedCrops = {}
  if (!prevSelectedCrops)
    state.crops.forEach(crop => (selectedCrops[crop.id] = false))
  else selectedCrops = prevSelectedCrops
  const cropsInView = {}
  state.crops.forEach(crop => (cropsInView[crop.id] = true))
  state.selectedCrops = selectedCrops
  state.cropsInView = cropsInView

  let selectedClients = {}
  if (!prevSelectedClients)
    state.clients.forEach(client => (selectedClients[client.id] = true))
  else selectedClients = prevSelectedClients
  state.clientsInView = prevClientsInView ? prevClientsInView : selectedClients
  state.selectedClients = selectedClients

  let selectedFarms = {}
  if (!prevSelectedFarms)
    state.farms.forEach(farm => (selectedFarms[farm.id] = true))
  else selectedFarms = prevSelectedFarms
  state.farmsInView = prevFieldsInView ? prevFarmsInView : selectedFarms
  state.selectedFarms = selectedFarms

  let selectedFields = {}
  if (!prevSelectedFields)
    state.fields.forEach(field => (selectedFields[field.id] = true))
  else selectedFields = prevSelectedFields
  state.fieldsInView = prevFieldsInView ? prevFieldsInView : selectedFields
  state.selectedFields = selectedFields
}

function toggleOrgNode(state, orgNodeId) {
  const selectedOrgNodes = { ...state.selectedOrgNodes }
  const clientsInView = { ...state.clientsInView }
  const selectedClients = { ...state.selectedClients }
  const farmsInView = { ...state.farmsInView }
  const selectedFarms = { ...state.selectedFarms }
  const fieldsInView = { ...state.fieldsInView }
  const selectedFields = { ...state.selectedFields }

  selectedOrgNodes[orgNodeId] = !selectedOrgNodes[orgNodeId]

  let toProcess = [orgNodeId]
  while (toProcess.length !== 0) {
    const newToProcess = []
    for (let id of toProcess) {
      const children = state.orgNodes.filter(
        orgNode => orgNode.parentOrgNodeId === id
      )
      children.forEach(child => {
        newToProcess.push(child.id)
        selectedOrgNodes[child.id] = selectedOrgNodes[id] // If this org node has children, then sync the child state with the parent
      })
    }
    toProcess = newToProcess
  }

  // Turn off clients which aren't part of the selected org nodes
  state.clients.forEach(client => {
    clientsInView[client.id] = selectedOrgNodes[client.orgNodeId]
    selectedClients[client.id] = selectedOrgNodes[client.orgNodeId]
  })

  // Turn off farms which aren't part of the selected clients
  state.farms.forEach(farm => {
    farmsInView[farm.id] = selectedClients[farm.clientId]
    selectedFarms[farm.id] = selectedClients[farm.clientId]
  })

  // Turn off fields which aren't part of the selected farms
  state.fields.forEach(field => {
    fieldsInView[field.id] = selectedFarms[field.farmId]
    selectedFields[field.id] = selectedFarms[field.farmId]
  })

  state.selectedOrgNodes = selectedOrgNodes
  state.clientsInView = clientsInView
  state.selectedClients = selectedClients
  state.farmsInView = farmsInView
  state.selectedFarms = selectedFarms
  state.fieldsInView = fieldsInView
  state.selectedFields = selectedFields
}

function toggleCrop(state, cropId) {
  const selectedCrops = { ...state.selectedCrops }
  const clientsInView = { ...state.clientsInView }
  const selectedClients = { ...state.selectedClients }
  const farmsInView = { ...state.farmsInView }
  const selectedFarms = { ...state.selectedFarms }
  const fieldsInView = { ...state.fieldsInView }
  const selectedFields = { ...state.selectedFields }

  selectedCrops[cropId] = !selectedCrops[cropId]

  const onCrops = Object.keys(selectedCrops).filter(
    cropId => selectedCrops[cropId]
  )
  const noCropsSelected = onCrops.length === 0
  if (noCropsSelected) {
    selectAll(state)
    return
  }

  state.clients.forEach(client => {
    // If client has a crop in onCrops, it is selected, otherwise, not
    const selected = onCrops.some(cropId => client.crops[cropId])
    clientsInView[client.id] = selected
    selectedClients[client.id] = selected
  })

  state.farms.forEach(farm => {
    const selected = onCrops.some(cropId => farm.crops[cropId])
    farmsInView[farm.id] = selected
    selectedFarms[farm.id] = selected
  })

  state.fields.forEach(field => {
    const selected = onCrops.some(cropId => field.crops[cropId])
    fieldsInView[field.id] = selected
    selectedFields[field.id] = selected
  })

  state.selectedCrops = selectedCrops
  state.clientsInView = clientsInView
  state.selectedClients = selectedClients
  state.farmsInView = farmsInView
  state.selectedFarms = selectedFarms
  state.fieldsInView = fieldsInView
  state.selectedFields = selectedFields
}

function toggleClient(state, clientId) {
  const selectedCrops = { ...state.selectedCrops }
  const selectedClients = { ...state.selectedClients }
  const farmsInView = { ...state.farmsInView }
  const selectedFarms = { ...state.selectedFarms }
  const fieldsInView = { ...state.fieldsInView }
  const selectedFields = { ...state.selectedFields }

  const onCrops = Object.keys(selectedCrops).filter(
    cropId => selectedCrops[cropId]
  )

  selectedClients[clientId] = !selectedClients[clientId]

  // Turn off farms which aren't part of the selected clients
  state.farms.forEach(farm => {
    farmsInView[farm.id] = selectedClients[farm.clientId]
    selectedFarms[farm.id] = selectedClients[farm.clientId]
  })

  // Turn off fields which aren't part of the selected farms
  state.fields.forEach(field => {
    let selected = true
    if (onCrops.length > 0) {
      selected = onCrops.some(cropId => field.crops[cropId])
    }

    selectedFields[field.id] = selectedFarms[field.farmId] && selected

    fieldsInView[field.id] = selectedFarms[field.farmId] && selected
  })

  state.selectedClients = selectedClients
  state.farmsInView = farmsInView
  state.selectedFarms = selectedFarms
  state.fieldsInView = fieldsInView
  state.selectedFields = selectedFields
}

function toggleFarm(state, farmId) {
  const selectedCrops = { ...state.selectedCrops }

  const selectedFarms = { ...state.selectedFarms }
  const fieldsInView = { ...state.fieldsInView }
  const selectedFields = { ...state.selectedFields }
  selectedFarms[farmId] = !selectedFarms[farmId]

  const onCrops = Object.keys(selectedCrops).filter(
    cropId => selectedCrops[cropId]
  )

  // Turn off fields which aren't part of the selected farms
  state.fields.forEach(field => {
    let selected = true

    if (onCrops.length > 0) {
      selected = onCrops.some(cropId => field.crops[cropId])
    }

    fieldsInView[field.id] = selectedFarms[field.farmId] && selected
    selectedFields[field.id] = selectedFarms[field.farmId] && selected
  })

  state.selectedFarms = selectedFarms
  state.fieldsInView = fieldsInView
  state.selectedFields = selectedFields
}

function toggleField(state, fieldId) {
  const selectedFields = { ...state.selectedFields }
  const fieldsInView = { ...state.fieldsInView }
  selectedFields[fieldId] = !selectedFields[fieldId]

  if (!fieldsInView[fieldId] && selectedFields[fieldId])
    fieldsInView[fieldId] = true

  state.fieldsInView = fieldsInView
  state.selectedFields = selectedFields
}

export default {
  state,
  getters,
  mutations,
}
