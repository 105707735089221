<template>
  <div>
    <v-progress-circular v-if="bulkCIEstimateLoading" indeterminate :size="48" color="#79c61c" />

    <div v-else-if="!bulkCIEstimateLoading && bulkCIEstimateError" class="total-span text-danger">
      Error
    </div>

    <button v-else :disabled="isCIDownloadDisabled" class="btn ci-download-btn total-span mb-0 btn-secondary collapsed"
      v-b-toggle.sidebar-right-downloads>
      Download
    </button>

    <b-sidebar id="sidebar-right-downloads" width="576px" title="Confirm Bulk CI Download Details" right backdrop>
      <div class="px-8 pt-4 sidebar-body-inner-padding-bottom">
        <h4>This bulk estimator runs off of the selections for crop, client, farm, and fields in view.</h4>
        <h4><strong>Ensure that Crop, Client, Farm, and Field all have valid selections in the top nav bar before
            attempting a report generation.</strong></h4>
        <br>
        <h4>You're submitting a bulk download for the following fields:</h4>
        <ul class="sidebar-body-inner-fieldlist" v-if="activeFields.length > 0">
          <li v-for="field of activeFields" :key="field.id">
            {{ field["name"] }}
          </li>
        </ul>
        <p class="text-danger" v-else>You must specify at least one field.</p>

        <h4>and the following crops:</h4>
        <ul v-if="selectedCrops.length > 0">
          <li v-for="crop of selectedCrops" :key="crop.id">
            {{ crop["name"] }}
          </li>
        </ul>
        <p v-else class="text-danger">You must specify at least one crop.</p>

        <div class="ci-navigation-handlers">
          <v-btn outlined height="52" @click="bulkCIEstimate()"
            :disabled="selectedCrops.length == 0 || activeFields.length == 0">
            Submit Download
          </v-btn>
        </div>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Filter } from "@/store/modules";
import { getBulkCIEstimateCSV } from "@/api/CIScore"

export default {
  name: "BulkCIForm",
  data() {
    return {
      bulkCIEstimateLoading: false,
      bulkCIEstimateError: false
    }
  },
  computed: {
    ...mapGetters({
      activeFields: Filter.Getters.getSelectedFields,
      selectedCrops: Filter.Getters.getSelectedCrops,
    }),
    isCIDownloadDisabled() {
      return this.activeFields.length == 0 && this.selectedCrops.length == 0
    },
  },
  methods: {
    async bulkCIEstimate() {
      this.bulkCIEstimateLoading = true
      this.bulkCIEstimateError = false

      const yearRange = [2021, 2022, 2023]
      const formData = {
        crops: this.selectedCrops.map(c => c["id"]),
        years: yearRange,
        fields: this.activeFields.map(f => f["id"]),
        ci_year: "2023",
      }
      try {
        const { data } = await getBulkCIEstimateCSV(formData)
        const a = document.createElement("a")
        a.style = "display:none;"
        const blob = new Blob([data], { type: "octet/stream" })
        const url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = "CIScores.csv"
        a.click()
        window.URL.revokeObjectURL(url)
      } catch (e) {
        console.log("error!", e)
        this.bulkCIEstimateError = true
      }

      this.bulkCIEstimateLoading = false
    }
  }
}
</script>

<style scoped>
.total-span {
  float: right;
  color: #79c61c;
  font-size: 20px;
  font-weight: 500;
  min-width: 40px;
  white-space: nowrap;
}

.ci-download-btn {
  background: none !important;
  box-shadow: none !important;
  border: none !important;
  color: #79c61c !important;
  padding-right: 0;
  margin-right: 0;
}

.ci-download-btn:hover {
  opacity: 0.5 !important;
}

.sidebar-body-inner-padding-bottom {
  padding-bottom: 96px;
}

.sidebar-body-inner-padding-bottom>h4 {
  margin-top: 16px;
}

.sidebar-body-inner-fieldlist {
  column-count: 3;
  column-gap: 16px;
}

::v-deep .ci-navigation-handlers {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px 32px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  background: #f8f9fa;
}

::v-deep header.b-sidebar-header {
  padding: 56px 32px 0;
  position: relative;
}

::v-deep header.b-sidebar-header>button.close {
  position: absolute;
  top: 24px;
  left: 24px;
}

::v-deep header.b-sidebar-header>strong {
  width: 100%;
  text-align: left;
}
</style>