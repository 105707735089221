<template>
  <ArvaModal
    name="fieldInsetEnrollmentModal"
    title="Field Inset Enrollment"
    :wide="true"
    :width="1000"
    @close-modal="handleCloseModal"
    style="z-index: 10000"
  >
    <div v-if="hasError">
      <span class="error-message ml-5 p-1"> Failed To Enroll Fields: </span>
      <div
        class="error-message ml-5 p-1"
        v-for="message in errorMessage"
        :key="message"
      >
        <li>{{ message }}</li>
      </div>
    </div>

    <div v-if="processingEnrollment" class="zero-state span-text">
      <div class="spinner-border spinner-border-sm ml-1" role="status"></div>
      <span>
        Processing Field(s) Enrollment. Please wait until this process is
        complete!
      </span>
    </div>

    <div v-else>
      <div class="row">
        <div class="col-12">
          <div class="agreement">
            <div class="row">
              <div class="col-2">
                <div class="logo" />
              </div>
              <div class="col">
                <h1>
                  Application to Participate in Arva's Environmental Asset
                  Program
                </h1>
              </div>
            </div>

            <div class="row">
              <h3>Participant</h3>
              <v-text-field
                label="Name"
                :rules="[rules.required]"
                v-model="participantName"
                style="width: 100%"
              ></v-text-field>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    label="Street Address"
                    v-model="participantAddress.street"
                    style="width: 100%"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field
                    label="City"
                    v-model="participantAddress.city"
                    style="width: 100%"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field
                    label="State"
                    v-model="participantAddress.state"
                    style="width: 100%"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field
                    label="Zip Code"
                    v-model="participantAddress.zip"
                    style="width: 100%"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-text-field
                label="Email"
                :rules="[rules.required]"
                v-model="participantEmail"
                style="width: 100%"
              ></v-text-field>
              <v-text-field
                label="Telephone"
                v-model="participantTelephone"
                style="width: 100%"
              ></v-text-field>
            </div>

            <div class="row">
              <h3>Channel Partner</h3>
              <v-text-field
                label="Name"
                v-model="cpName"
                :rules="[rules.required]"
                style="width: 100%"
              ></v-text-field>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    label="Street Address"
                    v-model="cpAddress.street"
                    style="width: 100%"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field
                    label="City"
                    v-model="cpAddress.city"
                    style="width: 100%"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field
                    label="State"
                    v-model="cpAddress.state"
                    style="width: 100%"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field
                    label="Zip Code"
                    v-model="cpAddress.zip"
                    style="width: 100%"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-text-field
                label="Email"
                :rules="[rules.required]"
                v-model="cpEmail"
                style="width: 100%"
              ></v-text-field>
              <v-text-field
                label="Telephone"
                v-model="cpTelephone"
                style="width: 100%"
              ></v-text-field>
            </div>

            <div class="row">
              <span>
                Participant requests consideration to participate in the Arva
                Environmental Asset Program(s) identified below and for the land
                identified below, subject to acceptance by end buyer programs
                and by Arva Intelligence Corp. (“Arva”). The Participant
                understands that Arva and the Channel Partner identified above
                will work together to evaluate each farm and field for best
                execution to present for engagement in the Environmental Asset
                Program(s).<br />
              </span>
            </div>

            <div class="row mt-7">
              <span class="font-weight-bold">
                1. This is an engagement application to participate in the
                following Environmental Asset Programs (check all that apply):
              </span>
            </div>

            <div class="row">
              <div class="col">
                <v-checkbox
                  v-model="carbonOffsetCredits"
                  label="Carbon Offset Credits"
                />
                <v-checkbox
                  v-model="scope3EmissionsClaims"
                  label="Scope 3 Emissions Claims"
                />
                <v-checkbox
                  v-model="sustainabilityProgram"
                  label="Sustainability Program"
                />
                <v-checkbox
                  v-model="biodiversityClaims"
                  label="Biodiversity Claims and/or Water Credits"
                />
              </div>
            </div>

            <div class="row mt-7">
              <span class="font-weight-bold">
                2. Participant is applying as an (check one of the following):
              </span>
            </div>

            <div class="row">
              <div class="col">
                <v-radio-group v-model="selectedType">
                  <v-radio label="Individual" value="individual" />
                  <v-radio
                    label="Entity (Corporation, Limited Partnership, Trust, Estate, etc.)"
                    value="entity"
                  />
                  <v-radio
                    label="Joint Operation (General Partnership, Joint Venture)"
                    value="joint-operation"
                  />
                </v-radio-group>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <span class="default-input-title font-weight-bold">
                  a. Please enter legal name and tax ID number:
                </span>
              </div>

              <div class="col-12 ml-3">
                <span class="default-input-title font-weight-bold">
                  Grower Name or Entity Name:
                </span>
                <v-tooltip top max-width="300">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on"> mdi-information </v-icon>
                  </template>
                  <span>
                    Fill in the box to the right with a grower or entity name.
                  </span>
                </v-tooltip>
                <div class="input-group">
                  <input
                    required
                    type="text"
                    class="form-control fieldInput"
                    v-model.trim="entityName"
                  />
                </div>
              </div>

              <div class="col-12 ml-3">
                <span class="default-input-title font-weight-bold">
                  Tax ID:
                </span>
                <v-tooltip top max-width="300">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on"> mdi-information </v-icon>
                  </template>
                  <span>
                    Fill in the box to the right with a tax id number.
                  </span>
                </v-tooltip>
                <div class="input-group">
                  <input
                    required
                    type="text"
                    class="form-control fieldInput"
                    v-model.trim="taxId"
                    placeholder="Optional"
                  />
                </div>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <span class="default-input-title font-weight-bold">
                  b. Do you have the appropriate documents including proof to
                  sign for the join operation?
                </span>
              </div>
              <div class="col ml-3">
                <v-radio-group v-model="hasProofForJO">
                  <v-radio label="Yes" value="yes" />
                  <v-radio label="No" value="no" />
                </v-radio-group>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <span class="default-input-title font-weight-bold">
                  c. If the consent of one or more persons in addition to
                  Applicant is needed to authorize participation in Arva's
                  Environmental Asset Programs for the land identified in this
                  Application, please list those persons below:
                </span>
              </div>
              <div class="col-12">
                <table class="table table-bordered mt-3">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Address</th>
                      <th>
                        <v-btn @click="addPerson"> Add Person </v-btn>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(person, index) in additionalPersons"
                      :key="index"
                    >
                      <td>
                        <v-text-field
                          label="Name"
                          v-model="person.name"
                          style="width: 100%"
                        ></v-text-field>
                      </td>
                      <td>
                        <v-row>
                          <v-col cols="12" md="5">
                            <v-text-field
                              label="Street"
                              v-model="person.address.street"
                              style="width: 100%"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="2">
                            <v-text-field
                              label="City"
                              v-model="person.address.city"
                              style="width: 100%"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="2">
                            <v-text-field
                              label="State"
                              v-model="person.address.state"
                              style="width: 100%"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-text-field
                              label="ZIP Code"
                              v-model="person.address.zip"
                              style="width: 100%"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </td>
                      <td>
                        <v-btn
                          color="red lighten-1"
                          @click="removePerson(index)"
                        >
                          Remove
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="row mt-7">
              <span class="font-weight-bold">
                3. Is the land being offered for enrollment used for crop
                (including forest-related) and/or livestock production?
              </span>
            </div>

            <div class="row">
              <div class="col">
                <v-checkbox
                  v-model="usedForCropProduction"
                  label="Crop Production"
                />
                <input
                  required
                  :disabled="!usedForCropProduction"
                  type="text"
                  class="form-control fieldInput"
                  placeholder="List Type"
                  v-model.trim="cropProductionType"
                />
              </div>
              <div class="col">
                <v-checkbox
                  v-model="usedForLivestockProduction"
                  label="Livestock Production"
                />
                <input
                  required
                  :disabled="!usedForLivestockProduction"
                  type="text"
                  class="form-control fieldInput"
                  placeholder="List Type"
                  v-model.trim="livestockProductionType"
                />
              </div>
            </div>

            <div class="row mt-7">
              <span class="font-weight-bold">
                4. The land offered under this enrollment is (check all that
                apply):
              </span>
            </div>

            <div class="row">
              <div class="col">
                <v-checkbox v-model="privateLand" label="Private Land" />
                <v-checkbox
                  v-model="publicLand"
                  label="Public Land (Federal, State, or Local Government)"
                />
                <v-checkbox
                  v-model="tribalOrIndianLand"
                  label="Tribal, Allotted, Ceded or Indian Land"
                />
              </div>
            </div>

            <div class="row mt-7">
              <span class="font-weight-bold">
                5. Participant certifies that control of the land offered under
                this enrollment is evidenced by:
              </span>
            </div>

            <div class="row">
              <div class="col">
                <v-radio-group v-model="landEvidence" mandatory>
                  <v-radio
                    label="Deed or other evidence of land ownership"
                    value="deed"
                  ></v-radio>
                  <v-radio
                    label="Written lease agreement"
                    value="lease"
                  ></v-radio>
                  <div v-if="landEvidence === 'lease'">
                    <v-select
                      label="Start Year"
                      :items="years"
                      v-model="leaseStart"
                      item-text="year"
                      item-value="year"
                    ></v-select>
                    <v-select
                      label="End Year"
                      :items="years"
                      v-model="leaseEnd"
                      item-text="year"
                      item-value="year"
                    ></v-select>
                  </div>
                  <v-radio
                    label="Other Agreement or Legal Conveyance (describe)"
                    value="other"
                  ></v-radio>
                  <div v-if="landEvidence === 'other'">
                    <v-text-field
                      label="Describe"
                      v-model="otherDescription"
                      style="width: 100%"
                    ></v-text-field>
                    <v-select
                      label="Start Year"
                      :items="years"
                      v-model="otherStart"
                      item-text="year"
                      item-value="year"
                    ></v-select>
                    <v-select
                      label="End Year"
                      :items="years"
                      v-model="otherEnd"
                      item-text="year"
                      item-value="year"
                    ></v-select>
                  </div>
                </v-radio-group>
              </div>
            </div>

            <div class="row mt-7">
              <span class="font-weight-bold">
                6. Is the Land offered under this enrollment in any other
                environmental asset (carbon offset, sustainability program,
                scope 3 emission claims, biodiversity claims, or water credits)
                program?
              </span>
            </div>

            <div class="row">
              <div class="col-12 ml-3">
                <v-radio-group v-model="hasEnrolledOnOtherProgram">
                  <v-radio label="Yes" value="yes" />
                  <v-radio label="No" value="no" />
                </v-radio-group>
              </div>

              <div class="col-12">
                <span class="font-weight-bold"> If yes, please describe: </span>
                <div class="input-group">
                  <input
                    :disabled="hasEnrolledOnOtherProgram !== 'yes'"
                    required
                    type="text"
                    class="form-control fieldInput"
                    v-model.trim="otherProgram"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <span>
                Submission of this Application does not guarantee acceptance by
                Arva. Arva reserves the right to accept or reject any
                Application in its sole discretion.<br /><br />
                The Participant understands that once a best-execution plan is
                designed and presented, additional agreements and other matters
                will be required before approval of participation in any Arva
                Environmental Asset Program, including execution of a Grower
                Agreement with Arva, Arva Software Services licenses,
                registration of the land with appropriate authorities, and other
                requirements. Arva does not guarantee that Participant will be
                able to satisfy any or all of these requirements.<br /><br />
                The Participant agrees to work with the Channel Partner to
                submit appropriate data necessary for accurate evaluation of the
                land identified in this Application. It is the responsibility of
                the Participant to provide accurate information to support all
                items addressed in this Application. Participant consents to the
                use of all information submitted to Channel Partner and Arva for
                purposes of evaluating the land in connection with Arva's
                Environmental Asset Programs, and consents to the disclosure of
                any such information to third parties as may be reasonably
                required in Arva's discretion in connection with such
                evaluation. Participant certifies that no other consents are
                required for Arva to use and disclose Participant's information
                as described here.<br /><br />
                The Participant certifies that all information in this
                Application and any accompanying materials provided to the
                Channel Partner or Arva is accurate and complete in all respects
                to the best of Participant's knowledge. Additional information
                on Arva's Environmental Asset Programs may be found online at
                arvaintelligence.com. Specific questions about the programs
                should be directed to the Channel Partner<br /><br />
              </span>
            </div>

            <div class="row">
              <span
                ><strong>Participant Consent and Agreement:</strong><br />
                <v-checkbox
                  v-model="consentCheck"
                  color="green"
                  label="I have read and consent to working with the Channel Partner to
                engage with Arva's Environmental Asset Program."
              /></span>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <ArvaModalSaveFooter
          confirmText="Accept"
          cancelText="Cancel"
          :disabled="disableAcceptBtn"
          @on-cancel="handleCloseModal"
          @on-confirm="handleAccept"
        />
      </div>
    </div>
  </ArvaModal>
</template>

<script>
import _ from "lodash"

import { mapGetters, mapState } from "vuex"
import { Filter } from "@/store/modules"
import ArvaModal from "@/components/modals/ArvaModal"
import ArvaModalSaveFooter from "@/components/modals/ArvaModalSaveFooter"

export default {
  name: "CarbonReadyFieldModal",
  props: ["selectedClient", "user", "org"],
  components: {
    ArvaModal,
    ArvaModalSaveFooter,
  },

  data() {
    return {
      hasError: false,
      errorMessage: null,
      processingEnrollment: false,
      participantName: this.user
        ? this.user.first_name + " " + this.user.last_name
        : null,
      participantAddress: {
        street: null,
        city: null,
        state: null,
        zip: null,
      },
      participantEmail: this.user ? this.user.email : null,
      participantTelephone: this.user ? this.user.mobile_phone_number : null,
      cpName: this.org ? this.org.name : null,
      cpAddress: {
        street: null,
        city: null,
        state: null,
        zip: null,
      },
      cpEmail: this.org ? this.org.corporation.user.email : null,
      cpTelephone: null,
      carbonOffsetCredits: false,
      scope3EmissionsClaims: false,
      sustainabilityProgram: false,
      biodiversityClaims: false,
      selectedType: null,
      privateLand: false,
      publicLand: false,
      tribalOrIndianLand: false,
      entityName: this.selectedClient ? this.selectedClient : null,
      taxId: null,
      hasProofForJO: null,
      showSignatureModal: false,
      hasEnrolledOnOtherProgram: "no",
      consentCheck: false,
      otherProgram: null,
      usedForCropProduction: false,
      usedForLivestockProduction: false,
      livestockProductionType: null,
      cropProductionType: null,
      landEvidence: null,
      leaseStart: null,
      leaseEnd: null,
      otherDescription: null,
      otherStart: null,
      otherEnd: null,
      additionalPersons: [
        {
          name: "",
          address: {
            street: "",
            city: "",
            state: "",
            zip: "",
          },
        },
      ],
      signature: null,
      rules: {
        required: value => !!value || "Field is required",
      },
      years: this.generateYears(2000, 2050),
    }
  },

  computed: {
    ...mapGetters({
      farms: Filter.Getters.getFarms,
      clients: Filter.Getters.getClients,
      getFields: Filter.Getters.getFields,
    }),
    disableAcceptBtn() {
      return (
        !this.consentCheck ||
        !this.participantEmail ||
        !this.participantName ||
        !this.cpName ||
        !this.cpEmail
      )
    },
  },

  methods: {
    generateYears(startYear, endYear) {
      const years = []
      for (let year = startYear; year <= endYear; year++) {
        years.push({ year })
      }
      return years
    },
    handleCloseModal() {
      this.$emit("close-modal")
    },
    addPerson() {
      this.additionalPersons.push({
        name: "",
        address: {
          street: "",
          city: "",
          state: "",
          zip: "",
        },
      })
    },
    removePerson(index) {
      this.additionalPersons.splice(index, 1)
    },
    filterAdditionalPersons() {
      return this.additionalPersons.filter(
        person =>
          person.name !== "" ||
          person.address.street !== "" ||
          person.address.city !== "" ||
          person.address.state !== "" ||
          person.address.zip !== ""
      )
    },
    handleAccept() {
      // const payload = {
      //   participant_name: this.participantName,
      //   participant_address: this.participantAddress,
      //   participant_email: this.participantEmail,
      //   participant_telephone: this.participantTelephone,
      //   cp_name: this.cpName,
      //   cp_address: this.cpAddress,
      //   cp_email: this.cpEmail,
      //   cp_telephone: this.cpTelephone,
      //   carbon_offset_credits: this.carbonOffsetCredits,
      //   scope_3_emissions_claims: this.scope3EmissionsClaims,
      //   sustainability_program: this.sustainabilityProgram,
      //   biodiversity_program: this.biodiversityClaims,
      //   selected_type: this.selectedType,
      //   private_land: this.privateLand,
      //   public_land: this.publicLand,
      //   tribal_or_indian_land: this.tribalOrIndianLand,
      //   signature: this.signature,
      //   entity_name: this.entityName,
      //   tax_id: this.taxId,
      //   has_enrolled_on_other_program: this.hasEnrolledOnOtherProgram,
      //   other_program: this.otherProgram,
      //   has_proof_for_join_operation: this.hasProofForJO,
      //   used_for_crop_production: this.usedForCropProduction,
      //   crop_production_type: this.cropProductionType,
      //   used_for_livestock_production: this.usedForLivestockProduction,
      //   livestock_production_type: this.livestockProductionType,
      //   land_evidence: this.landEvidence,
      //   lease_start: this.leaseStart,
      //   lease_end: this.leaseEnd,
      //   other_start: this.otherStart,
      //   other_end: this.otherEnd,
      //   other_description: this.otherDescription,
      //   additional_persons: this.filterAdditionalPersons(),
      // }
      // this.$emit("accept", payload)
      this.$emit("accept")
      this.processingEnrollment = true
    },
    async handleEnrollment() {
      this.processingEnrollment = true
    },
  },

  watch: {
    hasEnrolledOnOtherProgram(value) {
      if (value === "no") {
        this.otherProgram = null
      }
    },
  },
}
</script>

<style scoped>
.info-text {
  margin: 20px 0px;
  font-size: 15px;
  text-align: center;
}

.span-text {
  padding: 20px;
  font-size: 15px;
  text-align: center;
}

.order-row {
  padding: 0px 140px;
  font-weight: 500;
}

.order-row > div {
  margin: 5px 0px;
}

.agreement {
  padding: 20px 35px;
  background: #f9f9f9;
  border: 1pt solid #f0f0f0;
  max-height: 550px;
  overflow-y: scroll;
}

.agreement .logo {
  background-image: url("/assets/images/logos/arva-logo.png");
  background-size: cover;
  height: 40px;
  width: 120px;
  margin-left: -5px;
  margin-bottom: 12px;
}

.confirm {
  margin-left: 3%;
}

.pdf-link {
  font-weight: 500;
}

.importantText {
  color: green;
}

h1,
h3,
h5 {
  font-weight: bold;
}

.headerText {
  font-weight: bold;
  font-size: 15px;
  display: inline-block;
  width: 105px;
}

.normalText {
  padding-left: 30px;
}

.customizeStepper {
  min-width: 180px;
}

.fieldInput {
  border: 1px solid #050607;
  border-radius: 2px;
  box-shadow: none;
  height: 30px;
  width: 300px;
  font-size: 14px;
  color: #0a0a0d;
  transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
}

.carbonReadyFieldModal {
  width: 1000px;
}

.error-message {
  font-size: 15px;
  font-weight: 500;
  color: red;
}

.input-section > .col {
  padding-right: 5px;
  padding-bottom: 5px;
}
</style>
