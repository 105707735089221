<template>
  <ArvaModal
    name="confirmEnrollmentModal"
    title="Approve Enrollment"
    :wide="true"
    :width="1000"
    @close-modal="handleClose"
  >
    <b-alert v-model="hasError" variant="danger" dismissible>
      {{ errorMessage }}. <br />Please contact Arva Admin for assistance
    </b-alert>

    <div v-if="isProcessing" class="zero-state span-text ml-5">
      <div class="spinner-border spinner-border-sm" role="status"></div>
      <span class="ml-2 text-h6">
        The system is processing your request. Please wait...
      </span>
    </div>

    <div v-else-if="hasApproved" class="zero-state span-text ml-5">
      <span class="text-h6">
        You have approved enrollment
        <span class="green--text text--lighten-1 text-h6">{{
          enrollment.name
        }}</span
        >. <br />
      </span>

      <div v-if="enrollment.enroll_agreement_pdf">
        <span class="text-subtitle-1">
          Please download the Agreement and email this back to us with a
          physical signature at
          <a href="mailto:contracts@arvaintelligence.com"
            >contracts@arvaintelligence.com</a
          >
        </span>
        <br />
        <a
          :href="enrollment.enroll_agreement_pdf"
          target="_blank"
          class="text-subtitle-1"
        >
          <i class="fa fa-download" /> Download Agreement
        </a>
      </div>
    </div>

    <v-card-text v-else class="modal-body">
      <!-- {{ enrollment }} -->
      <div class="ml-2 text-h6">
        Approve Enrollment
        <span class="green--text text--lighten-1">{{ enrollment.name }}</span>
        and begin planning soil sampling?
      </div>
      <div class="ml-2 d-flex" @click="showTable = !showTable">
        <span class="text-subtitle-1"
          >Show {{ enrollmentFields.length }} Fields and their Practices</span
        >
        <v-icon v-if="showTable">mdi-chevron-up</v-icon>
        <v-icon v-else>mdi-chevron-down</v-icon>
      </div>
      <v-simple-table v-if="showTable" class="mt-3" height="400px">
        <thead>
          <tr>
            <th>Name</th>
            <th>Ownership</th>
            <th>Practices</th>
            <th>Start Date</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="field in enrollmentFields"
            :key="field.id"
            :field="field"
            :modalView="true"
            @click="handleSpreadsheetNav(field)"
          >
            <td class="hover">
              {{ field.name }}
              <v-tooltip top
                ><template v-slot:activator="{ on, attrs }"
                  ><v-icon v-bind="attrs" v-on="on">mdi-table</v-icon></template
                ><span>To Data Spreadsheet</span></v-tooltip
              >
            </td>
            <td>
              {{
                enrollment.enrollment_fields.filter(
                  x => x.field_id == field.id
                )[0].ownership
              }}
            </td>
            <td>
              <div v-for="scenario in scenarios(field)" :key="scenario.idx">
                <li v-if="scenario.is_selected">
                  {{ scenario.regen_practice.name }}
                </li>
              </div>
            </td>
            <td>
              {{
                enrollment.enrollment_start_date
                  ? enrollment.enrollment_start_date
                  : ""
              }}
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>
    <v-card-actions v-if="!hasApproved && !isProcessing && !hasError">
      <v-btn class="ml-auto" color="green lighten-1" @click="handleConfirm"
        >Approve</v-btn
      >
      <v-btn class="mr-3" @click="handleClose">Cancel</v-btn>
    </v-card-actions>
  </ArvaModal>
</template>

<script>
import ArvaModal from "./ArvaModal"
import CarbonReadyAPI from "@/api/CarbonReadyAPI"
import { mapMutations } from "vuex"
import { Enrollment } from "@/store/modules"

export default {
  name: "ConfirmEnrollmentModal",
  props: ["activeFields", "enrollment"],
  components: {
    ArvaModal,
  },
  data() {
    return {
      showTable: false,
      hasError: false,
      errorMessage: null,
      isProcessing: false,
      hasApproved: false,
    }
  },
  computed: {
    enrollmentFields() {
      if (this.activeFields && this.enrollment) {
        const enrollmentFieldIds = this.enrollment.enrollment_fields.map(
          f => f.field_id
        )
        const filtered = this.activeFields.filter(field =>
          enrollmentFieldIds.includes(field.id)
        )
        return filtered
      }
      return []
    },
  },
  methods: {
    ...mapMutations({
      setPreviousEnrollment: Enrollment.Mutations.setPreviousEnrollment,
    }),

    handleClose() {
      this.$emit("close-template-modal")
    },
    async handleConfirm() {
      this.isProcessing = true
      this.resetError()
      const payload = this.enrollment
      await CarbonReadyAPI.unenrollField(payload)
        .then(response => {
          const enrollment = response.data
          this.enrollment.status = enrollment.status
          this.enrollment.enroll_agreement_pdf = enrollment.enroll_agreement_pdf

          this.isProcessing = false
          this.hasApproved = true
        })
        .catch(error => {
          this.isProcessing = false
          this.hasError = true
          this.errorMessage = error.response.data
        })
    },
    handleSpreadsheetNav(field) {
      this.setPreviousEnrollment(this.enrollment)
      this.$router.push(`/data-spreadsheet/${field.id}`)
    },
    scenarios(field) {
      if (field.daycent) {
        if (field.daycent[0]) return field.daycent[0].scenario
      }
      return ""
      //if this is inset, maybe just call the fss scenario that matches this field and enrollment/loi directly?
    },
    resetError() {
      this.hasError = false
      this.errorMessage = null
    },
  },
}
</script>
<style scoped>
.hover {
  cursor: pointer;
  color: #0095ff;
  font-weight: 500;
}

.modal-body {
  max-height: 440px;
}
</style>
