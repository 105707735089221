<template>
  <div v-if="rollupLoading['error'] != null" class="rollup-table">
    <p>Error: {{ rollupLoading['error'] }}</p>
  </div>

  <div class="position-relative mb-6" v-else>
    <v-progress-circular v-if="rollupLoading['loading']" indeterminate :size="48" color="#79c61c" />
    
    <div :class="`${rollupLoading['loading'] ? 'loading' : ''}`">
      <v-data-table
        :class="`rollup-table ${groupId == null ? 'overview-rollup' : 'individual-rollup'}`"
        :style="`--custom-table-height: ${tableHeight}px`"
        :headers="groupId == null ? OVERVIEW_ROLLUP_HEADERS : INDIVIDUAL_ROLLUP_HEADERS"
        :items="groupId == null ? overviewRollupRows : individualRollupRows"
        @click:row="applyRollupFilterset"
        @current-items="(items) => filteredItems = items"
        hide-default-footer
        disable-pagination
      >
        <template v-slot:header.groupRequests>
          <div class="d-flex align-center flex-wrap">
            <p class="w-100 ma-0">Group Requests</p>
            <p class="w-100 ma-0">This group's enrollment requests</p>
          </div>
        </template>
  
        <template v-slot:header.groupEnrollments>
          <div class="d-flex align-center flex-wrap">
            <p class="w-100 ma-0">Group Enrollments</p>
            <p class="w-100 ma-0">This group's matched requests</p>
          </div>
        </template>
  
        <template v-slot:header.totalAllotted>
          <div class="d-flex align-center flex-wrap">
            <p class="w-100 ma-0">Allotted</p>
            <p class="w-100 ma-0">Available program space</p>
          </div>
        </template>
  
        <template v-slot:header.totalRequested>
          <div class="d-flex align-center flex-wrap">
            <p class="w-100 ma-0">Total Requested</p>
            <p class="w-100 ma-0">Total enrollment requests</p>
          </div>
        </template>

        <template v-slot:header.totalEnrolled>
          <div class="d-flex align-center flex-wrap">
            <p class="w-100 ma-0">Enrolled</p>
            <p class="w-100 ma-0">AC Matched to programs</p>
          </div>
        </template>

        <template v-slot:header.totalRemaining>
          <div class="d-flex align-center flex-wrap">
            <p class="w-100 ma-0">Remaining</p>
            <p class="w-100 ma-0">AC to be filled</p>
          </div>
        </template>
  
        <template v-slot:item.region="props">
          <span>{{ props.value['value'] }}</span>
        </template>
  
        <template v-slot:item.crops="props">
          <span>{{ props.value['value'].map(c => CROP_DISPLAY_NAME[c]).join(', ') }}</span>
        </template>
  
        <template v-slot:item.groupRequests="{ item, value }">
          <span>{{ numberToLocale(value) }}</span>
          <v-chip v-if="item['groupPendingRequests'] != 0" :class="`ml-2 ${item['groupPendingRequests'] < 0 ? 'red' : 'green'}`">
            {{ item['groupPendingRequests'] < 0 ? '' : '+' }} {{ numberToLocale(item['groupPendingRequests']) }}
          </v-chip>
        </template>
  
        <template v-slot:item.groupEnrollments="{ value }">
          <span>{{ numberToLocale(value) }}</span>
        </template>
  
        <template v-slot:item.totalAllotted="{ value }">
          <span>{{ numberToLocale(value) }}</span>
        </template>
  
        <template v-slot:item.totalRequested="{ item, value }">
          <div class="d-flex align-center">
            <span class="mr-2">{{ numberToLocale(value) }}</span>
            <div v-if="enrollmentTableLoading['loading'] && item['totalPendingRequests'] != 0" class="progress-min-width">
              <v-progress-linear indeterminate color="#E2E8F0" />
            </div>
            <v-chip v-else-if="item['totalPendingRequests'] != 0" :class="`${item['totalPendingRequests'] < 0 ? 'red' : 'green'}`">
              {{ item['totalPendingRequests'] < 0 ? '' : '+' }} {{ numberToLocale(item['totalPendingRequests']) }}
            </v-chip>
          </div>
        </template>

        <template v-slot:item.totalEnrolled="{ value }">
          <span>{{ numberToLocale(value) }}</span>
        </template>

        <template v-slot:item.totalRemaining="{ value }">
          <span>{{ numberToLocale(value) }}</span>
        </template>
      </v-data-table>
  
      <v-simple-table :class="`rollup-table-summary ${groupId == null ? 'overview-rollup' : 'individual-rollup'}`">
        <a @click="toggleShowRollup">
          <img :style="`--flipped: ${showRollupTable ? '0deg' : '180deg'}`" :src="chevronUp">
        </a>
  
        <thead v-if="groupId == null">
          <th></th>
          <th></th>
          <th><span>{{ numberToLocale(overviewSummary['totalAllotted']) }} AC</span></th>
          <th>
            <div class="d-flex align-center">
              <span class="mr-2">{{ numberToLocale(overviewSummary['totalRequested']) }} AC</span>
              <div v-if="enrollmentTableLoading['loading'] && overviewSummary['totalPendingRequests'] != 0" class="progress-min-width">
                <v-progress-linear indeterminate color="#E2E8F0" />
              </div>
              <v-chip
                v-else-if="overviewSummary['totalPendingRequests'] != 0"
                :class="`${overviewSummary['totalPendingRequests'] < 0 ? 'red' : 'green'}`"
              >
                {{ overviewSummary['totalPendingRequests'] < 0 ? '' : '+' }} {{ numberToLocale(overviewSummary['totalPendingRequests']) }}
              </v-chip>
            </div>
          </th>
          <th><span>{{ numberToLocale(overviewSummary['totalEnrolled']) }} AC</span></th>
          <th><span>{{ numberToLocale(overviewSummary['totalRemaining']) }} AC</span></th>
        </thead>

        <thead v-else>
          <th></th>
          <th></th>
          <th>
            <span>{{ numberToLocale(individualSummary['groupRequests']) }} AC</span>
            <v-chip
              v-if="individualSummary['groupPendingRequests'] != 0"
              :class="`ml-2 ${individualSummary['groupPendingRequests'] < 0 ? 'red' : 'green'}`"
            >
              {{ individualSummary['groupPendingRequests'] < 0 ? '' : '+' }} {{ numberToLocale(individualSummary['groupPendingRequests']) }}
            </v-chip>
          </th>
          <th><span>{{ numberToLocale(individualSummary['groupEnrollments']) }} AC</span></th>
          <th><span>{{ numberToLocale(individualSummary['totalAllotted']) }} AC</span></th>
          <th><span>{{ numberToLocale(individualSummary['totalRemaining']) }} AC</span></th>
        </thead>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import chevronUp from "@/assets/images/chevron-up.svg"
import { EnrollmentGroups } from "@/store/modules"
import { mapGetters, mapState } from "vuex"
import { numberToLocale } from "@/utility"
import { CROP_DISPLAY_NAME, US_STATES } from "@/constants"
import {
  ROLLUP_TABLE,
  ENROLLMENT_TABLE,
  REQUESTED,
  REQUESTED_PENDING,
  MATCHED,
  CONFIRMED
} from "@/constants/contractGroups"

const COMMON_HEADERS = [
  {
    text: "Region",
    value: "region",
    filter: v => v['selectedRegions'].length == 0 || v['selectedRegions'].some(r => v['value'].includes(r))
  },
  {
    text: "Crop",
    value: "crops",
    filter: v => v['selectedCrops'].length == 0 || v['selectedCrops'].some(id => v['value'].includes(id))
  }
]
const OVERVIEW_ROLLUP_HEADERS = [
  ...COMMON_HEADERS,
  { text: "", value: "totalAllotted", sortable: false },
  { text: "", value: "totalRequested", sortable: false },
  { text: "", value: "totalEnrolled", sortable: false },
  { text: "", value: "totalRemaining", sortable: false },
]

const INDIVIDUAL_ROLLUP_HEADERS = [
  ...COMMON_HEADERS,
  { text: "", value: "groupRequests", sortable: false },
  { text: "", value: "groupEnrollments", sortable: false },
  { text: "", value: "totalAllotted", sortable: false },
  { text: "", value: "totalRemaining", sortable: false }
]

export default {
  name: "RollupTable",
  components: {},
  emits: ['updateSelected', 'updateRollupTableHeight'],
  props: {
    selectedRegions: { type: Array, required: true },
    selectedCrops: { type: Array, required: true },
  },
  data() {
    return {
      showRollupTable: true,
      filteredItems: [],
      OVERVIEW_ROLLUP_HEADERS,
      INDIVIDUAL_ROLLUP_HEADERS,
      CROP_DISPLAY_NAME,
      numberToLocale,
      chevronUp
    }
  },
  computed: {
    ...mapState({
      groupData: function(state) {
        if (this.groupId != null)
          return state.EnrollmentGroups.groupData[this.groupId]
        return state.EnrollmentGroups.groupData
      },
      rollupData: state => state.EnrollmentGroups.rollupData,
      rollupLoading: state => state.EnrollmentGroups[ROLLUP_TABLE],
      enrollmentTableLoading: state => state.EnrollmentGroups[ENROLLMENT_TABLE]
    }),
    groupId() {
      return this.$router.currentRoute.params['id']
    },
    overviewPendingRequests() {
      const allAcreageValues = Object.values(this.groupData).map(({ acreageValues }) => acreageValues)

      return this.rollupData.map(({ crop_ids, state_name }) => {
        let pendingRequests = 0
        for (const acreageValues of allAcreageValues) {
          for (const cropId of crop_ids) {
            if (cropId in acreageValues[REQUESTED_PENDING]) {
              if (state_name in acreageValues[REQUESTED_PENDING][cropId]) {
                pendingRequests += acreageValues[REQUESTED_PENDING][cropId][state_name]['acreage']
              }
            }
          }
        }

        return pendingRequests ? Math.round(pendingRequests * 100) / 100 : 0
      })
    },
    overviewRollupRows() {
      return this.rollupData.map(({
        crop_ids,
        state_name,
        total_available,
        total_requested
      }, idx) => {
        let stateAbbrev = state_name
        const foundAbbrev = US_STATES.find(({ text }) => text == state_name)
        if (foundAbbrev != null) stateAbbrev = foundAbbrev['value']

        return {
          region: {
            value: stateAbbrev,
            fullName: state_name,
            selectedRegions: this.selectedRegions
          },
          crops: {
            value: crop_ids,
            selectedCrops: this.selectedCrops
          },
          totalAllotted: total_available || 0,
          totalRequested: total_requested || 0,
          totalPendingRequests: this.overviewPendingRequests[idx],
          totalEnrolled: 0, // get clarification, are these two meaning enrolled within these groups
          totalRemaining: 0, // or enrolled as a program (which could involve other cps potentially)
        }
      })
    },
    individualRollupRows() {
      if (this.groupData == null || this.groupData['fieldsupply'] == null) return []

      const { fieldsupply } = this.groupData

      const filteredRollupData = this.rollupData.filter(({ state_name, crop_ids }) => {
        return fieldsupply.some(([_state, _fssId, _status, stateName, cropId]) => 
          stateName == state_name && crop_ids.includes(cropId)
        )
      })

      return filteredRollupData.map(({ crop_ids, state_name, total_available }) => {
        const result = {}

        for (const s of [CONFIRMED, REQUESTED, REQUESTED_PENDING]) {
          for (const cropId of crop_ids) {
            if (cropId in this.groupData['acreageValues'][s]) {
              if (state_name in this.groupData['acreageValues'][s][cropId]) {
                result[s] = this.groupData['acreageValues'][s][cropId][state_name]['acreage']
              }
            }
          }
        }

        let stateAbbrev = state_name
        const foundAbbrev = US_STATES.find(({ text }) => text == state_name)
        if (foundAbbrev != null) stateAbbrev = foundAbbrev['value']

        return {
          region: {
            value: stateAbbrev,
            fullName: state_name,
            selectedRegions: this.selectedRegions
          },
          crops: {
            value: crop_ids,
            selectedCrops: this.selectedCrops
          },
          groupRequests: Math.round(result[REQUESTED] * 100) / 100,
          groupEnrollments: Math.round(result[CONFIRMED] * 100) / 100,
          groupPendingRequests: Math.round(result[REQUESTED_PENDING] * 100) / 100,
          totalAllotted: total_available ? Math.round(total_available * 100) / 100 : 0,
          totalRemaining: 0, // TODO see clarification note above
        }
      })
    },
    overviewSummary() {
      const props = ['totalAllotted', 'totalRequested', 'totalEnrolled', 'totalRemaining', 'totalPendingRequests']
      const {
        totalAllotted,
        totalRequested,
        totalEnrolled,
        totalRemaining,
        totalPendingRequests
      } = this.filteredItems.reduce((accum, cur) => {
        for (const prop of props) accum[prop] += parseFloat(cur[prop])
        return accum
      }, {
        totalAllotted: 0,
        totalRequested: 0,
        totalEnrolled: 0,
        totalRemaining: 0,
        totalPendingRequests: 0
      })

      return {
        region: null,
        crop: null,
        totalAllotted: Math.round(totalAllotted * 100) / 100,
        totalRequested: Math.round(totalRequested * 100) / 100,
        totalEnrolled: Math.round(totalEnrolled * 100) / 100,
        totalRemaining: Math.round(totalRemaining * 100) / 100, // TODO see clarification note above
        totalPendingRequests: Math.round(totalPendingRequests * 100) / 100
      }
    },
    individualSummary() {
      const props = [
        'groupRequests',
        'groupPendingRequests',
        'groupEnrollments',
        'totalAllotted',
        'totalRemaining'
      ]
      const {
        groupRequests,
        groupEnrollments,
        groupPendingRequests,
        totalAllotted,
        totalRemaining
      } = this.filteredItems.reduce((accum, cur) => {
        for (const prop of props) accum[prop] += parseFloat(cur[prop])
        return accum
      }, {
        groupRequests: 0,
        groupEnrollments: 0,
        groupPendingRequests: 0,
        totalAllotted: 0,
        totalRemaining: 0
      })

      return {
        region: null,
        crop: null,
        groupRequests: Math.round(groupRequests * 100) / 100,
        groupPendingRequests: Math.round(groupPendingRequests * 100) / 100,
        groupEnrollments: Math.round(groupEnrollments * 100) / 100,
        totalAllotted: Math.round(totalAllotted * 100) / 100,
        totalRemaining: Math.round(totalRemaining * 100) / 100, // TODO see clarification note above
      }
    },
    tableHeight() {
      if (!this.showRollupTable) return 58
      return this.filteredItems.length >= 3
        ? 179
        : 58 + this.filteredItems.length * 44
    },
  },
  methods: {
    applyRollupFilterset({ crops, region }) {
      this.$emit('updateSelected', {
        crops: crops['value'],
        region: [region['value']]
      })
    },
    toggleShowRollup() {
      this.showRollupTable = !this.showRollupTable
    },
  },
  watch: {
    tableHeight(curr) {
      this.$emit('updateRollupTableHeight', curr)
    }
  }
}
</script>

<style scoped>
.rollup-table {
  border: 1px solid #E5E7EB;
  border-radius: 8px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  overflow-y: scroll;
  --custom-table-height: 179px;
}
.rollup-table-summary {
  border: 1px solid #E5E7EB;
  border-radius: 8px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: none;
}
.rollup-table :deep(table),
.rollup-table-summary :deep(table) {
  table-layout: fixed;
}
.rollup-table > :deep(.v-data-table__wrapper) {
  transition: height 0.25s ease-in-out;
  height: var(--custom-table-height, 179px);
}
.rollup-table :deep(thead th) {
  height: 58px !important;
}
.rollup-table :deep(thead th > div > p:first-of-type) {
  color: #374151;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}
.rollup-table :deep(thead th > div > p:last-of-type) {
  color: #374151;
  font-size: 10px;
  font-style: italic;
  line-height: 14px;
  font-weight: 400;
  white-space: nowrap;
}
.rollup-table.overview-rollup :deep(th:nth-of-type(1)),
.rollup-table-summary.overview-rollup th:nth-of-type(1) {
  width: 100px;
}
.rollup-table.overview-rollup :deep(th:nth-of-type(2)),
.rollup-table-summary.overview-rollup th:nth-of-type(2) {
  width: 160px;
}
.rollup-table.overview-rollup :deep(th:nth-of-type(3)),
.rollup-table-summary.overview-rollup th:nth-of-type(3) {
  width: 175px;
}
.rollup-table.overview-rollup :deep(th:nth-of-type(4)),
.rollup-table-summary.overview-rollup th:nth-of-type(4) {
  width: 250px;
}
.rollup-table.overview-rollup :deep(th:nth-of-type(5)),
.rollup-table-summary.overview-rollup th:nth-of-type(5) {
  width: 200px;
}
.rollup-table.individual-rollup :deep(th:nth-of-type(1)),
.rollup-table-summary.individual-rollup th:nth-of-type(1) {
  width: 100px;
}
.rollup-table.individual-rollup :deep(th:nth-of-type(2)),
.rollup-table-summary.individual-rollup th:nth-of-type(2) {
  width: 160px;
}
.rollup-table.individual-rollup :deep(th:nth-of-type(3)),
.rollup-table-summary.individual-rollup th:nth-of-type(3) {
  width: 230px;
}
.rollup-table.individual-rollup :deep(th:nth-of-type(4)),
.rollup-table-summary.individual-rollup th:nth-of-type(4),
.rollup-table.individual-rollup :deep(th:nth-of-type(5)),
.rollup-table-summary.individual-rollup th:nth-of-type(5) {
  width: 200px;
}
.rollup-table :deep(tbody td) {
  height: 44px !important;
}
.rollup-table :deep(tr td:nth-of-type(2)) {
  white-space: nowrap;
}
.rollup-table-summary thead th {
  color: #20292F !important;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: normal;
  padding: 0 16px;
  height: 64px;
}
.rollup-table-summary thead th:nth-of-type(n+3) {
  font-weight: 700 !important;
}
.rollup-table :deep(tbody tr) {
  cursor: pointer;
}
.rollup-table-summary :deep(.v-data-table__wrapper > table > a) {
  display: flex;
  width: 96px;
  height: 64px;
  position: absolute;
  right: 1px;
  bottom: 1px;
  cursor: pointer;
  justify-content: flex-end;
}
.rollup-table-summary :deep(.v-data-table__wrapper > table > a img) {
  transform: rotate(var(--flipped, 0deg));
  transition: transform 0.25s ease-in-out;
  width: 36px;
  height: auto;
  margin-right: 36px;
}
.v-chip.v-size--default {
  height: 20px !important;
}
.v-chip :deep(span) {
  font-size: 12px;
}
.v-chip.green {
  background: #F1FDDF !important;
}
.v-chip.green :deep(span) {
  color: #61B100;
}
.v-chip.red {
  background: #FEF2F2 !important;
}
.v-chip.red :deep(span) {
  color: #EF4444;
  font-weight: bold;
}
.v-progress-circular {
  position: absolute;
  left: calc(50% - 32px);
  top: calc(50% - 32px);
  z-index: 1;
}
.loading {
  opacity: 0.50;
  pointer-events: none;
}
.progress-min-width {
  width: 80px;
}
</style>