<template>
  <v-card class="mx-auto ma-3" max-width="80%">
    <v-card-title>
      <h1>
        <span class="text-red">This Feature Requires Additional Data!</span>
      </h1>
    </v-card-title>
    <v-card-text class="py-0">
      <v-row align="center" no-gutters>
        <v-col cols="6">
          <h3>To Update This Page Please:</h3>
          <v-list :items="items"></v-list>

          <v-list class="text-h6">
            <v-list-item v-for="subItem in items" :key="subItem.title">
              <v-list-item-content>{{ subItem.title }}</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>

        <v-col cols="6" class="text-center">
          <v-icon color="error" size="200">mdi-barley-off</v-icon>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "PageMissing",
  data() {
    return {
      items: [
        { title: "1) Upload Machine Data", value: 0 },
        { title: "2) Connect an Equipment Repository", value: 1 },
        { title: "3) Set / Confirm Field Level Default Values", value: 2 },
        {
          title:
            "4) Select Fields Where at Least One of the Above has been Completed",
          value: 3,
        },
        {
          title:
            "5) Select a Year Where are Least One of the Above has been Completed",
          value: 4,
        },
      ],
    }
  },
}
</script>
