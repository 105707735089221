import Vue from 'vue'
import { EnrollmentGroups } from "@/store/modules"
import {
  getRollup,
  listOverview,
  getFssMetadata,
  submitSupplyRequests,
  applyPendingSupplyStates,
} from "@/api/ContractGroupAPI"
import { 
  NO,
  YES,
  REQUESTED,
  CONFIRMED,
  ROLLUP_TABLE,
  FSS_INDIVIUAL,
  REQUESTED_PENDING,
  ENROLLMENT_TABLE,
  BLANK_METADATA,
  BLANK_ACREAGE_VALUES
} from "@/constants/contractGroups"
import { CROP_DISPLAY_NAME, US_STATES } from "@/constants"

const parseGroupData = (groupData) => {
  const parsedData = {}

  for (const [id, { name, timestamps, snapshot, fieldsupply }] of Object.entries(groupData)) {
    const acreageValues = JSON.parse(JSON.stringify(BLANK_ACREAGE_VALUES))
    const cropRegionMap = {}
    const supplyChanges = {}
    supplyChanges[YES] = []
    supplyChanges[NO] = []

    fieldsupply.forEach(([supplyState, fssId, status, stateName, cropId, _, acreage, metadata=null], idx) => {
      if (metadata == null) fieldsupply[idx].push({ ...BLANK_METADATA })

      if (!cropRegionMap.hasOwnProperty(cropId)) {
        cropRegionMap[cropId] = []
        acreageValues[CONFIRMED][cropId] = {}
        acreageValues[REQUESTED][cropId] = {}
        acreageValues[REQUESTED_PENDING][cropId] = {}
      }
      if (!cropRegionMap[cropId].includes(stateName)) {
        cropRegionMap[cropId].push(stateName)
        acreageValues[CONFIRMED][cropId][stateName] = { acreage: 0, numFields: 0 }
        acreageValues[REQUESTED][cropId][stateName] = { acreage: 0, numFields: 0 }
        acreageValues[REQUESTED_PENDING][cropId][stateName] = { acreage: 0, numFields: 0 }
      }

      const otherState = supplyState == YES ? NO : YES

      // everything w/o a snapshot is a 'change'
      // if a snapshot exists, 'yes' group supply in a 'no' snapshot supply is a 'change'
      // i.e. a group supply change has happened that hasn't been saved to a snapshot yet
      if (snapshot == null) {
        supplyChanges[supplyState].push(fssId)
      }
      else {
        if (snapshot[`${otherState}_fieldsupply`].includes(fssId)) {
          // group supply 'yes', snapshot 'no', for example, is a change to 'yes'
          // that hasn't been realized yet
          supplyChanges[supplyState].push(fssId)
        }
        else if (!snapshot[`${supplyState}_fieldsupply`].includes(fssId)) {
          // fss isn't in any of the snapshot supply, so we must have submitted a partial crop-only snapshot
          // so this would count as a supply change since it's in the group but not the snapshot
          supplyChanges[supplyState].push(fssId)
        }
      }

      // NOTE: the below steps should amalgamate into the above steps
      //
      // supply can be counted for both requested/confirmed AND for pending changes
      // i.e. is in a requested state + is pending request removal
      if (status == REQUESTED) {
        acreageValues[REQUESTED][cropId][stateName]['acreage'] += acreage
        acreageValues[REQUESTED][cropId][stateName]['numFields'] += 1
      }
      if (status == CONFIRMED) {
        acreageValues[CONFIRMED][cropId][stateName]['acreage'] += acreage
        acreageValues[CONFIRMED][cropId][stateName]['numFields'] += 1
      }

      for (const supplyState of [YES, NO]) {
        if (supplyChanges[supplyState].includes(fssId)) {
          if (supplyState == YES) {
            acreageValues[REQUESTED_PENDING][cropId][stateName]['acreage'] += acreage
            acreageValues[REQUESTED_PENDING][cropId][stateName]['numFields'] += 1
          }
          if (supplyState == NO) {
            if (snapshot != null && snapshot['yes_fieldsupply'].includes(fssId)) {
              // check if there's a previous snapshot. If there's no snapshot, don't count
              // the 'no's, as you can't subtract from 0 here.
              // Also, because we are allowing partial snapshots, we need to confirm that this fss Id exists
              // in the opposite 'yes' field supply in the snapshot. If it doesn't, we shouldn't count it
              // as it hasn't been submitted at all yet.
              acreageValues[REQUESTED_PENDING][cropId][stateName]['acreage'] -= acreage
              acreageValues[REQUESTED_PENDING][cropId][stateName]['numFields'] += 1
            }
          }
        }
      }
    })

    fieldsupply.sort((a, b) => a[5].localeCompare(b[5])) // fieldcrop.field.name

    const crops = Object.keys(cropRegionMap).map(c => Number(c))
    const regions = Object.values(cropRegionMap).reduce((acc, curr) => {
      for (const region of curr) {
        if (!acc.includes(region)) acc.push(region)
      }

      return acc
    }, [])

    parsedData[id] = {
      name,
      crops,
      regions,
      cropRegionMap,
      snapshot,
      timestamps,
      fieldsupply,
      supplyChanges,
      acreageValues
    }
  }

  return parsedData
}

const tableLoadingState = {}
tableLoadingState[ENROLLMENT_TABLE] = { loading: true, error: null }
tableLoadingState[ROLLUP_TABLE] = { loading: true, error: null }
tableLoadingState[FSS_INDIVIUAL] = { loading: true, error: null }

const state = {
  ...tableLoadingState,
  groupData: {},
  rollupData: [],
  spoofingCP: true
}

const getters = {
  [EnrollmentGroups.Getters.allUniqueRegions]: state => {
    return Object.values(state.groupData)
      .reduce((accum, { regions }) => {
        for (const region of regions) {
          const found = US_STATES.find(({ text }) => text == region)
          if (found == null && !accum.includes('Canada')) accum.push('Canada')
          if (found != null && !accum.includes(found['value'])) accum.push(found['value'])
        }
        
        return accum
      }, [])
      .sort()
  },
  [EnrollmentGroups.Getters.allUniqueCrops]: state => {
    return Object.values(state.groupData)
      .reduce((accum, { crops }) => {
        for (const cropId of crops) {
          if (!accum.includes(cropId)) accum.push(cropId)
        }

        return accum
      }, [])
      .sort(id => CROP_DISPLAY_NAME[id])
  }
}

const mutations = {
  [EnrollmentGroups.Mutations.setSpoofingCP](state, spoofing) {
    state.spoofingCP = spoofing
  },
  [EnrollmentGroups.Mutations.setRollupData](state, rollupData) {
    Vue.set(state, 'rollupData', rollupData)
  },
  [EnrollmentGroups.Mutations.setAllGroupData](state, groupData) {
    state.groupData = Object.freeze(groupData)
  },
  [EnrollmentGroups.Mutations.setFssLoading](state, { fssIds, loading, error=null }) {
    const newLoading = { ...state[FSS_INDIVIUAL] }
    for (const fssId of fssIds) newLoading[fssId] = { loading, error }
    state[FSS_INDIVIUAL] = Object.freeze(newLoading)
  },
  [EnrollmentGroups.Mutations.setFssMetadata](state, { groupId, data }) {
    const newState = { ...state.groupData }
    const newGroupData = newState[groupId]
    
    for (const [fssId, practices, farmName, thumbnail] of data) {
      newGroupData['fieldsupply'].find(([_, id]) => id == fssId).splice(-1, 1, {
        practices,
        farmName,
        thumbnail
      })
    }

    state.groupData = Object.freeze(newState)
  },
  [EnrollmentGroups.Mutations.setIndividualGroupData](state, groupData) {
    state.groupData = Object.freeze({ ...state.groupData, ...groupData })
  },
  [EnrollmentGroups.Mutations.setGroupSupplyChanges](state, { groupId, supplyState, fssIds }) {
    const idx = state.groupData.findIndex(g => g['id'] == groupId)
    if (idx != -1) {
      Vue.set(state.groupData[idx]['supplyChanges'], supplyState, fssIds)
    }
  },
  [EnrollmentGroups.Mutations.setTableLoadingState](state, { target, loading, error=null }) {
    Vue.set(state, target, { loading, error })
  },
}

const actions = {
  [EnrollmentGroups.Actions.listOverview]({ commit, dispatch, rootState }) {
    return new Promise((resolve, reject) => {
      const { year, organization } = rootState.Organization
      if (year == null || organization == null || !('id' in organization)) {
        reject(`Organization or year is null: ${organization}, ${year}`)
        return
      }

      dispatch(EnrollmentGroups.Actions.fetchRollup)
  
      commit(EnrollmentGroups.Mutations.setTableLoadingState, { target: ROLLUP_TABLE, loading: true });
      commit(EnrollmentGroups.Mutations.setTableLoadingState, { target: ENROLLMENT_TABLE, loading: true });
  
      listOverview({ org_node_id: organization.id, year })
      .then(({ data }) => {
        commit(EnrollmentGroups.Mutations.setAllGroupData, parseGroupData(data));
        commit(EnrollmentGroups.Mutations.setTableLoadingState, { target: ENROLLMENT_TABLE, loading: false });
        resolve()
      })
      .catch(error => {
        console.log("Error fetching group data overview: ", error)
        commit(EnrollmentGroups.Mutations.setTableLoadingState, { target: ENROLLMENT_TABLE, loading: false, error });
        reject(error)
      })
    })
  },
  [EnrollmentGroups.Actions.fetchFssMetadata]({ commit, state, rootState }, { groupId }) {
    return new Promise((resolve, reject) => {
      const { year, organization } = rootState.Organization
      if (year == null || organization == null || !('id' in organization)) {
        reject(`Organization or year is null: ${organization}, ${year}`)
        return
      }

      const fssIds = state.groupData[groupId].fieldsupply.map(([_, id]) => id)
      commit(EnrollmentGroups.Mutations.setTableLoadingState, { target: ENROLLMENT_TABLE, loading: true });

      getFssMetadata({ fss_ids: fssIds, org_node_id: organization.id, year })
      .then(({ data }) => {
        commit(EnrollmentGroups.Mutations.setFssMetadata, { groupId, data })
        commit(EnrollmentGroups.Mutations.setTableLoadingState, { target: ENROLLMENT_TABLE, loading: false });
        resolve()
      })
      .catch(error => {
        console.log("Error fetching group metadata: ", error)
        commit(EnrollmentGroups.Mutations.setTableLoadingState, { target: ENROLLMENT_TABLE, loading: false, error });
        reject(error)
      })
    })
  },
  [EnrollmentGroups.Actions.fetchRollup]({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      const { year, organization } = rootState.Organization
      if (year == null || organization == null || !('id' in organization)) {
        reject(`Organization or year is null: ${organization}, ${year}`)
        return
      }
    
      getRollup({ org_node_id: organization.id, year })
      .then(({ data }) => {
        commit(EnrollmentGroups.Mutations.setRollupData, data);
        commit(EnrollmentGroups.Mutations.setTableLoadingState, { target: ROLLUP_TABLE, loading: false });
        resolve()
      })
      .catch(error => {
        console.log("Error fetching program rollup: ", error)
        commit(EnrollmentGroups.Mutations.setTableLoadingState, { target: ROLLUP_TABLE, loading: false, error });
        reject(error)
      })
    })
  },
  [EnrollmentGroups.Actions.submitSupplyChanges]({ commit, dispatch, state }, { groupId=null, groupIdCropKeys=null }) {
    const parsedIds = []

    if (groupId == null && groupIdCropKeys != null) {
      parsedIds.push(...groupIdCropKeys.map(k => k.split('-').map(s => Number(s))))
    }

    if (groupId != null && groupIdCropKeys == null) {
      parsedIds.push([groupId, null])
    }
    
    commit(EnrollmentGroups.Mutations.setTableLoadingState, { target: ENROLLMENT_TABLE, loading: true });
    
    Promise.all(
      parsedIds.map(([group_id, cropId]) => {
        return new Promise((resolve, reject) => {
          const groupData = state.groupData[group_id]

          const getFss = (fssId) => groupData['fieldsupply'].find(([_, id, __, ___, crop_id]) =>
            fssId == id && (cropId == null || cropId == crop_id)
          )

          submitSupplyRequests({
            group_id,
            fss_to_request: groupData['supplyChanges'][YES].filter(getFss),
            fss_to_available: groupData['supplyChanges'][NO].filter(getFss),
            crop_specific_snapshot: groupId == null
          })
          .then(({ data }) => {
            const toParse = {}
            toParse[group_id] = data
            const parsedData = parseGroupData(toParse)
            commit(EnrollmentGroups.Mutations.setIndividualGroupData, parsedData)
            commit(EnrollmentGroups.Mutations.setTableLoadingState, { target: ENROLLMENT_TABLE, loading: false });
            resolve()
          })
          .catch(error => {
            console.log("Error fetching group data after supply submission", error)
            commit(EnrollmentGroups.Mutations.setTableLoadingState, { target: ENROLLMENT_TABLE, loading: false, error });
            reject(error)
          })
        })
      })
    )
    .then(_ => dispatch(EnrollmentGroups.Actions.fetchRollup))
  },
  [EnrollmentGroups.Actions.updatePendingFssState]({ commit }, { groupId, fssIds, supplyState }) {
    applyPendingSupplyStates({ fss_ids: fssIds, group_id: groupId, supplyState })
    .then(({ data }) => {
      const newGroupData = {}
      newGroupData[groupId] = { ...state.groupData[groupId] }
      newGroupData[groupId]['supplyChanges'][NO] = []
      newGroupData[groupId]['supplyChanges'][YES] = []

      commit(EnrollmentGroups.Mutations.setTableLoadingState, { target: ENROLLMENT_TABLE, loading: true });

      for (let i=0; i<newGroupData[groupId]['fieldsupply'].length; i++) {
        const [supplyState, fssId] = newGroupData[groupId]['fieldsupply'][i]
        const otherState = supplyState == YES ? NO : YES

        if (data[`${otherState}_fieldsupply`].includes(fssId)) {
          newGroupData[groupId]['fieldsupply'][i][0] = otherState
        }
      }

      commit(EnrollmentGroups.Mutations.setIndividualGroupData, parseGroupData(newGroupData))
      commit(EnrollmentGroups.Mutations.setTableLoadingState, { target: ENROLLMENT_TABLE, loading: false });
    })
  },
}

export default {
    state,
    mutations,
    getters,
    actions
  }
