<template>
  <tr>
    <td>
      <img :src="field.boundary_image" style="width: 100px" />
    </td>
    <td>
      {{ field.name }}
    </td>
    <td>{{ field.acreage | prettyInteger }}</td>
    <td>{{ field.farm.name }}</td>
    <td>
      <div v-for="report in reports" :key="report.id">
        <div v-if="report.pdf_filename.includes(year)">
          <i class="fa fa-file-pdf-o" /><a
            class="report-link"
            :href="report.report_pdf"
            target="blank"
            >{{ report.pdf_filename }}</a  
          >
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "FieldReportsListRow",
  props: ["field"],

  computed: {
    ...mapState({
      year: state => state.Organization.year,
    }),

    reports() {
      const reports = [...this.field.reports]
      return reports.sort((a, b) => {
        if (a.pdf_filename > b.pdf_filename) return -1
        if (a.pdf_filename < b.pdf_filename) return 1
        return 0
      })
    },
  },
}
</script>

<style scoped>
.fa-file-pdf-o {
  margin-right: 8px;
}

.report-link {
  font-weight: 500;
}
</style>
