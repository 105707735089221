<template>
  <div v-if="loading[ACTIVE_EVIDENCING_GROUP]">
    <v-progress-circular indeterminate :size="48" color="#79c61c" />
  </div>
  <div v-else class="relative-position-wrapper">
    <div class="evidencing-dashboard">
      <div class="evidencing-dashboard-head">
        <h1>
          <a @click="router.push('/evidencing/')"><v-icon>mdi-chevron-left</v-icon></a>
          {{ getEvidencingGroupName }}
        </h1>
        <!-- <h2></h2> -->

        <EvidencingPageTabs :activeBtn="EVIDENCING_DASHBOARD" />
        <EvidencingActionButtons :activeBtn="EVIDENCING_DASHBOARD" />
      </div>

      <div class="evidencing-dashboard-statistics">
        <StatisticCard
          title="Requests submitted"
          :statistic="getPercentSubmitted"
          :subtitle="getSubmittedSubtitle"
        />

        <StatisticCard
          title="Fulfilled by user data"
          :statistic="getPercentFulfilledUserData"
          :subtitle="getFulfilledUserDataSubtitle"
        />

        <StatisticCard
          title="Fulfilled by machine data"
          :statistic="getPercentFulfilledMachineData"
          :subtitle="getFulfilledMachineDataSubtitle"
        />

        <StatisticCard
          title="Requests validated"
          :statistic="getPercentValidated"
          :subtitle="getValidatedSubtitle"
        />
      </div>
  
      <div class="evidencing-dashboard-info">
        <p>View this evidencing group's statuses, edit metadata, and configure evidencing that is included in the grouping, as well as fields and crops.</p>
      </div>

      <div class="evidencing-progress-viewer">
        <v-btn-toggle v-model="activeTable" mandatory group tile color="#000000" class="d-flex flex-wrap evidencing-toggler">
          <v-btn height="32px" :value="EVIDENCING_BY_PRACTICE">Practice</v-btn>
          <v-btn height="32px" :value="EVIDENCING_BY_FIELDCROP">Field/Crop</v-btn>
        </v-btn-toggle>

        <div class="evidencing-table-wrapper">
          <v-simple-table v-if="activeTable == EVIDENCING_BY_PRACTICE">
            <thead>
              <tr>
                <th data-column="practice">Practice</th>
                <th data-column="fields">Fields with this practice</th>
                <th data-column="asapplied">Completed by API or As-Applied files</th>
                <th data-column="manual">Completed by Manually Uploaded documents</th>
                <th data-column="progress">Total Progress</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="evidencingType, idx in evidencingTypeChoicesSorted" :key="evidencingType + '-' + idx">
                <td>{{ evidencingType['name'] }}</td>
                <td>
                  <p class="number-display">
                    {{ completionByPractice[evidencingType['value']] ? completionByPractice[evidencingType['value']]['fieldIds'].length : 0 }}
                  </p>
                </td>
                <td>
                  <p class="number-display">
                    {{ 
                      completionByPractice[evidencingType['value']]
                        ? completionByPractice[evidencingType['value']]['submittedByMachineData'].length
                        : 0
                    }}
                  </p>
                </td>
                <td>
                  <p class="number-display">
                    {{ 
                      completionByPractice[evidencingType['value']]
                        ? completionByPractice[evidencingType['value']]['submittedByUserData'].length
                        : 0
                    }}
                  </p>
                </td>
                <td>
                  <v-progress-linear
                    v-if="Math.floor((completionByPractice[evidencingType['value']]['submitted'].length / completionByPractice[evidencingType['value']]['evidencing'].length) * 100) > 0"
                    :value="Math.floor((completionByPractice[evidencingType['value']]['submitted'].length / completionByPractice[evidencingType['value']]['evidencing'].length) * 100)"
                    :color="getProgressColor(Math.floor((completionByPractice[evidencingType['value']]['submitted'].length / completionByPractice[evidencingType['value']]['evidencing'].length) * 100))"
                    height="32"
                  >
                    <template v-slot:default="{ value }">
                      <span class="progress-span" :style="{ color: getProgressSpanColor(value), '--margin-left-span': `${value-8}%` }">{{ value }}%</span>
                    </template>
                  </v-progress-linear>

                  <div v-else class="zero-percent-placeholder"></div>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
  
          <v-simple-table v-else-if="activeTable == EVIDENCING_BY_FIELDCROP">
            <thead>
              <tr>
                <th data-column="fieldid">Field ID</th>
                <th data-column="fieldname">Field Name</th>
                <th data-column="fields">Crops</th>
                <th data-column="totalevidencing">Total Evidencing Requests</th>
                <th data-column="asapplied">Completed by API or As-Applied files</th>
                <th data-column="manual">Completed by Manually Uploaded documents</th>
                <th data-column="progress">Total Progress</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="fieldId, idx in allFieldsSorted" :key="fieldId + '-f' + idx">
                <td>{{ fieldId }}</td>
                <td>{{ completionByFieldCrop[fieldId] ? completionByFieldCrop[fieldId]['name'] : 'None' }}</td>
                <td>
                  <p class="crops-display" v-for="crop, cIdx in completionByFieldCrop[fieldId]['crops']" :key="crop['id'] + '--' + cIdx">
                    {{ crop['name'] }}
                  </p>
                </td>
                <td>
                  <p class="number-display">{{ completionByFieldCrop[fieldId]['evidencing'].length }}</p>
                </td>
                <td>
                  <p class="number-display">
                    {{ 
                      completionByFieldCrop[fieldId]
                        ? completionByFieldCrop[fieldId]['submittedByMachineData'].length
                        : 0
                    }}
                  </p>
                </td>
                <td>
                  <p class="number-display">
                    {{ 
                      completionByFieldCrop[fieldId]
                        ? completionByFieldCrop[fieldId]['submittedByUserData'].length
                        : 0
                    }}
                  </p>
                </td>
                <td>
                  <v-progress-linear
                    :value="Math.floor((completionByFieldCrop[fieldId]['submitted'].length / completionByFieldCrop[fieldId]['evidencing'].length) * 100)"
                    :color="getProgressColor(Math.floor((completionByFieldCrop[fieldId]['submitted'].length / completionByFieldCrop[fieldId]['evidencing'].length) * 100))"
                    height="32"
                  >
                    <template v-slot:default="{ value }">
                      <span class="progress-span" :style="{ color: getProgressSpanColor(value), '--margin-left-span': `${value-8}%` }">{{ value }}%</span>
                    </template>
                </v-progress-linear>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import EvidencingActionButtons from "@/components/evidencing/EvidencingActionButtons";
import EvidencingPageTabs from "../components/evidencing/EvidencingPageTabs";
import StatisticCard from "../components/misc/StatisticCard";
import { mapGetters, mapState } from "vuex";
import { Evidencing } from "@/store/modules";
import { EVIDENCING_TYPE_CHOICES } from "@/constants/defaults";
import {
  EVIDENCING_DASHBOARD,
  EVIDENCING_BY_PRACTICE,
  EVIDENCING_BY_FIELDCROP,
  ACTIVE_EVIDENCING_GROUP,
  EVIDENCING_SOURCES,
  EVIDENCING_SOURCE_DETAILS,
  EVIDENCING_STATUSES
} from "@/constants/evidencing";

export default {
  name: "EvidencingGroupDashboard",
  components: {
    EvidencingActionButtons,
    EvidencingPageTabs,
    StatisticCard,
  },
  data() {
    return {
      activeTable: EVIDENCING_BY_PRACTICE,
      router,
      EVIDENCING_DASHBOARD,
      EVIDENCING_BY_PRACTICE,
      EVIDENCING_BY_FIELDCROP,
      ACTIVE_EVIDENCING_GROUP,
      EVIDENCING_TYPE_CHOICES
    }
  },
  computed: {
    ...mapGetters({
      allFields: Evidencing.Getters.allFields
    }),
    ...mapState({
      user: state => state.User.user,
      loading: state => state.Evidencing.loading,
      activeEvidencingGroup: state => state.Evidencing.activeEvidencingGroup,
      viewingOrgWideEvidencing: state => state.Evidencing.viewingOrgWideEvidencing
    }),
    allFieldsSorted() {
      const allFieldsSorted = [];
      const completionByFieldCrop = { ...this.completionByFieldCrop };

      for (const fieldId in completionByFieldCrop) {
        const ratio = completionByFieldCrop[fieldId]['evidencing'].length > 0
          ? completionByFieldCrop[fieldId]['submitted'].length / completionByFieldCrop[fieldId]['evidencing'].length
          : 0
        allFieldsSorted.push([fieldId, ratio])
      }

      allFieldsSorted.sort((a, b) => b[1] - a[1]);

      const result = allFieldsSorted.map(([fieldId, _]) => fieldId);
      return result
    },
    evidencingTypeChoicesSorted() {
      const evidencingTypesSorted = [];
      const completionByPractice = { ...this.completionByPractice };

      // sort by completion status
      for (const evidencingType in completionByPractice) {
        const ratio = completionByPractice[evidencingType]['evidencing'].length > 0
          ? completionByPractice[evidencingType]['submitted'].length / completionByPractice[evidencingType]['evidencing'].length
          : 0
        evidencingTypesSorted.push([evidencingType, ratio])
      }

      evidencingTypesSorted.sort((a, b) => b[1] - a[1]);

      const result = evidencingTypesSorted.map(([value, _]) => EVIDENCING_TYPE_CHOICES.find(e => e['value'] == value));
      return result
    },
    getEvidencingValidated() {
      if (this.activeEvidencingGroup == null) return [];
      return this.activeEvidencingGroup['confirmations'].filter(({ status }) => status == EVIDENCING_STATUSES.VALIDATED);
    },
    getEvidencingWithUploads() {
      if (this.activeEvidencingGroup == null) return [];
      return this.activeEvidencingGroup['confirmations'].filter(({ status }) => status != EVIDENCING_STATUSES.UNSUBMITTED);
    },
    getPercentValidated() {
      if (this.activeEvidencingGroup == null) return '0%';
      return `${Math.floor((this.getEvidencingValidated.length / this.activeEvidencingGroup['confirmations'].length) * 100)}%`;
    },
    getPercentSubmitted() {
      if (this.activeEvidencingGroup == null) return '0%';
      return `${Math.floor((this.getEvidencingWithUploads.length / this.activeEvidencingGroup['confirmations'].length) * 100)}%`;
    },
    getSubmittedSubtitle() {
      if (this.activeEvidencingGroup == null) return '0';
      return `${this.getEvidencingWithUploads.length} / ${this.activeEvidencingGroup['confirmations'].length} Requests`;
    },
    getValidatedSubtitle() {
      if (this.activeEvidencingGroup == null) return '0';
      return `${this.getEvidencingValidated.length} / ${this.activeEvidencingGroup['confirmations'].length} Requests`;
    },
    getEvidencingFulfilledViaMachineData() {
      if (this.activeEvidencingGroup == null) return [];
      return this.activeEvidencingGroup['confirmations']
      .filter(({ confirmation_source, confirmation_source_details, status }) => {
        return (
          confirmation_source == EVIDENCING_SOURCES.SHAPEFILE 
          && confirmation_source_details == EVIDENCING_SOURCE_DETAILS.MACHINE_DATA
          && (
            status == EVIDENCING_STATUSES.SUBMITTED || status == EVIDENCING_STATUSES.VALIDATED
          )
        )
      });
    },
    getPercentFulfilledMachineData() {
      if (this.activeEvidencingGroup == null) return '0%';
      return `${Math.floor((this.getEvidencingFulfilledViaMachineData.length / this.activeEvidencingGroup['confirmations'].length) * 100)}%`;
    },
    getFulfilledMachineDataSubtitle() {
      if (this.activeEvidencingGroup == null) return '0';
      return `${this.getEvidencingFulfilledViaMachineData.length} / ${this.activeEvidencingGroup['confirmations'].length} Requests`;
    },
    getEvidencingFulfilledUserData() {
      if (this.activeEvidencingGroup == null) return [];
      return this.activeEvidencingGroup['confirmations']
      .filter(({ id, status }) => {
        if (
          this.getEvidencingFulfilledViaMachineData.find(e => e['id'] == id) == null
          && (status == EVIDENCING_STATUSES.SUBMITTED || status == EVIDENCING_STATUSES.VALIDATED)
        ) return true;
        return false;
      });
    },
    getPercentFulfilledUserData() {
      if (this.activeEvidencingGroup == null) return '0%';
      return `${Math.floor((this.getEvidencingFulfilledUserData.length / this.activeEvidencingGroup['confirmations'].length) * 100)}%`;
    },
    getFulfilledUserDataSubtitle() {
      if (this.activeEvidencingGroup == null) return '0';
      return `${this.getEvidencingFulfilledUserData.length} / ${this.activeEvidencingGroup['confirmations'].length} Requests`;
    },
    getEvidencingGroupName() {
      if (this.activeEvidencingGroup != null && 'name' in this.activeEvidencingGroup) {
        return this.activeEvidencingGroup['name'];
      }

      return "Not Specified";
    },
    completionByFieldCrop() {
      if (this.activeEvidencingGroup == null) return {}

      const fieldsSpec = {};

      for (const field of this.allFields) {
        const newFieldSpec = {
          name: field['name'],
          crops: [],
          evidencing: [],
          submitted: [],
          submittedByUserData: [],
          submittedByMachineData: []
        }

        newFieldSpec['evidencing'] = this.activeEvidencingGroup['confirmations'].filter(({ fields }) => {
          const found = fields.find(({ id }) => id == field['id']);
          return found != null
        });

        newFieldSpec['crops'] = newFieldSpec['evidencing'].reduce((acc, val) => {
          for (const crop of val['crops']) {
            const found = acc.find(({ id }) => id == crop['id']);
            if (found == null) acc.push(crop);
          }

          return acc
        }, []);

        newFieldSpec['submitted'] = newFieldSpec['evidencing'].filter(({ status }) => 
          status == EVIDENCING_STATUSES.SUBMITTED || status == EVIDENCING_STATUSES.VALIDATED);
        
        for (const evidencing of newFieldSpec['submitted']) {
          if (
            evidencing['confirmation_source'] == EVIDENCING_SOURCES.SHAPEFILE 
            && evidencing['confirmation_source_details'] == EVIDENCING_SOURCE_DETAILS.MACHINE_DATA
          ) {
            newFieldSpec['submittedByMachineData'].push(evidencing)
          }
          else {
            newFieldSpec['submittedByUserData'].push(evidencing);
          }
        }

        fieldsSpec[field['id']] = newFieldSpec;
      }

      return fieldsSpec
    },
    completionByPractice() {
      if (this.activeEvidencingGroup == null) return {}

      const practiceSpec = {};
      
      for (const practice of EVIDENCING_TYPE_CHOICES) {
        const newSpec = {
          name: practice['name'],
          value: practice['value'],
          evidencing: [],
          submitted: [],
          submittedByUserData: [],
          submittedByMachineData: [],
          fieldIds: []
        }

        newSpec['evidencing'] = this.activeEvidencingGroup['confirmations']
          .filter(({ confirmation_type }) => confirmation_type == practice['value']);

        for (const { fields } of newSpec['evidencing']) {
          for (const field of fields) {
            if (!newSpec['fieldIds'].includes(field['id'])) {
              newSpec['fieldIds'].push(field['id'])
            }
          }
        }

        newSpec['submitted'] = newSpec['evidencing'].filter(({ status }) => 
          status == EVIDENCING_STATUSES.SUBMITTED || status == EVIDENCING_STATUSES.VALIDATED);

        for (const evidencing of newSpec['submitted']) {
          if (
            evidencing['confirmation_source'] == EVIDENCING_SOURCES.SHAPEFILE 
            && evidencing['confirmation_source_details'] == EVIDENCING_SOURCE_DETAILS.MACHINE_DATA
          ) {
            newSpec['submittedByMachineData'].push(evidencing)
          }
          else {
            newSpec['submittedByUserData'].push(evidencing);
          }
        }

        practiceSpec[practice['value']] = newSpec;
      }

      return practiceSpec
    },
    isSuperUser() {
      if (this.user != null) {
        return this.user['is_superuser'];
      }
      
      return false;
    },
  },
  methods: {
    getProgressSpanColor(ratio) {
      if (ratio <= 25 || isNaN(ratio)) return "#D22F19";
      if (ratio <= 50) return "#D22F19";
      if (ratio <= 90) return "#D46600";
      return "#288418"
    },
    getProgressColor(ratio) {
      if (ratio <= 25 || isNaN(ratio)) return "#FE5D5733";
      if (ratio <= 50) return "#FF7A0033";
      if (ratio <= 90) return "#FEDB8052";
      return "#C7E3C1"
    }
  },
  mounted() {
    if (this.activeEvidencingGroup == null && !this.loading[ACTIVE_EVIDENCING_GROUP]) {
      router.push('/evidencing/');
    }

    if (this.viewingOrgWideEvidencing) {
      router.push('/evidencing/view/');
    }
  },
  watch: {}
}
</script>

<style scoped>
.relative-position-wrapper {
  position: relative;
  background: #1b1b1d;
  height: calc(100vh - 65px);
  width: calc(100% + 60px);
  left: -30px;
  overflow: hidden;
  margin-top: 0 !important;
}
.evidencing-dashboard {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #f4f4f4;
  padding: 36px 24px;
  z-index: 2;
  overflow-y: scroll;
}
.evidencing-dashboard-head {
  margin-bottom: 16px;
}
.evidencing-dashboard-head h1 {
  font-size: 22px;
  line-height: 33px;
  color: #000000;
  font-weight: bold;
  margin-bottom: 0;
}
.evidencing-dashboard-head h1 a {
  display: inline-flex;
}
.evidencing-dashboard-head h1 a i {
  color: #000000;
}
.evidencing-dashboard-head h2 {
  margin: 0;
  font-size: 20px;
  line-height: 1.5;
}
.evidencing-dashboard-info {
  padding: 16px;
  background: #F6F9FC;
  border-radius: 4px;
  margin: 16px 0;
}
.evidencing-dashboard-info p {
  margin: 0;
  color: #000000;
}
.evidencing-toggler > button {
  text-transform: none;
  padding: 6px 16px;
  letter-spacing: normal;
  border-radius: 4px !important;
}
.evidencing-toggler button:not(.v-item--active),
.evidencing-toggler > button:not(.v-item--active)::before {
  background: none !important;
}
.evidencing-toggler > button:first-of-type {
  margin-left: 0;
}
.evidencing-toggler > button.v-btn.v-item--active::before {
  background: #FFFFFF !important;
  opacity: 1;
}
.evidencing-toggler ::v-deep(.v-ripple__container) {
  display: none !important;
}
.evidencing-progress-viewer {
  margin-top: 24px;
}
.evidencing-progress-viewer h5 {
  margin: 0 0 8px;
}
.edit-btn {
  margin: 24px 0;
}
.evidencing-dashboard-statistics {
  display: flex;
  gap: 16px;
}
.evidencing-table-wrapper {
  margin-top: 8px;
  height: 500px;
  overflow-y: scroll;
}
.evidencing-table-wrapper table {
  table-layout: fixed;
}
.evidencing-table-wrapper th {
  position: sticky;
  top: 0;
  background: #FFFFFF;
  z-index: 3;
}
.evidencing-table-wrapper th[data-column="practice"] {
  width: 300px;
}
.evidencing-table-wrapper th[data-column="fieldname"] {
  width: 225px;
}
.evidencing-table-wrapper th[data-column="asapplied"] {
  width: 150px;
}
.evidencing-table-wrapper th[data-column="manual"] {
  width: 175px;
}
.evidencing-table-wrapper th[data-column="fields"],
.evidencing-table-wrapper th[data-column="totalevidencing"],
.evidencing-table-wrapper th[data-column="fieldid"] {
  width: 125px;
}
.v-progress-linear {
  border-radius: 6px;
}
.number-display {
  display: flex;
  width: 52px;
  padding: 4px 0 4px 8px;
  margin: 2px 0;
  background: #F6F9FC;
  border-radius: 4px;
  color: #110000BF;
}
::v-deep(.v-progress-linear__content) {
  justify-content: flex-start;
}
.progress-span {
  --margin-left-span: 0%;
  margin-left: clamp(12px, var(--margin-left-span), calc(100% - 48px));
}
.crops-display {
  text-transform: capitalize;
  color: #000000;
  margin-bottom: 0px;
}
.zero-percent-placeholder {
  height: 32px;
  width: 100%;
  background: #F6F9FC;
  border-radius: 6px;
}
</style>
