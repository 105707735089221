import {
  Dashboard,
  Defaults,
  Fertilizer,
  Fields,
  Filter,
  Map,
  Placements,
  SoilSampling,
  Organization,
} from "@/store/modules"

const year = localStorage.getItem("arvaYear")
const state = {
  organization: { corporation: { name: "" } },
  year,
}

const getters = {
  [Organization.Getters.getYear]: state => state.year,
}

const mutations = {
  [Organization.Mutations.setOrganization](state, organization) {
    const resetView = true
    state.organization = { ...organization }

    if (
      state.organization &&
      state.organization.corporation &&
      state.organization.corporation.package
    ) {
      this.commit(
        Organization.Mutations.setShowBase,
        state.organization.corporation.package.includes("base")
      )

      this.commit(
        Organization.Mutations.setShowSustainability,
        state.organization.corporation.package.includes("sustainability")
      )

      this.commit(
        Organization.Mutations.setShowImagery,
        state.organization.corporation.package.includes("imagery")
      )

      this.commit(
        Organization.Mutations.setShowRxZones,
        state.organization.corporation.package.includes("rx-zones")
      )

      this.commit(
        Organization.Mutations.setShowCropPlanner,
        state.organization.corporation.package.includes("crop-planner")
      )

      this.commit(
        Organization.Mutations.setShowSeedSelector,
        state.organization.corporation.package.includes("seed-selector")
      )

      this.commit(
        Organization.Mutations.setShowInsetEnrollment,
        state.organization.corporation.package.includes("inset-enrollment")
      )
    }

    this.commit(Filter.Mutations.initialize, { organization, resetView })
  },

  [Organization.Mutations.setYear](state, { year, onlyIfNull }) {
    if (onlyIfNull && localStorage.getItem("arvaYear")) return

    state.year = year
    localStorage.setItem("arvaYear", year)

    this.commit(Filter.Mutations.initialize, {
      organization: state.organization,
      force: true,
      resetView: false,
    })
  },

  [Organization.Mutations.setShowBase](state, showBase) {
    state.organization.showBase = showBase
  },

  [Organization.Mutations.setShowSustainability](state, showSustainability) {
    state.organization.showSustainability = showSustainability
  },
  [Organization.Mutations.setShowInsetEnrollment](state, showInsetEnrollment) {
    state.organization.showInsetEnrollment = showInsetEnrollment
  },

  [Organization.Mutations.setShowImagery](state, showImagery) {
    state.organization.showImagery = showImagery
  },

  [Organization.Mutations.setShowRxZones](state, showRxZones) {
    state.organization.showRxZones = showRxZones
  },

  [Organization.Mutations.setShowCropPlanner](state, showCropPlanner) {
    state.organization.showCropPlanner = showCropPlanner
  },

  [Organization.Mutations.setShowSeedSelector](state, showSeedSelector) {
    state.organization.showSeedSelector = showSeedSelector
  },
}

const actions = {
  [Organization.Actions.updateYear]({ commit, dispatch }, newYear) {
    commit(Organization.Mutations.setYear, { year: newYear })
    //dispatch(Organization.Actions.initialFetch, false)
    dispatch(Map.Actions.fetchMapData) //I think we only need to do this
  },

  [Organization.Actions.initialFetch]({ commit, dispatch, state }) {
    // dispatch(Defaults.Actions.fetchDefaults)
    dispatch(Defaults.Actions.fetchPracticeChoices)
    dispatch(Fields.Actions.fetchFeatureFlags)
    dispatch(Fertilizer.Actions.fetchFertilizers)
    dispatch(Fields.Actions.fetchFields)
    dispatch(Dashboard.Actions.fetchAgtPerformance)
    dispatch(Dashboard.Actions.fetchAgtSummary)
    // dispatch(Defaults.Actions.fetchFieldSettings)
    dispatch(Dashboard.Actions.fetchSeedCompanies)
    dispatch(Dashboard.Actions.fetchAgts)
    dispatch(SoilSampling.Actions.fetchSamplePlans, state.organization.id)
    //dispatch(Map.Actions.fetchMapData, resetView)
    dispatch(Placements.Actions.fetchSeedPlacementPlans)
    dispatch(Placements.Actions.fetchCropsWithTrials)
    commit(Map.Mutations.setIsFetching, false)
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
