<template>
  <ArvaModal
    name="confirmInsetOrderModal"
    :title="modalTitle"
    :wide="true"
    :width="1100"
    @close-modal="handleCloseModal"
  >
    <v-card class="pa-5">
      <form @submit.prevent="handleSubmit">
        <div>
          <v-card class="pa-4">
            <!--<v-chip color="green lighten-1 mb-2" v-if="updateOrderID">
              {{ updateOrderName }} - {{ updateOrderID }}
            </v-chip>-->
            <v-expansion-panels focusable v-model="panels">
              <v-expansion-panel>
                <v-expansion-panel-header
                  >Requested Program Filters:</v-expansion-panel-header
                >
                <!--<h4>Requested Order Filters:</h4>-->
                <v-expansion-panel-content>
                  <v-card class="pa-1 ma-2">
                    <v-data-table
                      v-if="previewDataFrame"
                      :headers="headers"
                      :items="previewDataFrame"
                    ></v-data-table>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card>
          <v-card class="pa-4 mt-4" v-if="!reviewOnly">
            <h4 v-if="updateOrderID == null">
              Finalize and Confirm Program Parameters:
            </h4>
            <h5 v-else>
              Define New Program Name for this Copy of Program:<br />
              <b>{{ updateOrderName }}</b>
            </h5>

            <div v-if="showError" class="pw-error pa-2">{{ errorText }}</div>

            <v-text-field
              v-if="currentOrderName == null"
              v-model="orderName"
              label="Program Name"
              placeholder="A descriptive name"
              type="text"
            ></v-text-field>

            <v-text-field
              v-else
              v-model="currentOrderName"
              label="Progam Name"
              type="text"
              disabled
            ></v-text-field>

            <v-text-field
              v-if="currentSupplyTarget"
              label="Current Supply Target"
              v-model="currentSupplyTarget"
              type="number"
            ></v-text-field>

            <v-text-field
              v-else
              label="Supply Target"
              v-model="supplyTarget"
              type="number"
            ></v-text-field>

            <v-select
              label="Select Aggregation Metric"
              v-if="currentAggregationMetric"
              v-model="currentAggregationMetric"
              :items="aggregationMetrics"
            ></v-select>
            <v-select
              label="Select Aggregation Metric"
              v-else
              v-model="aggregationMetric"
              :items="aggregationMetrics"
            ></v-select>
          </v-card>

          <ArvaModalSaveFooter
            v-if="updateOrderID && !reviewOnly"
            confirmText="Make Copy"
            cancelText="Cancel"
            @on-cancel="handleCloseModal"
            @on-confirm="handleSubmit"
          />
          <ArvaModalSaveFooter
            v-else-if="!reviewOnly"
            confirmText="Save"
            cancelText="Cancel"
            @on-cancel="handleCloseModal"
            @on-confirm="handleSubmit"
          />
        </div>
      </form>
    </v-card>
  </ArvaModal>
</template>

<script>
import ArvaModal from "@/components/modals/ArvaModal"
import ArvaModalSaveFooter from "@/components/modals/ArvaModalSaveFooter"
import CarbonReadyAPI from "@/api/CarbonReadyAPI"

export default {
  name: "ConfirmInsetOrderModal",
  props: [
    "filterParams",
    "headers",
    "items",
    "currentOrderName",
    "currentOrderID",
    "updateOrderID",
    "updateOrderName",
    "reviewOnly",

    "status",
    "currentOrderPrice",
    "currentSupplyTarget",
    "currentAggregationMetric",
  ],
  components: { ArvaModal, ArvaModalSaveFooter },

  data() {
    return {
      panels: 0,
      previewDataFrame: null,
      orderName: null,
      showError: false,
      orderPrice: 15,
      orderGeoids: [],
      supplyTarget: 10000,
      errorText: "Please Provide an Unique Order Name",
      aggregationMetric: "acreage",
      aggregationMetrics: ["acreage", "harvest_amount", "scope3_emission"],
      fieldSupplyEligible: [],
      fieldSupply: [],
      selectedToRemove: [],
      selectedToInclude: [],
      includedSearch: "",
      eligibleSearch: "",

      //modalTitle: "Confirm Inset Supply Program",
      supplyHeaders: [
        { text: "Field", value: "field" },
        { text: "LOI", value: "loi_entity" },
        { text: "Crop", value: "crop" },
        { text: "Enroll ID", value: "enrollment_id" },
      ],
    }
  },
  computed: {
    modalTitle() {
      if (this.reviewOnly) {
        return "Program Rule/Supply Filter Details"
      }
      if (this.updateOrderID) {
        return "Make a Copy of Current Program"
      } else {
        return "Confirm Inset Supply Program"
      }
    },
  },
  methods: {
    handleCloseModal() {
      this.$emit("close-modal")
    },

    handleSubmit() {
      if (this.orderName == null && this.currentOrderName == null) {
        this.errorText = "Please Provide an Unique Order Name"
        this.showError = true
        return
      } else {
        this.showError = false
      }

      if (this.supplyTarget == null || this.supplyTarget == "") {
        this.errorText = "Please Provide a Numeric Supply Target"
        this.showError = true
        return
      } else {
        if (this.supplyTarget < 0) {
          this.errorText = "Please Provide a Positive Supply Target"
          this.showError = true
        } else {
          this.showError = false
        }
      }

      if ("geoids" in this.filterParams["filter_params"]) {
        this.orderGeoids = this.filterParams["filter_params"]["geoids"]
      }

      let payload_params = this.filterParams["filter_params"]
      if ("min_das" in payload_params) {
        payload_params = Object.assign(payload_params, {
          das: payload_params["min_das"],
        })
        delete payload_params["min_das"]
      }
      if ("max_price" in payload_params) {
        payload_params = Object.assign(payload_params, {
          price: payload_params["max_price"],
        })
        delete payload_params["max_price"]
      }

      if (this.currentOrderID != null) {
        let thisPrice = this.currentOrderPrice
        if (this.currentOrderPrice == null) {
          thisPrice = this.orderPrice
        }

        let thisSupply = this.currentSupplyTarget
        if (this.currentSupplyTarget == null) {
          thisSupply = this.supplyTarget
        }

        payload_params["supply_target"] = {
          type: this.currentAggregationMetric,
          value: parseFloat(thisSupply),
        }
        let payload = {
          filter_params: payload_params,
          name: this.currentOrderName,
          price: thisPrice,
          geoids: this.orderGeoids,
          order_id: this.currentOrderID,
          status: this.status,
        }

        CarbonReadyAPI.updateInsetOrder(payload)
          .then(response => {
            if (response.status == 200) {
              this.$emit("save-from-modal")
            } else {
              this.errorText = response.data
              this.showError = true
            }
          })
          .catch(err => {
            this.errorText = err.response.data
            this.showError = true
          })
      } else {
        let thisSupply = this.supplyTarget
        if (this.currentSupplyTarget != null) {
          thisSupply = this.currentSupplyTarget
        }

        payload_params["supply_target"] = {
          type: this.aggregationMetric,
          value: parseFloat(thisSupply),
        }

        let payload = {
          filter_params: payload_params,
          name: this.orderName,
          price: this.orderPrice,
          geoids: this.orderGeoids,
        }
        CarbonReadyAPI.postInsetOrder(payload)
          .then(response => {
            if (response.status == 200) {
              this.$emit("save-from-modal")
            } else {
              this.errorText = response.data
              this.showError = true
            }
          })
          .catch(err => {
            this.errorText = err.response.data
            this.showError = true
          })
      }
    },
  },
  mounted() {
    this.previewDataFrame = []
    for (const [key, value] of Object.entries(
      this.filterParams["filter_params"]
    )) {
      if (typeof value === "object" && value !== null) {
        this.previewDataFrame.push({
          category: key,
          value: JSON.stringify(value, null, 1),
        })
      } else {
        this.previewDataFrame.push({ category: key, value: value })
      }
    }
  },
}
</script>

<style scoped>
.pw-error {
  color: red;
  font-weight: 500;
}
</style>
