<template>
  <div class="relative-position-wrapper">
    <RejectionMessageSidebar
      :uploadId="activeUploadId"
      :visible="showRejectionMessageSidebar"
      @closeSidebar="handleRejectionMessageSidebarClose"
    />

    <EvidencingMessagingSidebar
      :uploadDetail="activeUploadDetail"
      :visible="showMessagingSidebar"
      @closeSidebar="handleMessagingSidebarClose"
    />

    <AddEvidencingForUploadSidebar
      :visible="showAddEvidencingSidebar"
      :existingFulfills="activeFulfillsData"
      :uploadId="activeUploadId"
      @closeSidebar="handleAddEvidencingSidebarClose"
    />

    <div class="evidencing-validation">
      <div class="evidencing-validation-head">
        <h1>
          <a @click="router.push('/evidencing/')"><v-icon>mdi-chevron-left</v-icon></a>
          Evidencing Group Validation
        </h1>

        <EvidencingPageTabs :activeBtn="EVIDENCING_VALIDATION" />
        <EvidencingActionButtons :activeBtn="EVIDENCING_VALIDATION" />
      </div>

      <div class="evidencing-validation-toggler">
        <v-btn-toggle v-model="activeValidationTab" mandatory tile color="#000000" class="d-flex flex-wrap validation-btns-toggle" group>
          <v-btn height="32px" value="all">All ({{ fileEmbeds.length }})</v-btn>
          <v-btn height="32px" :value="REVIEW_ACCEPTED">Approved ({{ getApprovedUploads.length }})</v-btn>
          <v-btn height="32px" :value="REVIEW_REJECTED">Needs Resubmit ({{ getRejectedUploads.length }})</v-btn>
          <v-btn height="32px" :value="NEEDS_REVIEW">Needs Review ({{ getNeedsReviewUploads.length }})</v-btn>
        </v-btn-toggle>

        <v-btn @click="downloadAll" outlined height="36px">Download All <v-icon>mdi-tray-arrow-down</v-icon></v-btn>
      </div>

      <div v-if="processingTableFunc" class="loading-spinner">
        <v-progress-circular indeterminate :size="48" color="#79c61c" />
      </div>

      <div v-if="processingTableError.length > 0">
        Error loading uploads for validation: {{ processingTableError }}
      </div>
      <div v-else class="evidencing-table-wrapper" :class="processingTableFunc ? 'loading' : ''">
        <v-simple-table>
          <thead>
            <tr>
              <th data-column="image">Image</th>
              <th data-column="file">File</th>
              <th data-column="evidencing-id">Evidencing ID</th>
              <th data-column="evidencing-types">Evidencing Types</th>
              <th data-column="evidencing-fields-ids">Field IDs</th>
              <th data-column="evidencing-fields-names">Field Names</th>
              <th data-column="evidencing-crops">Crops</th>
              <th data-column="evidencing-status">Status</th>
            </tr>
          </thead>
  
          <tbody>
            <tr v-for="uploadDetail, idx in getUploads" :key="uploadDetail['created_at'] + '-' + idx">
              <td>
                <EvidencingMultiImageViewer
                  :uploadId="uploadDetail['uploadId']"
                  :images="uploadDetail['data']"
                  @download="downloadData(uploadDetail)"
                />
              </td>
              <td>
                <div>
                  <p>Upload Contents:</p>
                  <p v-for="file, fIdx in uploadDetail['data']" :key="file['filename'] + '-' + fIdx">
                    {{ file['filename'] }}
                  </p>
                  <p class="uploaded-at-timestamp">Uploaded at: {{ new Date(uploadDetail['created_at']).toLocaleString() }}</p>
                  <p>Upload ID: {{ uploadDetail['uploadId'] }}</p>
                </div>
              </td>
  
              <td class="evidencing-related-cells">
                <div class="cell-with-close-btn">
                  <div class="underlined-portion" v-for="evidencingId, tIdx in Object.keys(uploadDetail['fulfills'])" :key="'evidencingId-' + tIdx">
                    <v-btn @click="handleRemoveEvidencing(uploadDetail['uploadId'], evidencingId)" v-if="isSuperUser" outlined height="16">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <p>
                      {{ evidencingId }}
                    </p>
                  </div>

                  <v-btn @click="openAddEvidencingSidebar(uploadDetail['uploadId'], uploadDetail['fulfills'])" v-if="isSuperUser" outlined height="20">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </div>
              </td>
  
              <td class="evidencing-related-cells">
                <div>
                  <p class="underlined-portion" v-for="evidencingId, tIdx in Object.keys(uploadDetail['fulfills'])" :key="'evidencingType-' + tIdx">
                    {{ truncateByChars(
                        getFormattedEvidencingType(uploadDetail['fulfills'][evidencingId]['confirmation_type']), 22
                    ) }}
                  </p>
                </div>
              </td>

              <td class="evidencing-related-cells">
                <div>
                  <p class="underlined-portion" v-for="evidencingId, tIdx in Object.keys(uploadDetail['fulfills'])" :key="'fields-' + tIdx">
                    {{ uploadDetail['fulfills'][evidencingId]['fields'].map(([id, name]) => id).join(', ') }}
                  </p>
                </div>
              </td>
  
              <td class="evidencing-related-cells">
                <div>
                  <p class="underlined-portion" v-for="evidencingId, tIdx in Object.keys(uploadDetail['fulfills'])" :key="'fields-' + tIdx">
                    {{ truncateByChars(
                      uploadDetail['fulfills'][evidencingId]['fields'].map(([id, name]) => name).join(', '), 22
                    ) }}
                  </p>
                </div>
              </td>
              
              <td class="evidencing-related-cells">
                <div>
                  <p class="underlined-portion" v-for="evidencingId, tIdx in Object.keys(uploadDetail['fulfills'])" :key="'crops-' + tIdx">
                    {{ uploadDetail['fulfills'][evidencingId]['crops'].map(([id, name]) => name).join(', ') }}
                  </p>
                </div>
              </td>
              
              <td>
                <div class="status-btns-wrapper">
                  <v-btn
                    height="32px"
                    v-if="isSuperUser"
                    outlined
                    :class="uploadDetail['status'] == REVIEW_ACCEPTED ? 'existing-state' : ''" 
                    @click="createApprovedUploadReview(uploadDetail['uploadId'])">Approve
                  </v-btn>
                  <v-btn
                    height="32px"
                    v-if="isSuperUser"
                    outlined
                    :class="uploadDetail['status'] == REVIEW_REJECTED ? 'existing-state' : ''" 
                    @click="openRejectionMessageSidebar(uploadDetail['uploadId'])">Reject
                  </v-btn>
                  <v-btn
                    height="32px"
                    outlined
                    @click="openMessagingSidebar(uploadDetail)"
                    >Messages
                  </v-btn>
                </div>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import {
  fetchGroupUploads,
  createEvidencingUploadReview,
  createEvidencingMessage,
  modifyUploadEvidencingRelationships
} from "@/api/EvidencingAPI";
import { mapState, mapActions } from "vuex";
import { base64ToBlob } from "@/utility";
import { EVIDENCING_TYPE_CHOICES } from "@/constants/defaults";
import { Evidencing } from "@/store/modules";
import {
  EVIDENCING_VALIDATION,
  ACTIVE_EVIDENCING_GROUP,
  REVIEW_ACCEPTED,
  REVIEW_REJECTED,
  REVIEW_MESSAGE,
  NEEDS_REVIEW
} from "@/constants/evidencing";
import EvidencingPageTabs from "@/components/evidencing/EvidencingPageTabs";
import EvidencingActionButtons from "@/components/evidencing/EvidencingActionButtons";
import RejectionMessageSidebar from "@/components/evidencing/RejectionMessageSidebar";
import EvidencingMessagingSidebar from "@/components/evidencing/EvidencingMessagingSidebar";
import EvidencingMultiImageViewer from "@/components/evidencing/EvidencingMultiImageViewer";
import AddEvidencingForUploadSidebar from "@/components/evidencing/AddEvidencingForUploadSidebar";
import router from "@/router";
import JSZip from "jszip";

const MIME_FILE_MAP = {
  png: 'image/png',
  jpg: 'image/jpg',
  jpeg: 'image/jpeg',
  pdf: 'application/pdf',
  // csv: 'text/csv',
}

export default {
  name: "EvidencingGroupValidation",
  components: {
    EvidencingActionButtons,
    EvidencingPageTabs,
    RejectionMessageSidebar,
    EvidencingMessagingSidebar,
    EvidencingMultiImageViewer,
    AddEvidencingForUploadSidebar
  },
  data() {
    return {
      activeFulfillsData: null,
      showAddEvidencingSidebar: false,
      activeUploadDetail: null,
      showMessagingSidebar: false,
      activeValidationTab: 'all',
      processingTableFunc: false,
      processingTableError: '',
      activeUploadId: null,
      showRejectionMessageSidebar: false,
      uploadModifications: {},
      evidencingModifications: {},
      expandedRows: [],
      fileUrls: [],
      fileEmbeds: [],
      EVIDENCING_VALIDATION,
      REVIEW_ACCEPTED,
      REVIEW_REJECTED,
      NEEDS_REVIEW,
      router,
      EVIDENCING_TYPE_CHOICES
    }
  },
  computed: {
    ...mapState({
      user: state => state.User.user,
      year: state => state.Organization.year,
      loading: state => state.Evidencing.loading,
      activeEvidencingGroup: state => state.Evidencing.activeEvidencingGroup
    }),
    getUploads() {
      if (this.activeValidationTab == 'all') return this.fileEmbeds;
      if (this.activeValidationTab == REVIEW_ACCEPTED) return this.getApprovedUploads;
      if (this.activeValidationTab == REVIEW_REJECTED) return this.getRejectedUploads;
      if (this.activeValidationTab == NEEDS_REVIEW) return this.getNeedsReviewUploads;
      return []
    },
    getApprovedUploads() {
      return this.fileEmbeds.filter(({ status }) => status == REVIEW_ACCEPTED);
    },
    getRejectedUploads() {
      return this.fileEmbeds.filter(({ status }) => status == REVIEW_REJECTED);
    },
    getNeedsReviewUploads() {
      return this.fileEmbeds.filter(({ status }) => status == NEEDS_REVIEW);
    },
    isSuperUser() {
      if (this.user != null) {
        return this.user['is_superuser'];
      }
      
      return false;
    },
  },
  methods: {
    ...mapActions({
      fetchEvidencingGroupData: Evidencing.Actions.fetchEvidencingGroupData,
    }),
    truncateByChars(text, charLimit) {
      if (text != null) {
        if (text.length <= charLimit) return text
        return `${text.slice(0, charLimit)}...`
      }
    },
    getFormattedEvidencingType(evidencingType) {
      const found = EVIDENCING_TYPE_CHOICES.find(({ value }) => value == evidencingType);
      if (found == null) return 'Not Specified';
      return found['name'];
    },
    openAddEvidencingSidebar(uploadId, fulfillsData) {
      this.activeFulfillsData = fulfillsData;
      this.activeUploadId = uploadId;
      this.showAddEvidencingSidebar = true;
    },
    handleAddEvidencingSidebarClose(cancelled, uploadId, evidencingIds) {
      if (!cancelled) {
        this.processingTableFunc = true;
        this.processingTableError = '';
        
        modifyUploadEvidencingRelationships({ uploadId, evidencingIds, action: 'add' })
        .then(_ => {
          this.processingTableFunc = false;
          this.getAllGroupUploads();
          this.fetchEvidencingGroupData({ year: this.year, groupId: this.activeEvidencingGroup['id'] });
        })
        .catch(err => {
          console.log('error!', err);
          this.processingTableFunc = false;
          this.processingTableError = err;
        });
      }

      this.activeFulfillsData = null;
      this.showAddEvidencingSidebar = false;
    },
    handleRemoveEvidencing(uploadId, evidencingId) {
      this.processingTableFunc = true;
      this.processingTableError = '';
        
      modifyUploadEvidencingRelationships({ uploadId, evidencingIds: [evidencingId], action: 'remove' })
      .then(_ => {
        const foundUploadIdx = this.fileEmbeds.findIndex(u => u['uploadId'] == uploadId);
        if (foundUploadIdx != -1) {
          const newFulfillsObj = { ...this.fileEmbeds[foundUploadIdx]['fulfills'] };
          delete newFulfillsObj[evidencingId];
          Vue.set(this.fileEmbeds[foundUploadIdx], 'fulfills', newFulfillsObj);
        }
        this.processingTableFunc = false;
        this.fetchEvidencingGroupData({ year: this.year, groupId: this.activeEvidencingGroup['id'] });
      })
      .catch(err => {
        console.log('error!', err);
        this.processingTableFunc = false;
        this.processingTableError = err;
      });
    },
    saveBlobData(blob, filename) {
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, filename);
      }
      else {
        const a = document.createElement('a');
        document.body.appendChild(a);
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = filename;
        a.click();
        setTimeout(() => {
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }, 0)
      }
    },
    getMessagesBlob(uploadDetail) {
      let txt = '';

      const chronology = [];

      for (const review of uploadDetail['reviews']) {
        let content = '';

        if (review['acceptance'] == REVIEW_ACCEPTED) {
          content = `This upload was approved.`;
        }

        if (review['acceptance'] == REVIEW_REJECTED) {
          content = `This upload was rejected.`;
        }

        chronology.push({
            type: review['acceptance'],
            date: review['created_at'],
            user: review['sender'],
            content
          }
        )
      }

      for (const message of uploadDetail['messages']) {
        chronology.push({
          type: REVIEW_MESSAGE,
          date: message['created_at'],
          user: message['sender'],
          content: message['message']
        })
      }

      const sortedMessages = chronology.toSorted((a, b) => new Date(a['date']) - new Date(b['date']));

      if (sortedMessages.length == 0) {
        return null
      }

      for (const { type, date, user, content } of sortedMessages) {
        txt += `Type: ${type == REVIEW_MESSAGE ? "MESSAGE" : "REVIEW"}\n`;
        txt += `Date: ${new Date(date).toLocaleString()}\n`;
        txt += `User: ${user['email']}\n`;
        txt += `Content: ${content}\n`;
        txt += '\n';
      }

      return new Blob([txt], { type: 'text/plain' });
    },
    async downloadAll() {
      const zip = new JSZip();
      for (const uploadDetail of this.getUploads) {
        zip.folder(uploadDetail['uploadId']);
        const msgBlob = this.getMessagesBlob(uploadDetail);
        if (msgBlob != null) {
          zip.file(`${uploadDetail['uploadId']}/messages.txt`, msgBlob);
        }

        for (const { filename, url } of uploadDetail['data']) {
          const d = await fetch(url);
          const blob = await d.blob();
          zip.file(`${uploadDetail['uploadId']}/${filename}`, blob);
        }
      }

      const z = await zip.generateAsync({ type: 'blob' });
      this.saveBlobData(z, 'validation-bulk.zip');
    },
    async downloadData(uploadDetail) {
      const zip = new JSZip();
      const msgBlob = this.getMessagesBlob(uploadDetail);
      if (msgBlob != null) {
        zip.file('messages.txt', msgBlob);
      }

      for (const { filename, url } of uploadDetail['data']) {
        const d = await fetch(url);
        const blob = await d.blob();
        zip.file(filename, blob);
      }

      const z = await zip.generateAsync({ type: 'blob' });
      this.saveBlobData(z, `${uploadDetail['uploadId']}.zip`);
    },
    openMessagingSidebar(uploadDetail) {
      this.activeUploadDetail = uploadDetail;
      this.showMessagingSidebar = true;
    },
    handleMessagingSidebarClose(cancelled, uploadId, message) {
      if (!cancelled) {
        this.createMessage(uploadId, message)
      }

      this.activeUploadDetail = null;
      this.showMessagingSidebar = false;
    },
    createMessage(uploadId, message) {
      this.processingTableFunc = true;
      this.processingTableError = '';

      createEvidencingMessage({ uploadId, message })
        .then(({ data }) => {
          this.processingTableFunc = false;
          const foundUpload = this.fileEmbeds.find(u => u['uploadId'] == uploadId);
          if (foundUpload != null) {
            foundUpload['messages'].push(data);
          }
        })
        .catch(err => {
          console.log('error!', err);
          this.processingTableFunc = false;
          this.processingTableError = err;
        });
    },
    createApprovedUploadReview(uploadId) {
      this.processingTableFunc = true;
      this.processingTableError = '';

      createEvidencingUploadReview({ uploadId, acceptance: REVIEW_ACCEPTED })
        .then(({ data }) => {
          const foundUploadIdx = this.fileEmbeds.findIndex(u => u['uploadId'] == uploadId);
          if (foundUploadIdx != -1) {
            Vue.set(this.fileEmbeds[foundUploadIdx], 'reviews', [...this.fileEmbeds[foundUploadIdx]['reviews'], data['review']]);
            Vue.set(this.fileEmbeds[foundUploadIdx], 'status', data['review']['acceptance']);
          }
          this.processingTableFunc = false;
          this.fetchEvidencingGroupData({ year: this.year, groupId: this.activeEvidencingGroup['id'] });
        })
        .catch(err => {
          console.log('error!', err);
          this.processingTableFunc = false;
          this.processingTableError = err;
        });
    },
    openRejectionMessageSidebar(uploadId) {
      this.activeUploadId = uploadId;
      this.showRejectionMessageSidebar = true;
    },
    handleRejectionMessageSidebarClose(cancelled, uploadId, rejectionMessage) {
      if (!cancelled) {
        this.processingTableFunc = true;
        this.processingTableError = '';

        createEvidencingUploadReview({ uploadId, acceptance: REVIEW_REJECTED, message: rejectionMessage })
        .then(({ data }) => {
          const foundUploadIdx = this.fileEmbeds.findIndex(u => u['uploadId'] == uploadId);
          if (foundUploadIdx != -1) {
            Vue.set(this.fileEmbeds[foundUploadIdx], 'reviews', [...this.fileEmbeds[foundUploadIdx]['reviews'], data['review']]);
            Vue.set(this.fileEmbeds[foundUploadIdx], 'status', data['review']['acceptance']);
            
            // message with review for rejections
            Vue.set(this.fileEmbeds[foundUploadIdx], 'messages', [...this.fileEmbeds[foundUploadIdx]['messages'], data['message']]);

          }
          this.processingTableFunc = false;
          this.fetchEvidencingGroupData({ year: this.year, groupId: this.activeEvidencingGroup['id'] });
        })
        .catch(err => {
          console.log('error!', err);
          this.processingTableFunc = false;
          this.processingTableError = err;
        });
      }

      this.showRejectionMessageSidebar = false;
    },
    async getAllGroupUploads() {
      if (this.activeEvidencingGroup == null) return;

      this.processingTableFunc = true;
      this.processingTableError = '';
      fetchGroupUploads({ id: this.activeEvidencingGroup['id'] })
      .then(response => {
        const { data } = response;
        Vue.set(this, 'fileUrls', data);
        this.processingTableFunc = false;
      })
      .catch(err => {
        this.processingTableFunc = false;
        this.processingTableError = err;
        console.log('oop!', err);
      })
    },
    async getRawFileData(fileUrl) {
      const z = new JSZip();
      const r = await fetch(fileUrl);
      const d = await r.blob();
      const zip = await z.loadAsync(d);
      const fileSrcs = [];

      for (const filename in z['files']) {
        const fileExt = filename.split('.').pop();

        if (fileExt in MIME_FILE_MAP) {
          // jszip's 'blob' async doesn't seem to work well here
          const src = await zip.file(filename).async('base64');
          const blob = base64ToBlob(src, `application/${fileExt}`);
          const url = URL.createObjectURL(blob);
          const uploadDetail = { filename, url, fileExt, isImage: true };
          fileSrcs.push(uploadDetail);
        }
        else {
          const blob = await zip.file(filename).async('blob');
          const url = URL.createObjectURL(blob);
          fileSrcs.push({ filename, url, fileExt, isImage: false });
        }
      }

      return fileSrcs;
    }
  },
  mounted() {
    if (this.activeEvidencingGroup == null && !this.loading[ACTIVE_EVIDENCING_GROUP]) {
      router.push('/evidencing/');
    }

    this.getAllGroupUploads();
  },
  watch: {
    async fileUrls(curr) {
      this.fileEmbeds = [];

      for (const uploadId in curr) {
        this.getRawFileData(curr[uploadId]['url'])
        .then(data => {
          this.fileEmbeds.push({ ...curr[uploadId], uploadId, data });
        })
        .catch(err => {
          console.log('error parsing zips:', err);
          this.fileEmbeds.push(null);
        });
      }
    }
  }
}
</script>

<style scoped>
.relative-position-wrapper {
  position: relative;
  background: #1b1b1d;
  height: calc(100vh - 65px);
  width: calc(100% + 60px);
  left: -30px;
  overflow: hidden;
  margin-top: 0 !important;
}
.evidencing-validation {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #f4f4f4;
  padding: 36px 24px;
  z-index: 2;
}
.evidencing-validation-head {
  margin-bottom: 16px;
}
.evidencing-validation-head h1 {
  font-size: 22px;
  line-height: 33px;
  color: #000000;
  font-weight: bold;
  margin-bottom: 0;
}
.evidencing-validation-head h1 a {
  display: inline-flex;
}
.evidencing-validation-head h1 a i {
  color: #000000;
}
td {
  vertical-align: top;
  padding: 16px !important
}
td:not(.evidencing-related-cells) > div {
  position: sticky;
  top: 64px;
}
td p {
  margin: 0;
  color: #000000;
}
.status-btns-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: flex-start;
  align-content: flex-start;
}
.status-btns-wrapper button {
  width: 100%;
  margin-bottom: 12px;
}
.evidencing-table-wrapper {
  margin-top: 8px;
  height: 70vh;
  overflow-y: scroll;
  background: #FFFFFF;
  border-radius: 8px;
}
.evidencing-table-wrapper table {
  table-layout: fixed;
}
.evidencing-table-wrapper.loading {
  opacity: 0.5;
  pointer-events: none;
}
.evidencing-table-wrapper th {
  position: sticky;
  top: 0;
  background: #FFFFFF;
  z-index: 3;
}
.evidencing-table-wrapper th[data-column="image"] {
  width: auto;
  min-width: 250px;
}
.evidencing-table-wrapper th[data-column="file"] {
  width: 196px;
}
.evidencing-table-wrapper th[data-column="evidencing-crops"] {
  width: 50px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.evidencing-table-wrapper th[data-column="evidencing-id"],
.evidencing-table-wrapper th[data-column="evidencing-fields-ids"] {
  width: 100px;
  padding-left: 0 !important;
  padding-right: 8px !important;
}
.evidencing-table-wrapper th[data-column="evidencing-types"],
.evidencing-table-wrapper th[data-column="evidencing-fields-names"] {
  width: 200px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.evidencing-table-wrapper th[data-column="evidencing-status"] {
  width: 200px;
}
.cell-with-close-btn > div {
  display: flex;
  align-items: center;
}
.cell-with-close-btn > div > button {
  width: 16px !important;
  min-width: unset !important;
  padding: 0 !important;
  margin-right: 8px;
}
.cell-with-close-btn > button {
  width: 66% !important;
  min-width: unset !important;
  padding: 0 !important;
}
.cell-with-close-btn button i {
  font-size: 16px;
}
.evidencing-related-cells {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.underlined-portion {
  text-transform: capitalize;
}
.evidencing-related-cells .underlined-portion:not(:last-of-type) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  white-space: nowrap;
}
.uploaded-at-timestamp {
  padding-top: 16px;
  border-top: 1px solid rgba(0, 0, 0, 0.25);
}
.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 9;
}
.validation-btns-toggle > button {
  text-transform: none;
  padding: 6px 16px;
  letter-spacing: normal;
  border-radius: 4px !important;
}

.validation-btns-toggle > button.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background: none !important;
}
button.existing-state {
  background: #001331;
  border-color: #001331;
  color: #FFFFFF;
  cursor: not-allowed;
  pointer-events: none;
}
.evidencing-validation-toggler {
  display: flex;
  justify-content: space-between;
}
.evidencing-validation-toggler > button i {
  margin-left: 8px
}
</style>