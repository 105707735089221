<template>
  <div class="upload-row" :class="configHint">
    <div class="d-flex align-items-center">
      <input
        @click="checkboxClicked"
        :disabled="!account.access_enabled"
        type="checkbox"
        name="selected"
        ref="orgSelectedCheckbox"
        class="checkbox"
      />
      <div class="divider" />
      <div>
        <div>{{ account.name }}</div>
        <div v-if="orgName" class="icon-org complete">
          <i class="fa fa-check-circle" /> {{ orgName }}
        </div>
        <div v-if="isChecked && !orgName" class="icon-org">
          <i class="fa fa-minus-circle" /> Update mapping
        </div>
      </div>
      <div class="actions">
        <div v-if="orgName" @click="handleConfigure">
          <i class="fa fa-gear" />
          {{ clientsActive }} / {{ clientsTotal }} clients active
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "JDIntegrationRow",
  props: {
    account: { type: Object },
    orgs: { type: Array },
  },
  data() {
     return {
       isChecked: false
     }
  },
  computed: {
    orgName() {
      const org = this.orgs.find(o => o.id === this.account.org_node)
      if (!org) return ""
      return org.name
    },

    configHint() {
      return this.isChecked && !this.orgName? "config-hint" : ""
    },

    clientsActive() {
      const active = this.account.clients.filter(
        clie => (clie.is_active)
      )
      return active.length
    },

    clientsTotal() {
      return this.account.clients.length
    }
  },
  methods: {
    handleConfigure() {
      this.$emit("configure", this.account.pk)
    },

    sendCheckEvents(checkbox) {
      this.isChecked = checkbox.checked
      if (this.isChecked) this.$emit("checked", this.account)
      else this.$emit("unchecked", this.account)
    },

    checkboxClicked() {
      const checkbox = this.$refs.orgSelectedCheckbox
      this.sendCheckEvents(checkbox)
    },
  },
}
</script>
<style scoped>
.actions {
  text-align: right;
  color: #0095ff;
  font-weight: 500;
  cursor: pointer;
  position: absolute;
  right: 10px;
}

.actions > div {
  display: block;
}

.actions > div:hover {
  color: #888;
}

.upload-row {
  position: relative;
  padding: 18px;
  border-top: 1pt solid #d5d5d5;
}

.upload-row.config-hint {
  font-weight: bold;
  color: #444;
  background-color: #f4f5ff;
}

.upload-row:hover {
  background: #fafafa;
}

.disabled {
  color: #b7baca;
}

.complete {
  color: #00a200;
}

.divider {
  height: 40px;
  width: 1px;
  border-left: 1pt solid #e0e0e0;
  display: block;
  margin: auto 18px;
}

.icon-info {
  min-width: 210px;
}

.icon-info > i {
  font-size: 25px;
}

.icon-text {
  font-size: 15px;
  margin-left: 10px;
  font-weight: 500;
}

.icon-org {
  margin-left: 10px;
  font-weight: 500;
  font-size: 12px;
}

.checkbox {
  min-width: 17px;
  min-height: 17px;
}
</style>
