<template>
  <ArvaModal
    name="reviewAllocationModal"
    title="Current Allocations for this Program"
    :wide="true"
    :width="1100"
    @close-modal="handleCloseModal"
  >
    <v-card class="pa-5">
      <v-snackbar v-model="snackbar" timeout="2000" color="green lighten-1">
        Could Not Save, Please Be Sure To Specify a Channel Partner and
        Allotment
        <template v-slot:actions>
          <v-btn color="blue" variant="text" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <v-card>
        <AllocationTable
          filter_type="program"
          :programID="programID"
          @export-channel-partner="setChannelPartner"
        />
      </v-card>
      <v-autocomplete
        v-model="inputSelection"
        ref="autocompleteSearch"
        clearable
        label="Select Channel Partner to Update Their Allocation For this Program"
        :items="items"
      ></v-autocomplete>

      <v-text-field
        label="Target Allotment"
        v-model="allocationTarget"
        type="number"
      ></v-text-field>

      <ArvaModalSaveFooter
        confirmText="Update Total Allotment For Selected CP"
        cancelText="Close"
        @on-cancel="handleCloseModal"
        @on-confirm="handleConfirm"
      />
    </v-card>
  </ArvaModal>
</template>

<script>
import ArvaModal from "@/components/modals/ArvaModal"
import ArvaModalSaveFooter from "@/components/modals/ArvaModalSaveFooter"
import AllocationTable from "@/components/insets/AllocationTable"

import CarbonReadyAPI from "@/api/CarbonReadyAPI"
import FieldsAPI from "@/api/FieldsAPI"

import _ from "lodash"

export default {
  name: "ReviewAllocationModal",
  props: ["programID", "filterParams"],
  components: { ArvaModal, ArvaModalSaveFooter, AllocationTable },

  data() {
    return {
      items: [],
      inputSelection: [],
      allocationTarget: 0,
      snackbar: false,
      previewParam: "org_nodes",
    }
  },

  methods: {
    setChannelPartner(val) {
      this.inputSelection = val.corporation_id
      this.allocationTarget = val.total_allotment
    },
    updateAllocation() {
      CarbonReadyAPI.postAllocationData({
        filter_type: "program",
        programID: this.programID,
        cpTarget: this.inputSelection,
        allotment: this.allocationTarget,
      })
        .then(response => {
          if (response.status == 200) {
            this.$emit("close-modal")
          }
        })
        .catch(() => {
          this.snackbar = true
        })
    },
    getOrganizations() {
      FieldsAPI.getCorpList().then(response => {
        this.items = []
        response.data.forEach(x => {
          this.items.push({
            text: x["name"] + " (" + x["id"] + ")",
            value: x["id"],
          })
        })
      })
    },
    handleCloseModal() {
      this.$emit("close-modal")
    },
    handleConfirm() {
      this.updateAllocation()
      //this.$emit("close-modal")
    },
  },

  mounted() {
    this.getOrganizations()
  },
}
</script>

<style scoped>
.pw-error {
  color: red;
  font-weight: 500;
}
</style>
