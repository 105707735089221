<template>
  <div class="opacity-slider">
    <v-slider class="my-0 py-0" :hide-details="true" v-model="opacity" />
  </div>
</template>

<script>

import { Map } from "@/store/modules"

export default {
  name: "OpacitySlider",
  props: ["id"],
  computed: {
    opacity: {
      get() {
        return this.$store.getters[Map.Getters.getOpacities][this.id] * 100
      },
      set(value) {
        this.$store.commit(Map.Mutations.updateOpacity, {
          opacity: value / 100,
          layerId: this.id,
        })
      },
    },
  },
}
</script>

<style scoped>
.opacity-slider {
  width: 100%;
}
</style>
