<template>
  <div>
    <div v-show="orgId && !canAccess" class="row">
      <div class="col-12">
        <v-alert type="error" dismissible>
          You do not have permission to access this page. Please contact Arva
          support for further assistance.
        </v-alert>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "Permissions",
  props: {
    package: { type: String },
    superUserOnly: { type: Boolean },
  },

  computed: {
    ...mapState({
      orgId: state => state.Organization.organization.id,
      showBase: state => state.Organization.organization.showBase,
      showSustainability: state =>
        state.Organization.organization.showSustainability,
      showInsetEnrollment: state =>
        state.Organization.organization.showInsetEnrollment,
      showImagery: state => state.Organization.organization.showImagery,
      showRxZones: state => state.Organization.organization.showRxZones,
      showCropPlanner: state => state.Organization.organization.showCropPlanner,
      showSeedSelector: state =>
        state.Organization.organization.showSeedSelector,
      isSuperuser: state => state.User.user.is_superuser,
    }),

    // This function checks if the user has access to a view based on the package
    // - For "Base" package, user will have access to view pages that are under Fields and Opportunity Summary
    // - For "Sustainability" package, user will have access to view pages that are under Sustainability
    // - For "Imagery" package, user will have access to view pages that are under Field Imagery
    // - For "Rx Zones" package, user will have access to view pages that are under Rx Zones
    // - For "Crop Planner" package, user will have access to view AI Crop Designer and Planning P+L that are under AI Planning
    // - For "Seed Selector" package, user will have access to view Seed Selector and Seed Placement Plans that are under Seed Selector
    // Note: There is one component, FieldImageryListView that is being used in multiple packages.
    //  So for this scenario, as long as the user navigates to this page from one of the permitted packages, then they will be able to access it.
    canAccess() {
      if (!this.package) return false
      if (this.superUserOnly && !this.isSuperuser) return false

      this.package = this.package.toLowerCase()
      if (this.package === "base") {
        return this.showBase
      } else if (this.package === "sustainability") {
        return this.showSustainability
      } else if (this.package === "imagery") {
        return this.showImagery
      } else if (this.package === "rx-zones") {
        return this.showRxZones
      } else if (this.package === "crop-planner") {
        return this.showCropPlanner
      } else if (this.package === "seed-selector") {
        return this.showSeedSelector
      } else if (this.package === "inset-enrollment") {
        return this.showInsetEnrollment
      }
    },
  },
}
</script>
