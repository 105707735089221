export const VIRIDIS = {
  "2": ["#fde725", "#440154"],
  "3": ["#fde725", "#21918c", "#440154"],
  "4": ["#fde725", "#35b779", "#31688e", "#440154"],
  "5": ["#fde725", "#5ec962", "#21918c", "#3b528b", "#440154"],
  "6": ["#fde725", "#7ad151", "#22a884", "#2a788e", "#414487", "#440154"],
  "7": [
    "#fde725",
    "#90d743",
    "#35b779",
    "#21918c",
    "#31688e",
    "#443983",
    "#440154",
  ],
  "8": [
    "#fde725",
    "#a0da39",
    "#4ac16d",
    "#1fa187",
    "#277f8e",
    "#365c8d",
    "#46327e",
    "#440154",
  ],
  "9": [
    "#fde725",
    "#addc30",
    "#5ec962",
    "#28ae80",
    "#21918c",
    "#2c728e",
    "#3b528b",
    "#472d7b",
    "#440154",
  ],
}
export const AGT_COLORS_OLD = [
  "#2fa1da",
  "#fb4f2f",
  "#e4ae38",
  "#6d904f",
  "#8a8a8a",
  "#16bdcf",
  "#9367bc",
  "#d62628",
  "#1f77b3",
  "#e277c1",
  "#8c564b",
  "#bcbc21",
  "#3a0182",
  "#004200",
  "#0fffa8",
  "#5d003f",
  "#c6bcff",
  "#424f52",
  "#b80080",
  "#7c0100",
  "#6026ff",
  "#ffff9a",
  "#aec8aa",
  "#00857c",
  "#543a00",
  "#93fbff",
  "#00bf00",
  "#7c00a0",
  "#aa7200",
  "#90ff00",
  "#01bd89",
  "#00447b",
  "#c8826e",
  "#ff1f82",
  "#dd00ff",
  "#057400",
  "#644460",
  "#878eff",
  "#ffb5f4",
  "#526236",
  "#cd85ff",
  "#676983",
  "#bdb3bd",
  "#a56089",
  "#95d3ff",
  "#0100f7",
  "#ff8001",
  "#8a2844",
  "#aca06d",
  "#52448a",
  "#c8ffd8",
  "#aa4600",
  "#ff798e",
  "#82d370",
  "#909ebf",
  "#9300f4",
  "#ebcf9a",
  "#ac8ab1",
  "#006249",
  "#ffdb00",
  "#877750",
  "#7eaaa3",
  "#000097",
  "#f400c6",
  "#643328",
  "#006677",
  "#03e2c8",
  "#cd576b",
  "#795900",
  "#5e879a",
  "#b3ff91",
  "#5d726b",
  "#520066",
  "#058750",
  "#831f6e",
  "#3b9505",
  "#647200",
  "#f0a06b",
  "#5e4f44",
  "#bc0049",
  "#cf6726",
  "#d695aa",
  "#895dff",
  "#826b75",
  "#2a54b8",
  "#6e7cba",
  "#e6d4d3",
  "#5d0018",
  "#7c3b01",
  "#80b17c",
  "#c8d87c",
  "#00e83b",
  "#7cb1ff",
  "#ff54ff",
  "#a32621",
  "#1ce4ff",
  "#7caf3b",
  "#7b4b90",
  "#dfff48",
  "#6b00c3",
  "#cda897",
  "#bd62c3",
  "#89cdcd",
  "#4603c8",
  "#5d9179",
  "#414901",
  "#05a79c",
  "#cf8c36",
  "#fff7cf",
  "#425470",
  "#b544ff",
  "#cf4993",
  "#cfa3df",
  "#93d400",
  "#a793da",
  "#2da557",
  "#8ce2b5",
  "#a3a89c",
  "#6b5bb6",
  "#ff7e5d",
  "#a78289",
  "#afbdd8",
  "#2ac3ff",
  "#a5673d",
  "#f690fd",
  "#874b64",
  "#ff0c4b",
  "#215d23",
  "#4291ff",
  "#87829c",
  "#672d44",
  "#b14f41",
  "#004d52",
  "#5e1a00",
  "#ac4167",
  "#4f3167",
  "#7eb5d1",
  "#a8b869",
  "#ff95ca",
  "#c87495",
  "#364f38",
  "#ffcf62",
  "#5d5762",
  "#879375",
  "#a877ff",
  "#03c862",
  "#e6bdd4",
  "#d4e2cf",
  "#876790",
  "#897c26",
  "#cddbff",
  "#aa676b",
  "#313474",
  "#ff5da8",
  "#009aaf",
  "#70ffdd",
  "#775b38",
  "#4f649a",
  "#cc00b3",
  "#567b54",
  "#506e7b",
  "#015e91",
  "#aabcbd",
  "#017e99",
  "#03dd97",
  "#873a2b",
  "#ef958e",
  "#75c6aa",
  "#70695d",
  "#ccdb08",
  "#af8556",
  "#d80075",
  "#9c3f80",
  "#d84400",
  "#dd6754",
  "#d4b172",
  "#62265d",
  "#baa13d",
  "#d8f2b3",
  "#56018e",
  "#a19aaa",
  "#4d4926",
  "#a3a8ff",
  "#ace8db",
  "#995901",
  "#ac00e2",
  "#46822f",
  "#cac3ac",
  "#00c4b5",
  "#605277",
  "#336d67",
  "#a59180",
  "#8399a1",
  "#fd5664",
  "#7095d1",
  "#728c07",
  "#7e004b",
  "#152fa0",
  "#d1c1e2",
  "#c885cf",
  "#6b444b",
  "#7e0023",
  "#00a179",
  "#b1a8cf",
  "#f90000",
  "#afe8ff",
  "#939e4f",
  "#727982",
  "#d82d54",
  "#466001",
  "#0059ff",
  "#773fb5",
  "#ace460",
  "#674424",
  "#525d50",
  "#957267",
  "#a8e49a",
  "#a30057",
  "#d862f6",
  "#8e7ccf",
  "#ffbc93",
  "#a30091",
  "#9affb8",
  "#a7c1ff",
  "#f46200",
  "#e4efff",
  "#b89ca3",
  "#609593",
  "#ff9e34",
  "#8c2800",
  "#726b31",
  "#df824d",
  "#af7bd4",
  "#bc2d00",
  "#7b6ea3",
  "#484262",
  "#c6a3ff",
  "#004d28",
  "#c3c68e",
  "#df48d6",
  "#e6e864",
  "#e4c10a",
  "#00f4f0",
  "#9e5ba1",
  "#4b41b6",
  "#64338e",
  "#757e6b",
  "#a88936",
]

export const AGT_COLORS = [
  "#a6cee3",
  "#97c4dd",
  "#88bad8",
  "#79b1d3",
  "#69a7ce",
  "#5b9ec8",
  "#4c94c3",
  "#3c8bbe",
  "#2d81b9",
  "#1f78b4",
  "#2f83af",
  "#3f8eaa",
  "#509aa6",
  "#60a5a1",
  "#70b19c",
  "#81bc98",
  "#91c893",
  "#a1d38e",
  "#b2df8a",
  "#a3d87f",
  "#95d175",
  "#87ca6a",
  "#79c360",
  "#6bbb55",
  "#5db44b",
  "#4fad40",
  "#41a636",
  "#33a02c",
  "#499f38",
  "#5f9e44",
  "#759e50",
  "#8b9d5c",
  "#a29c68",
  "#b89b74",
  "#ce9b80",
  "#e49a8c",
  "#fb9a99",
  "#f88b8b",
  "#f57d7d",
  "#f36f6f",
  "#f06161",
  "#ed5253",
  "#eb4445",
  "#e83637",
  "#e52829",
  "#e31a1c",
  "#e52c25",
  "#e83e2e",
  "#eb5137",
  "#ee6340",
  "#f1754a",
  "#f48853",
  "#f79a5c",
  "#faac65",
  "#fdbe6e",
  "#fdb762",
  "#fdb056",
  "#fda949",
  "#fda23d",
  "#fe9b31",
  "#fe9424",
  "#fe8d18",
  "#fe860c",
  "#fe7f00",
  "#f98417",
  "#f38a2f",
  "#ed9047",
  "#e7955f",
  "#e19b76",
  "#dba18e",
  "#d5a6a6",
  "#cfacbe",
  "#cab2d6",
  "#bfa4cf",
  "#b497c8",
  "#a98ac1",
  "#9f7dbb",
  "#9471b4",
  "#8963ad",
  "#7f57a7",
  "#7449a0",
  "#6a3d9a",
  "#7a5299",
  "#8b6899",
  "#9b7d99",
  "#ac9399",
  "#bca899",
  "#cdbe99",
  "#ddd399",
  "#eee999",
  "#ffff99",
  "#f6ec8c",
  "#edda7f",
  "#e5c773",
  "#dcb566",
  "#d3a25a",
  "#cb904d",
  "#c27d41",
  "#b96b34",
  "#b15928",
]
