export const CHANNEL_PARTNER = 'cp'
export const GROWER = 'grower'

export const YES = 'yes'
export const NO = 'no'

export const ENROLLMENT_TABLE = 'enrollmentTable'
export const ROLLUP_TABLE = 'rollupTable'
export const FSS_INDIVIUAL = 'fss'

export const OPEN = "open"
export const REQUESTED = "requested"
export const REQUESTED_PENDING = "requested_pending"
export const AVAILABLE = "available"
export const CONFIRMED = "confirmed"
export const MATCHED = "matched"
export const LOCKED = "locked"

export const BLANK_METADATA = {
    thumbnail: null,
    farmName: null,
    practices: []
}

export const BLANK_ACREAGE_VALUES = {}
BLANK_ACREAGE_VALUES[REQUESTED] = {}
BLANK_ACREAGE_VALUES[CONFIRMED] = {}
BLANK_ACREAGE_VALUES[REQUESTED_PENDING] = {}

export const STATUS_MAP = {}
STATUS_MAP[OPEN] = {
    text: 'Unavailable',
    color: 'gray'
}
STATUS_MAP[AVAILABLE] = {
    text: 'Available',
    color: 'gray'
}
STATUS_MAP[REQUESTED] = {
    text: 'Requested',
    color: 'green'
}
STATUS_MAP[REQUESTED_PENDING] = {
    text: 'Pre-Requested',
    color: 'green'
}
STATUS_MAP[LOCKED] = {
    text: 'Enrolled',
    color: 'green'
}
