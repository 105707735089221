<template>
  <div class="row">
    <form @submit.prevent="handleSubmit" novalidate>
      <div class="card">
        <h2
          class="card-heading default-title"
          @click="showInputs = !showInputs"
        >
          Financials / ROI
          <i class="fa fa-chevron-down" v-if="!showInputs" />
          <i class="fa fa-chevron-up" v-else />
        </h2>
        <div class="card-body" v-if="showInputs">
          <div class="row">
            <div class="col">
              <span class="default-input-title"
                >Operational Cost
                <i
                  class="fa fa-info-circle"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Operation direct cost excluding Seed, Fertilizer, & Financing"
              /></span>

              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input
                  type="number"
                  class="form-control"
                  v-model="operationCosts.operational_cost"
                />
              </div>
            </div>

            <div class="col">
              <span class="default-input-title"
                >Irrigation & Equipment Cost</span
              >
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input
                  type="number"
                  class="form-control"
                  v-model="operationCosts.equipment_and_irrigation_cost"
                />
              </div>
            </div>

            <div class="col">
              <span class="default-input-title">Lease/Rental Payment Type</span>
              <v-select-old
                class="select-style"
                :options="paymentTypeChoices"
                :reduce="o => o.value"
                v-model="operationCosts.lease_payment_type"
                label="display_name"
              />
            </div>

            <div class="col">
              <span class="default-input-title">Lease Cost $USD/acre</span>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input
                  type="number"
                  class="form-control"
                  v-model="operationCosts.lease_cost_per_acre"
                />
              </div>
            </div>

            <div class="col">
              <span class="default-input-title">Land Lease Crop Share %</span>
              <div class="input-group">
                <input
                  type="number"
                  class="form-control"
                  v-model="operationCosts.lease_crop_share_percentage"
                />
                <div class="input-group-append">
                  <span class="input-group-text">%</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <span class="default-input-title">Fertilizer Cost Per Acre</span>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input
                  type="number"
                  class="form-control"
                  v-model="operationCosts.fert_cost_per_acre"
                />
              </div>
            </div>

            <div class="col">
              <span class="default-input-title">Seed Cost Per Acre</span>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input
                  type="number"
                  class="form-control"
                  v-model="operationCosts.seed_cost_per_acre"
                />
              </div>
            </div>

            <div class="col">
              <span class="default-input-title">Est. Market $ Per Bushel</span>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input
                  type="number"
                  class="form-control"
                  v-model="operationCosts.estimated_price_per_bushel"
                />
              </div>
            </div>

            <div class="col" />

            <div class="col">
              <button
                type="submit"
                class="btn btn-primary update-btn"
                :class="{ disabled: !canUpdate }"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from "vuex"
import DefaultsAPI from "@/api/DefaultsAPI"
import { LEASE_TYPE_CHOICES } from "@/constants/defaults"
import { Fields } from "@/store/modules"
import { successMessage } from "@/utility"

export default {
  name: "OperationCostsDefaults",
  props: {
    operationCosts: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      showInputs: true,
      paymentTypeChoices: LEASE_TYPE_CHOICES,
      // update button highlighting
      initialOperationalCost: null,
      initialEquipmentCost: null,
      initialLeasePaymentType: null,
      initialLeaseCost: null,
      initialLeaseCropShare: null,
      initialFertCost: null,
      initialSeedCost: null,
      initialEstimatedPrice: null,
    }
  },

  computed: {
    canUpdate() {
      const {
        operational_cost,
        equipment_and_irrigation_cost,
        lease_payment_type,
        lease_cost_per_acre,
        lease_crop_share_percentage,
        fert_cost_per_acre,
        seed_cost_per_acre,
        estimated_price_per_bushel,
      } = this.operationCosts
      const {
        initialOperationalCost,
        initialEquipmentCost,
        initialLeasePaymentType,
        initialLeaseCost,
        initialLeaseCropShare,
        initialFertCost,
        initialSeedCost,
        initialEstimatedPrice,
      } = this
      return (
        operational_cost !== initialOperationalCost ||
        equipment_and_irrigation_cost !== initialEquipmentCost ||
        lease_payment_type !== initialLeasePaymentType ||
        lease_cost_per_acre !== initialLeaseCost ||
        lease_crop_share_percentage !== initialLeaseCropShare ||
        fert_cost_per_acre !== initialFertCost ||
        seed_cost_per_acre !== initialSeedCost ||
        estimated_price_per_bushel !== initialEstimatedPrice
      )
    },
  },

  methods: {
    ...mapActions({
      fetchFields: Fields.Actions.fetchFields,
    }),

    async handleSubmit() {
      await DefaultsAPI.update(
        "operation-costs-defaults",
        this.operationCosts.id,
        this.operationCosts
      )
      successMessage(this, "Operation Costs Defaults Updated", "top-right")
      this.fetchFields()
    },
  },

  mounted() {
    const { $ } = window
    $(function() {
      $('[data-toggle="tooltip"]').tooltip()
    })
    const {
      operational_cost,
      equipment_and_irrigation_cost,
      lease_payment_type,
      lease_cost_per_acre,
      lease_crop_share_percentage,
      fert_cost_per_acre,
      seed_cost_per_acre,
      estimated_price_per_bushel,
    } = this.operationCosts
    this.initialOperationalCost = operational_cost
    this.initialEquipmentCost = equipment_and_irrigation_cost
    this.initialLeasePaymentType = lease_payment_type
    this.initialLeaseCost = lease_cost_per_acre
    this.initialLeaseCropShare = lease_crop_share_percentage
    this.initialFertCost = fert_cost_per_acre
    this.initialSeedCost = seed_cost_per_acre
    this.initialEstimatedPrice = estimated_price_per_bushel
  },
}
</script>

<style scoped>
.defaults-container {
  background-color: white;
}

.default-title {
  font-size: 16px;
  font-weight: bold;
  color: #515365;
  margin: 0;
  padding-bottom: 0;
  cursor: pointer;
}

.default-title i {
  margin-left: 5px;
}

.default-input-title {
  font-size: 14px;
}

form {
  width: 100%;
}

.update-btn {
  width: 100%;
  margin: 0;
  margin-top: 17px;
}
</style>
